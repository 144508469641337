import { FC, PropsWithChildren } from 'react'
import { Drawer, Grid, Button, SxProps, Typography } from '@mui/material'
import { CloseIcon } from '../../assets/icons/Close'
import {
  containerStyle,
  headerStyle,
  contentContainerStyle,
  closeButtonStyle
} from './SideBar.styles'

interface SideBarProps {
  isOpen: boolean
  headerText: string
  onClose?: () => void
  sx?: SxProps
}

export const SideBar: FC<PropsWithChildren<SideBarProps>> = ({
  isOpen,
  children,
  headerText,
  onClose,
  sx = {}
}) => {
  return (
    <Drawer
      anchor='right'
      open={isOpen}
      onClose={onClose}
      transitionDuration={0}
    >
      <Grid container sx={{ ...containerStyle, ...sx }} flexDirection='column'>
        <Grid container justifyContent='space-between'>
          <Grid container item justifyContent='space-between' sx={headerStyle}>
            <Typography variant='h6'>{headerText}</Typography>
            <Grid
              container
              item
              xs={2}
              justifyContent='flex-end'
              alignItems='center'
            >
              <Button
                disableElevation
                variant='text'
                sx={closeButtonStyle}
                onClick={onClose}
              >
                <CloseIcon />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={contentContainerStyle}>{children}</Grid>
      </Grid>
    </Drawer>
  )
}
