import { offerMapper } from './offerMapper'
import { requestMapper } from './requestMapper'
import { tradeInMapper } from './tradeInMapper'
import { vehicleMapper } from './vehicleMapper'
import { contractMapper } from './contractMapper'
import { NormalizedTransactionType, RawTransactionType } from '../types'
import { paymentMapper } from './paymentMapper'

export const transactionMapper = (
  transaction: RawTransactionType
): NormalizedTransactionType => {
  return {
    id: transaction.id,
    createdBy: transaction.created_by,
    createdOn: new Date(transaction.created_on),
    changedBy: transaction.changed_by,
    changedOn: transaction.changed_on ? new Date(transaction.changed_on) : null,
    requestID: transaction.request_id,
    buyerID: transaction.buyer_id,
    sellerID: transaction.seller_id,
    trackingNumber: transaction.tracking_number,
    price: transaction.price,
    notes: transaction.notes,
    status: transaction.status,
    vehicleID: transaction.vehicle_id,
    tradeInVehicle: transaction.trade_in_vehicle,
    request: requestMapper(transaction.request),
    vehicle: vehicleMapper(transaction.vehicle),
    tradeIn: transaction.trade_in ? tradeInMapper(transaction.trade_in) : null,
    offer: transaction.offer ? offerMapper(transaction.offer) : null,
    previousOffer: transaction.previous_offer
      ? offerMapper(transaction.previous_offer)
      : null,
    contract: transaction.contract
      ? contractMapper(transaction.contract)
      : null,
    payments: transaction.payments.length
      ? paymentMapper(transaction.payments)
      : null
  }
}
