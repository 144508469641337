import { FC } from 'react'
import { useDeviceInfo } from '../../../../../utils/hooks'
import { NotAddedPlug } from '../DocumentRow/NotAddedPlug'

interface NotAddedActionsProps {
  userType: 'Buyer' | 'Seller'
}

export const NotAddedActions: FC<NotAddedActionsProps> = ({ userType }) => {
  const { isTablet } = useDeviceInfo()
  const title = isTablet ? 'Not added' : `Not added by ${userType} yet`

  return <NotAddedPlug title={title} />
}
