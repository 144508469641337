import { Addresses } from './addresses'
import { Vehicles } from './vehicles'
import { ApiOptions } from '../types'
import { Utils } from '../utils'
import { noop } from '../../utils'
import { Offers } from './offers'
import { Payment } from './payment'
import { Users } from './users'
import { Shipments } from './shipments'
import { Requests } from './requests'
import { config } from '../../constants/config'

export class ExternalCarSnoopApi {
  readonly utils: Utils
  addresses: Addresses
  vehicles: Vehicles
  offers: Offers
  payment: Payment
  users: Users
  shipments: Shipments
  requests: Requests

  constructor(options: ApiOptions = {}) {
    const {
      endpoint = config.EXTERNAL_API_ENDPOINT,
      token = config.AWS_API_KEY,
      session,
      logoutCallback = noop
    } = options

    this.utils = new Utils({
      endpoint,
      token,
      session,
      logoutCallback
    })

    this.addresses = new Addresses(this.utils)
    this.vehicles = new Vehicles(this.utils)
    this.offers = new Offers(this.utils)
    this.payment = new Payment(this.utils)
    this.users = new Users(this.utils)
    this.shipments = new Shipments(this.utils)
    this.requests = new Requests(this.utils)
  }
}

export const externalCarSnoopApi = new ExternalCarSnoopApi()
