import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { pageNavigation } from '../../thunks/pageNavigation'
import { logout } from '../../thunks/logout'

interface OfferPageState {
  currentTab: number
}

export const initialState: OfferPageState = {
  currentTab: 0
}

export const offerPageSlice = createSlice({
  name: 'offerPage',
  initialState,
  reducers: {
    setCurrentTab: (store, action: PayloadAction<number>) => {
      store.currentTab = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(pageNavigation.fulfilled, () => {
      return initialState
    })
    builder.addCase(logout.fulfilled, () => {
      return initialState
    })
  }
})
