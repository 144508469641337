import { Grid, Typography } from '@mui/material'
import { format, parse } from 'date-fns'
import { FC, useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { EmailIcon } from '../../assets/icons/Email'
import { PenIcon } from '../../assets/icons/Pen'
import { PhoneIcon } from '../../assets/icons/Phone'
import { BlueButton, RemoveButton } from '../../atoms/Button'
import { Condition } from '../../atoms/Condition'
import { Divider } from '../../atoms/Divider'
import { EllipsisEmail } from '../../atoms/EllipsisEmail'
import { EllipsisText } from '../../atoms/EllipsisText'
import { IconText } from '../../atoms/IconText'
import { Show } from '../../atoms/JSXExtensions'
import { Loader } from '../../atoms/Loader'
import { PersonalSettingsItem } from '../../atoms/PersonalSettingsItem'
import { RequiredLabel } from '../../atoms/RequiredText'
import { MMDDYYYY, YYYYMMDD } from '../../constants/applicationConstants'
import {
  PersonalDetailsForm,
  PersonalDetailsFormFieldsTypes
} from '../../features/PersonalDetailsForm'
import { routes } from '../../routes'
import { actionsUserInfo } from '../../store/features/userInfo'
import { ResponseStatusMap } from '../../types/status'
import { nbsp } from '../../types/strings'
import { formatPhoneNumber } from '../../utils/formatters/formatPhoneNumber'
import {
  useApiContext,
  useAppDispatch,
  useMakeService,
  useUserInfo
} from '../../utils/hooks'
import { UploadNewPhoto } from '../UploadNewPhoto'

export interface ContactInformationProps {
  id: string
  avatar: string
  email: string
  fullName: string
  dob: string
  phone: string
}

export const ContactInformationCard: FC<ContactInformationProps> = ({
  id,
  avatar,
  email,
  fullName,
  dob,
  phone
}) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { carSnoopApi } = useApiContext()
  const {
    driversLicense,
    driversLicenseState,
    driversLicenseExpiration,
    addresses,
    taxID,
    isAdmin
  } = useUserInfo()
  const [isEdit, setIsEdit] = useState(false)

  const setEditMode = useCallback(() => {
    setIsEdit(true)
  }, [setIsEdit])

  const setViewMode = useCallback(() => {
    setIsEdit(false)
  }, [setIsEdit])

  const handleRemoveAccount = useCallback(() => {
    navigate(routes.deleteUser, { state: { email } })
  }, [email])

  const [updateUser, updateUserObservable] = useMakeService(
    async (data: PersonalDetailsFormFieldsTypes) => {
      const res = await carSnoopApi.users.updateUser({
        id,
        taxId: taxID,
        driversLicense,
        driversLicenseState,
        driversLicenseExpiration,
        addresses,
        ...data
      })

      if (res.status === ResponseStatusMap.Success) {
        const userData = await carSnoopApi.users.readUser(id)

        if (userData.status === ResponseStatusMap.Success) {
          const { firstName, lastName, phone: newPhone, birthDate } = userData

          dispatch(
            actionsUserInfo.setUserPersonalInfo({
              firstName,
              lastName,
              phone: newPhone,
              dateOfBirth: birthDate,
              fullName: `${firstName} ${lastName}`
            })
          )
          setViewMode()
        }
        return userData
      }
      return { status: ResponseStatusMap.Error }
    },
    {
      withStatusNotification: true
    }
  )

  const formattedDate = useMemo(() => {
    return format(parse(dob, YYYYMMDD, new Date()), MMDDYYYY)
  }, [dob])

  return (
    <PersonalSettingsItem isEditMode={isEdit}>
      <Condition
        condition={updateUserObservable.isLoading}
        trueContent={<Loader />}
        falseContent={
          <>
            <Grid item xs={12}>
              <UploadNewPhoto avatar={avatar} />
            </Grid>

            <Grid container item spacing={2}>
              <Grid
                container
                item
                xs={12}
                justifyContent='space-between'
                alignItems='center'
                wrap='nowrap'
              >
                <Typography variant='label1'>Contact Information</Typography>
                <Condition
                  condition={!isEdit}
                  trueContent={
                    <BlueButton onClick={setEditMode}>
                      <PenIcon />
                    </BlueButton>
                  }
                />
              </Grid>
              <Show when={isEdit}>
                <Grid item>
                  <RequiredLabel marginTop={0} />
                </Grid>
              </Show>

              <Condition
                condition={isEdit}
                trueContent={
                  <PersonalDetailsForm
                    onSave={updateUser}
                    onCancel={setViewMode}
                  />
                }
                falseContent={
                  <Grid container item xs={12} spacing='10px'>
                    <Grid
                      container
                      item
                      xs={12}
                      alignItems='center'
                      wrap='nowrap'
                    >
                      <Typography variant='label1'> Name:{nbsp}</Typography>
                      <EllipsisText tooltipText={fullName}>
                        {fullName}
                      </EllipsisText>
                    </Grid>
                    <Grid container item xs={12} alignItems='center'>
                      <Typography variant='label1'>DOB:{nbsp}</Typography>
                      {formattedDate}
                    </Grid>
                    <Grid item xs={12}>
                      <IconText icon={<EmailIcon />}>
                        <EllipsisEmail maxCount={20}>{email}</EllipsisEmail>
                      </IconText>
                    </Grid>
                    <Grid item xs={12}>
                      <IconText icon={<PhoneIcon />}>
                        {formatPhoneNumber(phone)}
                      </IconText>
                    </Grid>
                    <Show when={isAdmin}>
                      <>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid item xs={12}>
                          <RemoveButton onClick={handleRemoveAccount}>
                            Delete my user
                          </RemoveButton>
                        </Grid>
                      </>
                    </Show>
                  </Grid>
                }
              />
            </Grid>
          </>
        }
      />
    </PersonalSettingsItem>
  )
}
