import { Button, Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { Divider } from '../../../../atoms/Divider'
import {
  DeleteConfirmationModal,
  DiscardConfirmationModal
} from '../../../../molecules/Modal'
import { CommonConfirmationModal } from '../../../../molecules/Modal/CommonConfirmationModal'
import { useModal } from '../../../../utils/hooks'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const ModalsExample: FC = () => {
  const commonConfirmationModal = useModal()
  const deleteConfirmationModal = useModal()
  const discardConfirmationModal = useModal()

  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <Grid container flexDirection='column' spacing={1}>
          <Grid item>
            <Typography>Common confirmation Modal</Typography>
          </Grid>
          <Grid item>
            <Button onClick={commonConfirmationModal.onOpen}>Open modal</Button>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <Typography>Delete confirmation Modal</Typography>
          </Grid>
          <Grid item>
            <Button onClick={deleteConfirmationModal.onOpen}>Open modal</Button>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <Typography>Discard confirmation Modal</Typography>
          </Grid>
          <Grid item>
            <Button onClick={discardConfirmationModal.onOpen}>
              Open modal
            </Button>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
        </Grid>
        <CommonConfirmationModal
          cancelText='Cancel'
          header='Discard Changes'
          confirmText='Discard'
          message='Are you sure you want to discard your changes?'
          hint='Some hint text'
          isOpen={commonConfirmationModal.isOpen}
          setOpen={commonConfirmationModal.setOpen}
          onSubmit={() => console.log('Submit common modal')}
        />
        <DeleteConfirmationModal
          {...deleteConfirmationModal}
          onSubmit={() => console.log('Submit delete modal')}
          hint='Deleted document cannot be recovered.'
          message='Are you sure you would like to delete document?'
        />
        <DiscardConfirmationModal {...discardConfirmationModal} />
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
