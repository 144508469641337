import { useCallback } from 'react'
import { generatePath } from 'react-router-dom'
import { routes } from '../../routes'

export const useVehicleDetailsRoute = () => {
  const getPathToVehicle = useCallback(
    (id: string) => {
      return generatePath(routes.vehicles.detail, {
        vehicleId: id
      })
    },
    [routes.vehicles.detail]
  )

  const getPathToVehicleEdit = useCallback(
    (id: string) => {
      return generatePath(routes.vehicles.edit, {
        vehicleId: id
      })
    },
    [routes.vehicles.edit]
  )

  return {
    getPathToVehicle,
    getPathToVehicleEdit,
  }
}
