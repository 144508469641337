import { Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { For } from '../../../../../atoms/JSXExtensions'
import { SelectedCar, SelectedCarProps } from './components/SelectedCar'

export type SelectedCarsProps = {
  selectedCars: SelectedCarProps[]
}

export const SelectedCars: FC<SelectedCarsProps> = (props) => {
  const { selectedCars } = props

  return (
    <Grid container item spacing={3} flexDirection='column'>
      <Grid item>
        <Typography variant='label1'>Selected cars</Typography>
      </Grid>

      <Grid container item spacing={2}>
        <For of={selectedCars}>
          {(car) => (
            <Grid item xs={12} md={6} lg={4}>
              <SelectedCar {...car} />
            </Grid>
          )}
        </For>
      </Grid>
    </Grid>
  )
}
