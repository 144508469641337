import { Grid, Typography } from '@mui/material'
import { FC, useMemo } from 'react'
import { PaymentMethodTypes } from '../../../../../../../api/carSnoopApi/statusTypes'
import { ActionBlock } from '../../../../../../../atoms/ActionBlock'
import { Condition } from '../../../../../../../atoms/Condition'
import { InformationLabel } from '../../../../../../../atoms/InformationLabel'
import { LabelWithCustomValue } from '../../../../../../../atoms/LabelWithCustomValue'
import { COLORS } from '../../../../../../../types/colors'
import { formatPrice } from '../../../../../../../utils'
import { PAYMENT_STATUS } from '../../../../../../Offers/forms/PaymentMicroform/constants'

interface FinalPaymentStep {
  totalPrice: number
  paymentMethod: PaymentMethodTypes
  isPaid: boolean
  disabled?: boolean
  onBackClick: () => void
  onCheckOutClick: () => void | Promise<void>
}

export const FinalShippingStep: FC<FinalPaymentStep> = ({
  totalPrice,
  paymentMethod,
  isPaid,
  disabled,
  onBackClick,
  onCheckOutClick
}) => {
  const displayTotalPrice = useMemo(() => {
    return formatPrice(totalPrice, { includeDecimalPart: true })
  }, [totalPrice])

  const statusOfPaymentParams = useMemo(
    () => ({
      props: {
        color: isPaid ? COLORS.utility_positive : COLORS.text_red
      },
      children: isPaid
        ? PAYMENT_STATUS.COMPLETED
        : PAYMENT_STATUS.WAITING_FOR_PAYMENT
    }),
    [isPaid]
  )

  return (
    <Grid container>
      <Grid item width='100%' mt='46px'>
        <LabelWithCustomValue
          title='Final price'
          value={<Typography variant='title1'>{displayTotalPrice}</Typography>}
        />
      </Grid>
      <Grid item width='100%' mt='46px'>
        <LabelWithCustomValue
          title='Payment method'
          value={<Typography variant='label1'>{paymentMethod}</Typography>}
        />
      </Grid>
      <Grid item width='100%' mt='46px'>
        <LabelWithCustomValue
          title='Status of payment'
          value={
            <Typography {...statusOfPaymentParams.props} variant='label1'>
              {statusOfPaymentParams.children}
            </Typography>
          }
        />
      </Grid>

      <Condition
        condition={isPaid}
        trueContent={
          <Grid item mt={4}>
            <InformationLabel labelText='When the Seller confirms the payment, we`ll be able to ship the vehicle!' />
          </Grid>
        }
        falseContent={
          <Grid item mt={4} width='100%'>
            <ActionBlock
              schema={[
                {
                  label: 'back',
                  type: 'secondary',
                  onClick: onBackClick
                },
                {
                  label: 'check out ',
                  type: 'primary',
                  disabled,
                  onClick: onCheckOutClick
                }
              ]}
            />
          </Grid>
        }
      />
    </Grid>
  )
}
