import { Grid } from '@mui/material'
import { EllipsisEmail } from '../../../../atoms/EllipsisEmail'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const EllipsisEmailExample = () => {
  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <Grid container flexDirection='column'>
          <Grid item>
            <EllipsisEmail>
              super_long_email_who_colud_be_so_crazy_to_create_it@mail.com
            </EllipsisEmail>
          </Grid>
          <Grid item>
            <EllipsisEmail>normal_email@mail.com@mail.com</EllipsisEmail>
          </Grid>
        </Grid>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
