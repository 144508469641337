import { Box } from '@mui/material'
import { FC, RefObject, useCallback, useMemo } from 'react'
import { Condition } from '../../../../atoms/Condition'
import { UploadDocument } from '../../../../atoms/UploadDocument'
import { BORDERS } from '../../../../types/colors'
import { CompressImageSuccessResponse } from '../../../../utils/compressImages'
import { useImagePreview } from '../../hooks/useImagePreview'
import { Drop } from '../Drop/Drop'
import { EditMenu } from '../EditMenu/EditMenu'
import { Hover } from '../Hover/Hover'
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner'

export interface MainImagePreviewProps {
  id: string
  type: string
  image: string
  onUpload: (data: {
    data: CompressImageSuccessResponse
    id?: string
    thumbnail: boolean
  }) => Promise<unknown>
  onDelete: (data: { id: string; type: string }) => Promise<unknown>
  setLoadingImageId: (id: string) => void
  onImageClick: (id: string) => void
  onModalClose: () => void
  isFetching?: boolean
  index: number
  allowEdit?: boolean
  fileInputRef: RefObject<HTMLDivElement>
}

export const MainImagePreview: FC<MainImagePreviewProps> = (props) => {
  const {
    image,
    id,
    type,
    isFetching,
    index,
    onUpload,
    onDelete,
    setLoadingImageId,
    onImageClick,
    onModalClose,
    allowEdit = true,
    fileInputRef
  } = props

  const thumbnail = useMemo(() => !index, [index])
  const {
    isImageHovered,
    isLoading,
    handleImageMouseOver,
    handleImageMouseLeave,
    handleUpload,
    handleDeleteClick,
    handleBeforeUpload,
    errorMessage
  } = useImagePreview(
    id,
    type,
    onUpload,
    onDelete,
    setLoadingImageId,
    thumbnail
  )

  const handleUploadClick = useCallback(() => {
    fileInputRef.current?.click()
    onModalClose()
  }, [onModalClose])

  const handleImageClick = useCallback(() => {
    if (errorMessage) {
      return
    }

    onImageClick(id)
  }, [onImageClick, id, errorMessage])

  const showBorder = useMemo(
    () => (!image && allowEdit) || errorMessage || isLoading,
    [image, errorMessage, isLoading]
  )

  return (
    <Box
      sx={{
        aspectRatio: '16/9',
        border: showBorder ? BORDERS.EMPTY_IMAGE_MAIN : 'none',
        position: 'relative',
        width: '100%',
      }}
      onMouseOver={handleImageMouseOver}
      onMouseLeave={handleImageMouseLeave}
      onClick={handleImageClick}
    >
      <UploadDocument
        name={`edit-photo-${id}`}
        onChange={handleUpload}
        onBeforeChange={handleBeforeUpload}
      >
        <div ref={fileInputRef}>{/* Magic! */}</div>
      </UploadDocument>
      <Condition
        condition={isLoading || !!isFetching}
        trueContent={<LoadingSpinner isMain />}
        falseContent={
          <Condition
            condition={isImageHovered && !!image && !errorMessage && allowEdit}
            trueContent={
              <>
                <EditMenu
                  isMain
                  onDeleteClick={handleDeleteClick}
                  onUploadClick={handleUploadClick}
                />
                <Hover />
              </>
            }
          />
        }
      />
      <Condition
        condition={!isLoading && !isFetching}
        trueContent={
          <Drop
            onUploadClick={handleUploadClick}
            handleBeforeDrop={handleBeforeUpload}
            onFileDrop={handleUpload}
            error={errorMessage}
            image={image}
            allowEdit={allowEdit}
          />
        }
      />
    </Box>
  )
}
