const SPLITTER = ' - '

export const parseSSN = (value: string) => {
  const [area, group, serial] = value.includes(SPLITTER)
    ? value.split(SPLITTER)
    : [value.slice(0, 3), value.slice(3, 5), value.slice(5)]

  return {
    area: area || '',
    group: group || '',
    serial: serial || ''
  }
}
