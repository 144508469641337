import { useMemo } from 'react'
import { Grid, Typography } from '@mui/material'
import { OperationResult } from '../../atoms/OperationResult'
import { OperationResultScreenProps } from './types'
import { errorHeaderText, successHeaderText } from './constants'
import { SomethingWentWrong } from '../../assets/icons/SomethingWentWrong'
import { Condition } from '../../atoms/Condition'
import { Loader } from '../../atoms/Loader'
import { ActionBlock } from '../../atoms/ActionBlock'
import { SuccessIconCircle } from '../../assets/icons/SuccessIconCircle'

export const OperationResultScreen = (props: OperationResultScreenProps) => {
  const { sx = {}, ...otherProps } = props
  const {
    isError,
    isLoading,
    successOperationInfo,
    errorOperationInfo,
    withoutHeader
  } = otherProps

  const { header, icon, mainText, buttonText, onClick } = isError
    ? errorOperationInfo
    : successOperationInfo

  const defaultHeader = useMemo(() => {
    if (withoutHeader) return null

    const headerText = isError ? errorHeaderText : successHeaderText

    return (
      <Grid item>
        <Typography variant='h6'>{headerText}</Typography>
      </Grid>
    )
  }, [isError])

  const defaultIcon = useMemo(
    () => (isError ? <SomethingWentWrong /> : <SuccessIconCircle />),
    [isError]
  )

  const actionBlock = useMemo(
    () => (
      <Condition
        condition={!!isLoading}
        trueContent={
          <Grid container justifyContent='center' mt={-12}>
            <Loader small />
          </Grid>
        }
        falseContent={
          <ActionBlock
            schema={[
              {
                label: buttonText,
                type: 'primary',
                onClick
              }
            ]}
          />
        }
      />
    ),
    [isLoading]
  )
  return (
    <Grid
      container
      m='auto'
      justifyContent='center'
      alignItems='center'
      flexDirection='column'
      rowSpacing={4}
      sx={sx}
    >
      <OperationResult
        header={header || defaultHeader}
        icon={icon || defaultIcon}
        mainText={mainText}
        actionBlock={actionBlock}
      />
    </Grid>
  )
}
