import { FC } from 'react'
import { Grid } from '@mui/material'
import { PageContainer } from '../../layout/PageContainer'
import { AddVehiclePageHeader } from './Header'
import { AddVehiclePreviewPage } from './screens/Preview'
import { FindCarFormStepper } from '../../atoms/FindCarFormStepper'
import { formSteps } from './constants'
import { useFormOrchestrator } from '../../layout/FormOrchestrator/hooks/useFormOrchestrator'
import { FormOrchestrator } from '../../layout/FormOrchestrator'
import { EditNewVehicleDetails } from './screens/EditNewVehicleDetails'
import { FormOrchestratorStep } from '../../layout/FormOrchestrator/types'
import { AddVehicleUploadPhotos } from './screens/AddNewVehicleUploadPhoto'

export const AddVehiclePage: FC = () => {
  const ctx = useFormOrchestrator()

  return (
    <PageContainer>
      <Grid item xs={12} mb={4}>
        <AddVehiclePageHeader step={ctx.step} />
      </Grid>
      <Grid container px={1} flexDirection='column' rowSpacing={4}>
        <Grid item xs={12}>
          <FindCarFormStepper
            steps={formSteps}
            currentStep={ctx.step}
            showAllSteps
          />
        </Grid>
        <Grid item xs={12}>
          <FormOrchestrator
            ctx={ctx}
            steps={[
              EditNewVehicleDetails,
              AddVehiclePreviewPage as unknown as FormOrchestratorStep,
              AddVehicleUploadPhotos
            ]}
          />
        </Grid>
      </Grid>
    </PageContainer>
  )
}
