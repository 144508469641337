import { FC, useCallback } from 'react'
import { Grid } from '@mui/material'
import { useBuildCarPreviewInfo } from './hooks/useBuildCarPreviewInfo'
import { BuildCarPreviewScreen } from './BuildCarPreviewScreen'
import { useAppDispatch } from '../../../utils/hooks'
import { BuildCarPreviewActionBlock } from './components/BuildCarPreviewActionBlock/BuildCarPreviewActionBlock'
import { findCarFormSlice } from '../../../store/units/findCarForm/findCarForm'
import { Show } from '../../../atoms/JSXExtensions'
import { Loader } from '../../../atoms/Loader'

export interface BuildCarPreviewScreenContainerProps {
  onCancel: () => void
  onSubmit: () => void
  isLoading: boolean
  actions: typeof findCarFormSlice.actions
}

export const BuildCarPreviewScreenContainer: FC<
  BuildCarPreviewScreenContainerProps
> = (props) => {
  const { onCancel, onSubmit, isLoading, actions } = props
  const { selectedCars, tradeinVehicle, requestedVehicle, notes } =
    useBuildCarPreviewInfo()
  const dispatch = useAppDispatch()

  const onBackClick = useCallback(() => {
    dispatch(actions.decrementScreenStep())
  }, [dispatch, actions])

  return (
    <Show when={!isLoading} fallback={<Loader />}>
      <Grid
        container
        item
        flexDirection='column'
        justifyContent='space-between'
      >
        <BuildCarPreviewScreen
          selectedCars={selectedCars}
          tradeinVehicle={tradeinVehicle}
          requestedVehicle={requestedVehicle}
          notes={notes}
        />
        <BuildCarPreviewActionBlock
          onNextClick={onSubmit}
          onCancelClick={onCancel}
          onBackClick={onBackClick}
        />
      </Grid>
    </Show>
  )
}
