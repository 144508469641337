import { Utils } from '../../utils'
import {
  ErrorResponse,
  RequestRecord,
  SuccessResponse
} from '../../private.types'
import { ResponseStatusMap } from '../../../types/status'
import { ERROR_REASONS } from '../../../types/enums/network'
import {
  CreateOfferError,
  CreateOfferErrorResponse,
  ICreateOfferParams,
  CreateOfferSuccess,
  CreateOfferSuccessResponse,
  IUpdateOfferParams,
  UpdateOfferError,
  UpdateOfferSuccess
} from './types'

export class Offers {
  protected readonly utils: Utils
  protected readonly operations = {
    createOffer: 'createOffer',
    updateOffer: 'updateOffer',
    cancelOffer: 'cancelOffer',
    declineOffer: 'declineOffer',
    readOffer: 'readOffer'
  }

  constructor(utils: Utils) {
    this.utils = utils
  }

  async createOffer(
    params: ICreateOfferParams
  ): Promise<
    SuccessResponse<CreateOfferSuccess> | ErrorResponse<CreateOfferError>
  > {
    const dataToSend = {
      transaction_id: params.transactionId,
      total_price: params.totalPrice,
      price_components: params.priceComponents,
      add_ons: params.addOns,
      notes: params.notes
    }
    try {
      const { type, payload } = await this.utils.makeJSONRequest<
        CreateOfferSuccessResponse,
        CreateOfferErrorResponse
      >(this.operations.createOffer, dataToSend as RequestRecord)

      if (type === ResponseStatusMap.Success) {
        return {
          status: ResponseStatusMap.Success,
          id: payload.id,
          message: payload.message
        }
      }

      return {
        status: ResponseStatusMap.Error,
        message: payload.message,
        isSubscriptionError:
          payload.reason === ERROR_REASONS.INVALID_SUBSCRIPTION
      }
    } catch (e) {
      return {
        status: ResponseStatusMap.Error,
        message: 'Something went wrong',
        isSubscriptionError: false
      }
    }
  }

  async updateOffer(
    params: IUpdateOfferParams
  ): Promise<
    SuccessResponse<UpdateOfferSuccess> | ErrorResponse<UpdateOfferError>
  > {
    const dataToSend = {
      id: params.id,
      total_price: params.totalPrice,
      price_components: params.priceComponents,
      add_ons: params.addOns,
      notes: params.notes
    }
    try {
      const { type, payload } = await this.utils.makeJSONRequest<
        SuccessResponse<CreateOfferSuccessResponse>,
        ErrorResponse<CreateOfferErrorResponse>
      >(this.operations.updateOffer, dataToSend as RequestRecord)

      if (type === ResponseStatusMap.Success) {
        return {
          status: ResponseStatusMap.Success,
          id: payload.id,
          message: payload.message
        }
      }

      return {
        status: ResponseStatusMap.Error,
        message: payload.message
      }
    } catch (e) {
      return {
        status: ResponseStatusMap.Error,
        message: 'Something went wrong'
      }
    }
  }

  async cancelOffer(
    id: string
  ): Promise<
    | SuccessResponse<{ id: string; message: string }>
    | ErrorResponse<{ id: string; message: string }>
  > {
    type RequestResponse = {
      id: string
      message: string
    }

    const { type, payload } = await this.utils.makeJSONRequest<
      RequestResponse,
      RequestResponse
    >(this.operations.cancelOffer, {
      transaction_id: id
    })

    if (type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        id: payload.id,
        message: payload.message
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: payload.id,
      message: payload.message
    }
  }

  async declineOffer(
    id: string
  ): Promise<
    | SuccessResponse<{ id: string; message: string }>
    | ErrorResponse<{ id: string; message: string }>
  > {
    type RequestResponse = {
      id: string
      message: string
    }

    const { type, payload } = await this.utils.makeJSONRequest<
      RequestResponse,
      RequestResponse
    >(this.operations.declineOffer, {
      transaction_id: id
    })

    if (type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        id: payload.id,
        message: payload.message
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: payload.id,
      message: payload.message
    }
  }
}
