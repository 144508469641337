import { styled } from '@mui/system'

export const FormStepperContainer = styled('div')`
  display: flex;
`

export const FormStep = styled('div', {
  shouldForwardProp: (prop) => prop !== 'stepColor'
})<{ stepColor: string }>`
  width: 100%;
  height: 2px;

  &:not(:last-child) {
    margin-right: 4px;
  }

  background-color: ${({ stepColor }) => stepColor};
`
