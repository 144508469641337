import { Grid, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import {
  FC,
  PropsWithChildren,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState
} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AutoselectColorOption } from '../../atoms/AutoselectColorOption'
import { AutoselectInputField } from '../../atoms/AutoselectInputField'
import { AutoselectOption } from '../../atoms/AutoselectOption'
import { AgreeButton, CancelButton } from '../../atoms/Button'
import { Condition } from '../../atoms/Condition'
import { DependentFieldHocEffect } from '../../atoms/DependentField'
import { Divider } from '../../atoms/Divider'
import { Show } from '../../atoms/JSXExtensions'
import { Loader } from '../../atoms/Loader'
import { NotesField } from '../../atoms/NotesField'
import { NumericField, NumericFieldProps } from '../../atoms/NumericField'
import { RequiredLabel } from '../../atoms/RequiredText'
import { TextInputFieldNew } from '../../atoms/TextInputFieldNew'
import { StateOptions } from '../../constants/applicationConstants'
import { PageContainer } from '../../layout/PageContainer'
import { DiscardConfirmationModal } from '../../molecules/Modal'
import { ColorOption } from '../../types/Autoselect'
import { ReferenceModel } from '../../types/ReferenceModel'
import { ResponseStatusMap, VehicleCondition } from '../../types/status'
import { nbsp } from '../../types/strings'
import { useBlockPath } from '../../utils/NavigationController/hooks/useBlockPath'
import { formatRemoveCurrency } from '../../utils/formatters/formatRemoveCurrency'
import {
  useApiContext,
  useBackPath,
  useMakeService,
  useUserInfo
} from '../../utils/hooks'
import { returnStringOrPropByName } from '../../utils/returnStringOrPropByName'
import { isRequiredFormField } from '../../utils/validators/isRequiredFormField'
import { DependentRetailValueField } from './DependentFields'
import {
  EDIT_VEHICLE_FIELD_LABELS,
  EDIT_VEHICLE_FIELD_NAMES,
  EditVehicleFormFieldsTypes,
  UpdateVehiclePropsType,
  VehicleConditionOptions
} from './constants'
import { useReadVehicle } from './hooks/useReadVehicle'
import { getValidationSchema } from './validationSchema'

const FieldContainer: FC<PropsWithChildren> = ({ children }) => (
  <Grid item xs={12}>
    {children}
  </Grid>
)

export const VehicleEditPage: FC = () => {
  const { isDealer, addresses } = useUserInfo()
  const { vehicleId = '' } = useParams()
  const { backPath } = useBackPath()
  const navigate = useNavigate()
  const { externalCarSnoopApi, carSnoopApi } = useApiContext()
  const {
    isNavigateConfirmationOpen,
    setIsNavigateConfirmationOpen,
    onNavigationConfirm
  } = useBlockPath()

  const [isDiscardConfirmationOpen, setIsDiscardConfirmationOpen] =
    useState(false)

  const [isLoading, currentVehicle, currentVehicleOptions] =
    useReadVehicle(vehicleId)

  const currentVin = useMemo(
    () => currentVehicle?.vin || '',
    [currentVehicle?.vin]
  )

  const [updateValue, updateVehicleObservable] = useMakeService(
    async ({
      licensePlate,
      licensePlateState,
      exteriorColor,
      interiorColor,
      currentMileage,
      condition,
      notes,
      retailValue,
      tradeInValue,
      addressId,
      amountOwed
    }: UpdateVehiclePropsType) => {
      const response = await carSnoopApi.vehicles.updateVehicle({
        id: currentVehicle?.id || '',
        addressId,
        make: currentVehicle?.make || '',
        model: currentVehicle?.model || '',
        year: currentVehicle?.year || 2000,
        trim: currentVehicle?.trim || '',
        vin: currentVehicle?.vin || '',
        mileage: currentMileage,
        condition,
        stockNumber: currentVehicle?.stockNumber || '',
        retailValue,
        tradeInValue,
        fuelType: currentVehicle?.fuelType || '',
        engineDescription: currentVehicle?.engineDescription || '',
        transmission: currentVehicle?.transmission || '',
        mpg: currentVehicle?.mpg || '',
        style: currentVehicle?.style || '',
        exteriorColor,
        interiorColor,
        drivetrain: currentVehicle?.drivetrain || '',
        licensePlate,
        licensePlateState,
        notes,
        amountOwed
      })

      if (response.status === ResponseStatusMap.Success) {
        return {
          status: response.status,
          id: response.id,
          message: response.message
        }
      }

      return response
    },
    { withStatusNotification: true }
  )

  const [readVehicleValue, readVehicleValueObservable] = useMakeService(
    async ({
      mileage,
      condition
    }: {
      mileage: string
      condition: VehicleCondition
    }) => {
      if (currentVin) {
        const response = await externalCarSnoopApi.vehicles.readVehicleValue({
          vin: currentVin,
          mileage,
          condition
        })

        if (response.status === ResponseStatusMap.Success) {
          return {
            status: response.status,
            tradeIn: response.tradeIn,
            retail: response.retail
          }
        }

        return response
      }

      const response = await externalCarSnoopApi.vehicles.readVehicleValue({
        make: currentVehicle?.make,
        model: currentVehicle?.model,
        year: currentVehicle?.year.toString(),
        trim: currentVehicle?.trim,
        mileage,
        condition
      })

      if (response.status === ResponseStatusMap.Success) {
        return {
          status: response.status,
          tradeIn: response.tradeIn,
          retail: response.retail
        }
      }

      return response
    }
  )

  const handleSave = useCallback(
    (values: EditVehicleFormFieldsTypes) => {
      const {
        licensePlate,
        state,
        exteriorColor,
        interiorColor,
        currentMileage,
        condition,
        notes,
        retailValue,
        tradeInValue,
        address,
        howMuchIsOwed
      } = values

      updateValue({
        licensePlate,
        licensePlateState: state?.name || '',
        exteriorColor: returnStringOrPropByName(exteriorColor, 'name'),
        interiorColor: returnStringOrPropByName(interiorColor, 'name'),
        currentMileage: Number(currentMileage.split(',').join('')),
        condition: condition.id,
        notes,
        retailValue: formatRemoveCurrency(retailValue),
        tradeInValue: formatRemoveCurrency(tradeInValue),
        addressId: address?.id || '',
        amountOwed: formatRemoveCurrency(howMuchIsOwed)
      })
    },
    [currentVehicle]
  )

  const getOptionLabel = useCallback(
    (option: ReferenceModel) => option.name,
    []
  )

  const isOptionEqualToValue = useCallback(
    (option: ReferenceModel, value: ReferenceModel) => option.id === value.id,
    []
  )

  const isOldCar = useMemo(() => {
    return !currentVehicle?.vin
  }, [currentVehicle])

  const hasLicensePlate = useMemo(
    () => !!currentVehicle?.licensePlate && !!currentVehicle?.licensePlateState,
    [currentVehicle]
  )

  const hasExteriorColorOptions = !!currentVehicleOptions?.extColors.length
  const hasInteriorColorOptions = !!currentVehicleOptions?.intColors.length

  const validationSchema = useMemo(
    () =>
      getValidationSchema(
        hasLicensePlate,
        hasExteriorColorOptions,
        hasInteriorColorOptions
      ),
    [
      getValidationSchema,
      hasLicensePlate,
      hasExteriorColorOptions,
      hasInteriorColorOptions
    ]
  )

  const addressesOptions: ReferenceModel[] = useMemo(
    () =>
      addresses.map<ReferenceModel>((a, index) => {
        const { id, nickname, street, city, state, zipCode } = a
        const addressName: string = `${nickname || `Home ${index + 1}`} (${street ? `${street}, ` : ''
          }${zipCode}, ${city}, ${state})`
        return { id, name: addressName }
      }),
    [addresses]
  )

  const handleCancelClick = useCallback(() => {
    setIsDiscardConfirmationOpen(true)
  }, [setIsDiscardConfirmationOpen])

  const handleDiscard = useCallback(() => {
    navigate(backPath, { replace: true })
  }, [])

  const retailValueFieldEffect: DependentFieldHocEffect<
    NumericFieldProps,
    EditVehicleFormFieldsTypes
  > = useCallback(
    async (_, ctx) => {
      const { values, setFieldValue, touched, setFieldTouched } = ctx
      const {
        currentMileage: mileage,
        condition: { id: conditionName }
      } = values

      setFieldTouched(EDIT_VEHICLE_FIELD_NAMES.CURRENT_MILEAGE)
      setFieldTouched(EDIT_VEHICLE_FIELD_NAMES.CONDITION)

      if (
        (currentVehicle?.mileage && touched.currentMileage) ||
        (currentVehicle?.condition && touched.condition)
      ) {
        const result = await readVehicleValue({
          mileage,
          condition: conditionName as VehicleCondition
        })

        if (result.status === 'success') {
          setFieldValue(
            EDIT_VEHICLE_FIELD_NAMES.RETAIL_VALUE,
            result.retail.toString()
          )
          setFieldValue(
            EDIT_VEHICLE_FIELD_NAMES.TRADE_IN_VALUE,
            result.tradeIn.toString()
          )
        }

        if (result.status === 'error') {
          setFieldValue(EDIT_VEHICLE_FIELD_NAMES.RETAIL_VALUE, '0.00')
          setFieldValue(EDIT_VEHICLE_FIELD_NAMES.TRADE_IN_VALUE, '0.00')
        }
      }
    },
    [
      readVehicleValue,
      currentVin,
      currentVehicle?.mileage,
      currentVehicle?.condition
    ]
  )

  useLayoutEffect(() => {
    if (updateVehicleObservable.status === 'succeeded') {
      navigate(backPath, { replace: true })
    }
  }, [updateVehicleObservable.status])
  const vehicleName = useMemo(() => {
    return `${currentVehicle?.year} ${currentVehicle?.make} ${currentVehicle?.model} ${currentVehicle?.trim}`.trim()
  }, [currentVehicle])

  const isRequiredField = isRequiredFormField(validationSchema)

  return (
    <PageContainer>
      <Condition
        condition={
          updateVehicleObservable.isLoading || !currentVehicle || isLoading
        }
        trueContent={
          <Grid container justifyContent='center'>
            <Loader />
          </Grid>
        }
        falseContent={
          <Grid container spacing={3}>
            <DiscardConfirmationModal
              isOpen={isDiscardConfirmationOpen}
              setOpen={setIsDiscardConfirmationOpen}
              onSubmit={handleDiscard}
            />
            <DiscardConfirmationModal
              isOpen={isNavigateConfirmationOpen}
              setOpen={setIsNavigateConfirmationOpen}
              onSubmit={onNavigationConfirm}
            />
            <Grid item xs={12}>
              <Typography component='h1' variant='title1'>{vehicleName}</Typography>
              <RequiredLabel />
            </Grid>
            <Grid container item>
              <Formik<EditVehicleFormFieldsTypes>
                initialValues={{
                  address:
                    addressesOptions.find(
                      (a) => a.id === currentVehicle?.addressID
                    ) || null,
                  licensePlate: currentVehicle?.licensePlate || '',
                  state:
                    StateOptions.find(
                      (s) => s.id === currentVehicle?.licensePlateState
                    ) || StateOptions[0],
                  exteriorColor:
                    currentVehicleOptions?.extColors.find(
                      (c) => c.name === currentVehicle?.exteriorColor
                    ) ||
                    currentVehicle?.exteriorColor ||
                    '',
                  interiorColor:
                    currentVehicleOptions?.intColors.find(
                      (c) => c.name === currentVehicle?.interiorColor
                    ) ||
                    currentVehicle?.interiorColor ||
                    '',
                  currentMileage: `${currentVehicle?.mileage || '0.00'}`,
                  condition:
                    VehicleConditionOptions.find(
                      (c) => c.id === currentVehicle?.condition
                    ) || VehicleConditionOptions[0],
                  howMuchIsOwed: `${currentVehicle?.amountOwed || '0.00'}`,
                  notes: currentVehicle?.notes || '',
                  tradeInValue: `${currentVehicle?.tradeinValue || '0.00'}`,
                  retailValue: `${currentVehicle?.retailValue || '0.00'}`
                }}
                validationSchema={validationSchema}
                onSubmit={handleSave}
                validateOnMount
                validateOnChange={false}
              >
                {(formik) => {
                  return (
                    <Form style={{ width: '100%' }}>
                      <Grid container item spacing={3} xs={12}>
                        <Grid
                          container
                          item
                          sm={12}
                          lg={6}
                          spacing={2}
                          alignContent='flex-start'
                        >
                          <Condition
                            condition={!isDealer}
                            trueContent={
                              <FieldContainer>
                                <AutoselectInputField<ReferenceModel>
                                  name={EDIT_VEHICLE_FIELD_NAMES.ADDRESS}
                                  label={EDIT_VEHICLE_FIELD_LABELS.ADDRESS}
                                  renderOption={AutoselectOption}
                                  options={addressesOptions}
                                  getOptionLabel={getOptionLabel}
                                  isOptionEqualToValue={isOptionEqualToValue}
                                  required={isRequiredField}
                                />
                              </FieldContainer>
                            }
                          />
                          <Condition
                            condition={hasLicensePlate}
                            trueContent={
                              <>
                                <FieldContainer>
                                  <TextInputFieldNew
                                    name={
                                      EDIT_VEHICLE_FIELD_NAMES.LICENSE_PLATE
                                    }
                                    label={
                                      EDIT_VEHICLE_FIELD_LABELS.LICENSE_PLATE
                                    }
                                    required={isRequiredField}
                                  />
                                </FieldContainer>

                                <FieldContainer>
                                  <AutoselectInputField<ReferenceModel>
                                    name={EDIT_VEHICLE_FIELD_NAMES.STATE}
                                    label={EDIT_VEHICLE_FIELD_LABELS.STATE}
                                    renderOption={AutoselectOption}
                                    options={StateOptions}
                                    getOptionLabel={getOptionLabel}
                                    isOptionEqualToValue={isOptionEqualToValue}
                                    required={isRequiredField}
                                  />
                                </FieldContainer>
                              </>
                            }
                          />
                          <Condition
                            condition={!!currentVehicle?.vin}
                            trueContent={
                              <FieldContainer>
                                <Grid container justifyContent='flex-end'>
                                  <Typography variant='subtitle1'>
                                    VIN:{nbsp}
                                  </Typography>
                                  <Typography variant='main' fontWeight={500}>
                                    {currentVehicle?.vin}
                                  </Typography>
                                </Grid>
                              </FieldContainer>
                            }
                          />
                          <FieldContainer>
                            <Show
                              when={hasExteriorColorOptions}
                              fallback={
                                <TextInputFieldNew
                                  name={EDIT_VEHICLE_FIELD_NAMES.EXTERIOR_COLOR}
                                  label={
                                    EDIT_VEHICLE_FIELD_NAMES.EXTERIOR_COLOR
                                  }
                                  required={isRequiredField}
                                />
                              }
                            >
                              <AutoselectInputField<ColorOption>
                                name={EDIT_VEHICLE_FIELD_NAMES.EXTERIOR_COLOR}
                                label={EDIT_VEHICLE_FIELD_LABELS.EXTERIOR_COLOR}
                                renderOption={AutoselectColorOption}
                                options={currentVehicleOptions?.extColors || []}
                                getOptionLabel={getOptionLabel}
                                isOptionEqualToValue={isOptionEqualToValue}
                                required={isRequiredField}
                              />
                            </Show>
                          </FieldContainer>

                          <FieldContainer>
                            <Show
                              when={hasInteriorColorOptions}
                              fallback={
                                <TextInputFieldNew
                                  name={EDIT_VEHICLE_FIELD_NAMES.INTERIOR_COLOR}
                                  label={
                                    EDIT_VEHICLE_FIELD_NAMES.INTERIOR_COLOR
                                  }
                                  required={isRequiredField}
                                />
                              }
                            >
                              <AutoselectInputField<ColorOption>
                                name={EDIT_VEHICLE_FIELD_NAMES.INTERIOR_COLOR}
                                label={EDIT_VEHICLE_FIELD_LABELS.INTERIOR_COLOR}
                                renderOption={AutoselectColorOption}
                                options={currentVehicleOptions?.intColors || []}
                                getOptionLabel={getOptionLabel}
                                isOptionEqualToValue={isOptionEqualToValue}
                                required={isRequiredField}
                              />
                            </Show>
                          </FieldContainer>

                          <Condition
                            condition={!!isOldCar}
                            trueContent={
                              <FieldContainer>
                                <NumericField
                                  name={
                                    EDIT_VEHICLE_FIELD_NAMES.CURRENT_MILEAGE
                                  }
                                  label={
                                    EDIT_VEHICLE_FIELD_LABELS.CURRENT_MILEAGE
                                  }
                                  required={isRequiredField}
                                />
                              </FieldContainer>
                            }
                          />
                        </Grid>
                        <Grid
                          container
                          item
                          sm={12}
                          lg={6}
                          spacing={2}
                          alignContent='flex-start'
                        >
                          <Condition
                            condition={!isOldCar}
                            trueContent={
                              <FieldContainer>
                                <NumericField
                                  name={
                                    EDIT_VEHICLE_FIELD_NAMES.CURRENT_MILEAGE
                                  }
                                  label={
                                    EDIT_VEHICLE_FIELD_LABELS.CURRENT_MILEAGE
                                  }
                                  required={isRequiredField}
                                />
                              </FieldContainer>
                            }
                          />
                          <FieldContainer>
                            <AutoselectInputField<ReferenceModel>
                              name={EDIT_VEHICLE_FIELD_NAMES.CONDITION}
                              label={EDIT_VEHICLE_FIELD_LABELS.CONDITION}
                              renderOption={AutoselectOption}
                              options={VehicleConditionOptions}
                              getOptionLabel={getOptionLabel}
                              isOptionEqualToValue={isOptionEqualToValue}
                              required={isRequiredField}
                            />
                          </FieldContainer>
                          <FieldContainer>
                            <NumericField
                              startWith='$'
                              name={EDIT_VEHICLE_FIELD_NAMES.HOW_MUCH_IS_OWED}
                              label={EDIT_VEHICLE_FIELD_LABELS.HOW_MUCH_IS_OWED}
                              required={isRequiredField}
                            />
                          </FieldContainer>
                          <FieldContainer>
                            <NotesField
                              name={EDIT_VEHICLE_FIELD_NAMES.NOTES}
                              label={EDIT_VEHICLE_FIELD_LABELS.NOTES}
                              required={isRequiredField}
                            />
                          </FieldContainer>
                        </Grid>
                        <Grid container item>
                          <Divider />
                        </Grid>
                        <Condition
                          condition={!isOldCar}
                          trueContent={
                            <Grid container item>
                              <Typography variant='details' fontStyle='italic'>
                                Estimated market values are based on the data
                                you have provided. Change it if needed.
                              </Typography>
                            </Grid>
                          }
                        />

                        <Grid container item spacing={3}>
                          <Grid item sm={12} lg={6}>
                            <FieldContainer>
                              <DependentRetailValueField
                                startWith='$'
                                name={EDIT_VEHICLE_FIELD_NAMES.RETAIL_VALUE}
                                label={EDIT_VEHICLE_FIELD_LABELS.RETAIL_VALUE}
                                onChangeEffect={retailValueFieldEffect}
                                required={isRequiredField}
                              />
                            </FieldContainer>
                          </Grid>
                          <Grid item sm={12} lg={6}>
                            <FieldContainer>
                              <NumericField
                                startWith='$'
                                name={EDIT_VEHICLE_FIELD_NAMES.TRADE_IN_VALUE}
                                label={EDIT_VEHICLE_FIELD_LABELS.TRADE_IN_VALUE}
                                required={isRequiredField}
                              />
                            </FieldContainer>
                          </Grid>
                        </Grid>
                        <Grid container item>
                          <Divider />
                        </Grid>
                        <Grid
                          container
                          item
                          justifyContent='flex-end'
                          spacing={2}
                        >
                          <Grid item>
                            <CancelButton onClick={handleCancelClick}>
                              CANCEL
                            </CancelButton>
                          </Grid>
                          <Grid item>
                            <AgreeButton
                              type='submit'
                              disabled={
                                !(formik.dirty && formik.isValid) ||
                                readVehicleValueObservable.isLoading
                              }
                            >
                              SAVE
                            </AgreeButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Form>
                  )
                }}
              </Formik>
            </Grid>
          </Grid>
        }
      />
    </PageContainer>
  )
}
