import { createContext } from 'react'
import {
  CarSnoopApi,
  ExternalCarSnoopApi,
  NotificationsApi,
  ChatApi
} from './api'
import { noop } from './utils'

export class ApiContextCreator {
  carSnoopApi: CarSnoopApi
  externalCarSnoopApi: ExternalCarSnoopApi
  notificationsApi: NotificationsApi
  chatApi: ChatApi
  readonly logoutCallback: () => void

  constructor(logoutCallback: () => void) {
    this.carSnoopApi = new CarSnoopApi({ logoutCallback })
    this.externalCarSnoopApi = new ExternalCarSnoopApi({ logoutCallback })
    this.notificationsApi = new NotificationsApi({
      logoutCallback
    })
    this.chatApi = new ChatApi({ logoutCallback })
    this.logoutCallback = logoutCallback
  }

  setSession = (session: string) => {
    this.carSnoopApi.utils.setSession(session)
    this.externalCarSnoopApi.utils.setSession(session)
    this.notificationsApi.utils.setSession(session)
    this.chatApi.utils.setSession(session)
  }
}

export const apiContextValues = new ApiContextCreator(noop)

export const ApiContext = createContext(apiContextValues)
