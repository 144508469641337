import type { UserInfoState } from '../store/types'
import { objectKeys } from './objectKeys'

type LocalStorageKeys = 'userSession'
type UserSessionType = { id: string; sessionID: string }

export class LocalStorageDatabase {
  private readonly storage: Storage
  constructor() {
    this.storage = localStorage
  }

  read(key: LocalStorageKeys): UserSessionType | void {
    const data = JSON.parse(this.storage.getItem(key) || '0')

    if (data) {
      return data
    }
  }

  private write(key: LocalStorageKeys, data: Record<string, unknown>) {
    this.storage.setItem(key, JSON.stringify(data))
  }

  writeUserSession(options: UserSessionType) {
    this.write('userSession', {
      id: options.id,
      sessionID: options.sessionID
    })
  }

  resetUserSession() {
    this.write('userSession', {})
  }
}

export const syncUserInfoStorages = (
  userInfo: UserInfoState
): UserSessionType | void => {
  const localStorage = new LocalStorageDatabase()
  const userInfoStorage = localStorage.read('userSession')

  if (!userInfo.sessionId) {
    if (userInfoStorage && objectKeys(userInfoStorage).length) {
      return userInfoStorage
    }
  } else {
    localStorage.writeUserSession({
      id: userInfo.id,
      sessionID: userInfo.sessionId
    })
  }
}
