import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  NormalizedReadTransactionsList,
  NormalizedDealsListType
} from '../../../api/carSnoopApi/transactions/types'
import { splitByProperty } from '../../../utils/splitByProperty'
import { requestTabNavigation } from '../../thunks/pageNavigation'
import { inProgressSelectOptionsList, inProgressSplitSchema } from './constants'

export type SelectedInProgressStatus =
  typeof inProgressSelectOptionsList[number]

export type InProgressDealsListMap = Record<
  SelectedInProgressStatus,
  NormalizedReadTransactionsList
>

export const getDealsListMap = (dealsList: NormalizedDealsListType) => {
  const deals = dealsList || []
  return splitByProperty(deals, inProgressSplitSchema).reduce(
    (acc, dealList, index) => {
      return Object.assign(acc, {
        [inProgressSplitSchema.status[index]]: dealList
      })
    },
    { All: dealsList } as InProgressDealsListMap
  )
}

const getMatchedDealsList = (deals: NormalizedDealsListType) => {
  if (deals) {
    return deals.filter((item) =>
      inProgressSelectOptionsList.includes(item.status)
    )
  }

  return deals
}
interface InProgressDealsState {
  selectedStatus: SelectedInProgressStatus
  selectOptions: readonly SelectedInProgressStatus[]
  dealsList: NormalizedDealsListType
  dealsListMap: InProgressDealsListMap
  displayList: NormalizedDealsListType
  searchFormValues: {
    search: string
  }
}

export const initialState: InProgressDealsState = {
  selectedStatus: 'All',
  selectOptions: inProgressSelectOptionsList,
  dealsListMap: getDealsListMap(null),
  dealsList: null,
  displayList: null,
  searchFormValues: {
    search: ''
  }
}

export const inProgressDealsPageSlice = createSlice({
  name: 'inProgressDealsPage',
  initialState,
  reducers: {
    setDealsList: (store, action: PayloadAction<NormalizedDealsListType>) => {
      const deals = getMatchedDealsList(action.payload)
      store.dealsList = deals
      store.displayList = deals
      store.dealsListMap = getDealsListMap(deals)
    },
    setSelectResults: (
      store,
      action: PayloadAction<{
        data: NormalizedDealsListType
        status: SelectedInProgressStatus
      }>
    ) => {
      store.selectedStatus = action.payload.status
      store.displayList = getMatchedDealsList(action.payload.data)
    },
    setSearchFormValues: (
      store,
      action: PayloadAction<InProgressDealsState['searchFormValues']>
    ) => {
      store.searchFormValues = action.payload
    },
    setInProgressDealsStatus: (
      store,
      actions: PayloadAction<SelectedInProgressStatus>
    ) => {
      store.selectedStatus = actions.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(requestTabNavigation.fulfilled, () => {
      return {
        ...initialState
      }
    })
  }
})
