import { Utils } from '../../utils'
import { ResponseStatusMap } from '../../../types/status'
import { GetSynchronizedDataTypes } from './types'
import { synchronizedDataNormalizer } from './utils/synchronizedDataNormalizer'

export class Duty {
  protected readonly utils: Utils
  protected readonly operations = {
    getSynchronizedData: 'getSynchronizedData'
  }

  constructor(utils: Utils) {
    this.utils = utils
  }

  async getSynchronizedData(): Promise<
    | GetSynchronizedDataTypes.ServiceSuccessResponse
    | GetSynchronizedDataTypes.ServiceErrorResponse
  > {
    const response = await this.utils.makeJSONRequest<
      GetSynchronizedDataTypes.ApiSuccessRawResponse,
      GetSynchronizedDataTypes.ApiErrorResponse
    >(this.operations.getSynchronizedData)

    if (response.type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        ...synchronizedDataNormalizer(response.payload)
      }
    }

    return {
      status: ResponseStatusMap.Error,
      message: response.payload.message
    }
  }
}
