import * as Yup from 'yup'
import { isValidEmail, latinWithSpecial } from '../../../../utils/validators'
import { ERRORS } from './types'

export const validationSchema = Yup.object({
  emails: Yup.array().of(
    Yup.string()
      .test('is-email', ERRORS.NOT_VALID, isValidEmail(false))
      .test(
        'is-latin-or-special',
        'Enter a valid email address.',
        latinWithSpecial()
      )
  )
})
