import { FILE_EXTENSIONS } from '../../../constants/applicationConstants'
import {
  DOCUMENT_AUDIO_SIZE_ERROR,
  FILE_VALIDATION_CONFIG
} from '../UploadFileComponent.constants'

export const validateFileSize = (
  extension: string | FILE_EXTENSIONS,
  size: number
) => {
  const validationConfig =
    FILE_VALIDATION_CONFIG[extension as FILE_EXTENSIONS] ||
    DOCUMENT_AUDIO_SIZE_ERROR

  return size >= validationConfig.maxSize * 1024
    ? validationConfig.errorText
    : ''
}
