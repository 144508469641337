import { FC, useMemo, useEffect } from 'react'
import { Grid } from '@mui/material'
import usePagination from '@mui/material/usePagination'
import { PaginationItem } from './PaginationItem'

interface PaginationProps {
  count: number
  onChange: (page: number) => void
}

export const Pagination: FC<PaginationProps> = ({ count, onChange }) => {
  const { items } = usePagination({ count })

  const activePage = useMemo(
    () => items.find((item) => item.selected)?.page,
    [items]
  )

  useEffect(() => {
    if (activePage) {
      onChange(activePage)
    }
  }, [activePage])

  return (
    <Grid container alignItems='center' justifyContent='end' marginTop={2}>
      {items.map((item, index) => (
        <Grid item key={index} sx={{ m: '2px' }}>
          <PaginationItem item={item} />
        </Grid>
      ))}
    </Grid>
  )
}
