import { Grid } from '@mui/material'
import { FC } from 'react'
import { AddShipmentDetailsParamsAddress } from '../../../../api/carSnoopApi/shipments/types'
import { DeliveryCard } from '../../../../features/DeliveryCard'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

const mockPickUp: AddShipmentDetailsParamsAddress = {
  addressName: 'Home',
  city: 'New York',
  state: 'New York',
  street: 'Delivery str',
  zipCode: '1234',
  contactEmail: 'contact@me.us',
  contactName: 'Leo',
  contactNumber: '+1765944345',
  notes: 'abc'
}

const mockCarInfo = {
  title: 'VIN',
  info: '231234123211'
}

export const DeliveryCardExample: FC = () => {
  return (
    <ComponentContainer fullWidth>
      <ComponentWithPaperBackground>
        <Grid container justifyContent='center'>
          <DeliveryCard
            cardTitle='Vehicle delivery'
            dropOff={mockPickUp}
            pickup={mockPickUp}
            carInfo={mockCarInfo}
          />
        </Grid>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
