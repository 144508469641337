import { SxProps, TextField, TextFieldProps } from '@mui/material'
import { FC, ReactNode, forwardRef, useMemo } from 'react'
import { InputError } from '../../assets/icons/InputError'
import { COLORS } from '../../types/colors'
import { useDeviceInfo } from '../../utils/hooks/useDeviceInfo'
import { Spinner } from '../Spinner'

const transformStyle = 'translate(14px, 5px) scale(0.8)'

const getStyles = ({
  hasMultiValue,
  isMobileDevice,
  isMutliline = false,
  borderError = false,
  textColor = '',
  backgroundColor = '',
  required = false
}: {
  hasMultiValue?: boolean
  isMobileDevice: boolean
  isMutliline?: boolean
  borderError?: boolean
  textColor?: string
  backgroundColor?: string
  required?: boolean
}): SxProps => ({
  width: '100%',
  '.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary': {
    paddingRight: isMutliline ? 'initial' : '32px',
    '& textarea': {
      paddingRight: isMutliline ? '64px' : 'initial'
    }
  },
  '.MuiInputBase-root.Mui-error .MuiInputBase-input': {
    '& + svg': {
      position: 'absolute',
      right: isMutliline ? '24px' : '18px'
    }
  },
  '& .MuiOutlinedInput-root': {
    whiteSpace: 'break-spaces',
    flexWrap: 'nowrap',
    '& > svg': {
      alignSelf: isMutliline ? 'flex-start' : 'inherit'
    }
  },
  '& .MuiFormHelperText-root': {
    margin: '4px 0 0 0',
    fontSize: '14px',
    lineHeight: '14px'
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: COLORS.text_red
  },
  '& .MuiInputLabel-root': {
    fontSize: '16px',
    color: COLORS.text_secondary,
    left: '8px',
    top: '3px',
    transform: hasMultiValue ? transformStyle : '',
    paddingRight: '28px',
    '&:after': {
      content: required ? "' *'" : "''",
      color: COLORS.text_red
    }
  },

  '& .MuiInputLabel-root.Mui-error': {
    color: COLORS.text_secondary
  },

  '& .MuiInputLabel-root.Mui-focused': {
    color: COLORS.text_blue,
    transform: transformStyle
  },

  '& .MuiInputLabel-root.Mui-disabled': {
    color: COLORS.disabled
  },

  '& .MuiInputLabel-root.MuiFormLabel-filled': {
    transform: transformStyle
  },
  '& .MuiInputBase-root': {
    minHeight: '60px',
    padding: '15px 22px',
    background: backgroundColor || '',

    '& .MuiAutocomplete-tag': {
      position: 'relative',
      top: '10px'
    },

    '& .MuiChip-root': {
      top: '10px',
      position: 'relative'
    },

    '& .MuiInputBase-input': {
      color: textColor || COLORS.text_primary,
      padding: '0px',
      top: '10px',
      position: 'relative',
      textOverflow: 'ellipsis',
      minWidth: '4px',
      '&::placeholder': {
        color: COLORS.text_secondary,
        opacity: '1'
      }
    },
    '& fieldset': {
      borderColor: COLORS.border_gray,
      top: '0',
      '& legend': {
        display: 'none'
      }
    }
  },

  '& .MuiInputBase-root:hover': {
    '& fieldset': {
      borderColor: isMobileDevice
        ? COLORS.border_gray
        : COLORS.text_blue
    }
  },

  '& .MuiInputBase-root.Mui-error': {
    '& .MuiInputBase-input': {
      color: COLORS.text_red
    },
    '& fieldset': {
      borderColor: COLORS.text_red
    }
  },

  '& .MuiInputBase-root.Mui-focused': {
    '& .MuiInputBase-input': {
      color: textColor || COLORS.text_primary
    },
    '& fieldset': {
      borderColor: borderError
        ? COLORS.text_red
        : COLORS.text_blue,
      borderWidth: '1px'
    }
  },

  '& .MuiInputBase-root.Mui-disabled p': { color: 'inherit' },

  '& .MuiInputBase-root.Mui-disabled': {
    backgroundColor: COLORS.secondary_background,
    '& fieldset': {
      borderColor: COLORS.secondary_background
    }
  }
})

export type InputFieldProps = {
  isLoading?: boolean
  hasMultiValue?: boolean
  endAdornment?: ReactNode
  borderError?: boolean
  textColor?: string
  backgroundColor?: string
} & TextFieldProps

export const InputField: FC<InputFieldProps> = forwardRef((props, ref) => {
  const {
    hasMultiValue,
    endAdornment,
    isLoading,
    helperText,
    borderError,
    textColor,
    backgroundColor,
    required,
    ...fieldProps
  } = props
  const { isIOS, isAndroid } = useDeviceInfo()

  const styles = useMemo(() => {
    const isMobileDevice = isIOS || isAndroid
    return getStyles({
      hasMultiValue,
      isMobileDevice,
      isMutliline: fieldProps.multiline,
      borderError,
      textColor,
      backgroundColor,
      required
    })
  }, [hasMultiValue, isIOS, isAndroid, borderError])

  const trimmedHelperText = useMemo(
    () => (typeof helperText === 'string' ? helperText?.trim() : helperText),
    [helperText]
  )

  return (
    <TextField
      sx={styles}
      helperText={trimmedHelperText}
      {...fieldProps}
      ref={ref}
      InputProps={{
        ...fieldProps.InputProps,
        endAdornment:
          (fieldProps.error && <InputError />) ||
          (isLoading && (
            <Spinner sx={{ position: 'absolute', right: '16px' }} />
          )) ||
          endAdornment ||
          fieldProps.InputProps?.endAdornment
      }}
    />
  )
})
