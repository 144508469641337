import { OutlinedInput, styled } from '@mui/material'
import { COLORS } from '../../types/colors'

export const Input = styled(OutlinedInput)({
  '& .MuiInputBase-input': {
    color: COLORS.text_primary
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: COLORS.border_gray
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: COLORS.border_gray
  },
  '& .MuiSvgIcon-root': {
    color: COLORS.text_gray
  },
  '&.Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#333',
      borderWidth: '1px'
    },
    '& .MuiSvgIcon-root': {
      color: COLORS.text_primary
    },
    '&.Mui-error': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#333'
      },
      '& .MuiInputBase-input': {
        color: COLORS.text_primary
      },
      '& .MuiSvgIcon-root': {
        color: COLORS.text_primary
      }
    }
  },
  '&.Mui-error': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: COLORS.text_red
    },
    '& .MuiSvgIcon-root': {
      color: COLORS.text_red
    },
    '& .MuiInputBase-input': {
      color: COLORS.text_red
    }
  }
})
