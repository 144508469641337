import { useEffect, useState } from 'react'
import { useApiContext, useMakeService, useUserInfo } from './index'
import { ResponseStatusMap } from '../../types/status'
import { NormalizedTransactionType } from '../../api/carSnoopApi/transactions/types'
import { OrNull } from '../../types/OrNull'

export const useReadTransaction = ({
  transactionId,
  initialTransactionData = null
}: {
  transactionId: string
  initialTransactionData?: OrNull<NormalizedTransactionType>
}) => {
  const userInfo = useUserInfo()
  const { carSnoopApi } = useApiContext()

  const [transaction, setTransaction] = useState<
    OrNull<NormalizedTransactionType>
  >(initialTransactionData)

  const [readTransaction, readTransactionObservable] = useMakeService(
    async (id: string) => {
      setTransaction(null)
      const response = await carSnoopApi.transactions.readTransaction(id)

      if (response.status === ResponseStatusMap.Success) {
        setTransaction(response.data)
      }

      return response
    }
  )

  useEffect(() => {
    if (userInfo.accountID && transactionId && !initialTransactionData) {
      readTransaction(transactionId)
    }
  }, [transactionId, userInfo.accountID, initialTransactionData])

  return {
    isLoading: readTransactionObservable.isLoading,
    data: transaction,
    setTransaction,
    readTransaction
  }
}
