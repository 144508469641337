import { NormalizedContractType } from '../../../../api/carSnoopApi/transactions/types/contract/normalized'
import { UpdateContractParams } from '../../../../api/carSnoopApi/contracts/types'
import { SellerFormFieldsTypes } from './components/SellerDetails/components/EditMode/types'
import { BuyerFormFieldsTypes } from './components/BuyerDetails/components/EditMode/types'
import { NormalizedTransactionType } from '../../../../api/carSnoopApi/transactions/types'
import { INTERNAL_SHIPMENT_TYPE } from '../../../../constants/applicationConstants'
import { OrNull } from '../../../../types/OrNull'

export const extractUpdateSellerContractParams = (
  contract: NormalizedContractType,
  formData: SellerFormFieldsTypes
): UpdateContractParams => {
  const {
    sellerNotes,
    sellerLegalName,
    sellerLegalAddress,
    sellerCity,
    sellerState,
    sellerZipCode
  } = formData

  const params: UpdateContractParams = {
    id: contract.id,
    notes: sellerNotes,
    sellerDetails: {
      legalName: sellerLegalName,
      legalStreet: sellerLegalAddress,
      legalCity: sellerCity,
      legalState: sellerState,
      legalZipCode: sellerZipCode
    }
  }

  return params
}

export const extractUpdateBuyerContractParams = (
  contract: NormalizedContractType,
  formData: BuyerFormFieldsTypes
): UpdateContractParams => {
  const {
    buyerLegalName,
    buyerLegalAddress,
    buyerCity,
    buyerState,
    buyerZipCode,
    coBuyerLegalName,
    coBuyerLegalAddress,
    coBuyerCity,
    coBuyerState,
    coBuyerZipCode
  } = formData

  const additionalBuyerDetails = contract.additionalBuyer
    ? {
        legalName: coBuyerLegalName,
        legalStreet: coBuyerLegalAddress,
        legalCity: coBuyerCity,
        legalState: coBuyerState,
        legalZipCode: coBuyerZipCode
      }
    : null

  const params: UpdateContractParams = {
    id: contract.id,
    additionalBuyerDetails,
    buyerDetails: {
      legalName: buyerLegalName,
      legalStreet: buyerLegalAddress,
      legalCity: buyerCity,
      legalState: buyerState,
      legalZipCode: buyerZipCode
    }
  }

  return params
}

export const arePaymentsPaid = (
  payments: NormalizedTransactionType['payments']
) => {
  // It means that we have only "Deposit" so other paymnets haven't been created
  // [].filter(...).every(...) doesn't work because .every returns true for empty array (PAIN!)
  if (payments?.length === 1 && payments[0].type === 'Deposit') {
    return false
  }

  return !!payments?.every((p) => p.status === 'Paid')
}

export const isDeliveryLocal = (contract: OrNull<NormalizedContractType>) =>
  contract?.shipmentType === INTERNAL_SHIPMENT_TYPE.local
