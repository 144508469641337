import { Grid, Typography } from '@mui/material'
import { Form, Formik, FormikValues } from 'formik'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { InformationIcon } from '../../../../../assets/icons/Information'
import { AutoselectColorOption } from '../../../../../atoms/AutoselectColorOption'
import { AutoselectInputField } from '../../../../../atoms/AutoselectInputField'
import { AutoselectOption } from '../../../../../atoms/AutoselectOption'
import { AgreeButton, CancelButton } from '../../../../../atoms/Button'
import { Condition } from '../../../../../atoms/Condition'
import { DependentFieldHocEffect } from '../../../../../atoms/DependentField'
import { Divider } from '../../../../../atoms/Divider'
import { Show } from '../../../../../atoms/JSXExtensions'
import { NotesField } from '../../../../../atoms/NotesField'
import {
  NumericField,
  NumericFieldProps
} from '../../../../../atoms/NumericField'
import {
  TextInputFieldNew,
  TextInputFieldProps
} from '../../../../../atoms/TextInputFieldNew'
import { FormOrchestratorStepProps } from '../../../../../layout/FormOrchestrator/types'
import {
  defaultColorOption,
  selectFindCarFormExteriorColorsOptions,
  selectFindCarFormInteriorColorsOptions,
  selectFindCarFormMakeOptions,
  selectFindCarFormModelOptions,
  selectFindCarFormStandardEquipment,
  selectFindCarFormTrimOptions,
  selectFindCarFormYearFromOptions,
  selectIsReadCarModelYearTrimLoading
} from '../../../../../store/units/findCarForm'
import {
  readCarModelYearTrimFromVehicles,
  readMakersFromVehicles,
  readModelsFromVehicles,
  readVehicleTrimByYearFromVehicles,
  readYearsFromVehicles
} from '../../../../../store/units/findCarForm/thunks'
import { Option } from '../../../../../types/Autoselect'
import { ReferenceModel } from '../../../../../types/ReferenceModel'
import {
  ResponseStatusMap,
  VehicleCondition
} from '../../../../../types/status'
import { getOptionLabel } from '../../../../../utils/autoselect/getOptionLabel'
import { isOptionEqualToValue } from '../../../../../utils/autoselect/isOptionEqualToValue'
import { stringToReferenceModel } from '../../../../../utils/autoselect/stringToReferenceModel'
import {
  useApiContext,
  useAppDispatch,
  useAppSelector,
  useMakeService,
  useUserInfo
} from '../../../../../utils/hooks'
import { objectKeys } from '../../../../../utils/objectKeys'
import { isRequiredFormField } from '../../../../../utils/validators/isRequiredFormField'
import { VehicleConditionOptions } from '../../../../VehicleEdit/constants'
import {
  ADD_VEHICLE_FIELD_LABELS,
  ADD_VEHICLE_FIELD_NAMES,
  AddOldVehicleFormFieldsTypes,
  AddressOption
} from '../../../constants'
import { getValidationSchema } from '../../../validationSchema'
import {
  DependentAutoselectExteriorField,
  DependentAutoselectInteriorField,
  DependentModelField,
  OldCarDependentRetailValueField as DependentRetailValueField,
  DependentTextExteriorField,
  DependentTextInteriorField,
  OldCarDependentTradeinValueField as DependentTradeinValueField,
  DependentTrimField,
  DependentYearField,
  ExteriorFieldProps,
  InteriorFieldProps,
  ModelFieldProps,
  TrimFieldProps,
  YearFieldProps
} from './DependentFields'
import { FieldContainer } from './EditNewVehicleForm'
import { REFERENCE_MODEL_FIELDS } from './constants'

interface AddOldVehicleFormProps extends FormOrchestratorStepProps {
  handleCancelClick: () => void
}

export const AddOldVehicleForm: FC<AddOldVehicleFormProps> = (props) => {
  const { ctx: context, handleCancelClick } = props
  const { values: orchestratorValues } = context
  const dispatch = useAppDispatch()
  const { isDealer, addresses } = useUserInfo()
  const apiContext = useApiContext()
  const makeOptions = useAppSelector(selectFindCarFormMakeOptions)
  const modelOptions = useAppSelector(selectFindCarFormModelOptions)
  const yearOptions = useAppSelector(selectFindCarFormYearFromOptions)
  const trimOptions = useAppSelector(selectFindCarFormTrimOptions)
  const interiorColorOptions = useAppSelector(
    selectFindCarFormInteriorColorsOptions
  )
  const exteriorColorOptions = useAppSelector(
    selectFindCarFormExteriorColorsOptions
  )
  const standardEquipment = useAppSelector(selectFindCarFormStandardEquipment)
  const isReadCarModelYearTrimLoading = useAppSelector(
    selectIsReadCarModelYearTrimLoading
  )

  const noCheckboxExteriorColorOptions = useMemo(
    () =>
      exteriorColorOptions
        .filter((option) => option.name !== defaultColorOption.name)
        .map((option) => ({
          ...option,
          disableCheckbox: true
        })),
    [exteriorColorOptions]
  )
  const noCheckboxInteriorColorOptions = useMemo(
    () =>
      interiorColorOptions
        .filter((option) => option.name !== defaultColorOption.name)
        .map((option) => ({
          ...option,
          disableCheckbox: true
        })),
    [interiorColorOptions]
  )

  const hasExteriorColorOptions = !!noCheckboxExteriorColorOptions.length
  const hasInteriorColorOptions = !!noCheckboxInteriorColorOptions.length

  useEffect(() => {
    dispatch(readMakersFromVehicles())
  }, [])

  const [readVehicleValue, readVehicleValueObservable] = useMakeService(
    async ({
      make,
      model,
      year,
      trim,
      mileage,
      condition
    }: {
      make: string
      model: string
      year: string
      trim: string
      mileage: string
      condition: VehicleCondition
    }) => {
      const response =
        await apiContext.externalCarSnoopApi.vehicles.readVehicleValue({
          make,
          model,
          year,
          trim,
          mileage,
          condition
        })

      if (response.status === ResponseStatusMap.Success) {
        return {
          status: response.status,
          tradeIn: response.tradeIn,
          retail: response.retail
        }
      }

      return response
    }
  )

  const modelFieldEffect: DependentFieldHocEffect<
    ModelFieldProps,
    AddOldVehicleFormFieldsTypes
  > = useCallback((_, ctx, prevValues) => {
    const { values, setFieldValue } = ctx
    const { make } = values
    if (!make) {
      return setFieldValue(ADD_VEHICLE_FIELD_NAMES.MODEL, null)
    }
    dispatch(readModelsFromVehicles({ make: make.id }))

    if (prevValues) {
      const { make: prevMake } = prevValues
      if (make?.id !== prevMake?.id) {
        setFieldValue(ADD_VEHICLE_FIELD_NAMES.MODEL, null)
      }
    }
  }, [])

  const yearFieldEffect: DependentFieldHocEffect<
    YearFieldProps,
    AddOldVehicleFormFieldsTypes
  > = useCallback((_, ctx, prevValues) => {
    const { values, setFieldValue } = ctx
    const { make, model } = values
    if (!model) {
      return setFieldValue(ADD_VEHICLE_FIELD_NAMES.YEAR, null)
    }
    if (make) dispatch(readYearsFromVehicles({ make: make.id, model: model.id }))

    if (prevValues) {
      const { model: prevModel } = prevValues
      if (model?.id !== prevModel?.id) {
        setFieldValue(ADD_VEHICLE_FIELD_NAMES.YEAR, null)
      }
    }
  }, [])

  const trimFieldEffect: DependentFieldHocEffect<
    TrimFieldProps,
    AddOldVehicleFormFieldsTypes
  > = useCallback(
    (_, ctx, prevValues) => {
      const { values, setFieldValue } = ctx
      const { make, model, year } = values
      if (!year) {
        return setFieldValue(ADD_VEHICLE_FIELD_NAMES.TRIM, null)
      }

      if (make && model) {
        dispatch(
          readVehicleTrimByYearFromVehicles({
            make: make.id,
            model: model.id,
            year: [year.id]
          })
        )
      }

      if (prevValues) {
        const { year: prevYearTo } = prevValues
        if (year?.id !== prevYearTo?.id) {
          setFieldValue(ADD_VEHICLE_FIELD_NAMES.TRIM, null)
        }
      }
    },
    [yearOptions]
  )

  const exteriorFieldEffect: DependentFieldHocEffect<
    ExteriorFieldProps,
    AddOldVehicleFormFieldsTypes
  > = useCallback((_, ctx, prevValues) => {
    const { values, setFieldValue, setFieldError } = ctx
    const { make, model, year, trim, exteriorColor } = values

    if (!exteriorColor) {
      setFieldValue(ADD_VEHICLE_FIELD_NAMES.EXTERIOR_COLOR, null)
    }

    if (!year) {
      setFieldValue(ADD_VEHICLE_FIELD_NAMES.EXTERIOR_COLOR, null)
      return setFieldError(ADD_VEHICLE_FIELD_NAMES.EXTERIOR_COLOR, '')
    }

    if (make && model && year && trim) {
      dispatch(
        readCarModelYearTrimFromVehicles({
          params: {
            make: make.id,
            model: model.id,
            year: year.id,
            trim: trim.id
          },
          apiContext
        })
      )
    }

    if (prevValues) {
      const { year: prevYear, trim: prevTrim } = prevValues
      if (year?.id !== prevYear?.id || trim?.id !== prevTrim?.id) {
        setFieldValue(ADD_VEHICLE_FIELD_NAMES.EXTERIOR_COLOR, null)
        setFieldError(ADD_VEHICLE_FIELD_NAMES.EXTERIOR_COLOR, '')
      }
    }
  }, [])

  const interiorFieldEffect: DependentFieldHocEffect<
    InteriorFieldProps,
    AddOldVehicleFormFieldsTypes
  > = useCallback((_, ctx, prevValues) => {
    const { values, setFieldValue, setFieldError } = ctx
    const { year, trim, interiorColor } = values

    if (!interiorColor) {
      setFieldValue(ADD_VEHICLE_FIELD_NAMES.INTERIOR_COLOR, null)
    }

    if (!year) {
      setFieldValue(ADD_VEHICLE_FIELD_NAMES.INTERIOR_COLOR, null)
      return setFieldError(ADD_VEHICLE_FIELD_NAMES.INTERIOR_COLOR, '')
    }

    if (prevValues) {
      const { year: prevYear, trim: prevTrim } = prevValues
      if (year?.id !== prevYear?.id || trim?.id !== prevTrim?.id) {
        setFieldValue(ADD_VEHICLE_FIELD_NAMES.INTERIOR_COLOR, null)
        setFieldError(ADD_VEHICLE_FIELD_NAMES.INTERIOR_COLOR, '')
      }
    }
  }, [])

  const exteriorTextFieldEffect: DependentFieldHocEffect<
    TextInputFieldProps,
    AddOldVehicleFormFieldsTypes
  > = useCallback(async (___, ctx, prevValues) => {
    const { values, setFieldValue, setFieldError } = ctx
    const { make, model, year, trim, exteriorColor } = values

    if (!exteriorColor) {
      setFieldValue(ADD_VEHICLE_FIELD_NAMES.EXTERIOR_COLOR, '')
    }

    if (!year) {
      setFieldValue(ADD_VEHICLE_FIELD_NAMES.EXTERIOR_COLOR, '')
      return setFieldError(ADD_VEHICLE_FIELD_NAMES.EXTERIOR_COLOR, '')
    }

    if (make && model && year && trim) {
      dispatch(
        readCarModelYearTrimFromVehicles({
          params: {
            make: make.id,
            model: model.id,
            year: year.id,
            trim: trim.id
          },
          apiContext
        })
      )
    }

    if (prevValues) {
      const { year: prevYear, trim: prevTrim } = prevValues
      if (year?.id !== prevYear?.id || trim?.id !== prevTrim?.id) {
        setFieldValue(ADD_VEHICLE_FIELD_NAMES.EXTERIOR_COLOR, '')
        setFieldError(ADD_VEHICLE_FIELD_NAMES.EXTERIOR_COLOR, '')
      }
    }
  }, [])

  const interiorTextFieldEffect: DependentFieldHocEffect<
    TextInputFieldProps,
    AddOldVehicleFormFieldsTypes
  > = useCallback(async (___, ctx, prevValues) => {
    const { values, setFieldValue, setFieldError } = ctx
    const { year, trim, interiorColor } = values

    if (!interiorColor) {
      setFieldValue(ADD_VEHICLE_FIELD_NAMES.INTERIOR_COLOR, '')
    }

    if (!year) {
      setFieldValue(ADD_VEHICLE_FIELD_NAMES.INTERIOR_COLOR, '')
      return setFieldError(ADD_VEHICLE_FIELD_NAMES.INTERIOR_COLOR, '')
    }

    if (prevValues) {
      const { year: prevYear, trim: prevTrim } = prevValues
      if (year?.id !== prevYear?.id || trim?.id !== prevTrim?.id) {
        setFieldValue(ADD_VEHICLE_FIELD_NAMES.INTERIOR_COLOR, '')
        setFieldError(ADD_VEHICLE_FIELD_NAMES.EXTERIOR_COLOR, '')
      }
    }
  }, [])

  const retailValueFieldEffect: DependentFieldHocEffect<
    NumericFieldProps,
    AddOldVehicleFormFieldsTypes
  > = useCallback(
    async (___, ctx) => {
      const { values, setFieldValue } = ctx
      const {
        make,
        model,
        year,
        trim,
        currentMileage: mileage,
        condition
      } = values
      const conditionName = condition?.id

      if (
        make?.id &&
        model?.id &&
        year?.id &&
        trim?.id &&
        mileage &&
        condition
      ) {
        const result = await readVehicleValue({
          make: make.id,
          model: model.id,
          year: year.id,
          trim: trim.id,
          mileage,
          condition: conditionName as VehicleCondition
        })

        if (result.status === 'success') {
          setFieldValue(
            ADD_VEHICLE_FIELD_NAMES.RETAIL_VALUE,
            result.retail.toString()
          )
          return setFieldValue(
            ADD_VEHICLE_FIELD_NAMES.TRADE_IN_VALUE,
            result.tradeIn.toString()
          )
        }
      }

      setFieldValue(ADD_VEHICLE_FIELD_NAMES.RETAIL_VALUE, '0.00')
      return setFieldValue(ADD_VEHICLE_FIELD_NAMES.TRADE_IN_VALUE, '0.00')
    },
    [readVehicleValue, trimFieldEffect]
  )

  const retailValueFieldOnBlurEffect: DependentFieldHocEffect<
    NumericFieldProps,
    AddOldVehicleFormFieldsTypes
  > = useCallback(async (___, ctx) => {
    const { validateForm } = ctx
    validateForm()
  }, [])

  const tradeinValueFieldOnBlurEffect: DependentFieldHocEffect<
    NumericFieldProps,
    AddOldVehicleFormFieldsTypes
  > = retailValueFieldOnBlurEffect

  const handleSubmitClick = useCallback(
    (formValues: AddOldVehicleFormFieldsTypes) => {
      context.setValues({
        ...formValues,
        make: formValues.make?.name,
        model: formValues.model?.name,
        year: formValues.year?.name,
        trim: formValues.trim?.name,
        vin: '',
        standardEquipment
      })
      context.nextStep()
    },
    [standardEquipment]
  )

  const addressesOptions: AddressOption[] = useMemo(
    () =>
      addresses.map<AddressOption>((a, index) => {
        const { id, nickname, street, city, state, zipCode } = a
        const addressName: string = `${nickname || `Home ${index + 1}`} (${
          street ? `${street}, ` : ''
        }${zipCode}, ${city}, ${state})`
        return { id, name: addressName, rawValue: a }
      }),
    [addresses]
  )

  const currentValidationSchema = useMemo(() => {
    return getValidationSchema(
      isDealer,
      hasExteriorColorOptions,
      hasInteriorColorOptions
    )
  }, [isDealer, hasExteriorColorOptions, hasInteriorColorOptions])

  const defaultAddress = useMemo(
    () => (isDealer ? addressesOptions[0] : null),
    [isDealer, addressesOptions]
  )

  // Transform string values to ReferenceModel for AutoselectInputField
  const modifyedOrchestratorValues: FormikValues = useMemo(() => {
    return objectKeys(orchestratorValues).reduce<FormikValues>((acc, key) => {
      if (REFERENCE_MODEL_FIELDS.includes(key as string)) {
        const primitiveValue = orchestratorValues[key]
        const referenceModelValue: ReferenceModel =
          stringToReferenceModel(primitiveValue)
        acc[key] = referenceModelValue
        return acc
      }
      acc[key] = orchestratorValues[key]
      return acc
    }, {})
  }, [orchestratorValues])

  const isRequiredField = isRequiredFormField(currentValidationSchema)

  return (
    <Grid container>
      <Formik<AddOldVehicleFormFieldsTypes>
        initialValues={{
          address: defaultAddress,
          stockNumber: '',
          make: null,
          model: null,
          year: null,
          trim: null,
          exteriorColor: '',
          interiorColor: '',
          currentMileage: '0.00',
          condition: null,
          howMuchIsOwed: '0.00',
          notes: '',
          tradeinValue: '0.00',
          retailValue: '0.00',
          ...modifyedOrchestratorValues
        }}
        validationSchema={currentValidationSchema}
        onSubmit={handleSubmitClick}
        validateOnMount
        validateOnChange={false}
      >
        {(formik) => {
          return (
            <Form style={{ width: '100%' }}>
              <Grid container item xs={12} spacing={3} mb={3}>
                <Grid
                  container
                  item
                  sm={12}
                  lg={6}
                  spacing={2}
                  alignContent='space-between'
                >
                  <Condition
                    condition={isDealer}
                    trueContent={
                      <FieldContainer>
                        <TextInputFieldNew
                          name={ADD_VEHICLE_FIELD_NAMES.STOCK_NUMBER}
                          label={ADD_VEHICLE_FIELD_LABELS.STOCK_NUMBER}
                          required={isRequiredField}
                        />
                      </FieldContainer>
                    }
                    falseContent={
                      <Grid item xs={12}>
                        <Grid item xs={12} sx={{ mb: 1 }}>
                          <AutoselectInputField<ReferenceModel>
                            name={ADD_VEHICLE_FIELD_NAMES.ADDRESS}
                            label={ADD_VEHICLE_FIELD_LABELS.ADDRESS}
                            renderOption={AutoselectOption}
                            options={addressesOptions}
                            getOptionLabel={getOptionLabel}
                            isOptionEqualToValue={isOptionEqualToValue}
                            required={isRequiredField}
                          />
                        </Grid>
                        <FieldContainer>
                          <Grid container spacing={1} alignContent='center'>
                            <Grid item>
                              <InformationIcon />
                            </Grid>
                            <Grid item>
                              <Typography variant='subtitle2'>
                                Select the location of this vehicle.
                              </Typography>
                            </Grid>
                          </Grid>
                        </FieldContainer>
                      </Grid>
                    }
                  />
                  <Grid item container spacing={2}>
                    <FieldContainer>
                      <AutoselectInputField<Option>
                        renderOption={AutoselectOption}
                        name={ADD_VEHICLE_FIELD_NAMES.MAKE}
                        label={ADD_VEHICLE_FIELD_LABELS.MAKE}
                        options={makeOptions}
                        isOptionEqualToValue={isOptionEqualToValue}
                        getOptionLabel={getOptionLabel}
                        required
                      />
                    </FieldContainer>
                    <FieldContainer>
                      <DependentModelField
                        renderOption={AutoselectOption}
                        name={ADD_VEHICLE_FIELD_NAMES.MODEL}
                        label={ADD_VEHICLE_FIELD_LABELS.MODEL}
                        options={modelOptions}
                        isOptionEqualToValue={isOptionEqualToValue}
                        getOptionLabel={getOptionLabel}
                        onChangeEffect={modelFieldEffect}
                        required
                      />
                    </FieldContainer>
                    <FieldContainer>
                      <DependentYearField
                        renderOption={AutoselectOption}
                        name={ADD_VEHICLE_FIELD_NAMES.YEAR}
                        label={ADD_VEHICLE_FIELD_LABELS.YEAR}
                        options={yearOptions}
                        isOptionEqualToValue={isOptionEqualToValue}
                        getOptionLabel={getOptionLabel}
                        onChangeEffect={yearFieldEffect}
                        required
                      />
                    </FieldContainer>
                    <FieldContainer>
                      <DependentTrimField
                        renderOption={AutoselectOption}
                        name={ADD_VEHICLE_FIELD_NAMES.TRIM}
                        label={ADD_VEHICLE_FIELD_LABELS.TRIM}
                        options={trimOptions}
                        isOptionEqualToValue={isOptionEqualToValue}
                        getOptionLabel={getOptionLabel}
                        onChangeEffect={trimFieldEffect}
                        required
                      />
                    </FieldContainer>

                    <FieldContainer>
                      <Show
                        when={hasExteriorColorOptions}
                        fallback={
                          <DependentTextExteriorField
                            name={ADD_VEHICLE_FIELD_NAMES.EXTERIOR_COLOR}
                            label={ADD_VEHICLE_FIELD_LABELS.EXTERIOR_COLOR}
                            disabled={
                              !formik.values[ADD_VEHICLE_FIELD_NAMES.TRIM] ||
                              isReadCarModelYearTrimLoading
                            }
                            required={isRequiredField}
                            onChangeEffect={exteriorTextFieldEffect}
                          />
                        }
                      >
                        <DependentAutoselectExteriorField
                          renderOption={AutoselectColorOption}
                          name={ADD_VEHICLE_FIELD_NAMES.EXTERIOR_COLOR}
                          label={ADD_VEHICLE_FIELD_LABELS.EXTERIOR_COLOR}
                          options={noCheckboxExteriorColorOptions}
                          isOptionEqualToValue={isOptionEqualToValue}
                          getOptionLabel={getOptionLabel}
                          onChangeEffect={exteriorFieldEffect}
                          required={isRequiredField}
                          disabled={isReadCarModelYearTrimLoading}
                        />
                      </Show>
                    </FieldContainer>

                    <FieldContainer>
                      <Show
                        when={hasInteriorColorOptions}
                        fallback={
                          <DependentTextInteriorField
                            name={ADD_VEHICLE_FIELD_NAMES.INTERIOR_COLOR}
                            label={ADD_VEHICLE_FIELD_LABELS.INTERIOR_COLOR}
                            required={isRequiredField}
                            disabled={
                              !formik.values[ADD_VEHICLE_FIELD_NAMES.TRIM] ||
                              isReadCarModelYearTrimLoading
                            }
                            onChangeEffect={interiorTextFieldEffect}
                          />
                        }
                      >
                        <DependentAutoselectInteriorField
                          renderOption={AutoselectColorOption}
                          name={ADD_VEHICLE_FIELD_NAMES.INTERIOR_COLOR}
                          label={ADD_VEHICLE_FIELD_LABELS.INTERIOR_COLOR}
                          options={noCheckboxInteriorColorOptions}
                          isOptionEqualToValue={isOptionEqualToValue}
                          getOptionLabel={getOptionLabel}
                          onChangeEffect={interiorFieldEffect}
                          required={isRequiredField}
                          disabled={isReadCarModelYearTrimLoading}
                        />
                      </Show>
                    </FieldContainer>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  sm={12}
                  lg={6}
                  spacing={2}
                  alignContent='space-between'
                >
                  <FieldContainer>
                    <NumericField
                      name={ADD_VEHICLE_FIELD_NAMES.CURRENT_MILEAGE}
                      label={ADD_VEHICLE_FIELD_LABELS.CURRENT_MILEAGE}
                      required={isRequiredField}
                    />
                  </FieldContainer>
                  <FieldContainer>
                    <AutoselectInputField<ReferenceModel>
                      name={ADD_VEHICLE_FIELD_NAMES.CONDITION}
                      label={ADD_VEHICLE_FIELD_LABELS.CONDITION}
                      renderOption={AutoselectOption}
                      options={VehicleConditionOptions}
                      getOptionLabel={getOptionLabel}
                      isOptionEqualToValue={isOptionEqualToValue}
                      required={isRequiredField}
                    />
                  </FieldContainer>
                  <FieldContainer>
                    <NumericField
                      startWith='$'
                      name={ADD_VEHICLE_FIELD_NAMES.HOW_MUCH_IS_OWED}
                      label={ADD_VEHICLE_FIELD_LABELS.HOW_MUCH_IS_OWED}
                      required={isRequiredField}
                    />
                  </FieldContainer>
                  <FieldContainer>
                    <NotesField
                      name={ADD_VEHICLE_FIELD_NAMES.NOTES}
                      label={ADD_VEHICLE_FIELD_LABELS.NOTES}
                      required={isRequiredField}
                    />
                  </FieldContainer>
                  <FieldContainer>
                    <DependentTradeinValueField
                      startWith='$'
                      name={ADD_VEHICLE_FIELD_NAMES.TRADE_IN_VALUE}
                      label={ADD_VEHICLE_FIELD_LABELS.TRADE_IN_VALUE}
                      onBlurEffect={tradeinValueFieldOnBlurEffect}
                      required={isRequiredField}
                    />
                  </FieldContainer>
                  <FieldContainer>
                    <DependentRetailValueField
                      startWith='$'
                      name={ADD_VEHICLE_FIELD_NAMES.RETAIL_VALUE}
                      label={ADD_VEHICLE_FIELD_LABELS.RETAIL_VALUE}
                      onChangeEffect={retailValueFieldEffect}
                      onBlurEffect={retailValueFieldOnBlurEffect}
                      required={isRequiredField}
                    />
                  </FieldContainer>
                </Grid>
              </Grid>
              <Grid container item xs={12} mb={3}>
                <Divider />
              </Grid>
              <Grid container item justifyContent='flex-end' spacing={2}>
                <Grid item>
                  <CancelButton onClick={handleCancelClick}>
                    CANCEL
                  </CancelButton>
                </Grid>
                <Grid item>
                  <AgreeButton
                    type='submit'
                    disabled={
                      !formik.isValid || readVehicleValueObservable.isLoading
                    }
                  >
                    NEXT
                  </AgreeButton>
                </Grid>
              </Grid>
            </Form>
          )
        }}
      </Formik>
    </Grid>
  )
}
