import { useCallback } from 'react'
import { useApiContext, useMakeService } from '../../../utils/hooks'
import { ChatProps } from '../types'
import { ResponseStatusMap } from '../../../types/status'
import { CompressImageSuccessResponse } from '../../../utils/compressImages'

export const useChatUpdater = ({ chatId }: ChatProps) => {
  const { chatApi } = useApiContext()

  const [sendTextMessage, sendTextMessageObservable] = useMakeService(
    async (message: string) => {
      // TODO: implement delivered/undelivered status
      return chatApi.createChatMessage({
        transactionId: chatId,
        message
      })
    },
    { withStatusNotification: [1] }
  )

  const [uploadFile, uploadFileObservable] = useMakeService(
    async (options: { url: string; file: File }) => {
      return chatApi.uploadFile(options)
    },
    { withStatusNotification: [1] }
  )

  const [createMediaMessage, createMediaMessageObservable] = useMakeService(
    async (file: File) => {
      return chatApi.createChatMessage({
        transactionId: chatId,
        file
      })
    },
    { withStatusNotification: [1] }
  )

  const [updateChatsHasRead] = useMakeService(async (ids: string[]) => {
    return chatApi.updateChatsHasRead({ ids, hasRead: true })
  })

  const sendMediaMessage = useCallback(
    async (fileData: CompressImageSuccessResponse) => {
      const { blob: file } = fileData
      const response = await createMediaMessage(file)
      if (response.status === ResponseStatusMap.Success) {
        await uploadFile({
          url: response.payload.url,
          file
        })
      }
    },
    [createMediaMessage, uploadFile]
  )

  const markMessagesAsRead = useCallback(
    async (ids: string[]) => {
      await updateChatsHasRead(ids)
    },
    [updateChatsHasRead]
  )

  return {
    isMessageSending:
      sendTextMessageObservable.isLoading ||
      createMediaMessageObservable.isLoading,
    isFileUploading: uploadFileObservable.isLoading,
    sendTextMessage,
    sendMediaMessage,
    markMessagesAsRead
  }
}
