import { AutoselectInputField } from '../../../../../atoms/AutoselectInputField'
import { DependentField } from '../../../../../atoms/DependentField'
import {
  NumericField,
  NumericFieldProps
} from '../../../../../atoms/NumericField'
import {
  AddVehicleFormFieldsTypes,
  AddOldVehicleFormFieldsTypes,
  ADD_VEHICLE_FIELD_NAMES
} from '../../../constants'
import { ColorOption, Option } from '../../../../../types/Autoselect'
import {
  TextInputFieldNew,
  TextInputFieldProps
} from '../../../../../atoms/TextInputFieldNew'

export type ModelFieldProps = Parameters<typeof AutoselectInputField<Option>>[0]
export type YearFieldProps = ModelFieldProps
export type TrimFieldProps = ModelFieldProps
export type ExteriorFieldProps = Parameters<
  typeof AutoselectInputField<ColorOption>
>[0]
export type InteriorFieldProps = ExteriorFieldProps

export const DependentModelField = DependentField<
  ModelFieldProps,
  AddOldVehicleFormFieldsTypes
>(AutoselectInputField<Option>, {
  modifyProps: (props, { make }) =>
    make ? props : { ...props, disabled: true },
  deps: [ADD_VEHICLE_FIELD_NAMES.MAKE]
})

export const DependentYearField = DependentField<
  YearFieldProps,
  AddOldVehicleFormFieldsTypes
>(AutoselectInputField<Option>, {
  modifyProps: (props, { model }) =>
    model ? props : { ...props, disabled: true },
  deps: [ADD_VEHICLE_FIELD_NAMES.MODEL]
})

export const DependentTrimField = DependentField<
  TrimFieldProps,
  AddOldVehicleFormFieldsTypes
>(AutoselectInputField<Option>, {
  modifyProps: (props, { year }) =>
    year ? props : { ...props, disabled: true },
  deps: [ADD_VEHICLE_FIELD_NAMES.YEAR]
})

export const DependentAutoselectExteriorField = DependentField<
  ExteriorFieldProps,
  AddOldVehicleFormFieldsTypes
>(AutoselectInputField<ColorOption>, {
  modifyProps: (props, { trim }) =>
    trim ? props : { ...props, disabled: true },
  deps: [ADD_VEHICLE_FIELD_NAMES.TRIM]
})

export const DependentTextExteriorField = DependentField<
  TextInputFieldProps,
  AddOldVehicleFormFieldsTypes
>(TextInputFieldNew, {
  deps: [ADD_VEHICLE_FIELD_NAMES.TRIM]
})

export const DependentAutoselectInteriorField = DependentField<
  InteriorFieldProps,
  AddOldVehicleFormFieldsTypes
>(AutoselectInputField<ColorOption>, {
  modifyProps: (props, { trim }) =>
    trim ? props : { ...props, disabled: true },
  deps: [ADD_VEHICLE_FIELD_NAMES.TRIM]
})

export const DependentTextInteriorField = DependentField<
  TextInputFieldProps,
  AddOldVehicleFormFieldsTypes
>(TextInputFieldNew, {
  deps: [ADD_VEHICLE_FIELD_NAMES.TRIM]
})

export const DependentRetailValueField = DependentField<
  NumericFieldProps,
  AddVehicleFormFieldsTypes
>(NumericField, {
  deps: [
    ADD_VEHICLE_FIELD_NAMES.CURRENT_MILEAGE,
    ADD_VEHICLE_FIELD_NAMES.CONDITION,
    ADD_VEHICLE_FIELD_NAMES.INTERIOR_COLOR,
    ADD_VEHICLE_FIELD_NAMES.EXTERIOR_COLOR
  ]
})

export const DependentTradeinValueField = DependentField<
  NumericFieldProps,
  AddVehicleFormFieldsTypes
>(NumericField, {
  deps: []
})

export const OldCarDependentRetailValueField = DependentField<
  NumericFieldProps,
  AddOldVehicleFormFieldsTypes
>(NumericField, {
  deps: [
    ADD_VEHICLE_FIELD_NAMES.TRIM,
    ADD_VEHICLE_FIELD_NAMES.CURRENT_MILEAGE,
    ADD_VEHICLE_FIELD_NAMES.CONDITION,
    ADD_VEHICLE_FIELD_NAMES.INTERIOR_COLOR,
    ADD_VEHICLE_FIELD_NAMES.EXTERIOR_COLOR
  ]
})

export const OldCarDependentTradeinValueField = DependentField<
  NumericFieldProps,
  AddOldVehicleFormFieldsTypes
>(NumericField, {
  deps: []
})
