import { styled } from '@mui/material'
import { FC } from 'react'
import { COLORS } from '../../types/colors'

interface INumberBadgeComponentProps {
  width?: string
  height?: string
  fontSize?: string
}

interface INumberBadgeProps {
  count: number
}

const NumberBadgeComponent = styled('div')<
  INumberBadgeProps & INumberBadgeComponentProps
>`
  background-color: ${COLORS.main_yellow};
  padding: 0 4px;
  width: fit-content;
  height: ${({ height }) => height || '24px'};
  border-radius: ${({ count }) => (count > 99 ? '66px' : '50%')};
  min-width: ${({ width }) => width || '24px'};
  text-align: center;
  font-size: ${({ fontSize }) => fontSize || '14px'};
  line-height: ${({ height }) => height || '24px'};
`

export const NumberBadge: FC<INumberBadgeProps & INumberBadgeComponentProps> = (
  props
) => {
  const { count } = props
  const updatedCount = count > 99 ? '99+' : count

  return <NumberBadgeComponent {...props}>{updatedCount}</NumberBadgeComponent>
}
