import { Box, Grid, Typography } from '@mui/material'
import { FC, useCallback, useMemo } from 'react'
import { ShippingVehicleIcon } from '../../assets/icons/ShippingVehicle'
import { Show } from '../../atoms/JSXExtensions'
import { ManualDataGetting } from '../../atoms/ManualDataGetting'
import { selectBasicInfoScreenData } from '../../store/units/findCarForm'
import { OrNull } from '../../types/OrNull'
import { COLORS } from '../../types/colors'
import { formatNumber, formatPrice } from '../../utils'
import { useAppSelector, useDeviceInfo } from '../../utils/hooks'
import { useShippingDetails } from './hooks/useShippingDetails'

interface VehicleDetailsProps {
  mileage: number
  distance: string
  shippingCost: OrNull<number>
  vehicleInfo: {
    id: string
    vin?: string
    year: string | number
    make: string
    model: string
    zipCode: string
  }
}

export const VehicleDetails: FC<VehicleDetailsProps> = ({
  mileage,
  distance,
  shippingCost,
  vehicleInfo
}) => {
  const { isTablet } = useDeviceInfo()
  const basicInfoScreenData = useAppSelector(selectBasicInfoScreenData)

  const { readShippingDetailsObservable, readShippingDetails } =
    useShippingDetails()

  const formattedData = useMemo(
    () => ({
      mileage: formatNumber(mileage)
    }),
    [mileage]
  )

  const handleGetShippingCostClick = useCallback(() => {
    const { deliverTo } = basicInfoScreenData

    if (deliverTo?.zipCode) {
      const {
        zipCode: fromZipCode,
        vin = '',
        year,
        make,
        model,
        id
      } = vehicleInfo

      readShippingDetails({
        id,
        toZipCode: deliverTo.zipCode,
        fromZipCode,
        vehicle: {
          vin,
          year: `${year}`,
          make,
          model
        }
      })
    }
  }, [basicInfoScreenData, vehicleInfo])

  return (
    <Grid container spacing={1}>
      <Grid item container spacing={0.5}>
        <Grid item>
          <Typography
            variant='body2'
            color='textSecondary'
            textTransform='uppercase'
          >
            mileage:
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='body2'>{formattedData.mileage}</Typography>
        </Grid>
      </Grid>
      <Grid item container spacing={0.5}>
        <Grid item>
          <Typography
            variant='body2'
            color='textSecondary'
            textTransform='uppercase'
          >
            {isTablet ? 'Away:' : 'Distance away:'}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='body2'>{distance}</Typography>
        </Grid>
      </Grid>
      <ManualDataGetting
        status={readShippingDetailsObservable.status}
        dataIcon={<ShippingVehicleIcon fill={COLORS.text_blue} />}
        dataText='Get shipping cost?'
        onRetryClick={handleGetShippingCostClick}
        onGetClick={handleGetShippingCostClick}
      >
        <Show when={shippingCost}>
          {(cost) => (
            <Grid item container columnSpacing={1}>
              <Grid item>
                <Box display='flex' alignItems='center' height='100%'>
                  <ShippingVehicleIcon />
                </Box>
              </Grid>
              <Grid item>
                <Typography variant='subtitle1'>
                  {formatPrice(cost, { includeDecimalPart: true })}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Show>
      </ManualDataGetting>
    </Grid>
  )
}
