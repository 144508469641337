import { ImagesCarousel } from '../../../../molecules/ImagesCarousel'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

const imageURL =
  'https://upload.wikimedia.org/wikipedia/commons/thumb/9/94/2015-bmw-x6.jpg/800px-2015-bmw-x6.jpg'

const imageURL2 =
  'https://tesla-cdn.thron.com/delivery/public/image/tesla/2391415a-7273-4735-92b2-a37c84c26f04/bvlatuR/std/4096x2560/Homepage-Model-S-Desktop-RHD'

export const ImagesCarouselExample = () => {
  const images = [imageURL, imageURL2].map((image, i) => ({
    id: `${i}`,
    url: image,
    type: 'image/jpeg'
  }))

  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <ImagesCarousel vehicleId='1' images={[]} />
        <br />
        <ImagesCarousel vehicleId='2' images={images.slice(0, 2)} />
        <br />
        <ImagesCarousel vehicleId='3' images={[...images]} />
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
