import { FC, useCallback, useLayoutEffect } from 'react'
import { Grid } from '@mui/material'
import { useAppDispatch } from '../../../utils/hooks'
import { actionsFindCarForm } from '../../../store/units/findCarForm'
import { DealsActiveTabEnum } from '../../../store/types'
import { useDealsPageSlice } from '../../../routes/Deals/hooks'
import { useBreadcrumbs } from '../../../utils/hooks/useBreadcrumbs'
import { Show } from '../../../atoms/JSXExtensions'
import { Loader } from '../../../atoms/Loader'
import { findCarFormSlice } from '../../../store/units/findCarForm/findCarForm'
import { FindCarResultSuccessScreen } from './components/FindCarResultSuccessScreen'
import { FindCarResultErrorScreen } from './components/FindCarResultErrorScreen'

interface FindCarResultScreenProps {
  isLoading: boolean
  isError: boolean
  onMountRequest: () => void
  actions: typeof findCarFormSlice.actions
}

export const FindCarResultScreen: FC<FindCarResultScreenProps> = ({
  isLoading,
  isError,
  onMountRequest,
  actions
}) => {
  const dispatch = useAppDispatch()
  const { returnToDealsPage } = useDealsPageSlice()
  const { resetBreadcrumbs } = useBreadcrumbs()

  const onSuccessScreenButtonClick = useCallback(() => {
    returnToDealsPage({
      initialSellingView: false,
      tabNumber: DealsActiveTabEnum.Requests
    })
    dispatch(actionsFindCarForm.resetState())
    resetBreadcrumbs()
  }, [returnToDealsPage, dispatch, resetBreadcrumbs])

  const onErrorScreenBackClick = useCallback(() => {
    dispatch(actions.decrementScreenStep())
  }, [dispatch, actions])

  useLayoutEffect(() => {
    onMountRequest()
  }, [])

  return (
    <Show
      when={!isLoading}
      fallback={
        <Grid container alignItems='center'>
          <Loader />
        </Grid>
      }
    >
      <Show
        when={isError}
        fallback={
          <FindCarResultSuccessScreen
            onNextClick={onSuccessScreenButtonClick}
          />
        }
      >
        <FindCarResultErrorScreen onBackClick={onErrorScreenBackClick} />
      </Show>
    </Show>
  )
}
