import { Button, Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { CloseIcon } from '../../assets/icons/Close'
import { EllipsisText } from '../../atoms/EllipsisText'
import { Show } from '../../atoms/JSXExtensions'
import { ProgressBar } from '../../atoms/ProgressBar'
import { COLORS } from '../../types/colors'
import { DocumentViewContainer } from './DocumentView.styles'
import { FileDescription } from './components/FileDescription'

interface IDocumentViewProps {
  documentIcon: JSX.Element
  documentName: string
  error?: JSX.Element | string
  isInProgress?: boolean
  progress?: number
  fileDescription?: string
  onFileDelete?: () => void
}

export const DocumentView: FC<IDocumentViewProps> = ({
  documentIcon,
  documentName,
  progress = 0,
  isInProgress = false,
  error,
  fileDescription,
  onFileDelete
}) => {
  return (
    <DocumentViewContainer container item xs={12} padding={3}>
      <Grid item xs={12}>
        <Grid container item columnSpacing={2}>
          <Grid item xs={1.75}>
            {documentIcon}
          </Grid>
          <Grid container item xs={9.5} direction='column'>
            <Grid item mb={1} width='100%'>
              <Typography variant='main'>
                <EllipsisText tooltipText={documentName}>
                  {documentName}
                </EllipsisText>
              </Typography>
            </Grid>
            <FileDescription error={error} fileDescription={fileDescription} />
          </Grid>
          <Show when={onFileDelete}>
            <Grid item xs={0.75}>
              <Button
                disableElevation
                variant='text'
                sx={{ minWidth: 0, padding: 0 }}
                onClick={onFileDelete}
              >
                <CloseIcon />
              </Button>
            </Grid>
          </Show>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Show when={isInProgress}>
          <ProgressBar
            progress={progress}
            progressColor={COLORS.main_yellow}
          />
        </Show>
      </Grid>
    </DocumentViewContainer>
  )
}
