import { Formik, Form } from 'formik'
import { AgreeButton } from '../../../../atoms/Button'
import { RadioGroupField } from '../../../../atoms/RadioGroup'
import { stringToReferenceModel } from '../../../../utils/autoselect/stringToReferenceModel'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

const options = ['Option 1', 'Option 2', 'Option 3'].map(stringToReferenceModel)

export const RadioGroupFieldExample = () => {
  const initialValues = {
    radioGroup: 'Option 2'
  }

  return (
    <ComponentContainer>
      <ComponentWithPaperBackground title='Atom RadioGroup with form'>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => console.log(values)}
        >
          <Form>
            <RadioGroupField name='radioGroup' options={options} />
            <AgreeButton type='submit'>Submit</AgreeButton>
          </Form>
        </Formik>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
