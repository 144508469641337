import { Grid, Typography } from '@mui/material'
import { Show } from '../JSXExtensions'
import { RedButton } from '../Button'
import { RetryIcon } from '../../assets/icons/RetryIcon'

export const LoadingError = ({
  errorMessage,
  handleCarfaxRequest
}: {
  errorMessage?: string
  handleCarfaxRequest: () => void
}) => {
  return (
    <Show
      when={!errorMessage}
      fallback={
        <Typography variant='main' fontStyle='italic' py={1}>
          There is no CarFax report available
        </Typography>
      }
    >
      <Grid item container justifyContent='flex-end' alignItems='center'>
        <Typography variant='main' color='error'>
          Something went wrong!
        </Typography>
        <RedButton onClick={handleCarfaxRequest} endIcon={<RetryIcon />}>
          <Typography
            variant='main'
            color='error'
            sx={{ textDecoration: 'underline' }}
          >
            Retry
          </Typography>
        </RedButton>
      </Grid>
    </Show>
  )
}
