import { Grid } from '@mui/material'
import { FC } from 'react'
import { EnvelopeIcon } from '../../assets/icons/EnvelopeIcon'
import { selectSideBarState, SIDE_BAR } from '../../store/features/SideBar'
import { useAppSelector } from '../../utils/hooks'
import { SupportFormMainText } from './components/SupportFormMainText'
import {
  SUPPORT_FINAL_TEXT_HEADER,
  SUPPORT_FINAL_TEXT_MESSAGE
} from './constants'
import { SupportForm } from './SupportForm'

export const SupportFormWrapper: FC = () => {
  const sideBar = useAppSelector(selectSideBarState)

  return (
    <Grid container sx={{ p: 4, height: '100%' }}>
      <SupportForm
        email={sideBar.content[SIDE_BAR.CONTACT_SUPPORT].email}
        prefilledReason={sideBar.content[SIDE_BAR.CONTACT_SUPPORT].reason}
        buttonText='go back'
        mainText={
          <SupportFormMainText
            primaryText={SUPPORT_FINAL_TEXT_HEADER}
            secondaryText={SUPPORT_FINAL_TEXT_MESSAGE}
          />
        }
        icon={<EnvelopeIcon />}
      />
    </Grid>
  )
}
