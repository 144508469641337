import { Grid, GridProps } from '@mui/material'
import { FC, PropsWithChildren, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Condition } from '../../atoms/Condition'
import { routes } from '../../routes'
import {
  UserInfoStatusMap,
  actionsUserInfo
} from '../../store/features/userInfo'
import {
  useAppDispatch,
  useUserInfo,
  useUserSession
} from '../../utils/hooks'

export const ServicePageContainer: FC<PropsWithChildren<GridProps>> = ({
  children,
  ...props
}) => {
  const navigate = useNavigate()
  const userInfo = useUserInfo()
  const dispatch = useAppDispatch()
  const { sessionCredentials } = useUserSession()

  useEffect(() => {
    if (!sessionCredentials && userInfo.status === UserInfoStatusMap.Unknown) {
      dispatch(
        actionsUserInfo.setUserStatus({
          status: UserInfoStatusMap.Unauthenticated
        })
      )
    }
  }, [sessionCredentials, userInfo.status, dispatch])

  useEffect(() => {
    if (userInfo.status === UserInfoStatusMap.Authenticated) {
      navigate(routes.dashboard)
    }
  }, [userInfo])

  return (
    <Condition
      condition={userInfo.status !== UserInfoStatusMap.Authenticated}
      trueContent={
        <Grid
          minHeight={{
            xs: '100%'
          }}
          container
          justifyContent='center'
          alignItems={{ sm: 'center', lg: 'flex-start' }}
          flexDirection={{ sm: 'column', lg: 'row' }}
          flexGrow={1}
          paddingTop={{
            xs: '0',
            md: '24px',
            lg: '96px'
          }}
          {...props}
        >
          {children}
        </Grid>
      }
    />
  )
}
