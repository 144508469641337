import { useCallback } from 'react'
import type { Address } from '../../../../../../store/types'
import { actionsUserInfo } from '../../../../../../store/features/userInfo'
import { actionsEditingAddress } from '../../../../../../store/units/editingAddress'
import { ResponseStatusMap } from '../../../../../../types/status'
import {
  useAppDispatch,
  useMakeService,
  useUserInfo,
  useApiContext
} from '../../../../../../utils/hooks'

export const useUpdateAddress = (
  address?: Address,
  onSave?: () => void,
  onNotFound?: () => void
) => {
  const {
    id: userId,
    firstName,
    lastName,
    phone,
    dateOfBirth,
    accountID,
    taxID,
    driversLicense,
    driversLicenseState,
    driversLicenseExpiration
  } = useUserInfo()
  const dispatch = useAppDispatch()
  const { carSnoopApi, externalCarSnoopApi } = useApiContext()

  const [updateAddress, updateAddressObservable] = useMakeService(
    async (data: Address) => {
      const { street, city, state, zipCode } = data
      dispatch(actionsEditingAddress.updateEditingAddress(data))
      const checkResponse = await externalCarSnoopApi.addresses.validateAddress(
        { street, city, state, zipCode }
      )

      if (checkResponse.status === ResponseStatusMap.Success) {
        const response = await carSnoopApi.users.updateUser({
          id: userId,
          firstName,
          lastName,
          phone,
          dateOfBirth: new Date(dateOfBirth),
          addresses: [{ ...data, id: address?.id || '' }],
          taxId: taxID,
          driversLicense,
          driversLicenseState,
          driversLicenseExpiration
        })

        if (response.status === ResponseStatusMap.Success) {
          const accInfo = await carSnoopApi.accounts.readAccount(accountID)

          if (accInfo.status === ResponseStatusMap.Success) {
            const { addresses } = accInfo
            dispatch(actionsUserInfo.setAddresses({ addresses }))
            dispatch(actionsEditingAddress.setEditingAddress(null))
            onSave && onSave()
          }

          return accInfo
        }

        return response
      }
      checkResponse.message === 'Address was not found.' &&
        onNotFound &&
        onNotFound()
      return checkResponse
    },
    { includeCheckAccess: true, withStatusNotification: true }
  )

  const handleAddressSaveClick = useCallback(
    (values: Address) => {
      updateAddress(values)
    },
    [updateAddress]
  )

  return [handleAddressSaveClick, updateAddressObservable] as [
    typeof handleAddressSaveClick,
    typeof updateAddressObservable
  ]
}
