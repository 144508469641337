import { createSlice } from '@reduxjs/toolkit'

interface SubscriptionModalState {
  isOpen: boolean
}

export const initialState: SubscriptionModalState = {
  isOpen: false
}

export const subscriptionModalSlice = createSlice({
  name: 'units/subscriptionModal',
  initialState,
  reducers: {
    openModal: (state) => {
      state.isOpen = true
    },
    closeModal: (state) => {
      state.isOpen = false
    }
  }
})
