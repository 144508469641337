import { useState, useCallback, useLayoutEffect, useMemo } from 'react'
import { CompressImageResponse } from '../../utils/compressImages'
import { OrNull } from '../../types/OrNull'
import {
  DOCUMENT_OVERSIZE_ERROR,
  DOCUMENT_WRONG_FORMAT_ERROR,
  DOCUMENT_PDF_OVERSIZE_ERROR
} from '../../constants/messages'

export type UploadDocumentItem = OrNull<{
  content: string
  type: string
  createdOn: Date
}>

type UseDocumentUploadOptions = {
  id: string
  type: OrNull<string>
  createdOn: OrNull<Date>
  isOpen: boolean
}

export const useDocumentUpload = ({
  id,
  type,
  createdOn,
  isOpen
}: UseDocumentUploadOptions) => {
  const [progress, setProgress] = useState(0)
  const [document, setDocument] = useState<UploadDocumentItem>(null)
  const [isOversize, setIsOversize] = useState(false)
  const [isWrongFormat, setIsWrongFormat] = useState(false)

  useLayoutEffect(() => {
    if (id && type && createdOn && isOpen) {
      setDocument({
        type,
        content: id,
        createdOn
      })
      setProgress(100)
    }
  }, [id, type, isOpen, createdOn])

  const handleUpload = useCallback(
    async (data: CompressImageResponse) => {
      setIsOversize(false)
      setIsWrongFormat(false)
      const file = data[0]

      if (!file.isFileExtensionAvailable) {
        return setIsWrongFormat(true)
      }

      const { isAcceptableMinify } = file

      if (!isAcceptableMinify) {
        setIsOversize(true)
      }

      setDocument({
        type: file.fileExtension,
        content: file.cleanBase64,
        createdOn: new Date()
      })
    },
    [setIsWrongFormat, setIsOversize, id]
  )

  const handleDiscardUpload = useCallback(() => {
    setProgress(0)
    setDocument(null)
    setIsOversize(false)
    setIsWrongFormat(false)
  }, [setProgress, setDocument, setIsOversize, setIsWrongFormat])

  const handleBeforeUpload = useCallback(async () => {
    setIsOversize(false)
  }, [setIsOversize, id])

  const errorMessage = useMemo(() => {
    if (isWrongFormat) return DOCUMENT_WRONG_FORMAT_ERROR
    if (isOversize && document?.type !== 'pdf') return DOCUMENT_OVERSIZE_ERROR
    if (isOversize && document?.type === 'pdf')
      return DOCUMENT_PDF_OVERSIZE_ERROR
    return ''
  }, [isOversize, isWrongFormat, document?.type])

  useLayoutEffect(() => {
    setProgress(0)
    setDocument(null)
    setIsOversize(false)
    setIsWrongFormat(false)
  }, [id])

  return {
    document,
    errorMessage,
    progress,
    handleUpload,
    handleBeforeUpload,
    handleDiscardUpload,
    setProgress
  }
}
