import { Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { MainCarImg } from '../../../../../assets/imgs/MainCarImg'
import { AgreeButton } from '../../../../../atoms/Button'

interface FindCarResultSuccessScreenProps {
  onNextClick: () => void
}

export const FindCarResultSuccessScreen: FC<
  FindCarResultSuccessScreenProps
> = ({ onNextClick }) => {
  return (
    <Grid container direction='column' gap={4} alignItems='center'>
      <Grid item>
        <MainCarImg width='100%' height='100%' />
      </Grid>
      <Grid item>
        <Typography component='h3' variant='h6'>
          We&apos;re working on getting your best offer!
        </Typography>
      </Grid>
      <Grid item>
        <AgreeButton onClick={onNextClick}>My Requests</AgreeButton>
      </Grid>
    </Grid>
  )
}
