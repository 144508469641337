import { styled } from '@mui/material'
import { COLORS } from '../../types/colors'

export const IconContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isDisabled'
})<{ isDisabled?: boolean }>(({ isDisabled }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '7px',
  background: COLORS.border_gray,
  borderRadius: '4px',
  '&:hover': {
    cursor: isDisabled ? 'unset' : 'pointer'
  }
}))

export const CounterContainer = styled('div')`
  display: flex;
  padding: 8px;
`
