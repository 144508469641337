import { Box } from '@mui/material'
import { CredentialResponse, GoogleLogin } from '@react-oauth/google'
import jwtDecode from 'jwt-decode'
import { actionsUserInfo } from '../../../store/features/userInfo'
import { SHADOWS } from '../../../types/colors'
import { ResponseStatusMap } from '../../../types/status'
import { useApiContext, useAppDispatch } from '../../../utils/hooks'
import { useAuthenticateUser } from '../../../utils/hooks/useAuthenticateUser'

type JWTResponse = {
  iss: string
  nbf: number
  aud: string
  sub: string
  email: string
  email_verified: boolean
  azp: string
  name: string
  picture: string
  given_name: string
  family_name: string
  iat: number
  exp: number
  jti: string
}

export const GoogleButton = () => {
  const dispatch = useAppDispatch()
  const { carSnoopApi } = useApiContext()
  const { loginService } = useAuthenticateUser()

  const onSuccess = async (response: CredentialResponse) => {
    const decodeData: JWTResponse = jwtDecode(response?.credential as string)
    const {
      email,
      given_name: firstName,
      family_name: lastName,
      picture,
      sub
    } = decodeData

    const payload = await carSnoopApi.users.checkUser(email)

    if (payload.status === ResponseStatusMap.Error) {
      dispatch(
        actionsUserInfo.register({
          id: payload.id,
          idp: 'Google',
          email,
          firstName,
          lastName,
          avatar: picture,
          sub
        })
      )
      return
    }

    loginService({ email, password: sub, idp: 'Google' })
  }

  return (
    <Box
      sx={{
        borderRadius: '4px',
        boxShadow: SHADOWS.hover,
        marginRight: '6px',
        maxWidth: '192px',
        width: 'calc((50% - (0.5em) / 2) + 0.5px)',
      }}
    >
      <GoogleLogin
        theme='outline'
        size='large'
        text='signin_with'
        locale='en_US'
        width='192px'
        onSuccess={onSuccess}
        onError={() => console.warn('Google login failed')}
      />
    </Box>
  )
}
