import { Paper, Typography, styled } from '@mui/material'
import { COLORS } from '../../types/colors'

export const AutoselectPaper = styled(Paper)`
  & .MuiAutocomplete-listbox .MuiAutocomplete-option {
    background-color: ${COLORS.white};
    padding: 0;
  }

  & .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused {
    background-color: ${COLORS.secondary_background};
  }
`

export const AutoselectTag = styled(Typography)`
  position: relative;
  top: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
