import * as Yup from 'yup'

import { latinWithSpecial } from '../../utils/validators'
import { validationPattern } from '../../molecules/AddOn/AddOn.validation'
import { OFFER_MODAL_FIELDS } from './OfferModal.constants'

export const validationSchema = Yup.object({
  [OFFER_MODAL_FIELDS.ADD_ONS]: validationPattern,
  [OFFER_MODAL_FIELDS.NOTES]: Yup.string().test(
    'is-latin-or-special',
    'Enter a valid data format.',
    latinWithSpecial(['\n', ' ', '€', '£'])
  ),
  [OFFER_MODAL_FIELDS.VEHICLE_PRICE]: Yup.string().required(''),
  [OFFER_MODAL_FIELDS.SALES_TAX]: Yup.string().required('')
})
