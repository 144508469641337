import { Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { NonNullableRecord } from '../../../types/NonNullableRecord'
import { OfferDetailsWidgetProps } from '../types'

type OfferNotesProps = NonNullableRecord<Pick<OfferDetailsWidgetProps, 'notes'>>

export const OfferNotes: FC<OfferNotesProps> = ({ notes }) => {
  return (
    <Grid container flexDirection='column' rowSpacing={2}>
      <Grid item>
        <Typography variant='main' fontWeight='600'>
          Offer Notes
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant='body1'
          sx={{
            wordBreak: 'normal',
            overflowWrap: 'anywhere',
            whiteSpace: 'pre-wrap'
          }}
        >
          {notes}
        </Typography>
      </Grid>
    </Grid>
  )
}
