import { RawShipmentResponse } from './raw'
import { NormalizedShipmentResponse } from './normalized'

export const shipmentMapper = (
  response: RawShipmentResponse
): NormalizedShipmentResponse => {
  const { orderId, iframe, pickup, dropoff, vehicle } = response

  return {
    orderID: orderId,
    iframe,
    pickup,
    dropOff: dropoff,
    vehicle
  }
}
