import { Grid } from '@mui/material'
import { format } from 'date-fns'
import { FC, useEffect, useLayoutEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Condition } from '../../atoms/Condition'
import { FormContainer } from '../../layout/FormContainer'
import { ErrorScreen } from '../../molecules/ErrorScreen'
import { routes } from '../../routes'
import { ERROR_REASONS } from '../../types/enums/network'
import { Fields } from '../../types/forms/registrationByEmail'
import { ResponseStatusMap } from '../../types/status'
import { useApiContext, useMakeService } from '../../utils/hooks'
import { isValidEmail } from '../../utils/validators'
import {
  RegistrationByEmailScreen,
  RegistrationByEmailSuccessScreen
} from '../screens/RegistrationByEmail'
import { INVITATION_LINK_ERROR } from '../screens/RegistrationByEmail/constants'
import { DividerStyles } from './RegistrationByEnail.styles'

export const RegistrationByEmailForm: FC = () => {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const { carSnoopApi } = useApiContext()

  const email = params.get('email') || ''
  const accountId = params.get('accountId') || ''
  const verificationCode = params.get('verificationCode') || ''

  const [checkLink, checkLinkObservable] = useMakeService(
    async (emailToCheck: string) => {
      const res = await carSnoopApi.users.checkUser(emailToCheck)

      if (res.status === ResponseStatusMap.Success) {
        return { status: ResponseStatusMap.Error }
      }

      if (res.reason === ERROR_REASONS.EXIST_NOT_VERIFIED) {
        return { status: ResponseStatusMap.Success }
      }

      return { status: ResponseStatusMap.Error }
    }
  )

  const [registerUser, registerUserObservable] = useMakeService(
    (data: Fields) => {
      return carSnoopApi.accounts.registerInvitedUser({
        ...data,
        accountId,
        email,
        birthDate: format(data.birthDate as Date, 'yyyy-MM-dd'),
        idp: 'CarSnoop',
        // TODO: UNIQUE ID - we have to find a way to get unique id;
        deviceId: '14:7d:da:49:4e:07',
        verificationCode
      })
    }
  )

  const { isLoading, isSuccess, isError } = registerUserObservable

  useEffect(() => {
    if (!isValidEmail(false)(email)) {
      navigate(routes.login)
    }
  })

  useLayoutEffect(() => {
    if (isSuccess) {
      navigate(routes.login)
    }
  }, [isSuccess])

  useLayoutEffect(() => {
    checkLink(email)
  }, [email])

  return (
    <Condition
      condition={!!isError || !!checkLinkObservable.isError}
      trueContent={
        <ErrorScreen
          isLoading={isLoading || !email}
          subtitle={INVITATION_LINK_ERROR.SUBTITLE}
          title={INVITATION_LINK_ERROR.TITLE}
        />
      }
      falseContent={
        <Condition
          condition={isSuccess}
          trueContent={<RegistrationByEmailSuccessScreen />}
          falseContent={
            <FormContainer title='Register'>
              <Grid container>
                <Grid container item xs={12} my={2}>
                  <DividerStyles />
                </Grid>
                <Grid container item xs={12}>
                  <RegistrationByEmailScreen
                    isLoading={isLoading || checkLinkObservable.isLoading}
                    email={email}
                    onSubmit={registerUser}
                  />
                </Grid>
              </Grid>
            </FormContainer>
          }
        />
      }
    />
  )
}
