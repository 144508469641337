import { Grid, Link, Typography } from '@mui/material'
import { FC, useCallback, useMemo } from 'react'
import {
  NormalizedContractType,
  NormalizedTradeInType,
  NormalizedTransactionType,
  NormalizedVehicleType
} from '../../../../../../api/carSnoopApi/transactions/types'
import { ActionBlock } from '../../../../../../atoms/ActionBlock'
import { Show } from '../../../../../../atoms/JSXExtensions'
import { LinkButton } from '../../../../../../atoms/LinkButton'
import { Loader } from '../../../../../../atoms/Loader'
import { SUPPORT_EMAIL } from '../../../../../../constants/applicationConstants'
import {
  DeliveryCard,
  UseDeliveryCardProps,
  useDeliveryCard
} from '../../../../../../features/DeliveryCard'
import { CommonConfirmationModal } from '../../../../../../molecules/Modal/CommonConfirmationModal'
import { OrNull } from '../../../../../../types/OrNull'
import { COLORS } from '../../../../../../types/colors'
import { ResponseStatusMap } from '../../../../../../types/status'
import {
  useCreateShipment,
  useMakeService,
  useModal,
  useUserInfo
} from '../../../../../../utils/hooks'
import { useUpdateContractTransaction } from '../../../../hooks/useUpdateContractTransaction'
import { completeDealHint, completeDealMessage } from './constants'
import { useTrackShipmentURL } from './hooks/useTrackShipmentURL'

interface TrackableViewProps {
  tradeIn: OrNull<NormalizedTradeInType>
  vehicle: NormalizedVehicleType
  contract: NormalizedContractType
  trackingNumber: NormalizedTransactionType['trackingNumber']
  transactionId: string
  completeDeal?: () => void
  shipmentCreationError?: boolean
  isDelivered?: boolean
}

const ShipmentError = () => {
  return (
    <Typography
      variant='body1'
      whiteSpace='pre-wrap'
      color={COLORS.text_red}
    >
      {`Something went wrong!\nPlease, check the address details and confirm the shipping one more time,\nor contact `}
      <Link href={`mailto:${SUPPORT_EMAIL}`}>CarSnoop support</Link>
    </Typography>
  )
}

export const TrackableView: FC<TrackableViewProps> = ({
  tradeIn,
  contract,
  trackingNumber,
  vehicle,
  transactionId,
  shipmentCreationError = false,
  isDelivered = false,
  completeDeal
}) => {
  const { pickup, dropOff } = contract

  const completeDealModal = useModal(completeDeal)
  const { isAdmin } = useUserInfo()
  const { updateTransaction, isLoading } = useUpdateContractTransaction()

  const { createShipmentServiceObservable, createShipmentService } =
    useCreateShipment()

  const shipmentInfo: UseDeliveryCardProps['shipmentInfo'] = useMemo(
    () => ({
      vehicle: {
        pickup,
        dropOff
      },
      tradeIn: tradeIn
        ? {
          pickup: dropOff,
          dropOff: pickup
        }
        : null
    }),
    [pickup, dropOff, tradeIn]
  )

  const { deliveryVehicleProps, tradeInVehicleProps } = useDeliveryCard({
    vehicle,
    tradeIn,
    shipmentInfo
  })

  const { trackShipmentURL, requestShipmentObservable } =
    useTrackShipmentURL(trackingNumber)

  const onTrackShipmentClick = useCallback(() => {
    if (trackShipmentURL) {
      window.open(trackShipmentURL, '_blank')?.focus()
    }
  }, [trackShipmentURL])

  const [handleConfirmClick] = useMakeService(
    async () => {
      const res = await createShipmentService(transactionId)

      if (res.status === ResponseStatusMap.Success) {
        updateTransaction({ id: transactionId })
      }

      return res
    },
    {
      withStatusNotification: [1]
    }
  )

  const buttonProps = useMemo(
    () => ({
      label: shipmentCreationError ? 'Confirm' : 'Complete the Deal',
      onClick: shipmentCreationError
        ? handleConfirmClick
        : completeDealModal.onOpen,
      disabled: !isAdmin
    }),
    [shipmentCreationError, handleConfirmClick]
  )

  return (
    <Show
      when={
        !createShipmentServiceObservable.isLoading &&
        (requestShipmentObservable.isSuccess ||
          requestShipmentObservable.isError) &&
        !isLoading
      }
      fallback={
        <Grid item container justifyContent='center'>
          <Loader small />
        </Grid>
      }
    >
      <>
        <Grid item container justifyContent='space-between' mb={4}>
          <Show when={!shipmentCreationError} fallback={<ShipmentError />}>
            <>
              <Grid item>
                <Typography variant='label1'>
                  Order for shipment is created
                </Typography>
              </Grid>
              <Grid item>
                <LinkButton
                  label='Track Shipment'
                  onClick={onTrackShipmentClick}
                />
              </Grid>
            </>
          </Show>
        </Grid>
        <Show when={!completeDeal && !isDelivered}>
          <Grid item mb={4}>
            <Typography variant='subtitle2'>
              Once the Buyer receives the vehicle, the deal will be finalized!
            </Typography>
          </Grid>
        </Show>
        <Grid item>
          <Grid item>
            <DeliveryCard {...deliveryVehicleProps} isDelivered={isDelivered} />
          </Grid>
          <Show when={tradeInVehicleProps}>
            {(props) => (
              <Grid item>
                <DeliveryCard {...props} isDelivered={isDelivered} />
              </Grid>
            )}
          </Show>
          <Show when={completeDeal}>
            <Grid item mt={4} width='100%'>
              <ActionBlock
                schema={[
                  {
                    type: 'primary',
                    ...buttonProps
                  }
                ]}
              />
            </Grid>
          </Show>
        </Grid>
        <CommonConfirmationModal
          {...completeDealModal}
          message={completeDealMessage}
          hint={completeDealHint}
        />
      </>
    </Show>
  )
}
