import { useCallback, useEffect, useMemo, useState } from 'react'
import { OrNull } from '../../../../../../types/OrNull'
import {
  shippingOptions,
  shipmentTypesMap
} from '../BuyerStripeAwaitingPayment/state.constants'
import { BuyerStripeAwaitingPaymentProps } from '../BuyerStripeAwaitingPayment/types'
import { useApiContext, useMakeService } from '../../../../../../utils/hooks'
import { ResponseStatusMap } from '../../../../../../types/status'
import { ShipmentInfoTypes } from '../../../../../../features/DeliveryCard'
import { AddShipmentDetailsParamsAddress } from '../../../../../../api/carSnoopApi/shipments/types'
import { ShipmentTypes } from '../../../../../../constants/applicationConstants'

const isAddressComplete = (
  address: AddShipmentDetailsParamsAddress
): boolean => {
  // Looks like it's only field that must be checked. Check it
  return !!address.street
}

export const useShipmentInfo = ({
  contract,
  tradeIn,
  vehicle
}: Pick<
  BuyerStripeAwaitingPaymentProps,
  'contract' | 'tradeIn' | 'vehicle'
>) => {
  const { externalCarSnoopApi } = useApiContext()

  const [shipmentType, setShipmentType] = useState<ShipmentTypes>(
    shipmentTypesMap[contract.shipmentType] || shippingOptions[0].name
  )

  const [pickup, setPickup] = useState<AddShipmentDetailsParamsAddress>(
    contract.pickup
  )

  const [dropOff, setDropOff] = useState<AddShipmentDetailsParamsAddress>(
    contract.dropOff
  )

  const [shippingCost, setShippingCost] = useState<OrNull<number>>(null)

  const [shippingDistance, setShippingDistance] = useState<number>(
    parseInt(vehicle.distance, 10) || 0
  )

  const shipmentInfo: OrNull<ShipmentInfoTypes> = useMemo(() => {
    if (shipmentType === ShipmentTypes.local) {
      return null
    }

    return {
      vehicle: {
        cost: shippingCost || 0,
        distance: shippingDistance,
        pickup,
        dropOff
      },
      tradeIn: tradeIn
        ? {
            pickup: dropOff,
            dropOff: pickup
          }
        : null
    }
  }, [shipmentType, shippingCost, pickup, dropOff, tradeIn, shippingDistance])

  const [readShippingCostService, readShippingCostObservable] = useMakeService(
    async ({
      fromZipCode,
      toZipCode
    }: {
      fromZipCode: string
      toZipCode: string
    }) => {
      const response = await externalCarSnoopApi.vehicles.readShippingCost({
        fromZipCode,
        toZipCode,
        vehicle: {
          make: vehicle.make,
          model: vehicle.model,
          year: vehicle.year.toString(),
          vin: vehicle.vin
        },
        tradeinVehicle: tradeIn
          ? {
              make: tradeIn.make,
              model: tradeIn.model,
              year: tradeIn.year.toString(),
              vin: tradeIn.vin
            }
          : undefined
      })

      if (response.status === ResponseStatusMap.Success) {
        setShippingCost(response.cost)
        setShippingDistance(response.distance)
      }
      return response
    }
  )

  useEffect(() => {
    if (
      shipmentInfo &&
      shippingCost === null &&
      shipmentType === ShipmentTypes.shipping
    ) {
      readShippingCostService({
        fromZipCode: shipmentInfo?.vehicle.pickup.zipCode,
        toZipCode: shipmentInfo?.vehicle.dropOff.zipCode
      })
    }
  }, [shipmentInfo, shippingCost, shipmentType])

  const onSetPickup = useCallback(
    (pickupAddress: AddShipmentDetailsParamsAddress) => {
      setPickup(pickupAddress)
      if (shipmentInfo) {
        readShippingCostService({
          fromZipCode: pickupAddress.zipCode,
          toZipCode: shipmentInfo.vehicle.dropOff.zipCode
        })
      }
    },
    [setPickup, shipmentInfo, readShippingCostService]
  )

  const onSetDropOff = useCallback(
    (dropOffAddress: AddShipmentDetailsParamsAddress) => {
      setDropOff(dropOffAddress)
      if (shipmentInfo) {
        readShippingCostService({
          fromZipCode: shipmentInfo.vehicle.pickup.zipCode,
          toZipCode: dropOffAddress.zipCode
        })
      }
    },
    [setDropOff, shipmentInfo, readShippingCostService]
  )

  const onShipmentTypeChange = useCallback(
    (type: ShipmentTypes) => {
      setShipmentType(type)
    },
    [setShipmentType]
  )

  const addressesAreValid = useMemo(() => {
    const pickupAddressIsValid = isAddressComplete(pickup)
    const dropOffAddressIsValid = isAddressComplete(dropOff)

    return pickupAddressIsValid && dropOffAddressIsValid
  }, [pickup, dropOff])

  const isDisabled = useMemo(
    () =>
      shipmentType === ShipmentTypes.shipping &&
      (!readShippingCostObservable.isSuccess || !addressesAreValid),
    [readShippingCostObservable.isSuccess, shipmentType, addressesAreValid]
  )

  return {
    isLoading: readShippingCostObservable.isLoading,
    isDisabled,
    addressesAreValid,
    shipmentType,
    shipmentInfo,
    onShipmentTypeChange,
    setPickup: onSetPickup,
    setDropOff: onSetDropOff
  }
}
