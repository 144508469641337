import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IBreadcrumbItem } from '../../../atoms/Breadcrumbs'

interface BreadcrumbsState {
  breadcrumbs: IBreadcrumbItem[]
}

export const initialState: BreadcrumbsState = {
  breadcrumbs: []
}

export const breadcrumbsSlice = createSlice({
  name: 'breadcrumbs',
  initialState,
  reducers: {
    pushBreadcrumb: (state, action: PayloadAction<IBreadcrumbItem>) => {
      state.breadcrumbs = [...state.breadcrumbs, action.payload]
    },
    popBreadcrumb: (state) => {
      state.breadcrumbs.pop()
    },
    setBreadcrumbs: (state, action: PayloadAction<IBreadcrumbItem[]>) => {
      state.breadcrumbs = action.payload
    }
  }
})
