import { FC, useEffect } from 'react'
import { useFormikContext } from 'formik'

export const AutoSubmitInput: FC = () => {
  const { isValid, handleSubmit, values } = useFormikContext()

  useEffect(() => {
    if (isValid) {
      handleSubmit()
    }
  }, [isValid, handleSubmit, values])

  return null
}
