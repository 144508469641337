import { Typography } from '@mui/material'
import { FC } from 'react'
import { COLORS } from '../../types/colors'

type ErrorMessageBlockProps = {
  message: string
}

export const ErrorMessageBlock: FC<ErrorMessageBlockProps> = ({ message }) => {
  return (
    <Typography variant='subtitle1' color={COLORS.error_message_red}>
      {message}
    </Typography>
  )
}
