import { Grid, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { useInventorySelectStatus } from './useInventorySelectStatus'
import { SelectField } from '../../atoms/SelectElement'
import { AutoSubmitInput } from '../../atoms/AutoSubmitInput'
import { SelectStatusProps } from './types'

export const FilterInventoryByStatusControl = <T extends string, V>(
  props: SelectStatusProps<T, V>
) => {
  const { initialValues, options, onStatusChange, FIELD_NAMES, selectFormRef } =
    useInventorySelectStatus(props)

  const { disabled } = props

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount
      enableReinitialize
      onSubmit={onStatusChange}
      innerRef={selectFormRef}
    >
      {() => {
        return (
          <Form style={{ width: '100%' }}>
            <AutoSubmitInput />
            <Grid container spacing={1} wrap='nowrap' alignItems='center'>
              <Grid item width='fit-content'>
                <Typography variant='subtitle2' textTransform='uppercase'>
                  Status:
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <SelectField
                  disabled={disabled}
                  name={FIELD_NAMES.status}
                  options={options}
                />
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}
