import { Grid, styled } from '@mui/material'
import { COLORS } from '../../../../../../types/colors'

export const ChatContainer = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'nowrap',
  padding: '24px 24px',
  backgroundColor: 'white',
  borderBottom: `1px solid ${COLORS.border_gray}`
}))
