import { RadioGroup, Radio, FormControlLabel } from '@mui/material'
import { FC, ChangeEvent } from 'react'

type RadioToggleProps = {
  checked: boolean
  trueLabel?: string
  falseLabel?: string
  row?: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void
}

export const RadioToggle: FC<RadioToggleProps> = ({
  checked,
  trueLabel = 'Yes',
  falseLabel = 'No',
  row,
  onChange
}) => {
  const checkedValue = checked ? trueLabel : falseLabel

  const handleChange = (event: ChangeEvent<HTMLInputElement>, _: string) => {
    if (event.target.value === trueLabel) {
      return onChange(event, true)
    }
    onChange(event, false)
  }

  return (
    <RadioGroup row={row} value={checkedValue} onChange={handleChange}>
      <FormControlLabel
        value={trueLabel}
        control={<Radio color='default' {...(row && { sx: { ml: 4 } })} />}
        label={trueLabel}
      />
      <FormControlLabel
        value={falseLabel}
        control={<Radio color='default' {...(row && { sx: { ml: 4 } })} />}
        label={falseLabel}
      />
    </RadioGroup>
  )
}
