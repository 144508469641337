import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReadVehicleResponse } from '../../../api/carSnoopApi/vehicles'
import { routes } from '../../../routes'
import { OrNull } from '../../../types/OrNull'
import { ResponseStatusMap } from '../../../types/status'
import { useApiContext, useMakeService } from '../../../utils/hooks'

export const useSellingRequestVehicle = (vehicleId: string) => {
  const [currentVehicle, setCurrentVehicle] =
    useState<OrNull<ReadVehicleResponse>>(null)
  const { carSnoopApi } = useApiContext()
  const navigate = useNavigate()

  const [readVehicle] = useMakeService(async (id: string) => {
    const response = await carSnoopApi.vehicles.readVehicle(id)

    if (response.status === ResponseStatusMap.Success) {
      setCurrentVehicle(response)
      return response
    }
    return { status: ResponseStatusMap.Error }
  })

  const [declineTransaction] = useMakeService(
    async (id: string) => {
      const res = await carSnoopApi.requests.declineTransaction({
        id
      })

      if (res.status === ResponseStatusMap.Success) {
        setCurrentVehicle(null)
        const response = await carSnoopApi.vehicles.readVehicle(vehicleId)

        if (response.status === ResponseStatusMap.Success) {
          setCurrentVehicle(response)
          if (!response.requestCount) {
            navigate(routes.deals.index)
          }

          return response
        }

        return { status: ResponseStatusMap.Error }
      }
    },
    { withStatusNotification: true }
  )

  const [declineAllRequests, declineAllRequestsObservable] = useMakeService(
    async () => {
      const res = await carSnoopApi.requests.declineAllRequests(vehicleId)

      if (res.status === ResponseStatusMap.Success) {
        setCurrentVehicle(null)
        const response = await carSnoopApi.vehicles.readVehicle(vehicleId)

        if (response.status === ResponseStatusMap.Success) {
          setCurrentVehicle(response)
          return response
        }

        return { status: ResponseStatusMap.Error }
      }
    },
    { withStatusNotification: true }
  )

  useEffect(() => {
    vehicleId && readVehicle(vehicleId)
  }, [vehicleId])

  useEffect(() => {
    if (declineAllRequestsObservable.status === 'succeeded') {
      navigate(routes.deals.index)
    }
  }, [declineAllRequestsObservable.status])

  return {
    vehicle: currentVehicle,
    declineTransaction,
    declineAllRequests
  }
}
