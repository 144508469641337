import { FC } from 'react'
import { FindCarForm } from '../../../../forms/FindCar'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const FindCarFormExample: FC = () => {
  return (
    <ComponentContainer fullWidth>
      <ComponentWithPaperBackground title='Not in features folder (in forms/FindCar)'>
        <FindCarForm />
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
