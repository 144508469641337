import {
  DragEventHandler,
  FC,
  MouseEventHandler,
  ReactNode,
  useCallback
} from 'react'
import { Box } from '@mui/material'

type StopPropagationContainerType = {
  preventDragEvents?: boolean
  children: ReactNode
}

export const StopPropagationContainer: FC<StopPropagationContainerType> = ({
  children,
  preventDragEvents = false
}) => {
  const stopPropagationClick: MouseEventHandler = useCallback((event) => {
    event.stopPropagation()
  }, [])

  const dragAndDropHandler: DragEventHandler = useCallback(
    (event) => {
      if (preventDragEvents) event.preventDefault()
    },
    [preventDragEvents]
  )

  return (
    <Box
      onClick={stopPropagationClick}
      onDragOver={dragAndDropHandler}
      onDrop={dragAndDropHandler}
    >
      {children}
    </Box>
  )
}
