import { FC, useCallback, useEffect, useMemo } from 'react'
import { Grid } from '@mui/material'
import { EmptyChat } from '../../assets/imgs/EmptyChat'
import { For, Show } from '../../atoms/JSXExtensions'
import { Loader } from '../../atoms/Loader'
import { DefaultEmptyState } from '../../atoms/DefaultEmptyState'
import { ChatContainer } from './view/ChatContainer'
import { ChatMediaMessage } from './view/ChatMediaMessage'
import { TextMessage } from './view/ChatTextMessage'
import { useChatReader } from './controller/reader'
import { useChatUpdater } from './controller/updater'
import { ChatProps } from './types'
import { OrNull } from '../../types/OrNull'
import { useRenovator } from './Renovator/useRenovator'
import { config } from '../../constants/config'

export const Chat: FC<ChatProps> = (props) => {
  const { onChatUnmount, height = '100%', isMinimized = false, chatId } = props
  const { messages, requestMessages } = useChatReader(props)
  const {
    sendTextMessage,
    sendMediaMessage,
    markMessagesAsRead,
    isFileUploading,
    isMessageSending
  } = useChatUpdater(props)

  const { renovator } = useRenovator({
    renovateFunction: requestMessages,
    renovatePeriod: Number(config.UPDATE_CHAT_PERIOD)
  })

  const handleSendTextMessage = useCallback(
    async (params: Parameters<typeof sendTextMessage>[0]) => {
      await sendTextMessage(params)
      await renovator.renovate()
    },
    [sendTextMessage, renovator]
  )

  const handleSendMediaMessage = useCallback(
    async (...params: Parameters<typeof sendMediaMessage>) => {
      await sendMediaMessage(...params)
      renovator.renovate()
    },
    [sendMediaMessage, renovator]
  )

  const unreadMessagesIds: OrNull<string[]> = useMemo(() => {
    if (!messages) {
      return null
    }

    return messages.reduce<string[]>((acc, message) => {
      const { id, hasRead, isSender } = message
      if (hasRead || isSender) {
        return acc
      }
      return [...acc, id]
    }, [])
  }, [messages])

  useEffect(() => {
    if (!unreadMessagesIds || !unreadMessagesIds.length) {
      return
    }

    markMessagesAsRead(unreadMessagesIds)
  }, [unreadMessagesIds])

  useEffect(() => {
    renovator.start()

    return () => {
      renovator.stop()
      onChatUnmount()
    }
  }, [])

  return (
    <ChatContainer
      onSend={handleSendTextMessage}
      onFileSend={handleSendMediaMessage}
      height={height}
      isUploadInProgress={isFileUploading || isMessageSending}
      chatId={chatId}
    >
      <Show
        when={messages}
        fallback={
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            height='100%'
          >
            <Loader small />
          </Grid>
        }
      >
        {(messageSet) => {
          return (
            <Show
              when={!!messageSet.length}
              fallback={
                <Grid
                  container
                  justifyContent='center'
                  alignItems='center'
                  height='100%'
                >
                  <DefaultEmptyState
                    text='No messages yet…'
                    image={<EmptyChat />}
                  />
                </Grid>
              }
            >
              <Grid
                container
                justifyContent='center'
                alignItems='center'
                height='100%'
                minHeight='min-content'
                p={2}
              >
                <Grid
                  container
                  item
                  direction='column'
                  spacing={2}
                  alignSelf='flex-end'
                  wrap='nowrap'
                  height='auto'
                >
                  <For of={messageSet}>
                    {(message) => {
                      if ('contentId' in message) {
                        return (
                          <Grid item key={message.id} xs={12}>
                            <ChatMediaMessage
                              {...message}
                              isMinimized={isMinimized}
                            />
                          </Grid>
                        )
                      }

                      return (
                        <Grid item key={message.id} xs={12}>
                          <TextMessage {...message} isMinimized={isMinimized} />
                        </Grid>
                      )
                    }}
                  </For>
                </Grid>
              </Grid>
            </Show>
          )
        }}
      </Show>
    </ChatContainer>
  )
}
