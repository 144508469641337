import { FC } from 'react'
import { Form, Formik } from 'formik'
import { Grid } from '@mui/material'

import { AutoselectInputField } from '../../../../atoms/AutoselectInputField'
import { AutoselectOption } from '../../../../atoms/AutoselectOption'
import { AutoselectColorOption } from '../../../../atoms/AutoselectColorOption'
import { ActionBlock } from '../../../../atoms/ActionBlock'

import { ReferenceModel } from '../../../../types/ReferenceModel'
import { ColorOption } from '../../../../types/Autoselect'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const AutoSelectExample: FC = () => {
  const onNextBtnClicked = (data: unknown): void => {
    // eslint-disable-next-line
    console.log('>>>', data)
  }
  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <Formik initialValues={{}} onSubmit={onNextBtnClicked} validateOnMount>
          {() => {
            return (
              <Form>
                <Grid container spacing={2} flexDirection='column'>
                  <Grid item>
                    <AutoselectInputField<ReferenceModel>
                      name='film'
                      placeholder='First Name'
                      label='Film'
                      options={[
                        { name: 'The Godfather', id: '1' },
                        { name: 'Pulp Fiction', id: '1' }
                      ]}
                      renderOption={AutoselectOption}
                      getOptionLabel={(o) => o.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                    />
                  </Grid>
                  <Grid item>
                    <AutoselectInputField<ColorOption>
                      name='color'
                      placeholder='First Name'
                      label='First Name'
                      options={[
                        {
                          name: 'ColorOne',
                          id: '1',
                          swatch: '#ffffff'
                        },
                        {
                          name: 'ColorTwo',
                          id: '2',
                          swatch: '#000000'
                        },
                        {
                          name: 'ColorThree',
                          id: '3',
                          swatch: '#fff000'
                        }
                      ]}
                      renderOption={AutoselectColorOption}
                      getOptionLabel={(o) => o.name}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      multiple
                    />
                  </Grid>
                  <Grid item>
                    <ActionBlock
                      schema={[
                        {
                          label: 'Next',
                          type: 'primary',
                          props: {
                            type: 'submit'
                          }
                        }
                      ]}
                    />
                  </Grid>
                </Grid>
              </Form>
            )
          }}
        </Formik>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
