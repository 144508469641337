import * as Yup from 'yup'
import { countOfNumbers } from '../../../utils/validators'
import { parseSSN } from '../../../utils/formatters/parseSSN'

const ssnLength = 9

export const validationSchema = Yup.object({
  ssn: Yup.string()
    .test('length', 'Enter a valid SSN.', countOfNumbers(ssnLength))
    .test('area', 'Enter a valid SSN.', (value) => {
      if (!value) return true

      const { area } = parseSSN(value)

      const startWithZero = area === '000'
      const startWithSix = area === '666'
      const over900 = parseInt(area, 10) >= 900

      return !startWithSix && !startWithZero && !over900
    })
    .test('group', 'Enter a valid SSN.', (value) => {
      if (!value) return true

      const { group } = parseSSN(value)

      return group !== '00'
    })
    .test('serial', 'Enter a valid SSN.', (value) => {
      if (!value) return true

      const { serial } = parseSSN(value)

      return serial !== '0000'
    })
    .required('')
})
