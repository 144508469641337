import { Grid, Typography } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ReadVehicleResponse } from '../../../../api/carSnoopApi/vehicles'
import { BlueButton } from '../../../../atoms/Button'
import { CollapsibleNotes } from '../../../../atoms/CollapsibleNotes'
import { Condition } from '../../../../atoms/Condition'
import { Loader } from '../../../../atoms/Loader'
import { FormOrchestratorStep } from '../../../../layout/FormOrchestrator/types'
import { ImagesCarousel } from '../../../../molecules/ImagesCarousel'
import type { Address } from '../../../../store/types'
import { OrNull } from '../../../../types/OrNull'
import { COLORS } from '../../../../types/colors'
import { ResponseStatusMap } from '../../../../types/status'
import {
  useApiContext,
  useDeviceInfo,
  useMakeService,
  useUserInfo
} from '../../../../utils/hooks'
import {
  DesktopVehicleDetails,
  TabletVehicleDetails
} from '../../../VehicleDetails/components/VehicleDetails/Layouts'
import { DisplayStandardEquipment } from '../../../VehicleDetails/components/VehicleDetails/StandardEquipment'
import { VehicleDetailsMainInfo } from '../../../VehicleDetails/components/VehicleDetailsMainInfo'
import { VehicleDetailsOverview } from '../../../VehicleDetails/components/VehicleDetailsOverview'
import { useAddVehicleFormSlice } from '../hooks'
import { UploadVehiclePhotoGreetingMessage } from './UploadVehiclePhotoGreetingMessage'

export const AddVehicleUploadPhotos: FormOrchestratorStep = () => {
  const { newVehicleId, isAddVehicleModalOpen, closeAddVehicleModal } =
    useAddVehicleFormSlice()
  const { addresses, isDealer } = useUserInfo()
  const { isTablet } = useDeviceInfo()
  const { carSnoopApi } = useApiContext()

  const [currentVehicle, setCurrentVehicle] =
    useState<OrNull<ReadVehicleResponse>>(null)

  const readVehicleSilent = useCallback(
    async (id: string) => {
      const response = await carSnoopApi.vehicles.readVehicle(id)

      if (response.status === ResponseStatusMap.Success) {
        setCurrentVehicle(response)

        return response
      }
      return { status: ResponseStatusMap.Error }
    },
    [setCurrentVehicle]
  )

  const [readVehicle, readVehicleObservable] = useMakeService(readVehicleSilent)

  const handleImagesChange = useCallback(() => {
    readVehicleSilent(newVehicleId)
  }, [newVehicleId, readVehicleSilent])

  useEffect(() => {
    newVehicleId && readVehicle(newVehicleId)
  }, [newVehicleId])

  const {
    year,
    make,
    model,
    trim,
    vin,
    mileage,
    condition,
    retailValue,
    tradeinValue,
    addressID,
    notes,
    vehicleStatus,
    interiorColor,
    exteriorColor,
    fuelType,
    engineDescription,
    transmission,
    mpg,
    style,
    drivetrain,
    stockNumber,
    images,
    standardEquipment,
    accountID: vehicleAccountID
  } = (currentVehicle || {}) as ReadVehicleResponse

  const vehicleProps = {
    interiorColor,
    exteriorColor,
    fuelType,
    engineDescription,
    transmission,
    mpg,
    style,
    drivetrain
  }

  const carName = useMemo(
    () => `${year} ${make} ${model}${trim ? ` ${trim}` : ''}`,
    [year, make, model, trim]
  )

  const carAddress = useMemo(() => {
    return addresses.find((a) => a.id === addressID)
  }, [addresses, addressID])

  const isLoading = readVehicleObservable.isLoading || !currentVehicle

  const imagesComponent = useMemo(
    () => (
      <ImagesCarousel
        vehicleId={newVehicleId}
        images={images}
        hint={`First photo will be the main image.
                    You may add up to 28 photos of your vehicle. Accepted file formats: .jpeg, .png.`}
        showAddButton={false}
        onLoadCallback={handleImagesChange}
      />
    ),
    [images, newVehicleId, handleImagesChange]
  )

  const vehicleDetailsComponent = useMemo(
    () => <VehicleDetailsOverview fullView={!!vin} {...vehicleProps} />,
    [vin, vehicleProps]
  )

  const sellerNotesComponent = useMemo(
    () => <CollapsibleNotes initialExpanded>{notes}</CollapsibleNotes>,
    [notes]
  )

  const vehicleInfoComponent = useMemo(
    () => (
      <VehicleDetailsMainInfo
        vin={vin}
        mileage={mileage}
        condition={condition}
        retailValue={retailValue}
        tradeinValue={tradeinValue}
        address={carAddress as Address}
        isDeleteButtonEnabled={false}
        isDealer={isDealer}
        stockNumber={stockNumber}
        status={vehicleStatus}
        vehicleAccountID={vehicleAccountID}
      />
    ),
    [
      vin,
      mileage,
      condition,
      retailValue,
      tradeinValue,
      carAddress,
      isDealer,
      stockNumber
    ]
  )

  const standardEquipmentComponent = useMemo(
    () => (
      <DisplayStandardEquipment equipment={standardEquipment} initialExpanded />
    ),
    [standardEquipment]
  )

  const greetingHintComponent = useMemo(
    () =>
      isAddVehicleModalOpen ? (
        <Typography variant='h6'>
          Now it&apos;s time to add some photos and then go
          <BlueButton
            onClick={closeAddVehicleModal}
            sx={{ paddingTop: '2px', textTransform: 'lowercase' }}
          >
            <Typography
              variant='h6'
              color={COLORS.text_blue}
              alignItems='baseline'
            >
              back
            </Typography>
          </BlueButton>
          to your Request.
        </Typography>
      ) : null,
    [isAddVehicleModalOpen]
  )

  return (
    <Condition
      condition={isLoading}
      trueContent={
        <Grid container justifyContent='center'>
          <Loader />
        </Grid>
      }
      falseContent={
        <Grid container spacing={4}>
          <Grid container item>
            <Grid container item columnSpacing={3}>
              <Grid item xs={12} mb={3}>
                <UploadVehiclePhotoGreetingMessage
                  hintComponent={greetingHintComponent}
                  carName={carName}
                />
              </Grid>
              <Condition
                condition={isTablet}
                trueContent={
                  <TabletVehicleDetails
                    imagesComponent={imagesComponent}
                    standardEquipmentComponent={standardEquipmentComponent}
                    vehicleDetailsComponent={vehicleDetailsComponent}
                    sellerNotesComponent={sellerNotesComponent}
                    vehicleInfoComponent={vehicleInfoComponent}
                  />
                }
                falseContent={
                  <DesktopVehicleDetails
                    imagesComponent={imagesComponent}
                    standardEquipmentComponent={standardEquipmentComponent}
                    vehicleDetailsComponent={vehicleDetailsComponent}
                    sellerNotesComponent={sellerNotesComponent}
                    vehicleInfoComponent={vehicleInfoComponent}
                  />
                }
              />
            </Grid>
          </Grid>
        </Grid>
      }
    />
  )
}
