import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChatsIcon } from '../../../../assets/icons/ChatsIcon';
import { ChatsIconWithNotification } from '../../../../assets/icons/ChatsIconWithNotification';
import { Document } from '../../../../assets/icons/Document';
import { GearIcon } from '../../../../assets/icons/GearIcon';
import { LogOutIcon } from '../../../../assets/icons/LogOutIcon';
import { MenuArrowIcon } from '../../../../assets/icons/MenuArrowIcon';
import { SubscriptionsIcon } from '../../../../assets/icons/SubscriptionsIcon';
import { SupportIcon } from '../../../../assets/icons/SupportIcon';
import { UnknownUserIcon } from '../../../../assets/icons/UnknownUser';
import { EllipsisText } from '../../../../atoms/EllipsisText';
import { Show } from '../../../../atoms/JSXExtensions';
import { MiniChat, MiniChatContainer } from '../../../../features/MiniChat';
import { useMiniChatReader } from '../../../../features/MiniChat/controller/reader';
import { useOpenMiniChat } from '../../../../features/MiniChat/hooks/useOpenMiniChat';
import { SubscriptionInfoModal } from '../../../../features/SubscriptionInfoModal';
import { useSupportSideBar } from '../../../../features/Support';
import { routes } from '../../../../routes';
import { logout } from '../../../../store/thunks/logout';
import { BORDERS, COLORS, SHADOWS } from '../../../../types/colors';
import {
  useAppDispatch,
  useBackPath,
  useModal,
  useUserInfo
} from '../../../../utils/hooks';
import { returnNoCachedSrc } from '../../../../utils/returnNoCachedSrc';
import { returnSubscriptionFeatureStatus } from '../../../../utils/returnSubscriptionFeatureStatus';
import { AdditionalMenuItem } from './components/AdditionalMenuItem';
import { AdditionalMenuNestedItem } from './components/AdditionalMenuNestedItem';

export const AdditionalMenu = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { avatar, email, fullName, isDealer } = useUserInfo()
  const { openSupportSideBar } = useSupportSideBar()
  const { hasUnread } = useMiniChatReader(false)
  const { isMiniChatOpen, closeMiniChat, openMiniChat } = useOpenMiniChat()
  const { currentLocation } = useBackPath()
  const subscriptionModal = useModal()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onOpenClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseClick = () => {
    setAnchorEl(null);
  };


  const { isSubscriptionFeatureEnabled } = returnSubscriptionFeatureStatus()

  const handleSettingsClick = useCallback(() => {
    navigate(routes.personalSettings)
  }, [])

  const handleSupportClick = useCallback(() => {
    closeMiniChat()
    openSupportSideBar({ email, reason: '' })
  }, [openSupportSideBar, closeMiniChat])

  const handleSubscriptionClick = useCallback(() => {
    if (isSubscriptionFeatureEnabled) {
      closeMiniChat()
      navigate(routes.subscription.index, {
        state: { backPath: currentLocation }
      })
    } else {
      subscriptionModal.onOpenHandler()
    }
  }, [
    currentLocation,
    closeMiniChat,
    subscriptionModal,
    isSubscriptionFeatureEnabled
  ])

  const logoutCallback = useCallback(() => {
    dispatch(logout())
    navigate(routes.login)
  }, [])

  const handlePolicyClick = useCallback(() => {
    window.open(routes.cookiePolicy, '_blank')?.focus()
  }, [])

  const handleTermsClick = useCallback(() => {
    window.open(routes.conditions, '_blank')?.focus()
  }, [])

  const openMenuBtnTransform = useMemo(
    () => `rotateZ(${Boolean(anchorEl) ? '0' : '180'}deg)`,
    [anchorEl]
  )

  const showSubscriptionMenuItem = isSubscriptionFeatureEnabled || isDealer;

  return (
    <Grid container item flexWrap='nowrap' alignItems='center'>
      <Grid
        item
        sx={{
          borderRadius: '4px',
          display: { xs: 'none', md: 'flex' },
          mr: 1,
          '&:hover': {
            cursor: 'pointer',
            background: COLORS.hover_header
          }
        }}
      >
        <IconButton onClick={openMiniChat}>
          <Show when={hasUnread} fallback={<ChatsIcon />}>
            <ChatsIconWithNotification />
          </Show>
        </IconButton>
      </Grid>
      <Grid
        container
        item
        sx={{
          borderRadius: '4px',
          zIndex: isMiniChatOpen ? 1500 : 'initial',
          '&:hover': {
            cursor: 'pointer',
            background: COLORS.hover_header
          }
        }}
      >
        <Grid
          item
          container
          onClick={onOpenClick}
          ml={2}
          alignItems='center'
        >
          <Grid item sx={{ mr: 2 }}>
            <Avatar
              alt={fullName}
              sx={{
                width: 24,
                height: 24
              }}
              src={returnNoCachedSrc(avatar)}
            >
              <UnknownUserIcon />
            </Avatar>
          </Grid>
          <Grid item sx={{ display: { xs: 'none', lg: 'flex' }, mr: 2 }}>
            <Typography
              variant='subtitle2'
              color={COLORS.text_white}
              sx={{
                maxWidth: 120
              }}
            >
              <EllipsisText tooltipText={fullName}>{fullName}</EllipsisText>
            </Typography>
          </Grid>
          <Grid item sx={{ display: { xs: 'none', md: 'flex' } }}>
            <IconButton sx={{ transform: openMenuBtnTransform }}>
              <MenuArrowIcon />
            </IconButton>
          </Grid>
        </Grid>
        <MiniChatContainer open={isMiniChatOpen} onClose={closeMiniChat}>
          {(renovator) => (
            <MiniChat onClose={closeMiniChat} renovator={renovator} />
          )}
        </MiniChatContainer>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          open={Boolean(anchorEl)}
          onClose={onCloseClick}
          onClick={onCloseClick}
          autoFocus={false}
          PaperProps={{
            elevation: 0,
            sx: {
              border: BORDERS.SM_GRAY,
              borderRadius: '4px',
              boxShadow: SHADOWS.hover,
              mt: 5,
              overflow: 'visible',
              width: 220,
              '& .MuiMenuItem-root': {
                height: 44
              }
            }
          }}
          transitionDuration={10}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
        >
          <Show
            when={hasUnread}
            fallback={<AdditionalMenuItem
              icon={<ChatsIcon htmlColor={COLORS.text_primary} />}
              label='Chats'
              menuItemSx={{ display: { xs: 'flex', md: 'none' } }}
              onClick={openMiniChat}
              withDivider
            />}
          >
            <AdditionalMenuItem
              color={COLORS.text_red}
              icon={<ChatsIconWithNotification htmlColor={COLORS.text_red} />}
              label='Chats'
              menuItemSx={{ display: { xs: 'flex', md: 'none' } }}
              onClick={openMiniChat}
              withDivider
            />
          </Show>
          <AdditionalMenuItem
            label='Settings'
            onClick={handleSettingsClick}
            icon={<GearIcon htmlColor={COLORS.text_primary} />}
            withDivider
          />
          <AdditionalMenuItem
            label='Contact Support'
            onClick={handleSupportClick}
            icon={<SupportIcon />}
            withDivider
          />
          <Show when={showSubscriptionMenuItem}>
            <AdditionalMenuItem
              label='Subscription'
              onClick={handleSubscriptionClick}
              icon={<SubscriptionsIcon />}
              withDivider
            />
          </Show>
          <AdditionalMenuNestedItem
            label='Data Privacy'
            icon={<Document color={COLORS.text_primary} />}
            isOpen={Boolean(anchorEl)}
          >
            <AdditionalMenuItem
              label='Privacy Policy'
              onClick={handlePolicyClick}
              withDivider
            />
            <AdditionalMenuItem
              label='Terms and Conditions'
              onClick={handleTermsClick}
            />
          </AdditionalMenuNestedItem>
          <AdditionalMenuItem
            label='Log Out'
            onClick={logoutCallback}
            icon={<LogOutIcon />}
          />
        </Menu>
        <Show when={!isSubscriptionFeatureEnabled}>
          <SubscriptionInfoModal {...subscriptionModal} />
        </Show>
      </Grid>
    </Grid>
  )
}
