import { useState, useEffect } from 'react'
import { useApiContext, useMakeService } from '../../../../../../../utils/hooks'

import { OrNull } from '../../../../../../../types/OrNull'
import { ResponseStatusMap } from '../../../../../../../types/status'

export const useTrackShipmentURL = (trackingNumber: OrNull<string>) => {
  const { externalCarSnoopApi } = useApiContext()
  const [trackShipmentURL, setTrackShipmentURL] = useState<string>('')

  const [onTrackShipment, requestShipmentObservable] = useMakeService(
    async () => {
      if (!trackingNumber) {
        return
      }

      const response = await externalCarSnoopApi.shipments.readShipment({
        trackingNumber
      })

      if (response.status === ResponseStatusMap.Success) {
        setTrackShipmentURL(response.data.iframe)
      }

      return response
    },
    {
      withStatusNotification: [1]
    }
  )

  useEffect(() => {
    onTrackShipment()
  }, [])

  return { trackShipmentURL, requestShipmentObservable }
}
