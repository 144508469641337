import { useUserAuthStatus } from "../../../../utils/hooks"
import { AdditionalMenu } from "../AdditionalMenu"
import { LogInLink } from "../LogInLink"

export const AccountMenu = () => {
  const isUserAuthenticated = useUserAuthStatus()

  if (!isUserAuthenticated) {
    return <LogInLink />
  }

  return <AdditionalMenu />
}
