import { FC, useMemo } from 'react'
import { AttentionIcon } from '../../../../../../../atoms/AttentionIcon'
import { VehicleComponent } from '../../../../../../../atoms/VehicleComponent'
import { VEHICLE_DOESNT_HAVE_VIN_WARNING } from '../../../../../../../constants/messages'
import { OrNull } from '../../../../../../../types/OrNull'
import { formatPrice } from '../../../../../../../utils'
import { VehicleDetails } from './VehicleDetails'

export interface SelectedCarProps {
  id: string
  image?: string
  title?: string
  year?: string | number
  make?: string
  model?: string
  trim?: string
  distance: string
  mileage: number
  shippingCost: OrNull<number>
  retailValue: number
  vin: string
  zipCode: string
}

export const SelectedCar: FC<SelectedCarProps> = (props) => {
  const {
    title,
    id,
    vin,
    zipCode,
    image,
    year,
    make,
    model,
    trim,
    distance,
    mileage,
    shippingCost,
    retailValue
  } = props

  const details = useMemo(() => {
    return (
      <VehicleDetails
        distance={distance}
        mileage={mileage}
        shippingCost={shippingCost}
        vehicleInfo={{
          id,
          vin,
          year,
          make,
          model,
          zipCode
        }}
      />
    )
  }, [
    distance,
    mileage,
    retailValue,
    shippingCost,
    id,
    vin,
    year,
    make,
    model,
    zipCode
  ])

  const badges = useMemo(() => {
    const hasVin = !!vin

    const defaultBadges = [
      {
        position: 'bottom-left',
        label: retailValue ? formatPrice(retailValue) : 'Contact CarSnoop'
      }
    ]

    return hasVin
      ? defaultBadges
      : [
          ...defaultBadges,
          {
            position: 'bottom-right',
            label: 'Control',
            component: <AttentionIcon text={VEHICLE_DOESNT_HAVE_VIN_WARNING} />
          }
        ]
  }, [vin])

  return (
    <VehicleComponent
      id={id}
      title={title}
      year={year}
      make={make}
      model={model}
      trim={trim}
      imageSource={image}
      details={details}
      badges={badges}
      navigateToNewTab
    />
  )
}
