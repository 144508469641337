import { TextField } from '@mui/material'
import { FC, forwardRef, useMemo } from 'react'
import { InputError } from '../../assets/icons/InputError'
import { BGCOLORS, COLORS } from '../../types/colors'
import { InputFieldProps } from '../InputField/InputField'
import { Spinner } from '../Spinner'

const styles = {
  '& .MuiOutlinedInput-root': {
    backgroundColor: BGCOLORS.white,
    '& fieldset': {
      borderColor: COLORS.border_gray
    },
    '&:hover fieldset': {
      borderColor: COLORS.text_blue
    },
    '&.Mui-focused fieldset': {
      border: `1px solid ${COLORS.text_blue}`
    }
  }
}

export const SearchField: FC<InputFieldProps> = forwardRef((props, ref) => {
  const { endAdornment, isLoading, helperText, ...fieldProps } = props

  const trimmedHelperText = useMemo(
    () => (typeof helperText === 'string' ? helperText?.trim() : helperText),
    [helperText]
  )

  return (
    <TextField
      fullWidth
      helperText={trimmedHelperText}
      sx={styles}
      {...fieldProps}
      ref={ref}
      InputProps={{
        ...fieldProps.InputProps,
        endAdornment:
          (fieldProps.error && <InputError />) ||
          (isLoading && <Spinner />) ||
          endAdornment
      }}
    />
  )
})
