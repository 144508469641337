import { useCallback } from 'react'
import {
  useApiContext,
  useMakeService,
  useAppDispatch
} from '../../../utils/hooks'
import { ResponseStatusMap } from '../../../types/status'
import {
  actionsCompletedDealsPage,
  actionsInProgressDealsPage
} from '../../../store/pages/Deals'
import { SearchComponentProps } from '../../../features/SearchInventory'

export const useDealsList = (
  type: 'buying' | 'selling',
  status: 'in_progress' | 'completed'
) => {
  const { carSnoopApi } = useApiContext()
  const dispatch = useAppDispatch()

  const [readTransactionsService] = useMakeService(
    async (searchFormValues: SearchComponentProps['initialValues']) => {
      if (status === 'completed') {
        dispatch(actionsCompletedDealsPage.setDealsList(null))
      } else {
        dispatch(actionsInProgressDealsPage.setDealsList(null))
      }

      const response = await carSnoopApi.transactions.readTransactions({
        stockNumber: searchFormValues.search,
        type,
        status
      })

      if (response.status === ResponseStatusMap.Success) {
        if (status === 'completed') {
          dispatch(actionsCompletedDealsPage.setDealsList(response.data))
        } else {
          dispatch(actionsInProgressDealsPage.setDealsList(response.data))
        }
      }

      return response
    }
  )

  const readTransactions = useCallback(
    async (searchFormValues: SearchComponentProps['initialValues']) => {
      await readTransactionsService(searchFormValues)
    },
    [readTransactionsService]
  )

  return {
    readTransactions
  }
}
