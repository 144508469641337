import { OrNull } from '../../types/OrNull'

export const lastOf = <T>(arr: T[]): OrNull<T> => {
  const { length } = arr
  if (!length) {
    return null
  }
  const lastElemIndex = length - 1

  return arr[lastElemIndex]
}
