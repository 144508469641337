import * as Yup from 'yup'
import { latinWithSpecial } from '../../../../../../utils/validators'

const ERROR = 'Enter a valid License plate'

export const licensePlateValidationPattern = Yup.string()
  .required('')
  .test('is-latin-or-special', ERROR, latinWithSpecial([' ']))

export const optionalLicensePlateValidationPattern = Yup.string().test(
  'is-latin-or-special',
  ERROR,
  latinWithSpecial([' '])
)

export const optionalStateValidationPattern = Yup.object({
  name: Yup.string(),
  id: Yup.string()
})

export const stateValidationPattern =
  optionalStateValidationPattern.required('')

export const validationSchema = Yup.object({
  licensePlate: licensePlateValidationPattern,
  state: stateValidationPattern
})
