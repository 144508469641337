import { ButtonProps, Grid } from '@mui/material'
import { FC } from 'react'
import { AgreeButton, CancelButton } from '../../../../../../../atoms/Button'
import { COLORS } from '../../../../../../../types/colors'

export interface OneButtonsVariantProps {
  onClick: ButtonProps['onClick']
}

export const OneButtonsVariant: FC<OneButtonsVariantProps> = (props) => {
  const { onClick } = props

  return (
    <Grid item xs={12}>
      <AgreeButton onClick={onClick} sx={{ width: '100%' }}>
        Confirm payment
      </AgreeButton>
    </Grid>
  )
}

export interface TwoButtonsVariantProps {
  onConfirmClick: ButtonProps['onClick']
  onDeclineClick: ButtonProps['onClick']
  confirmDisabled?: boolean
}

export const TwoButtonsVariant: FC<TwoButtonsVariantProps> = (props) => {
  const { confirmDisabled = false, onConfirmClick, onDeclineClick } = props

  return (
    <Grid container item justifyContent='space-between'>
      <CancelButton
        onClick={onDeclineClick}
        sx={{
          borderColor: COLORS.text_red
        }}
      >
        Decline
      </CancelButton>
      <AgreeButton
        onClick={onConfirmClick}
        sx={{ width: 'auto' }}
        disabled={confirmDisabled}
      >
        Confirm payment
      </AgreeButton>
    </Grid>
  )
}
