import { FC, useCallback, useState, useMemo } from 'react'
import { ViewMode } from './components/ViewMode'
import { Condition } from '../../../../../../atoms/Condition'
import { EditMode } from './components/EditMode'
import { DetailsContainer } from '../DetailsContainer'
import { SellerFormFieldsTypes } from './components/EditMode/types'
import { useUserInfo } from '../../../../../../utils/hooks'
import { useContractPageInfo } from '../../../../hooks/useContractPageInfo'
import { OrNull } from '../../../../../../types/OrNull'
import { NormalizedContractType } from '../../../../../../api/carSnoopApi/transactions/types'
import { Show } from '../../../../../../atoms/JSXExtensions'

export interface SellerDetailsProps {
  title: string
  contract: OrNull<NormalizedContractType>
  allowEdit?: boolean
  onSaveClick: (values: SellerFormFieldsTypes) => void
}

export const SellerDetails: FC<SellerDetailsProps> = (props) => {
  const { title, contract, allowEdit, onSaveClick } = props

  const [mode, setMode] = useState<'edit' | 'view'>('view')

  const { accountID } = useUserInfo()
  const { transaction } = useContractPageInfo()
  const isCurrentUserASeller = useMemo(
    () => transaction?.sellerID === accountID,
    [transaction?.sellerID, accountID]
  )
  const isEditMode = mode === 'edit'

  const setEditMode = useCallback(() => {
    setMode('edit')
  }, [setMode])

  const setViewMode = useCallback(() => {
    setMode('view')
  }, [setMode])

  return (
    <Show when={contract}>
      {(contractDetails) => (
        <DetailsContainer
          title={title}
          allowEdit={allowEdit && isCurrentUserASeller}
          emphasize={isCurrentUserASeller}
          onEditClick={setEditMode}
        >
          <Condition
            condition={isEditMode}
            trueContent={
              <EditMode
                {...contractDetails.sellerDetails}
                notes={contractDetails.notes || ''}
                onSaveClick={onSaveClick}
                onCancelClick={setViewMode}
              />
            }
            falseContent={
              <ViewMode
                {...contractDetails.sellerDetails}
                notes={contractDetails.notes || ''}
                isCurrentUserASeller={isCurrentUserASeller}
              />
            }
          />
        </DetailsContainer>
      )}
    </Show>
  )
}
