import { useState, useMemo, useEffect, useCallback } from 'react'
import { useDeviceInfo } from '../../../utils/hooks'

export const useCalulateChatListHeight = () => {
  const { isIOS } = useDeviceInfo()
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  const calculateAppHeight = useCallback(() => {
    setWindowHeight(window.innerHeight)
  }, [setWindowHeight, window.innerHeight])

  useEffect(() => {
    window.addEventListener('resize', calculateAppHeight)

    return () => {
      window.removeEventListener('resize', calculateAppHeight)
    }
  }, [])

  const chatListHeight = useMemo(() => {
    if (isIOS) {
      return `calc(${windowHeight}px - 80px - 78px)`
    }

    return 'calc(100vh - 80px - 56px)'
  }, [isIOS, windowHeight])

  return chatListHeight
}
