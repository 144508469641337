import { useCallback } from 'react'
import { useAppDispatch } from '.'
import { actionsSubscriptionModal } from '../../store/units/subscription'

export const useSubscriptionModal = () => {
  const dispatch = useAppDispatch()
  const { openModal, closeModal } = actionsSubscriptionModal

  const openSubscriptionModal = useCallback(() => dispatch(openModal()), [])

  const closeSubscriptionModal = useCallback(() => dispatch(closeModal()), [])

  return { openSubscriptionModal, closeSubscriptionModal }
}
