import Typography from "@mui/material/Typography";
import { routes } from "../../../../routes";
import { CustomNavLink } from "../CustomNavLink";


export const LogInLink = () => (
  <Typography
    variant='overline'
    component={CustomNavLink}
    to={routes.login}
    lineHeight={1}
    px={1.5}
  >
    Log In
  </Typography>
);
