const title = 'Vehicle will be shipped locally'

export const NON_TRACKABLE_PROPS_BUYER = {
  title,
  description: 'Once you receive the vehicle, the deal will be finalized!'
}

export const NON_TRACKABLE_PROPS_SELLER = {
  title,
  description:
    'Once the Buyer receives the vehicle, the deal will be finalized!'
}
