import { FC, memo, useMemo } from 'react'
import {
  DependentField,
  DependentFieldHocProps
} from '../../atoms/DependentField'
import { NumericField, NumericFieldProps } from '../../atoms/NumericField'
import { IAddOnFormValues } from '../../molecules/AddOn'
import { OFFER_MODAL_FIELDS } from './OfferModal.constants'
import { IOfferModalForm } from './OfferModal.types'

export const DependentTaxValueField: FC<
  NumericFieldProps &
    DependentFieldHocProps<NumericFieldProps, IOfferModalForm> & {
      values: IOfferModalForm
    }
> = memo(
  ({ values, ...props }) => {
    const Component = useMemo(() => {
      const modifiedValAddOnKeys = values[OFFER_MODAL_FIELDS.ADD_ONS].map(
        (addOn: IAddOnFormValues, index: number) => {
          return [
            `${OFFER_MODAL_FIELDS.ADD_ONS}.${index}.price`,
            `${OFFER_MODAL_FIELDS.ADD_ONS}.${index}.amount`
          ]
        }
      )

      return DependentField<NumericFieldProps, IOfferModalForm>(NumericField, {
        deps: [
          OFFER_MODAL_FIELDS.VEHICLE_PRICE,
          OFFER_MODAL_FIELDS.TRADE_IN,
          ...modifiedValAddOnKeys.flat()
        ]
      })
    }, [values])

    return <Component {...props} />
  },
  (prevProps, nextProps) => {
    const isAddOnsSameLength =
      prevProps.values.addOns.length === nextProps.values.addOns.length

    if (!isAddOnsSameLength) {
      return false
    }

    const isTradeInSame =
      prevProps.values[OFFER_MODAL_FIELDS.TRADE_IN] ===
      nextProps.values[OFFER_MODAL_FIELDS.TRADE_IN]
    const isPriceSame =
      prevProps.values[OFFER_MODAL_FIELDS.VEHICLE_PRICE] ===
      nextProps.values[OFFER_MODAL_FIELDS.VEHICLE_PRICE]

    const isAddOnsSame = prevProps.values.addOns.every(
      (addOn: IAddOnFormValues, index: number) => {
        return (
          addOn.price === nextProps.values.addOns[index].price &&
          addOn.quantity === nextProps.values.addOns[index].quantity
        )
      }
    )

    return isTradeInSame && isPriceSame && isAddOnsSame
  }
)
