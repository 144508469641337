import { createSlice } from '@reduxjs/toolkit'
import { Dealership } from '../../../types/Dealership'
import { readDealershipListAsync } from '../../thunks/readDealershipListAsync'
import { RequestStatus } from '../../../types/status'

interface DealershipListState {
  data: Dealership[]
  status: RequestStatus
  error: undefined | string
}

export const initialState: DealershipListState = {
  data: [],
  status: 'idle',
  error: undefined
}

export const dealershipListSlice = createSlice({
  name: 'fetchDealershipList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(readDealershipListAsync.pending, (state) => {
      state.status = 'pending'
      state.data = []
      state.error = undefined
    })
    builder.addCase(readDealershipListAsync.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.data = action.payload
      state.error = undefined
    })
    builder.addCase(readDealershipListAsync.rejected, (state) => {
      state.status = 'failed'
      state.data = []
      state.error = 'Something went wrong!'
    })
  }
})
