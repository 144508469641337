import { FC, PropsWithChildren } from 'react'
import { SomethingWentWrong } from '../../assets/icons/SomethingWentWrong'
import { ResultScreen, ResultScreenProps } from '../ResultScreen'

export type ErrorScreenProps = Omit<ResultScreenProps, 'icon'> &
  PropsWithChildren

export const ErrorScreen: FC<ErrorScreenProps> = (props) => {
  return <ResultScreen {...props} icon={<SomethingWentWrong />} />
}
