import { Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { GeneratePurchaseAgreementType } from '../../../api/carSnoopApi/documents/types'
import { NormalizedTransactionType } from '../../../api/carSnoopApi/transactions/types'
import { AddButton, LinkButtonWithDisable } from '../../../atoms/Button'
import { Show } from '../../../atoms/JSXExtensions'
import { Loader } from '../../../atoms/Loader'
import { COLORS } from '../../../types/colors'
import { useModal } from '../../../utils/hooks'
import { AddDocumentModal } from '../components/AddDocumentModal'
import { useContractPageInfo } from '../hooks/useContractPageInfo'
import { DocumentsTable } from './components/DocumentsTable'

interface DocumentsTabProps {
  transaction: NormalizedTransactionType | null
  generatePurchaseAgreement: GeneratePurchaseAgreementType
  agreementGenerationInProcess: boolean
  isLoading: boolean
}

export const DocumentsTab: FC<DocumentsTabProps> = ({
  transaction,
  generatePurchaseAgreement,
  agreementGenerationInProcess,
  isLoading
}) => {
  const {
    allowAddDocumentForBuyer,
    allowAddDocumentForSeller,
    isContractEditable,
    isContractInProgress,
    isCarsnoopAgreementAvailable,
    allowPurchaseAgreementCreation
  } = useContractPageInfo()

  const addDocumentModal = useModal()

  return (
    <>
      <Show
        when={!isLoading && transaction?.contract}
        fallback={
          <Grid container flexDirection='column' alignItems='center'>
            <Loader />
          </Grid>
        }
      >
        {(contract) => (
          <Grid container rowSpacing={1}>
            <Grid item xs={12}>
              <Grid container spacing={1} alignItems='center'>
                <Grid item>
                  <Typography variant='emphasized'>
                    Seller’s documentation
                  </Typography>
                </Grid>
                <Show when={allowAddDocumentForSeller}>
                  <Grid item>
                    <AddButton onClick={addDocumentModal.onOpen}>
                      Add Document
                    </AddButton>
                  </Grid>
                </Show>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='subtitle2' color='textSecondary'>
                Please download, complete and sign the seller’s documents, then
                upload a copy back to this page.
              </Typography>
            </Grid>
            <Show when={allowPurchaseAgreementCreation}>
              <Grid item xs={12}>
                <Typography variant='main'>
                  If you don`t have your own purchase agreement
                  <LinkButtonWithDisable
                    disabled={
                      agreementGenerationInProcess ||
                      isCarsnoopAgreementAvailable
                    }
                    onClick={generatePurchaseAgreement}
                    disabledColor={COLORS.text_secondary}
                  >
                    click here
                  </LinkButtonWithDisable>
                  to use CarSnoop Purchase Agreement
                </Typography>
              </Grid>
            </Show>
            <Grid item xs={12} mt={2}>
              <DocumentsTable
                viewType='seller'
                isEditable={isContractEditable}
                isUploadAllowed={isContractInProgress}
                documents={contract.sellerRequiredDocuments}
                sellerID={transaction?.sellerID}
                buyerID={transaction?.buyerID}
                showAgreementLoader={agreementGenerationInProcess}
              />
            </Grid>
            <Grid container spacing={1} alignItems='center' mt={2}>
              <Grid item>
                <Typography variant='emphasized'>
                  Buyer’s documentation
                </Typography>
              </Grid>
              <Show when={allowAddDocumentForBuyer}>
                <Grid item>
                  <AddButton onClick={addDocumentModal.onOpen}>
                    Add Document
                  </AddButton>
                </Grid>
              </Show>
            </Grid>
            <Grid item xs={12} mt={2}>
              <DocumentsTable
                viewType='buyer'
                isEditable={isContractEditable}
                isUploadAllowed={isContractInProgress}
                documents={contract.buyerRequiredDocuments}
                sellerID={transaction?.sellerID}
                buyerID={transaction?.buyerID}
              />
            </Grid>
          </Grid>
        )}
      </Show>
      <AddDocumentModal {...addDocumentModal} />
    </>
  )
}
