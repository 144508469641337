import {
  FILE_EXTENSIONS,
  MAX_PDF_SIZE_MB,
  MAX_PHOTO_SIZE_MB,
  MAX_VIDEO_SIZE_MB
} from '../../constants/applicationConstants'
import {
  DOCUMENT_OVERSIZE_ERROR,
  DOCUMENT_PDF_OVERSIZE_ERROR,
  VIDEO_OVERSIZE_ERROR
} from '../../constants/messages'

export const PLAYABLE_FILE_EXTENSIONS = [
  FILE_EXTENSIONS.mp3,
  FILE_EXTENSIONS.mp4,
  FILE_EXTENSIONS.wav,
  FILE_EXTENSIONS.webm
]

export const DEFAULT_FILE_INFO = {
  fileName: '',
  extension: FILE_EXTENSIONS.unknown,
  creationDate: '',
  size: 0
}

const IMAGE_SIZE_ERROR = {
  maxSize: MAX_PHOTO_SIZE_MB,
  errorText: DOCUMENT_OVERSIZE_ERROR
}

export const DOCUMENT_AUDIO_SIZE_ERROR = {
  maxSize: MAX_PDF_SIZE_MB,
  errorText: DOCUMENT_PDF_OVERSIZE_ERROR
}

const VIDEO_SIZE_ERROR = {
  maxSize: MAX_VIDEO_SIZE_MB,
  errorText: VIDEO_OVERSIZE_ERROR
}

export const FILE_VALIDATION_CONFIG = {
  [FILE_EXTENSIONS.mp3]: DOCUMENT_AUDIO_SIZE_ERROR,
  [FILE_EXTENSIONS.mpeg]: DOCUMENT_AUDIO_SIZE_ERROR,
  [FILE_EXTENSIONS.wav]: DOCUMENT_AUDIO_SIZE_ERROR,
  [FILE_EXTENSIONS.pdf]: DOCUMENT_AUDIO_SIZE_ERROR,
  [FILE_EXTENSIONS.unknown]: DOCUMENT_AUDIO_SIZE_ERROR,
  [FILE_EXTENSIONS.webm]: VIDEO_SIZE_ERROR,
  [FILE_EXTENSIONS.mp4]: VIDEO_SIZE_ERROR,
  [FILE_EXTENSIONS.gif]: DOCUMENT_AUDIO_SIZE_ERROR,
  [FILE_EXTENSIONS.jpeg]: IMAGE_SIZE_ERROR,
  [FILE_EXTENSIONS.jpg]: IMAGE_SIZE_ERROR,
  [FILE_EXTENSIONS.svg]: IMAGE_SIZE_ERROR,
  [FILE_EXTENSIONS.png]: IMAGE_SIZE_ERROR,
  [FILE_EXTENSIONS.mov]: VIDEO_SIZE_ERROR,
  [FILE_EXTENSIONS.avi]: VIDEO_SIZE_ERROR,
  [FILE_EXTENSIONS.heic]: IMAGE_SIZE_ERROR,
  [FILE_EXTENSIONS.heif]: IMAGE_SIZE_ERROR
}
