import { FC, MouseEventHandler, useCallback } from 'react'
import { MyTeamAddUsersView } from '../../../../molecules/MyTeamCard/views/MyTeamAddUsersView'
import { MyTeamAddUsersFormValues } from '../../../../molecules/MyTeamCard/views/MyTeamAddUsersView/types'
import { useAddUsersService } from '../../../../molecules/MyTeamCard/hooks'

export interface AddNewUserCardProps {
  onClose: () => void
}

export const AddNewUserCard: FC<AddNewUserCardProps> = (props) => {
  const { onClose } = props
  const { addUsersObservable, addUsersService } = useAddUsersService()

  const handleCancel = useCallback<MouseEventHandler>(
    (e) => {
      e.stopPropagation()
      onClose()
    },
    [onClose]
  )

  const handleAddingSendClick = useCallback(
    (values: MyTeamAddUsersFormValues) => {
      const { emails } = values
      addUsersService(emails)
    },
    [addUsersService]
  )

  return (
    <MyTeamAddUsersView
      isLoading={addUsersObservable.isLoading}
      isSuccess={addUsersObservable.isSuccess}
      onCloseClick={handleCancel}
      onCancelClick={handleCancel}
      onSendClick={handleAddingSendClick}
    />
  )
}
