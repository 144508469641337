import { FC } from 'react'
import { Grid } from '@mui/material'
import { Spinner } from '../../../../atoms/Spinner'
import { BORDERS, COLORS } from '../../../../types/colors'

export interface LoadingSpinnerProps {
  isMain?: boolean
}

export const LoadingSpinner: FC<LoadingSpinnerProps> = (props) => {
  const { isMain } = props

  return (
    <Grid
      container
      item
      justifyContent='center'
      alignItems='center'
      position='absolute'
      sx={{
        backgroundColor: COLORS.white,
        width: '100%',
        height: '100%',
        border: isMain ? 'none' : BORDERS.EMPTY_IMAGE,
        zIndex: 1
      }}
    >
      <Spinner size={60} />
    </Grid>
  )
}
