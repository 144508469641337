import { useCallback } from 'react'
import { useSubscriptionModal } from './useSubscriptionModal'
import { actionsUserInfo } from '../../store/features/userInfo'
import { ResponseStatusMap } from '../../types/status'
import { useApiContext } from './useApiContext'
import { useMakeService } from './useMakeService'
import { useUserInfo } from './useUserInfo'
import { useAppDispatch } from './reduxHooks'

export const useUpdateAccountSubscription = () => {
  const { closeSubscriptionModal } = useSubscriptionModal()
  const dispatch = useAppDispatch()
  const userInfo = useUserInfo()
  const { carSnoopApi } = useApiContext()

  const [readAccountService, readAccountObservable] = useMakeService(
    async (id: string) => {
      const response = await carSnoopApi.accounts.readAccount(id)

      if (response.status === ResponseStatusMap.Success) {
        dispatch(
          actionsUserInfo.setUserSubscription({
            subscription: response.subscription
          })
        )
      }
      closeSubscriptionModal()

      return response
    }
  )

  const updateAccountSubscription = useCallback(() => {
    readAccountService(userInfo.accountID)
  }, [userInfo.accountID, readAccountService])

  return {
    updateAccountSubscription,
    updateAccountSubscriptionObservable: readAccountObservable
  }
}
