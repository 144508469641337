import { Grid, Typography } from '@mui/material'
import { useMemo } from 'react'
import { OfficeIcon } from '../../../assets/icons/OfficeIcon'
import { IconText } from '../../../atoms/IconText'
import { PersonalSettingsItem } from '../../../atoms/PersonalSettingsItem'
import { COLORS } from '../../../types/colors'
import { useUserInfo } from '../../../utils/hooks'

export const DealerAddressCard = () => {
  const { addresses, dealershipName } = useUserInfo()

  const { street, city, state, zipCode } = useMemo(() => {
    return addresses[0]
  }, [addresses])

  return (
    <PersonalSettingsItem
      hint='When you change the location of your dealership, the address will be changed automatically.'
    >
      <Grid item xs={12}>
        <IconText
          flexWrap='nowrap'
          icon={<OfficeIcon fill={COLORS.active_yellow} />}
        >
          {dealershipName}
        </IconText>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='main'>{`${street}, ${city}, ${state} ${zipCode}`}</Typography>
      </Grid>
    </PersonalSettingsItem>
  )
}
