import { Grid, Typography } from '@mui/material'
import { FC, useMemo } from 'react'
import { NormalizedOfferType } from '../../../../../../api/carSnoopApi/transactions/types'
import { CollapsibleNotes } from '../../../../../../atoms/CollapsibleNotes'
import { For, Show } from '../../../../../../atoms/JSXExtensions'
import { getOfferComponentPrice } from '../../../../../../features/OfferModal/OfferModal.helpers'
import { OrNull } from '../../../../../../types/OrNull'
import { formatPrice } from '../../../../../../utils'
import { DetailsContainerAccordion } from '../DetailsContainer'
import { DetailsItem } from '../DetailsItem'
import { RenderAddOn, RenderNegative, RenderTotalPrice } from './components'

export interface OfferDetailsProps {
  offer: OrNull<NormalizedOfferType>
  includeTradeIn: boolean
}

export const OfferDetails: FC<OfferDetailsProps> = ({
  offer,
  includeTradeIn
}) => {
  const salesTax = useMemo(
    () => getOfferComponentPrice(offer, 'Sales Tax'),
    [offer]
  )
  const tradein = useMemo(
    () => getOfferComponentPrice(offer, 'Trade-In Vehicle'),
    [offer]
  )
  const vehiclePrice = useMemo(
    () => getOfferComponentPrice(offer, 'Vehicle Price'),
    [offer]
  )

  const deposit = useMemo(
    () => getOfferComponentPrice(offer, 'Deposit'),
    [offer]
  )

  const totalPrice = useMemo(() => offer?.totalPrice || 0, [offer?.totalPrice])

  const {
    formattedVehiclePrice,
    formattedSalesTax,
    formattedTradein,
    formattedTotalPrice,
    formattedDeposit
  } = useMemo(
    () => ({
      formattedVehiclePrice: formatPrice(vehiclePrice, {
        includeDecimalPart: true
      }),
      formattedSalesTax: formatPrice(salesTax, {
        includeDecimalPart: true
      }),
      formattedTradein: `-${formatPrice(tradein)}`,
      formattedTotalPrice: formatPrice(totalPrice, {
        includeDecimalPart: true
      }),
      formattedDeposit: `-${formatPrice(deposit, {
        includeDecimalPart: true
      })}`
    }),
    [vehiclePrice, salesTax, tradein, deposit]
  )

  return (
    <DetailsContainerAccordion title='Offer details'>
      <Grid container item spacing={3}>
        {/* Total calculation */}
        <Grid container item flexDirection='column' spacing={3}>
          <Grid item>
            <Typography variant='label1'>Total calculation</Typography>
          </Grid>
          <Grid item>
            <DetailsItem
              title='Total price'
              value={formattedTotalPrice}
              renderValue={RenderTotalPrice}
            />
          </Grid>
          <Grid item>
            <DetailsItem title='Vehicle Price' value={formattedVehiclePrice} />
          </Grid>
          <Grid item>
            <DetailsItem title='Sales tax' value={formattedSalesTax} />
          </Grid>
          <Show when={deposit}>
            <Grid item>
              <DetailsItem
                title='Deposit'
                value={formattedDeposit}
                renderValue={RenderNegative}
              />
            </Grid>
          </Show>
        </Grid>
        {/* /Total calculation */}

        {/* Add-ons */}
        <Show when={offer?.addons}>
          {(addons) => (
            <Grid container item flexDirection='column' spacing={3}>
              <Grid item>
                <Typography variant='label1'>Add-ons</Typography>
              </Grid>

              <For of={addons}>
                {(addon) => {
                  const formattedPrice = formatPrice(Number(addon.price))
                  return (
                    <Grid item>
                      <DetailsItem
                        title={addon.description}
                        value={formattedPrice}
                        renderValue={(value) => (
                          <RenderAddOn
                            quantity={addon.quantity}
                            value={value}
                          />
                        )}
                      />
                    </Grid>
                  )
                }}
              </For>
            </Grid>
          )}
        </Show>
        {/* /Add-ons */}

        {/* Trade In */}
        <Show when={includeTradeIn}>
          <Grid container item flexDirection='column' spacing={3}>
            <Grid item>
              <Typography variant='label1'>Trade In</Typography>
            </Grid>
            <Grid item>
              <DetailsItem
                title='Trade In'
                value={formattedTradein}
                renderValue={RenderNegative}
              />
            </Grid>
          </Grid>
        </Show>
        {/* /Trade In */}

        {/* Offer Notes */}
        <Show when={offer?.notes}>
          {(notes) => (
            <Grid item>
              <CollapsibleNotes
                header={<Typography variant='label1'>Offer Notes </Typography>}
              >
                {notes}
              </CollapsibleNotes>
            </Grid>
          )}
        </Show>
        {/* /Offer Notes */}
      </Grid>
    </DetailsContainerAccordion>
  )
}
