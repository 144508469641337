import { Grid } from '@mui/material'
import { FC } from 'react'
import { NumberBadge } from '../../../../atoms/NumberBadge'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const NumberBadgeExample: FC = () => {
  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <Grid item display='flex' mr='8px'>
          <NumberBadge count={1} />
        </Grid>
        <Grid item display='flex' mr='8px'>
          <NumberBadge width='40px' count={3} />
        </Grid>
        <Grid item display='flex'>
          <NumberBadge count={11} />
        </Grid>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
