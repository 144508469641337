import { useCallback } from 'react'
import { useAppSelector, useAppDispatch } from '../../../utils/hooks'
import { selectIsChatOpen } from '../model/selectors'
import { actionsMiniChat } from '../model/actions'

export const useOpenMiniChat = () => {
  const dispatch = useAppDispatch()
  const isMiniChatOpen = useAppSelector(selectIsChatOpen)

  const closeMiniChat = useCallback(() => {
    dispatch(actionsMiniChat.closeChat())
  }, [dispatch])

  const openMiniChat = useCallback(() => {
    dispatch(actionsMiniChat.openChat())
  }, [dispatch])

  return { isMiniChatOpen, closeMiniChat, openMiniChat }
}
