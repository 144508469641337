import { Grid, Typography } from '@mui/material'
import { Dispatch, FC, MouseEventHandler, SetStateAction } from 'react'
import { Condition } from '../../atoms/Condition'
import { COLORS } from '../../types/colors'
import { ConfirmationModal } from './ConfirmationModal'

export interface DeleteConfirmationModalProps {
  message: string
  hint?: string
  isOpen: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  onSubmit: MouseEventHandler
  submitButtonText?: string
  declineButtonText?: string
}

export const DeleteConfirmationModal: FC<DeleteConfirmationModalProps> = ({
  message,
  hint,
  isOpen,
  setOpen,
  onSubmit,
  declineButtonText = 'Cancel',
  submitButtonText = 'Delete'
}) => {
  return (
    <ConfirmationModal
      isOpen={isOpen}
      setIsOpen={setOpen}
      header={
        <Typography variant='h6' color={COLORS.text_red}>
          Delete
        </Typography>
      }
      cancel={{
        text: declineButtonText
      }}
      submit={{
        text: submitButtonText,
        callback: onSubmit
      }}
    >
      <Grid
        container
        rowSpacing={3}
        flexDirection='column'
        sx={{ pt: 4, pb: 2, px: 8 }}
      >
        <Grid item textAlign='center'>
          <Typography variant='main'>{message}</Typography>
        </Grid>
        <Condition
          condition={!!hint}
          trueContent={
            <Grid item>
              <Typography variant='subtitle2' textAlign='center'>
                {hint}
              </Typography>
            </Grid>
          }
        />
      </Grid>
    </ConfirmationModal>
  )
}
