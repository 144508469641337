import { useEffect, useState } from 'react'
import { NormalizedTransactionType } from '../../../../api/carSnoopApi/transactions/types'
import { OrNull } from '../../../../types/OrNull'
import { useUserInfo } from '../../../../utils/hooks'
import { PAYMENT_METHODS_ENUM } from '../../../Offers/forms/PaymentMicroform/constants'
import { ContractViewStates } from './types'
import { resolveStateByStateCode } from './utils'

export const useContractStateDecoder = (
  transaction: OrNull<NormalizedTransactionType>
) => {
  const [contractViewState, setContractViewState] =
    useState<ContractViewStates>(ContractViewStates.UNKNOWN)
  const { accountID, isAdmin, isDealer } = useUserInfo()

  useEffect(() => {
    if (transaction) {
      const { buyerID, status, contract } = transaction

      const isBuyer = accountID === buyerID
      const isStripeFlow =
        contract?.paymentMethod === PAYMENT_METHODS_ENUM.ELECTRONIC

      setContractViewState(
        resolveStateByStateCode({
          isBuyer,
          isStripeFlow,
          status
        })
      )
    }
  }, [transaction])

  return {
    // It's needed for edit shipment addresses forms (to display/not display dealership name)
    isDealer,
    isAdmin,
    contractViewState
  }
}
