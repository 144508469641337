import { ChangeEvent, FC, useMemo } from 'react'
import { VehicleComponent } from '../../atoms/VehicleComponent'
import { VehicleDetails } from './VehicleDetails'
import { CustomCheckbox } from './CustomCheckbox'
import { formatPrice } from '../../utils'
import { OrNull } from '../../types/OrNull'
import { AttentionIcon } from '../../atoms/AttentionIcon'
import { VEHICLE_DOESNT_HAVE_VIN_WARNING } from '../../constants/messages'

export interface CheckboxVehicleComponentProps {
  id: string
  disabled: boolean | ((id: string) => boolean)
  image?: string
  title?: string
  vin?: string
  year: string | number
  make: string
  model: string
  trim: string
  zipCode: string
  checked: boolean | ((id: string) => boolean)
  onChange: (
    id: string,
    nextValue: boolean,
    event?: ChangeEvent<HTMLInputElement>
  ) => void
  distance: string
  mileage: number
  shippingCost: OrNull<number>
  retailValue: number
}

export const CheckboxVehicleComponent: FC<CheckboxVehicleComponentProps> = (
  props
) => {
  const {
    title,
    id,
    image,
    vin,
    year,
    make,
    model,
    trim,
    zipCode,
    distance,
    mileage,
    shippingCost,
    retailValue,
    disabled = false,
    checked,
    onChange
  } = props

  const badges = useMemo(() => {
    const isChecked = typeof checked === 'boolean' ? checked : checked(id)
    const hasVin = !!vin

    const defaultBadges = [
      {
        position: 'top-left',
        label: 'Control',
        component: (
          <CustomCheckbox
            checked={isChecked}
            onChange={(event, nextValue) => onChange(id, nextValue, event)}
          />
        )
      },
      {
        position: 'bottom-left',
        label: retailValue ? formatPrice(retailValue) : 'Contact CarSnoop'
      }
    ]

    return hasVin
      ? defaultBadges
      : [
          ...defaultBadges,
          {
            position: 'bottom-right',
            label: 'vinAttention',
            component: <AttentionIcon text={VEHICLE_DOESNT_HAVE_VIN_WARNING} />
          }
        ]
  }, [checked, onChange, retailValue, vin])

  const details = useMemo(() => {
    return (
      <VehicleDetails
        distance={distance}
        mileage={mileage}
        shippingCost={shippingCost}
        vehicleInfo={{
          id,
          vin,
          year,
          make,
          model,
          zipCode
        }}
      />
    )
  }, [distance, mileage, shippingCost])

  const isDisabled = useMemo(
    () => (typeof disabled === 'boolean' ? disabled : disabled(id)),
    [disabled]
  )

  return (
    <VehicleComponent
      disabled={isDisabled}
      id={id}
      title={title}
      year={year}
      make={make}
      model={model}
      trim={trim}
      imageSource={image}
      details={details}
      badges={badges}
      navigateToNewTab
    />
  )
}
