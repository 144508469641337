import { Button, Grid, Typography } from '@mui/material'
import { FC, useCallback, useEffect } from 'react'
import { CloseIcon } from '../../assets/icons/Close'
import { ActionBlock } from '../../atoms/ActionBlock'
import { Divider } from '../../atoms/Divider'
import { Show } from '../../atoms/JSXExtensions'
import { Loader } from '../../atoms/Loader'
import { Modal } from '../../molecules/Modal'
import { CommonConfirmationModal } from '../../molecules/Modal/CommonConfirmationModal'
import { preselectedSubscriptionsMap } from '../../routes/SubscriptionPage/preselectedSubscriptionsMap'
import { selectSubscriptionModalState } from '../../store/units/subscription'
import { actionsSubscriptionAutoupdate } from '../../store/units/subscriptionAutoupdate'
import { nbsp } from '../../types/strings'
import { noop } from '../../utils'
import { useSynchronizer } from '../../utils/Synchronizer/hooks/useSynchronizer'
import {
  useAppDispatch,
  useAppSelector,
  useModal,
  useSearchParams,
  useSubscriptionModal,
  useUserInfo
} from '../../utils/hooks'
import { AUTOUPDATE_QUERY_SUBSCRIPTION_PAGE_MARK } from '../../utils/hooks/subscription/constants'
import { useUpdateSubscriptionService } from '../../utils/hooks/subscription/useUpdateSubscriptionService'
import { PaymentRedirect } from '../PaymentRedirect'
import { SubscriptionMicroform, subscriptionOptions } from '../Subscription'
import { useSubscriptionForm } from '../Subscription/hooks/useSubscriptionForm'
import {
  closeButtonStyle,
  modalContainerStyle,
  modalHeaderStyle,
  modalStyle
} from './SubscriptionModal.styles'

export const SubscriptionModal: FC = () => {
  const { closeSubscriptionModal } = useSubscriptionModal()
  const { isOpen } = useAppSelector(selectSubscriptionModalState)
  const { accountID, subscription, isAdmin } = useUserInfo()
  const dispatch = useAppDispatch()
  const {
    subscriptionRef,
    setSubscriptionMicroformRef,
    isSubscriptionFormValid
  } = useSubscriptionForm()

  const [params] = useSearchParams()
  const isSubscriptionPage = params.has(AUTOUPDATE_QUERY_SUBSCRIPTION_PAGE_MARK)

  const { updateSubscriptionService, redirectUrl, isLoading } =
    useUpdateSubscriptionService({ autoUpdate: !isSubscriptionPage })

  useEffect(() => {
    dispatch(actionsSubscriptionAutoupdate.setIsAutoupdating(isLoading))
  }, [dispatch, isLoading]);

  const { synchronize, isSynchronizing } = useSynchronizer()

  // useSynchronize can't be used here because this component is being rendered once
  // at the app start and then only reacts on isOpen
  useEffect(() => {
    if (isOpen) {
      synchronize()
    }
  }, [isOpen])

  const onSubmitClick = useCallback(async () => {
    const { current } = subscriptionRef
    if (current?.values) {
      const { subscription: subscriptionValue } = current.values
      await updateSubscriptionService({
        subscriptionType: subscriptionValue,
        accId: accountID
      })
    }
  }, [updateSubscriptionService, accountID])

  const activatePlanModal = useModal(onSubmitClick)

  return (
    <Modal open={isOpen} sx={modalStyle}>
      <Grid
        container
        sx={modalContainerStyle}
        alignItems='center'
        rowSpacing={5}
      >
        <Show
          when={!isLoading && !isSynchronizing}
          fallback={
            <Grid container alignContent='center'>
              <Loader />
            </Grid>
          }
        >
          <>
            <Grid item container>
              <Grid
                item
                container
                justifyContent='center'
                sx={modalHeaderStyle}
              >
                <Grid
                  container
                  item
                  justifyContent='flex-end'
                  alignItems='center'
                  position='absolute'
                  top={0}
                  right={0}
                >
                  <Button
                    disableElevation
                    variant='text'
                    sx={closeButtonStyle}
                    onClick={closeSubscriptionModal}
                  >
                    <CloseIcon />
                  </Button>
                </Grid>
                <Typography variant='h6' fontWeight='bold'>
                  Upgrade your CarSnoop subscription
                </Typography>
              </Grid>
              <Grid item container justifyContent='center'>
                <Typography variant='main' textAlign='center'>
                  You have reached your monthly limit for CarSnoop deals.
                  Please, upgrade your subscription. Subscription runs for
                  calendar month.
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              justifyContent='center'
              flexDirection='column'
              rowSpacing={3}
            >
              <Grid item>
                <SubscriptionMicroform
                  options={subscriptionOptions}
                  formRef={setSubscriptionMicroformRef}
                  containerSx={{ justifyContent: 'center' }}
                  initialValues={{
                    subscription: preselectedSubscriptionsMap[subscription.name]
                  }}
                />
              </Grid>
              <Grid item container justifyContent='center'>
                <Typography
                  variant='details'
                  whiteSpace='pre-line'
                  textAlign='center'
                >
                  {`By confirming your subscription, you allow CarSnoop to charge your payment method${nbsp}
          for this payment and future payments in accordance with the terms. You can always cancel your subscription.`}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container>
              <Divider />
            </Grid>
            <Grid item width='100%'>
              <ActionBlock
                pushToBottom
                schema={[
                  {
                    label: 'not now',
                    type: 'secondary',
                    onClick: closeSubscriptionModal
                  },
                  {
                    label: 'activate',
                    type: 'primary',
                    disabled: !isSubscriptionFormValid || isLoading || !isAdmin,
                    onClick: activatePlanModal.onOpen
                  }
                ]}
              />
            </Grid>
          </>
        </Show>
      </Grid>
      <PaymentRedirect
        open={!!redirectUrl}
        onRedirectFail={noop}
        getPaymentRedirectUrl={redirectUrl}
        onSuccessRedirect={closeSubscriptionModal}
      />
      <CommonConfirmationModal
        header='Confirmation'
        message='Are you sure you would like to activate the new plan?'
        {...activatePlanModal}
      />
    </Modal>
  )
}
