import { FC } from 'react'
import {
  CompletedTransactionStatuses,
  VehicleStatusTypes
} from '../../api/carSnoopApi/statusTypes'
import { Badge } from '../../atoms/Badge'
import { BADGE_COLORS } from '../../types/colors'

interface VehicleBadgeProps {
  status?: VehicleStatusTypes | CompletedTransactionStatuses
}

const colorsPalette = {
  'For Sale': BADGE_COLORS.violet,
  Requested: BADGE_COLORS.beige,
  Offered: BADGE_COLORS.orange,
  'In Offer': BADGE_COLORS.lightGreen,
  'In Request': BADGE_COLORS.blue,
  'Under Contract': BADGE_COLORS.gray,
  'Awaiting Payment': BADGE_COLORS.green,
  Shipping: BADGE_COLORS.mint,
  'Request Cancelled': BADGE_COLORS.red,
  'Request Declined': BADGE_COLORS.red,
  Delivered: BADGE_COLORS.green,
  'Offer Canceled': BADGE_COLORS.red,
  'Offer Expired': BADGE_COLORS.red,
  'Offer Declined': BADGE_COLORS.red,
  'Request Expired': BADGE_COLORS.red,
  'Offer Cancelled': BADGE_COLORS.red,
  'Contract Terminated': BADGE_COLORS.red,
  'Payment Failed': BADGE_COLORS.red
}

export const VehicleBadge: FC<VehicleBadgeProps> = ({
  status = 'Requested'
}) => {
  return <Badge text={status} {...colorsPalette[status]} />
}
