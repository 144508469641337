import { Utils } from '../../utils'
import { ErrorResponse, SuccessResponse } from '../../private.types'
import { ResponseStatusMap } from '../../../types/status'
import { PaymentConfirmResponse } from './types'

export class Payments {
  protected readonly utils: Utils
  protected readonly operations = {
    declinePayment: 'declinePayment'
  }

  constructor(utils: Utils) {
    this.utils = utils
  }

  async declinePayment({
    id
  }: {
    id: string
  }): Promise<
    | SuccessResponse<PaymentConfirmResponse>
    | ErrorResponse<PaymentConfirmResponse>
  > {
    try {
      const { type, payload } = await this.utils.makeJSONRequest<
        SuccessResponse<PaymentConfirmResponse>,
        ErrorResponse<PaymentConfirmResponse>
      >(this.operations.declinePayment, {
        transaction_id: id
      })

      if (type === ResponseStatusMap.Success) {
        return {
          status: ResponseStatusMap.Success,
          message: payload.message
        }
      }

      return {
        status: ResponseStatusMap.Error,
        message: payload.message
      }
    } catch (e) {
      return {
        status: ResponseStatusMap.Error,
        message: 'Something went wrong'
      }
    }
  }
}
