import { Grid, Typography } from '@mui/material'
import { format } from 'date-fns'
import { FC, MouseEventHandler, useCallback, useMemo, useState } from 'react'
import { NormalizedContractDocumentType } from '../../../../../api/carSnoopApi/transactions/types'
import { FileDocument } from '../../../../../assets/icons/Document'
import { CarsnoopIcon } from '../../../../../assets/logo/CarsnoopIcon'
import { BlueButton } from '../../../../../atoms/Button'
import { EllipsisText } from '../../../../../atoms/EllipsisText'
import { Show } from '../../../../../atoms/JSXExtensions'
import { StopPropagationContainer } from '../../../../../atoms/StopPropagationContainer'
import { MMDDYYYYSlash } from '../../../../../constants/applicationConstants'
import { ConfirmationModal } from '../../../../../molecules/Modal'
import { BORDERS, COLORS } from '../../../../../types/colors'
import { useDeviceInfo, useModal } from '../../../../../utils/hooks'
import { useFiles } from '../../../../../utils/hooks/files'
import { DocumentPreview } from '../DocumentPreview'
import { DocumentRowActions } from '../DocumentRowActions'
import { NotAddedPlug } from './NotAddedPlug'

const activeStyles = {
  ':hover': {
    border: BORDERS.CONTAINER_ACCENT,
    cursor: 'pointer',
    role: 'button'
  }
}

const notesModalStyles = {
  padding: 3,
  minWidth: '50vw',
  maxHeight: '90vh',
  overflow: 'auto'
}

interface DocumentRowProps extends NormalizedContractDocumentType {
  viewType: 'seller' | 'buyer'
  sellerID?: string
  buyerID?: string
  isEditable: boolean
  isUploadAllowed: boolean
  isCarsnoopAgreement: boolean
}

export const DocumentRow: FC<DocumentRowProps> = ({
  name,
  id,
  type,
  createdOn,
  notes,
  isEditable,
  isUploadAllowed,
  isCarsnoopAgreement,
  ...transactionIDs
}) => {
  const notesModal = useModal()
  const { getMediaLink } = useFiles()
  const [documentUrl, setDocumentUrl] = useState('')
  const documentPreviewModal = useModal()
  const { isTablet } = useDeviceInfo()
  const displayData = useMemo(() => {
    return {
      name,
      isFleExist: !!id,
      type: type || '-',
      createdOn: createdOn ? format(createdOn, MMDDYYYYSlash) : 'MM/DD/YYYY',
      notes
    }
  }, [name, id, type, createdOn, notes])

  const iconColor = useMemo(
    () =>
      displayData.isFleExist
        ? COLORS.utility_positive
        : COLORS.text_primary,
    [displayData.isFleExist]
  )

  const openDocumentClick: MouseEventHandler = useCallback(
    async (e) => {
      let updatedUrl

      if (!documentUrl) {
        const { url } = await getMediaLink(id || '')
        updatedUrl = url

        setDocumentUrl(url)
      } else {
        updatedUrl = documentUrl
      }

      if (type === 'pdf' && updatedUrl) {
        window.open(updatedUrl, '_blank')?.focus()
      } else {
        documentPreviewModal.onOpen(e)
      }
    },
    [type, documentPreviewModal.onOpen, id, documentUrl]
  )

  return (
    <Grid
      container
      alignItems='center'
      columns={18}
      sx={{
        p: 2,
        borderRadius: '10px',
        border: BORDERS.SM_GRAY,
        ...(displayData.isFleExist ? activeStyles : {})
      }}
      {...(displayData.isFleExist && {
        onClick: openDocumentClick
      })}
    >
      <Show when={documentUrl}>
        {(url) => (
          <DocumentPreview
            type={type}
            link={url}
            isOpen={documentPreviewModal.isOpen}
            setIsOpen={documentPreviewModal.setOpen}
          />
        )}
      </Show>
      <Grid item xs={6}>
        <Grid container alignItems='center'>
          <Show
            when={!isCarsnoopAgreement}
            fallback={<CarsnoopIcon width='24' height='24' />}
          >
            <FileDocument color={iconColor} />
          </Show>

          <Grid item xs={10} pl={2}>
            <EllipsisText tooltipText={displayData.name}>
              {displayData.name}
            </EllipsisText>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Typography variant='main'>{displayData.createdOn}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant='details' textTransform='uppercase'>
          {displayData.type}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Show when={notes} fallback={<NotAddedPlug />}>
          <>
            <StopPropagationContainer>
              <ConfirmationModal
                isOpen={notesModal.isOpen}
                setIsOpen={notesModal.setOpen}
                header={
                  <Grid item>
                    <Typography variant='h6'>View Notes</Typography>
                  </Grid>
                }
              >
                <Grid
                  container
                  rowSpacing={1}
                  maxWidth={isTablet ? '85vw' : '50vw'}
                  sx={notesModalStyles}
                >
                  <Grid item xs={12}>
                    <Typography variant='emphasized'>Document Notes</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant='body1'
                      sx={{
                        wordBreak: 'normal',
                        overflowWrap: 'anywhere',
                        whiteSpace: 'pre-wrap'
                      }}
                    >
                      {displayData.notes}
                    </Typography>
                  </Grid>
                </Grid>
              </ConfirmationModal>
            </StopPropagationContainer>
            <BlueButton sx={{ padding: '6px 0' }} onClick={notesModal.onOpen}>
              View notes
            </BlueButton>
          </>
        </Show>
      </Grid>
      <Grid item xs={2}>
        <DocumentRowActions
          id={id}
          name={name}
          type={type}
          createdOn={createdOn}
          notes={notes}
          isEditable={isEditable}
          isUploadAllowed={isUploadAllowed}
          {...transactionIDs}
        />
      </Grid>
    </Grid>
  )
}
