import { FC, useCallback } from 'react'
import { Grid, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import {
  SellerFormFieldsTypes,
  SELLER_FIELDS_ERRORS,
  SELLER_FIELDS_LABELS,
  SELLER_FIELDS_NAMES
} from './types'
import { validationSchema } from './validationSchema'
import { TextInputFieldNew } from '../../../../../../../../atoms/TextInputFieldNew'
import { zipCodeMask } from '../../../../../../../../constants/regexp'
import { useCheckZipCode } from '../../../../../../../../utils/hooks/useCheckZipCode'
import { ResponseStatusMap } from '../../../../../../../../types/status'
import { DependentFieldHocEffect } from '../../../../../../../../atoms/DependentField'
import {
  DependentZipCodeFieldProps,
  DependentZipCodeField
} from './dependentFields'
import { COLORS } from '../../../../../../../../types/colors'
import { NotesField } from '../../../../../../../../atoms/NotesField'
import { CancelButton, AgreeButton } from '../../../../../../../../atoms/Button'
import { isRequiredFormField } from '../../../../../../../../utils/validators/isRequiredFormField'
import { RequiredLabel } from '../../../../../../../../atoms/RequiredText'

export interface EditModeProps {
  legalName: string
  legalStreet: string
  legalZipCode: string
  legalCity: string
  legalState: string
  notes?: string
  onSaveClick: (values: SellerFormFieldsTypes) => void
  onCancelClick: () => void
}

export const EditMode: FC<EditModeProps> = (props) => {
  const {
    legalName,
    legalStreet,
    legalZipCode,
    legalCity,
    legalState,
    notes,
    onSaveClick,
    onCancelClick
  } = props
  const { checkZipCode, checkZipCodeObservable } = useCheckZipCode()

  const zipCodeFieldEffect: DependentFieldHocEffect<
    DependentZipCodeFieldProps,
    SellerFormFieldsTypes
  > = useCallback(async (_, ctx) => {
    const {
      values: formValues,
      setFieldValue,
      validateForm,
      setFieldError,
      setValues
    } = ctx
    const { sellerZipCode } = formValues
    setFieldValue(SELLER_FIELDS_NAMES.CITY, '')
    setFieldValue(SELLER_FIELDS_NAMES.STATE, '')

    if (sellerZipCode) {
      const response = await checkZipCode(sellerZipCode)

      if (response.status === ResponseStatusMap.Success) {
        setValues(
          {
            ...formValues,
            [SELLER_FIELDS_NAMES.CITY]: response.city,
            [SELLER_FIELDS_NAMES.STATE]: response.state
          },
          true
        )
      } else {
        validateForm().then(() => {
          setFieldError(
            SELLER_FIELDS_NAMES.ZIP_CODE,
            SELLER_FIELDS_ERRORS.ZIP_CODE
          )
        })
      }
    }
  }, [])

  const isRequiredField = isRequiredFormField(validationSchema)

  return (
    <Grid item>
      <Grid item mb={2}>
        <RequiredLabel marginTop={0} />
      </Grid>
      <Formik<SellerFormFieldsTypes>
        onSubmit={onSaveClick}
        initialValues={{
          sellerLegalName: legalName,
          sellerLegalAddress: legalStreet,
          sellerZipCode: legalZipCode,
          sellerCity: legalCity,
          sellerState: legalState,
          sellerNotes: notes || ''
        }}
        validateOnChange={false}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Form>
            <Grid container item xs={12} flexDirection='column' spacing={2}>
              <Grid item xs={12}>
                <TextInputFieldNew
                  name={SELLER_FIELDS_NAMES.NAME}
                  label={SELLER_FIELDS_LABELS.NAME}
                  backgroundColor={COLORS.white}
                  required={isRequiredField}
                />
              </Grid>
              <Grid item>
                <TextInputFieldNew
                  name={SELLER_FIELDS_NAMES.ADDRESS}
                  label={SELLER_FIELDS_LABELS.ADDRESS}
                  backgroundColor={COLORS.white}
                  required={isRequiredField}
                />
              </Grid>
              <Grid container item justifyContent='space-between' spacing={2}>
                <Grid item xs={5}>
                  <DependentZipCodeField
                    name={SELLER_FIELDS_NAMES.ZIP_CODE}
                    label={SELLER_FIELDS_LABELS.ZIP_CODE}
                    mask={zipCodeMask}
                    onDoneEffect={zipCodeFieldEffect}
                    backgroundColor={COLORS.white}
                    required={isRequiredField}
                  />
                </Grid>
                <Grid item xs={3.5}>
                  <TextInputFieldNew
                    name={SELLER_FIELDS_NAMES.CITY}
                    label={SELLER_FIELDS_LABELS.CITY}
                    disabled
                    isLoading={checkZipCodeObservable.isLoading}
                    required={isRequiredField}
                  />
                </Grid>
                <Grid item xs={3.5}>
                  <TextInputFieldNew
                    name={SELLER_FIELDS_NAMES.STATE}
                    label={SELLER_FIELDS_LABELS.STATE}
                    disabled
                    isLoading={checkZipCodeObservable.isLoading}
                    required={isRequiredField}
                  />
                </Grid>
              </Grid>
              <Grid item mt={2}>
                <Typography variant='emphasized'>Contract Notes</Typography>
              </Grid>
              <Grid item>
                <NotesField
                  name={SELLER_FIELDS_NAMES.NOTES}
                  label=''
                  backgroundColor={COLORS.white}
                  placeholder='Once you add notes here, the buyer will see it on their page'
                  required={isRequiredField}
                />
              </Grid>
              <Grid
                container
                item
                xs={12}
                justifyContent='space-between'
                mt={2}
              >
                <Grid item>
                  <CancelButton
                    onClick={onCancelClick}
                    sx={{ background: COLORS.white }}
                  >
                    Cancel
                  </CancelButton>
                </Grid>
                <Grid item>
                  <AgreeButton
                    type='submit'
                    disabled={
                      !formik.dirty ||
                      !formik.isValid ||
                      checkZipCodeObservable.isLoading
                    }
                  >
                    Save
                  </AgreeButton>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  )
}
