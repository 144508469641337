import { styled } from '@mui/system'
import { Form } from 'formik'
import { COLORS } from '../../../types/colors'

export const ContactDetailsForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  & > div {
    margin-bottom: 24px;
  }

  & > div:last-child {
    margin-bottom: 0;
    margin-top: auto;
  }
`

export const PasswordHint = styled('div')`
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: ${COLORS.text_secondary};
`

export const TermsCheckboxContainer = styled('div')`
  margin-bottom: 74px;
`
