import { useFormikContext } from 'formik'
import { FC, useCallback, useMemo } from 'react'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { trimZeroes } from '../../utils'
import { MaskedInput } from '../MaskedInput'
import { PhoneInputFieldNewProps } from '../PhoneInputField'
import { DEFAULT_VALUE } from './constants'

export type NumericFieldProps = PhoneInputFieldNewProps & {
  onBlur?: () => void
  startWith?: string
  textColor?: string
  allowNegative?: boolean
}

export const NumericField: FC<NumericFieldProps> = (props): JSX.Element => {
  const { name, startWith = '', onBlur: outerOnBlur, allowNegative = false } = props
  const { setFieldValue, getFieldProps } =
    useFormikContext<Record<typeof name, string>>()
  const value = getFieldProps(name).value || ''

  const mask = useMemo(
    () => createNumberMask({
      allowDecimal: true,
      allowNegative: allowNegative,
      includeThousandsSeparator: true,
      prefix: startWith,
    }),
    [allowNegative, startWith, value]
  )

  const modifyValue = useMemo(() => trimZeroes(startWith), [startWith])

  const handleBlur = useCallback(() => {
    if (!value) {
      setFieldValue(name, DEFAULT_VALUE)
    }
    outerOnBlur && outerOnBlur()
  }, [value, setFieldValue])

  const handleFocus = useCallback(() => {
    if (value === DEFAULT_VALUE) {
      setFieldValue(name, '')
    }
  }, [value, setFieldValue])

  return (
    <MaskedInput
      {...props}
      mask={mask}
      guide={false}
      onBlur={handleBlur}
      onFocus={handleFocus}
      modifyValue={modifyValue}
      autoComplete='off'
    />
  )
}
