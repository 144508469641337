import { FC, Suspense, lazy } from 'react'

const LazyIcon = lazy(() => import('./Icon'))
const LazyIconChecked = lazy(() => import('./IconChecked'))

export const Checkbox: FC = () => {
  return (
    <Suspense>
      <LazyIcon />
    </Suspense>
  )
}

export const CheckboxChecked: FC = () => {
  return (
    <Suspense>
      <LazyIconChecked />
    </Suspense>
  )
}
