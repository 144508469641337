import { Avatar, Button, Grid, Typography } from '@mui/material'
import { FC, useCallback, useMemo, useState } from 'react'
import { DeleteIcon } from '../../assets/icons/Delete'
import { Condition } from '../../atoms/Condition'
import { EllipsisEmail } from '../../atoms/EllipsisEmail'
import { EllipsisText } from '../../atoms/EllipsisText'
import { Switch } from '../../atoms/Switch'
import { COLORS } from '../../types/colors'
import { UserStatusMap } from '../../types/status'
import { useDeviceInfo } from '../../utils/hooks/useDeviceInfo'
import { MemberDialogConfirmations } from './confirmations'

export interface Member {
  id: string
  isAdmin: boolean
  fullName: string
  active: boolean
  status: 'Admin' | 'User' | 'Pending'
  avatar: string
}

export type AccountMemberProps = {
  member: Member
  onDeleteClick: (id: string) => void
  onUserTypeChange: (options: { id: string; setAdminRole: boolean }) => void
  isAdminView?: boolean
}
export const AccountMember: FC<AccountMemberProps> = (props) => {
  const { member, isAdminView, onDeleteClick, onUserTypeChange } = props
  const { fullName, id, avatar, status } = member
  const [isNextUserTypeAdmin, setNextUserTypeAdmin] = useState(false)
  const [isUserTypeChangeConfirmationOpen, setUserTypeChangeConfirmationOpen] =
    useState(false)
  const [isDeleteUserConfirmationOpen, setDeleteUserConfirmationOpen] =
    useState(false)

  const { isTablet } = useDeviceInfo()

  const statusText = useMemo(
    () =>
      status && UserStatusMap[status] !== UserStatusMap.User
        ? UserStatusMap[status]
        : '',
    [status]
  )

  const isPending = useMemo(() => status === UserStatusMap.Pending, [status])

  const handleAdminRoleChange = useCallback(
    (setAdminRole: boolean) => {
      setNextUserTypeAdmin(setAdminRole)
      setUserTypeChangeConfirmationOpen(true)
    },
    [setNextUserTypeAdmin, setUserTypeChangeConfirmationOpen]
  )

  const handleUserDelete = useCallback(() => {
    setDeleteUserConfirmationOpen(true)
  }, [setDeleteUserConfirmationOpen])

  const onSubmitUserTypeChangeCallback = useCallback(() => {
    onUserTypeChange({ id, setAdminRole: isNextUserTypeAdmin })
  }, [onUserTypeChange, isNextUserTypeAdmin, id])

  const onSubmitUserDeleteCallback = useCallback(() => {
    onDeleteClick(id)
  }, [id])

  const maxEmailLength = useMemo(() => {
    return isTablet ? 15 : 24
  }, [isTablet])

  return (
    <Grid
      container
      item
      sx={{
        padding: '10px 24px',
        borderBottom: `1px solid ${COLORS.border_gray}`
      }}
    >
      <MemberDialogConfirmations
        fullName={fullName}
        changeMap={{
          isOpen: isUserTypeChangeConfirmationOpen,
          setOpen: setUserTypeChangeConfirmationOpen,
          onSubmit: onSubmitUserTypeChangeCallback,
          isSetToAdmin: isNextUserTypeAdmin
        }}
        deleteMap={{
          isOpen: isDeleteUserConfirmationOpen,
          setOpen: setDeleteUserConfirmationOpen,
          onSubmit: onSubmitUserDeleteCallback
        }}
      />
      <Grid container item xs={7} md={9} spacing='10px' alignItems='center'>
        <Grid item>
          <Avatar
            alt={fullName}
            sx={{
              width: '32px',
              height: '32px'
            }}
            src={avatar}
          />
        </Grid>
        <Grid container item xs={8} lg={10}>
          <Grid item xs={12}>
            <Typography lineHeight='normal' sx={{ pb: '4px' }}>
              <EllipsisText tooltipText={fullName || ''}>
                {fullName}
              </EllipsisText>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant='subtitle1'
              lineHeight='normal'
              color={COLORS.text_blue}
            >
              <EllipsisEmail maxCount={maxEmailLength}>{id}</EllipsisEmail>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={5}
        md={3}
        justifyContent='flex-end'
        alignItems='center'
      >
        <Condition
          condition={!!isAdminView}
          trueContent={
            <Grid container justifyContent='flex-end' alignItems='center'>
              <Condition
                condition={isPending}
                trueContent={
                  <Typography
                    color={COLORS.text_secondary}
                    fontStyle={isPending ? 'italic' : 'normal'}
                    sx={{ mr: 2 }}
                  >
                    {statusText}
                  </Typography>
                }
                falseContent={
                  <Grid item>
                    <Switch
                      checked={status === UserStatusMap.Admin}
                      onChange={handleAdminRoleChange}
                    />
                  </Grid>
                }
              />
              <Button
                disableElevation
                variant='text'
                sx={{ minWidth: 0, padding: 0 }}
                onClick={handleUserDelete}
              >
                <DeleteIcon />
              </Button>
            </Grid>
          }
          falseContent={
            <Typography
              color={COLORS.text_secondary}
              fontStyle={isPending ? 'italic' : 'normal'}
            >
              {statusText}
            </Typography>
          }
        />
      </Grid>
    </Grid>
  )
}
