import { Grid, Button } from '@mui/material'
import { UploadNewDocumentModal } from '../../../../features/UploadNewDocument'
import { UploadDocumentFormType } from '../../../../features/UploadNewDocument/types'
import { useModal } from '../../../../utils/hooks'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const UploadNewDocumentModalExample = () => {
  // TODO: change later when API and UI will be ready
  const id = ''
  const documentName = "Driver's licence"

  const handleSubmit = async (data: UploadDocumentFormType) => {
    // eslint-disable-next-line
    console.log('>>>', 'upload', data)
  }

  const uploadDocumentModal = useModal()

  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <Grid container item>
          <Button onClick={uploadDocumentModal.onOpen}>Open</Button>
          <UploadNewDocumentModal
            {...uploadDocumentModal}
            id={id}
            name={documentName}
            onSubmit={handleSubmit}
          />
        </Grid>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
