import { Grid } from '@mui/material'
import { useMemo } from 'react'
import { AudioIcon } from '../../../../../../assets/icons/AudioIcon'
import { FileDocument } from '../../../../../../assets/icons/Document'
import { SmallPlayIcon } from '../../../../../../assets/icons/SmallPlayIcon'
import { EllipsisText } from '../../../../../../atoms/EllipsisText'
import { IconText } from '../../../../../../atoms/IconText'
import { COLORS } from '../../../../../../types/colors'
import { useFileType } from '../../../../../Chat/view/ChatMediaMessage/hooks/useFileType'

interface LastTextMessageProps {
  displayText: string
}
interface LastMediaMessageProps extends LastTextMessageProps {
  messageType: string
}
const fileMessageIcons = {
  audio: {
    supported: <SmallPlayIcon color={COLORS.main_black} />,
    unsupported: <AudioIcon />
  },
  application: {
    supported: <FileDocument />,
    unsupported: <FileDocument />
  },
  video: {
    supported: <AudioIcon />,
    unsupported: <FileDocument />
  },
  image: {
    supported: <FileDocument />,
    unsupported: <FileDocument />
  },
  text: {
    supported: <FileDocument />,
    unsupported: <FileDocument />
  },
  default: <FileDocument />
}

export const LastTextMessage = ({ displayText }: LastTextMessageProps) => (
  <Grid item sx={{ wordBreak: 'break-word' }}>
    <EllipsisText ellipsisRows={2}>{displayText}</EllipsisText>
  </Grid>
)

export const LastMediaMessage = ({
  displayText,
  messageType
}: LastMediaMessageProps) => {
  const file = useFileType(messageType)

  const fileIcon = useMemo(() => {
    if (file.type in fileMessageIcons) {
      return file.isSupported
        ? fileMessageIcons[file.type].supported
        : fileMessageIcons[file.type].unsupported
    }
    return fileMessageIcons.default
  }, [file])

  return (
    <Grid item mt='8px'>
      <IconText flexWrap='nowrap' icon={fileIcon}>
        <Grid item>
          <EllipsisText ellipsisRows={2}>{displayText}</EllipsisText>
        </Grid>
      </IconText>
    </Grid>
  )
}
