import { styled } from '@mui/material'
import { Form } from 'formik'

export const RegistrationForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  > div {
    margin-bottom: 24px;
  }

  > div:last-child {
    margin-bottom: 0;
    margin-top: auto;
  }
`

export const EmptyBlockContainer = styled('div')`
  margin-top: 48px;
`
