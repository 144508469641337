import { Typography } from '@mui/material'
import { FC } from 'react'
import { NormalizedOfferType } from '../../../api/carSnoopApi/transactions/types'
import { CollapsibleNotes } from '../../../atoms/CollapsibleNotes'
import { NonNullableRecord } from '../../../types/NonNullableRecord'

type OffersNotesProps = NonNullableRecord<Pick<NormalizedOfferType, 'notes'>>

const NotesHeader = () => (
  <Typography variant='emphasized'>Buyer’s Notes</Typography>
)

export const OffersNotes: FC<OffersNotesProps> = ({ notes }) => {
  return (
    <CollapsibleNotes nonCollapsibleRows={10} header={<NotesHeader />}>
      {notes}
    </CollapsibleNotes>
  )
}
