import { formatNumber, FormatNumberParams } from './formatNumber'

type FormatPriceParams = Pick<
  FormatNumberParams,
  'includeDecimalPart' | 'hideTrailingDecimalZeroes'
>

export const formatPrice = (
  value: number,
  params?: FormatPriceParams
): string => {
  const { includeDecimalPart = false, hideTrailingDecimalZeroes = true } =
    params || ({} as FormatPriceParams)

  return formatNumber(value, {
    start: '$',
    includeDecimalPart,
    hideTrailingDecimalZeroes
  })
}
