import { FC } from 'react'
import { LinkButton } from '../../../atoms/LinkButton'
import { routes } from '../../../routes'
import {
  ConditionsHintsContainer,
  RegistrationButtonContainer,
  TermsContainer
} from './ConditionHints.styles'


export const ConditionsHints: FC = () => {
  return (
    <ConditionsHintsContainer>
      <RegistrationButtonContainer>
        Don&#x2019;t have an account?{' '}
        <LinkButton label='Register' to={routes.registration.manual} />
      </RegistrationButtonContainer>
      <TermsContainer>
        By creating an account or logging in, you confirm that you understand
        and agree to CarSnoop&apos;s{' '}
        <LinkButton label='Terms and Conditions' href={routes.conditions} />.
        You also acknowledge our{' '}
        <LinkButton label='Cookie and Privacy' href={routes.cookiePolicy} /> policies.
      </TermsContainer>
    </ConditionsHintsContainer>
  )
}
