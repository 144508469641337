import { FC } from 'react'
import { Dialog } from '@mui/material'
import { ApplicationWrapper } from '../../../../../layout/ApplicationWrapper'
import { AddVehiclePage } from '../../../../../routes/AddVehicle'

interface AddNewVehicleModalProps {
  isOpen: boolean
  onClose: () => void
}

export const AddNewVehicleModal: FC<AddNewVehicleModalProps> = ({
  isOpen,
  onClose
}) => {
  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={onClose}
      disableEscapeKeyDown
      transitionDuration={0}
    >
      <ApplicationWrapper excludeApiContext>
        <AddVehiclePage />
      </ApplicationWrapper>
    </Dialog>
  )
}
