import { Grid, Typography } from '@mui/material'
import { FC, useMemo } from 'react'
import { DrivetrainIcon } from '../../../../assets/icons/vehicleOverviewIcons/DrivetrainIcon'
import { EngineIcon } from '../../../../assets/icons/vehicleOverviewIcons/EngineIcon'
import { ExteriorIcon } from '../../../../assets/icons/vehicleOverviewIcons/ExteriorIcon'
import { FuelIcon } from '../../../../assets/icons/vehicleOverviewIcons/FuelIcon'
import { InteriorIcon } from '../../../../assets/icons/vehicleOverviewIcons/InteriorIcon'
import { MPGIcon } from '../../../../assets/icons/vehicleOverviewIcons/MPGIcon'
import { StyleIcon } from '../../../../assets/icons/vehicleOverviewIcons/StyleIcon'
import { TransmissionIcon } from '../../../../assets/icons/vehicleOverviewIcons/TransmissionIcon'
import { BGCOLORS } from '../../../../types/colors'
import { replaceNullableValueToString } from '../../../../utils'
import { OverviewItem } from './components/OverviewItem'
import { VehicleDetailsOverviewText } from './constants'

interface VehicleDetailsOverviewProps {
  drivetrain?: string | null
  engineDescription?: string | null
  exteriorColor: string | null
  fuelType?: string | null
  fullView: boolean
  interiorColor: string | null
  mpg?: string | null
  style?: string | null
  transmission?: string | null
}

export const VehicleDetailsOverview: FC<VehicleDetailsOverviewProps> = (
  props
) => {
  const {
    drivetrain,
    engineDescription,
    exteriorColor,
    fuelType,
    fullView,
    interiorColor,
    mpg,
    style,
    transmission,
  } = props

  const overviewItems = useMemo(
    () =>
      fullView
        ? [
          {
            icon: <FuelIcon />,
            title: 'Fuel type',
            value: fuelType
          },
          {
            icon: <EngineIcon />,
            title: 'Engine',
            value: engineDescription
          },
          {
            icon: <TransmissionIcon />,
            title: 'Transmission',
            value: transmission
          },
          {
            icon: <MPGIcon />,
            title: 'MPG',
            value: mpg
          },
          {
            icon: <StyleIcon />,
            title: 'Style',
            value: style
          },
          {
            icon: <DrivetrainIcon />,
            title: 'Drivetrain',
            value: drivetrain
          },
          {
            icon: <ExteriorIcon />,
            title: 'Exterior color',
            value: exteriorColor
          },
          {
            icon: <InteriorIcon />,
            title: 'Interior color',
            value: interiorColor
          }
        ]
        : [
          {
            icon: <ExteriorIcon />,
            title: 'Exterior color',
            value: exteriorColor
          },
          {
            icon: <InteriorIcon />,
            title: 'Interior color',
            value: interiorColor
          }
        ],
    [props]
  )

  const replacedOverviewItems = useMemo(
    () =>
      overviewItems.map((item) => {
        return {
          ...item,
          value: replaceNullableValueToString(
            item.value,
            VehicleDetailsOverviewText.NotAvailable
          )
        }
      }),
    [
      overviewItems,
      replaceNullableValueToString,
      VehicleDetailsOverviewText.NotAvailable
    ]
  )

  return (
    <Grid container item
      sx={{
        backgroundColor: BGCOLORS.white,
        borderRadius: '10px',
        padding: { xs: 2, md: 3 }
      }}
    >
      <Typography component='h2' marginBottom={{ xs: 2, md: 3 }} variant='h6'>
        Overview
      </Typography>
      <Grid container item
        columnSpacing={{ xs: 0, md: 1, lg: 2 }}
        rowSpacing={{ xs: 1, md: 2, lg: 4 }}
      >
        {replacedOverviewItems.map((item) => (
          <OverviewItem {...item} key={item.title} />
        ))}
      </Grid>
    </Grid>
  )
}
