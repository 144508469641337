export const phoneNumberMaskPattern = [
  '(',
  /\d/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  ' ',
  '-',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/
]

export const zipCodeMask = [/\d/, /\d/, /\d/, /\d/, /\d/]
