import { Grid, Typography } from '@mui/material'
import { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ContractCompleted } from '../../assets/icons/ContractCompleted'
import { PageContainer } from '../../layout/PageContainer'
import { OperationResultScreen } from '../../molecules/OperationResultScreen'
import { OperationResultInfoType } from '../../molecules/OperationResultScreen/types'
import { routes } from '../../routes'
import {
  contractCompletedButtonText,
  contractCompletedHeader,
  contractCompletedMainText
} from './constants'

export const CompletedContractPage = () => {
  const { transactionId } = useParams()
  const navigate = useNavigate()
  const onClick = useCallback(() => {
    navigate(`${routes.contracts.index}/${transactionId}`)
  }, [transactionId])
  const operationResultInfo: OperationResultInfoType = useMemo(
    () => ({
      header: (
        <Grid item>
          <Typography variant='h6'>{contractCompletedHeader}</Typography>
        </Grid>
      ),
      mainText: (
        <Typography
          variant='subtitle2'
          textAlign='center'
          whiteSpace='pre-wrap'
        >
          {contractCompletedMainText}
        </Typography>
      ),
      buttonText: contractCompletedButtonText,
      icon: <ContractCompleted />,
      onClick
    }),
    [onClick]
  )
  return (
    <PageContainer>
      <OperationResultScreen
        successOperationInfo={operationResultInfo}
        sx={{ minHeight: '650px' }}
      />
    </PageContainer>
  )
}
