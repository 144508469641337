import { AutoselectColorOption } from '../../../../atoms/AutoselectColorOption'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const AutoselectColorOptionExample = () => {
  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        {AutoselectColorOption(
          {},
          { id: 'Color', name: 'Color', swatch: '#fff000' },
          { selected: false, inputValue: '' }
        )}

        {AutoselectColorOption(
          {},
          { id: 'Color', name: 'Long color name', swatch: '#fff000' },
          { selected: true, inputValue: '' }
        )}
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
