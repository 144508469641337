import { Grid } from '@mui/material'
import { FC } from 'react'
import {
  NormalizedOfferType,
  NormalizedTradeInType
} from '../../../api/carSnoopApi/transactions/types'
import { NormalizedRequestType } from '../../../api/carSnoopApi/transactions/types/request/normalized'
import { DisplayTradeInDetails } from '../../../features/DisplayTradeInDetails'
import {
  BuyerActionsType,
  SellerActionsType
} from '../../../features/OfferDetailsWidget'
import { OfferDetailsWidget } from '../../../features/OfferDetailsWidget/OfferDetailsWidget'
import { getOfferComponentPrice } from '../../../features/OfferModal/OfferModal.helpers'
import { RequestDetailsWidget } from '../../../features/RequestDetailsWidget'
import { OrNull } from '../../../types/OrNull'
import { OffersNotes } from './OffersNotes'

interface OfferInformationViewTabProps {
  request: NormalizedRequestType
  offer: NormalizedOfferType
  previousOffer: OrNull<NormalizedOfferType>
  tradeIn: OrNull<NormalizedTradeInType>
  actions: SellerActionsType | BuyerActionsType
  showActions?: boolean
}

export const OfferInformationViewTab: FC<OfferInformationViewTabProps> = ({
  request,
  offer,
  previousOffer,
  tradeIn,
  actions,
  showActions = true
}) => {
  return (
    <Grid container rowSpacing={2} spacing={3}>
      <Grid item xs={12} lg={6}>
        <Grid container rowSpacing={2} flexDirection='column'>
          <Grid item>
            <RequestDetailsWidget
              id={request.id}
              make={request.make}
              model={request.model}
              trim={request.trim}
              years={request.years}
              interiorColors={request.interiorColors}
              exteriorColors={request.exteriorColors}
              tradeinPrice={tradeIn?.tradeInValue}
            />
          </Grid>
          <Grid item>
            <OffersNotes notes={request.notes} />
          </Grid>
          <Grid item>
            <DisplayTradeInDetails tradeinVehicle={tradeIn} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={6}>
        <OfferDetailsWidget
          totalPrice={offer.totalPrice}
          vehiclePrice={getOfferComponentPrice(offer, 'Vehicle Price')}
          salesTax={getOfferComponentPrice(offer, 'Sales Tax')}
          tradeinValue={getOfferComponentPrice(offer, 'Trade-In Vehicle')}
          notes={offer.notes}
          addons={offer.addons}
          actions={actions}
          previousOffer={previousOffer}
          showActions={showActions}
        />
      </Grid>
    </Grid>
  )
}
