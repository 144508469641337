import { useCallback, useLayoutEffect, FC } from 'react'
import { useParams } from 'react-router-dom'
import { PageContainer } from '../../layout/PageContainer'
import { Show } from '../../atoms/JSXExtensions'
import { OfferDetailsView } from './OfferDetailsView'
import {
  useReadTransaction,
  useApiContext,
  useMakeService,
  useAppDispatch,
  useChatSearchParams
} from '../../utils/hooks'
import { ResponseStatusMap } from '../../types/status'
import { actionsOfferPage } from '../../store/pages/Offer'
import { OFFER_TABS } from './constants'
import { NormalizedTransactionType } from '../../api/carSnoopApi/transactions/types'

interface OfferDetailsPageProps {
  transaction: NormalizedTransactionType
}

export const OfferDetailsPage: FC<OfferDetailsPageProps> = ({
  transaction: initialTransactionData
}) => {
  const { transactionId = '' } = useParams()
  const { isLoading, data, readTransaction } = useReadTransaction({
    transactionId,
    initialTransactionData
  })
  const dispatch = useAppDispatch()
  const { carSnoopApi } = useApiContext()
  const isChatOpen = useChatSearchParams()

  useLayoutEffect(() => {
    if (isChatOpen) {
      dispatch(actionsOfferPage.setCurrentTab(OFFER_TABS.chat))
    } else {
      dispatch(actionsOfferPage.setCurrentTab(OFFER_TABS.offerInformation))
    }
  }, [isChatOpen])

  const [cancelOffer] = useMakeService(
    async () => {
      const res = await carSnoopApi.offers.cancelOffer(transactionId)

      if (res.status === ResponseStatusMap.Success) {
        return readTransaction(transactionId)
      }
      return res
    },
    { includeCheckAccess: true, withStatusNotification: true }
  )

  const [declineOffer] = useMakeService(
    async () => {
      const res = await carSnoopApi.offers.declineOffer(transactionId)

      if (res.status === ResponseStatusMap.Success) {
        return readTransaction(transactionId)
      }
      return res
    },
    { includeCheckAccess: true, withStatusNotification: true }
  )

  const handleReadTransaction = useCallback(() => {
    readTransaction(transactionId)
  }, [transactionId])

  return (
    <PageContainer isLoading={isLoading}>
      <Show when={data}>
        {(props) => (
          <OfferDetailsView
            {...props}
            transactionID={transactionId}
            onCancelOffer={cancelOffer}
            onDeclineOffer={declineOffer}
            readTransaction={handleReadTransaction}
          />
        )}
      </Show>
    </PageContainer>
  )
}
