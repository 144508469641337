import { FIELDS_NAMES, FormFields } from './types'

export const DEFAULT_VALUES: FormFields = {
  [FIELDS_NAMES.ADDRESS_NAME]: '',
  [FIELDS_NAMES.STREET]: '',
  [FIELDS_NAMES.ZIP_CODE]: '',
  [FIELDS_NAMES.CITY]: '',
  [FIELDS_NAMES.STATE]: '',
  [FIELDS_NAMES.CONTACT_NAME]: '',
  [FIELDS_NAMES.CONTACT_NUMBER]: '',
  [FIELDS_NAMES.CONTACT_EMAIL]: '',
  [FIELDS_NAMES.NOTES]: ''
}
