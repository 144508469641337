import { FC, useMemo, Ref } from 'react'
import { Formik, Form, FormikProps } from 'formik'
import { useUserInfo } from '../../../../utils/hooks'
import { StripeIcon } from '../../../../assets/icons/StripeIcon'
import { CheckIcon } from '../../../../assets/icons/CheckIcon'
import { CashIcon } from '../../../../assets/icons/CashIcon'
import { LeaseIcon } from '../../../../assets/icons/LeaseIcon'
import { FinanceIcon } from '../../../../assets/icons/FinanceIcon'
import {
  PaymentFormValues,
  initialValues,
  PAYMENT_FIELD_NAMES,
  PAYMENT_METHODS_ENUM
} from './constants'
import { FormDescription } from '../../components/FormDescription'
import { noop } from '../../../../utils/noop'
import { RadioActionGroup } from '../../../../atoms/RadioGroup'

const returnPaymentTypesList = (isDealer: boolean) =>
  isDealer
    ? [
        {
          name: PAYMENT_METHODS_ENUM.CHECK,
          image: <CheckIcon />
        },
        {
          name: PAYMENT_METHODS_ENUM.CASH,
          image: <CashIcon />
        },
        {
          name: PAYMENT_METHODS_ENUM.ELECTRONIC,
          label: 'Electronic',
          image: <StripeIcon />
        }
      ]
    : [
        {
          name: PAYMENT_METHODS_ENUM.CHECK,
          image: <CheckIcon />
        },
        {
          name: PAYMENT_METHODS_ENUM.CASH,
          image: <CashIcon />
        },
        {
          name: PAYMENT_METHODS_ENUM.ELECTRONIC,
          label: 'Electronic',
          image: <StripeIcon />
        },
        {
          name: PAYMENT_METHODS_ENUM.LEASE,
          image: <LeaseIcon />
        },
        {
          name: PAYMENT_METHODS_ENUM.FINANCE,
          image: <FinanceIcon />
        }
      ]

export type PaymentFormRefTargetType = FormikProps<PaymentFormValues>

interface PaymentMicroformProps {
  defaultValues?: PaymentFormValues
  formRef: Ref<PaymentFormRefTargetType>
}

export const PaymentMicroform: FC<PaymentMicroformProps> = ({
  formRef,
  defaultValues
}) => {
  const { isDealer } = useUserInfo()

  const paymentTypesList = useMemo(
    () => returnPaymentTypesList(isDealer),
    [isDealer, returnPaymentTypesList]
  )

  return (
    <Formik<PaymentFormValues>
      initialValues={defaultValues || initialValues}
      onSubmit={noop}
      validateOnChange={false}
      innerRef={formRef}
    >
      {() => (
        <Form>
          <FormDescription
            title='2. Select final payment method'
            description='Selected method will be used for the final payment'
          />
          <RadioActionGroup
            options={paymentTypesList}
            name={PAYMENT_FIELD_NAMES.PAYMENT_METHOD}
          />
        </Form>
      )}
    </Formik>
  )
}
