import { Grid, Typography } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroupMUI, {
  RadioGroupProps as MUIRadioGroupProps
} from '@mui/material/RadioGroup'
import { FC, FocusEvent, Fragment } from 'react'
import { ReferenceModel } from '../../types/ReferenceModel'
import { Show } from '../JSXExtensions'

export interface RadioGroupProps {
  name: string
  options: ReferenceModel[]
  row?: boolean
  value: string
  withBold?: boolean
  onChange?: MUIRadioGroupProps['onChange']
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
}

export const RadioGroup: FC<RadioGroupProps> = ({
  name,
  options,
  row,
  value,
  withBold = false,
  onBlur,
  onChange
}) => {
  return (
    <RadioGroupMUI
      row={row}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      name={name}
    >
      {options.map((option) => (
        <Fragment key={option.id}>
          <FormControlLabel
            control={<Radio color='default' />}
            key={option.id}
            label={
              <Typography fontWeight={withBold ? 'bold' : ''}>
                {option.name}
              </Typography>
            }
            sx={{ mr: { xs: 2, md: 4 } }}
            value={option.id}
          />
          <Show when={!!option.additionalInfo}>
            <Grid container ml={4}>
              <Typography>{option.additionalInfo}</Typography>
            </Grid>
          </Show>
        </Fragment>
      ))}
    </RadioGroupMUI>
  )
}
