export enum PAYMENT_METHODS_ENUM {
  ELECTRONIC = 'Stripe',
  CHECK = 'Check',
  CASH = 'Cash',
  LEASE = 'Lease',
  FINANCE = 'Finance'
}

export enum PAYMENT_STATUS {
  COMPLETED = 'Completed',
  WAITING_FOR_PAYMENT = 'Waiting for payment'
}

export enum PAYMENT_FIELD_NAMES {
  PAYMENT_METHOD = 'paymentMethod'
}

export interface PaymentFormValues {
  [PAYMENT_FIELD_NAMES.PAYMENT_METHOD]: PAYMENT_METHODS_ENUM
}

export const initialValues: PaymentFormValues = {
  [PAYMENT_FIELD_NAMES.PAYMENT_METHOD]: PAYMENT_METHODS_ENUM.CHECK
}
