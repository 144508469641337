import { Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { Condition } from '../../../../../atoms/Condition'
import {
  RequestedVehicleItem,
  RequestedVehicleItemProps
} from '../../../../../molecules/RequestedVehicleItem'
import { SHADOWS } from '../../../../../types/colors'

export type ReviewRequestProps = {
  requestedVehicle: RequestedVehicleItemProps['vehicle']
  notes?: string
}

export const ReviewRequest: FC<ReviewRequestProps> = (props) => {
  const { requestedVehicle, notes } = props

  return (
    <Grid container item spacing={3} direction='row'>
      <Grid item xs={12}>
        <Typography variant='label1'>Please review your request</Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
      >
        <RequestedVehicleItem vehicle={requestedVehicle} />
        <Condition
          condition={!!notes}
          trueContent={
            <Grid
              item
              container
              direction='column'
              sx={{
                borderRadius: '10px',
                boxShadow: SHADOWS.primary,
                padding: 2,
                marginTop: 2,
              }}
            >
              <Grid item>
                <Typography variant='subtitle2'>NOTES</Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant='body1'
                  sx={{
                    maxWidth: '80ch',
                    whiteSpace: 'pre-line',
                    wordBreak: 'break-word',
                  }}
                >
                  {notes}
                </Typography>
              </Grid>
            </Grid>
          }
        />
      </Grid>
    </Grid>
  )
}
