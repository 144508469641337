import { Grid, Paper, Typography } from '@mui/material'
import { Children, FC, PropsWithChildren, useMemo } from 'react'
import { BGCOLORS, SHADOWS } from '../../types/colors'
import { Condition } from '../Condition'
import { Loader } from '../Loader'

interface UserSettingsItemProps {
  hint?: string
  isEditMode?: boolean
  isLoading?: boolean
}

export const PersonalSettingsItem: FC<
  PropsWithChildren<UserSettingsItemProps>
> = ({ children, hint, isLoading, isEditMode }) => {
  const paddingX = useMemo(() => {
    return {
      xs: 2,
      lg: isEditMode ? 3 : 7,
      xl: 7
    }
  }, [isEditMode])

  return (
    <Grid item container xs={12}>
      <Grid item xs={12}>
        <Paper
          sx={{
            border: `1px solid ${BGCOLORS.white}`,
            boxShadow: SHADOWS.primary,
            pt: 3,
            pb: 2,
            height: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Grid item container xs={12} px={paddingX}>
            <Condition
              condition={!!isLoading}
              trueContent={<Loader />}
              falseContent={<>{Children.toArray(children)}</>}
            />
          </Grid>
        </Paper>
      </Grid>
      <Condition
        condition={!!hint}
        trueContent={
          <Grid item xs={12} m={1}>
            <Typography
              variant='subtitle2'
              sx={{ fontSize: { sm: 12, lg: 14 } }}
            >
              {hint}
            </Typography>
          </Grid>
        }
      />
    </Grid>
  )
}
