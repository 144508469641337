import { ReadCarByYearResponse } from '../../../../api/carSnoopApi/vehicles'
import type { ColorOption } from '../../../../types/Autoselect'
import { arrayUnique } from '../../../../utils/arrayUnique'
import { stringToReferenceModel } from '../../../../utils/autoselect/stringToReferenceModel'
import { sortArrPropAlphabetically } from '../../../../utils/sortArrPropAlphabetically'
import type { VehicleColor } from '../thunks'

export const carsByYearResponseToExteriorColorsOption = (response: {
  data: ReadCarByYearResponse
}): ColorOption[] => {
  const { data } = response

  const allColors = data.reduce<VehicleColor[]>(
    (acc, { exterior_colors: colors }) => [...acc, ...colors],
    []
  )

  const uniqueColors = arrayUnique(allColors, (color) => color.color)
  const sortedAlphabeticallyColors = sortArrPropAlphabetically(
    uniqueColors,
    'color'
  )

  const colorOptions = sortedAlphabeticallyColors.map((color) => ({
    ...stringToReferenceModel(color.color),
    swatch: color.swatch
  }))

  return colorOptions
}
