import { Button, Grid } from '@mui/material'
import { Form, Formik, FormikValues } from 'formik'
import { NumericField } from '../../../../atoms/NumericField'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const NumericFieldExample = () => {
  const initialValues: FormikValues = {
    currentMileage: '',
    howMuchIsOwed: '0.00'
  }

  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <Grid container>
          <Formik initialValues={initialValues} onSubmit={console.warn}>
            {() => {
              return (
                <Form>
                  <Grid container spacing={3} flexDirection='column'>
                    <Grid item>
                      <NumericField
                        name='currentMileage'
                        label='Current Mileage'
                      />
                    </Grid>
                    <Grid item>
                      <NumericField
                        allowNegative={true}
                        name='howMuchIsOwed'
                        label='How much is owed'
                        startWith='$'
                      />
                    </Grid>
                    <Grid item>
                      <Button type='submit'>Log value</Button>
                    </Grid>
                  </Grid>
                </Form>
              )
            }}
          </Formik>
        </Grid>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
