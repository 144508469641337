import { Grid } from '@mui/material'
import { FC } from 'react'
import { ErrorTextWithRetry } from '../../../../atoms/ErrorTextWithRetry'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const ErrorTextWithRetryExample: FC = () => {
  const handleRetryClick = () => console.log('retry handler')

  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <Grid container flexDirection='column' spacing={1}>
          <Grid item>
            <ErrorTextWithRetry
              errorText='Custom error text'
              retryButtonText='Retry text'
              onRetryClick={handleRetryClick}
            />
          </Grid>
          <Grid item>
            <ErrorTextWithRetry
              errorText='Custom error text with retry icon'
              retryButtonText='Retry text'
              onRetryClick={handleRetryClick}
              withRetryIcon
            />
          </Grid>
          <Grid item>
            <ErrorTextWithRetry errorText='Simple error text' />
          </Grid>
        </Grid>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
