import { parseRelativePhoneNumber } from '../../../../../utils/formatters/parseRelativePhoneNumber'
import { NormalizedReadAccountType } from './normalized'
import { RawReadAccountType } from './raw'

export const formatReadAccount = (
  raw: RawReadAccountType
): NormalizedReadAccountType => {
  return {
    id: raw.id,
    name: raw.name,
    website: raw.website,
    phone: parseRelativePhoneNumber(raw.phone),
    isDealer: raw.is_dealer,
    createdOn: raw.created_on,
    createdBy: raw.created_by,
    changedOn: raw.changed_on,
    changedBy: raw.changed_by,
    otherData: raw.other_data,
    active: raw.active,
    deleted: raw.deleted,
    public: raw.public,
    users: raw.users.map((user) => ({
      id: user.id,
      isAdmin: user.is_admin,
      fullName: user.full_name,
      active: user.active,
      status: user.status,
      avatar: user.avatar,
      driversLicense: user.drivers_license,
      driversLicenseState: user.drivers_license_state
    })),
    addresses: raw.addresses.map((a) => ({
      id: a.id,
      nickname: a.nickname,
      street: a.street,
      city: a.city,
      state: a.state,
      zipCode: a.zip_code
    })),
    subscription: {
      name: raw.subscription.name,
      completedDeals: raw.subscription.completed_deals,
      maxDeals: raw.subscription.max_deals,
      price: raw.subscription.price
    },
    message: 'success',
    submitRequestFeePaid: raw.submit_request_fee_paid
  }
}
