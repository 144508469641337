import { RootState } from '../../types'
import { RegisterStep } from '../../../types/enums/RegisterStep'

export const extSelectRegistrationStep = (state: RootState) =>
  state.externalServiceRegistration.step

export const extSelectFormLength = (state: RootState) =>
  state.externalServiceRegistration.length

export const extSelectPersonalDetailsData = (state: RootState) => {
  return state.externalServiceRegistration[RegisterStep.stepOne]
}
export const extSelectContactDetailsData = (state: RootState) => {
  return state.externalServiceRegistration[RegisterStep.stepTwo]
}

export const extSelectIsExitState = (state: RootState) => {
  return state.externalServiceRegistration.step === -1
}
