import Box from '@mui/material/Box'
import { FC } from 'react'
import { GoogleButton } from './GoogleButton'

export const SocialNetLogIn: FC = () => {
  return (
    <Box sx={(theme) => ({
      display: 'flex',
      height: '40px',
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        alignItems: 'center',
        flexDirection: 'column',
        height: '90px',
        justifyContent: 'space-between',
        '& > *': {
          marginInline: '0 !important',
          width: '192px !important'
        }
      }
    })}>
      <GoogleButton />
    </Box>
  )
}
