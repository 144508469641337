import { useCallback, useEffect, useRef, useState } from 'react'
import { noop } from '../../noop'
import { useNavigationController } from './useNavigationController'
import { useAppDispatch } from '../../hooks'
import { pageNavigation } from '../../../store/thunks/pageNavigation'

export const useBlockPath = (enabled?: boolean) => {
  const controller = useNavigationController()
  const dispatch = useAppDispatch()

  const [isNavigateConfirmationOpen, setIsNavigateConfirmationOpen] =
    useState(false)

  const discardCallback = useRef(noop)

  useEffect(() => {
    if (enabled) {
      controller.blockPath((callback) => {
        discardCallback.current = callback
        setIsNavigateConfirmationOpen(true)
      })
    }

    return () => {
      controller.releasePath()
    }
  }, [enabled])

  const onNavigationConfirm = useCallback(() => {
    discardCallback.current()
    dispatch(pageNavigation())
  }, [dispatch])

  return {
    controller,
    isNavigateConfirmationOpen,
    setIsNavigateConfirmationOpen,
    onNavigationConfirm
  }
}
