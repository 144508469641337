export enum ResetPasswordConstants {
  title = 'Reset your Password',
  description = 'You’re allowed to use the combination of letters, numbers, and special characters. The minimum length of the password is 8, maximum length is 20.',
  promotionDescription = 'CarSnoop was designed to save you time, money and the hassle of negotiations and runarounds. Make CarSnoop work the best for you!',
}

export const resetPasswordInitialValues = {
  password: '',
  confirmPassword: ''
}
