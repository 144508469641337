import { ResponseStatusMap } from '../../types/status'
import { useApiContext } from './useApiContext'
import { useMakeService } from './useMakeService'

export const useCheckZipCode = () => {
  const { externalCarSnoopApi } = useApiContext()

  const [checkZipCode, checkZipCodeObservable] = useMakeService(
    async (zip: string) => {
      const response = await externalCarSnoopApi.addresses.validateZipCode(zip)

      if (response.status === ResponseStatusMap.Success) {
        return {
          status: response.status,
          city: response.city,
          state: response.state
        }
      }

      return response
    }
  )

  return { checkZipCode, checkZipCodeObservable }
}
