import { Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { COLORS, SHADOWS } from '../../types/colors'
import type { StatisticCardSchema } from '../../types/statisticCard'

type StatisticButtonProps = {
  schema: StatisticCardSchema
}

export const StatisticButton: FC<StatisticButtonProps> = ({ schema }) => {
  return (
    <>
      {schema.map((item, index) => {
        return (
          <Grid
            alignItems='center'
            container
            direction='column'
            item
            justifyContent='center'
            key={index}
            onClick={item.onClick}
            sx={{
              cursor: 'pointer',
              height: '100%',
              position: 'relative', // Required for dividers
              ':hover': {
                backgroundColor: COLORS.hover_background_blue,
                boxShadow: SHADOWS.hover,
                cursor: 'pointer'
              }
            }}
            xs={4}
          >
            <Grid item>
              <Typography
                color={COLORS.text_blue}
                fontSize={42}
                fontWeight={500}
                textAlign='center'
              >
                {item.value}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                color={COLORS.text_primary}
                letterSpacing='0.03em'
                textAlign='center'
                textTransform='uppercase'
                variant='main'
              >
                {item.text}
              </Typography>
            </Grid>
          </Grid>
        )
      })}
    </>
  )
}
