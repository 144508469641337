import { Fields } from '../../../types/forms/registrationByEmail'

export const INVITATION_LINK_ERROR = {
  TITLE: 'The invitation link is invalid',
  SUBTITLE: 'Please, contact your account owner to get a new link.'
}

export const INVITATION_LINK_SUCCESS = {
  TITLE: 'Almost there!',
  SUBTITLE: 'Your registration has been completed successfully!'
}

export const initialValues: Fields = {
  firstName: '',
  lastName: '',
  birthDate: null,
  phone: '',
  password: '',
  confirmPassword: ''
}
