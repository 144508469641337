import { Grid, Modal, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { FC, MouseEventHandler, useCallback, useMemo, useState } from 'react'
import { ReadRequestsResponse } from '../../api/carSnoopApi/requests/types'
import { Card } from '../../atoms/Card'
import { Condition } from '../../atoms/Condition'
import { DependentFieldHocEffect } from '../../atoms/DependentField'
import { Divider } from '../../atoms/Divider'
import { Loader } from '../../atoms/Loader'
import { NotesField } from '../../atoms/NotesField'
import { NumericField, NumericFieldProps } from '../../atoms/NumericField'
import { RequiredLabel } from '../../atoms/RequiredText'
import { CREATE_OFFER_MODAL_FORM_KEY } from '../../constants/localStorageKeys'
import { AddOnsArray, IAddOnFormValues } from '../../molecules/AddOn'
import { CommonConfirmationModal } from '../../molecules/Modal/CommonConfirmationModal'
import { TradeInField } from '../../molecules/TradeInField'
import { OrNull } from '../../types/OrNull'
import { ResponseStatusMap } from '../../types/status'
import { formatPrice } from '../../utils'
import { useModal, useRestoreData } from '../../utils/hooks'
import { isRequiredFormField } from '../../utils/validators/isRequiredFormField'
import { DependentTaxValueField } from './DependentFields'
import { ModalFooter } from './ModalFooter'
import { ModalHeader } from './ModalHeader'
import {
  OFFER_INITIAL_VALUES,
  OFFER_MODAL_FIELDS
} from './OfferModal.constants'
import { calculateSubtotalCost, calculateTotalCost } from './OfferModal.helpers'
import { modalContainerStyle } from './OfferModal.styles'
import { IOfferModalForm } from './OfferModal.types'
import { validationSchema } from './OfferModal.validation'
import { TotalPrice } from './components/TotalPrice'
import { useRequests } from './hooks/useRequests'

export interface IOfferModalProps {
  vehicleZipCode: string
  isOpen: boolean
  isModalLoading: boolean
  withDeclineModal?: boolean
  confirmText?: string
  title?: string
  withConfirmationModal?: boolean
  vehiclePrice: ReadRequestsResponse.Normilized.ReadRequestsResponse_Tradein['retailValue']
  tradeIn?: number
  notes?: OrNull<
    ReadRequestsResponse.Normilized.ReadRequestsResponse_Tradein['notes']
  >
  addons?: OrNull<Array<IAddOnFormValues>>
  salesTax?: number
  includeTradeIn: boolean
  onConfirm: (data: IOfferModalForm) => Promise<void | boolean>
  onCloseClick: () => void
  onRemoveTradeInConfirm: () => void
}

export const OfferModal: FC<IOfferModalProps> = ({
  isOpen,
  confirmText = 'Create',
  title = 'Create Offer',
  onCloseClick,
  onConfirm,
  vehiclePrice,
  tradeIn,
  addons = [],
  notes = null,
  includeTradeIn,
  withConfirmationModal = false,
  withDeclineModal = false,
  vehicleZipCode,
  isModalLoading,
  salesTax = 0,
  onRemoveTradeInConfirm
}) => {
  const { getVehicleTaxObservable, getVehicleTax } = useRequests()
  const offerConfirmationModal = useModal()
  const offerDeclineModal = useModal()
  const [showTotalPrice, setShowTotalPrice] = useState(false)

  const { restoredData, isRestoring, storeData, clearData } =
    useRestoreData<IOfferModalForm>(CREATE_OFFER_MODAL_FORM_KEY)

  const handleFormSubmit = useCallback(
    async (values: IOfferModalForm) => {
      storeData(values)
      const isSuccess = await onConfirm(values)

      if (isSuccess) {
        clearData()
      }
    },
    [onConfirm, storeData, clearData]
  )

  const taxValueFieldEffect: DependentFieldHocEffect<
    NumericFieldProps,
    IOfferModalForm
  > = useCallback(
    async (___, ctx) => {
      const { values, setFieldValue } = ctx
      if (!salesTax || showTotalPrice) {
        const result = await getVehicleTax({
          sellerZipCode: vehicleZipCode,
          totalAmount: calculateSubtotalCost(values)
        })

        if (result.status === ResponseStatusMap.Success) {
          setFieldValue(OFFER_MODAL_FIELDS.SALES_TAX, result.tax)
        } else {
          setFieldValue(OFFER_MODAL_FIELDS.SALES_TAX, '0.00')
        }

        return setShowTotalPrice(true)
      }
      setFieldValue(OFFER_MODAL_FIELDS.SALES_TAX, salesTax.toFixed(2))
      setShowTotalPrice(true)
    },
    [vehicleZipCode, salesTax, showTotalPrice]
  )
  const handleCloseClick = useCallback(() => {
    clearData()
    onCloseClick()
    setShowTotalPrice(false)
  }, [onCloseClick, clearData])

  const handleDiscardEdit = useCallback(() => {
    handleCloseClick()
    offerDeclineModal.setOpen(false)
  }, [handleCloseClick, offerDeclineModal])

  const handleModalCancel: MouseEventHandler = useCallback(
    (event) => {
      if (withDeclineModal) {
        return offerDeclineModal.onOpen(event)
      }
      handleCloseClick()
    },
    [withDeclineModal, offerDeclineModal.onOpen, handleCloseClick]
  )

  const defaultOfferForm = useMemo(() => {
    if (restoredData) {
      return restoredData
    }
    return {
      ...OFFER_INITIAL_VALUES,
      [OFFER_MODAL_FIELDS.ADD_ONS]: addons || [],
      [OFFER_MODAL_FIELDS.NOTES]: notes || OFFER_INITIAL_VALUES.notes,
      [OFFER_MODAL_FIELDS.VEHICLE_PRICE]: vehiclePrice.toString(),
      [OFFER_MODAL_FIELDS.TRADE_IN]: formatPrice(tradeIn || 0),
      [OFFER_MODAL_FIELDS.SALES_TAX]: salesTax.toFixed(2)
    }
  }, [vehiclePrice, tradeIn, notes, addons, restoredData])

  const isRequiredField = isRequiredFormField(validationSchema)

  return (
    <Modal
      open={isOpen}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Grid item container sx={modalContainerStyle}>
        <Formik<IOfferModalForm>
          initialValues={defaultOfferForm}
          onSubmit={handleFormSubmit}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          validateOnMount
          enableReinitialize
        >
          {({ values, isValid, dirty, handleSubmit, validateForm }) => (
            <>
              <Card
                header={<ModalHeader title={title} />}
                footer={
                  <ModalFooter
                    isConfirmDisabled={
                      !isValid ||
                      getVehicleTaxObservable.isLoading ||
                      !dirty ||
                      +calculateTotalCost(values) < 0
                    }
                    confirmText={confirmText}
                    onCancel={handleModalCancel}
                    onConfirm={
                      withConfirmationModal
                        ? offerConfirmationModal.onOpen
                        : handleSubmit
                    }
                  />
                }
                onCloseClick={offerDeclineModal.onOpen}
              >
                <Condition
                  condition={isModalLoading || isRestoring}
                  trueContent={<Loader />}
                  falseContent={
                    <Grid container padding='36px'>
                      <Grid item container rowSpacing={1}>
                        <TotalPrice
                          showTotalPrice={showTotalPrice}
                          totalPrice={calculateTotalCost(values)}
                        />
                        <Grid item xs={12}>
                          <RequiredLabel />
                        </Grid>
                        <Form style={{ width: '100%' }}>
                          <Grid container item spacing={1} mt='8px'>
                            <Grid item sm={6}>
                              <NumericField
                                startWith='$'
                                name={OFFER_MODAL_FIELDS.VEHICLE_PRICE}
                                label='Vehicle price'
                                // TODO: find a way to remove onBlur from form fields
                                onBlur={validateForm}
                                required={isRequiredField}
                              />
                            </Grid>
                            <Grid item sm={6}>
                              <DependentTaxValueField
                                startWith='$'
                                name={OFFER_MODAL_FIELDS.SALES_TAX}
                                label='Sales tax'
                                onChangeEffect={taxValueFieldEffect}
                                values={values}
                                onBlur={validateForm}
                                allowNegative
                                required={isRequiredField}
                              />
                            </Grid>
                          </Grid>
                          <Grid item padding='24px 0'>
                            <Divider />
                          </Grid>
                          <Grid item paddingBottom='32px'>
                            <AddOnsArray
                              name={OFFER_MODAL_FIELDS.ADD_ONS}
                              values={values[OFFER_MODAL_FIELDS.ADD_ONS]}
                              onBlur={validateForm}
                            />
                          </Grid>
                          {includeTradeIn && (
                            <Grid item>
                              <TradeInField
                                name={OFFER_MODAL_FIELDS.TRADE_IN}
                                onRemoveTradeInConfirm={onRemoveTradeInConfirm}
                                onBlur={validateForm}
                                required
                              />
                            </Grid>
                          )}
                          <Grid item mt='8px'>
                            <Typography variant='label1'>Notes</Typography>
                          </Grid>
                          <Grid item>
                            <NotesField
                              name={OFFER_MODAL_FIELDS.NOTES}
                              label='You may leave any additional information for the buyer here.'
                              required={isRequiredField}
                            />
                          </Grid>
                        </Form>
                      </Grid>
                    </Grid>
                  }
                />
              </Card>
              <CommonConfirmationModal
                isOpen={offerConfirmationModal.isOpen}
                setOpen={offerConfirmationModal.setOpen}
                onSubmit={handleSubmit}
                message='Create offer?'
                hint='All other open requests for this vehicle will be declined and cannot be recovered.'
              />
              <CommonConfirmationModal
                isOpen={offerDeclineModal.isOpen}
                setOpen={offerDeclineModal.setOpen}
                onSubmit={handleDiscardEdit}
                message='Are you sure you want to discard your changes?'
                confirmText='Discard'
                cancelText='Cancel'
                header='Discard Changes'
              />
            </>
          )}
        </Formik>
      </Grid>
    </Modal>
  )
}
