import { useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useBreadcrumbs } from '../../../../utils/hooks/useBreadcrumbs'
import { useBlockPath } from '../../../../utils/NavigationController/hooks/useBlockPath'
import { useAddVehicleFormSlice } from './useAddVehicleFormSlice'

export const useAddVehicleDiscardModal = () => {
  const [isDiscardModalOpen, setDiscardModalOpen] = useState(false)
  const { resetBreadcrumbs, popBreadcrumb } = useBreadcrumbs()
  const navigate = useNavigate()
  const {
    isNavigateConfirmationOpen,
    setIsNavigateConfirmationOpen,
    onNavigationConfirm
  } = useBlockPath()

  const { closeAddVehicleModal, isAddVehicleModalOpen, resetAddVehicleForm } =
    useAddVehicleFormSlice()

  const handleDiscard = useCallback(() => {
    if (isAddVehicleModalOpen) {
      popBreadcrumb()
      return closeAddVehicleModal()
    }
    resetBreadcrumbs()
    resetAddVehicleForm()
    navigate(-1)
  }, [isAddVehicleModalOpen, closeAddVehicleModal])

  const handleNavigationDiscard = useCallback(() => {
    if (isAddVehicleModalOpen) {
      return closeAddVehicleModal()
    }

    onNavigationConfirm()
  }, [closeAddVehicleModal, isAddVehicleModalOpen, onNavigationConfirm])

  return {
    isDiscardModalOpen,
    isNavigateConfirmationOpen,
    setDiscardModalOpen,
    setIsNavigateConfirmationOpen,
    handleDiscard,
    handleNavigationDiscard
  }
}
