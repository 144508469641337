import { Paper, SxProps } from '@mui/material'
import { FC, PropsWithChildren } from 'react'
import { COLORS, SHADOWS } from '../../types/colors'

export type DashboardItemprops = {
  isInteractive?: boolean
  onClick?: () => void
  sx?: SxProps
} & PropsWithChildren

export const DashboardItem: FC<DashboardItemprops> = (props) => {
  const { isInteractive, onClick, children, sx = {} } = props

  return (
    <Paper
      sx={{
        border: '1px solid #FAFAFA',
        boxShadow: SHADOWS.primary,
        padding: '1.5em',
        height: '100%',
        ':hover': isInteractive
          ? {
              cursor: 'pointer',
              backgroundColor: COLORS.hover_background_blue,
              border: `1px solid ${COLORS.border_gray}`,
              boxShadow: SHADOWS.hover
            }
          : {},
        ...sx
      }}
      onClick={onClick}
    >
      {children}
    </Paper>
  )
}
