import { FC } from 'react'
import { StyledLabel } from './Label.styles'

type LabelProps = {
  name: string
  label: string
}

export const Label: FC<LabelProps> = ({ name, label }) => {
  return <StyledLabel htmlFor={name}>{label}</StyledLabel>
}
