import { ResponseStatusMap } from '../../../types/status'
import { ErrorResponse, SuccessResponse } from '../../private.types'
import { Utils } from '../../utils'
import { transactionMapper } from './mappers/transactionMapper'
import { transactionsMapper } from './mappers/transactionsMapper'
import {
  NormalizedReadTransactionsList,
  NormalizedTransactionType,
  RawReadTransactionsItemType,
  RawTransactionType,
  RemoveTradeInError,
  RemoveTradeInSuccess
} from './types'

export class Transactions {
  protected readonly utils: Utils
  protected readonly operations = {
    readTransaction: 'readTransaction',
    removeTradeIn: 'removeTradeIn',
    readTransactions: 'readTransactions',
    cancelTransaction: 'cancelTransaction'
  }

  constructor(utils: Utils) {
    this.utils = utils
  }

  async readTransaction(
    id: string
  ): Promise<
    SuccessResponse<{ data: NormalizedTransactionType }> | ErrorResponse
  > {
    type ErrorResponseType = ErrorResponse<{ message: string }>

    const { type, payload } = await this.utils.makeJSONRequest<
      RawTransactionType,
      ErrorResponseType
    >(this.operations.readTransaction, {
      id
    })

    if (type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        data: transactionMapper(payload)
      }
    }

    return {
      status: ResponseStatusMap.Error
    }
  }

  async removeTradeIn(
    id: string
  ): Promise<
    SuccessResponse<RemoveTradeInSuccess> | ErrorResponse<RemoveTradeInError>
  > {
    type RequestResponse = {
      id: string
      message: string
    }

    const { type, payload } = await this.utils.makeJSONRequest<
      RequestResponse,
      RequestResponse
    >(this.operations.removeTradeIn, { id })

    if (type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        id: payload.id,
        message: payload.message
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: payload.id,
      message: payload.message
    }
  }

  async readTransactions({
    stockNumber = '',
    status: transactionsStatus,
    type: mode
  }: {
    stockNumber?: string
    status: 'in_progress' | 'completed'
    type: 'buying' | 'selling'
  }): Promise<
    SuccessResponse<{ data: NormalizedReadTransactionsList }> | ErrorResponse
  > {
    type ErrorResponseType = ErrorResponse<{ message: string }>

    const { type, payload } = await this.utils.makeJSONRequest<
      RawReadTransactionsItemType[],
      ErrorResponseType
    >(this.operations.readTransactions, {
      stock_number: stockNumber,
      type: mode,
      status: transactionsStatus
    })

    if (type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        // TODO: Ensure payload only returns transactions with vehicles
        // TODO: Ensure vehicles are not null (probably deleted manually in db)
        data: payload.filter(x => x.vehicle).map(transactionsMapper)
      }
    }

    return {
      status: ResponseStatusMap.Error
    }
  }

  async cancelTransaction(
    id: string
  ): Promise<
    | SuccessResponse<{ id: string; message: string }>
    | ErrorResponse<{ message: string }>
  > {
    type RequestResponse = {
      id: string
      message: string
    }

    const { type, payload } = await this.utils.makeJSONRequest<
      RequestResponse,
      RequestResponse
    >(this.operations.cancelTransaction, { transaction_id: id })

    if (type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        id: payload.id,
        message: payload.message
      }
    }

    return {
      status: ResponseStatusMap.Error,
      message: payload.message
    }
  }
}
