import { Grid } from '@mui/material'
import { FC } from 'react'
import { InformationLabel } from '../../../../atoms/InformationLabel'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const InformationLabelExample: FC = () => {
  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <Grid item display='flex'>
          <InformationLabel labelText='Label text' />
        </Grid>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
