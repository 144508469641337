import { ResponseStatusMap } from '../../../types/status'
import {
  ServiceType,
  useApiContext,
  useMakeService,
  useSubscriptionModal
} from '../../../utils/hooks'

export const useCheckSubscription = () => {
  const { carSnoopApi } = useApiContext()
  const { openSubscriptionModal } = useSubscriptionModal()
  const checkSubscription = async () => {
    const checkResult = await carSnoopApi.subscription.checkSubscription()
    const isSuccess = checkResult.status === ResponseStatusMap.Success

    if (isSuccess) {
      return { ...checkResult, ok: isSuccess }
    }

    if (checkResult.subscriptionIsInvalid) {
      openSubscriptionModal()

      return {
        ...checkResult,
        ok: isSuccess,
        status: ResponseStatusMap.Success
      }
    }

    return { ...checkResult, ok: false }
  }

  const [checkSubscriptionService, checkSubscriptionServiceObservable] =
    useMakeService(checkSubscription, { withStatusNotification: [1] })

  const withSubscriptionChecking =
    <T extends ServiceType>(service: T) =>
    async (
      params: Parameters<T>[0]
    ): Promise<ReturnType<T> | ReturnType<typeof checkSubscription>> => {
      const subscriptionCheckResult = await checkSubscription()

      if (subscriptionCheckResult.status === ResponseStatusMap.Error) {
        return subscriptionCheckResult
      }

      return service(params)
    }

  return {
    checkSubscriptionService,
    checkSubscriptionServiceObservable,
    withSubscriptionChecking
  }
}
