import { useCallback } from 'react'

export const usePaymentRedirect = () => {
  const tryToRedirect = useCallback(
    async (getRedirectUrl: (() => Promise<string>) | string) => {
      if (typeof getRedirectUrl === 'string') {
        window.location.href = getRedirectUrl
      } else {
        const redirectUrl = await getRedirectUrl()

        window.location.href = redirectUrl
      }
    },
    []
  )

  return {
    tryToRedirect
  }
}
