import { Box, Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { Document } from '../../../assets/icons/Document'
import { BlueButton } from '../../../atoms/Button'
import { ConfirmationModal } from '../../../molecules/Modal'
import { COLORS } from '../../../types/colors'
import { useDeviceInfo, useModal } from '../../../utils/hooks'
import { OfferDetailsBody, OfferDetailsBodyProps } from '../OfferDetailsBody'

type PreviousOfferProps = OfferDetailsBodyProps

const previousOfferModalStyles = {
  padding: 3,
  minWidth: '50vw',
  maxHeight: '90vh',
  overflow: 'auto'
}

export const PreviousOffer: FC<PreviousOfferProps> = (props) => {
  const { isOpen, setOpen, onOpen } = useModal()
  const { isTablet } = useDeviceInfo()
  return (
    <>
      <ConfirmationModal
        isOpen={isOpen}
        setIsOpen={setOpen}
        header={
          <Grid item>
            <Typography variant='h6'>Previous offer</Typography>
          </Grid>
        }
      >
        <Box
          sx={previousOfferModalStyles}
          maxWidth={isTablet ? '85vw' : '50vw'}
        >
          <OfferDetailsBody {...props} isPreviousOffer />
        </Box>
      </ConfirmationModal>
      <BlueButton
        fullWidth
        onClick={onOpen}
        sx={{
          backgroundColor: COLORS.hover_background_blue,
          borderRadius: 0,
          verticalAlign: 'middle',
          textTransform: 'none'
        }}
      >
        <Grid container alignItems='center' spacing={1}>
          <Grid item>
            <Document color={COLORS.text_blue} />
          </Grid>
          <Grid item>
            <Typography variant='main' color='inherit'>
              View previous offer
            </Typography>
          </Grid>
        </Grid>
      </BlueButton>
    </>
  )
}
