import { NormalizedPaymentType, RawPaymentType } from '../types'

export const paymentMapper = (
  payments: RawPaymentType[]
): NormalizedPaymentType[] => {
  return payments.map((payment) => ({
    id: payment.id,
    transactionID: payment.transaction_id,
    method: payment.method,
    status: payment.status,
    type: payment.type,
    amount: payment.amount,
    notes: payment.notes,
    createdBy: payment.created_by,
    createdOn: new Date(payment.created_on),
    changedBy: payment.changed_by,
    changedOn: payment.changed_on ? new Date(payment.changed_on) : null,
    errorMessage: payment.error_message,
    paymentReference: payment.payment_reference
  }))
}
