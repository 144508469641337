import { Grid } from '@mui/material'
import { FC } from 'react'
import { Breadcrumbs } from '../../../../atoms/Breadcrumbs'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

const mockBreadCrumbs = [
  { label: 'Crumb without link' },
  { label: 'Crumb with link', link: '/components' },
  { label: 'Last active breadcrumb' }
]

export const BreadcrumbsExample: FC = () => {
  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <Grid container p={2}>
          <Grid item>
            <Breadcrumbs breadcrumbs={mockBreadCrumbs} />
          </Grid>
        </Grid>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
