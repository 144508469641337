import * as Yup from 'yup'
import { latinWithSpecial } from '../../../utils/validators'

export const validationSchema = Yup.object({
  dnl: Yup.string()
    .required('')
    .trim()
    .test('is-latin-or-special', 'Enter a valid DLN.', latinWithSpecial([' ']))
    .nullable(),
  // Sync with default DateInput value
  expirationDate: Yup.date()
    .nullable()
    .transform((value, originalValue) => {
      if (originalValue && originalValue.toString() === 'Invalid Date') {
        return new Date('01/01/1800')
      }

      return value
    })
    .min('1900-01-01', 'Enter a valid Date.')
    .max('2100-01-01', 'Enter a valid Date.')
    .required(''),
  state: Yup.string().required('State is required.')
})
