import { Box, Grid, styled } from '@mui/material'
import { BORDERS } from '../../types/colors'

export const CardContainer = styled(Grid)`
  background-color: white;
  box-shadow: $box-shadow;
  border-radius: 14px;
  overflow: auto;
  max-height: 100vh;
`

export const CardHeader = styled(Grid)`
  border-bottom: ${BORDERS.SM_GRAY};
  padding: 24px;
`

export const CardFooter = styled(Box)`
  width: 100%;
  border-top: ${BORDERS.SM_GRAY};
  padding: 24px;
`
