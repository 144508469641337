import { useCallback, useLayoutEffect, useMemo, useRef, RefObject } from 'react'
import { FormikProps } from 'formik'
import {
  useApiContext,
  useAppSelector,
  useMakeService,
  useUserInfo
} from '../../utils/hooks'
import {
  SearchComponentProps,
  SearchFormValues
} from '../../features/SearchInventory'
import { ResponseStatusMap } from '../../types/status'
import { selectInventoryPageModel } from '../../store/pages/Inventory'
import { FiltersFormValues } from './FilterInventory'
import { NormalizedReadInventoryItem } from '../../api/carSnoopApi/accounts/types'

export interface UseSearchComponentProps {
  filtersFormRef: RefObject<FormikProps<FiltersFormValues>>
  resetSearchVehiclesList: () => void
  applySearchVehiclesList: (
    vehiclesList: NormalizedReadInventoryItem[],
    formValues: SearchFormValues
  ) => void
}

export const useInventorySearch = ({
  filtersFormRef,
  applySearchVehiclesList,
  resetSearchVehiclesList
}: UseSearchComponentProps): SearchComponentProps => {
  const userInfo = useUserInfo()
  const { carSnoopApi } = useApiContext()
  const { action, searchFormValues } = useAppSelector(selectInventoryPageModel)
  const formRef =
    useRef<FormikProps<SearchComponentProps['initialValues']>>(null)

  const isActive = useMemo(() => action === 'search', [action])

  useLayoutEffect(() => {
    if (!isActive && formRef.current?.values.search) {
      formRef.current.resetForm()
    }
  }, [isActive])

  const [requestSearchInventoryService] = useMakeService(
    async (formValues: SearchComponentProps['initialValues']) => {
      const response = await carSnoopApi.accounts.readInventory({
        accountID: userInfo.accountID,
        stockNumber: formValues.search
      })

      if (response.status === ResponseStatusMap.Success) {
        applySearchVehiclesList(response.inventory, formValues)
      }

      return response
    }
  )

  const onSearchApply = useCallback(
    (values: SearchComponentProps['initialValues']) => {
      if (values.search) {
        requestSearchInventoryService(values)
      } else {
        resetSearchVehiclesList()
      }

      filtersFormRef?.current?.resetForm()
    },
    [requestSearchInventoryService]
  )

  return {
    formRef,
    onSearchApply,
    initialValues: searchFormValues
  }
}
