import { VehicleDetailsMainInfo } from '../../../VehicleDetails/components/VehicleDetailsMainInfo'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const VehicleDetailsMainInfoExample = () => {
  const handleDeleteClick = () => {
    // eslint-disable-next-line
    console.log('>>>', 'Delete')
  }

  return (
    <ComponentContainer>
      <ComponentWithPaperBackground title='Not in molecules folder (in routes/VehicleDetails)'>
        <VehicleDetailsMainInfo
          vin='1G3NL52E24C209053'
          mileage={10000}
          condition='Clean'
          retailValue={25000}
          tradeinValue={8000}
          address={{
            id: '1',
            nickname: 'Bla bla',
            city: 'Bugland',
            state: 'NY',
            street: 'Broken dreams',
            zipCode: '12345'
          }}
          isDeleteButtonEnabled
          onDeleteVehicle={handleDeleteClick}
          stockNumber='12345'
          isDealer
          vehicleAccountID='1232'
        />
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
