export const routes = {
  // Next.js Routes
  carsForSale: 'https://carsnoop.com/cars-for-sale/',
  conditions: 'https://carsnoop.com/master-service-agreement/',
  cookiePolicy: 'https://carsnoop.com/privacy-policy/',
  home: 'https://carsnoop.com',
  // App Routes
  carSearch: '/car-search',
  contracts: {
    completed: '/contracts/:transactionId/completed',
    index: '/contracts',
    paymentConfirmed: '/contracts/:transactionId/payment_confirmed',
    paymentSuccess: '/contracts/:transactionId/payment_success',
    transaction: '/contracts/:transactionId'
  },
  dashboard: '/dashboard',
  deals: {
    completed: '/deals/:mode/:transactionId/completed',
    details: '/deals/:mode/:transactionId',
    index: '/deals',
    requests: '/deals/requests/:vehicleID',
    vehicleDetails: '/deals/:vehicleID'
  },
  deleteUser: '/delete-user',
  forgotPassword: '/forgot-password',
  garage: '/garage',
  inventory: '/inventory',
  linkedin: '/linkedin',
  login: '/login',
  offers: {
    details: '/offers/:transactionId',
    payment: '/offers/:transactionId/payment',
    paymentResult: '/offers/:transactionId/payment_result'
  },
  personalSettings: '/personal-settings',
  registration: {
    external: '/registration/external',
    invitation: '/registration/invitation',
    manual: '/registration/manual'
  },
  resetPassword: '/reset-password',
  requests: '/requests',
  subscription: {
    index: '/subscription',
    paymentResult: '/subscription/payment_result'
  },
  vehicles: {
    add: '/vehicles/add',
    detail: '/vehicles/:vehicleId',
    edit: '/vehicles/:vehicleId/edit',
    publicDetail: '/public/vehicles/:vehicleId'
  },
  // TODO: NOT FOR PROD!!!
  // DEV Pages
  dev: {
    index: '/components',
    typography: '/components/typography',
  }
}
