import { SxProps } from '@mui/material'
import { COLORS } from '../../../../../types/colors'

export const inputStyles: SxProps = {
  width: '100%',
  '& .MuiInputBase-root': {
    fontSize: '16px',
    color: COLORS.text_primary,
    backgroundColor: COLORS.secondary_background
  },
  '& fieldset': {
    borderColor: COLORS.secondary_background
  },

  '& .MuiInputBase-root:hover': {
    '& fieldset': {
      borderColor: COLORS.text_blue
    }
  },

  '& .MuiInputBase-root.Mui-focused': {
    '& .MuiInputBase-input': {
      color: COLORS.text_primary
    },
    '& fieldset': {
      borderColor: COLORS.text_blue,
      borderWidth: '1px'
    }
  }
}

export const buttonStyles: SxProps = {
  width: '56px',
  height: '56px'
}
