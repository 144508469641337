export enum SELLER_FIELDS_LABELS {
  NAME = 'Legal Name',
  ADDRESS = 'Legal Address',
  ZIP_CODE = 'Zip Code',
  CITY = 'City',
  STATE = 'State'
}

export enum SELLER_FIELDS_NAMES {
  NAME = 'sellerLegalName',
  ADDRESS = 'sellerLegalAddress',
  ZIP_CODE = 'sellerZipCode',
  CITY = 'sellerCity',
  STATE = 'sellerState',
  NOTES = 'sellerNotes'
}

export enum SELLER_FIELDS_ERRORS {
  NAME = 'Enter a valid Legal Name.',
  ADDRESS = 'Enter a valid Street format.',
  ZIP_CODE = 'Enter a valid Zip Code.',
  NOTES = 'Enter a valid data format.'
}

export interface SellerFormFieldsTypes {
  [SELLER_FIELDS_NAMES.NAME]: string
  [SELLER_FIELDS_NAMES.ADDRESS]: string
  [SELLER_FIELDS_NAMES.ZIP_CODE]: string
  [SELLER_FIELDS_NAMES.CITY]: string
  [SELLER_FIELDS_NAMES.STATE]: string
  [SELLER_FIELDS_NAMES.NOTES]: string
}
