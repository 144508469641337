import { styled } from '@mui/system'
import { Form } from 'formik'

export const LoginScreenContainerForm = styled(Form)`
  & > div:first-of-type {
    margin: 0 0 24px 0;
  }

  & div + div > div > div {
    height: 46px;
  }

  & button {
    height: 46px;
  }
`

export const ForgotPasswordContainer = styled('div')`
  margin: 8px 0 32px;
  text-align: end;

  & a {
    color: #01c1e0;
  }
  & a:hover {
    text-decoration: underline;
  }
`
