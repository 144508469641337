import { UpdateContractApiRequest, UpdateContractParams } from '../types'

export const serializeDealSideDetails = (
  data: UpdateContractParams['buyerDetails']
): UpdateContractApiRequest['buyer_details'] => {
  // undefined case
  if (!data) return data

  return {
    legal_name: data.legalName,
    legal_street: data.legalStreet,
    legal_city: data.legalCity,
    legal_state: data.legalState,
    legal_zip_code: data.legalZipCode
  }
}
