import { useState } from 'react'
import { Button, Grid } from '@mui/material'
import { FindCarResult } from '../../../../atoms/FindCarResult'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const FindCarResultExample = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [withChilder, setWithChilder] = useState<boolean>(false)

  const handleSwitchIsLoadingClick = () => {
    setIsLoading(!isLoading)
  }

  const handleSwitchWithChildrenClick = () => {
    setWithChilder(!withChilder)
  }

  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <Grid container>
          <Grid item>
            <FindCarResult isLoading={isLoading}>
              {withChilder ? <h1>Hello! I am future result</h1> : null}
            </FindCarResult>
          </Grid>
        </Grid>
        <Grid item>
          <Button onClick={handleSwitchIsLoadingClick}>
            Switch is loading
          </Button>
          <Button onClick={handleSwitchWithChildrenClick}>
            Switch with children
          </Button>
        </Grid>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
