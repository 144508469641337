import { Option } from '../../../types/Autoselect'
import { stringToReferenceModel } from '../../../utils/autoselect/stringToReferenceModel'
import { sortArrPropAlphabetically } from '../../../utils/sortArrPropAlphabetically'
import { objectKeys } from '../../../utils/objectKeys'
import { NormalizedReadInventoryItem } from '../../../api/carSnoopApi/accounts/types'

export const getMakes = (
  vehiclesList: NormalizedReadInventoryItem[]
): Option[] => {
  const makes = vehiclesList.reduce((acc, vehicle) => {
    const make = stringToReferenceModel(vehicle.make)

    if (!acc.find((item) => item.name === make.name)) {
      acc.push(make)
    }

    return acc
  }, [] as Option[])

  return sortArrPropAlphabetically(makes, 'name')
}

export const getModels = (
  vehiclesList: NormalizedReadInventoryItem[],
  make: string
): Option[] => {
  const models = vehiclesList.reduce((acc, vehicle) => {
    const model = stringToReferenceModel(vehicle.model)

    if (
      vehicle.make === make &&
      !acc.find((item) => item.name === model.name)
    ) {
      acc.push(model)
    }

    return acc
  }, [] as Option[])

  return sortArrPropAlphabetically(models, 'name')
}

export const getYears = (
  vehiclesList: NormalizedReadInventoryItem[],
  make?: string,
  model?: string,
  minValue?: number
): Option[] => {
  const filters = {
    make,
    model
  }

  const optionsList = vehiclesList
    .reduce((acc, vehicle) => {
      const year = stringToReferenceModel(String(vehicle.year))

      if (
        !acc.find((item) => item.name === year.name) &&
        objectKeys(filters).every((key) =>
          filters[key] ? filters[key] === vehicle[key] : vehicle
        )
      ) {
        acc.push(year)
      }

      return acc
    }, [] as Option[])
    .sort((a, b) => Number(b.name) - Number(a.name))

  if (!minValue) {
    return sortArrPropAlphabetically(optionsList, 'name').reverse()
  }

  return sortArrPropAlphabetically(
    optionsList.filter((option) => Number(option.id) - minValue >= 0),
    'name'
  ).reverse()
}
