import { ColorOption, Option } from '../../../types/Autoselect'

export const defaultColorOption: ColorOption = {
  name: 'Any color',
  id: '',
  swatch: ''
}
export const defaultTrimOption: Option = {
  name: 'Any trim',
  id: ''
}
