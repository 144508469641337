import { Button } from '@mui/material'
import { Form, Formik, FormikValues } from 'formik'
import { useToast } from '../../../../utils/hooks'
import { TextInputField } from '../../../../atoms/TextInputField'
import { ResponseStatusMap } from '../../../../types/status'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const SnackbarExample = () => {
  const { openToast } = useToast()

  const handlerClick = (message: string, status: ResponseStatusMap) => {
    openToast({ message, status })
  }

  const initialValues: FormikValues = {
    snackbarMessage: ''
  }

  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <Formik initialValues={initialValues} onSubmit={console.warn}>
          {(formik) => {
            return (
              <Form>
                <TextInputField
                  name='snackbarMessage'
                  label=''
                  placeholder='Snackbar message'
                  isValid
                />
                <Button
                  type='submit'
                  onClick={() =>
                    handlerClick(
                      formik.values.snackbarMessage,
                      ResponseStatusMap.Success
                    )
                  }
                >
                  Open Success Snackbar
                </Button>
                <Button
                  type='submit'
                  onClick={() =>
                    handlerClick(
                      formik.values.snackbarMessage,
                      ResponseStatusMap.Error
                    )
                  }
                >
                  Open Error Snackbar
                </Button>
              </Form>
            )
          }}
        </Formik>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
