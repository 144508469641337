import { Grid, Typography } from '@mui/material'
import { Dispatch, FC, RefObject, SetStateAction, useMemo } from 'react'
import Cropper from 'react-cropper'
import { CameraIcon } from '../../assets/icons/Camera'
import { Condition } from '../../atoms/Condition'
import { Show } from '../../atoms/JSXExtensions'
import { Loader } from '../../atoms/Loader'
import { ProgressBar } from '../../atoms/ProgressBar'
import {
  UploadDocument,
  UploadDocumentCallbackPropsItem
} from '../../atoms/UploadDocument'
import { COLORS } from '../../types/colors'
import { CompressImageResponse } from '../../utils/compressImages'
import { ConfirmationModal, DiscardConfirmationModal } from '../Modal'

interface UploadAvatarModalsProps {
  isEditPopupOpen: boolean
  setIsEditPopupOpen: (value: boolean) => void
  isDiscardPopupOpen: boolean
  setIsDiscardPopupOpen: Dispatch<SetStateAction<boolean>>
  isProcessing: boolean
  progress: number
  fileData: UploadDocumentCallbackPropsItem | null
  cropperRef: RefObject<HTMLImageElement>
  onCrop: () => void
  onDiscardSubmit: () => void
  onSubmit: () => Promise<void>
  onDiscard: () => void
  error: string
  onChangeFileLoad: (files: CompressImageResponse) => Promise<void>
  beforeLoad: (files: File[]) => void
}

const desktopModalSizes = {
  marginBottom: '24px',
  maxHeight: '60vh',
  maxWidth: '80vw',
  minHeight: '60vh',
  minWidth: '100%',
  overflow: 'hidden'
}

const EditScreen: FC<
  Pick<UploadAvatarModalsProps, 'fileData' | 'onCrop' | 'cropperRef'>
> = ({ fileData, onCrop, cropperRef }) => {
  return (
    <Grid item sx={{ overflow: 'hidden', width: { xs: '80vw', lg: 700 } }}>
      <Cropper
        src={fileData?.base64 || ''}
        style={desktopModalSizes}
        initialAspectRatio={1}
        crop={onCrop}
        ref={cropperRef}
      />
    </Grid>
  )
}

const ErrorScreen: FC<
  Pick<UploadAvatarModalsProps, 'error' | 'onChangeFileLoad' | 'beforeLoad'>
> = ({ error, onChangeFileLoad, beforeLoad }) => {
  return (
    <Grid
      item
      container
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      spacing={2}
    >
      <Grid item width='fit-content'>
        <CameraIcon
          color={COLORS.error_message_red}
          width='44'
          height='44'
        />
      </Grid>
      <Grid item>
        <Typography variant='error'>{error}</Typography>
      </Grid>
      <Grid item>
        <UploadDocument
          name='retry-upload-avatar'
          initialCompression={false}
          onChange={onChangeFileLoad}
          onBeforeChange={beforeLoad}
        >
          <Typography
            color='inherit'
            sx={{
              cursor: 'pointer',
              color: COLORS.text_blue,
              ':hover': {
                textDecoration: 'underline'
              }
            }}
          >
            Click here to choose new photo
          </Typography>
        </UploadDocument>
      </Grid>
    </Grid>
  )
}

export const UploadAvatarModals: FC<UploadAvatarModalsProps> = ({
  isEditPopupOpen,
  setIsEditPopupOpen,
  onSubmit,
  onDiscard,
  fileData,
  isProcessing,
  progress,
  isDiscardPopupOpen,
  setIsDiscardPopupOpen,
  onDiscardSubmit,
  onChangeFileLoad,
  beforeLoad,
  onCrop,
  cropperRef,
  error
}) => {
  const submitText = useMemo(
    () => (isProcessing || error ? '' : 'Confirm'),
    [isProcessing, error]
  )

  const cancelText = useMemo(
    () => (isProcessing || error ? '' : 'Cancel'),
    [isProcessing, error]
  )

  return (
    <ConfirmationModal
      header={
        <Grid item>
          <Typography component='h1' variant='h6'>Upload New Photo</Typography>
        </Grid>
      }
      isOpen={isEditPopupOpen}
      setIsOpen={setIsEditPopupOpen}
      submit={{
        text: submitText,
        callback: onSubmit,
        closeModal: false
      }}
      cancel={{
        text: cancelText,
        callback: onDiscard,
        closeModal: false
      }}
    >
      <Grid
        container
        alignItems='center'
        justifyContent='center'
        p={7}
        sx={desktopModalSizes}
      >
        <Condition
          condition={!isProcessing && (!!fileData || !!error)}
          trueContent={
            <Condition
              condition={!error}
              trueContent={
                <EditScreen
                  fileData={fileData}
                  onCrop={onCrop}
                  cropperRef={cropperRef}
                />
              }
              falseContent={
                <ErrorScreen
                  error={error}
                  onChangeFileLoad={onChangeFileLoad}
                  beforeLoad={beforeLoad}
                />
              }
            />
          }
          falseContent={
            <Grid item container alignContent='center' height='100%'>
              <Show when={progress > 0} fallback={<Loader />}>
                <ProgressBar
                  label='We are working on compressing your image'
                  progress={progress}
                />
              </Show>
            </Grid>
          }
        />
        <DiscardConfirmationModal
          isOpen={isDiscardPopupOpen}
          setOpen={setIsDiscardPopupOpen}
          onSubmit={onDiscardSubmit}
        />
      </Grid>
    </ConfirmationModal>
  )
}
