import { FC, MouseEventHandler } from 'react'
import { Grid } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { RedButton } from '../../../atoms/Button'
import { Show } from '../../../atoms/JSXExtensions'
import { VehicleBadge } from '../../../molecules/VehicleItem/VehicleBadge'
import { TransactionStatusTypes } from '../../../api/carSnoopApi/statusTypes'
import { NavigationBackButton } from '../../../atoms/NavigationBackButton'

export interface HeaderProps {
  contractStatus: TransactionStatusTypes
  onCancelDealClick: MouseEventHandler
  onBackClick: () => void
  showBackButton?: boolean
  showCancelButton: boolean
}

export const Header: FC<HeaderProps> = (props) => {
  const {
    contractStatus,
    onCancelDealClick,
    onBackClick,
    showBackButton = true,
    showCancelButton
  } = props

  return (
    <Grid container justifyContent='space-between' wrap='nowrap'>
      <NavigationBackButton
        buttonText='Deal details'
        onBackClick={onBackClick}
        showBackButton={showBackButton}
      />
      <Grid
        container
        item
        wrap='nowrap'
        alignItems='center'
        justifyContent='flex-end'
        spacing={3}
      >
        <Grid item>
          <VehicleBadge status={contractStatus} />
        </Grid>
        <Show when={showCancelButton}>
          <Grid item>
            <RedButton onClick={onCancelDealClick} startIcon={<CloseIcon />}>
              Cancel Deal
            </RedButton>
          </Grid>
        </Show>
      </Grid>
    </Grid>
  )
}
