import { Button } from '@mui/material'
import { useModal } from '../../../../utils/hooks'
import { AddDocumentModal } from '../../../Contract/components/AddDocumentModal'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const AddDocumentModalExample = () => {
  const addDocumentModal = useModal()

  return (
    <ComponentContainer>
      <ComponentWithPaperBackground title='Not in molecules folder'>
        <Button onClick={addDocumentModal.onOpen}>Open</Button>
        <AddDocumentModal {...addDocumentModal} />
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
