export enum BUYER_FIELDS_NAMES {
  NAME = 'buyerLegalName',
  ADDRESS = 'buyerLegalAddress',
  ZIP_CODE = 'buyerZipCode',
  CITY = 'buyerCity',
  STATE = 'buyerState',
  CO_BUYER_NAME = 'coBuyerLegalName',
  CO_BUYER_ADDRESS = 'coBuyerLegalAddress',
  CO_BUYER_ZIP_CODE = 'coBuyerZipCode',
  CO_BUYER_CITY = 'coBuyerCity',
  CO_BUYER_STATE = 'coBuyerState'
}

export enum BUYER_FIELDS_LABELS {
  NAME = 'Legal Name',
  ADDRESS = 'Legal Address',
  ZIP_CODE = 'Zip Code',
  CITY = 'City',
  STATE = 'State'
}

export enum BUYER_FIELDS_ERRORS {
  NAME = 'Enter a valid Legal Name.',
  ADDRESS = 'Enter a valid Street format.',
  ZIP_CODE = 'Enter a valid Zip Code.'
}

export interface BuyerFormFieldsTypes {
  [BUYER_FIELDS_NAMES.NAME]: string
  [BUYER_FIELDS_NAMES.ADDRESS]: string
  [BUYER_FIELDS_NAMES.ZIP_CODE]: string
  [BUYER_FIELDS_NAMES.CITY]: string
  [BUYER_FIELDS_NAMES.STATE]: string
  [BUYER_FIELDS_NAMES.CO_BUYER_NAME]: string
  [BUYER_FIELDS_NAMES.CO_BUYER_ADDRESS]: string
  [BUYER_FIELDS_NAMES.CO_BUYER_ZIP_CODE]: string
  [BUYER_FIELDS_NAMES.CO_BUYER_CITY]: string
  [BUYER_FIELDS_NAMES.CO_BUYER_STATE]: string
}
