export enum FIND_CAR_STEPS {
  stepOne = 0,
  stepTwo = 1,
  stepThree = 2,
  stepFour = 3,
  stepFive = 4
}

export enum FIND_CAR_FIELD_NAMES {
  MAKE = 'make',
  MODEL = 'model',
  YEAR_FROM = 'yearFrom',
  YEAR_TO = 'yearTo',
  TRIM = 'trim',
  EXTERIOR_COLOR = 'exteriorColor',
  INTERIOR_COLOR = 'interiorColor',
  DELIVER_TO = 'deliverTo',
  NOTES = 'notes',
  TRADE_IN_VEHICLE = 'tradeinVehicle',
  SHOW_TRADE_IN = 'showTradeIn'
}

export enum FIND_CAR_FIELD_LABELS {
  MAKE = 'Make',
  MODEL = 'Model',
  YEAR_FROM = 'Year (From)',
  YEAR_TO = 'Year (To)',
  TRIM = 'Trim',
  EXTERIOR_COLOR = 'Exterior Color',
  INTERIOR_COLOR = 'Interior Color',
  DELIVER_TO = 'Deliver To',
  NOTES = 'You may leave any additional information for the seller here.'
}
