import { Grid, Typography } from '@mui/material'
import { FC } from 'react'

import { COLORS } from '../../../../../../../types/colors'
import { DetailsItemProps } from '../../DetailsItem'

interface RenderAddOnProps {
  quantity: number
  value: DetailsItemProps['value']
}

export const RenderAddOn: FC<RenderAddOnProps> = ({ quantity, value }) => {
  return (
    <Grid container item spacing={0.5}>
      <Grid item>
        <Typography variant='label1' color={COLORS.text_secondary}>
          {quantity}x
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant='label1'>{value}</Typography>
      </Grid>
    </Grid>
  )
}
