import { FC, useMemo } from 'react'
import { Grid, Typography } from '@mui/material'
import { formatNumber } from '../../../../../../../utils'
import { EllipsisText } from '../../../../../../../atoms/EllipsisText'

interface VehicleDetailsProps {
  mileage: number
  vin?: string
}

export const VehicleDetails: FC<VehicleDetailsProps> = ({ mileage, vin }) => {
  const formattedData = useMemo(
    () => ({
      mileage: formatNumber(mileage)
    }),
    [mileage]
  )

  const definedVin = useMemo(() => {
    // {vin = '-' } = props isn't the same as vin || '-'
    return vin || '-'
  }, [vin])

  return (
    <Grid container spacing={1}>
      <Grid item container spacing={0.5}>
        <Grid item>
          <Typography
            variant='body2'
            color='textSecondary'
            textTransform='uppercase'
          >
            mileage:
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='body2'>{formattedData.mileage}</Typography>
        </Grid>
      </Grid>
      <Grid item container spacing={1} wrap='nowrap'>
        <Grid item>
          <Typography
            variant='body2'
            color='textSecondary'
            textTransform='uppercase'
          >
            vin:
          </Typography>
        </Grid>
        <Grid item zeroMinWidth>
          <Typography variant='body1'>
            <EllipsisText tooltipText={definedVin}>{definedVin}</EllipsisText>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
