import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { OnboardingStepOneImg } from '../../assets/imgs/OnboardingStepOneImg'
import { PromotionBlock } from '../../atoms/PromotionBlock'
import { RegistrationExternalServiceForm } from '../../forms/RegistrationExternalService'
import { ServicePageContainer } from '../../layout/ServicePageContainer'
import { routes } from '../../routes'
import { UserInfoStatusMap } from '../../store/features/userInfo'
import { OnboardText } from '../../types/enums/OnboardText'
import { useUserInfo } from '../../utils/hooks'

export const ExternalServiceRegistrationPage: FC = () => {
  const navigate = useNavigate()
  const userInfo = useUserInfo()

  useEffect(() => {
    if (userInfo.status === UserInfoStatusMap.Authenticated) {
      navigate(routes.dashboard)
    }
  }, [userInfo])

  return (
    <ServicePageContainer>
      <PromotionBlock
        title={OnboardText.title}
        subtitle={OnboardText.subtitle}
        description={OnboardText.stepOneDesc}
        image={<OnboardingStepOneImg />}
      />
      <RegistrationExternalServiceForm />
    </ServicePageContainer>
  )
}
