import { Button } from '@mui/material'
import { FC, useCallback, useRef } from 'react'
import { UploadFileComponent } from '../../../../molecules/UploadFileComponent'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'
import { CompressImageSuccessResponse } from '../../../../utils/compressImages'

export const UploadFileComponentExample: FC = () => {
  const fileInputRef = useRef<HTMLDivElement>(null)
  const handleFileUpload = useCallback(() => {
    fileInputRef.current?.click()
  }, [])

  const onFileUpload = (file: CompressImageSuccessResponse) => {
    console.log(file)
  }

  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <Button onClick={handleFileUpload}>Upload file</Button>
        <UploadFileComponent
          fileInputRef={fileInputRef}
          onFileUpload={onFileUpload}
          isUploadInProgress={false}
        />
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
