import { CardMedia, Grid, Typography } from '@mui/material'
import { format } from 'date-fns'
import { FC, RefObject, useCallback, useMemo, useState } from 'react'
import { ErrorTextWithRetry } from '../../atoms/ErrorTextWithRetry'
import { UploadDocument } from '../../atoms/UploadDocument'
import { MMDDYYYY } from '../../constants/applicationConstants'
import { getFileExtension } from '../../utils'
import {
  CompressImageResponse,
  CompressImageSuccessResponse
} from '../../utils/compressImages'
import { useDeviceInfo, useModal } from '../../utils/hooks'
import { DocumentView } from '../DocumentView'
import { ConfirmationModal } from '../Modal'
import { FileIcon } from './components/FileIcon'
import { DEFAULT_FILE_INFO } from './UploadFileComponent.constants'
import { IBaseFileInfo } from './UploadFileComponent.types'
import { validateFileSize } from './utils/fileValidation'
import { OrNull } from '../../types/OrNull'
import { Show } from '../../atoms/JSXExtensions'
import { Loader } from '../../atoms/Loader'
import { supportedTypesMap } from '../../features/Chat/view/ChatMediaMessage/hooks/useFileType'

interface IUploadFileComponentProps {
  fileInputRef: RefObject<HTMLDivElement>
  isUploadInProgress: boolean
  onFileUpload: (file: CompressImageSuccessResponse) => void
  uploadDocumentId?: string
}

interface IFileUploadError {
  errorText: string
  withRetry?: boolean
}

export const UploadFileComponent: FC<IUploadFileComponentProps> = ({
  fileInputRef,
  onFileUpload,
  isUploadInProgress,
  uploadDocumentId = 'document'
}) => {
  const [progress, setProgress] = useState(0)
  const [uploadError, setUploadError] = useState<IFileUploadError>()
  const [baseFileData, setBaseFileData] =
    useState<IBaseFileInfo>(DEFAULT_FILE_INFO)
  const [processedFile, setProcessedFile] =
    useState<OrNull<CompressImageSuccessResponse>>(null)
  const { setOpen, ...uploadFileModal } = useModal()
  const { isTablet } = useDeviceInfo()
  const handleCloseModal = useCallback(() => {
    setUploadError({ errorText: '', withRetry: false })
    setBaseFileData(DEFAULT_FILE_INFO)
    setProcessedFile(null)
  }, [setUploadError, setBaseFileData, setProcessedFile])

  const handleSendClick = async () => {
    if (processedFile) {
      await onFileUpload(processedFile)
    }
    handleCloseModal()
    setOpen(false)
  }

  const isCompressionInProgress = !!progress && progress < 100

  const fileDescription = useMemo(() => {
    if (isCompressionInProgress) {
      return 'Compressing...'
    }

    return baseFileData.fileName
      ? `${baseFileData.extension.toUpperCase()} – ${baseFileData.creationDate}`
      : 'Loading...'
  }, [isCompressionInProgress, baseFileData])

  const handleUpload = useCallback(
    (uploadedFile: CompressImageResponse) => {
      const fileData = uploadedFile[0]
      const {
        size,
        blob: { name }
      } = fileData
      const extension = getFileExtension(name).toLocaleLowerCase()

      setBaseFileData({
        size,
        extension,
        fileName: name,
        creationDate: format(new Date(), MMDDYYYY)
      })
      setUploadError({
        errorText: validateFileSize(extension, size)
      })

      if (fileData.isFileExtensionAvailable) {
        setProcessedFile(fileData)
      } else {
        setProcessedFile(null)
      }
    },
    [setBaseFileData, setUploadError, setProcessedFile]
  )

  const error = useMemo(() => {
    return uploadError?.withRetry ? (
      <ErrorTextWithRetry
        errorText={uploadError.errorText}
        retryButtonText='Please retry'
        onRetryClick={handleSendClick}
      />
    ) : (
      uploadError?.errorText
    )
  }, [uploadError, handleSendClick])

  const isSubmitDisabled =
    isCompressionInProgress ||
    !!uploadError?.errorText ||
    !processedFile ||
    isUploadInProgress

  const isSupportedImageFile = useMemo(
    () =>
      processedFile?.type.startsWith('image') &&
      supportedTypesMap.image.includes(processedFile.fileExtension),
    [processedFile]
  )

  return (
    <>
      <UploadDocument
        name={uploadDocumentId}
        acceptType=''
        onChange={handleUpload}
        onProgress={setProgress}
        onBeforeChange={uploadFileModal.onOpenHandler}
        additionalExtensions={['*']}
      >
        <div ref={fileInputRef}>{/* Magic! */}</div>
      </UploadDocument>
      <ConfirmationModal
        {...uploadFileModal}
        setIsOpen={setOpen}
        cancel={{
          text: 'cancel',
          callback: handleCloseModal,
          disabled: isUploadInProgress
        }}
        submit={{
          text: 'send',
          callback: handleSendClick,
          closeModal: false,
          disabled: isSubmitDisabled
        }}
        header={<Typography variant='h6'>Upload file</Typography>}
      >
        <Grid container item p='24px 36px' width={isTablet ? '700px' : '780px'}>
          <Show
            when={!isUploadInProgress}
            fallback={
              <Grid container justifyContent='center'>
                <Loader small />
              </Grid>
            }
          >
            <Grid item xs={12}>
              <DocumentView
                documentName={baseFileData.fileName}
                documentIcon={
                  <Show
                    when={isSupportedImageFile && processedFile}
                    fallback={
                      <FileIcon fileExtension={baseFileData.extension} />
                    }
                  >
                    {(img) => (
                      <CardMedia
                        component='img'
                        src={img.base64}
                        alt=''
                        width='55px'
                        height='55px'
                      />
                    )}
                  </Show>
                }
                progress={progress}
                isInProgress={isCompressionInProgress}
                fileDescription={fileDescription}
                error={error}
              />
            </Grid>
          </Show>
        </Grid>
      </ConfirmationModal>
    </>
  )
}
