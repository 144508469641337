import { Box, Grid } from '@mui/material'
import { FC, PropsWithChildren, ReactNode, useMemo } from 'react'
import { useDeviceInfo } from '../../utils/hooks/useDeviceInfo'
import { For, Show } from '../JSXExtensions'
import {
  desktopSizes,
  oneColumn,
  tabletSizes,
  threeColumns,
  twoColumns
} from './constants'

type ItemListProps<T> = {
  dataList: Partial<T>[]
  itemProps?: Partial<T>
  ItemComponent: FC<T>
  EmptyStateComponent?: ReactNode
  columns?: {
    xs?: number
    sm?: number
    md?: number
    lg?: number
    xl?: number
  }
}

const EmptyStateContainer: FC<PropsWithChildren> = ({ children }) => {
  const { isTablet } = useDeviceInfo()
  const sizes = useMemo(
    () => (isTablet ? tabletSizes : desktopSizes),
    [isTablet]
  )

  return (
    <Grid
      container
      justifyContent='center'
      alignContent='center'
      marginTop={8}
      minHeight={sizes.container}
      overflow='hidden'
    >
      <Box
        sx={{
          height: sizes.height,
          width: sizes.width
        }}
      >
        {children}
      </Box>
    </Grid>
  )
}

const defaultColumns = {
  xs: oneColumn,
  md: twoColumns,
  lg: threeColumns
}

export const ItemList = <T extends { id: string }>({
  dataList,
  ItemComponent,
  itemProps = {},
  EmptyStateComponent,
  columns = {}
}: ItemListProps<T>) => {
  const computedColumns = useMemo(
    () => ({ ...defaultColumns, ...columns }),
    [columns]
  )

  const fallbackComponent = useMemo(
    () =>
      EmptyStateComponent ? (
        <EmptyStateContainer>{EmptyStateComponent}</EmptyStateContainer>
      ) : null,
    [EmptyStateComponent]
  )

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Show when={dataList.length} fallback={fallbackComponent}>
          <Grid container spacing={1}>
            <For of={dataList}>
              {(partialProps) => {
                const props = { ...itemProps, ...partialProps } as T

                return (
                  <Grid item key={props.id} {...computedColumns}>
                    <ItemComponent {...props} />
                  </Grid>
                )
              }}
            </For>
          </Grid>
        </Show>
      </Grid>
    </Grid>
  )
}
