import { FC } from 'react'
import { FieldValidator } from 'formik'
import { MaskedInput } from '../MaskedInput'
import { phoneNumberMaskPattern } from '../../constants/regexp'

export interface PhoneInputFieldNewProps {
  name: string
  label: string
  placeholder?: string
  disabled?: boolean
  validate?: FieldValidator
  required?: boolean | ((name: string) => boolean)
}

export const PhoneInputFieldNew: FC<PhoneInputFieldNewProps> = (props) => {
  return <MaskedInput mask={phoneNumberMaskPattern} {...props} />
}
