import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../../routes'
import {
  actionsDealsPage,
  selectDealsPageState
} from '../../../store/pages/Deals'
import { requestTabNavigation } from '../../../store/thunks/pageNavigation'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'

export interface IDealsLocationParams {
  tabNumber: number
  initialSellingView: boolean
}

export const useDealsPageSlice = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { isSellingView, activeBuyingTab, activeSellingTab } =
    useAppSelector(selectDealsPageState)
  const setSellingView = useCallback(
    (sellingView: boolean) => {
      dispatch(actionsDealsPage.setSellingView(sellingView))
    },
    [dispatch, actionsDealsPage.setSellingView]
  )

  const setActiveBuyingTab = useCallback(
    (tab: number) => {
      dispatch(actionsDealsPage.setActiveBuyingTab(tab))
    },
    [dispatch, actionsDealsPage.setActiveBuyingTab]
  )

  const setActiveSellingTab = useCallback(
    (tab: number) => {
      dispatch(actionsDealsPage.setActiveSellingTab(tab))
    },
    [dispatch, actionsDealsPage.setActiveSellingTab]
  )
  const resetActiveTabs = useCallback(() => {
    dispatch(actionsDealsPage.resetActiveTabs())
  }, [dispatch, actionsDealsPage.resetActiveTabs])

  const resetActiveTabFilters = useCallback(() => {
    dispatch(requestTabNavigation())
  }, [dispatch, requestTabNavigation])

  const returnToDealsPage = useCallback(
    ({ initialSellingView, tabNumber }: IDealsLocationParams) => {
      navigate(routes.deals.index, {
        state: { initialSellingView, tabNumber }
      })
    },
    [navigate]
  )
  const dealPageTabSwitcher = ({
    initialSellingView,
    tabNumber
  }: Required<IDealsLocationParams>) => {
    setSellingView(initialSellingView)
    if (isSellingView) setActiveSellingTab(tabNumber)
    else setActiveBuyingTab(tabNumber)
  }
  return {
    isSellingView,
    activeBuyingTab,
    activeSellingTab,
    setSellingView,
    setActiveBuyingTab,
    setActiveSellingTab,
    resetActiveTabs,
    resetActiveTabFilters,
    returnToDealsPage,
    dealPageTabSwitcher
  }
}
