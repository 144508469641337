import { DefaultVehicle } from '../../../../assets/imgs/DefaultVehicle'
import { EmptyChat } from '../../../../assets/imgs/EmptyChat'
import { EmptyRequest } from '../../../../assets/imgs/EmptyRequest'
import { MainCarImg } from '../../../../assets/imgs/MainCarImg'
import { NoDocuments } from '../../../../assets/imgs/NoDocuments'
import { NoInternet } from '../../../../assets/imgs/NoInternet'
import { NoMatchesImage } from '../../../../assets/imgs/NoMatches/intex'
import { NoVehiclesImage } from '../../../../assets/imgs/NoVehicles'
import { OnboardingStepFourImg } from '../../../../assets/imgs/OnboardingStepFourImg'
import { OnboardingStepOneImg } from '../../../../assets/imgs/OnboardingStepOneImg'
import { OnboardingStepThreeImg } from '../../../../assets/imgs/OnboardingStepThreeImg'
import { OnboardingStepTwoImg } from '../../../../assets/imgs/OnboardingStepTwoImg'

export const IMAGES_MAP: Record<string, JSX.Element> = {
  DefaultVehicle: <DefaultVehicle />,
  EmptyRequest: <EmptyRequest />,
  MainCarImg: <MainCarImg />,
  NoDocuments: <NoDocuments />,
  NoInternet: <NoInternet />,
  NoMatchesImage: <NoMatchesImage />,
  NoVehiclesImage: <NoVehiclesImage />,
  OnboardingStepFourImg: <OnboardingStepFourImg />,
  OnboardingStepOneImg: <OnboardingStepOneImg />,
  OnboardingStepThreeImg: <OnboardingStepThreeImg />,
  OnboardingStepTwoImg: <OnboardingStepTwoImg />,
  EmptyChat: <EmptyChat />
}
