export enum FIELDS_NAMES {
  NAME = 'nickname',
  STREET = 'street',
  ZIP_CODE = 'zipCode',
  CITY = 'city',
  STATE = 'state'
}

export enum FIELDS_LABELS {
  NAME = 'Address name',
  STREET = 'Street',
  ZIP_CODE = 'Zip code',
  CITY = 'City',
  STATE = 'State'
}
export interface AddressFieldsTypes {
  [FIELDS_NAMES.NAME]: string
  [FIELDS_NAMES.STREET]: string
  [FIELDS_NAMES.ZIP_CODE]: string
  [FIELDS_NAMES.CITY]: string
  [FIELDS_NAMES.STATE]: string
}

export enum FIELDS_ERRORS {
  NAME = 'Enter a valid Address name.',
  STREET = 'Enter a valid Street.',
  ZIP_CODE = 'Enter a valid Zip Code.',
  CHECK_ADDRESS = 'We have not found your address. Check the details.'
}
