import {
  Avatar,
  AvatarGroup,
  Typography
} from '@mui/material'
import { FC } from 'react'
import { UnknownUserIcon } from '../../assets/icons/UnknownUser'
import type { UserInfoState } from '../../store/types'
import { COLORS, SHADOWS } from '../../types/colors'
import { Tooltip } from '../Tooltip'

type AvatarsListProps = {
  members: UserInfoState['users']
}

export const AvatarsList: FC<AvatarsListProps> = ({ members }) => {
  const avatars = members.map((user) => (
    <Tooltip
      key={user.id}
      title={
        <Typography variant='body1'>
          {user.fullName || 'Pending User'} <br /> {user.id}
        </Typography>
      }
      sx={{
        textAlign: 'center'
      }}
    >
      <Avatar
        alt={user.fullName}
        key={user.id}
        sx={{
          width: '36px',
          height: '36px'
        }}
        src={user.avatar}
      >
        <UnknownUserIcon />
      </Avatar>
    </Tooltip>
  ))

  return (
    <AvatarGroup
      max={5}
      total={members.length}
      variant='circular'
      componentsProps={{
        additionalAvatar: {
          sx: {
            backgroundColor: COLORS.text_blue,
            fontSize: '0.7rem',
            width: '36px',
            height: '36px',
            boxShadow: SHADOWS.avatarGroup,
            fontWeight: 500,
            color: COLORS.text_light_gray
          }
        }
      }}
      sx={{
        '& .MuiAvatar-root': {
          border: 'none',
          boxShadow: SHADOWS.avatar
        }
      }}
    >
      {avatars}
    </AvatarGroup>
  )
}
