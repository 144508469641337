import { ResponseStatusMap } from '../../../types/status'
import { asyncDebounce } from '../../../utils'
import {
  RequestObservable,
  useApiContext,
  useMakeService
} from '../../../utils/hooks'

export const useRequests = (): {
  getVehicleTax: (data: {
    sellerZipCode: string
    totalAmount: string
  }) => ReturnType<typeof apiContext.externalCarSnoopApi.offers.readSalesTax>
  getVehicleTaxObservable: RequestObservable<ReturnType<typeof useMakeService>>
} => {
  const apiContext = useApiContext()
  const [getVehicleTax, getVehicleTaxObservable] = useMakeService(
    async ({
      sellerZipCode,
      totalAmount
    }: {
      sellerZipCode: string
      totalAmount: string
    }) => {
      const response = await apiContext.externalCarSnoopApi.offers.readSalesTax(
        sellerZipCode,
        totalAmount
      )

      if (response.status === ResponseStatusMap.Success) {
        return {
          status: response.status,
          tax: response.tax
        }
      }

      return response
    }
  )

  return {
    getVehicleTax: asyncDebounce<
      Awaited<
        ReturnType<typeof apiContext.externalCarSnoopApi.offers.readSalesTax>
      >
    >(getVehicleTax, 500),
    getVehicleTaxObservable
  }
}
