import {
  FC,
  useState,
  useCallback,
  SyntheticEvent,
  useLayoutEffect,
  useMemo
} from 'react'
import { Grid, Typography } from '@mui/material'
import { VehiclesListContainer } from '../../atoms/VehiclesListContainer'
import { useApiContext, useMakeService, useUserInfo } from '../../utils/hooks'
import { ResponseStatusMap } from '../../types/status'
import { PageContainer } from '../../layout/PageContainer'
import { VehiclesList } from '../../atoms/VehiclesList'
import { TabContainer } from '../../atoms/TabContainer'
import type { Address } from '../../store/types'
import { EmptyState } from '../Inventory/EmptyState'
import { useGaragePageSlice } from './useGaragePageSlice'
import { NormalizedReadInventoryItem } from '../../api/carSnoopApi/accounts/types'

const AddressDetails: FC<{ address: Address }> = ({ address }) => {
  return (
    <Grid container>
      <Grid item>
        <Typography variant='main'>Address:</Typography>
      </Grid>
      <Grid item ml={2} container flexDirection='column' width='fit-content'>
        <Grid item>
          <Typography variant='details'>
            {address.street} {address.zipCode},
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='details'>
            {address.city}, {address.state}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export const GaragePage: FC = () => {
  const userInfo = useUserInfo()
  const { currentTabID, setCurrentTabID } = useGaragePageSlice()
  const { carSnoopApi } = useApiContext()
  const [userVehiclesList, setUserVehiclesList] = useState<
    NormalizedReadInventoryItem[]
  >([])

  const handleChangeTabID = useCallback(
    (_: SyntheticEvent, newValue: number) => {
      setCurrentTabID(newValue)
    },
    [setCurrentTabID]
  )

  const [requestInventoryService, inventoryObservable] = useMakeService(
    async () => {
      const response = await carSnoopApi.accounts.readInventory({
        accountID: userInfo.accountID
      })

      if (response.status === ResponseStatusMap.Success) {
        setUserVehiclesList(response.inventory)
      }

      return response
    }
  )

  useLayoutEffect(() => {
    if (userInfo.accountID) {
      requestInventoryService()
    }
  }, [userInfo.accountID])

  const { tabNames, tabContents } = useMemo(() => {
    const tabTitles = userInfo.addresses.map(({ nickname }) => nickname)

    const tabData = userInfo.addresses.map((address) => {
      const vehiclesSceneList = userVehiclesList.filter((vehicle) => {
        return vehicle.addressID === address.id
      })

      return (
        <VehiclesList
          key={`${address.nickname}-tab-content`}
          before={<AddressDetails address={address} />}
          vehiclesList={vehiclesSceneList}
          noVehiclesComponent={<EmptyState />}
        />
      )
    })

    return { tabNames: tabTitles, tabContents: tabData }
  }, [userInfo.addresses, userVehiclesList])

  return (
    <PageContainer isLoading={inventoryObservable.isLoading}>
      <VehiclesListContainer vehiclesListCount={userVehiclesList.length}>
        <Grid container m={{ md: 1 }}>
          <TabContainer
            tabNames={tabNames}
            tabContents={tabContents}
            currentTabID={currentTabID}
            onChange={handleChangeTabID}
          />
        </Grid>
      </VehiclesListContainer>
    </PageContainer>
  )
}
