import { Divider, Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { AddShipmentDetailsParamsAddress } from '../../../../api/carSnoopApi/shipments/types'
import { EditIcon } from '../../../../assets/icons/Edit'
import { Show } from '../../../../atoms/JSXExtensions'
import { COLORS } from '../../../../types/colors'
import { useModal, useUserInfo } from '../../../../utils/hooks'
import { AddressEditModal } from './AddressEditForm/AddressEditModal'
import { AddressMark } from './AddressMark'

interface IAddressHeader {
  address: AddShipmentDetailsParamsAddress
  onEditClick?: (
    address: AddShipmentDetailsParamsAddress
  ) => void | Promise<void>
  headerTitle: string
  isDropOff?: boolean
  isDelivered?: boolean
}

export const AddressHeader: FC<IAddressHeader> = ({
  address,
  onEditClick,
  headerTitle,
  isDropOff = false,
  isDelivered = false
}) => {
  const editAddressModal = useModal()
  const { isAdmin } = useUserInfo()
  return (
    <Grid container alignItems='center' spacing={2} wrap='nowrap'>
      <Grid item>
        <AddressMark isOutline={isDropOff && !isDelivered} />
      </Grid>
      <Grid item>
        <Typography variant='subtitle2' textTransform='uppercase' noWrap>
          {headerTitle}
        </Typography>
      </Grid>
      <Show when={isAdmin && onEditClick}>
        {(onEditSubmit) => (
          <Grid item display='flex' flexGrow={1}>
            <AddressEditModal
              address={address}
              isDropOff={isDropOff}
              isOpen={editAddressModal.isOpen}
              setOpen={editAddressModal.setOpen}
              onSubmit={onEditSubmit}
            />
            <EditIcon onClick={editAddressModal.onOpenHandler} />
          </Grid>
        )}
      </Show>
      <Show when={!isDropOff}>
        <Grid item container>
          <Divider
            sx={{
              borderStyle: 'dashed',
              borderColor: COLORS.text_primary,
              width: '100%'
            }}
          />
        </Grid>
      </Show>
    </Grid>
  )
}
