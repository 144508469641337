import { Grid, Paper, Typography } from '@mui/material'
import { FC, PropsWithChildren } from 'react'
import { Divider } from '../../../../atoms/Divider'
import { Show } from '../../../../atoms/JSXExtensions'

export const ComponentWithPaperBackground: FC<
  { minWidth?: string; title?: string } & PropsWithChildren
> = ({ children, minWidth = '300px', title = '' }) => {
  return (
    <Grid
      container
      width='100%'
      height='auto'
      display='flex'
      justifyContent='center'
    >
      <Paper elevation={3} sx={{ width: '100%', padding: '8px', minWidth }}>
        <Grid container spacing={3} p={1} flexDirection='column'>
          <Show when={title}>
            <Grid item container>
              <Grid item mb='8px'>
                <Typography variant='label1'>{title}</Typography>
              </Grid>
              <Divider />
            </Grid>
          </Show>
          <Grid item container justifyContent='center'>
            {children}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}
