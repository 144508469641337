interface ShowProps<T> {
  when: T | undefined | null | false
  fallback?: JSX.Element | null
  children: JSX.Element | ((item: T) => JSX.Element)
}

export const Show = <T,>({ children, fallback = null, when }: ShowProps<T>) => {
  if (when) {
    if (typeof children === 'function') {
      return children(when)
    }

    return children
  }

  return fallback
}
