import { Grid } from '@mui/material'
import { FC, useCallback } from 'react'
import { AgreeButton, CancelButton } from '../../../atoms/Button'
import { COLORS } from '../../../types/colors'
import { useCheckSubscription } from '../../Subscription/hooks/useCheckSubscription'
import {
    BuyerActionsType,
    OfferDetailsWidgetProps,
    SellerActionsType
} from '../types'

type OfferActionsProps = Pick<OfferDetailsWidgetProps, 'actions'>

const SellerActionsComponent: FC<SellerActionsType> = ({ onCancelClick }) => {
  return (
    <Grid container flexDirection='row-reverse'>
      <Grid item>
        <CancelButton onClick={onCancelClick} sx={{ width: 220 }}>
          Cancel offer
        </CancelButton>
      </Grid>
    </Grid>
  )
}

const BuyerActionsComponent = ({
  onAcceptClick,
  onDeclineClick
}: BuyerActionsType) => {
  const { checkSubscriptionServiceObservable, checkSubscriptionService } =
    useCheckSubscription()

  const handleAcceptClick = useCallback(async () => {
    const subscriptionCheckingResult = await checkSubscriptionService()
    if (subscriptionCheckingResult.ok) {
      onAcceptClick()
    }
  }, [onAcceptClick, checkSubscriptionService])

  return (
    <Grid container justifyContent='space-between'>
      <Grid item>
        <CancelButton
          onClick={onDeclineClick}
          sx={{ width: 160, borderColor: COLORS.text_red }}
          disabled={checkSubscriptionServiceObservable.isLoading}
        >
          Decline
        </CancelButton>
      </Grid>
      <Grid item>
        <AgreeButton
          onClick={handleAcceptClick}
          sx={{ width: 220 }}
          disabled={checkSubscriptionServiceObservable.isLoading}
        >
          Accept offer
        </AgreeButton>
      </Grid>
    </Grid>
  )
}

export const OfferActions = ({ actions }: OfferActionsProps) => {
  if ('onCancelClick' in actions) {
    return <SellerActionsComponent {...actions} />
  }

  return <BuyerActionsComponent {...actions} />
}
