import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Typography } from '@mui/material'
import { FC, useEffect, useRef } from 'react'

import { COLORS } from '../../types/colors'

import { noop } from '../../utils/noop'
import { CounterContainer, IconContainer } from './Counter.styles'

interface ICounterProps {
  onAddClick: () => void
  onSubClick: () => void
  countValue?: number
  minValue?: number
  name: string
}

export const Counter: FC<ICounterProps> = ({
  onAddClick = noop,
  onSubClick = noop,
  countValue = 0,
  minValue = 1,
  name
}) => {
  const isSubDisabled = countValue <= minValue
  const counterRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    (counterRef.current as HTMLInputElement).value = countValue.toString()
  }, [countValue])

  return (
    <CounterContainer>
      <input type='hidden' ref={counterRef} value={countValue} name={name} />
      <IconContainer
        isDisabled={isSubDisabled}
        {...(!isSubDisabled && { onClick: onSubClick })}
      >
        <RemoveIcon
          sx={{
            color: isSubDisabled
              ? COLORS.text_secondary
              : COLORS.text_blue
          }}
        />
      </IconContainer>
      <Typography textAlign='center' minWidth='32px' variant='h6'>
        {countValue}
      </Typography>
      <IconContainer onClick={onAddClick}>
        <AddIcon sx={{ color: COLORS.text_blue }} />
      </IconContainer>
    </CounterContainer>
  )
}
