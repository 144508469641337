import type { Address } from '../../../store/types'

export const EMPTY_ADDRESS: Address = {
  id: '',
  nickname: '',
  street: '',
  city: '',
  state: '',
  zipCode: ''
}

export enum MODES {
  VIEW = 'view',
  CREATE = 'create',
  EDIT = 'edit'
}

export type ModeType = `${MODES}`
