import { Grid } from '@mui/material'
import { styled } from '@mui/system'
import { BGCOLORS, COLORS } from '../../../../types/colors'

export const VehicleDetailsMainInfoGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: BGCOLORS.white,
  borderRadius: 10,

  '& > div:not(:last-of-type)': {
    borderBottomColor: COLORS.border_gray,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}))
