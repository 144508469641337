import { useState, useRef } from 'react'
import { FormikProps } from 'formik'
import { OrNull } from '../../../types/OrNull'
import {
  useApiContext,
  useMakeService,
  useUserInfo
} from '../../../utils/hooks'
import { ResponseStatusMap } from '../../../types/status'
import { SearchComponentProps } from '../../../features/SearchInventory'
import { VehicleStatusTypes } from '../../../api/carSnoopApi/statusTypes'
import { NormalizedReadInventoryItem } from '../../../api/carSnoopApi/accounts/types'

export interface VehicleRequest {
  id: string
  make: string
  model: string
  years: string[]
  interiorColors: string[]
  exteriorColors: string[]
  expirationDate: Date
  tradeIn: OrNull<{
    make: string
    model: string
    year: string
    trim: string
  }>
}

export interface RequestedVehicleDetails {
  id: string
  status: VehicleStatusTypes
  make: string
  model: string
  year: number
  trim: string
  stockNumber: string
  mileage: number
  retailValue: number
  imageSource?: string
}

export interface RequestedVehicle extends RequestedVehicleDetails {
  requests: number
  documentContent: string
}

export const useRequestedVehicles = () => {
  const searchRef =
    useRef<FormikProps<SearchComponentProps['initialValues']>>(null)
  const [userVehiclesList, setUserVehiclesList] =
    useState<OrNull<NormalizedReadInventoryItem[]>>(null)
  const [searchFormValues, setSearchFormValues] = useState<
    SearchComponentProps['initialValues']
  >({
    search: ''
  })

  const { carSnoopApi } = useApiContext()
  const userInfo = useUserInfo()

  const [readInventory, readInventoryObservable] = useMakeService(
    async (values: SearchComponentProps['initialValues']) => {
      setUserVehiclesList(null)
      const response = await carSnoopApi.accounts.readInventory({
        accountID: userInfo.accountID,
        status: 'Requested',
        ordering: true,
        stockNumber: values.search
      })

      if (response.status === ResponseStatusMap.Success) {
        setUserVehiclesList(
          response.inventory.filter((item) => item.requests > 0)
        )
      }

      return response
    }
  )

  const onSearchApply = (values: SearchComponentProps['initialValues']) => {
    if (values !== searchFormValues) {
      setSearchFormValues(values)

      readInventory(values)
    }
  }

  const resetSearchValues = () => {
    setSearchFormValues({ search: '' })
  }

  return {
    readInventory,
    requestedVehicles: userVehiclesList,
    isLoading: readInventoryObservable.isLoading,
    requestedSearch: {
      formRef: searchRef,
      onSearchApply,
      initialValues: searchFormValues
    },
    resetSearchValues
  }
}
