import { FC } from 'react'
import { OnboardingStepOneImg } from '../../assets/imgs/OnboardingStepOneImg'
import { PromotionBlock } from '../../atoms/PromotionBlock'
import { RegistrationByEmailForm } from '../../forms/RegistrationByEmail'
import { ServicePageContainer } from '../../layout/ServicePageContainer'
import { OnboardText } from '../../types/enums/OnboardText'
import { RegistrationPageContainer } from './RegistrationByEmail.styles'

export const RegistrationByEmailPage: FC = () => {
  return (
    <ServicePageContainer>
      <RegistrationPageContainer
        flexGrow={{
          xs: 1,
          md: 0
        }}
      >
        <PromotionBlock
          title={OnboardText.title}
          subtitle={OnboardText.subtitle}
          description={OnboardText.stepOneDesc}
          image={<OnboardingStepOneImg />}
        />
        <RegistrationByEmailForm />
      </RegistrationPageContainer>
    </ServicePageContainer>
  )
}
