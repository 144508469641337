import { styled } from '@mui/system'
import { COLORS } from '../../../types/colors'

export const ConditionsHintsContainer = styled('div')`
  text-align: center;
`

export const RegistrationButtonContainer = styled('div')`
  margin: 24px 0;

  & a {
    margin-left: 5px;
    font-weight: 500;
    color: ${COLORS.text_blue};
  }

  & a:hover {
    text-decoration: underline;
  }
`

export const TermsContainer = styled('div')`
  padding: 0 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${COLORS.text_secondary};

  & a {
    color: ${COLORS.text_blue};
  }

  & a:hover {
    text-decoration: underline;
  }
`
