import { useCallback, useEffect } from 'react'
import {
  useApiContext,
  useAppDispatch,
  useAppSelector,
  useMakeService,
  useUserInfo
} from '../../../utils/hooks'
import { chatActions } from '../model/actions'
import { ChatProps } from '../types'
import { ResponseStatusMap } from '../../../types/status'
import { formatReadChatMessages } from '../../../api/chat/types/readChatMessages'

export const useChatReader = ({
  chatId,
  onMessagesRead,
  messagesSelector
}: ChatProps) => {
  const dispatch = useAppDispatch()
  const messages = useAppSelector(messagesSelector)
  const { chatApi } = useApiContext()
  const { id: userId } = useUserInfo()

  const readChatMessagesService = useCallback(async () => {
    const response = await chatApi.readChatMessages({
      user: userId,
      transactionId: chatId
    })

    if (response.status === ResponseStatusMap.Success) {
      const { payload } = response
      const formattedPayload = formatReadChatMessages(payload, userId)

      onMessagesRead(formattedPayload)

      return response
    }

    return response
  }, [userId, chatId])

  const [readChatMessages] = useMakeService(
    readChatMessagesService,
    // Discussable
    { includeCheckAccess: true, withStatusNotification: [1, 0] }
  )

  const requestMessages = useCallback(async () => {
    await readChatMessages()
  }, [dispatch])

  useEffect(() => {
    dispatch(chatActions.setChatId(chatId))
    requestMessages()
  }, [])

  return {
    messages,
    requestMessages
  }
}
