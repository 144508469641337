import { Grid } from '@mui/material'
import { FC, useCallback } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { MainCarImg } from '../../../assets/imgs/MainCarImg'
import { ActionBlock } from '../../../atoms/ActionBlock'
import { PageContainer } from '../../../layout/PageContainer'
import { ResultScreen } from '../../../molecules/ResultScreen'
import { routes } from '../../../routes'
import { useDealsPageSlice } from '../../../routes/Deals/hooks/useDealsPageSlice'
import { DealsActiveTabEnum } from '../../../store/types'

export const CreateOfferSuccessScreen: FC = () => {
  const navigate = useNavigate()
  const { transactionId = '' } = useParams()
  const { setSellingView, setActiveSellingTab } = useDealsPageSlice()

  const backToOffersHandler = useCallback(() => {
    setSellingView(true)
    setActiveSellingTab(DealsActiveTabEnum['In Progress'])
    navigate(routes.deals.index)
  }, [setSellingView, setActiveSellingTab, navigate])

  const goToChatHandler = useCallback(() => {
    const path = generatePath(routes.offers.details, { transactionId })
    navigate(`${path}?chat=true`)
  }, [transactionId])

  return (
    <PageContainer>
      <ResultScreen
        title='You have created the Offer!'
        icon={<MainCarImg />}
        description='Now you can chat with your buyer!'
        inlined
      >
        <Grid item container justifyContent='center'>
          <Grid item width={600} pt={12}>
            <ActionBlock
              schema={[
                {
                  label: 'Back to offers',
                  type: 'secondary',
                  onClick: backToOffersHandler
                },
                {
                  label: 'Go to chat',
                  type: 'primary',
                  onClick: goToChatHandler
                }
              ]}
            />
          </Grid>
        </Grid>
      </ResultScreen>
    </PageContainer>
  )
}
