import { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import { ProgressBar } from '../../../../atoms/ProgressBar'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const ProgressExample = () => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0
        }
        const diff = Math.random() * 10
        return Math.round(Math.min(oldProgress + diff, 100))
      })
    }, 500)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <ComponentContainer fullWidth>
      <ComponentWithPaperBackground>
        <Grid container justifyContent='center'>
          <Grid item xs={6}>
            <ProgressBar progress={progress} label='Progress bar label' />
          </Grid>
        </Grid>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
