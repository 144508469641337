import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface SubscriptionAutoupdateState {
  isAutoupdating: boolean
}

export const initialState: SubscriptionAutoupdateState = {
  isAutoupdating: false
}

export const subscriptionAutoupdateSlice = createSlice({
  name: 'units/subscriptionAutoupdate',
  initialState,
  reducers: {
    setIsAutoupdating: (state, action: PayloadAction<boolean>) => {
      state.isAutoupdating = action.payload
    }
  }
})
