import { FC } from 'react'
import AccordionDetails from '@mui/material/AccordionDetails'
import { ItemList } from '../../../../../../atoms/ItemList'
import { DealVehicleItem } from '../DealVehicleItem'
import { ReadRequestsResponse } from '../../../../../../api/carSnoopApi/requests/types'

interface RequestBodyProps {
  deals: ReadRequestsResponse.Normilized.ReadRequestsResponse['transactions']
}

export const RequestBody: FC<RequestBodyProps> = ({ deals }) => {
  return (
    <AccordionDetails sx={{ py: 3 }}>
      <ItemList dataList={deals} ItemComponent={DealVehicleItem} />
    </AccordionDetails>
  )
}
