export const applyMask = (value: string, mask: string): string => {
  const valueArray = value.split('')

  return mask.split('').reduceRight((res, char) => {
    if (char === '#') {
      return valueArray.pop() + res
    }

    return char + res
  }, '')
}
