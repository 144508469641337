export enum ContractViewStates {
  UNDER_CONTRACT = 'underContract',
  BUYER_STRIPE_AWAITING_PAYMENT = 'buyerStripeAwaitingPayment',
  SELLER_STRIPE_AWAITING_PAYMENT = 'sellerStripeAwaitingPayment',
  BUYER_NON_STRIPE_AWAITING_PAYMENT = 'buyerNonStripeAwaitingPayment',
  SELLER_NON_STRIPE_AWAITING_PAYMENT = 'sellerNonStripeAwaitingPayment',
  BUYER_STRIPE_SHIPPING = 'buyerStripeShipping',
  SELLER_STRIPE_SHIPPING = 'sellerStripeShipping',
  BUYER_NON_STRIPE_SHIPPING = 'buyerNonStripeShipping',
  SELLER_NON_STRIPE_SHIPPING = 'sellerNonStripeShipping',
  PAYMENT_FAILED = 'paymentFailed',
  BUYER_COMPLETED = 'buyerCompleted',
  SELLER_COMPLETED = 'sellerCompleted',
  UNKNOWN = 'unknown'
}
