import { FC } from 'react'
import './Loader.css'

interface LoaderProps {
  small?: boolean
}
export const Loader: FC<LoaderProps> = ({ small }) => {
  const classNames = `loader ${small ? 'small' : ''}`

  return (
    <div className={classNames}>
      <div className='loader__items'>
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  )
}
