import { useRef, useState, useCallback } from 'react'
import { SubscriptionFormRefTargetType } from '../microform/SubscriptionMicroform'
import { OrNull } from '../../../types/OrNull'

export const useSubscriptionForm = () => {
  const subscriptionRef = useRef<OrNull<SubscriptionFormRefTargetType>>(null)
  const [isSubscriptionFormValid, setSubscriptionFormValid] = useState(false)

  const setSubscriptionMicroformRef = useCallback(
    (node: SubscriptionFormRefTargetType) => {
      subscriptionRef.current = node
      if (subscriptionRef.current) {
        setSubscriptionFormValid(subscriptionRef.current.isValid)
      }
    },
    [setSubscriptionFormValid]
  )

  return {
    subscriptionRef,
    isSubscriptionFormValid,
    setSubscriptionMicroformRef
  }
}
