import { FC, Suspense, lazy } from 'react'

const LazyDocument = lazy(() => import('./Document'))
const LazyFileDocument = lazy(() => import('./FileDocument'))

export const Document: FC<Parameters<typeof LazyDocument>[0]> = (props) => {
  return (
    <Suspense>
      <LazyDocument {...props} />
    </Suspense>
  )
}

export const FileDocument: FC<Parameters<typeof LazyFileDocument>[0]> = (
  props
) => {
  return (
    <Suspense>
      <LazyFileDocument {...props} />
    </Suspense>
  )
}
