import { Grid, Typography } from '@mui/material'
import { Formik, FormikValues } from 'formik'
import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { PasswordInputField } from '../../../atoms/PasswordInputField'
import { RequiredLabel } from '../../../atoms/RequiredText'
import { validationSchema } from '../../../forms/screens/CreatePassword/validationSchema'
import { FormContainer } from '../../../layout/FormContainer'
import { ScreenContainer } from '../../../layout/ScreenContainer'
import { routes } from '../../../routes'
import { formatEmail } from '../../../utils/formatters/formatEmail'
import { isValidForm } from '../../../utils/isValidForm'
import {
  ResetPasswordConstants,
  resetPasswordInitialValues
} from '../constants'
import {
  ActionBlockStyles,
  ResetPasswordForm
} from './ResetPasswordContainer.styles'

type ResetPasswordContainerProps = {
  isLoading?: boolean
  id: string
  token: string
  onSubmit: (props: {
    password: string
    id: string
    token: string
  }) => Promise<unknown>
}

export const ResetPasswordContainer: FC<ResetPasswordContainerProps> = ({
  id,
  token,
  isLoading,
  onSubmit
}) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (!token || !id) {
      navigate(routes.login)
    }
  }, [])

  const onSubmitClicked = async ({ password }: FormikValues) => {
    if (!id || !token) {
      return
    }

    onSubmit({ id, token, password })
  }

  return (
    <FormContainer
      title={ResetPasswordConstants.title}
      minHeight={{ md: '520px' }}
    >
      <ScreenContainer isLoading={isLoading}>
        <Grid>
          <Typography variant='body1'>For {formatEmail(id)}</Typography>
        </Grid>
        <Grid>
          <RequiredLabel />
        </Grid>
        <Formik
          initialValues={resetPasswordInitialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmitClicked}
        >
          {(formik) => {
            const submitButton = {
              label: 'confirm new password',
              type: 'primary',
              size: 'large',
              disabled: !isValidForm(formik, {
                requiredFields: ['password', 'confirmPassword']
              }),
              props: {
                type: 'submit'
              }
            } as const

            const cancelButton = {
              label: 'Cancel',
              type: 'secondary',
              onClick: () => navigate(routes.login)
            } as const

            return (
              <ResetPasswordForm>
                <PasswordInputField
                  name='password'
                  label='New Password'
                  placeholder='New Password'
                  isValid={
                    !(formik.touched?.password && formik.errors.password)
                  }
                />
                <PasswordInputField
                  name='confirmPassword'
                  label='Confirm New Password'
                  placeholder='Confirm New Password'
                  isValid={
                    !(
                      formik.touched?.confirmPassword &&
                      formik.errors.confirmPassword
                    )
                  }
                />
                <Typography variant='subtitle2' marginY={1}>
                  {ResetPasswordConstants.description}
                </Typography>
                <ActionBlockStyles
                  schema={[cancelButton, submitButton]}
                />
              </ResetPasswordForm>
            )
          }}
        </Formik>
      </ScreenContainer>
    </FormContainer>
  )
}
