import { Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { NoDocuments } from '../../../../../assets/imgs/NoDocuments'

export const EmptyStandardEquipment: FC = () => {
  return (
    <Grid
      container
      justifyContent='center'
      flexDirection='column'
      rowSpacing={2}
    >
      <Grid item textAlign='center'>
        <NoDocuments />
      </Grid>
      <Grid item>
        <Typography variant='body1' textAlign='center'>
          There is no Standard Equipment information for this vehicle
        </Typography>
      </Grid>
    </Grid>
  )
}
