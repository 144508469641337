import { FC } from 'react'
import { Grid } from '@mui/material'
import { ShipmentInfoTypes } from '../../../../../../features/DeliveryCard'
import { OrNull } from '../../../../../../types/OrNull'
import { Show } from '../../../../../../atoms/JSXExtensions'
import { Loader } from '../../../../../../atoms/Loader'
import { DeliveryInfo } from './DeliveryInfo'
import {
  NormalizedTradeInType,
  NormalizedVehicleType
} from '../../../../../../api/carSnoopApi/transactions/types'
import { AddShipmentDetailsParamsAddress } from '../../../../../../api/carSnoopApi/shipments/types'

type DeliveryInfoContainerProps = {
  shipmentInfo: OrNull<ShipmentInfoTypes>
  vehicle: NormalizedVehicleType
  tradeIn: OrNull<NormalizedTradeInType>
  isNotAvailable?: boolean
  hasAddressError?: boolean
  setPickup?: (address: AddShipmentDetailsParamsAddress) => void
  setDropOff?: (address: AddShipmentDetailsParamsAddress) => void
}

export const DeliveryInfoContainer: FC<DeliveryInfoContainerProps> = ({
  shipmentInfo,
  ...props
}) => {
  return (
    <Show
      when={shipmentInfo}
      fallback={
        <Grid container justifyContent='center'>
          <Loader small />
        </Grid>
      }
    >
      {(shipmentDetails) => (
        <DeliveryInfo shipmentInfo={shipmentDetails} {...props} />
      )}
    </Show>
  )
}
