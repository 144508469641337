import { FC, useMemo } from 'react'
import { Grid } from '@mui/material'
import { Show, SwitchComponent } from '../../../../../atoms/JSXExtensions'
import {
  ContractViewStates,
  useContractStateDecoder
} from '../../../hooks/useContractStateDecoder'
import { BuyerStripeAwaitingPayment } from './BuyerStripeAwaitingPayment'
import { BuyerNonStripeShipment } from './BuyerNonStripeShipment'
import { PaymentDetails } from './PaymentDetails'
import { BuyerStripeShipment } from './BuyerStripeShipment'
import { OrNull } from '../../../../../types/OrNull'
import { NormalizedTransactionType } from '../../../../../api/carSnoopApi/transactions/types'
import { SellerPayment } from './SellerPayment'
import { NonStripeDetails, StripeDetails } from './SellerPayment/SellerPayment'
import { SellerShipping } from './SellerShipping'
import { DeclinePaymentReturnType } from '../../../../../api/carSnoopApi/payments/types'
import { CompleteShipmentReturnType } from '../../../../../api/carSnoopApi/shipments/types'
import { DeliveredShipment } from './DeliveredShipment'

interface PaymentsWidgetProps {
  transaction: OrNull<NormalizedTransactionType>
  declinePayment: () => DeclinePaymentReturnType
  confirmPayment: () => unknown
  completeDeal: () => CompleteShipmentReturnType
  isLoading: boolean
}

export const PaymentsWidget: FC<PaymentsWidgetProps> = ({
  transaction,
  declinePayment,
  confirmPayment,
  completeDeal,
  isLoading
}) => {
  const { contractViewState } = useContractStateDecoder(transaction)

  const filledTransaction = useMemo(() => {
    if (!transaction?.offer) {
      return null
    }

    if (!transaction?.contract) {
      return null
    }

    return {
      offer: transaction.offer,
      contract: transaction.contract,
      vehicle: transaction.vehicle,
      tradeIn: transaction.tradeIn,
      trackingNumber: transaction.trackingNumber,
      payments: transaction.payments || [],
      transactionId: transaction.id
    }
  }, [transaction])

  return (
    <Show when={filledTransaction}>
      {({
        offer,
        contract,
        vehicle,
        tradeIn,
        trackingNumber,
        payments,
        transactionId
      }) => (
        <Grid item>
          <SwitchComponent case={contractViewState}>
            {{
              [ContractViewStates.BUYER_NON_STRIPE_AWAITING_PAYMENT]: (
                <PaymentDetails
                  totalPrice={offer.totalPrice}
                  paymentMethod={contract.paymentMethod}
                  showTooltip
                />
              ),

              [ContractViewStates.SELLER_NON_STRIPE_AWAITING_PAYMENT]: (
                <SellerPayment
                  paymentDetails={{
                    totalPrice: offer.totalPrice,
                    paymentMethod:
                      contract.paymentMethod as NonStripeDetails['paymentMethod']
                  }}
                  terminateContract={declinePayment}
                  confirmPayment={confirmPayment}
                  isLoading={isLoading}
                  payments={payments}
                />
              ),

              [ContractViewStates.BUYER_NON_STRIPE_SHIPPING]: (
                <BuyerNonStripeShipment
                  completeDeal={completeDeal}
                  transactionId={offer.transactionID}
                  offer={offer}
                  vehicle={vehicle}
                  tradeIn={tradeIn}
                  contract={contract}
                  trackingNumber={trackingNumber}
                  payments={payments}
                />
              ),

              [ContractViewStates.BUYER_STRIPE_AWAITING_PAYMENT]: (
                <BuyerStripeAwaitingPayment
                  transactionId={transactionId}
                  offer={offer}
                  vehicle={vehicle}
                  tradeIn={tradeIn}
                  contract={contract}
                  payments={payments}
                />
              ),

              [ContractViewStates.SELLER_STRIPE_AWAITING_PAYMENT]: (
                <SellerPayment
                  paymentDetails={{
                    totalPrice: offer.totalPrice,
                    paymentMethod:
                      contract.paymentMethod as StripeDetails['paymentMethod']
                  }}
                  terminateContract={declinePayment}
                  confirmPayment={confirmPayment}
                  isLoading={isLoading}
                  payments={payments}
                />
              ),

              [ContractViewStates.BUYER_STRIPE_SHIPPING]: (
                <BuyerStripeShipment
                  tradeIn={tradeIn}
                  vehicle={vehicle}
                  contract={contract}
                  trackingNumber={trackingNumber}
                  transactionId={transactionId}
                  completeDeal={completeDeal}
                />
              ),

              [ContractViewStates.SELLER_STRIPE_SHIPPING]: (
                <SellerShipping
                  contract={contract}
                  vehicle={vehicle}
                  tradeIn={tradeIn}
                  trackingNumber={trackingNumber}
                  transactionId={transactionId}
                />
              ),

              [ContractViewStates.SELLER_NON_STRIPE_SHIPPING]: (
                <SellerShipping
                  contract={contract}
                  vehicle={vehicle}
                  tradeIn={tradeIn}
                  trackingNumber={trackingNumber}
                  transactionId={transactionId}
                />
              ),

              [ContractViewStates.BUYER_COMPLETED]: (
                <DeliveredShipment
                  contract={contract}
                  vehicle={vehicle}
                  tradeIn={tradeIn}
                  trackingNumber={trackingNumber}
                  transactionId={transactionId}
                />
              ),

              [ContractViewStates.SELLER_COMPLETED]: (
                <DeliveredShipment
                  contract={contract}
                  vehicle={vehicle}
                  tradeIn={tradeIn}
                  trackingNumber={trackingNumber}
                  transactionId={transactionId}
                  isSeller
                />
              ),

              [ContractViewStates.PAYMENT_FAILED]: (
                <PaymentDetails
                  totalPrice={offer.totalPrice}
                  paymentMethod={contract.paymentMethod}
                />
              )
            }}
          </SwitchComponent>
        </Grid>
      )}
    </Show>
  )
}
