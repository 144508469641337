import { FC, useMemo } from 'react'
import { Grid, SxProps, Typography } from '@mui/material'
import { BADGE_COLORS } from '../../types/colors'

interface BadgeProps {
  text: string
  background: typeof BADGE_COLORS[keyof typeof BADGE_COLORS]['background']
  color: typeof BADGE_COLORS[keyof typeof BADGE_COLORS]['color']
  border?: string
  textSx?: SxProps
  sx?: SxProps
}

export const Badge: FC<BadgeProps> = ({
  text,
  background,
  color,
  border,
  textSx = {},
  sx = {}
}) => {
  const borderStyle = useMemo(
    () => (border ? { border } : { border: `1px solid ${color}` }),
    [border, color]
  )

  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      sx={{
        color,
        background,
        borderRadius: '5px',
        minWidth: '90px',
        height: '20px',
        pl: 1,
        pr: 1,
        ...borderStyle,
        ...sx
      }}
    >
      <Typography variant='badge' sx={textSx} noWrap>
        {text}
      </Typography>
    </Grid>
  )
}
