import { FC, useMemo } from 'react'
import { Grid, Typography } from '@mui/material'
import { Show } from '../../../atoms/JSXExtensions'
import { EditIcon } from '../../../assets/icons/Edit'
import { OfferDetailsWidgetProps } from '../types'

type OfferHeaderProps = Pick<OfferDetailsWidgetProps, 'actions' | 'showActions'>

export const OfferHeader: FC<OfferHeaderProps> = ({ actions, showActions }) => {
  const onEditClick = useMemo(() => {
    if ('onEditClick' in actions) {
      return actions.onEditClick
    }
  }, [actions])

  return (
    <Grid container justifyContent='space-between'>
      <Grid item>
        <Typography variant='h6' textTransform='uppercase'>
          Offer details
        </Typography>
      </Grid>
      <Show when={showActions && onEditClick}>
        {(onClick) => (
          <Grid item>
            <EditIcon onClick={onClick} />
          </Grid>
        )}
      </Show>
    </Grid>
  )
}
