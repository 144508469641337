import { Grid, Typography } from '@mui/material'
import { FC } from 'react'

export const ListHeaderDesktop: FC = () => {
  return (
    <Grid container item xs={10.5} wrap='nowrap' alignItems='center'>
      <Grid item xs={2.5}>
        <Typography variant='subtitle2' textTransform='uppercase'>
          Make, Model, Trim
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant='subtitle2' textTransform='uppercase'>
          Year
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant='subtitle2' textTransform='uppercase'>
          Exterior/Interior Color
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant='subtitle2' textTransform='uppercase'>
          Trade In
        </Typography>
      </Grid>
      <Grid item xs={2.5}>
        <Typography variant='subtitle2' textTransform='uppercase'>
          Expires On
        </Typography>
      </Grid>
    </Grid>
  )
}
