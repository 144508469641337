import { BORDERS } from '../../types/colors'

export const containerStyle = {
  height: '100%'
}

export const headerStyle = {
  p: 4,
  pb: 3,
  borderBottom: BORDERS.SM_GRAY
}

export const closeButtonStyle = { minWidth: 0, padding: 0 }

export const contentContainerStyle = { flexGrow: 1 }
