import { Grid, Typography } from '@mui/material'
import { FC } from 'react'

import { CarIcon } from '../../assets/icons/CarIcon'
import { COLORS } from '../../types/colors'

export interface CarInfoItemProps {
  title: string
  info: string
}

export const CarInfoItem: FC<CarInfoItemProps> = ({ info, title }) => {
  return (
    <Grid spacing={2} container flexDirection='row' alignItems='center'>
      <Grid item>
        <CarIcon fill={COLORS.text_primary} />
      </Grid>
      <Grid item>
        <Grid container flexDirection='column'>
          <Grid item>
            <Typography variant='subtitle2' textTransform='uppercase'>
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='label1'>{info}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
