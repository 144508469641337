import { Grid, Typography } from '@mui/material'
import { FC, useCallback, useEffect, useState } from 'react'
import {
  actionsFindCarForm,
  selectFindCarFormYearFromOptions,
  selectSelectedVehiclesData
} from '../../../store/units/findCarForm'
import { DisplayVehiclesList } from './DisplayVehiclesList'

import { SearchInventoryResponseItem } from '../../../api/carSnoopApi/vehicles'
import { Show } from '../../../atoms/JSXExtensions'
import { Loader } from '../../../atoms/Loader'
import { getOptionsBetween } from '../../../store/units/findCarForm/utils/getOptionsBetween'
import { NonNullableRecord } from '../../../types/NonNullableRecord'
import { FIND_CAR_FIELD_NAMES } from '../../../types/enums/findCar'
import { BasicInfo } from '../../../types/forms/findCar'
import { ResponseStatusMap } from '../../../types/status'
import { firstOf, lastOf } from '../../../utils/array'
import {
  useApiContext,
  useAppDispatch,
  useAppSelector,
  useMakeService,
  useUserInfo
} from '../../../utils/hooks'
import { FindCarActionBlock } from './FindCarActionBlock'

interface FindCarSelectScreenProps {
  onCancel: () => void
  initialValues: {
    selectedVehicles: SearchInventoryResponseItem[]
  }
  dependentData: NonNullableRecord<BasicInfo>
  actions: typeof actionsFindCarForm
}

export const FindCarSelectScreen: FC<FindCarSelectScreenProps> = ({
  onCancel,
  initialValues,
  dependentData,
  actions
}) => {
  const dispatch = useAppDispatch()
  const yearsOptions = useAppSelector(selectFindCarFormYearFromOptions)
  const { carSnoopApi } = useApiContext()
  const { isDealer } = useUserInfo()

  const { vehiclesList } = useAppSelector(selectSelectedVehiclesData)

  const [searchVehiclesService, searchVehiclesObserver] = useMakeService(
    async () => {
      const from =
        dependentData[FIND_CAR_FIELD_NAMES.YEAR_FROM] || firstOf(yearsOptions)
      const to =
        dependentData[FIND_CAR_FIELD_NAMES.YEAR_TO] || lastOf(yearsOptions)

      const years = getOptionsBetween(
        from,
        to,
        yearsOptions,
        (o, v) => +o.id >= +v.id,
        (o, v) => +o.id <= +v.id
      ).map((option) => option.id)

      const response = await carSnoopApi.vehicles.searchInventory({
        deliverTo: dependentData.deliverTo.zipCode || '',
        isDealer,
        make: dependentData.make.name,
        model: dependentData.model.name,
        years,
        trim: dependentData.trim.id || '',
        exteriorColor: dependentData.exteriorColor.map((c) => c.id).join(', '),
        interiorColor: dependentData.interiorColor.map((c) => c.id).join(', ')
      })

      if (response.status === ResponseStatusMap.Success) {
        dispatch(actions.setVehiclesList(response.list))
        return response
      }

      dispatch(actions.setVehiclesList([]))
      return response
    }
  )

  useEffect(() => {
    searchVehiclesService()
  }, [])

  const [checkedList, setCheckedList] = useState(
    initialValues.selectedVehicles.map(({ id }) => id)
  )

  const onCheckChange = useCallback(
    (id: string, nextValue: boolean) => {
      if (nextValue) {
        setCheckedList([...checkedList, id])
      } else {
        setCheckedList(checkedList.filter((item) => item !== id))
      }
    },
    [checkedList]
  )

  const onBackHandler = useCallback(() => {
    dispatch(actions.decrementScreenStep())
    dispatch(actions.setSelectedVehicles([]))
  }, [dispatch, actions])

  const onNextHandler = useCallback(() => {
    dispatch(
      actions.setSelectedVehicles(
        vehiclesList.filter((item) => checkedList.includes(item.id))
      )
    )
    dispatch(actions.incrementScreenStep())
  }, [dispatch, actions, vehiclesList, checkedList])
  return (
    <Show
      when={
        !searchVehiclesObserver.isLoading &&
        searchVehiclesObserver.status !== 'idle'
      }
      fallback={<Loader />}
    >
      <Grid
        container
        flexGrow={1}
        alignContent='flex-start'
        rowSpacing={2}
        pb={9}
      >
        <Grid item container justifyContent='space-between'>
          <Grid item>
            <Typography variant='label1'>
              Select up to 15 available vehicles.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='subtitle2'>
              {vehiclesList.length} vehicles have been found matching your request.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DisplayVehiclesList
            vehiclesList={vehiclesList}
            checkedList={checkedList}
            onCheckChange={onCheckChange}
          />
        </Grid>
        <FindCarActionBlock
          onCancelClick={onCancel}
          onBackClick={onBackHandler}
          onNextClick={onNextHandler}
          nextButtonDisabled={!checkedList.length}
        />
      </Grid>
    </Show>
  )
}
