import { RawReadInventoryType } from './raw'
import { NormalizedReadInventoryType } from './normalized'
import { ReadInventoryItemStatusesMap } from './options'

export const readInventoryFormatter = (
  response: RawReadInventoryType
): NormalizedReadInventoryType['inventory'] => {
  return response.list.map((item) => ({
    id: item.id,
    documentID: item.document_id,
    addressID: item.address_id,
    transactionID: item.transaction_id,
    documentContent: item.document_content,
    make: item.make,
    mileage: item.mileage,
    model: item.model,
    status: ReadInventoryItemStatusesMap[item.status],
    stockNumber: item.stock_number,
    trim: item.trim,
    type: item.type,
    vin: item.vin,
    year: item.year,
    tradeInValue: item.tradein_value,
    retailValue: item.retail_value,
    requests: item.requests
  }))
}
