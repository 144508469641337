import format from 'date-fns/format'
import { useApiContext } from './useApiContext'
import { ContactDetails, PersonalDetails } from '../../types/forms/registration'
import { useMakeService } from './useMakeService'
import { IDPTypes } from '../../api/carSnoopApi/statusTypes'

interface RegisterUserOptions {
  personalDetails: PersonalDetails
  contactDetails: ContactDetails
  options: {
    idp: IDPTypes
    accountId?: string | false
    externalServiceToken?: string
    avatar?: string
  }
}

export const useRegisterUser = () => {
  const { carSnoopApi } = useApiContext()

  return useMakeService(
    async ({
      personalDetails,
      contactDetails,
      options
    }: RegisterUserOptions): Promise<
      Awaited<ReturnType<typeof carSnoopApi.users.registerUser>>
    > => {
      const birthDate = format(personalDetails.birthDate as Date, 'yyyy-MM-dd')
      const accountId = options.accountId || ''

      const registrationData = {
        idp: options.idp,
        first_name: personalDetails.firstName,
        last_name: personalDetails.lastName,
        birth_date: birthDate,
        zip_code: personalDetails.zipCode,
        email: contactDetails.email,
        city: contactDetails.city.name,
        state: contactDetails.state.name,
        phone: contactDetails.phone,
        // TODO: UNIQUE ID - we have to find a way to get unique id;
        device_id: '14:7d:da:49:4e:07',
        account_id: accountId,
        token: options.externalServiceToken,
        avatar: options.avatar
      }

      return carSnoopApi.users.registerUser(registrationData)
    }
  )
}
