import { Grid } from '@mui/material'
import { FC } from 'react'
import { ChatMediaMessage } from '../../../../../features/Chat/view/ChatMediaMessage'
import { ComponentContainer } from '../../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../../ComponentsPreviewPage/components/ComponentWithPaperBackground'
import { MediaMessageType } from '../../../../../api/chat/types'

const mockFile: MediaMessageType = {
  id: '1',
  message: '',
  contentName: 'file.txt',
  isSender: true,
  senderName: 'Seller',
  type: 'application/pdf',
  contentId: '711b3b14-5f7b-4af1-81e2-b83aab6db6d8',
  transactionId: '1',
  timestamp: new Date().toISOString(),
  hasRead: true,
  contentPreviewURL:
    'https://www.meme-arsenal.com/memes/617960cfa3101bf2ed6ca9501f63b4c8.jpg'
}

const mockFile1: MediaMessageType = {
  id: '2',
  message: '',
  contentName: 'Purchase Agreement.xls',
  isSender: false,
  senderName: 'Buyer',
  type: 'application/xls',
  contentId: '711b3b14-5f7b-4af1-81e2-b83aab6db6d8',
  transactionId: '1',
  timestamp: new Date().toISOString(),
  hasRead: true,
  contentPreviewURL:
    'https://www.meme-arsenal.com/memes/617960cfa3101bf2ed6ca9501f63b4c8.jpg'
}

const mockFile2: MediaMessageType = {
  id: '3',
  message: '',
  contentName: 'John`s favourite song.mp3',
  isSender: true,
  senderName: 'Seller',
  type: 'audio/mp3',
  contentId: '711b3b14-5f7b-4af1-81e2-b83aab6db6d8',
  transactionId: '1',
  timestamp: new Date().toISOString(),
  hasRead: true,
  contentPreviewURL:
    'https://www.meme-arsenal.com/memes/617960cfa3101bf2ed6ca9501f63b4c8.jpg'
}

const mockFile3: MediaMessageType = {
  id: '4',
  message: '',
  contentName: 'some-strange-song.brknfrmt',
  isSender: false,
  senderName: 'Buyer',
  type: 'audio/brknfrmt',
  contentId: '711b3b14-5f7b-4af1-81e2-b83aab6db6d8',
  transactionId: '1',
  timestamp: new Date().toISOString(),
  hasRead: true,
  contentPreviewURL:
    'https://www.meme-arsenal.com/memes/617960cfa3101bf2ed6ca9501f63b4c8.jpg'
}

const mockFile4: MediaMessageType = {
  id: '5',
  message: '',
  contentName:
    'Name-of-this-file-is-almost-as-big-as-Anatoliy-meme-collection-or-maybe-even-bigger.xls',
  isSender: true,
  senderName: 'Seller',
  type: 'application/xls',
  contentId: '711b3b14-5f7b-4af1-81e2-b83aab6db6d8',
  transactionId: '1',
  timestamp: new Date().toISOString(),
  hasRead: true,
  contentPreviewURL:
    'https://www.meme-arsenal.com/memes/617960cfa3101bf2ed6ca9501f63b4c8.jpg'
}

const mockSmallImage: MediaMessageType = {
  id: '6',
  message: '',
  contentName: '123',
  isSender: false,
  senderName: 'Buyer',
  type: 'image/jpeg',
  contentId: '711b3b14-5f7b-4af1-81e2-b83aab6db6d8',
  transactionId: '1',
  timestamp: new Date().toISOString(),
  hasRead: true,
  contentPreviewURL:
    'https://carsnoop.s3.us-east-2.amazonaws.com/2d9eb307-ae62-4ee4-b879-b62ae762ac9d.jpeg'
}

const mockLargeImage: MediaMessageType = {
  id: '7',
  message: '',
  contentName: '123',
  isSender: true,
  senderName: 'Seller',
  type: 'image/jpeg',
  contentId: '711b3b14-5f7b-4af1-81e2-b83aab6db6d8',
  transactionId: '1',
  timestamp: new Date().toISOString(),
  hasRead: true,
  contentPreviewURL:
    'https://carsnoop.s3.us-east-2.amazonaws.com/8329c85f-f987-4d83-afd9-b04fa8f26239.jpg'
}

const mockVideo: MediaMessageType = {
  id: '8',
  message: '',
  contentName: '123',
  isSender: false,
  senderName: 'Buyer',
  type: 'video/mp4',
  contentId: '711b3b14-5f7b-4af1-81e2-b83aab6db6d8',
  transactionId: '1',
  timestamp: new Date().toISOString(),
  hasRead: true,
  contentPreviewURL:
    'https://carsnoop.s3.us-east-2.amazonaws.com/8329c85f-f987-4d83-afd9-b04fa8f26239.jpg'
}

export const ChatMediaMessageExample: FC = () => {
  return (
    <ComponentContainer fullWidth>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ComponentWithPaperBackground title='PDF file'>
            <ChatMediaMessage {...mockFile} />
          </ComponentWithPaperBackground>
        </Grid>
        <Grid item xs={12}>
          <ComponentWithPaperBackground title='Some unsupported file'>
            <ChatMediaMessage {...mockFile1} />
          </ComponentWithPaperBackground>
        </Grid>
        <Grid item xs={12}>
          <ComponentWithPaperBackground title='Supported audio'>
            <ChatMediaMessage {...mockFile2} />
          </ComponentWithPaperBackground>
        </Grid>
        <Grid item xs={12}>
          <ComponentWithPaperBackground title='Unsupported audio'>
            <ChatMediaMessage {...mockFile3} />
          </ComponentWithPaperBackground>
        </Grid>
        <Grid item xs={12}>
          <ComponentWithPaperBackground title='File with long name'>
            <ChatMediaMessage {...mockFile4} />
          </ComponentWithPaperBackground>
        </Grid>
        <Grid item xs={12}>
          <ComponentWithPaperBackground title='Supported video'>
            <ChatMediaMessage {...mockVideo} />
          </ComponentWithPaperBackground>
        </Grid>
        <Grid item xs={12}>
          <ComponentWithPaperBackground title='Supported video'>
            <ChatMediaMessage {...mockVideo} senderName='Seller' isSender />
          </ComponentWithPaperBackground>
        </Grid>
        <Grid item xs={12}>
          <ComponentWithPaperBackground title='Supported pic'>
            <ChatMediaMessage {...mockLargeImage} />
          </ComponentWithPaperBackground>
        </Grid>
        <Grid item xs={12}>
          <ComponentWithPaperBackground title='Supported pic'>
            <ChatMediaMessage
              {...mockLargeImage}
              isSender={false}
              senderName='Buyer'
            />
          </ComponentWithPaperBackground>
        </Grid>
        {/* 470px is approx. width of sidechat (both tablet and desktop) */}
        <Grid item width='470px'>
          <ComponentWithPaperBackground title='File with long name, minimized'>
            <ChatMediaMessage {...mockFile4} isMinimized />
          </ComponentWithPaperBackground>
        </Grid>
        <Grid item width='470px'>
          <ComponentWithPaperBackground title='File with long name, minimized'>
            <ChatMediaMessage
              {...mockFile4}
              isMinimized
              senderName='Seller'
              isSender={false}
            />
          </ComponentWithPaperBackground>
        </Grid>
        <Grid item width='470px'>
          <ComponentWithPaperBackground title='Some unsupported file'>
            <ChatMediaMessage
              {...mockFile1}
              isSender={false}
              isMinimized
              senderName='Buyer'
            />
          </ComponentWithPaperBackground>
        </Grid>
        <Grid item width='470px'>
          <ComponentWithPaperBackground title='Some unsupported file'>
            <ChatMediaMessage
              {...mockFile1}
              isSender={false}
              isMinimized
              senderName='Buyer'
            />
          </ComponentWithPaperBackground>
        </Grid>
        <Grid item width='470px'>
          <ComponentWithPaperBackground title='Some unsupported file'>
            <ChatMediaMessage
              {...mockSmallImage}
              isSender={false}
              isMinimized
              senderName='Buyer'
            />
          </ComponentWithPaperBackground>
        </Grid>
        <Grid item width='470px'>
          <ComponentWithPaperBackground title='Some unsupported file'>
            <ChatMediaMessage
              {...mockLargeImage}
              isSender
              isMinimized
              senderName='Seller'
            />
          </ComponentWithPaperBackground>
        </Grid>
        <Grid item width='470px'>
          <ComponentWithPaperBackground title='Some unsupported file'>
            <ChatMediaMessage
              {...mockVideo}
              isSender
              isMinimized
              senderName='Seller'
            />
          </ComponentWithPaperBackground>
        </Grid>
        <Grid item width='470px'>
          <ComponentWithPaperBackground title='Some unsupported file'>
            <ChatMediaMessage
              {...mockVideo}
              isSender={false}
              isMinimized
              senderName='Buyer'
            />
          </ComponentWithPaperBackground>
        </Grid>
      </Grid>
    </ComponentContainer>
  )
}
