import { Form, Formik, FormikProps, FormikErrors } from 'formik'
import { FC, Ref, useMemo, useCallback } from 'react'
import { SxProps } from '@mui/material/styles'
import { noop } from '../../../utils'
import { SubscriptionRadioGroup } from './components/SubscriptionRadioGroup'
import { SUBSCRIPTION_FIELD_NAMES } from './SubscriptionMicroform.constants'
import {
  ISubscriptionOption,
  SubscriptionFormValues
} from './SubscriptionMicroform.types'
import { useUserInfo } from '../../../utils/hooks'

export type SubscriptionFormRefTargetType = FormikProps<SubscriptionFormValues>

interface ISubscriptionMicroform {
  options: ISubscriptionOption[]
  formRef: Ref<SubscriptionFormRefTargetType>
  initialValues?: SubscriptionFormValues
  containerSx?: SxProps
}

export const SubscriptionMicroform: FC<ISubscriptionMicroform> = ({
  options,
  formRef,
  initialValues,
  containerSx = {}
}) => {
  const {
    subscription: { name: currentSubscription }
  } = useUserInfo()
  const validate = useCallback(
    (values: SubscriptionFormValues) => {
      const errors: FormikErrors<SubscriptionFormValues> = {}
      if (values.subscription === currentSubscription) {
        errors.subscription = 'Not valid'
      }
      return errors
    },
    [initialValues]
  )

  const defaultValues = useMemo(() => {
    return (
      initialValues || {
        [SUBSCRIPTION_FIELD_NAMES.SUBSCRIPTION]: options.length
          ? options[0].name
          : ''
      }
    )
  }, [options, initialValues])

  return (
    <Formik<SubscriptionFormValues>
      initialValues={defaultValues}
      onSubmit={noop}
      validateOnChange
      validateOnMount
      validate={validate}
      innerRef={formRef}
      enableReinitialize
    >
      {() => (
        <Form>
          <SubscriptionRadioGroup
            name={SUBSCRIPTION_FIELD_NAMES.SUBSCRIPTION}
            options={options}
            containerSx={containerSx}
          />
        </Form>
      )}
    </Formik>
  )
}
