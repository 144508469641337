import {
  FC,
  useCallback,
  useState,
  ChangeEvent,
  ElementType,
  ReactNode,
  FocusEvent
} from 'react'
import { FieldValidator, useField } from 'formik'
import { InputField, InputFieldProps } from '../InputField/InputField'
import { getIsFieldRequired } from '../../utils/validators/getIsFieldRequired'

export interface TextInputFieldProps {
  name: string
  label?: string
  placeholder?: string
  disabled?: boolean
  isLoading?: boolean
  required?: boolean | ((name: string) => boolean)
  backgroundColor?: string
  validate?: FieldValidator
  onBlur?: () => void
  onIsChanged?: () => void
  component?: ElementType<InputFieldProps>
  endAdornment?: ReactNode
  maxChars?: number
}

export const TextInputFieldNew: FC<TextInputFieldProps> = (props) => {
  const {
    name,
    label,
    placeholder,
    disabled,
    isLoading,
    validate,
    required = false,
    backgroundColor,
    onBlur: customOnBlur,
    onIsChanged,
    endAdornment,
    maxChars = Infinity,
    component: FieldComponent = InputField
  } = props
  const [field, meta, helpers] = useField<string>({ name, validate })
  const [isOverflow, setIsOverflow] = useState(false)
  const { value, onChange, onBlur } = field
  const { error } = meta

  const handleFocus = useCallback(() => {
    helpers.setError('')
  }, [helpers])

  const handleBlur = useCallback(
    (e: FocusEvent) => {
      onBlur(e)
      customOnBlur && customOnBlur()
      setIsOverflow(false)
    },
    [onBlur, customOnBlur]
  )

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value: inputValue } = e.target
      if (inputValue.length > maxChars) {
        setIsOverflow(true)
        return
      }

      setIsOverflow(false)
      onIsChanged && onIsChanged()
      onChange(e)
    },
    [maxChars, setIsOverflow, onIsChanged, onChange]
  )

  const isRequiredField = getIsFieldRequired(required, name)

  return (
    <FieldComponent
      name={name}
      value={value}
      label={label}
      placeholder={placeholder}
      required={isRequiredField}
      disabled={disabled}
      isLoading={isLoading}
      helperText={error}
      error={!!error}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      backgroundColor={backgroundColor}
      endAdornment={endAdornment}
      borderError={isOverflow}
    />
  )
}
