import { findCarFormSlice } from './findCarForm'

export const actionsFindCarForm = {
  incrementScreenStep: findCarFormSlice.actions.incrementScreenStep,
  decrementScreenStep: findCarFormSlice.actions.decrementScreenStep,
  setBasicInfo: findCarFormSlice.actions.setBasicInfo,
  setTradeInInfo: findCarFormSlice.actions.setTradeInInfo,
  setVehiclesList: findCarFormSlice.actions.setVehiclesList,
  setSelectedVehicles: findCarFormSlice.actions.setSelectedVehicles,
  modifyVehiclesListShippingCost:
    findCarFormSlice.actions.modifyVehiclesListShippingCost,
  modifySelectedVehiclesShippingCost:
    findCarFormSlice.actions.modifySelectedVehiclesShippingCost,
  resetState: findCarFormSlice.actions.resetState,
  updateDisplayedTradeInValues:
    findCarFormSlice.actions.updateDisplayedTradeInValues,
  deleteTradein: findCarFormSlice.actions.deleteTradein,
  setFindCarFormState: findCarFormSlice.actions.setFindCarFormState,
  setScreenStep: findCarFormSlice.actions.setScreenStep
}
