import {
    Tooltip as MUITooltip,
    TooltipProps,
    styled,
    tooltipClasses
} from '@mui/material'
import { FC } from 'react'

import { COLORS } from '../../types/colors'

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MUITooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: COLORS.main_yellow,
    color: COLORS.text_primary,
    fontSize: 14,
    fontWeight: 400
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: COLORS.main_yellow
  }
}))

export const Tooltip: FC<TooltipProps> = (props) => {
  return <StyledTooltip {...props} arrow placement='top' />
}
