import { actionsContractPage } from '../../../store/pages/Contract'
import {
  useAppDispatch,
  useMakeService,
  useApiContext
} from '../../../utils/hooks'
import { ResponseStatusMap } from '../../../types/status'
import { CONTRACT_TABS } from '../../../store/pages/Contract/contractPage'

type UpdateTransactionParams = {
  id: string
  skipLoader?: boolean
  openContractInformation?: boolean
}

export const useUpdateContractTransaction = () => {
  const { carSnoopApi } = useApiContext()
  const dispatch = useAppDispatch()

  const [updateTransaction, updateTransactionObservable] = useMakeService(
    async ({
      id,
      skipLoader = false,
      openContractInformation = true
    }: UpdateTransactionParams) => {
      if (openContractInformation) {
        dispatch(
          actionsContractPage.setCurrentTab(CONTRACT_TABS.contractInformation)
        )
      }
      if (!skipLoader) {
        dispatch(actionsContractPage.setTransaction(null))
      }

      const response = await carSnoopApi.transactions.readTransaction(id)

      if (response.status === ResponseStatusMap.Success) {
        dispatch(actionsContractPage.setTransaction(response.data))
      }

      return response
    }
  )

  return {
    isLoading: updateTransactionObservable.isLoading,
    updateTransaction
  }
}
