import { FC } from 'react'
import { Label } from '../../../../atoms/Label'
import { SelectElement } from '../../../../atoms/SelectElement'

import { LocationContainer } from './Location.styles'

type LocationProps = {
  state: {
    name: string
    value: string
    status: 'idle' | 'pending' | 'succeeded' | 'failed'
    options: Array<{ id: string; name: string }>
  }
  city: {
    name: string
    value: string
    status: 'idle' | 'pending' | 'succeeded' | 'failed'
    options: Array<{ id: string; name: string }>
  }
}

export const Location: FC<LocationProps> = ({ city, state }) => {
  return (
    <div>
      <Label name='location' label='City, State' />
      <LocationContainer>
        <SelectElement
          placeholder={city.name}
          name='city'
          isDisabled
          value={city.value}
          options={city.options}
          status={state.status}
        />
        <SelectElement
          placeholder={state.name}
          name='state'
          isDisabled
          value={state.value}
          options={state.options}
          status={state.status}
        />
      </LocationContainer>
    </div>
  )
}
