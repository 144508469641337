import { css, Grid } from '@mui/material'
import { Box, styled } from '@mui/system'
import { BORDERS, COLORS, SHADOWS } from '../../types/colors'

export const VehicleItemContainer = styled(Box)`
  padding: 16px;
  border: ${BORDERS.SM_GRAY};
  box-shadow: ${SHADOWS.primary};
  border-radius: 10px;
  height: 100%;
`

export const ImageContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'isDefault'
}) <{ isDefault?: boolean }>`
  border-radius: 3px;
  overflow: hidden;
  position: relative;

  ${({ isDefault }) =>
    isDefault
      ? css`
          background-color: ${COLORS.border_gray};
          padding: 10px;
        `
      : ''}
`

export const StatusContainer = styled(Box)`
  position: absolute;
  top: 6px;
  left: 6px;
`

export const TitleContainer = styled('div')`
  font-weight: 500;
  color: ${COLORS.text_primary};
  overflow: hidden;
`

export const NumberIcon = styled('div')`
  font-size: 14px;
  color: ${COLORS.text_secondary};
  margin-right: 4px;
`

export const NumberContainer = styled('div')`
  font-size: 14px;
  color: ${COLORS.text_primary};
  overflow: hidden;
`

export const InfoTitle = styled('span')`
  color: ${COLORS.text_disabled_black};
`

export const Info = styled('div')`
  font-weight: 500;
  color: ${COLORS.text_primary};
  overflow: hidden;
`

export const DividerStyles = styled(Box)`
  border-top: ${COLORS.border_gray};
  height: 6px;
  width: 100%;
`
