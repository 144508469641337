import { Checkbox } from '@mui/material'
import { SwitchBaseProps } from '@mui/material/internal/SwitchBase'
import { FC } from 'react'
import { COLORS } from '../../types/colors'

interface CustomCheckboxProps {
  checked: boolean
  onChange: SwitchBaseProps['onChange']
}

export const CustomCheckbox: FC<CustomCheckboxProps> = ({
  checked,
  onChange
}) => {
  return (
    <Checkbox
      checked={checked}
      onChange={onChange}
      sx={{
        borderRadius: 0,
        padding: 0,
        backgroundColor: COLORS.white,
        '&.Mui-checked': {
          color: COLORS.main_black
        },
        '&:hover': {
          backgroundColor: COLORS.white
        }
      }}
    />
  )
}
