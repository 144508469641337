import { Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { AddShipmentDetailsParamsAddress } from '../../api/carSnoopApi/shipments/types'
import { CarInfoItem } from '../../atoms/CarInfoItem'
import { CarInfoItemProps } from '../../atoms/CarInfoItem/CarInfoItem'
import { Show } from '../../atoms/JSXExtensions'
import { COLORS } from '../../types/colors'
import { DeliveryAddresses } from './DeliveryAddresses'
import { CardContainer } from './DeliveryCard.styles'

const NOT_COMPLETE_ADDRESS_ERROR = 'Please, add a complete address.'

export interface DeliveryCardProps {
  cardTitle: string
  carInfo: CarInfoItemProps
  pickup: AddShipmentDetailsParamsAddress
  dropOff: AddShipmentDetailsParamsAddress
  hasAddressError?: boolean
  isDelivered?: boolean
  onDropOffEdit?: (address: AddShipmentDetailsParamsAddress) => void
  onPickupEdit?: (address: AddShipmentDetailsParamsAddress) => void
}

export const DeliveryCard: FC<DeliveryCardProps> = ({
  cardTitle,
  pickup,
  dropOff,
  onDropOffEdit,
  onPickupEdit,
  carInfo,
  isDelivered,
  hasAddressError = false
}) => {
  return (
    <CardContainer container flexDirection='column' rowSpacing={2}>
      <Grid item>
        <Typography variant='label1'>{cardTitle}</Typography>
      </Grid>
      <Grid item container>
        <DeliveryAddresses
          pickUpAddress={pickup}
          dropOffAddress={dropOff}
          onDropOffEdit={onDropOffEdit}
          onPickupEdit={onPickupEdit}
          isDelivered={isDelivered}
        />
      </Grid>
      <Show when={hasAddressError}>
        <Grid item>
          <Typography variant='subtitle1' color={COLORS.text_red}>
            {NOT_COMPLETE_ADDRESS_ERROR}
          </Typography>
        </Grid>
      </Show>
      <Grid container item>
        <CarInfoItem {...carInfo} />
      </Grid>
    </CardContainer>
  )
}
