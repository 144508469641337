import { Grid } from '@mui/material'
import { FC } from 'react'
import { NoMatchesImage } from '../../../../../assets/imgs/NoMatches/intex'
import { Condition } from '../../../../../atoms/Condition'
import { EmptyState } from '../../../../../atoms/ItemList'
import { For, Show } from '../../../../../atoms/JSXExtensions'
import { NormalizedDealsListType } from '../../../../../store/types'
import { DealCard } from '../DealCard'

export interface DealsListProps {
  deals: NormalizedDealsListType
  noDealsText: string
}

export const DealsList: FC<DealsListProps> = (props) => {
  const { deals, noDealsText } = props

  return (
    <Grid container item spacing={2}>
      <Show when={deals}>
        {(completedDeals) => (
          <Condition
            condition={!!completedDeals.length}
            trueContent={
              <For of={completedDeals}>
                {(deal) => {
                  return (
                    <Grid item xs={12} md={6} lg={4}>
                      <DealCard deal={deal} />
                    </Grid>
                  )
                }}
              </For>
            }
            falseContent={
              <EmptyState title={noDealsText} image={<NoMatchesImage />} />
            }
          />
        )}
      </Show>
    </Grid>
  )
}
