import { useCallback, useMemo, useState } from 'react'
import {
  RequestStatus,
  RequestStatusMap,
  ResponseStatusMap
} from '../../../types/status'
import { useAccessDeniedHandler } from './handlers/useAccessDeniedHandler'
import { useRequestNotification } from './handlers/useRequestNotification'
import {
  InitialStateProp,
  ServiceType,
  UseMakeServiceReturnType
} from './types'

const defaultInitialState = {
  payload: {
    status: ResponseStatusMap.Error,
    message: ''
  }
}

/**
 * @param service - Service function
 * @param config {object} - Service config
 * @param config.props {object} - Initial props of service
 * @param config.payload {object} - Initial payload of service
 * @param config.includeCheckAccess {boolean} - Include check access
 * @param config.withStatusNotification {boolean | [0|1] | [0|1, 0|1]} - Include status notification. [error, success]
 * @returns [service, serviceMetaData]
 */
export const useMakeService = <T extends ServiceType>(
  service: T,
  config: InitialStateProp<
    Parameters<T>[0],
    Awaited<ReturnType<T>>
  > = defaultInitialState as { payload: Awaited<ReturnType<T>> }
): UseMakeServiceReturnType<T> => {
  type ServiceProps = Parameters<T>[0]
  type ServicePayload = Awaited<ReturnType<T>>

  const {
    payload: initialPayload = defaultInitialState.payload,
    props: initialProps = config.props,
    includeCheckAccess = false,
    withStatusNotification = false
  } = config

  const { isAccessDenied, processAccessDenied } = useAccessDeniedHandler()
  const { triggerNotification } = useRequestNotification({
    withStatusNotification
  })

  const [serviceStatus, setServiceStatus] = useState<RequestStatus>(
    RequestStatusMap.Idle
  )
  const [servicePayload, setServicePayload] = useState<ServicePayload>(
    initialPayload as ServicePayload
  )
  const [props, setProps] = useState<ServiceProps>(initialProps as ServiceProps)

  const store = useMemo(() => {
    return {
      status: serviceStatus,
      props: props as ServiceProps,
      payload: servicePayload,
      isLoading: serviceStatus === RequestStatusMap.Pending,
      isError: serviceStatus === RequestStatusMap.Failed,
      isSuccess: serviceStatus === RequestStatusMap.Succeeded
    }
  }, [serviceStatus, servicePayload, props])

  const makeRequest = useCallback(
    async (...serviceProps: Parameters<T>) => {
      setProps(serviceProps[0])
      setServiceStatus(RequestStatusMap.Pending)

      try {
        let responseData: ServicePayload

        if (serviceProps) {
          responseData = await service(...serviceProps)
        } else {
          responseData = await service()
        }

        const { status, ...payload } = responseData

        if (includeCheckAccess && isAccessDenied({ status, payload })) {
          processAccessDenied()
          setServiceStatus(RequestStatusMap.Idle)
          setServicePayload(payload as ServicePayload)

          return responseData
        }

        const currentServiceStatus =
          status === ResponseStatusMap.Success
            ? RequestStatusMap.Succeeded
            : RequestStatusMap.Failed

        setServiceStatus(currentServiceStatus)
        setServicePayload(payload as ServicePayload)
        triggerNotification(currentServiceStatus)

        return responseData
      } catch (error) {
        console.error(error)
        setServiceStatus(RequestStatusMap.Failed)
      }
    },
    [service]
  ) as UseMakeServiceReturnType<T>[0]

  return [makeRequest, store]
}
