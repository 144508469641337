import {
  VinFieldSet,
  VinFieldSetValues
} from '../../../AddVehicle/screens/EditNewVehicleDetails/Controls'
import { noop } from '../../../../utils/noop'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const VinFieldSetExample = () => {
  const handleVinConfirm = (formValues: VinFieldSetValues) => {
    console.log(formValues)
  }

  return (
    <ComponentContainer fullWidth>
      <ComponentWithPaperBackground title='Not it molecules folder'>
        <VinFieldSet
          onSubmit={handleVinConfirm}
          storage={{
            values: {
              VIN: {
                vin: ''
              }
            },
            errors: {
              VIN: {
                vin: ''
              }
            },
            updateValues: noop,
            updateErrors: noop
          }}
        />
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
