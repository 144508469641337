import { useState, useMemo } from 'react'
import { Grid } from '@mui/material'
import { RadioVehicleComponent } from '../../../../atoms/RadioVehicleComponent'
import { vehicles } from './mocks'
import { For } from '../../../../atoms/JSXExtensions'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const RadioVehicleComponentExample = () => {
  const singleVehicleList = [vehicles[25]]

  const [checkedID, setCheckedID] = useState<string | null>(
    singleVehicleList[0]?.id || null
  )

  const isShowControl = useMemo(
    () => singleVehicleList.length > 1,
    [singleVehicleList]
  )

  return (
    <ComponentContainer fullWidth>
      <ComponentWithPaperBackground>
        <Grid container spacing={2}>
          <For of={singleVehicleList}>
            {(vehicle) => (
              <Grid item xs={12} md={6} lg={4} key={vehicle.id}>
                <RadioVehicleComponent
                  id={vehicle.id}
                  showControl={isShowControl}
                  imageSource={vehicle.document_content}
                  year={vehicle.year}
                  make={vehicle.make}
                  model={vehicle.model}
                  trim={vehicle.trim}
                  mileage={vehicle.mileage}
                  tradeInPrice={vehicle.trade_in_price}
                  checked={vehicle.id === checkedID}
                  onChange={setCheckedID}
                />
              </Grid>
            )}
          </For>
        </Grid>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}

export const MultipleRadioVehicleComponentExample = () => {
  const [checkedID, setCheckedID] = useState<string>(vehicles[0].id)
  const isShowControl = vehicles.length > 1

  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <Grid container spacing={2}>
          <For of={vehicles}>
            {(vehicle) => (
              <Grid item xs={12} md={6} lg={4} key={vehicle.id}>
                <RadioVehicleComponent
                  id={vehicle.id}
                  showControl={isShowControl}
                  imageSource={vehicle.document_content}
                  year={vehicle.year}
                  make={vehicle.make}
                  model={vehicle.model}
                  trim={vehicle.trim}
                  mileage={vehicle.mileage}
                  tradeInPrice={vehicle.trade_in_price}
                  checked={vehicle.id === checkedID}
                  onChange={setCheckedID}
                />
              </Grid>
            )}
          </For>
        </Grid>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
