import { useState, useCallback, useLayoutEffect, useMemo } from 'react'
import {
  CompressImageResponse,
  CompressImageSuccessResponse
} from '../../../utils/compressImages'
import {
  IMAGE_OVERSIZE_ERROR,
  IMAGE_WRONG_FORMAT_ERROR,
  IMAGE_SOMETHING_WRONG_ERROR
} from '../../../constants/messages'

export const useImagePreview = (
  id: string,
  type: string,
  onUpload: (data: {
    data: CompressImageSuccessResponse
    thumbnail: boolean
  }) => Promise<unknown>,
  onDeleteClick: (data: { id: string; type: string }) => void,
  setLoadingImageId: (id: string) => void,
  thumbnail: boolean
) => {
  const [isImageHovered, setIsImageHovered] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isOversize, setIsOversize] = useState(false)
  const [isWrongFormat, setIsWrongFormat] = useState(false)
  const [isRequestError, setIsRequestError] = useState(false)

  const handleImageMouseOver = useCallback(() => {
    setIsImageHovered(true)
  }, [setIsImageHovered])

  const handleImageMouseLeave = useCallback(() => {
    setIsImageHovered(false)
  }, [setIsImageHovered])

  const handleUpload = useCallback(
    async (data: CompressImageResponse) => {
      setIsOversize(false)
      setIsWrongFormat(false)
      const file = data[0]

      if (!file.isFileExtensionAvailable) {
        setIsLoading(false)
        setLoadingImageId('')
        return setIsWrongFormat(true)
      }

      const { isAcceptableMinify } = file

      if (!isAcceptableMinify) {
        setIsLoading(false)
        setLoadingImageId('')
        return setIsOversize(true)
      }

      const response = await onUpload({
        data: file,
        thumbnail
      })

      if (!response) {
        setIsRequestError(true)
      }
      setIsLoading(false)
      setIsImageHovered(false)
      setLoadingImageId('')
    },
    [onUpload, setIsLoading, setIsImageHovered, setLoadingImageId, id]
  )

  const handleBeforeUpload = useCallback(async () => {
    setIsOversize(false)
    setLoadingImageId(id)
    setIsLoading(true)
    setIsRequestError(false)
  }, [setIsLoading, id])

  const handleDeleteClick = useCallback(() => {
    onDeleteClick({ type, id })
  }, [onDeleteClick, id, type])

  const errorMessage = useMemo(() => {
    if (isWrongFormat) return IMAGE_WRONG_FORMAT_ERROR
    if (isOversize) return IMAGE_OVERSIZE_ERROR
    if (isRequestError) return IMAGE_SOMETHING_WRONG_ERROR
    return ''
  }, [isOversize, isWrongFormat, isRequestError])

  useLayoutEffect(() => {
    setIsOversize(false)
    setIsWrongFormat(false)
    setIsRequestError(false)
  }, [id])

  return {
    isImageHovered,
    isLoading,
    errorMessage,
    handleImageMouseOver,
    handleImageMouseLeave,
    handleUpload,
    handleDeleteClick,
    handleBeforeUpload
  }
}
