import { Box, Grid, Typography } from '@mui/material'
import { Form, Formik } from 'formik'

import { FC } from 'react'
import { ActionBlock } from '../../../atoms/ActionBlock'
import { DatePicker } from '../../../atoms/DatePicker'
import { PasswordInputField } from '../../../atoms/PasswordInputField'
import { PhoneInputField } from '../../../atoms/PhoneInputField'
import { RequiredLabel } from '../../../atoms/RequiredText'
import { TextInputField } from '../../../atoms/TextInputField'
import { MAX_DATE } from '../../../constants/applicationConstants'
import {
  CREATE_PASSWORD_HINT,
  DATE_PICKER_INFO_MESSAGE
} from '../../../constants/messages'
import { phoneNumberMaskPattern } from '../../../constants/regexp'
import { ScreenContainer } from '../../../layout/ScreenContainer'
import {
  REGISTRATION_BY_EMAIL_FIELD_LABELS,
  REGISTRATION_BY_EMAIL_FIELD_NAMES,
  REGISTRATION_BY_EMAIL_PLACEHOLDERS
} from '../../../types/enums/registrationByEmail'
import { Fields } from '../../../types/forms/registrationByEmail'
import { initialValues } from './constants'
import { validationSchema } from './validationSchema'

export interface RegistrationByEmailScreenProps {
  email: string
  isLoading?: boolean
  onSubmit: (data: Fields) => void
}

export const RegistrationByEmailScreen: FC<RegistrationByEmailScreenProps> = ({
  email, isLoading, onSubmit
}) => {
  return (
    <ScreenContainer isLoading={isLoading} >
      <Box sx={{ mb: 2 }}>
        <RequiredLabel />
      </Box>
      <Formik<Fields & { email: string }>
        initialValues={{ email, ...initialValues }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnMount
      >
        {(formik) => {
          return (
            <Form autoComplete='off'>
              <Grid container spacing='24px'>
                <Grid item xs={12}>
                  <TextInputField
                    label={REGISTRATION_BY_EMAIL_FIELD_LABELS.FIRST_NAME}
                    placeholder={REGISTRATION_BY_EMAIL_PLACEHOLDERS.FIRST_NAME}
                    name={REGISTRATION_BY_EMAIL_FIELD_NAMES.FIRST_NAME}
                    isValid={
                      !(formik.touched?.firstName && formik.errors.firstName)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInputField
                    label={REGISTRATION_BY_EMAIL_FIELD_LABELS.LAST_NAME}
                    placeholder={REGISTRATION_BY_EMAIL_PLACEHOLDERS.LAST_NAME}
                    name={REGISTRATION_BY_EMAIL_FIELD_NAMES.LAST_NAME}
                    isValid={
                      !(formik.touched?.lastName && formik.errors.lastName)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <DatePicker
                    label={REGISTRATION_BY_EMAIL_FIELD_LABELS.BIRTH_DATE}
                    name={REGISTRATION_BY_EMAIL_FIELD_NAMES.BIRTH_DATE}
                    value={formik.values.birthDate}
                    maxDate={MAX_DATE}
                    isError={!!formik.errors.birthDate}
                    infoMessage={DATE_PICKER_INFO_MESSAGE}
                    onChange={(value) =>
                      formik.setFieldValue('birthDate', value)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <PhoneInputField
                    label={REGISTRATION_BY_EMAIL_FIELD_LABELS.PHONE}
                    placeholder={REGISTRATION_BY_EMAIL_PLACEHOLDERS.PHONE}
                    name={REGISTRATION_BY_EMAIL_FIELD_NAMES.PHONE}
                    mask={phoneNumberMaskPattern}
                    isValid={!(formik.touched?.phone && formik.errors.phone)}
                    autoComplete='no'
                  />
                </Grid>
                <Grid item xs={12}>
                  <PasswordInputField
                    label={REGISTRATION_BY_EMAIL_FIELD_LABELS.PASSWORD}
                    placeholder={REGISTRATION_BY_EMAIL_PLACEHOLDERS.PASSWORD}
                    name={REGISTRATION_BY_EMAIL_FIELD_NAMES.PASSWORD}
                    isValid={
                      !(formik.touched?.password && formik.errors.password)
                    }
                    autoComplete='no'
                  />
                </Grid>

                <Grid container item xs={12} spacing='16px'>
                  <Grid item xs={12}>
                    <PasswordInputField
                      label={
                        REGISTRATION_BY_EMAIL_FIELD_LABELS.CONFIRM_PASSWORD
                      }
                      placeholder={
                        REGISTRATION_BY_EMAIL_PLACEHOLDERS.CONFIRM_PASSWORD
                      }
                      name={REGISTRATION_BY_EMAIL_FIELD_NAMES.CONFIRM_PASSWORD}
                      isValid={
                        !(
                          formik.touched?.confirmPassword &&
                          formik.errors.confirmPassword
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='subtitle2'>
                      {CREATE_PASSWORD_HINT}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={12} />

                <Grid item xs={12}>
                  <ActionBlock
                    schema={
                      [
                        {
                          label: 'space',
                          type: 'empty'
                        },
                        {
                          label: 'Log In',
                          type: 'primary',
                          disabled: !(formik.dirty && formik.isValid),
                          props: {
                            type: 'submit'
                          }
                        }
                      ]
                    }
                  />
                </Grid>
              </Grid>
            </Form>
          )
        }}
      </Formik>
    </ScreenContainer >
  )
}
