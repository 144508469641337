import { FC } from 'react'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { selectNetworkLocation } from '../../../store/units/registrationForm/selectors'
import { ContactDetails, FormActions } from '../../../types/forms/registration'

import {
  ContactDetailsScreen,
  ContactDetailsScreenScreenProps
} from './ContactDetailsScreen'

type ContactDetailsContainerProps = {
  initialValues: ContactDetails
  actions: FormActions<
    | 'incrementScreenStep'
    | 'decrementScreenStep'
    | 'incrementOnboardStep'
    | 'decrementOnboardStep'
    | 'setContactDetails'
  >
  staticValues?: Partial<ContactDetails>
  initialErrors?: ContactDetailsScreenScreenProps['initialErrors']
  isLoading?: boolean
  onSubmit: ContactDetailsScreenScreenProps['onNextBtnClicked']
}

export const ContactDetailsContainer: FC<ContactDetailsContainerProps> = ({
  initialValues,
  actions,
  staticValues = {},
  initialErrors,
  isLoading,
  onSubmit
}) => {
  const dispatch = useAppDispatch()
  const userLocation = useAppSelector(selectNetworkLocation)

  const onBackBtnClicked = (): void => {
    dispatch(actions.decrementScreenStep())
    dispatch(actions.decrementOnboardStep())
  }

  return (
    <ContactDetailsScreen
      isLoading={userLocation.status === 'pending' || isLoading}
      initialValues={initialValues}
      staticValues={staticValues}
      onNextBtnClicked={onSubmit}
      onBackBtnClicked={onBackBtnClicked}
      initialErrors={initialErrors}
    />
  )
}
