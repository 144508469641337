import { FormActions } from '../../../types/forms/registration'

export const VERIFICATION_CODE_LENGTH = 4

export enum VerificationCodeText {
  title = 'Verification Code',
  subtitle = "We've sent you a verification code to your email",
  resend = "Didn't get the email?"
}

export type VerificationCodeScreenActions = FormActions<
  | 'incrementScreenStep'
  | 'decrementScreenStep'
  | 'incrementOnboardStep'
  | 'decrementOnboardStep'
  | 'setVerificationCode'
  | 'setIsVerificationBlocked'
>

export enum EMAIL_NOT_VERIFIED_ERROR {
  TITLE = 'Email is not verified',
  SUBTITLE = 'An account with this email already exists but the email has not been verified.',
  DESCRIPTION = 'Check your email or click the button below to resend a verification code.'
}

export enum VERIFICATION_CODE_BLOCKED_ERROR {
  TEMPORARY = 'You’ve exceeded the number of attempts.\n Please try again in {{locked_until}} min.',
  PERMANENTLY = 'You’ve exceeded the number of attempts.\n Please contact Support@carsnoop.com'
}
