import { Box, TextField } from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DateValidationError } from '@mui/x-date-pickers/internals'
import { useField } from 'formik'
import { FC, SyntheticEvent, useCallback, useMemo, useState } from 'react'
import { COLORS } from '../../types/colors'
import { getIsFieldRequired } from '../../utils/validators/getIsFieldRequired'
import { Condition } from '../Condition'
import { ErrorMessageBlock } from '../ErrorMessageBlock'

type DatePickerFieldProps = {
  name: string
  label: string
  minDate?: Date
  maxDate?: Date
  disabled?: boolean
  showError?: boolean
  errorMessages?: Partial<
    Record<
      | 'invalidDate'
      | 'shouldDisableDate'
      | 'disableFuture'
      | 'disablePast'
      | 'minDate'
      | 'maxDate',
      string
    >
  >
  required?: boolean | ((name: string) => boolean)
}

const getStyles = (hasError: boolean, requiredField: boolean) => {
  return {
    width: '100%',
    '& .MuiInputLabel-root': {
      paddingLeft: '14px',
      color: COLORS.text_secondary,
      fontSize: '16px',
      '&:after': {
        content: requiredField ? "' *'" : "''",
        color: COLORS.text_red
      }
    },
    '& .MuiFilledInput-input': {
      textTransform: 'uppercase',
      '&::placeholder': {
        color: COLORS.text_secondary,
        opacity: 1
      }
    },
    '& .MuiFilledInput-root': {
      backgroundColor: COLORS.white,
      border: `1px solid ${COLORS.border_gray}`,
      borderRadius: '4px',
      paddingLeft: '24px',
      '&:hover': {
        backgroundColor: COLORS.white,
        border: `1px solid ${COLORS.text_blue}`
      },
      '& .MuiSvgIcon-root': {
        fill: COLORS.text_primary
      }
    },
    '& .MuiFilledInput-root.Mui-focused': {
      backgroundColor: COLORS.white,
      border: `1px solid ${COLORS.text_blue}`
    },
    '& .MuiFilledInput-root.Mui-error': {
      color: hasError ? COLORS.error_message_red : '',
      border: hasError ? `1px solid ${COLORS.error_message_red}` : '',
      '& .MuiButtonBase-root': {
        color: hasError ? COLORS.error_message_red : ''
      }
    },
    '& .MuiInputLabel-root.Mui-error': {
      color: hasError ? COLORS.error_message_red : COLORS.text_blue
    },
    '&.MuiTextField-root .MuiFilledInput-root .MuiSvgIcon-root': {
      fill: hasError ? COLORS.error_message_red : ''
    }
  }
}

export const DatePickerField: FC<DatePickerFieldProps> = ({
  label,
  name,
  minDate,
  maxDate,
  disabled = false,
  showError = false,
  errorMessages,
  required = false
}) => {
  const [field, meta, helpers] = useField<Date | null>({ name, type: 'date' })
  const { value, onBlur } = field
  const { error } = meta
  const [isActive, setIsActive] = useState(false)

  const hasErrorOnBlur = useMemo(() => !!error && !isActive, [error, isActive])
  const isRequiredField = getIsFieldRequired(required, name)

  const styles = useMemo(
    () => getStyles(hasErrorOnBlur, isRequiredField),
    [hasErrorOnBlur]
  )

  const onChangeHandler = (changedDate: Date | null) => {
    helpers.setValue(changedDate)
    helpers.setTouched(true)
  }

  const onFocusHandler = useCallback(() => {
    helpers.setError('')
    setIsActive(true)
  }, [helpers, setIsActive])

  const onBlurHandler = useCallback(
    (event: SyntheticEvent) => {
      setIsActive(false)
      onBlur(event)
    },
    [value, setIsActive, onBlur]
  )

  const onErrorHandler = useCallback(
    (reason: DateValidationError) => {
      if (!reason) {
        helpers.setError('')
        return
      }

      const errorMessage = errorMessages ? errorMessages[reason] : reason
      helpers.setError(errorMessage)
    },
    [helpers, errorMessages]
  )

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          inputFormat='MM/dd/yyyy'
          disabled={disabled}
          value={value}
          label={label}
          defaultCalendarMonth={maxDate}
          maxDate={maxDate}
          minDate={minDate}
          onChange={onChangeHandler}
          InputAdornmentProps={{ position: 'start' }}
          onError={onErrorHandler}
          onClose={() => {
            helpers.setTouched(true)
          }}
          InputProps={{
            disableUnderline: true,
            onFocus: onFocusHandler,
            onBlur: onBlurHandler
          }}
          renderInput={(params) => {
            return <TextField {...params} variant='filled' sx={styles} />
          }}
        />
      </LocalizationProvider>
      <Condition
        condition={(showError || !!errorMessages) && hasErrorOnBlur}
        trueContent={<ErrorMessageBlock message={error || ''} />}
      />
    </Box>
  )
}
