import { styled } from '@mui/material';
import { Form } from 'formik';
import { ActionBlock } from '../../../atoms/ActionBlock';

export const ResetPasswordForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  & > div:first-of-type {
    margin: 16px 0 24px;
  }
`

export const ActionBlockStyles = styled(ActionBlock)(({ theme }) => ({
  marginTop: 'auto',
  '& > button': {
    fontSize: '16px',
  },
  '& button:first-of-type': {
    minWidth: '136px',
  },
  '& button:last-of-type': {
    padding: '12px',
    border: 'none',
  },
  [theme.breakpoints.down('md')]: {
    margin: '16px 0',
    '& button:first-of-type': {
      display: 'none',
    },
    '& button:last-of-type': {
      width: '100%',
    },
  },
}));
