import { FC, useMemo } from 'react'
import { useField } from 'formik'
import { Switch } from '@mui/material'
import { ToggleProps, getStyles } from './Toggle'

interface ToggleFieldProps {
  name: string
}

export const ToggleField: FC<ToggleFieldProps> = ({ name }) => {
  const [field] = useField<boolean>({ name, type: 'checkbox' })

  const { value, onChange, onBlur } = field

  return <Switch checked={value} onChange={onChange} onBlur={onBlur} />
}

export type StyledToggleFieldProps = ToggleFieldProps &
  Omit<ToggleProps, 'checked' | 'onChange'>

export const StyledToggleField: FC<StyledToggleFieldProps> = (props) => {
  const { name, ...otherProps } = props
  const [field] = useField<boolean>({ name, type: 'checkbox' })
  const { value, onChange, onBlur } = field
  const sx = useMemo(() => getStyles(otherProps), [otherProps])

  return (
    <Switch
      checked={value}
      onChange={onChange}
      onBlur={onBlur}
      sx={sx}
      name={name}
      disableRipple
    />
  )
}
