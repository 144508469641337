import * as Yup from 'yup'
import { latinWithSpecial, notOnlySpaces } from '../../utils/validators'
import { ADD_VEHICLE_FIELD_NAMES } from './constants'
import { optionalLicensePlateValidationPattern } from './screens/EditNewVehicleDetails/Controls/LicensePlateFieldSet/validationSchema'

const optionScheme = {
  name: Yup.string(),
  id: Yup.string()
}

const stockNumberValidationPattern = Yup.string().test(
  'is-latin-or-special',
  'Enter a valid data format.',
  latinWithSpecial([' '])
)

const stockNumberRequiredValidationPattern =
  stockNumberValidationPattern.required('')

export const getValidationSchema = (
  isDealer: boolean,
  hasExteriorColors: boolean,
  hasInteriorColors: boolean
) =>
  Yup.object({
    [ADD_VEHICLE_FIELD_NAMES.LICENSE_PLATE]:
      optionalLicensePlateValidationPattern,
    [ADD_VEHICLE_FIELD_NAMES.ADDRESS]: Yup.object(optionScheme).required(''),
    [ADD_VEHICLE_FIELD_NAMES.STOCK_NUMBER]: isDealer
      ? stockNumberRequiredValidationPattern
      : stockNumberValidationPattern,
    [ADD_VEHICLE_FIELD_NAMES.EXTERIOR_COLOR]: hasExteriorColors
      ? Yup.object(optionScheme).required('').nullable()
      : Yup.string()
          .test(
            'is-latin-or-special',
            'Enter a latin data format.',
            latinWithSpecial(['\n', ' ', '€', '£'])
          )
          .test(
            'not-only-spaces',
            'Enter a valid data format.',
            notOnlySpaces()
          )
          .required('')
          .nullable(),
    [ADD_VEHICLE_FIELD_NAMES.INTERIOR_COLOR]: hasInteriorColors
      ? Yup.object(optionScheme).required('').nullable()
      : Yup.string()
          .test(
            'is-latin-or-special',
            'Enter a valid data format.',
            latinWithSpecial(['\n', ' ', '€', '£'])
          )
          .test(
            'not-only-spaces',
            'Enter a valid data format.',
            notOnlySpaces()
          )
          .required('')
          .nullable(),
    [ADD_VEHICLE_FIELD_NAMES.CURRENT_MILEAGE]: Yup.string().required(''),
    [ADD_VEHICLE_FIELD_NAMES.CONDITION]: Yup.object(optionScheme).required(''),
    [ADD_VEHICLE_FIELD_NAMES.HOW_MUCH_IS_OWED]: Yup.string(),
    [ADD_VEHICLE_FIELD_NAMES.NOTES]: Yup.string().test(
      'is-latin-or-special',
      'Enter a valid data format.',
      latinWithSpecial(['\n', ' ', '€', '£'])
    ),
    [ADD_VEHICLE_FIELD_NAMES.TRADE_IN_VALUE]: Yup.string().required(''),
    [ADD_VEHICLE_FIELD_NAMES.RETAIL_VALUE]: Yup.string().required('')
  })
