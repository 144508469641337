import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { splitByProperty } from '../../../utils/splitByProperty'
import { logout } from '../../thunks/logout'
import { pageNavigation } from '../../thunks/pageNavigation'
import { NormalizedReadInventoryItem } from '../../../api/carSnoopApi/accounts/types'

interface DealerHomepageState {
  currentTabID: number
  userVehicles: {
    requested: NormalizedReadInventoryItem[]
    offered: NormalizedReadInventoryItem[]
    underContract: NormalizedReadInventoryItem[]
  }
}

export const initialState: DealerHomepageState = {
  currentTabID: 0,
  userVehicles: {
    requested: [],
    offered: [],
    underContract: []
  }
}

export const dealerHomepageSlice = createSlice({
  name: 'dealerHomepage',
  initialState,
  reducers: {
    setCurrentTabID: (store, action: PayloadAction<number>) => {
      store.currentTabID = action.payload
    },
    setUserVehiclesList: (
      store,
      action: PayloadAction<NormalizedReadInventoryItem[]>
    ) => {
      const [requested, offered, underContract] = splitByProperty(
        action.payload,
        {
          status: ['Requested', 'Offered', 'Under Contract']
        }
      )
      store.userVehicles = {
        requested,
        offered,
        underContract
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(pageNavigation.fulfilled, () => {
      return initialState
    })
    builder.addCase(logout.fulfilled, () => {
      return initialState
    })
  }
})
