import { Grid, Typography } from '@mui/material'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ActionBlock } from '../../atoms/ActionBlock'
import { FixedContainer } from '../../atoms/FixedContainer'
import { Show } from '../../atoms/JSXExtensions'
import { Loader } from '../../atoms/Loader'
import { PaymentRedirect } from '../../features/PaymentRedirect'
import { PageContainer } from '../../layout/PageContainer'
import { DiscardConfirmationModal } from '../../molecules/Modal'
import { routes } from '../../routes'
import { COLORS, SHADOWS } from '../../types/colors'
import { useBlockPath } from '../../utils/NavigationController/hooks/useBlockPath'
import { useModal, useUserInfo } from '../../utils/hooks'
import { BuyerDetailsForm } from './forms/BuyerDetailsForm'
import { CobuyerMicroform } from './forms/CobuyerMicroform'
import { PaymentMicroform } from './forms/PaymentMicroform'
import { useOfferForm } from './hooks/useOfferForm'
import { useRestoreAcceptOfferForm } from './hooks/useRestoreAcceptOfferForm'

export const OfferPage: FC = () => {
  const { fullName, addresses, isDealer, dealershipName } = useUserInfo()
  const navigate = useNavigate()
  const { transactionId } = useParams()

  const {
    isNavigateConfirmationOpen,
    setIsNavigateConfirmationOpen,
    onNavigationConfirm
  } = useBlockPath()

  const {
    setCobuyerMicroformRef,
    setBuyerMicroformRef,
    getFormValues,
    paymentMicroformRef,
    isFormValid
  } = useOfferForm()

  const {
    isPaymentOpen,
    isRestoring,
    restoredData,
    storeData,
    clearData,
    getPaymentRedirectUrl,
    handleRedirectFail,
    redirectToPayment
  } = useRestoreAcceptOfferForm(transactionId)

  const handleConfirmOffer = useCallback(async () => {
    storeData(getFormValues())

    redirectToPayment()
  }, [getFormValues, redirectToPayment])

  useEffect(() => () => clearData(), [])

  const handleDiscardOffer = useCallback(() => {
    // We cant restore location.state after redirect. Actually we can^ but it's possible through localStorage, so...
    const backPath = generatePath(routes.offers.details, {
      transactionId
    })
    navigate(backPath)
  }, [transactionId])

  const discardConfirmationModal = useModal(handleDiscardOffer)

  const handleCancelOffer = useCallback(() => {
    discardConfirmationModal.setOpen(true)
  }, [discardConfirmationModal])

  // && doesn't work. Type Inference is broken :`(
  const cobuyerMicroformDefaultValues = restoredData?.cobuyerMicroform
    ? restoredData.cobuyerMicroform
    : undefined

  const buyerName = useMemo(
    () => (isDealer ? dealershipName : fullName),
    [isDealer, dealershipName, fullName]
  )

  const getRedirectUrl = useCallback(() => {
    const itemsForPaying = [
      {
        amount: 0.0,
        productName: 'Deposit',
        productDescription: 'Initial Deposit to Hold the Vehicle'
      }
    ]

    return getPaymentRedirectUrl(itemsForPaying)
  }, [getPaymentRedirectUrl])

  return (
    <PageContainer paddingBottom='140px'>
      <Grid container mb='32px'>
        <Typography component='h1' variant='title1'>Accept the offer</Typography>
      </Grid>
      <DiscardConfirmationModal
        isOpen={isNavigateConfirmationOpen}
        setOpen={setIsNavigateConfirmationOpen}
        onSubmit={onNavigationConfirm}
      />
      <Show when={!isRestoring} fallback={<Loader />}>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <BuyerDetailsForm
              defaultValues={restoredData?.buyerDetailsForm}
              formRef={setBuyerMicroformRef}
              fullName={buyerName}
              address={addresses[0]}
            />
          </Grid>
          <Show when={!isDealer}>
            <Grid item xs={12}>
              <Grid item xs={12} lg={6}>
                <CobuyerMicroform
                  defaultValues={cobuyerMicroformDefaultValues}
                  formRef={setCobuyerMicroformRef}
                />
              </Grid>
            </Grid>
          </Show>
          <Grid item xs={12}>
            <PaymentMicroform
              defaultValues={restoredData?.paymentMicroform}
              formRef={paymentMicroformRef}
            />
          </Grid>
        </Grid>
      </Show>
      <FixedContainer
        flexDirection='row-reverse'
        bottom
        p={3}
        background={COLORS.white}
        boxShadow={SHADOWS.modal}
      >
        <Grid item mt={4} width='100%'>
          <ActionBlock
            schema={[
              {
                label: 'cancel',
                type: 'secondary',
                onClick: handleCancelOffer
              },
              {
                label: 'Continue',
                type: 'primary',
                disabled: !isFormValid,
                onClick: handleConfirmOffer
              }
            ]}
          />
        </Grid>
      </FixedContainer>
      <DiscardConfirmationModal {...discardConfirmationModal} />
      <PaymentRedirect
        open={isPaymentOpen}
        onRedirectFail={handleRedirectFail}
        getPaymentRedirectUrl={getRedirectUrl}
      />
    </PageContainer>
  )
}
