import { Grid } from '@mui/material'
import { FC } from 'react'
import { AddressHeader } from './components'
import { AddressInfo } from './components/AddressInfo'
import { AddShipmentDetailsParamsAddress } from '../../../api/carSnoopApi/shipments/types'

interface IDeliveryAddresses {
  pickUpAddress: AddShipmentDetailsParamsAddress
  dropOffAddress: AddShipmentDetailsParamsAddress
  isDelivered?: boolean
  onDropOffEdit?: (address: AddShipmentDetailsParamsAddress) => void
  onPickupEdit?: (address: AddShipmentDetailsParamsAddress) => void
}

export const DeliveryAddresses: FC<IDeliveryAddresses> = ({
  pickUpAddress,
  dropOffAddress,
  isDelivered = false,
  onDropOffEdit,
  onPickupEdit
}) => {
  return (
    <Grid container>
      <Grid
        item
        container
        flexDirection='row'
        spacing={2}
        alignItems='flex-start'
        flexWrap='nowrap'
      >
        <Grid item xs={6}>
          <Grid container flexDirection='column'>
            <Grid item>
              <AddressHeader
                onEditClick={onPickupEdit}
                headerTitle='Pickup Address'
                address={pickUpAddress}
              />
            </Grid>
            <Grid item>
              <AddressInfo address={pickUpAddress} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container flexDirection='column'>
            <Grid item>
              <AddressHeader
                onEditClick={onDropOffEdit}
                headerTitle='Drop-off Address'
                address={dropOffAddress}
                isDelivered={isDelivered}
                isDropOff
              />
            </Grid>
            <Grid item>
              <AddressInfo address={dropOffAddress} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
