import { ColorOption } from '../../types/Autoselect'
import { OrNull } from '../../types/OrNull'
import { ReferenceModel } from '../../types/ReferenceModel'
import { VehicleCondition, VehicleConditionMap } from '../../types/status'

export enum EDIT_VEHICLE_FIELD_NAMES {
  ADDRESS = 'address',
  LICENSE_PLATE = 'licensePlate',
  STATE = 'state',
  EXTERIOR_COLOR = 'exteriorColor',
  INTERIOR_COLOR = 'interiorColor',
  CURRENT_MILEAGE = 'currentMileage',
  CONDITION = 'condition',
  HOW_MUCH_IS_OWED = 'howMuchIsOwed',
  NOTES = 'notes',
  TRADE_IN_VALUE = 'tradeInValue',
  RETAIL_VALUE = 'retailValue'
}

export enum EDIT_VEHICLE_FIELD_LABELS {
  ADDRESS = 'Address',
  LICENSE_PLATE = 'License Plate:',
  STATE = 'State',
  EXTERIOR_COLOR = 'Exterior Color',
  INTERIOR_COLOR = 'Interior Color',
  CURRENT_MILEAGE = 'Current Mileage',
  CONDITION = 'Condition',
  HOW_MUCH_IS_OWED = 'How Much is Owed',
  NOTES = 'Notes',
  TRADE_IN_VALUE = 'Trade in Value',
  RETAIL_VALUE = 'Retail Value'
}

export interface EditVehicleFormFieldsTypes {
  [EDIT_VEHICLE_FIELD_NAMES.ADDRESS]: OrNull<ReferenceModel>
  [EDIT_VEHICLE_FIELD_NAMES.LICENSE_PLATE]: string
  [EDIT_VEHICLE_FIELD_NAMES.STATE]: ReferenceModel
  [EDIT_VEHICLE_FIELD_NAMES.EXTERIOR_COLOR]: OrNull<ColorOption> | string
  [EDIT_VEHICLE_FIELD_NAMES.INTERIOR_COLOR]: OrNull<ColorOption> | string
  [EDIT_VEHICLE_FIELD_NAMES.CURRENT_MILEAGE]: string
  [EDIT_VEHICLE_FIELD_NAMES.CONDITION]: ReferenceModel
  [EDIT_VEHICLE_FIELD_NAMES.HOW_MUCH_IS_OWED]: string
  [EDIT_VEHICLE_FIELD_NAMES.NOTES]: string
  [EDIT_VEHICLE_FIELD_NAMES.TRADE_IN_VALUE]: string
  [EDIT_VEHICLE_FIELD_NAMES.RETAIL_VALUE]: string
}

export const VehicleConditionOptions: ReferenceModel<VehicleCondition>[] = [
  { id: 'Extra Clean', name: VehicleConditionMap['Extra Clean'] },
  { id: 'Clean', name: VehicleConditionMap.Clean },
  { id: 'Average', name: VehicleConditionMap.Average },
  { id: 'Rough', name: VehicleConditionMap.Rough }
]

export interface UpdateVehiclePropsType {
  licensePlate: string
  licensePlateState: string
  exteriorColor: string
  interiorColor: string
  currentMileage: number
  condition: string
  notes: string
  retailValue: number
  tradeInValue: number
  addressId: string
  amountOwed: number
}
