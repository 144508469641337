import type { Option } from '../../../../types/Autoselect'
import { stringToReferenceModel } from '../../../../utils/autoselect/stringToReferenceModel'
import type { ReadYearsResponse } from '../thunks'

export const yearsResponseToOption = (
  response: ReadYearsResponse
): Option[] => {
  const { data } = response
  return data.map((maker) => stringToReferenceModel(`${maker.year}`))
}
