import { RawCreateMessageResponse } from './raw'
import { NormalizedCreateMessageResponse } from './normalized'

export const formatCreateMessageResponse = ({
  data
}: RawCreateMessageResponse): NormalizedCreateMessageResponse => {
  return {
    transactionId: data.transaction_id,
    id: data.id,
    fromUser: data.from_user,
    message: data.message,
    sentOn: new Date(data.sent_on),
    hasRead: data.has_read,
    url: data.url,
    documentId: data.document_id
  }
}
