import { NormalizedVehicleType, RawVehicleType } from '../types'

export const vehicleMapper = (
  vehicle: RawVehicleType
): NormalizedVehicleType => {
  return {
    id: vehicle.id,
    year: vehicle.year,
    make: vehicle.make,
    model: vehicle.model,
    trim: vehicle.trim,
    vin: vehicle.vin,
    stockNumber: vehicle.stock_number,
    mileage: vehicle.mileage,
    status: vehicle.status,
    createdOn: new Date(vehicle.created_on),
    createdBy: vehicle.created_by,
    changedOn: vehicle.changed_on ? new Date(vehicle.changed_on) : null,
    changedBy: vehicle.changed_by,
    condition: vehicle.condition,
    accountID: vehicle.account_id,
    addressID: vehicle.address_id,
    retailValue: vehicle.retail_value,
    tradeInValue: vehicle.tradein_value,
    notes: vehicle.notes,
    standardEquipment: vehicle.standard_equipment,
    fuelType: vehicle.fuel_type,
    engineDescription: vehicle.engine_description,
    transmission: vehicle.transmission,
    mpg: vehicle.mpg,
    style: vehicle.style,
    interiorColor: vehicle.interior_color,
    exteriorColor: vehicle.exterior_color,
    drivetrain: vehicle.drivetrain,
    licensePlate: vehicle.license_plate,
    licensePlateState: vehicle.license_plate_state,
    deleted: vehicle.deleted,
    city: vehicle.city,
    state: vehicle.state,
    zipCode: vehicle.zip_code,
    amountOwed: vehicle.amount_owed,
    documentContent: vehicle.document_content,
    shippingCost: vehicle.shipping_cost,
    distance: vehicle.distance
  }
}
