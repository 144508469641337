import { useApiContext, useMakeService } from '../../../utils/hooks'
import { PaymentApi } from './types'

export const useCreatePaymentCheckout = () => {
  const { externalCarSnoopApi } = useApiContext()

  const [createPaymentCheckout, createPaymentCheckoutObservable] =
    useMakeService(
      async (params: PaymentApi.CreatePaymentCheckout.Params) => {
        const response =
          await externalCarSnoopApi.payment.createPaymentCheckout(params)

        return response
      },
      { includeCheckAccess: true }
    )

  return {
    createPaymentCheckoutObservable,
    createPaymentCheckout
  }
}
