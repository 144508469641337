import { subYears } from 'date-fns'
import { ReferenceModel } from '../types/ReferenceModel'
import { StatesList } from '../types/states'
import { stringToReferenceModel } from '../utils/autoselect/stringToReferenceModel'

export const MIN_AGE = 16
export const MAX_AGE = 125

export const MAX_TEXT_ROWS_SIZE = 4
export const PER_PAGE = 4
export const MAX_IMAGES = 28
export const MAX_PHONE_LENGTH = 10

export const MAX_PHOTO_SIZE_MB = 0.09765
export const MIN_IMG_SIZE_FOR_COMPRESSION_KB = 100
export const MAX_PDF_SIZE_MB = 10
export const MAX_VIDEO_SIZE_MB = 50
export const MAX_IMG_COMPRESSION_ITERATIONS = 100

export const MAX_DATE = subYears(new Date(), MIN_AGE)
export const MIN_DATE = subYears(new Date(), MAX_AGE)

export const phoneNumberLength = 10

export const passwordLength = {
  min: 8,
  max: 20
}

export const MMDDYYYY = 'MM.dd.yyyy'
export const YYYYMMDD = 'yyyy-MM-dd'
export const MMDDYYYYSlash = 'MM/dd/yyyy'

export const chatDatesFormatters = {
  time: 'hh:mm a',
  date: 'MM/dd/yyyy',
  default: 'MM/dd/yyyy, hh:mm a'
}

export const StateOptions: ReferenceModel[] = StatesList.map(
  stringToReferenceModel
)

export enum FILE_EXTENSIONS {
  jpeg = 'jpeg',
  jpg = 'jpg',
  png = 'png',
  gif = 'gif',
  svg = 'svg',
  mp3 = 'mp3',
  mpeg = 'mpeg',
  mp4 = 'mp4',
  webm = 'webm',
  wav = 'wav',
  pdf = 'pdf',
  mov = 'mov',
  avi = 'avi',
  heic = 'heic',
  heif = 'heif',
  unknown = 'unknown'
}

export const TEXT_MESSAGE_TYPE = 'textMessage'

export const availableImageFormats = [
  FILE_EXTENSIONS.jpeg,
  FILE_EXTENSIONS.jpg,
  FILE_EXTENSIONS.png,
  FILE_EXTENSIONS.heic,
  FILE_EXTENSIONS.heif
]

export const phoneNumberMask = '(XXX) XXX - XXXX'

export const SUPPORT_EMAIL = 'support@carsnoop.com'

export const CONVERT_TIME = {
  MILISECONDS_IN_SECOND: 1000,
  SECONDS_IN_MINUTE: 60,
  MINUTES_IN_HOUR: 60,
  HOURS_IN_DAY: 24
}

// For UI
export enum ShipmentTypes {
  local = 'Local delivery',
  shipping = 'Shipment'
}

// For BE interactions
export enum INTERNAL_SHIPMENT_TYPE {
  local = 'local',
  shipping = 'service'
}

export enum PAYMENT_TYPES {
  deposit = 'Deposit',
  balance = 'Balance',
  shipping = 'Shipping'
}

export const FLOW_SEARCH_PARAM = 'flow'

export enum FLOW_PARAM {
  stripe = 'stripe',
  nonStripe = 'nonStripe'
}

export const AGREEMENT_DOCUMENT_NAME = 'CarSnoop Purchase Agreement'

export const SIGNED_AGREEMENT_DOCUMENT_NAME =
  'Signed CarSnoop Purchase Agreement'
