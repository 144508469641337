import { FC, MouseEventHandler, useMemo } from 'react'
import { Form, Formik } from 'formik'

import { Grid } from '@mui/material'

import { TextInputFieldNew } from '../../atoms/TextInputFieldNew'
import { DatePicker } from '../../atoms/DatePicker'
import { useUserInfo } from '../../utils/hooks'
import { validationSchema } from './validationSchema'
import { MAX_DATE } from '../../constants/applicationConstants'
import {
  PersonalDetailsFormFieldsTypes,
  FIELDS_NAMES,
  FIELDS_LABELS
} from './types'
import { PhoneInputFieldNew } from '../../atoms/PhoneInputField'
import { AgreeButton, CancelButton } from '../../atoms/Button'
import { isRequiredFormField } from '../../utils/validators/isRequiredFormField'

export type PersonalDetailsFormProps = {
  onSave: (values: PersonalDetailsFormFieldsTypes) => void
  onCancel: MouseEventHandler
}

// TODO: ui-components Add to Components demo page (optional)
export const PersonalDetailsForm: FC<PersonalDetailsFormProps> = (props) => {
  const { onSave, onCancel } = props
  const { firstName, lastName, phone, dateOfBirth, email } = useUserInfo()

  const dob = useMemo(() => new Date(dateOfBirth), [dateOfBirth])
  const isRequiredField = isRequiredFormField(validationSchema)

  return (
    <Grid
      container
      item
      spacing='14px'
      sx={{
        pl: '32px'
      }}
    >
      <Grid item xs={12}>
        <Formik<PersonalDetailsFormFieldsTypes>
          initialValues={{
            firstName,
            lastName,
            dateOfBirth: dob,
            email,
            phone
          }}
          validationSchema={validationSchema}
          onSubmit={onSave}
          validateOnMount
          validateOnChange={false}
        >
          {(formik) => {
            return (
              <Form>
                <Grid container spacing='16px'>
                  <Grid item xs={12}>
                    <TextInputFieldNew
                      name={FIELDS_NAMES.FIRST_NAME}
                      label={FIELDS_LABELS.FIRST_NAME}
                      required={isRequiredField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInputFieldNew
                      name={FIELDS_NAMES.LAST_NAME}
                      label={FIELDS_LABELS.LAST_NAME}
                      required={isRequiredField}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <DatePicker
                      name={FIELDS_NAMES.DATE_OF_BIRTH}
                      label={FIELDS_LABELS.DATE_OF_BIRTH}
                      newFieldVariant
                      value={formik.values.dateOfBirth}
                      maxDate={MAX_DATE}
                      isError={!!formik.errors.dateOfBirth}
                      onChange={(value) => {
                        formik.setFieldValue(FIELDS_NAMES.DATE_OF_BIRTH, value)
                      }}
                      onBlur={() => {
                        formik.validateField(FIELDS_NAMES.DATE_OF_BIRTH)
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextInputFieldNew
                      name={FIELDS_NAMES.EMAIL}
                      label={FIELDS_LABELS.EMAIL}
                      disabled
                      required={isRequiredField}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <PhoneInputFieldNew
                      name={FIELDS_NAMES.PHONE}
                      label={FIELDS_LABELS.PHONE}
                      required={isRequiredField}
                    />
                  </Grid>

                  <Grid item xs={12} />
                  <Grid item xs={12} />
                  <Grid item xs={12} />

                  <Grid
                    container
                    item
                    xs={12}
                    justifyContent='flex-end'
                    spacing={2}
                  >
                    <Grid item>
                      <CancelButton onClick={onCancel}>Cancel</CancelButton>
                    </Grid>
                    <Grid item>
                      <AgreeButton
                        type='submit'
                        disabled={!formik.dirty || !formik.isValid}
                      >
                        Save
                      </AgreeButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )
          }}
        </Formik>
      </Grid>
    </Grid>
  )
}
