import { FC } from 'react'
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material'

interface VehicleComponentDetailsItemProps {
  title: string
  value: string
}

export const VehicleComponentDetailsItem: FC<
  VehicleComponentDetailsItemProps
> = ({ title, value }) => {
  const theme = useTheme()
  const isMinWidthTablet = useMediaQuery(theme.breakpoints.between('xs', 1000))
  const isMinWidthLaptop = useMediaQuery(theme.breakpoints.between('lg', 1500))

  return (
    <Grid
      item
      container
      spacing={0.5}
      sx={{
        flexDirection: isMinWidthTablet || isMinWidthLaptop ? 'column' : 'row'
      }}
    >
      <Grid item>
        <Typography
          variant='body2'
          color='textSecondary'
          textTransform='uppercase'
        >
          {title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant='body2'>{value}</Typography>
      </Grid>
    </Grid>
  )
}
