import { FC, MouseEventHandler, PropsWithChildren, ReactNode } from 'react'
import { Button, Grid, SxProps } from '@mui/material'
import { CloseIcon } from '../../assets/icons/Close'
import { Condition } from '../Condition'
import { CardContainer, CardFooter, CardHeader } from './Card.styles'

export type CardProps = {
  header: ReactNode
  footer?: ReactNode
  withoutCloseButton?: boolean
  onCloseClick?: MouseEventHandler
  sx?: SxProps
  headerSx?: SxProps
} & PropsWithChildren

export const Card: FC<CardProps> = (props) => {
  const {
    header,
    withoutCloseButton,
    onCloseClick,
    children,
    footer,
    sx = {},
    headerSx = {}
  } = props

  const renderCloseButton = !withoutCloseButton

  return (
    <CardContainer container sx={sx}>
      <CardHeader container item xs={12} wrap='nowrap' sx={headerSx}>
        {header}
        <Condition
          condition={renderCloseButton}
          trueContent={
            <Grid
              container
              item
              xs={2}
              justifyContent='flex-end'
              alignItems='center'
            >
              <Button
                disableElevation
                variant='text'
                sx={{ minWidth: 0, padding: 0 }}
                onClick={onCloseClick}
              >
                <CloseIcon />
              </Button>
            </Grid>
          }
        />
      </CardHeader>
      {children}
      {footer && <CardFooter>{footer}</CardFooter>}
    </CardContainer>
  )
}
