import type { Option } from '../../../../types/Autoselect'
import { stringToReferenceModel } from '../../../../utils/autoselect/stringToReferenceModel'
import type { ReadMakersResponse } from '../thunks'

export const makersResponseToOption = (
  response: ReadMakersResponse
): Option[] => {
  const { data } = response
  return data.map((maker) => stringToReferenceModel(maker.make))
}
