import { useCallback } from 'react'
import { ERROR_REASONS } from '../../../../types/enums/network'
import { ResponseStatusMap } from '../../../../types/status'
import { ERRORS, MyTeamAddUsersFormValues } from './types'
import { useApiContext } from '../../../../utils/hooks'

export const getLastEmail = <T>(
  arr: T[]
): { lastEmail: T; lastEmailIndex: number } => {
  const lastEmailIndex = arr.length - 1
  const lastEmail = arr[lastEmailIndex]
  return { lastEmail, lastEmailIndex }
}

export const getEmailsError = <T>(arr: T[], errorText: string): string[] => {
  const { lastEmailIndex } = getLastEmail(arr)
  const errors = new Array(arr.length)
  errors[lastEmailIndex] = errorText

  return errors
}

export const useCheckIfEmailExist = () => {
  const { carSnoopApi } = useApiContext()

  return useCallback(
    async (email: string) => {
      const res = await carSnoopApi.users.checkUser(email)

      if (
        res.status === ResponseStatusMap.Error &&
        res.reason === ERROR_REASONS.EXIST_NOT_VERIFIED
      ) {
        return true
      }

      return res.status === ResponseStatusMap.Success
    },
    [carSnoopApi]
  )
}

export const validateEmails = async (
  emails: MyTeamAddUsersFormValues['emails'],
  checkIfEmailExist: (email: string) => Promise<boolean>
) => {
  const { lastEmail } = getLastEmail(emails)

  const isEmailExist = await checkIfEmailExist(lastEmail)

  return isEmailExist ? getEmailsError(emails, ERRORS.ALREADY_EXIST) : undefined
}
