import { NormalizedReadInventoryItem } from '../../../../api/carSnoopApi/accounts/types'
import { OrNull } from '../../../../types/OrNull'
import { DisplayedTradeinValues } from '../types'

export const updateTradeinValues = (
  tradeInVehicle: OrNull<NormalizedReadInventoryItem>,
  displayedTradeinValues: DisplayedTradeinValues
): OrNull<NormalizedReadInventoryItem> => {
  const { mileage, tradeInValue, id, documentContent } = displayedTradeinValues
  return tradeInVehicle?.id === id
    ? { ...tradeInVehicle, id, mileage, tradeInValue, documentContent }
    : tradeInVehicle
}
