import { FC, useMemo } from 'react'
import { Grid, Typography } from '@mui/material'
import { ExclamationSpeechBubble } from '../../../../../../assets/icons/ExclamationSpeechBubble'
import { SpeedometerIcon } from '../../../../../../assets/icons/Speedometer'
import { Condition } from '../../../../../../atoms/Condition'
import { formatNumber } from '../../../../../../utils/formatters/formatNumber'
import { RequestedVehicleDetails } from '../../../useRequestedVehicles'
import { EllipsisText } from '../../../../../../atoms/EllipsisText'
import { formatPrice } from '../../../../../../utils'
import { VehicleComponentDetailsItem } from '../../../../../../atoms/VehicleComponentDetailsItem'

export interface DealsDetailsProps extends RequestedVehicleDetails {
  requestsCount: number
}

export const Details: FC<DealsDetailsProps> = ({
  requestsCount,
  mileage,
  stockNumber,
  retailValue
}) => {
  const formattedMileage = useMemo(() => {
    return `${formatNumber(mileage)} miles`
  }, [mileage])

  const formattedRetailValue = useMemo(() => {
    return `${formatPrice(retailValue || 0)}`
  }, [retailValue])

  return (
    <Grid container item flexDirection='column' spacing={1}>
      <Grid item container spacing={1} flexWrap='nowrap'>
        <Condition
          condition={!!stockNumber}
          trueContent={
            <Grid container item flexWrap='nowrap' xs={6}>
              <Grid
                item
                display='flex'
                alignItems='center'
                sx={{ mr: 0.5 }}
                flexWrap='nowrap'
              >
                <Typography variant='body2' color='textSecondary'>
                  №
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant='subtitle1'>
                  <EllipsisText tooltipText={stockNumber}>
                    {stockNumber}
                  </EllipsisText>
                </Typography>
              </Grid>
            </Grid>
          }
        />
        <Grid container item flexWrap='nowrap'>
          <Grid item display='flex' alignItems='center' sx={{ mr: 0.5 }}>
            <SpeedometerIcon />
          </Grid>
          <Grid item xs={6}>
            <Typography variant='subtitle1' whiteSpace='nowrap'>
              <EllipsisText tooltipText={formattedMileage}>
                {formattedMileage}
              </EllipsisText>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <VehicleComponentDetailsItem
        title='Retail value:'
        value={formattedRetailValue}
      />
      <Grid item container spacing={0.5} alignItems='flex-start'>
        <Grid item>
          <ExclamationSpeechBubble />
        </Grid>
        <Grid item>
          <Typography
            variant='body2'
            color='textSecondary'
            textTransform='uppercase'
          >
            {requestsCount} open requests
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
