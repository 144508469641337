import { ContractViewStates } from './types'
import { NormalizedTransactionType } from '../../../../api/carSnoopApi/transactions/types'

export type StatusResolverStatuses = Extract<
  | 'Under Contract'
  | 'Awaiting Payment'
  | 'Shipping'
  | 'Payment Failed'
  | 'Delivered',
  NormalizedTransactionType['status']
>

type StatusResolverInterface = {
  resolve: (status: StatusResolverStatuses) => ContractViewStates
}

class StatusResolver implements StatusResolverInterface {
  private readonly isBuyer: boolean
  private readonly isStripeFlow: boolean
  constructor(isBuyer: boolean, isStripeFlow: boolean) {
    this.isBuyer = isBuyer
    this.isStripeFlow = isStripeFlow
  }

  resolve(status: string) {
    if (status in this) {
      return this[status as StatusResolverStatuses]
    }
    return ContractViewStates.UNKNOWN
  }

  private get ['Under Contract']() {
    return ContractViewStates.UNDER_CONTRACT
  }

  private get ['Awaiting Payment']() {
    if (this.isBuyer) {
      if (this.isStripeFlow) {
        return ContractViewStates.BUYER_STRIPE_AWAITING_PAYMENT
      }
      return ContractViewStates.BUYER_NON_STRIPE_AWAITING_PAYMENT
    }

    if (this.isStripeFlow) {
      return ContractViewStates.SELLER_STRIPE_AWAITING_PAYMENT
    }

    return ContractViewStates.SELLER_NON_STRIPE_AWAITING_PAYMENT
  }

  private get Shipping() {
    if (this.isBuyer) {
      if (this.isStripeFlow) {
        return ContractViewStates.BUYER_STRIPE_SHIPPING
      }

      return ContractViewStates.BUYER_NON_STRIPE_SHIPPING
    }
    if (this.isStripeFlow) {
      return ContractViewStates.SELLER_STRIPE_SHIPPING
    }

    return ContractViewStates.SELLER_NON_STRIPE_SHIPPING
  }

  private get ['Payment Failed']() {
    return ContractViewStates.PAYMENT_FAILED
  }

  private get Delivered() {
    if (this.isBuyer) {
      return ContractViewStates.BUYER_COMPLETED
    }

    return ContractViewStates.SELLER_COMPLETED
  }
}

interface ResolveStateByStateCodeProps {
  status: NormalizedTransactionType['status']
  isBuyer: boolean
  isStripeFlow: boolean
}

export const resolveStateByStateCode = ({
  isBuyer,
  isStripeFlow,
  status
}: ResolveStateByStateCodeProps) => {
  const statusMachine = new StatusResolver(isBuyer, isStripeFlow)

  return statusMachine.resolve(status)
}
