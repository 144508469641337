import * as Yup from 'yup'
import { latinWithSpecial, notOnlySpaces } from '../../utils/validators'
import { EDIT_VEHICLE_FIELD_NAMES } from './constants'

const optionScheme = {
  name: Yup.string(),
  id: Yup.string()
}

const licensePlateValidationPattern = Yup.string().test(
  'is-latin-or-special',
  'Enter a valid data format.',
  latinWithSpecial(['-'], false)
)
const licensePlateRequiredValidationPattern =
  licensePlateValidationPattern.required('')
const licensePlateStateValidationPattern = Yup.object(optionScheme)
const licensePlateStateValidationRequiredPattern =
  licensePlateStateValidationPattern.required('')

export const getValidationSchema = (
  hasLicencePlate: boolean,
  hasExteriorColors: boolean,
  hasInteriorColors: boolean
) =>
  Yup.object({
    [EDIT_VEHICLE_FIELD_NAMES.ADDRESS]: Yup.object(optionScheme).required(''),
    [EDIT_VEHICLE_FIELD_NAMES.LICENSE_PLATE]: hasLicencePlate
      ? licensePlateRequiredValidationPattern
      : licensePlateValidationPattern,
    [EDIT_VEHICLE_FIELD_NAMES.STATE]: hasLicencePlate
      ? licensePlateStateValidationRequiredPattern
      : licensePlateStateValidationPattern,
    [EDIT_VEHICLE_FIELD_NAMES.EXTERIOR_COLOR]: hasExteriorColors
      ? Yup.object(optionScheme).required('')
      : Yup.string()
          .required('')
          .test(
            'is-latin-or-special',
            'Enter a latin data format.',
            latinWithSpecial(['\n', ' ', '€', '£'])
          )
          .test(
            'not-only-spaces',
            'Enter a valid data format.',
            notOnlySpaces()
          ),
    [EDIT_VEHICLE_FIELD_NAMES.INTERIOR_COLOR]: hasInteriorColors
      ? Yup.object(optionScheme).required('')
      : Yup.string()
          .required('')
          .test(
            'is-latin-or-special',
            'Enter a latin data format.',
            latinWithSpecial(['\n', ' ', '€', '£'])
          )
          .test(
            'not-only-spaces',
            'Enter a valid data format.',
            notOnlySpaces()
          ),
    [EDIT_VEHICLE_FIELD_NAMES.CURRENT_MILEAGE]: Yup.string().required(''),
    [EDIT_VEHICLE_FIELD_NAMES.CONDITION]: Yup.object(optionScheme).required(''),
    [EDIT_VEHICLE_FIELD_NAMES.HOW_MUCH_IS_OWED]: Yup.string(),
    [EDIT_VEHICLE_FIELD_NAMES.NOTES]: Yup.string().test(
      'is-latin-or-special',
      'Enter a valid data format.',
      latinWithSpecial(['\n', ' ', '€', '£'])
    ),
    [EDIT_VEHICLE_FIELD_NAMES.TRADE_IN_VALUE]: Yup.string().required(''),
    [EDIT_VEHICLE_FIELD_NAMES.RETAIL_VALUE]: Yup.string().required('')
  })
