import { styled, useMediaQuery, useTheme } from '@mui/material'
import Container from '@mui/material/Container'
import { FC, PropsWithChildren, useCallback, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { ApiContext, ApiContextCreator } from '../../apiContext'
import { Condition } from '../../atoms/Condition'
import { Show } from '../../atoms/JSXExtensions'
import { Loader } from '../../atoms/Loader'
import { Snackbar } from '../../atoms/Snackbar'
import { SubscriptionModal } from '../../features/SubscriptionModal'
import { NoInternetScreen } from '../../molecules/NoInternetScreen'
import { SideBarContainer } from '../../molecules/SideBar/SideBarContainer'
import { actionsUserDeviceInfo } from '../../store/features/userDeviceInfo'
import { actionsUserInfo } from '../../store/features/userInfo'
import {
  NavigationControllerContext,
  navigationController
} from '../../utils/NavigationController'
import { useAppDispatch, useDeviceInfo } from '../../utils/hooks'
import { returnSubscriptionFeatureStatus } from '../../utils/returnSubscriptionFeatureStatus'
import { ResponsiveAppBar } from '../ResponsiveAppBar'

const NavOffset = styled('div')(({ theme }) => theme.mixins.toolbar);

interface ApplicationWrapperProps {
  excludeApiContext?: boolean
}

export const ApplicationWrapper: FC<
  PropsWithChildren<ApplicationWrapperProps>
> = ({ children, excludeApiContext = false }) => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const theme = useTheme()
  const isBreakpointDownMd = useMediaQuery(theme.breakpoints.down('md'))
  const { currentScreenWidth, isOnLine } = useDeviceInfo()

  useEffect(() => {
    dispatch(actionsUserDeviceInfo.read())
  }, [location])

  const logoutCallback = useCallback(() => {
    dispatch(actionsUserInfo.logout())
  }, [])

  const apiContextValues = useMemo(() => {
    return new ApiContextCreator(logoutCallback)
  }, [logoutCallback])

  const { isSubscriptionFeatureEnabled } = returnSubscriptionFeatureStatus()

  const wrapperContent = useMemo(
    () => (
      <>
        <SideBarContainer />
        <Snackbar />
        <Show when={isSubscriptionFeatureEnabled}>
          <SubscriptionModal />
        </Show>
        <ResponsiveAppBar />
        <Container component="main" disableGutters={isBreakpointDownMd} maxWidth="xl">
          <NavOffset />
          <Condition
            condition={!currentScreenWidth && isOnLine}
            trueContent={<Loader />}
            falseContent={
              <Condition
                condition={isOnLine}
                trueContent={<>{children}</>}
                falseContent={<NoInternetScreen />}
              />
            }
          />
        </Container>
      </>
    ),
    [
      children,
      currentScreenWidth,
      isBreakpointDownMd,
      isOnLine
    ]
  )

  return (
    <NavigationControllerContext.Provider value={navigationController}>
      <Condition
        condition={excludeApiContext}
        trueContent={wrapperContent}
        falseContent={
          <ApiContext.Provider value={apiContextValues}>
            {wrapperContent}
          </ApiContext.Provider>
        }
      />
    </NavigationControllerContext.Provider>
  )
}
