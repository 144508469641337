export const sortArrPropAlphabetically = <T>(
  arr: T[],
  propName: keyof T
): T[] =>
  arr.sort((a: T, b: T) => {
    if (typeof a[propName] === 'string') {
      return String(a[propName]).localeCompare(String(b[propName]))
    }

    return 0
  })
