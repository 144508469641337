import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Grid, Typography } from '@mui/material'
import { ChangeEvent, FC, useCallback, useLayoutEffect, useState } from 'react'
import { NormalizedReadInventoryItem } from '../../../api/carSnoopApi/accounts/types'
import { BlueButton } from '../../../atoms/Button'
import { Condition } from '../../../atoms/Condition'
import { Loader } from '../../../atoms/Loader'
import { RadioToggle } from '../../../atoms/RadioToggle'
import { useAddVehicleFormSlice } from '../../../routes/AddVehicle/screens/hooks'
import type { TradeInInfo } from '../../../store/types'
import { findCarFormSlice } from '../../../store/units/findCarForm/findCarForm'
import { OrNull } from '../../../types/OrNull'
import { ResponseStatusMap } from '../../../types/status'
import {
  useApiContext,
  useAppDispatch,
  useMakeService,
  useUserInfo
} from '../../../utils/hooks'
import { useBreadcrumbs } from '../../../utils/hooks/useBreadcrumbs'
import { FindCarActionBlock } from '../FindCarSelect/FindCarActionBlock'
import { AddNewVehicleModal } from './components/AddNewVehicleModal'
import { TradeInCarsList } from './components/TradeInCarsList'

interface FindCarTradeInScreenProps {
  actions: typeof findCarFormSlice.actions
  initialValues: TradeInInfo
  onCancel: () => void
}

export const FindCarTradeInScreen: FC<FindCarTradeInScreenProps> = ({
  initialValues,
  actions,
  onCancel
}) => {
  const { carSnoopApi } = useApiContext()
  const { accountID, isDealer } = useUserInfo()
  const dispatch = useAppDispatch()
  const { pushBreadcrumb } = useBreadcrumbs()
  const [inventoryCars, setInventoryCars] = useState<
    NormalizedReadInventoryItem[]
  >([])
  const [showTradeIn, setShowTradeIn] = useState(isDealer ? false : initialValues.showTradeIn)
  const [checkedTradeIn, setCheckedTradeIn] = useState<
    OrNull<NormalizedReadInventoryItem>
  >(initialValues.tradeinVehicle)
  const { openAddVehicleModal, closeAddVehicleModal, isAddVehicleModalOpen } =
    useAddVehicleFormSlice()
  const handleChangeRadio = useCallback(
    (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (checked) {
        setCheckedTradeIn(inventoryCars[0] || null)
      } else {
        setCheckedTradeIn(null)
      }
      setShowTradeIn(checked)
    },
    [setCheckedTradeIn, setShowTradeIn, inventoryCars]
  )

  const onNextClick = useCallback(() => {
    dispatch(
      actions.setTradeInInfo({
        tradeinVehicle: showTradeIn ? checkedTradeIn : null,
        showTradeIn
      })
    )
    dispatch(actions.incrementScreenStep())
  }, [dispatch, actions, checkedTradeIn, showTradeIn])

  const onBackClick = useCallback(() => {
    dispatch(
      actions.setTradeInInfo({
        tradeinVehicle: checkedTradeIn,
        showTradeIn: true
      })
    )
    dispatch(actions.decrementScreenStep())
  }, [dispatch, actions, checkedTradeIn, showTradeIn])

  const [requestInventoryService, inventoryObservable] = useMakeService(
    async () => {
      const response = await carSnoopApi.accounts.readInventory({
        accountID,
        status: 'Available'
      })

      if (response.status === ResponseStatusMap.Success) {
        setInventoryCars(response.inventory)
        setCheckedTradeIn(response.inventory[0] || null)
      }

      return response
    }
  )

  const handleAddVehicle = useCallback(() => {
    pushBreadcrumb({ label: 'Add new vehicle' })
    openAddVehicleModal()
  }, [pushBreadcrumb, openAddVehicleModal])

  useLayoutEffect(() => {
    if (accountID && !isAddVehicleModalOpen) {
      requestInventoryService()
    }
    window.scroll(0, 0)
  }, [accountID, isAddVehicleModalOpen])

  return (
    <>
      <Grid
        container
        item
        gap={2}
        marginBottom={4}
        padding={{ xs: 1, md: 3 }}
        xs={12}
      >
        <Grid
          container
          item
          direction={{ xs: 'column', md: 'row' }}
          gap={{ xs: 0, md: 2 }}
          justifyContent='space-between'
          xs={12}
        >
          <Grid item>
            <Typography variant='label1'>
              Would you like to add a Trade In?
            </Typography>
          </Grid>
          <Grid item>
            <BlueButton
              startIcon={<AddCircleOutlineIcon />}
              onClick={handleAddVehicle}
            >
              Add Vehicle
            </BlueButton>
          </Grid>
        </Grid>
        <Grid item mb={2}>
          <RadioToggle checked={showTradeIn} onChange={handleChangeRadio} />
        </Grid>
        <Condition
          condition={showTradeIn}
          trueContent={
            <Condition
              condition={inventoryObservable.isLoading}
              trueContent={<Loader />}
              falseContent={
                <TradeInCarsList
                  cars={inventoryCars}
                  checkedTradeIn={checkedTradeIn}
                  setCheckedTradeIn={setCheckedTradeIn}
                />
              }
            />
          }
        />
      </Grid>
      <FindCarActionBlock
        nextButtonDisabled={!inventoryCars.length && showTradeIn}
        onCancelClick={onCancel}
        onNextClick={onNextClick}
        onBackClick={onBackClick}
      />
      <AddNewVehicleModal
        isOpen={isAddVehicleModalOpen}
        onClose={closeAddVehicleModal}
      />
    </>
  )
}
