import { ResponseStatusMap } from '../../../types/status'
import { ErrorResponse, SuccessResponse } from '../../private.types'
import { Utils } from '../../utils'

export class Addresses {
  protected readonly utils: Utils
  protected readonly operations = {
    deleteAddress: 'deleteAddress'
  }

  constructor(utils: Utils) {
    this.utils = utils
  }

  async deleteAddress(id: string): Promise<
    | SuccessResponse<{
      id: string
      message: string
    }>
    | ErrorResponse<{
      id: string
      message: string
    }>
  > {
    type APISuccessResponse = {
      id: string
      message: string
    }

    type APIErrorResponse = {
      id: string
      message: string
    }

    const { type, payload } = await this.utils.makeJSONRequest<
      APISuccessResponse,
      APIErrorResponse
    >(this.operations.deleteAddress, { id })

    if (type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        id: payload.id,
        message: payload.message
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: payload.id,
      message: payload.message
    }
  }
}
