import { FC, ReactElement, useCallback, useLayoutEffect, useRef } from 'react'
import { FormikContextType, FormikErrors, useFormikContext } from 'formik'

import { get, identity } from '../../utils'

export interface DependentFieldHocEffect<P, V> {
  (props: P, ctx: FormikContextType<V>, prevValues?: V): void
}

export interface DependentFieldHocParams<P, V> {
  modifyProps?: (props: P, values: V) => P
  deps: (keyof V)[]
}

export interface DependentFieldHocProps<P, V> {
  onChangeEffect?: DependentFieldHocEffect<P, V>
  onBlurEffect?: DependentFieldHocEffect<P, V>
  onDoneEffect?: DependentFieldHocEffect<P, V>
}

export const DependentField =
  <P extends { name: string }, V>(
    Field: FC<P>,
    params: DependentFieldHocParams<P, V>
  ): FC<P & DependentFieldHocProps<P, V>> =>
  (props): ReactElement => {
    const { modifyProps = identity, deps } = params
    const { onChangeEffect, onBlurEffect, onDoneEffect } = props
    // https://github.com/facebook/react/issues/23230
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const prevValues = useRef<V>()
    // https://github.com/facebook/react/issues/23230
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const ctx = useFormikContext<V>()
    const { values } = ctx
    const nextProps = modifyProps(props, values)

    // https://github.com/facebook/react/issues/23230
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLayoutEffect(() => {
      onChangeEffect && onChangeEffect(nextProps, ctx, prevValues.current)
      // Yes! It's working!
      prevValues.current = values
    }, [...deps.map((dep) => get(values, dep))])

    // https://github.com/facebook/react/issues/23230
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const handleBlur = useCallback(async () => {
      const errors = (await ctx.validateForm()) as Awaited<FormikErrors<V>>
      const fieldName = nextProps.name as keyof V
      const isFieldValid = !errors[fieldName]

      onBlurEffect && onBlurEffect(nextProps, ctx, prevValues.current)
      onDoneEffect &&
        isFieldValid &&
        onDoneEffect(nextProps, ctx, prevValues.current)
    }, [nextProps, ctx, prevValues.current])

    return <Field {...nextProps} onBlur={handleBlur} />
  }
