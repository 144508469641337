import { FC, useState } from 'react'
import { Button, Grid } from '@mui/material'
import { SideBar } from '../../../../molecules/SideBar'
import { EnvelopeIcon } from '../../../../assets/icons/EnvelopeIcon'
import { SupportForm } from '../../../../features/Support'
import { SupportFormMainText } from '../../../../features/Support/components/SupportFormMainText'
import {
  buttonText,
  SUPPORT_FINAL_TEXT_HEADER,
  SUPPORT_FINAL_TEXT_MESSAGE
} from '../../../../features/Support/constants'

export const ContactSupportSideBar: FC = () => {
  const [isOpen, setIsOpen] = useState(true)

  const onOpen = () => {
    setIsOpen(true)
  }

  const onClose = () => {
    setIsOpen(false)
  }

  const email = 'JohnDoe@gmail.com'
  const prefilledReason = 'Delete account'
  const headerText = 'Contact Support'

  const successSupportMessageText = (
    <SupportFormMainText
      primaryText={SUPPORT_FINAL_TEXT_HEADER}
      secondaryText={SUPPORT_FINAL_TEXT_MESSAGE}
    />
  )

  return (
    <>
      <Grid container justifyContent='center'>
        <Button onClick={onOpen}>Open SideBar</Button>
      </Grid>

      <SideBar
        headerText={headerText}
        isOpen={isOpen}
        onClose={onClose}
        sx={{ width: 600 }}
      >
        <Grid container sx={{ p: 4, height: '100%' }}>
          <SupportForm
            email={email}
            prefilledReason={prefilledReason}
            buttonText={buttonText}
            mainText={successSupportMessageText}
            icon={<EnvelopeIcon />}
          />
        </Grid>
      </SideBar>
    </>
  )
}
