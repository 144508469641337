import { FC, useCallback, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import { FormContainer } from '../../layout/FormContainer'
import { FormStepper } from '../../atoms/FormStepper'
import { PersonalDetailsScreen } from '../screens/PersonalDetails'
import {
  useAppDispatch,
  useAppSelector,
  useMakeService,
  useRegisterUser
} from '../../utils/hooks'
import {
  selectPersonalDetailsScreenData,
  selectRegistrationScreenLength,
  selectRegistrationStep,
  selectContactDetailsScreenData,
  selectVerificationCodeScreenData,
  selectConfirmPasswordScreenData,
  selectIsExitState
} from '../../store/units/registrationForm/selectors'
import { actionsManualRegistration } from '../../store/units/registrationForm/actions'
import {
  actionsUserInfo,
  UserInfoStatusMap
} from '../../store/features/userInfo'
import { ContactDetailsScreen } from '../screens/ContactDetails'
import { VerificationCodeScreen } from '../screens/VerificationCode'
import { CreatePasswordScreen } from '../screens/CreatePassword'
import { PreparingAccountScreen } from '../screens/PreparingAccount'
import { routes } from '../../routes'
import { ContactDetails } from '../../types/forms/registration'
import { UserTypes } from '../../types/enums/UserTypes'
import { ResponseStatusMap } from '../../types/status'

export const RegistrationForm: FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [registerUser] = useRegisterUser()
  const isExitState = useAppSelector(selectIsExitState)
  const [registrationErrors, setRegistarationErrors] = useState('')

  useEffect(() => {
    if (isExitState) {
      dispatch(actionsManualRegistration.resetState())
      navigate(routes.login)
    }
  }, [isExitState])

  const screenStep = useAppSelector(selectRegistrationStep)
  const screenLength = useAppSelector(selectRegistrationScreenLength)
  const personalDetailsScreenData = useAppSelector(
    selectPersonalDetailsScreenData
  )
  const contactDetailsScreenData = useAppSelector(
    selectContactDetailsScreenData
  )
  const verificationCodeScreenData = useAppSelector(
    selectVerificationCodeScreenData
  )
  const createPasswordScreenData = useAppSelector(
    selectConfirmPasswordScreenData
  )

  const onBackBtnClick = useCallback(() => {
    setRegistarationErrors('')
  }, [setRegistarationErrors])

  const [registrationService, registrationObservable] = useMakeService(
    async (data: ContactDetails) => {
      dispatch(actionsManualRegistration.setContactDetails(data))
      const response = await registerUser({
        personalDetails: personalDetailsScreenData,
        contactDetails: data,
        options: {
          idp: 'CarSnoop',
          accountId:
            personalDetailsScreenData.userType === UserTypes.DEALER &&
            personalDetailsScreenData.dealership?.id
        }
      })

      if (response.isNotVerified) {
        dispatch(
          actionsUserInfo.setUserStatus({
            status: UserInfoStatusMap.Preregistered
          })
        )
      }

      dispatch(
        actionsManualRegistration.setIsVerificationBlocked({
          isVerificationBlocked: response.isBlocked,
          blockedUntil: response.blockedUntil
        })
      )

      if (
        response.status === ResponseStatusMap.Success ||
        response.isNotVerified ||
        response.isBlocked
      ) {
        dispatch(actionsManualRegistration.incrementScreenStep())
        dispatch(actionsManualRegistration.incrementOnboardStep())
      }

      setRegistarationErrors(response.message)

      return response
    }
  )

  const screenFlow = [
    <PersonalDetailsScreen
      initialValues={personalDetailsScreenData}
      actions={actionsManualRegistration}
    />,
    <ContactDetailsScreen
      initialValues={contactDetailsScreenData}
      actions={actionsManualRegistration}
      onSubmit={registrationService}
      isLoading={registrationObservable.isLoading}
      initialErrors={{
        email: registrationErrors
      }}
    />,
    <VerificationCodeScreen
      initialValues={verificationCodeScreenData}
      actions={actionsManualRegistration}
      dependencies={{
        email: contactDetailsScreenData.email
      }}
      onBackBtnClick={onBackBtnClick}
    />,
    <CreatePasswordScreen
      initialValues={createPasswordScreenData}
      actions={actionsManualRegistration}
      dependencies={contactDetailsScreenData}
    />,
    <PreparingAccountScreen />
  ]

  const formTitle = screenStep === 4 ? 'Preparing the Account...' : 'Register'

  return (
    <FormContainer title={formTitle}>
      <Grid item>
        <FormStepper target={screenStep} count={screenLength - 1} />
      </Grid>
      <Grid container item flexGrow={1}>
        {screenFlow[screenStep]}
      </Grid>
    </FormContainer>
  )
}
