import { FC } from 'react'
import { CardMedia } from '@mui/material'
import { useModal } from '../../../../../../utils/hooks'
import { DocumentPreview } from '../../../../../../routes/Contract/DocumentsTab/components/DocumentPreview'
import { Show } from '../../../../../../atoms/JSXExtensions'

interface ImageMessageProps {
  contentPreviewURL?: string
}

export const ImageMessage: FC<ImageMessageProps> = ({ contentPreviewURL }) => {
  const imagePreviewModal = useModal()

  return (
    <Show when={contentPreviewURL}>
      {(previewURL) => (
        <>
          <DocumentPreview
            type={null}
            link={previewURL}
            isOpen={imagePreviewModal.isOpen}
            setIsOpen={imagePreviewModal.setOpen}
            sx={{ mt: '70px' }}
          />
          <CardMedia
            sx={{
              cursor: 'pointer',
              height: 200,
              width: 340
            }}
            onClick={imagePreviewModal.onOpen}
            component='img'
            src={previewURL}
          />
        </>
      )}
    </Show>
  )
}
