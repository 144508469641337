import { FC, useMemo } from 'react'
import { Show } from '../../../../../../atoms/JSXExtensions'
import { DetailsContainer } from '../../components/DetailsContainer'
import { NonTrackableView } from '../../components/Shipment/NonTrackableView'
import { TrackableView } from '../../components/Shipment/TrackableView'
import { SellerShippingProps } from '../SellerShipping/types'

export const DeliveredShipment: FC<SellerShippingProps> = ({
  trackingNumber,
  isSeller = false,
  ...props
}) => {
  const nonTrackableText = useMemo(
    () => ({
      title: 'Vehicle was shipped locally!',
      description: isSeller
        ? 'Once the Buyer receives the vehicle, the deal will be finalized!'
        : 'Once you receive the vehicle, the deal will be finalized!'
    }),
    [isSeller]
  )

  return (
    <DetailsContainer title='Shipment'>
      <Show
        when={trackingNumber}
        fallback={<NonTrackableView {...nonTrackableText} />}
      >
        {(tracking) => (
          <TrackableView trackingNumber={tracking} isDelivered {...props} />
        )}
      </Show>
    </DetailsContainer>
  )
}
