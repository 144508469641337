import { Formik, FormikErrors } from 'formik'
import { FC, useCallback, useMemo } from 'react'
import { ActionBlock } from '../../../atoms/ActionBlock'
import { AutoFormValidator } from '../../../atoms/AutoFormValidator'
import { LinkButton } from '../../../atoms/LinkButton'
import { PasswordInputField } from '../../../atoms/PasswordInputField'
import { TextInputField } from '../../../atoms/TextInputField'
import { routes } from '../../../routes'
import { actionsLoginForm } from '../../../store/units/loginForm/actions'
import { selectLoginFormState } from '../../../store/units/loginForm/selectors'
import {
  returnTimerString,
  useAppDispatch,
  useAppSelector,
  useCountdown
} from '../../../utils/hooks'
import { isValidForm } from '../../../utils/isValidForm'
import {
  ForgotPasswordContainer,
  LoginScreenContainerForm
} from './LoginScreen.styles'
import { LoginFormValues } from './types'
import { validationSchema } from './validationSchema'

type LogInScreenProps = {
  onSubmit: (values: LoginFormValues) => Promise<unknown>
  temporaryData: LoginFormValues
}

export const LogInScreen: FC<LogInScreenProps> = ({
  onSubmit,
  temporaryData
}) => {
  const { errorMessage, blockedUntil } = useAppSelector(selectLoginFormState)
  const dispatch = useAppDispatch()

  const onTimerEnd = useCallback(() => {
    dispatch(
      actionsLoginForm.setLoginFormState({
        isBlocked: false,
        blockedUntil: 0,
        errorMessage: ''
      })
    )
  }, [dispatch])

  const { minutes, seconds } = useCountdown(blockedUntil, onTimerEnd)

  const timerString = useMemo(
    () => returnTimerString(minutes, seconds),
    [minutes, seconds]
  )

  const formattedErrorMessage = useMemo(
    () => errorMessage.replace('{{locked_until}}', timerString),
    [errorMessage, timerString]
  )

  const initialErrors: { email: string; password: string } = {
    email: formattedErrorMessage ? ' ' : '',
    password: formattedErrorMessage
  }

  const validate = useCallback(
    (values: LoginFormValues) => {
      const errors: FormikErrors<LoginFormValues> = {}
      if (
        formattedErrorMessage &&
        values.password === temporaryData.password &&
        values.email === temporaryData.email
      ) {
        errors.password = formattedErrorMessage
        errors.email = ' '
      }

      return errors
    },
    [formattedErrorMessage, temporaryData]
  )

  return (
    <Formik<LoginFormValues>
      initialValues={temporaryData}
      initialErrors={initialErrors}
      initialTouched={{ email: true, password: true }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validate={validate}
    >
      {(formik) => {
        return (
          <LoginScreenContainerForm>
            <AutoFormValidator dependencies={[formattedErrorMessage]} />
            <TextInputField
              name='email'
              label='Email'
              placeholder='Email'
              autocomplete='email'
              isValid={!(formik.touched?.email && formik.errors.email)}
            />
            <PasswordInputField
              name='password'
              label='Password'
              placeholder='Password'
              autoComplete='current-password'
              isValid={!(formik.touched?.password && formik.errors.password)}
            />
            <ForgotPasswordContainer>
              <LinkButton label='Forgot password?' to={routes.forgotPassword} />
            </ForgotPasswordContainer>
            <ActionBlock
              schema={[
                {
                  label: 'Log In',
                  type: 'primary',
                  disabled: !isValidForm(formik, {
                    requiredFields: ['email', 'password']
                  }),
                  props: {
                    type: 'submit'
                  }
                }
              ]}
            />
          </LoginScreenContainerForm>
        )
      }}
    </Formik>
  )
}
