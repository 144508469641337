import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Grid, Typography } from '@mui/material'
import {
  ChangeEvent,
  FC,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState
} from 'react'
import { NavLink } from 'react-router-dom'
import { InformationIcon } from '../../assets/icons/Information'
import { CommonConfirmationModal } from '../../molecules/Modal/CommonConfirmationModal'
import { routes } from '../../routes'
import { actionsUserInfo } from '../../store/features/userInfo'
import { ResponseStatusMap } from '../../types/status'
import {
  useApiContext,
  useAppDispatch,
  useMakeService,
  useUserInfo
} from '../../utils/hooks'
import { BlueButton } from '../Button'
import { Condition } from '../Condition'
import { Toggle } from '../Toggle'

interface VehiclesListHeaderProps {
  vehiclesCount: number
}

const VehiclesListHeader: FC<VehiclesListHeaderProps> = ({ vehiclesCount }) => {
  const userInfo = useUserInfo()
  const dispatch = useAppDispatch()
  const { carSnoopApi } = useApiContext()

  const [isPublic, setIsPublic] = useState(userInfo.public)
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false)

  const [headerTitle, mainTitle] = useMemo(
    () => [
      userInfo.isDealer ? 'My Inventory' : 'Garage',
      userInfo.isDealer ? 'Inventory' : 'Garage'
    ],
    [userInfo.isDealer]
  )

  const [isPublicInfo, visibilityInfo] = useMemo(
    () => [
      userInfo.public ? 'Private' : 'Public',
      userInfo.public ? 'unavailable' : 'visible'
    ],
    [userInfo.public]
  )

  const [makeAccountPrivate] = useMakeService(
    async () => {
      const response = await carSnoopApi.accounts.makeAccountPrivate()

      if (response.status === ResponseStatusMap.Success) {
        dispatch(actionsUserInfo.setUserPersonalInfo({ public: false }))

        return response
      }

      return response
    },
    { includeCheckAccess: true, withStatusNotification: true }
  )

  const [makeAccountPublic] = useMakeService(
    async () => {
      const response = await carSnoopApi.accounts.makeAccountPublic()

      if (response.status === ResponseStatusMap.Success) {
        dispatch(actionsUserInfo.setUserPersonalInfo({ public: true }))

        return response
      }

      return response
    },
    { includeCheckAccess: true, withStatusNotification: true }
  )

  const togglePublic = useCallback(
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setConfirmationModalOpen(true)
      setIsPublic(checked)
    },
    [setIsPublic, setConfirmationModalOpen]
  )

  const onConfirmSubmit = useCallback(() => {
    if (isPublic) {
      return makeAccountPublic()
    }
    makeAccountPrivate()
  }, [isPublic, makeAccountPublic, makeAccountPrivate])

  return (
    <Grid container>
      <CommonConfirmationModal
        isOpen={isConfirmationModalOpen}
        setOpen={setConfirmationModalOpen}
        onSubmit={onConfirmSubmit}
        message={`Are you sure you would like to make ${mainTitle} ${isPublicInfo}?`}
        hint={`By making ${mainTitle} ${isPublicInfo}, your vehicles become ${visibilityInfo} for all users.`}
        cancelText='Cancel'
        header='Confirmation'
      />
      <Grid container item justifyContent='space-between' alignContent='center'>
        <Grid
          container
          item
          width='fit-content'
          alignContent='center'
          spacing={1}
        >
          <Grid item>
            <Typography variant='h6'>{headerTitle}</Typography>
          </Grid>
          <Grid item my='auto'>
            <Typography variant='main'>{vehiclesCount} vehicles</Typography>
          </Grid>
          <Grid item>
            <NavLink to={routes.vehicles.add} style={{ textDecoration: 'none' }}>
              <BlueButton startIcon={<AddCircleOutlineIcon />}>
                Add Vehicle
              </BlueButton>
            </NavLink>
          </Grid>
        </Grid>
        <Condition
          condition={userInfo.isAdmin && !!vehiclesCount}
          trueContent={
            <Grid item>
              <Toggle
                checked={userInfo.public}
                onChange={togglePublic}
                leftLabel='Private'
                rightLabel='Public'
              />
            </Grid>
          }
        />
      </Grid>
      <Condition
        condition={userInfo.isAdmin && !!vehiclesCount}
        trueContent={
          <Grid container spacing={1} alignContent='center'>
            <Grid item>
              <InformationIcon />
            </Grid>
            <Grid item>
              <Typography variant='subtitle2'>
                By making your {mainTitle} {isPublicInfo}, your vehicles become{' '}
                {visibilityInfo} for all users.
              </Typography>
            </Grid>
          </Grid>
        }
      />
    </Grid>
  )
}

interface VehiclesListContainerProps {
  vehiclesListCount: number
}

export const VehiclesListContainer: FC<
  PropsWithChildren<VehiclesListContainerProps>
> = ({ children, vehiclesListCount }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <VehiclesListHeader vehiclesCount={vehiclesListCount} />
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  )
}
