import { useCallback } from 'react'
import { Grid } from '@mui/material'
import { CancelButton } from '../../../../../atoms/Button'
import { FormOrchestratorStep } from '../../../../../layout/FormOrchestrator/types'
import { SuccessScreen } from '../../../../../molecules/SuccessScreen'

export const FinalStep: FormOrchestratorStep = (props) => {
  const { ctx } = props

  const handleBack = useCallback(() => {
    ctx.prevStep()
  }, [])

  return (
    <Grid container item>
      <Grid item xs={12}>
        <SuccessScreen title='Success' subtitle='Hop-hop' />
      </Grid>

      <Grid item xs={6}>
        <CancelButton onClick={handleBack}>Back</CancelButton>
      </Grid>
    </Grid>
  )
}
