import { Box, Grid } from '@mui/material'
import { FC, PropsWithChildren } from 'react'

export const ComponentContainer: FC<
  { fullWidth?: boolean } & PropsWithChildren
> = ({ fullWidth, children }) => {
  return (
    <Grid
      container
      item
      xs={12}
      justifyContent='center'
      sx={{
        width: '100%',
        padding: '10px'
      }}
    >
      <Box {...(fullWidth && { width: '100%' })}>{children}</Box>
    </Grid>
  )
}
