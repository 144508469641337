import { Fragment, ReactNode, Children } from 'react'

interface ForProps<T> {
  of: Array<T>
  children: (item: T, index: number) => ReactNode
}

export const For = <T,>({ children, of }: ForProps<T>) => {
  return (
    <>
      {Children.toArray(
        of.map((item, index) => (
          <Fragment key={index}>{children(item, index)}</Fragment>
        ))
      )}
    </>
  )
}
