import { useState, useEffect } from 'react'
import { CONVERT_TIME } from '../../constants/applicationConstants'
import { extractTimerValues } from '../extractTimerValues'

export const returnTimerString = (minutes: number, seconds: number) =>
  `${minutes < 10 ? `0${minutes}` : minutes}:${
    seconds < 10 ? `0${seconds}` : seconds
  }`

export const useCountdown = (targetDate: number, onTimerEnd?: () => void) => {
  const countdownDate =
    new Date(targetDate).getTime() * CONVERT_TIME.MILISECONDS_IN_SECOND

  const [countDown, setCountDown] = useState(countdownDate - Date.now())
  let interval: ReturnType<typeof setInterval> | undefined
  useEffect(() => {
    if (targetDate) {
      interval = setInterval(() => {
        setCountDown(countdownDate - Date.now())
      }, CONVERT_TIME.MILISECONDS_IN_SECOND)
    }

    return () => clearInterval(interval)
  }, [countdownDate, targetDate])

  useEffect(() => {
    if (countDown <= 0 && targetDate) {
      clearInterval(interval)
      onTimerEnd?.()
    }
  }, [countDown, onTimerEnd, interval])

  return extractTimerValues(countDown)
}
