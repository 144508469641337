import { Grid, Typography } from '@mui/material'
import { format } from 'date-fns'
import {
    Dispatch,
    DragEventHandler,
    FC,
    SetStateAction,
    useCallback,
    useMemo,
    useRef
} from 'react'
import { UploadCloudIcon } from '../../../../assets/icons/UploadCloud'
import { UploadDocumentIcon } from '../../../../assets/icons/UploadDocumentIcon'
import { Show } from '../../../../atoms/JSXExtensions'
import { UploadDocument } from '../../../../atoms/UploadDocument'
import {
    MAX_PDF_SIZE_MB,
    MAX_PHOTO_SIZE_MB,
    MMDDYYYY
} from '../../../../constants/applicationConstants'
import { UploadDocumentItem } from '../../../../features/UploadNewDocument/useDocumentUpload'
import { BORDERS, COLORS } from '../../../../types/colors'
import {
    CompressImageResponse,
    compressImages
} from '../../../../utils/compressImages'
import { DocumentView } from '../../../DocumentView'

export interface DocumentUploaderProps {
  onBeforeUpload: () => Promise<void>
  onFileUpload: (file: CompressImageResponse) => void
  onFileDelete: () => void
  setProgress: Dispatch<SetStateAction<number>>
  progress: number
  document: UploadDocumentItem
  name: string
  maxSizeMB?: number
  error?: string
}

export const DocumentUploader: FC<DocumentUploaderProps> = (props) => {
  const {
    onBeforeUpload,
    onFileUpload,
    onFileDelete,
    document,
    progress,
    setProgress,
    name,
    maxSizeMB = MAX_PHOTO_SIZE_MB,
    error = ''
  } = props

  const fileInputRef = useRef<HTMLDivElement>(null)

  const handleUploadClick = useCallback(() => {
    fileInputRef.current?.click()
  }, [])

  const handleFileDrop: DragEventHandler<HTMLDivElement> = useCallback(
    async (e) => {
      e.stopPropagation()
      e.preventDefault()
      await onBeforeUpload()
      const files = Array.from(e.dataTransfer.files)
      const compressedFiles = await compressImages(files, {
        onProgress: setProgress,
        useWebWorker: true,
        maxSizeMB,
        additionalExtensions: ['pdf'],
        additionalExtensionsMaxSize: MAX_PDF_SIZE_MB
      })

      onFileUpload(compressedFiles)
    },
    [onFileUpload, onBeforeUpload]
  )

  const onDragOver: DragEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()
    e.preventDefault()
  }

  const formattedDate = useMemo(() => {
    return format(document?.createdOn || new Date(), MMDDYYYY)
  }, [format, document?.createdOn])

  const isPdfFile = useMemo(() => document?.type === 'pdf', [document?.type])

  const UploadDocumentView = (
    <Grid
      item
      container
      height={240}
      border={BORDERS.EMPTY_IMAGE_MAIN}
      xs={12}
      alignItems='center'
    >
      <Grid container item zIndex={6} spacing={1}>
        <UploadDocument
          name='document'
          onChange={onFileUpload}
          onProgress={setProgress}
          onBeforeChange={onBeforeUpload}
          additionalExtensions={['pdf']}
          additionalExtensionsMaxSize={MAX_PDF_SIZE_MB}
          acceptType='image/*, application/pdf'
        >
          <div ref={fileInputRef}>{/* Magic! */}</div>
        </UploadDocument>
        <Grid item xs={12} container justifyContent='center'>
          <UploadCloudIcon />
        </Grid>
        <Grid item xs={12} container justifyContent='center'>
          <Typography variant='main' color={COLORS.text_secondary}>
            Drag and drop files or
          </Typography>
        </Grid>
        <Grid item xs={12} container justifyContent='center'>
          <Typography
            variant='main'
            color={COLORS.text_blue}
            sx={{
              cursor: 'pointer'
            }}
            onClick={handleUploadClick}
          >
            Click here to choose
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )

  const fileDescription = useMemo(
    () =>
      progress < 100 && !isPdfFile
        ? 'Uploading...'
        : `${document?.type.toUpperCase()} – ${formattedDate}`,
    [progress, document?.type, isPdfFile]
  )

  const isCompressionInProgress = useMemo(
    () => !!progress && progress < 100,
    [progress]
  )
  const documentIcon = useMemo(() => {
    const documentIconColor =
      !error && (progress === 100 || isPdfFile)
        ? COLORS.utility_positive
        : COLORS.text_primary

    return <UploadDocumentIcon fill={documentIconColor} />
  }, [error, progress, isPdfFile])

  return (
    <Grid
      container
      justifyContent='center'
      onDragOver={onDragOver}
      onDrop={handleFileDrop}
    >
      <Show
        when={!!progress || document || error}
        fallback={UploadDocumentView}
      >
        <DocumentView
          documentName={name}
          documentIcon={documentIcon}
          progress={progress}
          isInProgress={isCompressionInProgress}
          error={error}
          fileDescription={fileDescription}
          onFileDelete={onFileDelete}
        />
      </Show>
    </Grid>
  )
}
