import { Box, CardMedia, Grid } from '@mui/material'
import { FC, useCallback } from 'react'
import { Condition } from '../../../../atoms/Condition'
import { Show } from '../../../../atoms/JSXExtensions'
import { BORDERS } from '../../../../types/colors'
import { EmptyImage } from '../EmptyImage/EmptyImage'
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner'

export interface ImagePreviewProps {
  id: string
  image: string
  onClick: (id: string) => void
  isSelected?: boolean
  isLoading?: boolean
  allowEdit?: boolean
}

export const ImagePreview: FC<ImagePreviewProps> = (props) => {
  const { image, isSelected, id, isLoading, onClick, allowEdit = true } = props
  const allowInteraction = allowEdit || !id.includes('empty')
  const handleClick = useCallback(() => {
    allowInteraction && onClick(id)
  }, [onClick, id])

  return (
    <Grid
      item
      sx={{
        aspectRatio: '16/9',
        cursor: allowInteraction ? 'pointer' : 'default',
        width: '25%',
      }}
      onClick={handleClick}
    >
      <Show
        when={image}
        fallback={
          <EmptyImage
            isLoading={isLoading}
            isSelected={isSelected}
            allowEdit={allowEdit}
          />
        }
      >
        {(imageURL) => (
          <Box
            sx={{
              height: '100%',
              position: 'relative'
            }}
          >
            <Condition
              condition={!!isLoading}
              trueContent={<LoadingSpinner />}
              falseContent={
                <CardMedia
                  sx={{
                    height: '100%',
                    border: isSelected
                      ? BORDERS.SELECTED_IMAGE
                      : BORDERS.TRANSPARENT_IMAGE
                  }}
                  component='img'
                  src={`${imageURL}`}
                  alt='Vehicle'
                />
              }
            />
          </Box>
        )}
      </Show>
    </Grid>
  )
}
