import { useCallback } from 'react'
import { useRestoreData } from '../../../utils/hooks'
import { useStripePaymentRedirect } from '../../Contract/hooks/usePaymentRedirect/useStripePaymentRedirect'
import { IOfferForm } from './useOfferForm'

export const OFFER_FORM_KEY = 'offerFormKey'

// Just to separate logic
const usePaymentRedirect = (transactionId: string) => {
  const generateSuccessUrl = useCallback((): string => {
    const baseUrl = window.location.origin
    const baseUrlPath = window.location.pathname

    const successUrl = new URL('/', baseUrl)
    successUrl.pathname = `${baseUrlPath}_result`

    return successUrl.href
  }, [])

  const {
    getPaymentRedirectUrl,
    isPaymentOpen,
    redirectToPayment,
    handleRedirectFail
  } = useStripePaymentRedirect(generateSuccessUrl, transactionId)

  return {
    getPaymentRedirectUrl,
    isPaymentOpen,
    redirectToPayment,
    handleRedirectFail
  }
}

export const useRestoreAcceptOfferForm = (transactionId: string = '') => {
  const { restoredData, isRestoring, storeData, clearData } =
    useRestoreData<IOfferForm | null>(OFFER_FORM_KEY)

  const {
    getPaymentRedirectUrl,
    isPaymentOpen,
    redirectToPayment,
    handleRedirectFail
  } = usePaymentRedirect(transactionId)

  return {
    isRestoring,
    restoredData,
    isPaymentOpen,
    redirectToPayment,
    storeData,
    clearData,
    handleRedirectFail,
    getPaymentRedirectUrl
  }
}
