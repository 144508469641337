import * as Yup from 'yup'
import {
  isValidEmail,
  latinWithSpecial,
  countOfNumbers
} from '../../../utils/validators'
import { phoneNumberLength } from '../../../constants/applicationConstants'

export const validationSchema = Yup.object({
  email: Yup.string()
    .test('is-email', 'Enter a valid email address.', isValidEmail())
    .test(
      'is-latin-or-special',
      'Enter a valid email address.',
      latinWithSpecial()
    ),
  phone: Yup.string()
    .trim()
    .test(
      'length',
      'Enter a valid phone number.',
      countOfNumbers(phoneNumberLength)
    ),
  state: Yup.object(),
  city: Yup.object()
})
