import { CONVERT_TIME } from '../constants/applicationConstants'

export const extractTimerValues = (countDown: number) => {
  const days = Math.floor(
    countDown /
      (CONVERT_TIME.MILISECONDS_IN_SECOND *
        CONVERT_TIME.SECONDS_IN_MINUTE *
        CONVERT_TIME.MINUTES_IN_HOUR *
        CONVERT_TIME.HOURS_IN_DAY)
  )
  const hours = Math.floor(
    (countDown %
      (CONVERT_TIME.MILISECONDS_IN_SECOND *
        CONVERT_TIME.SECONDS_IN_MINUTE *
        CONVERT_TIME.MINUTES_IN_HOUR *
        CONVERT_TIME.HOURS_IN_DAY)) /
      (CONVERT_TIME.MILISECONDS_IN_SECOND *
        CONVERT_TIME.SECONDS_IN_MINUTE *
        CONVERT_TIME.MINUTES_IN_HOUR)
  )
  const minutes = Math.floor(
    (countDown %
      (CONVERT_TIME.MILISECONDS_IN_SECOND *
        CONVERT_TIME.SECONDS_IN_MINUTE *
        CONVERT_TIME.MINUTES_IN_HOUR)) /
      (CONVERT_TIME.MILISECONDS_IN_SECOND * CONVERT_TIME.SECONDS_IN_MINUTE)
  )
  const seconds = Math.floor(
    (countDown %
      (CONVERT_TIME.MILISECONDS_IN_SECOND * CONVERT_TIME.SECONDS_IN_MINUTE)) /
      CONVERT_TIME.MILISECONDS_IN_SECOND
  )

  return { days, hours, minutes, seconds }
}
