import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Box, Grid, Typography } from '@mui/material'
import { FC, useCallback, useMemo, useRef, useState } from 'react'
import { VehicleImage } from '../../api/carSnoopApi/vehicles'
import { BlueButton } from '../../atoms/Button'
import { Condition } from '../../atoms/Condition'
import { MAX_IMAGES, PER_PAGE } from '../../constants/applicationConstants'
import { returnNoCachedSrc } from '../../utils/returnNoCachedSrc'
import { CarouselButton } from './components/CarouselButton/CarouselButton'
import { FullScreenImagePreview } from './components/FullScreenImagePreview'
import { ImagePreview } from './components/ImagePreview/ImagePreview'
import { ImagesCarouselStepper } from './components/ImagesCarouselStepper/ImagesCarouselStepper'
import { ImagesCounterLabel } from './components/ImagesCounterLabel'
import { MainImagePreview } from './components/MainImagePreview/MainImagePreview'
import { useDeleteImage } from './hooks/useDeleteImage'
import { useUploadImage } from './hooks/useUploadImage'
import { integrateServiceImageBlock } from './integrateServiceImageBlock'

export interface ImagesCarouselProps {
  vehicleId: string
  images: VehicleImage[]
  hint?: string
  showAddButton?: boolean
  allowEdit?: boolean
  onLoadCallback?: () => void
}

export const ImagesCarousel: FC<ImagesCarouselProps> = (props) => {
  const {
    vehicleId,
    images,
    hint,
    showAddButton = true,
    allowEdit = true,
    onLoadCallback
  } = props

  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [currentImagesPage, setCurrentImagesPage] = useState(0)
  const [loadingImageId, setLoadingImageId] = useState('')

  const [uploadVehiclePhoto, uploadVehiclePhotoObservable] = useUploadImage(
    vehicleId,
    onLoadCallback
  )

  const [deleteVehiclePhotoObservable, deleteVehiclePhoto] = useDeleteImage(
    vehicleId,
    onLoadCallback
  )

  const wipImages: VehicleImage[] = useMemo(
    () =>
      integrateServiceImageBlock(
        images,
        PER_PAGE,
        allowEdit && images.length < MAX_IMAGES
      ),
    [images, allowEdit]
  )

  const totalPages = useMemo(
    () => Math.ceil(wipImages.length / PER_PAGE),
    [wipImages.length]
  )
  const [fullScreenCurrentImage, setFullScreenCurrentImage] = useState(0)
  const [isFullScreenOpen, setFullScreenOpen] = useState(false)

  const fileInputRef = useRef<HTMLDivElement>(null)

  const pageImages = useMemo(() => {
    const pageFirstImageIndex = currentImagesPage * PER_PAGE
    const slicedImages = wipImages.slice(
      pageFirstImageIndex,
      pageFirstImageIndex + PER_PAGE
    )

    return slicedImages
  }, [images, currentImagesPage])

  const handleRightClick = useCallback(() => {
    if (currentImageIndex < wipImages.length - 1) {
      if (
        wipImages[currentImageIndex]?.id ===
        pageImages[pageImages.length - 1]?.id
      ) {
        setCurrentImagesPage((p) => p + 1)
      }
      setCurrentImageIndex((i) => i + 1)
    }
  }, [currentImageIndex, setCurrentImageIndex, wipImages])

  const handleLeftClick = useCallback(() => {
    if (currentImageIndex > 0) {
      if (wipImages[currentImageIndex]?.id === pageImages[0]?.id) {
        setCurrentImagesPage((p) => p - 1)
      }
      setCurrentImageIndex((i) => i - 1)
    }
  }, [currentImageIndex, setCurrentImageIndex, wipImages])

  const handleCarouselStepperClick = useCallback(
    (page: number) => {
      setCurrentImagesPage(page)
      setCurrentImageIndex(page * PER_PAGE)
    },
    [currentImagesPage, setCurrentImagesPage, setCurrentImageIndex]
  )

  const handleCloseFullScreen = useCallback(() => {
    setFullScreenOpen(false)
  }, [setFullScreenOpen])

  const handleAddPhotoClick = useCallback(() => {
    const newPage = totalPages && totalPages - 1
    const newIndex = wipImages.length && wipImages.length - 1
    setCurrentImagesPage(newPage)
    setCurrentImageIndex(newIndex)
    fileInputRef.current?.click()
    handleCloseFullScreen()
  }, [
    setCurrentImageIndex,
    setCurrentImageIndex,
    wipImages,
    totalPages,
    handleCloseFullScreen
  ])

  const handleImageClick = useCallback(
    (id: string) => {
      const imageIndex = wipImages.findIndex((image) => image.id === id)

      if (imageIndex >= 0) {
        setCurrentImageIndex(imageIndex)
      } else {
        setCurrentImageIndex(0)
      }
    },
    [wipImages, setCurrentImageIndex]
  )

  const isFetching = useMemo(
    () =>
      uploadVehiclePhotoObservable.isLoading ||
      deleteVehiclePhotoObservable.isLoading,
    [uploadVehiclePhotoObservable, deleteVehiclePhotoObservable]
  )

  const handleOpenFullScreen = useCallback(
    (id: string) => {
      const index = images.findIndex((image) => image.id === id)
      setFullScreenCurrentImage(index)
      setFullScreenOpen(true)
    },
    [setFullScreenCurrentImage, setFullScreenOpen, images]
  )

  const hintMessage = useMemo(
    () =>
      hint ||
      `You have added ${images.length} photos of ${MAX_IMAGES} possible `,
    [hint, images.length]
  )

  const hintMessageVariant = useMemo(
    () => (hint ? 'main' : 'subtitle1'),
    [hint]
  )
  const showImagesCounter = useMemo(
    () => !!images.length && currentImageIndex < images.length && allowEdit,
    [images, currentImageIndex]
  )

  const showCarouselHeader = useMemo(
    () => (images.length < MAX_IMAGES || !!hint) && allowEdit,
    [images, hint]
  )

  return (
    <Grid container spacing={2}>
      <FullScreenImagePreview
        activeImageIndex={fullScreenCurrentImage}
        images={images}
        isOpen={isFullScreenOpen}
        onClose={handleCloseFullScreen}
      />
      <Condition
        condition={showCarouselHeader}
        trueContent={
          <Grid
            container
            item
            justifyContent='space-between'
            alignItems='center'
          >
            <Grid item>
              <Typography variant={hintMessageVariant} whiteSpace='pre-line'>
                {hintMessage}
              </Typography>
            </Grid>
            <Grid item>
              <Condition
                condition={showAddButton}
                trueContent={
                  <BlueButton
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={handleAddPhotoClick}
                  >
                    <Typography color='inherit' sx={{ cursor: 'pointer' }}>
                      Add Photo
                    </Typography>
                  </BlueButton>
                }
              />
            </Grid>
          </Grid>
        }
      />

      <Grid
        container
        item
        sx={{
          aspectRatio: '16/9',
          position: 'relative',
          width: '100%',
        }}
      >
        <Condition
          condition={showImagesCounter}
          trueContent={
            <Box
              sx={{
                position: 'absolute',
                top: '32px',
                left: '32px',
                zIndex: 4
              }}
            >
              <ImagesCounterLabel
                current={currentImageIndex + 1}
                total={images.length}
              />
            </Box>
          }
        />
        <Condition
          condition={wipImages.length > 1}
          trueContent={
            <>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  position: 'absolute',
                  zIndex: 10,
                  top: '50%',
                  left: '-16px'
                }}
              >
                <CarouselButton
                  onClick={handleLeftClick}
                  disabled={!currentImageIndex}
                />
              </Box>
              <Box
                sx={{
                  transform: 'rotateZ(180deg)',
                  display: 'flex',
                  alignItems: 'center',
                  position: 'absolute',
                  zIndex: 10,
                  top: '50%',
                  right: '-32px'
                }}
              >
                <CarouselButton
                  onClick={handleRightClick}
                  disabled={
                    allowEdit
                      ? currentImageIndex >= wipImages.length - 1
                      : currentImageIndex >= images.length - 1
                  }
                />
              </Box>
            </>
          }
        />

        <MainImagePreview
          id={wipImages[currentImageIndex]?.id || ''}
          index={currentImageIndex}
          type={wipImages[currentImageIndex]?.type || ''}
          image={returnNoCachedSrc(wipImages[currentImageIndex]?.url)}
          isFetching={isFetching}
          onUpload={uploadVehiclePhoto}
          onDelete={deleteVehiclePhoto}
          setLoadingImageId={setLoadingImageId}
          onImageClick={handleOpenFullScreen}
          onModalClose={handleCloseFullScreen}
          allowEdit={allowEdit}
          fileInputRef={fileInputRef}
        />
      </Grid>
      <Condition
        condition={wipImages.length > 1}
        trueContent={
          <>
            <Grid container item spacing='4px' direction='row'>
              {pageImages.map((image, i) => {
                return (
                  <ImagePreview
                    image={returnNoCachedSrc(image?.url)}
                    id={image?.id || ''}
                    key={image?.url || i}
                    onClick={handleImageClick}
                    isSelected={wipImages[currentImageIndex]?.id === image?.id}
                    isLoading={loadingImageId === image?.id}
                    allowEdit={allowEdit}
                  />
                )
              })}
            </Grid>
            <Condition
              condition={wipImages.length > PER_PAGE}
              trueContent={
                <ImagesCarouselStepper
                  onClick={handleCarouselStepperClick}
                  totalPages={totalPages}
                  currentPage={currentImagesPage}
                />
              }
            />
          </>
        }
      />
    </Grid>
  )
}
