import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../../../../routes'
import {
  actionsDealsPage,
  actionsInProgressDealsPage
} from '../../../../../store/pages/Deals'
import { useAppDispatch } from '../../../../../utils/hooks'

export const useUserDashboardClickHandlers = (isDealer: boolean) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const requestsClickHandler = useCallback(() => {
    navigate(routes.deals.index)
    if (isDealer) {
      dispatch(actionsDealsPage.setSellingView(true))
      dispatch(actionsDealsPage.setActiveSellingTab(0))
    } else {
      dispatch(actionsDealsPage.setSellingView(false))
      dispatch(actionsDealsPage.setActiveBuyingTab(0))
    }
  }, [navigate, dispatch])

  const offersClickHandler = useCallback(() => {
    navigate(routes.deals.index)
    if (isDealer) {
      dispatch(actionsDealsPage.setSellingView(true))
      dispatch(actionsDealsPage.setActiveSellingTab(1))
    } else {
      dispatch(actionsDealsPage.setSellingView(false))
      dispatch(actionsDealsPage.setActiveBuyingTab(1))
    }

    dispatch(actionsInProgressDealsPage.setInProgressDealsStatus('Offered'))
  }, [dispatch, navigate])

  const contractsClickHandler = useCallback(() => {
    navigate(routes.deals.index)
    if (isDealer) {
      dispatch(actionsDealsPage.setSellingView(true))
      dispatch(actionsDealsPage.setActiveSellingTab(1))
    } else {
      dispatch(actionsDealsPage.setSellingView(false))
      dispatch(actionsDealsPage.setActiveBuyingTab(1))
    }

    dispatch(
      actionsInProgressDealsPage.setInProgressDealsStatus('Under Contract')
    )
  }, [dispatch, navigate])

  return { requestsClickHandler, offersClickHandler, contractsClickHandler }
}
