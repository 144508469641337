import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../utils/hooks'
import {
  selectAddVehicleForm,
  actionsAddVehicleForm
} from '../../../../store/units/addVehicleForm'

export const useAddVehicleFormSlice = () => {
  const { flow, newVehicleId, isAddVehicleModalOpen } =
    useAppSelector(selectAddVehicleForm)
  const dispatch = useAppDispatch()

  const setFlow = useCallback((nextFlow: typeof flow) => {
    dispatch(actionsAddVehicleForm.setFlow(nextFlow))
  }, [])

  const setNewVehicleId = useCallback((id: string) => {
    dispatch(actionsAddVehicleForm.setNewVehicleId(id))
  }, [])

  const resetNewVehicleId = useCallback(() => {
    dispatch(actionsAddVehicleForm.resetNewVehicleId())
  }, [])

  const openAddVehicleModal = useCallback(() => {
    dispatch(actionsAddVehicleForm.setAddVehicleModalOpen(true))
  }, [])

  const closeAddVehicleModal = useCallback(() => {
    dispatch(actionsAddVehicleForm.setAddVehicleModalOpen(false))
  }, [])

  const resetAddVehicleForm = useCallback(() => {
    dispatch(actionsAddVehicleForm.resetAddVehicleForm())
  }, [])

  return {
    flow,
    newVehicleId,
    isAddVehicleModalOpen,
    setFlow,
    setNewVehicleId,
    resetNewVehicleId,
    openAddVehicleModal,
    closeAddVehicleModal,
    resetAddVehicleForm
  }
}
