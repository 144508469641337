import { Typography, TypographyProps } from '@mui/material'
import { forwardRef, useCallback } from 'react'
import { NavLinkProps, useLocation } from 'react-router-dom'
import { pageNavigation } from '../../../../store/thunks/pageNavigation'
import {
  ControlledLink,
  useNavigationController
} from '../../../../utils/NavigationController'
import { LinkWithEffect } from '../../../../utils/NavigationController/components/LinkWithEffect'
import { useAppDispatch } from '../../../../utils/hooks'


const DivTypography = forwardRef<HTMLDivElement, TypographyProps>(
  (props, ref) => {
    const newProps = {
      ...props,
      component: 'div',
    }
    return <Typography ref={ref} {...newProps} />
  }
)

export const CustomNavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(
  (props, ref) => {
    const { pathname } = useLocation()
    const dispatch = useAppDispatch()
    const controller = useNavigationController()

    const linkEffect = useCallback(() => {
      if (!controller.isPathBlocked && pathname !== props.to) {
        dispatch(pageNavigation())
      }
    }, [controller.isPathBlocked, pathname, props.to])

    return (
      <ControlledLink component={DivTypography} to={props.to}>
        <LinkWithEffect ref={ref} {...props} effect={linkEffect} />
      </ControlledLink>
    )
  }
)
