import { FC, useCallback, useState, useMemo } from 'react'
import { ViewMode } from './components/ViewMode'
import { Condition } from '../../../../../../atoms/Condition'
import { EditMode } from './components/EditMode'
import { DetailsContainer } from '../DetailsContainer'
import { BuyerFormFieldsTypes } from './components/EditMode/types'
import { useUserInfo } from '../../../../../../utils/hooks'
import { OrNull } from '../../../../../../types/OrNull'
import { NormalizedContractType } from '../../../../../../api/carSnoopApi/transactions/types'
import { Show } from '../../../../../../atoms/JSXExtensions'
import { useContractPageInfo } from '../../../../hooks/useContractPageInfo'

export interface BuyerDetailsProps {
  title: string
  contract: OrNull<NormalizedContractType>
  allowEdit?: boolean
  onSaveClick: (data: BuyerFormFieldsTypes) => void
}

export const BuyerDetails: FC<BuyerDetailsProps> = (props) => {
  const { title, contract, allowEdit, onSaveClick } = props
  const { transaction } = useContractPageInfo()

  const [mode, setMode] = useState<'edit' | 'view'>('view')

  const { accountID } = useUserInfo()
  const isCurrentUserABuyer = useMemo(
    () => transaction?.buyerID === accountID,
    [transaction?.buyerID, accountID]
  )
  const isEditMode = mode === 'edit'

  const setEditMode = useCallback(() => {
    setMode('edit')
  }, [setMode])

  const setViewMode = useCallback(() => {
    setMode('view')
  }, [setMode])

  return (
    <Show when={contract}>
      {(contractDetails) => (
        <DetailsContainer
          title={title}
          allowEdit={allowEdit && isCurrentUserABuyer}
          emphasize={isCurrentUserABuyer}
          onEditClick={setEditMode}
        >
          <Condition
            condition={isEditMode}
            trueContent={
              <EditMode
                buyerDetails={contractDetails.buyerDetails}
                coBuyerDetails={contractDetails.additionalBuyerDetails}
                onCancelClick={setViewMode}
                onSaveClick={onSaveClick}
              />
            }
            falseContent={
              <ViewMode
                buyerDetails={contractDetails.buyerDetails}
                coBuyerDetails={contractDetails.additionalBuyerDetails}
              />
            }
          />
        </DetailsContainer>
      )}
    </Show>
  )
}
