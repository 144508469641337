import { useLayoutEffect } from 'react'
import {
  useApiContext,
  useAppDispatch,
  useMakeService,
  useUserInfo
} from '../../utils/hooks'
import { ResponseStatusMap } from '../../types/status'
import type {
  SelectedInventoryStatus,
  VehiclesListType
} from '../../store/types'
import { actionsInventoryPage } from '../../store/pages/Inventory'
import { SearchFormValues } from '../../features/SearchInventory'
import { FiltersFormValues } from './FilterInventory'

export const useInventoryHandlers = () => {
  const dispatch = useAppDispatch()
  const userInfo = useUserInfo()
  const { carSnoopApi } = useApiContext()

  const [requestInventoryService, readInventoryObservable] = useMakeService(
    async () => {
      const response = await carSnoopApi.accounts.readInventory({
        accountID: userInfo.accountID
      })

      if (response.status === ResponseStatusMap.Success) {
        dispatch(actionsInventoryPage.setVehiclesList(response.inventory))
        dispatch(actionsInventoryPage.resetSelectedStatus())
      }

      return response
    }
  )

  useLayoutEffect(() => {
    if (userInfo.accountID) {
      requestInventoryService()
    }
  }, [userInfo.accountID])

  /* Select status */

  const applySelectVehiclesList = (
    vl: VehiclesListType,
    status: SelectedInventoryStatus
  ) => {
    dispatch(actionsInventoryPage.setSelectResults({ data: vl, status }))
  }

  /* Search by stock number */

  const applySearchVehiclesList = (
    vl: VehiclesListType,
    formValues: SearchFormValues
  ) => {
    dispatch(actionsInventoryPage.resetFilterResults())
    dispatch(actionsInventoryPage.setSearchResults({ data: vl, formValues }))
  }

  const resetSearchVehiclesList = () => {
    dispatch(actionsInventoryPage.resetFilterResults())
  }

  /* Filters */

  const applyFilterVehiclesList = (
    vl: VehiclesListType,
    formValues: FiltersFormValues
  ) => {
    dispatch(actionsInventoryPage.setFilterResults({ data: vl, formValues }))
  }

  /* Resets */

  const clearAllFilters = () => {
    dispatch(actionsInventoryPage.resetFilterResults())
  }

  return {
    isLoading: readInventoryObservable.isLoading,
    applySelectVehiclesList,
    applySearchVehiclesList,
    resetSearchVehiclesList,
    applyFilterVehiclesList,
    clearAllFilters
  }
}
