export enum ReadInventoryItemStatusesMap {
  'Available' = 'For Sale',
  'Requested' = 'Requested',
  'In Request' = 'In Request',
  'In Offer' = 'In Offer',
  'Offered' = 'Offered',
  'Under Contract' = 'Under Contract',
  'Awaiting Payment' = 'Awaiting Payment',
  'Shipping' = 'Shipping',
  'Delivered' = 'Delivered'
}

export type ReadInventoryOptionsType = {
  accountID: string
  make?: string
  model?: string
  minYear?: string
  maxYear?: string
  status?: keyof typeof ReadInventoryItemStatusesMap | ''
  stockNumber?: string
  ordering?: boolean
}
