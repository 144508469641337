import { Divider } from '../../../../atoms/Divider'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const EmptyDividerExample = () => {
  return (
    <ComponentContainer fullWidth>
      <ComponentWithPaperBackground>
        <div
          style={{
            width: '768px'
          }}
        >
          <Divider />
        </div>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}

export const FilledDividerExample = () => {
  return (
    <ComponentContainer fullWidth>
      <ComponentWithPaperBackground>
        <div
          style={{
            width: '768px'
          }}
        >
          <Divider text='Honda Odyssey' />
        </div>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
