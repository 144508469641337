import { useCallback } from 'react'
import { ICreateOfferParams } from '../../../../../api/carSnoopApi/offers/types'
import { ResponseStatusMap } from '../../../../../types/status'
import {
  useApiContext,
  useMakeService,
  useSubscriptionModal
} from '../../../../../utils/hooks'

export const useRequests = () => {
  const { carSnoopApi } = useApiContext()
  const { openSubscriptionModal } = useSubscriptionModal()

  const createOfferService = useCallback(
    async ({
      transactionId,
      totalPrice,
      addOns,
      notes,
      priceComponents
    }: ICreateOfferParams) => {
      const response = await carSnoopApi.offers.createOffer({
        transactionId,
        totalPrice,
        priceComponents,
        notes,
        addOns
      })

      if (response.status === ResponseStatusMap.Success) {
        return {
          status: response.status,
          id: response.id,
          message: response.message
        }
      }

      const { isSubscriptionError } = response

      if (isSubscriptionError) {
        openSubscriptionModal()
        return { ...response, isSubscriptionError }
      }

      return {
        status: response.status,
        message: response.message,
        isSubscriptionError: false
      }
    },
    []
  )

  const [createOffer, createOfferObservable] = useMakeService(
    createOfferService,
    { includeCheckAccess: true }
  )

  const [removeTradeIn, removeTradeInObservable] = useMakeService(
    async (id: string) => {
      const response = await carSnoopApi.transactions.removeTradeIn(id)

      if (response.status === ResponseStatusMap.Success) {
        return {
          status: response.status,
          id: response.id,
          message: response.message
        }
      }

      if (response.status === ResponseStatusMap.Error) {
        return {
          status: response.status,
          message: response.message
        }
      }
    },
    { withStatusNotification: true }
  )

  return {
    createOffer,
    createOfferObservable,
    removeTradeIn,
    removeTradeInObservable
  }
}
