import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { ReadInventoryItemStatusesMap } from '../../../../api/carSnoopApi/accounts/types'
import { ReadVehicleResponse } from '../../../../api/carSnoopApi/vehicles'
import { PenIcon } from '../../../../assets/icons/Pen'
import { BlueButton } from '../../../../atoms/Button'
import { CollapsibleNotes } from '../../../../atoms/CollapsibleNotes'
import { Condition } from '../../../../atoms/Condition'
import { EllipsisText } from '../../../../atoms/EllipsisText'
import { Loader } from '../../../../atoms/Loader'
import { NavigationBackButton } from '../../../../atoms/NavigationBackButton'
import { ImagesCarousel } from '../../../../molecules/ImagesCarousel'
import { DeleteConfirmationModal } from '../../../../molecules/Modal'
import {
  actionsFindCarForm,
  selectTradeInInfoScreenData
} from '../../../../store/units/findCarForm'
import { OrNull } from '../../../../types/OrNull'
import { COLORS } from '../../../../types/colors'
import { ResponseStatusMap } from '../../../../types/status'
import { noop } from '../../../../utils'
import {
  useApiContext,
  useAppDispatch,
  useAppSelector,
  useBackPath,
  useMakeService,
  useUserInfo,
  useVehicleDetailsRoute
} from '../../../../utils/hooks'
import { isNewTab } from '../../../../utils/isNewTab'
import { VehicleDetailsMainInfo } from '../VehicleDetailsMainInfo'
import { VehicleDetailsOverview } from '../VehicleDetailsOverview'
import { DesktopVehicleDetails, TabletVehicleDetails } from './Layouts'
import { DisplayStandardEquipment } from './StandardEquipment'

export const VehicleDetails: FC = () => {
  const theme = useTheme()
  const isBreakpointDownLg = useMediaQuery(theme.breakpoints.down('lg'))
  const navigate = useNavigate()
  const { currentLocation } = useBackPath()
  const { vehicleId = '' } = useParams()
  const { tradeinVehicle } = useAppSelector(selectTradeInInfoScreenData)
  const isTradein = tradeinVehicle?.id === vehicleId
  const dispatch = useAppDispatch()
  const { addresses, isDealer, accountID } = useUserInfo()
  const { carSnoopApi } = useApiContext()
  const { getPathToVehicleEdit } = useVehicleDetailsRoute()
  const [currentVehicle, setCurrentVehicle] =
    useState<OrNull<ReadVehicleResponse>>(null)
  const [
    isDeleteVehicleConfirmationModalOpen,
    setDeleteVehicleConfirmationModalOpen
  ] = useState(false)
  const readVehicleSilent = useCallback(
    async (id: string) => {
      const response = await carSnoopApi.vehicles.readVehicle(id)

      if (response.status === ResponseStatusMap.Success) {
        setCurrentVehicle(response)

        return response
      }
      return { status: ResponseStatusMap.Error }
    },
    [setCurrentVehicle]
  )
  const [readVehicle, readVehicleObservable] = useMakeService(readVehicleSilent)
  const [deleteVehicle, deleteVehicleObservable] = useMakeService(
    async (id: string) => {
      const response = await carSnoopApi.vehicles.deleteVehicle(id)

      if (response.status === ResponseStatusMap.Success) {
        if (isTradein) {
          dispatch(actionsFindCarForm.deleteTradein())
        }
        navigate(-1)
        return response
      }

      return { status: ResponseStatusMap.Error }
    },
    {
      withStatusNotification: true
    }
  )

  const handleDeleteVehicleConfirm = useCallback(() => {
    if (vehicleId) {
      deleteVehicle(vehicleId)
    }
  }, [deleteVehicle, vehicleId])

  const handleBackClick = () => {
    const displayedValues = {
      id: currentVehicle?.id || '',
      mileage: currentVehicle?.mileage || 0,
      tradeInValue: currentVehicle?.tradeinValue || 0,
      documentContent: currentVehicle?.images[0]?.url || ''
    }
    dispatch(actionsFindCarForm.updateDisplayedTradeInValues(displayedValues))
    navigate(-1)
  }

  useEffect(() => {
    vehicleId && readVehicle(vehicleId)
  }, [vehicleId])

  const {
    year,
    make,
    model,
    trim,
    vin,
    mileage,
    condition,
    retailValue,
    tradeinValue,
    addressID,
    notes,
    vehicleStatus,
    interiorColor,
    exteriorColor,
    fuelType,
    engineDescription,
    transmission,
    mpg,
    style,
    carStatus,
    drivetrain,
    stockNumber,
    images,
    accountID: vehicleAccountID,
    standardEquipment = null
  } = (currentVehicle || {}) as ReadVehicleResponse

  const vehicleProps = {
    interiorColor,
    exteriorColor,
    fuelType,
    engineDescription,
    transmission,
    mpg,
    style,
    drivetrain
  }

  const carAddress = useMemo(() => {
    return addresses.find((a) => a.id === addressID)
  }, [addresses, addressID])

  const allowEdit = accountID === currentVehicle?.accountID
  const onDeleteVehicleClick = useCallback(() => {
    setDeleteVehicleConfirmationModalOpen(true)
  }, [setDeleteVehicleConfirmationModalOpen])

  const handleImagesChange = useCallback(() => {
    readVehicleSilent(vehicleId)
  }, [vehicleId, readVehicleSilent])

  const isDeleteButtonEnabled = useMemo(
    () => vehicleStatus === ReadInventoryItemStatusesMap.Available,
    [vehicleStatus]
  )

  const isLoading =
    readVehicleObservable.isLoading ||
    !currentVehicle ||
    deleteVehicleObservable.isLoading

  const imagesComponent = useMemo(
    () => (
      <ImagesCarousel
        vehicleId={vehicleId}
        images={images}
        onLoadCallback={handleImagesChange}
        allowEdit={allowEdit}
      />
    ),
    [images, vehicleId, handleImagesChange]
  )

  const isEditable =
    carStatus === ReadInventoryItemStatusesMap.Available && allowEdit
  const vehicleDetailsComponent = useMemo(
    () => <VehicleDetailsOverview fullView={!!vin} {...vehicleProps} />,
    [vin, vehicleProps]
  )

  const sellerNotesComponent = useMemo(
    () => <CollapsibleNotes>{notes}</CollapsibleNotes>,
    [notes]
  )

  const vehicleInfoComponent = useMemo(
    () => (
      <VehicleDetailsMainInfo
        address={carAddress}
        allowEdit={allowEdit}
        condition={condition}
        isDealer={isDealer}
        isDeleteButtonEnabled={isDeleteButtonEnabled}
        mileage={mileage}
        onDeleteVehicle={onDeleteVehicleClick}
        retailValue={retailValue}
        status={carStatus}
        stockNumber={stockNumber}
        tradeinValue={tradeinValue}
        vehicleAccountID={vehicleAccountID}
        vin={vin}
      />
    ),
    [
      allowEdit,
      carAddress,
      carStatus,
      condition,
      isDealer,
      isDeleteButtonEnabled,
      mileage,
      onDeleteVehicleClick,
      retailValue,
      stockNumber,
      tradeinValue,
      vin,
    ]
  )

  const standardEquipmentComponent = useMemo(
    () => <DisplayStandardEquipment equipment={standardEquipment} />,
    [standardEquipment]
  )

  const buttonTextComponent = useMemo(() => {
    const vehicleName = `${year} ${make} ${model} ${trim}`

    return (
      <Grid item sx={{ marginRight: 2, overflow: 'hidden' }}>
        <Typography component='h1' variant='title1'>
          <EllipsisText tooltipText={vehicleName}>{vehicleName}</EllipsisText>
        </Typography>
      </Grid>
    )
  }, [year, make, model, trim])

  return (
    <Condition
      condition={isLoading}
      trueContent={
        <Grid container justifyContent='center'>
          <Loader />
        </Grid>
      }
      falseContent={
        <>
          <DeleteConfirmationModal
            isOpen={isDeleteVehicleConfirmationModalOpen}
            setOpen={setDeleteVehicleConfirmationModalOpen}
            onSubmit={handleDeleteVehicleConfirm}
            hint='Deleted vehicle cannot be recovered.'
            message='Are you sure you would like to delete this vehicle?'
          />
          <Grid container spacing={3}>
            {/* Header */}
            <Grid container item justifyContent='space-between'>
              <Grid container alignItems='center' justifyContent='space-between'>
                <Grid item
                  sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                  xs={11} md={10}
                >
                  <NavigationBackButton
                    showBackButton={!isNewTab()}
                    buttonText={buttonTextComponent}
                    onBackClick={handleBackClick}
                  />
                </Grid>
                <Condition
                  condition={isEditable}
                  trueContent={
                    <Grid item
                      display='flex'
                      justifyContent='end'
                      xs={1} md={2}
                    >
                      <NavLink
                        to={getPathToVehicleEdit(vehicleId)}
                        state={{ backPath: currentLocation }}
                        style={{
                          textDecoration: 'none'
                        }}
                      >
                        <BlueButton
                          endIcon={
                            <PenIcon stroke={COLORS.text_blue} />
                          }
                          onClick={noop}
                          sx={{ minWidth: 'unset' }}
                        >
                          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>Edit</Box>
                        </BlueButton>
                      </NavLink>
                    </Grid>
                  }
                />
              </Grid>
            </Grid>
            {/* Details */}
            <Condition
              condition={isBreakpointDownLg}
              trueContent={
                <TabletVehicleDetails
                  imagesComponent={imagesComponent}
                  standardEquipmentComponent={standardEquipmentComponent}
                  vehicleDetailsComponent={vehicleDetailsComponent}
                  sellerNotesComponent={sellerNotesComponent}
                  vehicleInfoComponent={vehicleInfoComponent}
                />
              }
              falseContent={
                <DesktopVehicleDetails
                  imagesComponent={imagesComponent}
                  standardEquipmentComponent={standardEquipmentComponent}
                  vehicleDetailsComponent={vehicleDetailsComponent}
                  sellerNotesComponent={sellerNotesComponent}
                  vehicleInfoComponent={vehicleInfoComponent}
                />
              }
            />
          </Grid>
        </>
      }
    />
  )
}
