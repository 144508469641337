import { MAX_PDF_SIZE_MB, MAX_VIDEO_SIZE_MB } from './applicationConstants'

export const IMAGE_OVERSIZE_ERROR =
  'File size is too big. Please try another photo.'

export const IMAGE_WRONG_FORMAT_ERROR =
  'Unsupported file format! Accepted file formats: .jpeg, .png, .heic, .heif'

export const IMAGE_SOMETHING_WRONG_ERROR =
  'Something went wrong! Please, try again.'

export const DOCUMENT_OVERSIZE_ERROR =
  'File size is too big. Please try another file.'

export const DOCUMENT_WRONG_FORMAT_ERROR =
  'Unsupported file format! Accepted file formats: .pdf, .jpeg, .png, .heic, .heif'

export const DOCUMENT_PDF_OVERSIZE_ERROR = `File size is too big. The maximum size for the file is ${MAX_PDF_SIZE_MB}MB. Please try another file.`

export const VIDEO_OVERSIZE_ERROR = `File size is too big. The maximum size for video file is ${MAX_VIDEO_SIZE_MB}MB. Please try another file.`

export const DATE_PICKER_INFO_MESSAGE = 'You must be at least 16 years old.'

export const CREATE_PASSWORD_HINT =
  'You’re allowed to use the combination of letters, numbers, and special characters. The minimum length of the password is 8, maximum length is 20.'

export const VEHICLE_DOESNT_HAVE_VIN_WARNING =
  'The vehicle does not have a VIN number, please verify the VIN with the seller'

export const REQUEST_WRONG_ERROR_MESSAGE =
  'Something went wrong. Please try again later.'

export const ACCESS_DENIED = 'You have no admin permission'
