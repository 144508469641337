/* eslint-disable */
export const debounce = (func: any, timeout = 300) => {
  let timer: NodeJS.Timeout
  return (...args: any) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply({}, args)
    }, timeout)
  }
}

export const asyncDebounce = <ReturnType>(func: any, timeout = 300) => {
  const debounced = debounce(
    (
      resolve: (value: unknown) => void,
      reject: (reason?: any) => void,
      args: any
    ) => {
      func(...args)
        .then(resolve)
        .catch(reject)
    },
    timeout
  )
  return (...args: any): Promise<ReturnType> =>
    new Promise((resolve, reject) => {
      debounced(resolve, reject, args)
    })
}
