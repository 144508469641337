import { Grid, Typography } from '@mui/material'
import { FC, useMemo } from 'react'
import { DefaultVehicle } from '../../assets/imgs/DefaultVehicle'
import { EllipsisText } from '../../atoms/EllipsisText'
import { ReferenceModel } from '../../types/ReferenceModel'
import { COLORS, SHADOWS } from '../../types/colors'
import { nbsp } from '../../types/strings'
import { ColorView } from './components/ColorView'

export interface RequestedVehicleItemProps {
  vehicle: {
    make: string
    model: string
    years: string[]
    interiorColors: ReferenceModel[]
    exteriorColors: ReferenceModel[]
    trim?: string
    image?: string
  }
}

export const RequestedVehicleItem: FC<RequestedVehicleItemProps> = (props) => {
  const { vehicle } = props
  const { interiorColors, exteriorColors } = vehicle

  const vehicleName = useMemo(() => {
    const { make, model, trim } = vehicle
    return [make, model, trim].filter(Boolean).join(' ')
  }, [vehicle])

  const vehicleYears = useMemo(() => {
    const { years } = vehicle
    switch (years.length) {
      case 1: {
        return years[0]
      }
      default: {
        return [years.slice(0, 1), years.slice(-1)].join('-')
      }
    }
  }, [vehicle])

  return (
    <Grid
      container
      item
      gap={2}
      justifyContent='space-between'
      direction={{ xs: 'row', md: 'row-reverse' }}
      margin={0}
      padding={2}
      sx={{
        borderRadius: '10px',
        boxShadow: SHADOWS.primary,
      }}
      xs={12}
    >
      <Grid
        container
        item
        alignItems='center'
        justifyContent='center'
        padding={2}
        sx={{
          backgroundColor: COLORS.border_gray,
        }}
        xs={12} md={4}
      >
        <DefaultVehicle />
      </Grid>
      <Grid
        container
        item
        gap={2}
        xs={12} md={6}
      >
        <Grid item xs={12}>
          <Typography variant='label1'>
            <EllipsisText tooltipText={vehicleName}>
              {vehicleName}
            </EllipsisText>
          </Typography>
        </Grid>
        <Grid container item spacing={1} xs={12}>
          <Grid container item>
            <Typography variant='subtitle2'>YEAR:{nbsp}</Typography>
            <Typography variant='body1'>{vehicleYears}</Typography>
          </Grid>
          <ColorView colors={exteriorColors} title='EXTERIOR COLOR:' />
          <ColorView colors={interiorColors} title='INTERIOR COLOR:' />
        </Grid>
      </Grid>
    </Grid>
  )
}
