import { useEffect } from 'react'
import { useSynchronizer } from './useSynchronizer'

export const useSynchronize = ({
  autoSynchronize
}: {
  autoSynchronize: boolean
}) => {
  const { synchronize, isSynchronizing } = useSynchronizer()

  useEffect(() => {
    if (autoSynchronize) {
      synchronize()
    }
  }, [autoSynchronize])

  return {
    isSynchronizing
  }
}
