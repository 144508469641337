import { FormikProps } from 'formik'
import { useRef } from 'react'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import { SearchComponentProps } from '../../../features/SearchInventory'
import { useAppDispatch } from '../../../utils/hooks'

interface UseSearchComponentProps {
  readTransactions: (values: SearchComponentProps['initialValues']) => void
  setSearchFormValues: ActionCreatorWithPayload<
    SearchComponentProps['initialValues']
  >
  searchFormValues: SearchComponentProps['initialValues']
}

export const useTransactionSearch = ({
  readTransactions,
  setSearchFormValues,
  searchFormValues
}: UseSearchComponentProps): SearchComponentProps => {
  const dispatch = useAppDispatch()
  const searchRef =
    useRef<FormikProps<SearchComponentProps['initialValues']>>(null)

  const onSearchApply = async (
    values: SearchComponentProps['initialValues']
  ) => {
    if (values !== searchFormValues) {
      dispatch(setSearchFormValues(values))

      await readTransactions(values)
    }
  }

  return {
    formRef: searchRef,
    onSearchApply,
    initialValues: searchFormValues
  }
}
