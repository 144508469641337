import { parseRelativePhoneNumber } from '../../../../utils/formatters/parseRelativePhoneNumber'
import {
  RawContractType,
  NormalizedContractDocumentType,
  RawContractDocumentType,
  NormalizedContractType
} from '../types'

const formatContractDocument = (
  document: RawContractDocumentType
): NormalizedContractDocumentType => {
  return {
    name: document.name,
    id: document.id || null,
    createdOn: document.created_on ? new Date(document.created_on) : null,
    type: document.type || null,
    notes: document.notes || null
  }
}

export const contractMapper = (
  contract: RawContractType
): NormalizedContractType => {
  return {
    id: contract.id,
    status: contract.status,
    createdBy: contract.created_by,
    createdOn: new Date(contract.created_on),
    changedBy: contract.changed_by,
    changedOn: contract.changed_on ? new Date(contract.changed_on) : null,
    buyerDetails: {
      legalName: contract.buyer_legal_name,
      legalStreet: contract.buyer_legal_street,
      legalZipCode: contract.buyer_legal_zip_code,
      legalCity: contract.buyer_legal_city,
      legalState: contract.buyer_legal_state
    },
    sellerDetails: {
      legalName: contract.seller_legal_name,
      legalStreet: contract.seller_legal_street,
      legalZipCode: contract.seller_legal_zip_code,
      legalCity: contract.seller_legal_city,
      legalState: contract.seller_legal_state
    },
    additionalBuyer: contract.additional_buyer,
    additionalBuyerDetails: contract.additional_buyer
      ? {
          legalName: contract.additional_buyer_legal_name || '',
          legalStreet: contract.additional_buyer_legal_street || '',
          legalZipCode: contract.additional_buyer_legal_zip_code || '',
          legalCity: contract.additional_buyer_legal_city || '',
          legalState: contract.additional_buyer_legal_state || ''
        }
      : null,
    depositPaymentID: contract.deposit_payment_id,
    buyerRequiredDocuments: contract.buyer_required_documents.map(
      formatContractDocument
    ),
    notes: contract.notes,
    transactionID: contract.transaction_id,
    paymentMethod: contract.payment_method,
    primaryBuyer: contract.primary_buyer,
    sellerRequiredDocuments: contract.seller_required_documents.map(
      formatContractDocument
    ),
    pickup: {
      addressName: contract.pickup.address_name,
      city: contract.pickup.city,
      state: contract.pickup.state,
      street: contract.pickup.street,
      zipCode: contract.pickup.zip_code,
      contactEmail: contract.pickup.contact_email,
      contactName: contract.pickup.contact_name,
      contactNumber: parseRelativePhoneNumber(contract.pickup.contact_number),
      notes: contract.pickup.notes
    },
    dropOff: {
      addressName: contract.drop_off.address_name,
      city: contract.drop_off.city,
      state: contract.drop_off.state,
      street: contract.drop_off.street,
      zipCode: contract.drop_off.zip_code,
      contactEmail: contract.drop_off.contact_email,
      contactName: contract.drop_off.contact_name,
      contactNumber: parseRelativePhoneNumber(contract.drop_off.contact_number),
      notes: contract.drop_off.notes
    },
    shipmentType: contract.shipment_type
  }
}
