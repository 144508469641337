import { Typography } from '@mui/material'
import { FC } from 'react'
import { Condition } from '../../../atoms/Condition'

interface IFileDescription {
  error?: JSX.Element | string
  fileDescription?: string
}

export const FileDescription: FC<IFileDescription> = ({
  error,
  fileDescription
}) => {
  if (!error) {
    return <Typography variant='subtitle2'>{fileDescription}</Typography>
  }

  return (
    <Condition
      condition={typeof error === 'string'}
      trueContent={
        <Typography variant='error' sx={{ fontSize: 14 }}>
          {error}
        </Typography>
      }
      falseContent={error as JSX.Element}
    />
  )
}
