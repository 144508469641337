import { StandardEquipment } from '../../../../api/carSnoopApi/vehicles'

export const standardEquipmentMock: StandardEquipment = {
  dimensions: [
    {
      'Base Curb Weight (lbs)': '3,144'
    },
    {
      'Cargo Volume (cu ft)': '12.4'
    },
    {
      'Estimated Fuel Economy (city miles)': '24'
    },
    {
      'Estimated Fuel Economy (combined miles)': '28'
    },
    {
      'Estimated Fuel Economy (hwy miles)': '34'
    },
    {
      'Fuel Capacity (gal)': '13.2'
    },
    {
      'Height (in)': '55.6'
    },
    {
      'Length (in)': '182.2'
    },
    {
      'Seating Capacity': '5'
    },
    {
      'Wheelbase (in)': '105.1'
    },
    {
      'Width (in)': '70.6'
    }
  ],
  'drivetrain_-_engine': [
    {
      'Cam Configuration': 'DOHC'
    },
    {
      'Cylinder Configuration': 'I'
    },
    {
      Cylinders: '4'
    },
    {
      Displacement: '2.4'
    },
    {
      'Fuel Delivery': 'Direct injection'
    },
    {
      'Fuel Type': 'Gas'
    },
    {
      'Horsepower @ RPM': '201 @ 6800'
    },
    {
      'Torque @ RPM': '180 @ 3600'
    }
  ],
  'drivetrain_-_transmission': [
    {
      '# of Gears': '8'
    },
    {
      'Manual/Automatic': 'A'
    },
    {
      'Rollback Protection': 'Hill-start Assist Control (HAC)'
    }
  ],
  exterior: [
    {
      'Tire Size': '225/40R18'
    },
    {
      'Wheel Material': 'Aluminum alloy'
    },
    {
      'Wheel Size': '18"'
    }
  ],
  interior: [
    {
      'Air Conditioning': 'Air filter'
    },
    {
      'Air Conditioning': 'Automatic'
    },
    {
      'Air Conditioning': 'Dual zone'
    },
    {
      'Air Conditioning': 'Humidity sensor'
    },
    {
      'Audio - Brand': 'Premium'
    },
    {
      'Capabilities/Wiring': 'Apple CarPlay and Android'
    },
    {
      'Moonroof/Sunroof': 'Moonroof, power'
    },
    {
      Seats: 'Sport'
    },
    {
      'Seats - Driver': '8-way adjuster, power'
    },
    {
      'Seats - Front': 'Heated'
    },
    {
      'Seats - Front': 'Lumbar support, power'
    },
    {
      'Seats - Passenger': '4-way adjuster, power'
    },
    {
      'Seats - Rear': 'Fold flat'
    },
    {
      'Theft Deterrent Systems': ''
    },
    {
      Trim: 'Headliner, color-keyed'
    },
    {
      Trim: 'Shift knob, leather-wrapped'
    },
    {
      Upholstery: 'Leather, upgraded'
    },
    {
      Windows: 'Power'
    }
  ],
  mechanical: [
    {
      Brakes: '4-wheel'
    },
    {
      Brakes: 'Electronic Brake Distribution (EBD)'
    },
    {
      Brakes: 'Quick Brake Assist'
    },
    {
      'Brakes - ABS': ''
    },
    {
      'Brakes - Front': 'Disc, vented'
    },
    {
      'Brakes - Rear': 'Disc, solid'
    },
    {
      'Cruise Control': 'Adaptive'
    },
    {
      'Drive System': 'Front Wheel'
    }
  ],
  safety: [
    {
      'Airbag - Driver': 'Advanced'
    },
    {
      'Airbag - Front Driver Side': ''
    },
    {
      'Airbag - Front Passenger': 'Advanced'
    },
    {
      'Airbag - Front Passenger Side': ''
    },
    {
      Airbags: 'Side Curtain'
    },
    {
      Airbags: 'Supplemental Restraint System'
    },
    {
      Brakes: 'Collision Mitigation Braking System (CMBS)'
    },
    {
      'Immobilizing System': 'Electronic'
    },
    {
      Lights: 'Daytime running lights'
    },
    {
      'Mfr Trademark': 'Advanced Compatibility Engineering (ACE)'
    },
    {
      'Mfr Trademark': 'Cross Traffic Alert'
    },
    {
      'Mfr Trademark': 'Forward Collision Warning'
    },
    {
      Monitors: 'Blind Spot Assist'
    },
    {
      Monitors: 'Lane Change Assist'
    },
    {
      Monitors: 'Lane Departure System'
    },
    {
      Monitors: 'Lane Keep Assist'
    },
    {
      Monitors: 'Tire pressure'
    },
    {
      'Parking Features': 'Camera, rearview'
    },
    {
      'Tool Kit': 'Tire repair kit'
    },
    {
      'Tool Kit': 'Tire sealant & inflator kit'
    },
    {
      'Traction Control': 'Traction Control System (TCS)'
    },
    {
      'Traction Control': 'Vehicle Stability Assist (VSA)'
    }
  ],
  warranty: [
    {
      'Anti-Corrosion': '5-year/Unlimited-mile'
    },
    {
      Basic: '4-year/50,000-mile, Limited'
    },
    {
      Powertrain: '6-year/70,000-mile, Limited'
    },
    {
      'Roadside Assistance': '4-year/50,000-mile'
    }
  ]
}
