import { Grid, Typography } from '@mui/material'
import { FC } from 'react'

interface FormDescriptionProps {
  title: string
  description: string
}

export const FormDescription: FC<FormDescriptionProps> = ({
  title,
  description
}) => {
  return (
    <Grid container item xs={12} rowSpacing={1} flexDirection='column' my={4}>
      <Typography variant='label1'>{title}</Typography>
      <Typography variant='details'>{description}</Typography>
    </Grid>
  )
}
