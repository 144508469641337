import { Step, StepLabel, Stepper, SxProps, useMediaQuery, useTheme } from '@mui/material'
import { FC } from 'react'
import { COLORS } from '../../types/colors'

export interface Step {
  label: string
}
export interface FindCarFormStepperProps {
  steps: Step[]
  currentStep: number
  showAllSteps?: boolean
}

const sxStepLabel: SxProps = {
  '& .MuiSvgIcon-root': {
    color: COLORS.border_gray
  },
  '& .MuiSvgIcon-root.Mui-active': {
    color: COLORS.main_yellow
  },
  '& .MuiSvgIcon-root.Mui-completed': {
    color: COLORS.main_yellow
  },
  '& .MuiStepIcon-text': {
    fill: COLORS.text_primary,
    fontSize: '12px',
    fontWeight: '700'
  },
  '& .MuiStepLabel-label': {
    fontSize: '14px',
    fontWeight: '400',
  },
  '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
    marginTop: 1
  }
}

export const FindCarFormStepper: FC<FindCarFormStepperProps> = ({ steps, currentStep, showAllSteps = false }) => {
  const theme = useTheme();
  const isBreakpointDownMd = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Stepper activeStep={currentStep} alternativeLabel={isBreakpointDownMd}>
      {steps.map(({ label }, i) => {
        const shouldShowLabel = showAllSteps || i === currentStep || !isBreakpointDownMd;
        return (
          <Step key={label}>
            <StepLabel sx={sxStepLabel}>{shouldShowLabel && label}</StepLabel>
          </Step>
        )
      })}
    </Stepper>
  )
}
