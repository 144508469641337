import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Grid, Typography } from '@mui/material'
import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { BlueButton } from '../../../atoms/Button'
import { Condition } from '../../../atoms/Condition'
import { Pagination } from '../../../atoms/Pagination'
import { PER_PAGE } from '../../../constants/applicationConstants'
import type { Address } from '../../../store/types'
import {
  actionsEditingAddress,
  selectEditingAddress
} from '../../../store/units/editingAddress'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { AddressCard } from './components/AddressCard'
import { EMPTY_ADDRESS, MODES, ModeType } from './constants'

interface AddressesProps {
  addresses: Address[]
  isUserAdmin?: boolean
}

export const Addresses: FC<AddressesProps> = ({ addresses, isUserAdmin = false }) => {
  const [mode, setMode] = useState<ModeType>(MODES.VIEW)
  const [currentPage, setCurrentPage] = useState(1)
  const dispatch = useAppDispatch()
  const { editingAddress } = useAppSelector(selectEditingAddress)
  const addressesPagesCount = useMemo(
    () => Math.ceil(addresses.length / PER_PAGE),
    [addresses]
  )

  const showAddressesPagination = useMemo(
    () => addresses.length > PER_PAGE,
    [addresses.length]
  )

  const pageAddresses: Address[] = useMemo(() => {
    const pageFirstAddressIndex = (currentPage - 1) * PER_PAGE
    const slicedAddresses = addresses.slice(
      pageFirstAddressIndex,
      pageFirstAddressIndex + PER_PAGE
    )

    return slicedAddresses
  }, [addresses, currentPage])

  const handleAddressCreateClick = useCallback(() => {
    setMode(MODES.CREATE)
    dispatch(actionsEditingAddress.setEditingAddress(null))
  }, [setMode])

  const handleAddressEditClick = useCallback(
    (address: Address) => {
      setMode(MODES.EDIT)
      dispatch(actionsEditingAddress.setEditingAddress(address))
    },
    [setMode, dispatch, actionsEditingAddress.setEditingAddress]
  )

  const handleAddressCancelClick = useCallback(() => {
    setMode(MODES.VIEW)
    dispatch(actionsEditingAddress.setEditingAddress(null))
  }, [setMode, dispatch, actionsEditingAddress.setEditingAddress])

  const handleAddressChangePage = useCallback(
    (page: number) => {
      setCurrentPage(page)
    },
    [setCurrentPage]
  )

  const isCreateMode = mode === MODES.CREATE

  useEffect(() => {
    if (!(addresses.length % PER_PAGE) && currentPage !== 1) {
      setCurrentPage((current) => current - 1)
    }
  }, [addresses.length])

  return (
    <Grid container>
      <Grid
        container
        item
        alignItems='center'
        justifyContent='space-between'
        padding={1}
        xs={12}
      >
        <Typography variant='emphasized' fontSize='18px'>
          Address
        </Typography>
        <Condition
          condition={isUserAdmin && !isCreateMode}
          trueContent={
            <BlueButton
              startIcon={<AddCircleOutlineIcon />}
              onClick={handleAddressCreateClick}
            >
              Add Address
            </BlueButton>
          }
        />
      </Grid>
      <Grid
        container
        item
        alignItems='space-between'
      >
        <Grid item xs={12}>
          <Condition
            condition={isCreateMode}
            trueContent={
              <Grid item xs={12} sx={{ mb: 2 }}>
                <AddressCard
                  mode={MODES.CREATE}
                  address={EMPTY_ADDRESS}
                  isUserAdmin={isUserAdmin}
                  onEditClick={handleAddressEditClick}
                  onCancelClick={handleAddressCancelClick}
                />
              </Grid>
            }
          />
          <Grid item container spacing={2} xs={12}>
            {pageAddresses.map((a, i) => (
              <Grid item xs={12} key={a.id}>
                <AddressCard
                  mode={editingAddress?.id === a.id ? MODES.EDIT : MODES.VIEW}
                  address={a}
                  canBeDeleted={i + (currentPage - 1) !== 0}
                  isUserAdmin={isUserAdmin}
                  onEditClick={handleAddressEditClick}
                  onCancelClick={handleAddressCancelClick}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Condition
          condition={showAddressesPagination}
          trueContent={
            <Grid item container alignItems='end' justifyContent='end' xs={12}>
              <Pagination
                count={addressesPagesCount}
                onChange={handleAddressChangePage}
              />
            </Grid>
          }
        />
      </Grid>
    </Grid>
  )
}
