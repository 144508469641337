import { FC, useCallback, useMemo, useState } from 'react'
import { Grid } from '@mui/material'
import { DiscardConfirmationModal } from '../../molecules/Modal'
import { Condition } from '../../atoms/Condition'
import { ADD_CAR_STEPS } from '../../types/enums/addCar'
import { NavigationBackButton } from '../../atoms/NavigationBackButton'
import { useAppSelector } from '../../utils/hooks'
import { selectBreadcrumbsState } from '../../store/features/Breadcrumbs'
import { Breadcrumbs } from '../../atoms/Breadcrumbs'
import { useAddVehicleDiscardModal } from './screens/hooks'

export interface AddVehiclePageHeaderProps {
  step: number
}

export const AddVehiclePageHeader: FC<AddVehiclePageHeaderProps> = ({
  step
}) => {
  const { handleDiscard } = useAddVehicleDiscardModal()
  const breadcrumbsState = useAppSelector(selectBreadcrumbsState)
  const [isDiscardConfirmationOpen, setIsDiscardConfirmationOpen] =
    useState(false)

  const handleBackClick = useCallback(() => {
    if (step === ADD_CAR_STEPS.stepThree) handleDiscard()
    setIsDiscardConfirmationOpen(true)
  }, [setIsDiscardConfirmationOpen, handleDiscard, step])
  const navigationButtonContent = useMemo(() => {
    return breadcrumbsState.breadcrumbs.length ? (
      <Breadcrumbs breadcrumbs={breadcrumbsState.breadcrumbs} />
    ) : (
      'Add new vehicle'
    )
  }, [breadcrumbsState.breadcrumbs])

  return (
    <Grid container alignItems='center'>
      <Condition
        condition={step !== ADD_CAR_STEPS.stepThree}
        trueContent={
          <DiscardConfirmationModal
            isOpen={isDiscardConfirmationOpen}
            setOpen={setIsDiscardConfirmationOpen}
            onSubmit={handleDiscard}
          />
        }
      />

      <NavigationBackButton
        textVariant='h6'
        buttonMinWidth='24px'
        gridSpacing={3}
        buttonText={navigationButtonContent}
        onBackClick={handleBackClick}
      />
    </Grid>
  )
}
