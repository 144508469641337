import { createAsyncThunk } from '@reduxjs/toolkit'
import { externalCarSnoopApi } from '../../api'
import { ResponseStatusMap } from '../../types/status'

type ReadLocationAsyncResponse = {
  status: string
  city: string
  state: string
}

export const readLocationAsync = createAsyncThunk(
  'fetchDealershipList/byZipCode',
  async (zipCode: string): Promise<ReadLocationAsyncResponse> => {
    const response = await externalCarSnoopApi.addresses.validateZipCode(
      zipCode
    )

    if (response.status === ResponseStatusMap.Success) {
      return {
        status: 'succeeded',
        city: response.city,
        state: response.state
      }
    }

    throw new Error()
  }
)
