export const returnBuyerAddressString = ({
  street,
  city,
  state,
  zipCode
}: {
  street: string | null
  city: string
  state: string
  zipCode: string
}): string => {
  if (!street) {
    return `${city}, ${state} ${zipCode}`
  }

  return `${street}, ${city}, ${state} ${zipCode}`
}
