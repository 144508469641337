import { FC, useEffect } from 'react'
import { Dialog, Grid } from '@mui/material'
import { Loader } from '../../atoms/Loader'
import { PageContainer } from '../../layout/PageContainer'
import { usePaymentRedirect } from './hooks/usePaymentRedirect'

export interface PaymentRedirectProps {
  open: boolean
  onRedirectFail: (error: Error) => void
  getPaymentRedirectUrl: (() => Promise<string>) | string
  onSuccessRedirect?: () => void
}

export const PaymentRedirect: FC<PaymentRedirectProps> = (props) => {
  const { open, onRedirectFail, getPaymentRedirectUrl, onSuccessRedirect } =
    props
  const { tryToRedirect } = usePaymentRedirect()

  useEffect(() => {
    if (open) {
      tryToRedirect(getPaymentRedirectUrl)
        .then(onSuccessRedirect)
        .catch(onRedirectFail)
    }
  }, [open])

  return (
    <Dialog open={open} fullScreen>
      <PageContainer>
        <Grid container justifyContent='center'>
          <Loader />
        </Grid>
      </PageContainer>
    </Dialog>
  )
}
