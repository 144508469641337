import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ChatListItemType } from './types'
import { OrNull } from '../../../types/OrNull'
import { MediaMessageType, TextMessageType } from '../../../api/chat/types'
import { ChatState } from '../../Chat/model/chatStore'
import { logout } from '../../../store/thunks/logout'

interface MiniChatState extends ChatState {
  isChatOpen: boolean
  chatList: OrNull<ChatListItemType[]>
  hasUnread: boolean
  unreadChats: Record<string, number>
}

export const initialState: MiniChatState = {
  isChatOpen: false,
  chatList: null,
  chatId: null,
  messages: null,
  hasUnread: false,
  unreadChats: {}
}

export const miniChatSlice = createSlice({
  name: 'miniChatStore',
  initialState,
  reducers: {
    setChatList: (store, action: PayloadAction<ChatListItemType[]>) => {
      store.hasUnread = action.payload.some((m) => !!m.unreadCount)
      store.unreadChats = action.payload.reduce((acc, chat) => {
        return { ...acc, [chat.transactionId]: chat.unreadCount }
      }, {})
      store.chatList = action.payload
    },
    setChatId: (store, action: PayloadAction<string>) => {
      store.chatId = action.payload
    },
    setMessages: (
      store,
      action: PayloadAction<Array<TextMessageType | MediaMessageType>>
    ) => {
      store.messages = action.payload
    },
    resetMessages: (state) => {
      state.messages = null
    },
    resetChatList: (state) => {
      state.chatList = null
    },
    openChat: (state) => {
      state.isChatOpen = true
    },
    closeChat: (state) => {
      state.isChatOpen = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, () => {
      return initialState
    })
  }
})
