import imageCompression from 'browser-image-compression'
import heic2any from 'heic2any'
import {
  availableImageFormats,
  FILE_EXTENSIONS,
  MIN_IMG_SIZE_FOR_COMPRESSION_KB,
  MAX_IMG_COMPRESSION_ITERATIONS
} from '../constants/applicationConstants'
import { blobToBase64 } from './formatters/blobToBase64'
import { extractCleanBase64 } from './formatters/extractCleanBase64'
import { getFileExtension } from './stringFormatters/getFileExtension'
import { returnFileName } from './formatters/returnFileName'
import { formatFileInfo } from './formatters/formatFileInfo'

export type CompressImageOptions = Parameters<typeof imageCompression>[1] & {
  maxSizeMB: number
  additionalExtensions?: string[]
  additionalExtensionsMaxSize?: number
}

export interface CompressImageSuccessResponse {
  name: string
  size: number
  isAcceptableMinify: boolean
  sizeDiff: number
  type: string
  fileExtension: string
  isFileExtensionAvailable: boolean
  raw: File
  blob: File
  base64: string
  cleanBase64: string
}

export type CompressImageResponse = Array<CompressImageSuccessResponse>

export type CompressImagesType = (
  files: File[],
  settings: CompressImageOptions,
  minSizeForCompression?: number
) => Promise<CompressImageResponse>

export const transformToCompressImageResponse = async (
  file: File,
  compressedFile: File,
  options: CompressImageOptions,
  fileExtension: string
): Promise<CompressImageSuccessResponse> => {
  const additionalExtensionsSizeMB = options.additionalExtensionsMaxSize || 0
  const maxSizeMB =
    options.additionalExtensions?.includes(fileExtension) ||
    options.additionalExtensions?.[0] === '*'
      ? additionalExtensionsSizeMB
      : options.maxSizeMB

  const base64 = await blobToBase64(compressedFile)
  const cleanBase64 = extractCleanBase64(base64, fileExtension)

  const size = compressedFile.size / 1024 // in KB
  const isAcceptableMinify = size <= maxSizeMB * 1024
  const isFileExtensionAvailable =
    [
      ...availableImageFormats,
      ...(options?.additionalExtensions || [])
    ].includes(fileExtension) || options.additionalExtensions?.[0] === '*'
  const fileName = returnFileName(file.name, fileExtension)

  return {
    name: fileName,
    size,
    type: compressedFile.type,
    fileExtension,
    isFileExtensionAvailable,
    isAcceptableMinify,
    sizeDiff: (file.size - compressedFile.size) / 1024, // in KB
    raw: file,
    blob: formatFileInfo(compressedFile, fileName),
    base64,
    cleanBase64: cleanBase64 || ''
  }
}

export const compressImages: CompressImagesType = async (
  files,
  settings,
  minSizeForCompression = MIN_IMG_SIZE_FOR_COMPRESSION_KB
) => {
  return Promise.all(
    Array.from(files || []).map(async (file) => {
      const isIOSImageFile =
        getFileExtension(file.name.toLowerCase()) === FILE_EXTENSIONS.heic ||
        getFileExtension(file.name.toLowerCase()) === FILE_EXTENSIONS.heif

      const convertedFile = isIOSImageFile
        ? ((await heic2any({
            blob: file,
            quality: 0.8,
            toType: 'image/jpeg'
          })) as File)
        : file

      const fileSizeKB = convertedFile.size / 1024
      const fileExtension =
        getFileExtension(convertedFile.type, '/') ||
        getFileExtension(convertedFile.name)

      const isFileCompressible = availableImageFormats.includes(
        fileExtension as FILE_EXTENSIONS
      )

      if (isFileCompressible && fileSizeKB > minSizeForCompression) {
        const blob = await imageCompression(convertedFile, {
          ...settings,
          maxIteration: MAX_IMG_COMPRESSION_ITERATIONS
        })

        const compressedFileData = transformToCompressImageResponse(
          file,
          blob,
          settings,
          fileExtension
        )
        // eslint-disable-next-line no-console
        console.log(await compressedFileData)
        return compressedFileData
      }

      const compressedFileData = transformToCompressImageResponse(
        file,
        convertedFile,
        settings,
        fileExtension
      )

      if (isFileCompressible) {
        settings.onProgress?.(100)
      }

      // eslint-disable-next-line no-console
      console.log(await compressedFileData)
      return compressedFileData
    })
  )
}
