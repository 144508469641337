import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { Button, ButtonProps, Typography, styled } from '@mui/material'
import { FC, PropsWithChildren } from 'react'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { COLORS } from '../../types/colors'

export const AgreeButton: FC<PropsWithChildren & ButtonProps> = ({
  children,
  sx = {},
  ...buttonProps
}) => (
  <Button
    variant='outlined'
    sx={{
      backgroundColor: COLORS.main_yellow,
      borderColor: COLORS.main_yellow,
      color: COLORS.text_primary,
      fontSize: '16px',
      ':hover': {
        backgroundColor: COLORS.hover_yellow,
        borderColor: COLORS.hover_yellow
      },
      ':active': {
        backgroundColor: COLORS.active_yellow
      },
      '&.Mui-disabled': {
        backgroundColor: COLORS.border_gray,
        color: COLORS.text_primary,
        border: `1px solid ${COLORS.border_gray}`
      },
      ...sx
    }}
    {...buttonProps}
  >
    {children}
  </Button>
)

export const CancelButton: FC<PropsWithChildren & ButtonProps> = ({
  children,
  sx = {},
  ...buttonProps
}) => (
  <Button
    variant='outlined'
    sx={{
      borderColor: COLORS.main_yellow,
      color: COLORS.text_primary,
      fontSize: '16px',
      ':hover': {
        borderColor: COLORS.main_yellow
      },
      ...sx
    }}
    {...buttonProps}
  >
    {children}
  </Button>
)

export const ColoredButton: FC<
  PropsWithChildren<ButtonProps & { customColor?: string }>
> = ({ children, customColor, sx, ...props }) => (
  <Button
    variant='text'
    disableElevation
    sx={{
      color: customColor,
      fontWeight: '400',
      textTransform: 'none',
      ...sx
    }}
    {...props}
  >
    {children}
  </Button>
)

export const BlueButton: FC<PropsWithChildren<ButtonProps>> = ({
  children,
  ...props
}) => (
  <ColoredButton {...props} customColor={COLORS.text_blue}>
    {children}
  </ColoredButton>
)

export const RedButton: FC<PropsWithChildren<ButtonProps>> = ({
  children,
  ...props
}) => (
  <ColoredButton {...props} color='error'>
    {children}
  </ColoredButton>
)

export const OrangeButton: FC<PropsWithChildren<ButtonProps>> = ({
  children,
  ...props
}) => (
  <ColoredButton {...props} customColor={COLORS.active_yellow}>
    {children}
  </ColoredButton>
)

export const AddButton: FC<PropsWithChildren<ButtonProps>> = ({
  children,
  ...props
}) => (
  <BlueButton startIcon={<AddCircleOutlineIcon />} {...props}>
    <Typography color='inherit' sx={{ cursor: 'pointer' }}>
      {children}
    </Typography>
  </BlueButton>
)

export const RemoveButton: FC<PropsWithChildren<ButtonProps>> = ({
  children,
  ...props
}) => (
  <RedButton startIcon={<DeleteOutlineIcon />} {...props}>
    <Typography variant='main' noWrap>
      {children}
    </Typography>
  </RedButton>
)

export const LinkButtonWithDisable = styled('button')<{
  disabled?: boolean
  activeColor?: string
  disabledColor?: string
}>(
  ({
    disabled,
    activeColor = COLORS.accent_blue,
    disabledColor = COLORS.text_gray
  }) => ({
    border: 'none',
    color: disabled ? disabledColor : activeColor,
    background: 'transparent',
    cursor: disabled ? 'auto' : 'pointer'
  })
)
