import { FC, useMemo } from 'react'
import {
  ChatMessageContainer,
  ChatMessageContainerOptions
} from '../ChatMessageContainer'
import {
  SupportedAudioMessage,
  UnsupportedAudioMessage
} from './components/AudioMessage'
import {
  SupportedFileMessage,
  UnsupportedFileMessage
} from './components/DocumentMessage'
import { ImageMessage } from './components/ImageMessage'
import { VideoMessage } from './components/VideoMessage'
import { useFileType } from './hooks/useFileType'
import { MediaMessageType } from '../../../../api/chat/types'

const fileMessageComponents = {
  audio: {
    supported: SupportedAudioMessage,
    unsupported: UnsupportedAudioMessage
  },
  application: {
    supported: SupportedFileMessage,
    unsupported: UnsupportedFileMessage
  },
  video: {
    supported: VideoMessage,
    unsupported: UnsupportedFileMessage
  },
  image: {
    supported: ImageMessage,
    unsupported: UnsupportedFileMessage
  },
  text: {
    supported: SupportedFileMessage,
    unsupported: UnsupportedFileMessage
  },
  default: UnsupportedFileMessage
}

export const ChatMediaMessage: FC<
  MediaMessageType & ChatMessageContainerOptions
> = ({ type, isSender, isMinimized, ...props }) => {
  const file = useFileType(type)

  const FileMessageComponent = useMemo(() => {
    if (file.type in fileMessageComponents) {
      return file.isSupported
        ? fileMessageComponents[file.type].supported
        : fileMessageComponents[file.type].unsupported
    }
    return fileMessageComponents.default
  }, [file])

  const fileMessageElement = useMemo(
    () => (
      <FileMessageComponent
        {...props}
        type={type}
        isMinimized={isMinimized}
        isSender={isSender}
      />
    ),
    [props.contentId, FileMessageComponent]
  )

  return (
    <ChatMessageContainer
      {...props}
      isMinimized={isMinimized}
      isSender={isSender}
    >
      {fileMessageElement}
    </ChatMessageContainer>
  )
}
