import { Grid } from '@mui/material'
import { GearIcon } from '../../../../assets/icons/GearIcon'
import { OfficeIcon } from '../../../../assets/icons/OfficeIcon'
import { IconText } from '../../../../atoms/IconText'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const IconTextExample = () => {
  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <Grid container spacing={1}>
          <IconText icon={<GearIcon />}>Text</IconText>
          <IconText icon={<OfficeIcon />}>Text_2</IconText>
        </Grid>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
