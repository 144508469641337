import { Grid, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { ActionBlock } from '../../../atoms/ActionBlock'
import { AutoselectColorOption } from '../../../atoms/AutoselectColorOption'
import { AutoselectInputField } from '../../../atoms/AutoselectInputField'
import { AutoselectOption } from '../../../atoms/AutoselectOption'

import { DeliverOption, Option } from '../../../types/Autoselect'
import {
  FIND_CAR_FIELD_LABELS,
  FIND_CAR_FIELD_NAMES
} from '../../../types/enums/findCar'
import { BasicInfo } from '../../../types/forms/findCar'
import {
  useAppDispatch,
  useAppSelector,
  useDeviceInfo,
  useUserInfo
} from '../../../utils/hooks'

import {
  actionsFindCarForm,
  defaultColorOption,
  defaultTrimOption,
  selectFindCarFormExteriorColorsOptions,
  selectFindCarFormInteriorColorsOptions,
  selectFindCarFormMakeOptions,
  selectFindCarFormModelOptions,
  selectFindCarFormTrimOptions,
  selectFindCarFormYearFromOptions,
  selectFindCarFormYearToOptions
} from '../../../store/units/findCarForm'
import {
  readCarByYear,
  readCarByYearFromNonDealerInventory,
  readMakers,
  readMakersFromNonDealerInventory,
  readModels,
  readModelsFromNonDealerInventory,
  readYears,
  readYearsFromNonDealerInventory
} from '../../../store/units/findCarForm/thunks'

import {
  DependentExteriorField,
  DependentInteriorField,
  DependentModelField,
  DependentTrimField,
  DependentYearFromField,
  DependentYearToField,
  ExteriorFieldProps,
  InteriorFieldProps,
  ModelFieldProps,
  TrimFieldProps,
  YearFromFieldProps
} from './DependentFields'
import { validationSchema } from './validationSchema'

import { AutoFormValidator } from '../../../atoms/AutoFormValidator'
import { BlueButton } from '../../../atoms/Button'
import { DependentFieldHocEffect } from '../../../atoms/DependentField'
import { Divider } from '../../../atoms/Divider'
import { FixedContainer } from '../../../atoms/FixedContainer'
import { Show } from '../../../atoms/JSXExtensions'
import { RequiredLabel } from '../../../atoms/RequiredText'
import { getOptionsBetween } from '../../../store/units/findCarForm/utils/getOptionsBetween'
import { COLORS, SHADOWS } from '../../../types/colors'
import { firstOf, lastOf } from '../../../utils/array'
import { getOptionLabel } from '../../../utils/autoselect/getOptionLabel'
import { isOptionEqualToValue } from '../../../utils/autoselect/isOptionEqualToValue'
import { objectKeys } from '../../../utils/objectKeys'
import { isRequiredFormField } from '../../../utils/validators/isRequiredFormField'
import { AdditionalNotesField } from './AdditionalNotesField'

type FindCarBasicInfoScreenProps = {
  actions: typeof actionsFindCarForm

  initialValues: BasicInfo
  staticValues?: Partial<BasicInfo>
}

export const FindCarBasicInfoScreen: FC<FindCarBasicInfoScreenProps> = ({
  actions,
  initialValues,
  staticValues = {}
}) => {
  const dispatch = useAppDispatch()
  const { isTablet } = useDeviceInfo()
  const makeOptions = useAppSelector(selectFindCarFormMakeOptions)
  const modelOptions = useAppSelector(selectFindCarFormModelOptions)
  const yearFromOptions = useAppSelector(selectFindCarFormYearFromOptions)
  const yearToOptions = useAppSelector(selectFindCarFormYearToOptions)
  const trimOptions = useAppSelector(selectFindCarFormTrimOptions)
  const interiorColorOptions = useAppSelector(
    selectFindCarFormInteriorColorsOptions
  )
  const exteriorColorOptions = useAppSelector(
    selectFindCarFormExteriorColorsOptions
  )
  const { addresses, isDealer } = useUserInfo()

  const deliverToOptions = useMemo(
    () =>
      addresses.map(({ zipCode, street, city, state, id }) => {
        const localAddress = [street, city].filter(Boolean).join(' ')

        return {
          id,
          zipCode,
          name: `${zipCode} (${localAddress}, ${state})`
        }
      }),
    [addresses]
  )

  useEffect(() => {
    if (isDealer) {
      dispatch(readMakersFromNonDealerInventory())
    } else {
      dispatch(readMakers())
    }
  }, [isDealer])

  const onNextBtnClicked = (data: BasicInfo): void => {
    const emptyReference = {
      id: '',
      name: ''
    }

    const trim = data[FIND_CAR_FIELD_NAMES.TRIM] || emptyReference

    dispatch(
      actions.setBasicInfo({
        ...data,
        [FIND_CAR_FIELD_NAMES.TRIM]: trim
      })
    )
    dispatch(actions.incrementScreenStep())
  }

  const modelFieldEffect: DependentFieldHocEffect<ModelFieldProps, BasicInfo> =
    useCallback((_, ctx, prevValues) => {
      const { values, setFieldValue } = ctx
      const { make } = values
      if (!make) {
        return setFieldValue(FIND_CAR_FIELD_NAMES.MODEL, null)
      }
      if (isDealer) {
        dispatch(readModelsFromNonDealerInventory({ make: make.id }))
      } else {
        dispatch(readModels({ make: make.id }))
      }

      if (prevValues) {
        const { make: prevMake } = prevValues
        if (make?.id !== prevMake?.id) {
          setFieldValue(FIND_CAR_FIELD_NAMES.MODEL, null)
        }
      }
    }, [isDealer])

  const yearFromFieldEffect: DependentFieldHocEffect<
    YearFromFieldProps,
    BasicInfo
  > = useCallback((_, ctx, prevValues) => {
    const { values, setFieldValue } = ctx
    const { make, model } = values
    if (!model) {
      setFieldValue(FIND_CAR_FIELD_NAMES.YEAR_TO, null)
      return setFieldValue(FIND_CAR_FIELD_NAMES.YEAR_FROM, null)
    }
    if (make) {
      if (isDealer) {
        dispatch(readYearsFromNonDealerInventory({ make: make.id, model: model.id }))
      } else {
        dispatch(readYears({ make: make.id, model: model.id }))
      }
    }

    if (prevValues) {
      const { model: prevModel } = prevValues
      if (model?.id !== prevModel?.id) {
        setFieldValue(FIND_CAR_FIELD_NAMES.YEAR_FROM, null)
        setFieldValue(FIND_CAR_FIELD_NAMES.YEAR_TO, null)
      }
    }
  }, [isDealer])

  const trimFieldEffect: DependentFieldHocEffect<TrimFieldProps, BasicInfo> =
    useCallback(
      (_, ctx, prevValues) => {
        const { values, setFieldValue } = ctx
        const { make, model, yearFrom, yearTo } = values
        if (!yearTo && !yearFrom) {
          return setFieldValue(FIND_CAR_FIELD_NAMES.TRIM, defaultTrimOption)
        }
        const from = yearFrom || firstOf(yearFromOptions)
        const to = yearTo || lastOf(yearFromOptions)

        if (make && model && from && to) {
          const filteredYears = getOptionsBetween(
            from,
            to,
            yearFromOptions,
            (o, v) => +o.id >= +v.id,
            (o, v) => +o.id <= +v.id
          )
          const years = filteredYears.map((option) => option.id)

          if (isDealer) {
            dispatch(
              readCarByYearFromNonDealerInventory({
                make: make.id,
                model: model.id,
                year: years
              })
            )
          } else {
            dispatch(
              readCarByYear({
                make: make.id,
                model: model.id,
                year: years
              })
            )
          }
        }

        if (prevValues) {
          const { yearTo: prevYearTo, yearFrom: prevYearFrom } = prevValues
          if (
            yearTo?.id !== prevYearTo?.id ||
            yearFrom?.id !== prevYearFrom?.id
          ) {
            setFieldValue(FIND_CAR_FIELD_NAMES.TRIM, defaultTrimOption)
          }
        }
      },
      [isDealer, yearFromOptions]
    )

  const exteriorFieldEffect: DependentFieldHocEffect<
    ExteriorFieldProps,
    BasicInfo
  > = useCallback((_, ctx, prevValues) => {
    const { values, setFieldValue } = ctx
    const { yearTo } = values
    if (!yearTo) {
      return setFieldValue(FIND_CAR_FIELD_NAMES.EXTERIOR_COLOR, [
        defaultColorOption
      ])
    }

    if (prevValues) {
      const { yearTo: prevYearTo } = prevValues
      if (yearTo?.id !== prevYearTo?.id) {
        setFieldValue(FIND_CAR_FIELD_NAMES.EXTERIOR_COLOR, [defaultColorOption])
      }
    }
  }, [])

  const interiorFieldEffect: DependentFieldHocEffect<
    InteriorFieldProps,
    BasicInfo
  > = useCallback((_, ctx, prevValues) => {
    const { values, setFieldValue } = ctx
    const { yearTo } = values
    if (!yearTo) {
      return setFieldValue(FIND_CAR_FIELD_NAMES.INTERIOR_COLOR, [
        defaultColorOption
      ])
    }

    if (prevValues) {
      const { yearTo: prevYearTo } = prevValues
      if (yearTo?.id !== prevYearTo?.id) {
        setFieldValue(FIND_CAR_FIELD_NAMES.INTERIOR_COLOR, [defaultColorOption])
      }
    }
  }, [])

  const [isShowAddNotes, setIsShowAddNotes] = useState(!!initialValues?.notes)

  const onAddNotesHandler = useCallback(() => {
    setIsShowAddNotes(true)
  }, [setIsShowAddNotes])

  const onCloseNotesHandler = useCallback(() => {
    setIsShowAddNotes(false)
  }, [setIsShowAddNotes])

  const isRequiredField = isRequiredFormField(validationSchema)

  return (
    <Grid item sx={{ flexGrow: 1 }}>
      <Formik
        initialValues={{
          ...initialValues,
          [FIND_CAR_FIELD_NAMES.DELIVER_TO]:
            initialValues[FIND_CAR_FIELD_NAMES.DELIVER_TO] ||
            deliverToOptions[0],
          ...staticValues
        }}
        validationSchema={validationSchema}
        onSubmit={onNextBtnClicked}
        validateOnMount
        validateOnChange={false}
      >
        {({ touched, values, isValid }) => {
          const isFormTouched = objectKeys(touched).length > 0
          return (
            <Form
              style={{
                height: '100%'
              }}
            >
              <AutoFormValidator
                dependencies={isTablet ? [values.yearFrom, values.yearTo] : []}
              />
              <Grid
                container
                item
                flexDirection='column'
                justifyContent='space-between'
                sx={{
                  height: '100%'
                }}
              >
                <Grid container spacing={3} flexDirection='column' mb={15}>
                  <Grid item>
                    <Typography variant='label1'>
                      Basic Specifications & Notes
                    </Typography>
                    <RequiredLabel />
                  </Grid>
                  <Grid
                    container
                    item
                    spacing={4}
                    flexDirection={isTablet ? 'column' : 'row'}
                  >
                    <Grid item container xs={isTablet ? 12 : 4} spacing='12px'>
                      <Grid item xs={12}>
                        <AutoselectInputField<Option>
                          renderOption={AutoselectOption}
                          name={FIND_CAR_FIELD_NAMES.MAKE}
                          label={FIND_CAR_FIELD_LABELS.MAKE}
                          options={makeOptions}
                          isOptionEqualToValue={isOptionEqualToValue}
                          getOptionLabel={getOptionLabel}
                          required={isRequiredField}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <DependentModelField
                          renderOption={AutoselectOption}
                          name={FIND_CAR_FIELD_NAMES.MODEL}
                          label={FIND_CAR_FIELD_LABELS.MODEL}
                          options={modelOptions}
                          isOptionEqualToValue={isOptionEqualToValue}
                          getOptionLabel={getOptionLabel}
                          onChangeEffect={modelFieldEffect}
                          required={isRequiredField}
                        />
                      </Grid>
                    </Grid>

                    <Grid item container xs={isTablet ? 12 : 4} spacing='12px'>
                      <Grid container item xs={12} spacing='12px'>
                        <Grid item xs={6}>
                          <DependentYearFromField
                            renderOption={AutoselectOption}
                            name={FIND_CAR_FIELD_NAMES.YEAR_FROM}
                            label={FIND_CAR_FIELD_LABELS.YEAR_FROM}
                            options={yearFromOptions}
                            isOptionEqualToValue={isOptionEqualToValue}
                            getOptionLabel={getOptionLabel}
                            onChangeEffect={yearFromFieldEffect}
                            required
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <DependentYearToField
                            renderOption={AutoselectOption}
                            name={FIND_CAR_FIELD_NAMES.YEAR_TO}
                            label={FIND_CAR_FIELD_LABELS.YEAR_TO}
                            options={yearToOptions}
                            isOptionEqualToValue={isOptionEqualToValue}
                            getOptionLabel={getOptionLabel}
                            required
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <DependentTrimField
                          renderOption={AutoselectOption}
                          name={FIND_CAR_FIELD_NAMES.TRIM}
                          label={FIND_CAR_FIELD_LABELS.TRIM}
                          options={trimOptions}
                          isOptionEqualToValue={isOptionEqualToValue}
                          getOptionLabel={getOptionLabel}
                          onChangeEffect={trimFieldEffect}
                          required={isRequiredField}
                        />
                      </Grid>
                    </Grid>

                    <Grid item container xs={isTablet ? 12 : 4} spacing='12px'>
                      <Grid container item xs={12} spacing={2}>
                        <Grid item xs={6}>
                          <DependentExteriorField
                            multiple
                            renderOption={AutoselectColorOption}
                            name={FIND_CAR_FIELD_NAMES.EXTERIOR_COLOR}
                            label={FIND_CAR_FIELD_LABELS.EXTERIOR_COLOR}
                            options={exteriorColorOptions}
                            exactMultiValue={defaultColorOption}
                            isOptionEqualToValue={isOptionEqualToValue}
                            getOptionLabel={getOptionLabel}
                            onChangeEffect={exteriorFieldEffect}
                            customTags
                            required={isRequiredField}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <DependentInteriorField
                            multiple
                            renderOption={AutoselectColorOption}
                            name={FIND_CAR_FIELD_NAMES.INTERIOR_COLOR}
                            label={FIND_CAR_FIELD_LABELS.INTERIOR_COLOR}
                            options={interiorColorOptions}
                            exactMultiValue={defaultColorOption}
                            isOptionEqualToValue={isOptionEqualToValue}
                            getOptionLabel={getOptionLabel}
                            onChangeEffect={interiorFieldEffect}
                            customTags
                            required={isRequiredField}
                          />
                        </Grid>
                      </Grid>

                      <Grid container item xs={12}>
                        <AutoselectInputField<DeliverOption>
                          renderOption={AutoselectOption}
                          name={FIND_CAR_FIELD_NAMES.DELIVER_TO}
                          label={FIND_CAR_FIELD_LABELS.DELIVER_TO}
                          options={deliverToOptions}
                          isOptionEqualToValue={isOptionEqualToValue}
                          getOptionLabel={getOptionLabel}
                          required={isRequiredField}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Show
                    when={isShowAddNotes}
                    fallback={
                      <Grid item>
                        <BlueButton
                          startIcon={<AddCircleOutlineIcon />}
                          onClick={onAddNotesHandler}
                        >
                          Add Notes
                        </BlueButton>
                      </Grid>
                    }
                  >
                    <Grid item container>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12} mt={2}>
                        <AdditionalNotesField
                          name={FIND_CAR_FIELD_NAMES.NOTES}
                          label={FIND_CAR_FIELD_LABELS.NOTES}
                          onCloseHandler={onCloseNotesHandler}
                        />
                      </Grid>
                    </Grid>
                  </Show>
                </Grid>

                <FixedContainer
                  flexDirection='row-reverse'
                  bottom
                  p={3}
                  background={COLORS.white}
                  boxShadow={SHADOWS.modal}
                >
                  <Show when={isShowAddNotes}>
                    <Grid item xs={12} mt={1}>
                      <Divider />
                    </Grid>
                  </Show>
                  <Grid item>
                    <ActionBlock
                      schema={[
                        {
                          label: 'just space',
                          type: 'empty'
                        },
                        {
                          label: 'Search',
                          type: 'primary',
                          disabled:
                            (!initialValues.make && !isFormTouched) || !isValid,
                          props: {
                            type: 'submit'
                          }
                        }
                      ]}
                    />
                  </Grid>
                </FixedContainer>
              </Grid>
            </Form>
          )
        }}
      </Formik>
    </Grid>
  )
}
