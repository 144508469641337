import { styled } from '@mui/system'

export const TermsContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-left: 4px;

  & > * {
    margin-left: 4px;
  }

  & > *:first-child {
    margin-left: 0;
  }
`
