import { FC } from 'react'
import { Show } from '../../../../../../atoms/JSXExtensions'
import { DetailsContainer } from '../../components/DetailsContainer'
import { SellerShippingProps } from './types'
import { TrackableView } from '../../components/Shipment/TrackableView'
import { NonTrackableView } from '../../components/Shipment/NonTrackableView'
import { isDeliveryLocal } from '../../utils'
import { NON_TRACKABLE_PROPS_SELLER } from '../constants'
import { Condition } from '../../../../../../atoms/Condition'
import { SellerDefaultShipping } from './components/SellerDefaultShipping'

export const SellerShipping: FC<SellerShippingProps> = ({
  trackingNumber,

  ...props
}) => {
  const { contract } = props

  const deliveryIsByService = !isDeliveryLocal(contract)

  const shipmentCreationError =
    // Delivery by service
    deliveryIsByService &&
    // But shipment hasn't been created
    !trackingNumber

  return (
    <DetailsContainer title='Shipment'>
      <Show
        when={deliveryIsByService}
        fallback={<NonTrackableView {...NON_TRACKABLE_PROPS_SELLER} />}
      >
        <Condition
          condition={shipmentCreationError}
          trueContent={<SellerDefaultShipping />}
          falseContent={
            <TrackableView trackingNumber={trackingNumber} {...props} />
          }
        />
      </Show>
    </DetailsContainer>
  )
}
