import { Box } from '@mui/material'
import { FC, useCallback } from 'react'
import { ChatList } from '../../../../features/MiniChat/view/ChatList'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'
import { chatListSet } from './mocks'
import { ChatListItemType } from '../../../../features/MiniChat/model/types'

export const ChatListExample: FC = () => {
  const handleChatClick = useCallback((chat: ChatListItemType) => {
    // eslint-disable-next-line
    console.log('Chat id', chat)
  }, [])

  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <Box height='150px' width='482px'>
          <ChatList chatList={chatListSet} onChatClick={handleChatClick} />
        </Box>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
