import { styled } from '@mui/system'
import { BGCOLORS, COLORS } from '../../../types/colors'

export const PaginationButton = styled('button', {
  shouldForwardProp: (prop) => prop !== 'isSelected'
})<{ isSelected: boolean }>(({ isSelected }) => ({
  width: '34px',
  height: '34px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: BGCOLORS.gray,
  boxShadow: isSelected ? 'none' : '1px 2px 2px rgba(217, 221, 228, 0.4)',
  backgroundColor: isSelected
    ? COLORS.main_yellow
    : COLORS.text_white,
  cursor: 'pointer',
  ...(isSelected
    ? {
      fontWeight: 'bold',
      color: COLORS.main_yellow
    }
    : {})
}))

export const PaginationControls = styled('button')`
  border: none;
  color: ${({ disabled }) =>
    disabled ? COLORS.text_gray : COLORS.text_blue};
  font-weight: ${({ disabled }) => (disabled ? 400 : 700)};
  background-color: transparent;
  text-transform: capitalize;
  cursor: pointer;
`
