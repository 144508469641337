import { NormalizedRequestType, RawRequestType } from '../types'

export const requestMapper = (
  request: RawRequestType
): NormalizedRequestType => {
  return {
    id: request.id,
    createdBy: request.created_by,
    createdOn: new Date(request.created_on),
    changedBy: request.changed_by,
    changedOn: request.changed_on ? new Date(request.changed_on) : null,
    accountID: request.account_id,
    years: request.year.split(', '),
    make: request.make,
    model: request.model,
    trim: request.trim,
    exteriorColors: request.exterior_color.split(', '),
    interiorColors: request.interior_color.split(', '),
    notes: request.notes,
    deleted: request.deleted,
    status: request.status,
    mileage: request.mileage,
    expiresOn: new Date(request.expires_on),
    tradeInVehicle: request.trade_in_vehicle,
    deliverToAddressID: request.deliver_to_address_id
  }
}
