import { FC } from 'react'
import { FileDocument } from '../../../../../../assets/icons/Document'
import { ChatMediaMessageContainer } from '../ChatMediaMessageContainer'
import { ChatFileMessageWrapperProps } from '../../types'

export const UnsupportedFileMessage: FC<ChatFileMessageWrapperProps> = (
  props
) => {
  const icon = <FileDocument />
  return <ChatMediaMessageContainer {...props} icon={icon} />
}
