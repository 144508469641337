import { FC, PropsWithChildren, useState, useCallback } from 'react'
import { Condition } from '../../../atoms/Condition'
import { DisplaySSNInformation } from './DisplaySSNInformation'
import { PersonalSettingsItem } from '../../../atoms/PersonalSettingsItem'
import { EditSSNInformation } from './EditSSNInformation'
import {
  useApiContext,
  useAppDispatch,
  useMakeService,
  useUserInfo
} from '../../../utils/hooks'
import { actionsUserInfo } from '../../../store/features/userInfo'
import { ResponseStatusMap } from '../../../types/status'
import { DeleteConfirmationModal } from '../../../molecules/Modal'

interface SSNInformationCardProps {
  ssn?: string | null
}

export const SSNInformationCard: FC<
  PropsWithChildren<SSNInformationCardProps>
> = (props) => {
  const {
    id: userId,
    firstName,
    lastName,
    phone,
    dateOfBirth,
    driversLicense,
    driversLicenseState,
    driversLicenseExpiration,
    addresses
  } = useUserInfo()

  const dispatch = useAppDispatch()
  const { carSnoopApi } = useApiContext()

  const [isEditMode, setIsEditMode] = useState(false)
  const [isDeleteSSNConfirmationOpen, setDeleteSSNConfirmationOpen] =
    useState(false)

  const [updateUserSSN, updateUserObservable] = useMakeService(
    async (ssn: string) => {
      const updateResponse = await carSnoopApi.users.updateUser({
        id: userId,
        firstName,
        lastName,
        phone,
        dateOfBirth: new Date(dateOfBirth),
        taxId: ssn,
        driversLicense,
        driversLicenseState,
        driversLicenseExpiration,
        addresses
      })

      if (updateResponse.status === ResponseStatusMap.Success) {
        const updatedUser = await carSnoopApi.users.readUser(userId)

        if (updatedUser.status === ResponseStatusMap.Success) {
          dispatch(
            actionsUserInfo.setUserPersonalInfo({
              taxID: updatedUser.taxID,
              driversLicense: updatedUser.driversLicense
            })
          )

          setIsEditMode(false)
        }
      }

      return updateResponse
    },
    {
      withStatusNotification: true
    }
  )

  const openDeleteSSNConfirmation = useCallback(() => {
    setDeleteSSNConfirmationOpen(true)
  }, [setDeleteSSNConfirmationOpen])

  const onDeleteSSN = useCallback(async () => {
    await updateUserSSN('')
  }, [updateUserSSN])

  return (
    <PersonalSettingsItem
      hint='We only need your SSN if you want to finance your vehicle.'
      isEditMode={isEditMode}
      isLoading={updateUserObservable.isLoading}
    >
      <DeleteConfirmationModal
        isOpen={isDeleteSSNConfirmationOpen}
        setOpen={setDeleteSSNConfirmationOpen}
        onSubmit={onDeleteSSN}
        message='Are you sure you would like to delete Social Security Number?'
      />
      <Condition
        condition={isEditMode}
        trueContent={
          <EditSSNInformation
            submitChangeSSN={updateUserSSN}
            deleteSSN={openDeleteSSNConfirmation}
            setIsEditMode={setIsEditMode}
            {...props}
          />
        }
        falseContent={
          <DisplaySSNInformation setEditMode={setIsEditMode} {...props} />
        }
      />
    </PersonalSettingsItem>
  )
}
