import { FC, useCallback } from 'react'
import {
  selectSideBarState,
  sideBarActions
} from '../../store/features/SideBar'
import { useAppDispatch, useAppSelector } from '../../utils/hooks'
import { SideBar } from './SideBar'
import { SideBarContentSwitcher } from './SideBarContentSwitcher'

export const SideBarContainer: FC = () => {
  const sideBarState = useAppSelector(selectSideBarState)
  const dispatch = useAppDispatch()
  const { closeSideBar } = sideBarActions
  const handleCloseBar = useCallback(() => {
    dispatch(closeSideBar())
  }, [closeSideBar])

  return (
    <SideBar
      headerText={sideBarState.header}
      isOpen={sideBarState.isOpen}
      onClose={handleCloseBar}
      sx={{ width: { xs: '100vw', lg: '600px' } }}
    >
      <SideBarContentSwitcher barType={sideBarState.sideBarType} />
    </SideBar>
  )
}
