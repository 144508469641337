import CloseIcon from '@mui/icons-material/Close'
import { Box, Grid, Typography } from '@mui/material'
import { FC, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ReadVehicleResponse } from '../../../../../api/carSnoopApi/vehicles'
import { DefaultVehicle } from '../../../../../assets/imgs/DefaultVehicle'
import { Badge } from '../../../../../atoms/Badge'
import { RedButton } from '../../../../../atoms/Button'
import { For, Show } from '../../../../../atoms/JSXExtensions'
import { Loader } from '../../../../../atoms/Loader'
import { NavigationBackButton } from '../../../../../atoms/NavigationBackButton'
import { DisplayVehicleInfo } from '../../../../../features/DisplayVehicleInfo'
import { PageContainer } from '../../../../../layout/PageContainer'
import { CommonConfirmationModal } from '../../../../../molecules/Modal/CommonConfirmationModal'
import { routes } from '../../../../../routes'
import { DealsActiveTabEnum } from '../../../../../store/types'
import { BORDERS, COLORS, SHADOWS } from '../../../../../types/colors'
import { DEAL_FLOW } from '../../../../../types/enums/dealFlow'
import { formatPrice } from '../../../../../utils'
import {
  useBackPath,
  useDealsRoute,
  useDeviceInfo,
  useModal
} from '../../../../../utils/hooks'
import {
  ListHeaderDesktop,
  ListHeaderTablet
} from '../../../BuyingFlow/RequestsTab/components/ListHeader'
import { RequestHeader } from '../../../BuyingFlow/RequestsTab/components/RequestHeader'
import { useDealsPageSlice } from '../../../hooks'
import { useSellingRequestVehicle } from '../../useSellingRequestVehicle'

const getTradeinValue =
  (request: ReadVehicleResponse['requests'][number]) => () => {
    const { tradeinVehicleId, tradeinValue } = request
    if (!tradeinVehicleId) {
      return {
        tablet: ['Not included'],
        desktop: 'Not included'
      }
    }
    const tradeinFormattedValue = formatPrice(tradeinValue)
    return {
      tablet: [tradeinFormattedValue],
      desktop: tradeinFormattedValue
    }
  }

export const SellingRequestsPage: FC = () => {
  const { vehicleID = '' } = useParams()
  const { returnToDealsPage } = useDealsPageSlice()
  const { backPath } = useBackPath()
  const navigate = useNavigate()

  const { isTablet } = useDeviceInfo()
  const { getPathToDeal } = useDealsRoute()

  const handleBackClick = useCallback(() => {
    if (backPath !== routes.deals.index) {
      navigate(backPath)
    } else {
      returnToDealsPage({
        initialSellingView: true,
        tabNumber: DealsActiveTabEnum.Requests
      })
    }
  }, [returnToDealsPage, backPath])

  const { vehicle, declineTransaction, declineAllRequests } =
    useSellingRequestVehicle(vehicleID)
  const declineAllRequestsConfirmation = useModal(declineAllRequests)

  return (
    <>
      <CommonConfirmationModal
        {...declineAllRequestsConfirmation}
        message='Are you sure you would like to decline all requests?'
        hint='Declined requests can not be recovered'
      />
      <PageContainer>
        <Show
          when={vehicle}
          fallback={
            <Grid container justifyContent='center'>
              <Loader />
            </Grid>
          }
        >
          {(veh) => {
            const vehImage = veh.images[0]
            const { url: imageUrl } = vehImage || {}
            const { retailValue, requests } = veh
            const requestsText =
              requests?.length > 1 ? 'Open requests' : 'Open request'
            return (
              <Grid container spacing={3}>
                <NavigationBackButton
                  buttonText='Open Requests'
                  onBackClick={handleBackClick}
                  textVariant='h6'
                />
                <Grid container item xs={12} spacing={3}>
                  <Grid item xs={12} lg={5}>
                    <Box
                      sx={{
                        aspectRatio: '16/9',
                        background: imageUrl
                          ? `no-repeat center/cover url(${imageUrl})`
                          : `no-repeat center/cover ${COLORS.border_gray}`,
                        borderRadius: '3px',
                        position: 'relative',
                      }}
                    >
                      <Box
                        sx={{
                          position: 'absolute',
                          left: '24px',
                          bottom: '24px'
                        }}
                      >
                        <Badge
                          text={formatPrice(retailValue)}
                          background={COLORS.hover_background_blue}
                          color={COLORS.main_black}
                          textSx={{
                            fontSize: '18px'
                          }}
                          border='none'
                          sx={{ height: 'auto' }}
                        />
                      </Box>

                      <Show when={!imageUrl}>
                        <Box
                          sx={{
                            p: 2,
                            height: '100%',
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <DefaultVehicle />
                        </Box>
                      </Show>
                    </Box>
                  </Grid>
                  <Grid container item xs={12} lg={7}>
                    <DisplayVehicleInfo {...veh} />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Grid item>
                    <Typography variant='h6'>
                      {requests.length} {requestsText}
                    </Typography>
                  </Grid>
                  <Grid item pr={4}>
                    <RedButton
                      onClick={declineAllRequestsConfirmation.onOpen}
                      startIcon={<CloseIcon />}
                    >
                      Decline All
                    </RedButton>
                  </Grid>
                </Grid>

                <Grid container item>
                  <Grid
                    item
                    xs={12}
                    sx={{ borderBottom: BORDERS.SM_GRAY, pb: 1 }}
                    ml={1}
                  >
                    <Show when={isTablet} fallback={<ListHeaderDesktop />}>
                      <ListHeaderTablet />
                    </Show>
                  </Grid>

                  <For of={requests}>
                    {(request) => (
                      <Grid
                        item
                        xs={12}
                        sx={{
                          borderBottom: BORDERS.SM_GRAY,
                          ':hover': { boxShadow: SHADOWS.requestHover }
                        }}
                      >
                        <RequestHeader
                          navigateToNewTab
                          navigateTo={getPathToDeal({
                            transactionId: request.id,
                            flow: DEAL_FLOW.SELL
                          })}
                          request={request}
                          standalone
                          getTradeinValue={getTradeinValue(request)}
                          onCancelClick={declineTransaction}
                        />
                      </Grid>
                    )}
                  </For>
                </Grid>
              </Grid>
            )
          }}
        </Show>
      </PageContainer>
    </>
  )
}
