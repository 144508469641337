import { Grid } from '@mui/material'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { NavigationBackButton } from '../../atoms/NavigationBackButton'
import { FindCarForm } from '../../forms/FindCar'
import { PageContainer } from '../../layout/PageContainer'
import { DiscardConfirmationModal } from '../../molecules/Modal'
import { selectBreadcrumbsState } from '../../store/features/Breadcrumbs'
import { DealsActiveTabEnum } from '../../store/types'
import {
  actionsFindCarForm,
  selectFindCarFormStep
} from '../../store/units/findCarForm'
import { FIND_CAR_STEPS } from '../../types/enums/findCar'
import { useBlockPath } from '../../utils/NavigationController/hooks/useBlockPath'
import { useAppDispatch, useAppSelector, useModal } from '../../utils/hooks'
import { useBreadcrumbs } from '../../utils/hooks/useBreadcrumbs'
import { useDealsPageSlice } from '../Deals/hooks'

export const FindCarPage: FC = () => {
  const { returnToDealsPage } = useDealsPageSlice()
  const dispatch = useAppDispatch()
  const { resetBreadcrumbs, setBreadcrumbs } = useBreadcrumbs()
  const breadcrumbsState = useAppSelector(selectBreadcrumbsState)
  const screenStep = useAppSelector(selectFindCarFormStep)
  const isRequestSubmitted = useMemo(
    () => screenStep === FIND_CAR_STEPS.stepFive,
    [screenStep]
  )
  const {
    isNavigateConfirmationOpen,
    setIsNavigateConfirmationOpen,
    onNavigationConfirm
  } = useBlockPath(!isRequestSubmitted)

  const onBackConfirm = useCallback(() => {
    dispatch(actionsFindCarForm.resetState())
    resetBreadcrumbs()
    returnToDealsPage({
      initialSellingView: false,
      tabNumber: DealsActiveTabEnum.Requests
    })
  }, [dispatch, resetBreadcrumbs, returnToDealsPage])

  const discardModal = useModal(onBackConfirm)

  const handleBackClick = useCallback(() => {
    discardModal.setOpen(true)
  }, [discardModal.setOpen])

  useEffect(() => {
    setBreadcrumbs([{ label: 'Add Request' }])
  }, [])
  const handleConfirmLeavePage = useCallback(() => {
    resetBreadcrumbs()
    onNavigationConfirm()
  }, [onNavigationConfirm])

  return (
    <>
      <DiscardConfirmationModal {...discardModal} />
      <DiscardConfirmationModal
        isOpen={!isRequestSubmitted && isNavigateConfirmationOpen}
        setOpen={setIsNavigateConfirmationOpen}
        onSubmit={handleConfirmLeavePage}
      />
      <PageContainer>
        <Grid container gap={2}>
          <Grid item xs={12}>
            <NavigationBackButton
              buttonMinWidth='24px'
              buttonText={breadcrumbsState.breadcrumbs[0]?.label || ''}
              onBackClick={handleBackClick}
              textVariant='h6'
            />
          </Grid>
          <Grid item xs={12}>
            <FindCarForm onCancelSearch={handleBackClick} />
          </Grid>
        </Grid>
      </PageContainer>
    </>
  )
}
