import { RawReadChatMessagesResponse } from './raw'
import { NormalizedReadChatMessagesResponse } from './normalized'
import { TextMessageType, MediaMessageType } from '..'
import { TEXT_MESSAGE_TYPE } from '../../../../constants/applicationConstants'
import { ChatState } from '../../../../features/Chat/model/chatStore'

export const normalizeReadChatMessages = ({
  data: { chats }
}: RawReadChatMessagesResponse): NormalizedReadChatMessagesResponse => {
  const result: NormalizedReadChatMessagesResponse = []

  chats.forEach((m) => {
    const {
      id,
      from_user: fromUser,
      to_user: toUser,
      message,
      transaction_id: transactionId,
      sent_on: sentOn,
      has_read: hasRead,
      media_id: mediaId,
      content_type: contentType,
      file_name: fileName,
      media_url: mediaUrl,
      users_full_name: usersFullName,
      avatar
    } = m

    if (mediaId !== null) {
      const formattedMessage = {
        id,
        fromUser,
        toUser,
        message,
        transactionId,
        sentOn,
        hasRead,
        mediaId,
        contentType,
        fileName,
        mediaUrl,
        usersFullName,
        avatar
      }

      result.push(formattedMessage)
    } else {
      const formattedMessage = {
        id,
        fromUser,
        toUser,
        message,
        transactionId,
        sentOn,
        hasRead,
        mediaId,
        contentType,
        fileName,
        mediaUrl,
        usersFullName,
        avatar
      }

      result.push(formattedMessage)
    }
  })

  return result
}

export const formatReadChatMessages = (
  messages: NormalizedReadChatMessagesResponse,
  currentUserId: string
): NonNullable<ChatState['messages']> => {
  return messages.map((m) => {
    const {
      id,
      fromUser,
      message,
      transactionId,
      sentOn,
      hasRead,
      mediaId,
      contentType,
      fileName,
      mediaUrl,
      usersFullName,
      avatar
    } = m

    const isSender = currentUserId === fromUser

    if (mediaId !== null) {
      const formattedMessage: MediaMessageType = {
        id,
        transactionId,
        senderName: usersFullName,
        isSender,
        hasRead,
        timestamp: sentOn,
        message,
        avatar,
        type: contentType,
        contentId: mediaId,
        contentName: fileName,
        contentPreviewURL: mediaUrl
      }
      return formattedMessage
    }
    const formattedMessage: TextMessageType = {
      id,
      transactionId,
      senderName: usersFullName,
      isSender,
      hasRead,
      timestamp: sentOn,
      message,
      avatar,
      type: TEXT_MESSAGE_TYPE
    }
    return formattedMessage
  })
}
