import { ResetLinkExpiredContainer } from '../../../ResetPassword/ResetLinkExpiredContainer'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const ResetLinkExpiredContainerExample = () => {
  const handleClick = (email: string): Promise<void> => {
    return new Promise((res) => {
      // eslint-disable-next-line
      console.log('>>> RESEND', email)
      res()
    })
  }

  return (
    <ComponentContainer>
      <ComponentWithPaperBackground title='Not in molecules folder (in reoutes/ResetPassword)'>
        <ResetLinkExpiredContainer
          email='admin123456@gmail.com'
          resendLinkService={handleClick}
        />
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
