import { Typography } from '@mui/material'

export const SupportFormMainText = ({
  primaryText,
  secondaryText
}: {
  primaryText: string
  secondaryText: string
}) => {
  return (
    <>
      <Typography variant='h6' textAlign='center' fontSize={20}>
        {primaryText}
      </Typography>
      <Typography
        component='p'
        variant='details'
        textAlign='center'
        whiteSpace='pre-wrap'
      >
        {secondaryText}
      </Typography>
    </>
  )
}
