import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CloseIcon from '@mui/icons-material/Close'
import { Grid, Typography } from '@mui/material'
import { FC, MouseEventHandler, useMemo } from 'react'
import { RedButton } from '../../../../../../atoms/Button'
import { DisplayColors } from '../../../../../../atoms/DisplayColors'
import { EllipsisText } from '../../../../../../atoms/EllipsisText'
import { Show } from '../../../../../../atoms/JSXExtensions'
import { RequestHeaderLink } from './RequestHeaderDesktopLayout'

type RequestHeaderTabletLayoutProps = {
  disableCancel?: boolean
  expirationDate: string
  exteriorColors: string[]
  interiorColors: string[]
  navigateTo: string
  navigateToNewTab: boolean
  onCancelRequest: MouseEventHandler<HTMLButtonElement>
  title: string
  tradeIn: string[]
  years: string
}

export const RequestHeaderTabletLayout: FC<RequestHeaderTabletLayoutProps> = ({
  disableCancel = false,
  expirationDate,
  exteriorColors,
  interiorColors,
  navigateTo = '',
  navigateToNewTab,
  onCancelRequest,
  title,
  tradeIn,
  years,
}) => {
  const [firstTradeInRow, secondTradeInRow] = tradeIn

  const tradeInStyles = useMemo(() => {
    if (firstTradeInRow !== 'Not included') {
      return {}
    }

    return {
      fontStyle: 'italic'
    }
  }, [firstTradeInRow])

  return (
    <Grid container>
      <Grid item xs={11}>
        <RequestHeaderLink
          navigateTo={navigateTo}
          navigateToNewTab={navigateToNewTab}
        >
          <Grid container spacing={1} alignItems='center'>
            <Grid item xs={6}>
              <Grid container flexDirection='column'>
                <Grid item xs={12}>
                  <Typography variant='emphasized'>
                    <EllipsisText tooltipText={title}>{title}</EllipsisText>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant='main'>{years}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container flexDirection='column'>
                <Grid item>
                  <Typography variant='main'>
                    <DisplayColors colors={exteriorColors} />{' '}/{' '}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant='main'>
                    <DisplayColors colors={interiorColors} />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container flexDirection='column'>
                <Grid item xs={12}>
                  <Typography variant='main' sx={tradeInStyles}>
                    <EllipsisText tooltipText={firstTradeInRow}>
                      {firstTradeInRow}
                    </EllipsisText>
                  </Typography>
                </Grid>
                <Show when={secondTradeInRow}>
                  {(secondRow) => (
                    <Grid item xs={12}>
                      <Typography variant='main'>
                        <EllipsisText tooltipText={secondRow}>
                          {secondRow}
                        </EllipsisText>
                      </Typography>
                    </Grid>
                  )}
                </Show>
              </Grid>
            </Grid>
            <Grid item container xs={6} gap={1} wrap='nowrap'>
              <Grid item>
                <AccessTimeIcon />
              </Grid>
              <Grid item xs='auto'>
                <Typography variant='main'>{expirationDate}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </RequestHeaderLink>
      </Grid>
      <Grid container item xs={1}>
        <Show when={!disableCancel}>
          <RedButton onClick={onCancelRequest} sx={{ minWidth: 'unset ' }}>
            <CloseIcon />
          </RedButton>
        </Show>
      </Grid>
    </Grid>
  )
}
