import { Grid } from '@mui/material'
import { FC } from 'react'
import { Divider } from '../../../atoms/Divider'
import { For } from '../../../atoms/JSXExtensions'
import { objectKeys } from '../../../utils/objectKeys'
import { ComponentContainer } from '../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const AssetsDemoContainer: FC<{
  iconsMap: Record<string, JSX.Element>
}> = ({ iconsMap }) => {
  return (
    <ComponentContainer fullWidth>
      <ComponentWithPaperBackground>
        <Grid container>
          <For of={objectKeys(iconsMap)}>
            {(iconKey: keyof typeof iconsMap) => (
              <>
                <Grid container mb='8px' mt='8px'>
                  <Grid xs={6} item display='flex' justifyContent='center'>
                    {iconsMap[iconKey]}
                  </Grid>
                  <Grid xs={6} item>
                    {iconKey}
                  </Grid>
                </Grid>
                <Divider />
              </>
            )}
          </For>
        </Grid>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
