import { Grid } from '@mui/material'
import { styled } from '@mui/system'

export const StatisticButtonGrid = styled(Grid)`
  height: 100%;
  & > div:not(:last-of-type)::after {
    content: '';
    position: absolute;
    right: 0;
    border-right: 1px solid #d9d9d9;
    height: 66%;
  }

  & > div:last-of-type {
    border-radius: 0 10px 10px 0;
  }

  & > div:first-of-type {
    border-radius: 10px 0 0 10px;
  }
`
