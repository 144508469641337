import { FC } from 'react'
import { Grid, Typography } from '@mui/material'
import { NoVehiclesImage } from '../../../assets/imgs/NoVehicles'

interface EmptyStateProps {
  category: string
}

export const EmptyState: FC<EmptyStateProps> = ({ category }) => {
  return (
    <Grid
      container
      justifyContent='center'
      alignContent='center'
      flexDirection='column'
      spacing={2}
    >
      <Grid item textAlign='center'>
        <NoVehiclesImage />
      </Grid>
      <Grid item>
        <Typography variant='h6' sx={{ textAlign: 'center' }}>
          There are no {category} vehicles yet.
        </Typography>
      </Grid>
    </Grid>
  )
}
