import { Typography } from '@mui/material'
import { OrangeButton } from '../Button'
import { Spinner } from '../Spinner'

export const LoadingText = () => {
  return (
    <OrangeButton startIcon={<Spinner size={24} />}>
      <Typography variant='main'>Loading...</Typography>
    </OrangeButton>
  )
}
