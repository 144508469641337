import { Box } from '@mui/material'
import { FC } from 'react'
import { SuccessScreen } from '../../../molecules/SuccessScreen'
import { RESET_PASSWORD_SUCCESS } from './constants'

export const ResetPasswordSuccessScreen: FC = () => {
  return (
    <Box
      sx={{
        maxHeight: '100vh',
        overflow: 'hidden',
        '> div': {
          mt: 'auto'
        }
      }}
    >
      <SuccessScreen
        title={RESET_PASSWORD_SUCCESS.TITLE}
        subtitle={RESET_PASSWORD_SUCCESS.SUBTITLE}
      />
    </Box>
  )
}
