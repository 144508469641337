import { objectKeys } from './objectKeys'

export const splitByProperty = <T extends object>(
  array: T[],
  property: { [key in keyof T]?: T[key][] }
): T[][] => {
  const keys = objectKeys(property)
  const targetKey = keys[0]

  const emptyState: T[][] = Array.from(
    { length: property[targetKey]?.length || 0 },
    () => []
  )

  return array.reduce((acc, item) => {
    if (!(targetKey in item)) {
      return acc
    }

    const index = property[targetKey]?.indexOf(item[targetKey])

    if (index === undefined || index === -1) {
      return acc
    }

    acc[index].push(item)

    return acc
  }, emptyState)
}
