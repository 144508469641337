import { configureStore } from '@reduxjs/toolkit'
import { chatStoreSlice } from '../features/Chat/model/chatStore'
import { miniChatSlice } from '../features/MiniChat/model/storage'
import { breadcrumbsSlice } from './features/Breadcrumbs/breadcrumbsSlice'
import { sideBarSlice } from './features/SideBar/sideBarSlice'
import { snackbarSlice } from './features/Snackbar/snackbarSlice'
import { userDeviceInfoSlice } from './features/userDeviceInfo/userDeviceInfoSlice'
import { userInfoSlice } from './features/userInfo/userInfo'
import { contractPageSlice } from './pages/Contract/contractPage'
import { completedDealsPageSlice } from './pages/Deals/completedDeals'
import { dealsPageSlice } from './pages/Deals/deals'
import { inProgressDealsPageSlice } from './pages/Deals/inProgressDeals'
import { garagePageSlice } from './pages/Garage/garage'
import { inventoryPageSlice } from './pages/Inventory/inventory'
import { offerPageSlice } from './pages/Offer/offerPage'
import { dealerHomepageSlice } from './pages/dealerMain/dealerHomePage'
import { externalServiceRegistrationSlice } from './pages/externalServiceRegistration/externalServiceRegistration'
import { addVehicleFormSlice } from './units/addVehicleForm/addVehicleForm'
import { dealershipListSlice } from './units/dealershipList/dealershipList'
import { editingAddressSlice } from './units/editingAddress/editingAddress'
import { findCarFormSlice } from './units/findCarForm/findCarForm'
import { loginFormSlice } from './units/loginForm'
import { registrationFormSlice } from './units/registrationForm'
import { statisticSlice } from './units/statistic/statisticSlice'
import { subscriptionModalSlice } from './units/subscription/subscriptionModal'
import { subscriptionAutoupdateSlice } from './units/subscriptionAutoupdate/subscriptionAutoupdate'

export const store = configureStore({
  reducer: {
    externalServiceRegistration: externalServiceRegistrationSlice.reducer,
    registrationForm: registrationFormSlice.reducer,
    dealershipList: dealershipListSlice.reducer,
    userDeviceInfo: userDeviceInfoSlice.reducer,
    userInfo: userInfoSlice.reducer,
    snackbarUnit: snackbarSlice.reducer,
    findCarForm: findCarFormSlice.reducer,
    addVehicle: addVehicleFormSlice.reducer,
    inventoryPage: inventoryPageSlice.reducer,
    inProgressDeals: inProgressDealsPageSlice.reducer,
    completedDeals: completedDealsPageSlice.reducer,
    dealsPage: dealsPageSlice.reducer,
    garagePage: garagePageSlice.reducer,
    dealerHomepage: dealerHomepageSlice.reducer,
    editingAddress: editingAddressSlice.reducer,
    contractPage: contractPageSlice.reducer,
    statistic: statisticSlice.reducer,
    subscriptionModal: subscriptionModalSlice.reducer,
    subscriptionAutoupdate: subscriptionAutoupdateSlice.reducer,
    breadcrumbs: breadcrumbsSlice.reducer,
    loginForm: loginFormSlice.reducer,
    sideBar: sideBarSlice.reducer,
    chatStore: chatStoreSlice.reducer,
    miniChatStore: miniChatSlice.reducer,
    offerPage: offerPageSlice.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
})
