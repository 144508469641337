import { styled } from '@mui/material'

interface IMessageContainerProps {
  backgroundColor?: string
}

export const MessageContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'backgroundColor'
})<IMessageContainerProps>(({ backgroundColor }) => ({
  borderRadius: '6px',
  padding: '10px',
  backgroundColor
}))
