import { useCallback } from 'react'
import { generatePath } from 'react-router-dom'
import {
  CompletedTransactionStatuses,
  VehicleStatusTypes
} from '../../api/carSnoopApi/statusTypes'
import { routes } from '../../routes'
import { DEAL_FLOW } from '../../types/enums/dealFlow'

export const useStatusPathResolver = (
  status?: VehicleStatusTypes | CompletedTransactionStatuses
) => {
  const getPathByStatus = useCallback(
    (params: { transactionId: string; flow?: DEAL_FLOW }) => {
      const { transactionId, flow = DEAL_FLOW.BUY } = params

      switch (status) {
        case 'Request Cancelled':
        case 'Request Declined':
        case 'Request Expired':
          return generatePath(routes.deals.details, {
            transactionId,
            mode: flow
          })
        case 'Offered':
        case 'Offer Cancelled':
        case 'Offer Expired':
        case 'Offer Declined':
          return generatePath(routes.offers.details, {
            transactionId
          })
        case 'Under Contract':
        case 'Awaiting Payment':
        case 'Shipping':
        case 'Contract Terminated':
        case 'Payment Failed':
        case 'Delivered':
          return generatePath(routes.contracts.transaction, {
            transactionId
          })
        default:
      }
    },
    [status]
  )

  return {
    getPathByStatus
  }
}
