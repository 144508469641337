import * as Yup from 'yup'
import { isValidEmail, latinWithSpecial } from '../../../utils/validators'

export const validationSchema = Yup.object({
  email: Yup.string()
    .test('is-email', 'Enter a valid email address.', isValidEmail())
    .test(
      'is-latin-or-special',
      'Enter a valid email address.',
      latinWithSpecial()
    )
})
