import UploadIcon from '@mui/icons-material/Upload'
import { Typography } from '@mui/material'
import { FC, useCallback, useMemo } from 'react'
import { BlueButton } from '../../../../../atoms/Button'
import { Condition } from '../../../../../atoms/Condition'
import { UploadNewDocumentModal } from '../../../../../features/UploadNewDocument'
import { UploadDocumentFormType } from '../../../../../features/UploadNewDocument/types'
import { selectContractPageModel } from '../../../../../store/pages/Contract'
import { OrNull } from '../../../../../types/OrNull'
import { COLORS } from '../../../../../types/colors'
import { ResponseStatusMap } from '../../../../../types/status'
import {
    useApiContext,
    useAppSelector,
    useDeviceInfo,
    useMakeService,
    useModal
} from '../../../../../utils/hooks'
import { useContractPageInfo } from '../../../hooks/useContractPageInfo'

interface UploadActionProps {
  name: string
  id: OrNull<string>
  type: OrNull<string>
  notes: OrNull<string>
  isEditable: boolean
}

export const UploadAction: FC<UploadActionProps> = ({
  name,
  id,
  type,
  notes,
  isEditable
}) => {
  const { isTablet } = useDeviceInfo()
  const { carSnoopApi } = useApiContext()
  const uploadDocumentModal = useModal()
  const { readTransaction, transactionID } = useContractPageInfo()
  const { transaction } = useAppSelector(selectContractPageModel)

  const [uploadContractDocument, uploadContractDocumentObservable] =
    useMakeService(
      async (document: UploadDocumentFormType) => {
        const response = await carSnoopApi.documents.createContractDocument({
          contractId: transaction?.contract?.id || '',
          type: document.file?.type || '',
          name,
          content: document.file?.content || '',
          notes: document?.notes
        })

        if (response.status === ResponseStatusMap.Success) {
          readTransaction({ id: transactionID, openContractInformation: false })
          return response
        }

        return { status: ResponseStatusMap.Error }
      },
      {
        withStatusNotification: true
      }
    )

  const submitUploadDocument = useCallback(
    async (data: UploadDocumentFormType) => {
      uploadContractDocument(data)
    },
    [uploadContractDocument]
  )

  const buttonColor = useMemo(
    () => (isEditable ? COLORS.accent_blue : COLORS.text_secondary),
    [isEditable]
  )
  return (
    <>
      <UploadNewDocumentModal
        id={id || ''}
        type={type || ''}
        notes={notes}
        {...uploadDocumentModal}
        isLoading={uploadContractDocumentObservable.isLoading}
        name={name}
        onSubmit={submitUploadDocument}
      />
      <Condition
        condition={isTablet}
        trueContent={
          <BlueButton
            onClick={uploadDocumentModal.onOpen}
            disabled={!isEditable}
          >
            <UploadIcon sx={{ color: buttonColor }} />
          </BlueButton>
        }
        falseContent={
          <BlueButton
            startIcon={<UploadIcon sx={{ color: buttonColor }} />}
            onClick={uploadDocumentModal.onOpen}
            disabled={!isEditable}
          >
            <Typography variant='body1' color={buttonColor}>
              Upload
            </Typography>
          </BlueButton>
        }
      />
    </>
  )
}
