import { Grid, Typography } from '@mui/material'
import { FC, ReactNode, useMemo } from 'react'
import { Divider } from '../../../atoms/Divider'
import { Show } from '../../../atoms/JSXExtensions'
import { formatPrice } from '../../../utils'

interface ItemRowProps {
  label: string
  value: ReactNode | string | number
  quantity?: number
}

export const ItemRow: FC<ItemRowProps> = ({ label, value, quantity }) => {
  const valueComponent = useMemo(() => {
    if (typeof value !== 'object') {
      return (
        <>
          <Show when={quantity}>
            <Typography component='span' variant='details' fontWeight='600'>
              {`${quantity}x `}
            </Typography>
          </Show>
          <Typography component='span' variant='label1'>
            {formatPrice(Number(value), { includeDecimalPart: true })}
          </Typography>
        </>
      )
    }

    return value
  }, [value])

  return (
    <Grid container flexDirection='column' rowSpacing={2}>
      <Grid item container justifyContent='space-between' alignItems='flex-end'>
        <Grid item>
          <Typography variant='subtitle2' textTransform='uppercase'>
            {label}
          </Typography>
        </Grid>
        <Grid item>{valueComponent}</Grid>
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
    </Grid>
  )
}
