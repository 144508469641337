import { Box } from '@mui/material'
import { FC, ReactNode } from 'react'
import { COLORS } from '../../types/colors'
import { Badge } from '../Badge'
import { For, Show } from '../JSXExtensions'

interface VehicleBadgeProps {
  badges?: Array<{
    position: string
    label: string
    component?: ReactNode
    background?: string
    color?: string
    border?: string
  }>
}

const badgeRootStyle = {
  position: 'absolute' as const
}

const badgePositionStyle = {
  'top-left': {
    top: '6px',
    left: '6px'
  },
  'top-right': {
    top: '6px',
    right: '6px'
  },
  'bottom-left': {
    bottom: '6px',
    left: '6px'
  },
  'bottom-right': {
    bottom: '6px',
    right: '6px'
  }
}

export const VehicleBadge: FC<VehicleBadgeProps> = ({ badges = [] }) => {
  return (
    <For of={badges}>
      {(badge) => (
        <Box
          sx={{
            ...badgeRootStyle,
            ...(badge.position in badgePositionStyle &&
              badgePositionStyle[
                badge.position as keyof typeof badgePositionStyle
              ])
          }}
        >
          <Show
            when={badge.component}
            key={badge.label}
            fallback={
              <Badge
                text={badge.label}
                color={badge.color || COLORS.main_black}
                background={badge.background || COLORS.hover_background_blue}
                border={badge.border || 'none'}
                textSx={{
                  fontSize: '12px'
                }}
              />
            }
          >
            {(BadgeComponent) => <Box zIndex={999}>{BadgeComponent}</Box>}
          </Show>
        </Box>
      )}
    </For>
  )
}
