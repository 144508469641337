import { useCallback } from 'react'
import { useRequestToast } from '../../useToast'
import { ACCESS_DENIED } from '../../../../constants/messages'
import {
  RequestStatusMap,
  ResponseStatus,
  ResponseStatusMap
} from '../../../../types/status'
import { ServiceType } from '../types'

export const useAccessDeniedHandler = () => {
  const { openRequestToast } = useRequestToast()

  const isAccessDenied = useCallback(
    <T extends ServiceType>({
      status,
      payload
    }: {
      status: ResponseStatus
      payload: Awaited<ReturnType<T>>
    }) =>
      status === ResponseStatusMap.Error && payload.message === ACCESS_DENIED,
    []
  )

  const processAccessDenied = useCallback(() => {
    openRequestToast({
      status: RequestStatusMap.Failed,
      errorMessage: ACCESS_DENIED
    })

    setTimeout(() => {
      window.location.reload()
    }, 1000)
  }, [openRequestToast])

  return { isAccessDenied, processAccessDenied }
}
