import { ResponseStatusMap } from '../types/status'
import { ApiContextCreator } from '../apiContext'

export type AuthenticatedUserValues = {
  id: string
  sessionID: string
}

export const authorizeBySession = async (
  options: AuthenticatedUserValues,
  contextAPI: ApiContextCreator
) => {
  const { setSession, carSnoopApi } = contextAPI

  setSession(options.sessionID)
  const userData = await carSnoopApi.users.readUser(options.id)

  if (userData.status === ResponseStatusMap.Success) {
    const accountData = await carSnoopApi.accounts.readAccount(
      userData.accountID
    )

    if (accountData.status === ResponseStatusMap.Success) {
      return {
        id: options.id,
        sessionId: options.sessionID,
        isDealer: accountData.isDealer,
        users: accountData.users,
        dealershipName: accountData.name,
        addresses: accountData.addresses,
        taxID: userData.taxID,
        driversLicense: userData.driversLicense,
        driversLicenseState: userData.driversLicenseState,
        driversLicenseExpiration: userData.driversLicenseExpiration,
        driverLicenseFile: userData.driverLicenseFile,
        isAdmin: userData.isAdmin,
        accountID: userData.accountID,
        firstName: userData.firstName,
        lastName: userData.lastName,
        phone: userData.phone,
        dateOfBirth: userData.birthDate,
        email: userData.id,
        fullName: userData.fullName,
        avatar: userData.avatar,
        public: accountData.public,
        subscription: accountData.subscription,
        submitRequestFeePaid: accountData.submitRequestFeePaid
      }
    }
  }
}
