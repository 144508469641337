import { FormikValues } from 'formik'
import { useCallback, useState } from 'react'
import { FormOrchestratorCtx } from '../../types'

export const useFormOrchestrator = (): FormOrchestratorCtx => {
  const [step, setStep] = useState(0)
  const [values, setValues] = useState<FormikValues>({})

  const nextStep = useCallback(() => {
    setStep((s) => s + 1)
  }, [setStep])

  const prevStep = useCallback(() => {
    setStep((s) => s - 1)
  }, [setStep])

  const clearValues = useCallback(() => {
    setValues({})
  }, [setValues])

  return {
    step,
    setStep,
    values,
    setValues,
    nextStep,
    prevStep,
    clearValues
  }
}
