import { ResponseStatusMap } from '../../../types/status'
import {
  ErrorResponse,
  MessageResponse,
  SuccessResponse
} from '../../private.types'
import { Utils } from '../../utils'
import {
  NormalizedReadAccountType,
  NormalizedReadInventoryType,
  NormalizedRegisterInvitedUserType,
  RawCheckStripePaymentMethodType,
  RawGetAccountStatisticsType,
  RawReadAccountType,
  RawReadInventoryType,
  RawRegisterInvitedUserType,
  ReadInventoryOptionsType,
  RegisterInvitedUserOptionsType
} from './types'
import { formatReadAccount } from './types/readAccount/formatter'
import { readInventoryFormatter } from './types/readInventory/formatter'
import { registerInvitedUserDataToSend } from './types/registerInvitedUser/getDataToSend'

export class Accounts {
  protected readonly utils: Utils
  protected readonly operations = {
    readDealershipList: 'readDealershipList',
    readAccount: 'readAccount',
    registerInvitedUser: 'registerInvitedUser',
    addMultipleUsers: 'addMultipleUsers',
    readInventory: 'readInventory',
    makeAccountPublic: 'makeAccountPublic',
    makeAccountPrivate: 'makeAccountPrivate',
    readAccountOwners: 'readAccountOwners',
    getAccountStatistics: 'getAccountStatistics',
    checkStripePaymentMethod: 'checkStripePaymentMethod'
  }

  constructor(utils: Utils) {
    this.utils = utils
  }

  async readDealershipList(zipCode: string): Promise<Response> {
    return this.utils.makePostRequest(this.operations.readDealershipList, {
      zip_code: zipCode
    })
  }

  async readAccount(accountId: string): Promise<
    | SuccessResponse<NormalizedReadAccountType>
    | ErrorResponse<{
      id: string
      message: string
    }>
  > {
    type ErrorAPIResponse = {
      id: string
      message: string
    }

    const response = await this.utils.makeJSONRequest<
      RawReadAccountType,
      ErrorAPIResponse
    >(this.operations.readAccount, {
      id: accountId
    })

    if (response.type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        ...formatReadAccount(response.payload)
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: response.payload.id,
      message: response.payload.message
    }
  }

  async registerInvitedUser(data: RegisterInvitedUserOptionsType): Promise<
    | SuccessResponse<NormalizedRegisterInvitedUserType>
    | ErrorResponse<{
      id: string
      message: string
    }>
  > {
    type ErrorAPIResponse = {
      id: string
      message: string
    }

    const dataToSend = registerInvitedUserDataToSend(data)

    const response = await this.utils.makeJSONRequest<
      RawRegisterInvitedUserType,
      ErrorAPIResponse
    >(this.operations.registerInvitedUser, dataToSend)

    if (response.type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        accountId: response.payload.account_id,
        userId: response.payload.account_id,
        message: response.payload.message
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: response.payload.id,
      message: response.payload.message
    }
  }

  async addMultipleUsers({ emails }: { emails: string[] }): Promise<
    | SuccessResponse<{
      id: string
      message: string
    }>
    | ErrorResponse<{
      message: string
    }>
  > {
    type APISuccessResponse = {
      id: string
      message: string
    }

    type APIErrorResponse = {
      message: string
    }

    const dataToSend = {
      emails: emails.map((email) => ({ email }))
    }

    const response = await this.utils.makeJSONRequest<
      APISuccessResponse,
      APIErrorResponse
    >(this.operations.addMultipleUsers, dataToSend)

    if (response.type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        id: response.payload.id,
        message: response.payload.message
      }
    }

    return {
      status: ResponseStatusMap.Error,
      message: response.payload.message
    }
  }

  async readInventory({
    accountID,
    make = '',
    model = '',
    minYear = '',
    maxYear = '',
    status = '',
    stockNumber = '',
    ordering = false
  }: ReadInventoryOptionsType): Promise<
    | SuccessResponse<NormalizedReadInventoryType>
    | ErrorResponse<{
      message: string
    }>
  > {
    type APIErrorResponseType = {
      id: string
      message: string
    }

    const response = await this.utils.makeJSONRequest<
      RawReadInventoryType,
      APIErrorResponseType
    >(this.operations.readInventory, {
      account_id: accountID,
      make,
      model,
      min_year: minYear,
      max_year: maxYear,
      status,
      stock_number: stockNumber,
      ordering
    })

    if (response.type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        inventory: readInventoryFormatter(response.payload)
      }
    }

    return {
      status: ResponseStatusMap.Error,
      message: response.payload.message
    }
  }

  async readAccountOwners({ id }: { id: string }): Promise<
    | SuccessResponse<{
      accounts: Array<{
        id: string
        full_name: string
      }>
    }>
    | ErrorResponse<{
      message: string
    }>
  > {
    type SuccessResponse = Array<{
      id: string
      full_name: string
    }>

    type ErrorResponse = {
      message: string
    }

    const { type, payload } = await this.utils.makeJSONRequest<
      SuccessResponse,
      ErrorResponse
    >(this.operations.readAccountOwners, { id })

    if (type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        accounts: payload
      }
    }

    return {
      status: ResponseStatusMap.Error,
      message: payload.message
    }
  }

  async makeAccountPublic(): Promise<
    | SuccessResponse<{
      id: string
      message: string
    }>
    | ErrorResponse<{
      id: string
      message: string
    }>
  > {
    type SuccessResponse = {
      id: string
      message: string
    }

    type ErrorResponse = {
      id: string
      message: string
    }

    const { type, payload } = await this.utils.makeJSONRequest<
      SuccessResponse,
      ErrorResponse
    >(this.operations.makeAccountPublic)

    if (type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        id: payload.id,
        message: payload.message
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: payload.id,
      message: payload.message
    }
  }

  async makeAccountPrivate(): Promise<
    | SuccessResponse<{
      id: string
      message: string
    }>
    | ErrorResponse<{
      id: string
      message: string
    }>
  > {
    type SuccessResponse = {
      id: string
      message: string
    }

    type ErrorResponse = {
      id: string
      message: string
    }

    const { type, payload } = await this.utils.makeJSONRequest<
      SuccessResponse,
      ErrorResponse
    >(this.operations.makeAccountPrivate)

    if (type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        id: payload.id,
        message: payload.message
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: payload.id,
      message: payload.message
    }
  }

  async getAccountStatistics(): Promise<
    | SuccessResponse<RawGetAccountStatisticsType>
    | ErrorResponse<{ message: string }>
  > {
    const { type, payload } = await this.utils.makeJSONRequest<
      RawGetAccountStatisticsType,
      { message: string }
    >(this.operations.getAccountStatistics)

    if (type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        requests: payload.requests,
        offers: payload.offers,
        contracts: payload.contracts
      }
    }

    return {
      status: ResponseStatusMap.Error,
      message: payload.message
    }
  }

  async checkStripePaymentMethod(): Promise<
    | SuccessResponse<RawCheckStripePaymentMethodType>
    | ErrorResponse<MessageResponse>
  > {
    const { type, payload } = await this.utils.makeJSONRequest<
      RawCheckStripePaymentMethodType,
      MessageResponse
    >(this.operations.checkStripePaymentMethod)

    if (type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        result: payload.result
      }
    }

    return {
      status: ResponseStatusMap.Error,
      message: payload.message
    }
  }
}
