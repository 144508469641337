import { Grid } from '@mui/material'
import { FC, useMemo } from 'react'
import { Show } from '../../../../../../atoms/JSXExtensions'
import {
  DeliveryCard,
  useDeliveryCard
} from '../../../../../../features/DeliveryCard'
import { DetailsItem } from '../DetailsItem'
import { formatPrice, formatNumber } from '../../../../../../utils'
import { IDeliveryInfo } from './types'
import { RenderNotAvaliableValue } from '../RenderNotAvaliableValue'

export const DeliveryInfo: FC<IDeliveryInfo> = ({
  vehicle,
  tradeIn,
  shipmentInfo,
  isNotAvailable = false,
  hasAddressError = false,
  setPickup,
  setDropOff
}) => {
  const vehicleDistance = useMemo(() => {
    const value = shipmentInfo?.vehicle.distance || 0

    return `${formatNumber(value, { includeDecimalPart: true })} miles`
  }, [shipmentInfo?.vehicle.distance])

  const vehiclesCount = useMemo(() => {
    return tradeIn ? '2' : '1'
  }, [tradeIn])

  const shippingCost = useMemo(() => {
    return formatPrice(shipmentInfo.vehicle.cost, { includeDecimalPart: true })
  }, [shipmentInfo.vehicle.cost])

  const detailsItemRenderValue = useMemo(
    () => (isNotAvailable ? RenderNotAvaliableValue : undefined),
    [isNotAvailable]
  )

  const { deliveryVehicleProps, tradeInVehicleProps } = useDeliveryCard({
    vehicle,
    tradeIn,
    shipmentInfo,
    hasAddressError,
    setPickup,
    setDropOff
  })

  return (
    <Grid container>
      <Grid item mb='24px' xs={12}>
        <DeliveryCard {...deliveryVehicleProps} />
      </Grid>
      <Show when={tradeInVehicleProps}>
        {(props) => (
          <Grid item mb='36px' xs={12}>
            <DeliveryCard {...props} />
          </Grid>
        )}
      </Show>
      <Grid item container spacing='36px' flexDirection='column'>
        <Grid item>
          <DetailsItem
            title='Distance'
            value={vehicleDistance}
            renderValue={detailsItemRenderValue}
            withoutDivider
          />
        </Grid>
        <Grid item>
          <DetailsItem title='VHCS' value={vehiclesCount} withoutDivider />
        </Grid>
        <Grid item>
          <DetailsItem
            title='Cost'
            value={shippingCost}
            renderValue={detailsItemRenderValue}
            withoutDivider
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
