import { Grid } from '@mui/material'
import { useField } from 'formik'
import { FC, useCallback } from 'react'
import { DeleteIcon } from '../../assets/icons/Delete'
import { Counter } from '../../atoms/Counter'

import { NumericField } from '../../atoms/NumericField'
import { TextInputFieldNew } from '../../atoms/TextInputFieldNew'
import {
  AmountField,
  ButtonField,
  DescriptionField,
  InputsContainer,
  PriceField
} from './AddOn.styles'

interface IAddOnProps {
  onRemoveAddOn: (index: number) => void
  index: number
  fieldGroupName: string
  onBlur?: () => void
}

export const AddOn: FC<IAddOnProps> = ({
  onRemoveAddOn,
  onBlur,
  index,
  fieldGroupName
}) => {
  const [quantity, , quantityHelpers] = useField({
    name: `${fieldGroupName}.${index}.quantity`,
    type: 'hidden'
  })
  const handleCounterChange = (value: number) => () =>
    quantityHelpers.setValue(value)
  const handleRemoveAddOn = useCallback(() => onRemoveAddOn(index), [index])

  return (
    <Grid container direction='column'>
      <InputsContainer>
        <DescriptionField>
          <TextInputFieldNew
            name={`${fieldGroupName}.${index}.description`}
            placeholder='Description'
            label='Description'
            onBlur={onBlur}
            required
          />
        </DescriptionField>
        <PriceField>
          <NumericField
            allowNegative={true}
            startWith='$'
            name={`${fieldGroupName}.${index}.price`}
            label='Price'
            onBlur={onBlur}
            required
          />
        </PriceField>
        <AmountField>
          <Counter
            name={`${fieldGroupName}.${index}.quantity`}
            countValue={quantity.value}
            onAddClick={handleCounterChange(quantity.value + 1)}
            onSubClick={handleCounterChange(quantity.value - 1)}
          />
        </AmountField>
        <ButtonField onClick={handleRemoveAddOn}>
          <DeleteIcon />
        </ButtonField>
      </InputsContainer>
    </Grid>
  )
}
