import { Dispatch, FC, SetStateAction } from 'react'
import { COLORS } from '../../types/colors'
import { CommonConfirmationModal } from './CommonConfirmationModal'

export interface DiscardConfirmationModalProps {
  isOpen: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  onSubmit: () => void | Promise<void>
}

export const DiscardConfirmationModal: FC<DiscardConfirmationModalProps> = ({
  isOpen,
  setOpen,
  onSubmit
}) => {
  return (
    <CommonConfirmationModal
      cancelText='Cancel'
      header='Discard Changes'
      confirmText='Discard'
      message='Are you sure you want to discard your changes?'
      messageColor={COLORS.text_primary}
      isOpen={isOpen}
      setOpen={setOpen}
      onSubmit={onSubmit}
    />
  )
}
