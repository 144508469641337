import { createSlice } from '@reduxjs/toolkit'
import { isAndroid, isIOS } from 'react-device-detect'
import { BREAKPOINTS } from '../../../types/breakpoints'

interface UserDeviceInfoState {
  isOnLine: boolean
  isTablet: boolean
  isAndroid: boolean
  isIOS: boolean
  currentScreenWidth: number
  currentScreenHeight: number
}

export const initialState: UserDeviceInfoState = {
  isOnLine: true,
  isTablet: false,
  isAndroid: false,
  isIOS: false,
  currentScreenWidth: NaN,
  currentScreenHeight: NaN
}

export const userDeviceInfoSlice = createSlice({
  name: 'userDeviceInfo',
  initialState,
  reducers: {
    read: (state) => {
      const isOnLine = navigator.onLine
      const currentScreenWidth = window.innerWidth
      const currentScreenHeight = window.innerHeight

      const isTablet = currentScreenWidth < BREAKPOINTS.LG

      return {
        ...state,
        isOnLine,
        isTablet,
        isIOS,
        isAndroid,
        currentScreenWidth,
        currentScreenHeight
      }
    }
  }
})
