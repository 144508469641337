import { FC, useCallback, useMemo } from 'react'
import { FormikValues } from 'formik'
import { ForgotPasswordContainer } from './ForgotPasswordContainer'
import { PromotionBlock } from '../../atoms/PromotionBlock'
import { ResentLinkContainer } from './ResentLinkContainer'
import { Condition } from '../../atoms/Condition'
import { ServicePageContainer } from '../../layout/ServicePageContainer'
import { useNavigate, useMakeService } from '../../utils/hooks'
import { carSnoopApi } from '../../api'
import { routes } from '../../routes'

export const ForgotPassword: FC = () => {
  const navigate = useNavigate()
  const [forgotPasswordService, forgotPassword] = useMakeService(
    ({ email }: FormikValues) => carSnoopApi.users.forgotPassword(email),
    {
      props: {
        email: ''
      }
    }
  )
  const [resendLinkService, resendLink] = useMakeService(
    (email: string) => carSnoopApi.users.forgotPassword(email),
    {
      props: ''
    }
  )

  const onExit = useCallback(() => {
    navigate(routes.login)
  }, [])

  const isLoading = useMemo(() => {
    return forgotPassword.isLoading || resendLink.isLoading
  }, [forgotPassword, resendLink])

  return (
    <ServicePageContainer>
      <PromotionBlock />
      <Condition
        condition={!forgotPassword.isSuccess}
        trueContent={
          <ForgotPasswordContainer
            isLoading={isLoading}
            email={forgotPassword.props.email}
            status={forgotPassword.status}
            message={forgotPassword.payload.message}
            onSubmit={forgotPasswordService}
            onExit={onExit}
          />
        }
        falseContent={
          <ResentLinkContainer
            isLoading={isLoading}
            email={forgotPassword.props.email}
            resendLinkService={resendLinkService}
          />
        }
      />
    </ServicePageContainer>
  )
}
