import { Box } from '@mui/material'
import { FC, PropsWithChildren } from 'react'
import { Modal } from '../../molecules/Modal'
import { useModal } from '../../utils/hooks'

interface IAudioPlayerProps {
  link: string
}

export const AudioPlayer: FC<PropsWithChildren<IAudioPlayerProps>> = ({
  link,
  children
}) => {
  const audioModal = useModal()

  return (
    <>
      <Box onClick={audioModal.onOpen}>{children}</Box>
      <Modal open={audioModal.isOpen} onClose={audioModal.onCancel}>
        <Box
          style={{
            position: 'absolute',
            bottom: '65px',
            left: '50%',
            transform: 'translateX(-50%)'
          }}
        >
          <audio controls autoPlay>
            <source src={link} />
            <track kind='captions' />
          </audio>
        </Box>
      </Modal>
    </>
  )
}
