export type RequestStatus = 'idle' | 'pending' | 'succeeded' | 'failed'
export type ResponseStatus = 'success' | 'error'

export enum ResponseStatusMap {
  Success = 'success',
  Error = 'error'
}

export enum RequestStatusMap {
  Idle = 'idle',
  Pending = 'pending',
  Succeeded = 'succeeded',
  Failed = 'failed'
}

export enum UserStatusMap {
  Admin = 'Admin',
  User = 'User',
  Pending = 'Pending'
}

export type VehicleCondition = 'Extra Clean' | 'Clean' | 'Average' | 'Rough'

export enum VehicleConditionMap {
  'Extra Clean' = 'New',
  Clean = 'Pre-owned',
  Average = 'Good',
  Rough = 'Rough'
}
