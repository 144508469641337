import { FC, useEffect, useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { carSnoopApi } from '../../api'
import { Condition } from '../../atoms/Condition'
import { PromotionBlock } from '../../atoms/PromotionBlock'
import { ServicePageContainer } from '../../layout/ServicePageContainer'
import { routes } from '../../routes'
import { useMakeService } from '../../utils/hooks'
import { ResentLinkContainer } from '../ForgotPassword'
import { ResetLinkExpiredContainer } from './ResetLinkExpiredContainer'
import { ResetPasswordContainer } from './ResetPasswordContainer'
import { ResetPasswordSuccessScreen } from './ResetPasswordSuccessScreen'
import { ResetPasswordConstants } from './constants'

export const ResetPassword: FC = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const linkEmail = searchParams.get('email') || ''
  const linkToken = searchParams.get('token') || ''

  const [resendLinkService, resendLink] = useMakeService(
    (id: string) => carSnoopApi.users.forgotPassword(id),
    {
      props: ''
    }
  )

  const [verifyForgotPasswordTokenService, verifyForgotPasswordToken] =
    useMakeService(
      (data: { id: string; token: string }) =>
        carSnoopApi.users.verifyForgotPasswordToken(data),
      {
        props: {
          id: '',
          token: ''
        }
      }
    )

  const [resetPasswordService, resetPassword] = useMakeService(
    (data: { password: string; id: string; token: string }) =>
      carSnoopApi.users.resetPassword(data)
  )

  useEffect(() => {
    verifyForgotPasswordTokenService({ id: linkEmail, token: linkToken })
  }, [])

  useEffect(() => {
    if (resetPassword.isSuccess) {
      navigate(routes.login)
    }
  }, [resetPassword.isSuccess])

  const isLoading = useMemo(() => {
    return (
      resetPassword.isLoading ||
      resendLink.isLoading ||
      verifyForgotPasswordToken.isLoading
    )
  }, [resetPassword, resendLink, verifyForgotPasswordToken])

  const isExpired = useMemo(() => {
    return resetPassword.isError || !verifyForgotPasswordToken.isSuccess
  }, [resetPassword, verifyForgotPasswordToken])

  return (
    <ServicePageContainer>
      <Condition
        condition={!isLoading && isExpired}
        trueContent={<PromotionBlock description={ResetPasswordConstants.promotionDescription} />}
      />
      <Condition
        condition={isExpired}
        trueContent={
          <Condition
            condition={resendLink.isSuccess}
            trueContent={
              <ResentLinkContainer
                isLoading={resendLink.isLoading}
                email={linkEmail}
                resendLinkService={resendLinkService}
              />
            }
            falseContent={
              <ResetLinkExpiredContainer
                isLoading={isLoading}
                email={linkEmail}
                resendLinkService={resendLinkService}
              />
            }
          />
        }
        falseContent={
          <Condition
            condition={resetPassword.isSuccess}
            trueContent={<ResetPasswordSuccessScreen />}
            falseContent={
              <ResetPasswordContainer
                isLoading={isLoading}
                id={linkEmail}
                token={linkToken}
                onSubmit={resetPasswordService}
              />
            }
          />
        }
      />
    </ServicePageContainer>
  )
}
