import { createAsyncThunk } from '@reduxjs/toolkit'
import { carSnoopApi } from '../../../../api'
import { ReadModelsParams } from '../../../../api/carSnoopApi/vehicles'
import type { ReadModelsResponse } from './types'

export const readModels = createAsyncThunk(
  'readModels/byMaker',
  async (params: ReadModelsParams): Promise<ReadModelsResponse> => {
    const response = await carSnoopApi.vehicles.readModels(params)

    if (response.status >= 200 && response.status < 300) {
      const data = await response.json()

      return {
        status: 'succeeded',
        data
      }
    }

    return {
      status: 'failed',
      data: []
    }
  }
)
