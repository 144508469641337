import { DependentField } from '../../../../../atoms/DependentField'
import {
  MaskedInput,
  MaskedInputFieldProps
} from '../../../../../atoms/MaskedInput'
import { FIELDS_NAMES, FormFields } from './types'

export const DependentZipCodeField = DependentField<
  MaskedInputFieldProps,
  FormFields
>(MaskedInput, {
  deps: [FIELDS_NAMES.ZIP_CODE]
})
