import { COLORS } from '../../types/colors'

export const modalStyle = {
  width: '100%',
  maxWidth: '90vw',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

export const modalContainerStyle = {
  p: 5,
  pt: 0,
  background: COLORS.white,
  minWidth: '750px',
  width: '100%',
  maxWidth: '1272px',
  borderRadius: '14px',
  overflowY: 'auto',
  maxHeight: '90vh'
}

export const modalHeaderStyle = { mb: 1, position: 'relative' }

export const closeButtonStyle = { minWidth: 0, padding: 0 }
