import { FC } from 'react'
import { Grid, Paper } from '@mui/material'
import { Show } from '../../atoms/JSXExtensions'
import { OfferHeader } from './components/Header'
import { OfferDetailsWidgetProps } from './types'
import { OfferActions } from './components/Actions'
import { OfferDetailsBody } from './OfferDetailsBody'
import { PreviousOffer } from './components/PreviousOffer'
import { getOfferComponentPrice } from '../OfferModal/OfferModal.helpers'

export const OfferDetailsWidget: FC<OfferDetailsWidgetProps> = ({
  actions,
  previousOffer,
  showActions = true,
  ...props
}) => {
  return (
    <Paper sx={{ width: '100%', p: 3 }}>
      <Grid container rowSpacing={2} flexDirection='column'>
        <Grid item>
          <OfferHeader actions={actions} showActions={showActions} />
        </Grid>
        <Grid item>
          <OfferDetailsBody {...props} />
        </Grid>
        <Show when={previousOffer}>
          {(offer) => (
            <Grid item>
              <PreviousOffer
                totalPrice={offer.totalPrice}
                addons={offer.addons}
                notes={offer.notes}
                vehiclePrice={getOfferComponentPrice(offer, 'Vehicle Price')}
                salesTax={getOfferComponentPrice(offer, 'Sales Tax')}
                tradeinValue={getOfferComponentPrice(offer, 'Trade-In Vehicle')}
              />
            </Grid>
          )}
        </Show>
        <Show when={showActions}>
          <Grid item mt={3}>
            <OfferActions actions={actions} />
          </Grid>
        </Show>
      </Grid>
    </Paper>
  )
}
