import { Grid } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import { Condition } from '../../../../atoms/Condition'
import { Divider } from '../../../../atoms/Divider'
import { Loader } from '../../../../atoms/Loader'
import { FormOrchestratorStep } from '../../../../layout/FormOrchestrator/types'
import { DiscardConfirmationModal } from '../../../../molecules/Modal'
import { ResponseStatusMap } from '../../../../types/status'
import {
  useApiContext,
  useCombinedFormValues,
  useMakeService
} from '../../../../utils/hooks'
import { useAddVehicleDiscardModal, useAddVehicleFormSlice } from '../hooks'
import {
  AddVehicleFormControls,
  LicensePlateFieldSet,
  LicensePlateFieldSetProps,
  VinFieldSet,
  VinFieldSetProps,
  getLicensePlateInitialValues,
  getVinInitialValues
} from './Controls'
import { EditNewVehicleForm } from './Form'
import { AddOldVehicleForm } from './Form/AddOldVehicleForm'
import { PrefilledValues } from './Form/types'
import { useReadModelYear } from './hooks/useReadModelYear'

const flowList = ['License Plate', 'VIN', 'Older Car'] as const

interface ControlsComponentsMapType {
  'License Plate': JSX.Element
  VIN: JSX.Element
  'Older Car': null
}

const getInitialValues = (
  lpInitValues: Parameters<typeof getLicensePlateInitialValues>[0],
  vinInitValues: Parameters<typeof getVinInitialValues>[0]
) => ({
  'License Plate': getLicensePlateInitialValues(lpInitValues),
  VIN: getVinInitialValues(vinInitValues)
})

const initialLicensePlatePrefilledValues = {
  state: '',
  country: '',
  vin: '',
  uvc: '',
  year: '',
  make: '',
  model: '',
  trim: '',
  style: '',
  class: '',
  fuelType: '',
  engineDescription: '',
  transmission: '',
  mpg: '',
  drivetrain: '',
  standardEquipment: null
}

const initialVinPrefilledValues = {
  country: '',
  vin: '',
  uvc: '',
  year: '',
  make: '',
  model: '',
  trim: '',
  style: '',
  class: '',
  fuelType: '',
  engineDescription: '',
  transmission: '',
  mpg: '',
  drivetrain: '',
  standardEquipment: null
}

const initialPrefilledValues = {
  'License Plate': initialLicensePlatePrefilledValues,
  VIN: initialVinPrefilledValues,
  'Older Car': {}
}

export const EditNewVehicleDetails: FormOrchestratorStep = ({ ctx }) => {
  const { externalCarSnoopApi } = useApiContext()
  const combinedFormsStorage = useCombinedFormValues(
    getInitialValues(
      {
        licensePlate: ctx.values.formLicensePlate,
        state: ctx.values.formLicensePlateState
      },
      {
        vin: ctx.values.vinFieldSetValue
      }
    )
  )

  const {
    isDiscardModalOpen,
    isNavigateConfirmationOpen,
    setDiscardModalOpen,
    setIsNavigateConfirmationOpen,
    handleDiscard,
    handleNavigationDiscard
  } = useAddVehicleDiscardModal()

  const { flow: currentFlow, setFlow } = useAddVehicleFormSlice()

  const [prefilledValues, setPrefilledValues] = useState<PrefilledValues>(
    initialPrefilledValues[currentFlow]
  )

  const onFlowChange = (nextFlow: keyof ControlsComponentsMapType) => {
    setFlow(nextFlow)
    combinedFormsStorage.updateValues('License Plate', {
      licensePlate: '',
      state: null
    })
    combinedFormsStorage.updateValues('VIN', { vin: '' })
    ctx.clearValues()
    setPrefilledValues(initialPrefilledValues[nextFlow])
  }

  const [requestDataByVin, requestDataByVinObservable] = useMakeService(
    async (...options: Parameters<VinFieldSetProps['onSubmit']>) => {
      const [formValues, formikHelpers] = options

      const response = await externalCarSnoopApi.vehicles.readVIN(formValues)

      if (response.status === ResponseStatusMap.Success) {
        const { status, ...data } = response

        setPrefilledValues({ ...data, vin: formValues.vin })
        ctx.setValues(() => ({
          vinFieldSetValue: formValues.vin
        }))
      } else {
        setPrefilledValues(initialPrefilledValues.VIN)
      }

      const errors = {
        vin:
          response.status !== ResponseStatusMap.Success ? response.message : ''
      }

      formikHelpers.setErrors(errors)

      return response
    }
  )

  const [requestDataByLicensePlate, requestDataByLicensePlateObservable] =
    useMakeService(
      async (...options: Parameters<LicensePlateFieldSetProps['onSubmit']>) => {
        const [formValues, formikHelpers] = options

        const response = await externalCarSnoopApi.vehicles.readLicensePlate({
          plate: formValues.licensePlate,
          state: formValues.state.name
        })

        if (response.status === ResponseStatusMap.Success) {
          const { status, ...data } = response

          setPrefilledValues(data)
          ctx.setValues(() => ({
            formLicensePlate: formValues.licensePlate,
            formLicensePlateState: formValues.state
          }))
        } else {
          setPrefilledValues(initialPrefilledValues['License Plate'])
        }

        const errors = {
          licensePlate:
            response.status !== ResponseStatusMap.Success
              ? response.message
              : ''
        }

        formikHelpers.setErrors(errors)

        return response
      }
    )

  const [isLoadingCurrentVehicleOptions, currentVehicleOptions] =
    useReadModelYear({
      make: prefilledValues?.make || ctx.values?.make,
      model: prefilledValues?.model || ctx.values?.model,
      year: prefilledValues?.year || ctx.values?.year
    })

  const isLoading = useMemo(
    () =>
      isLoadingCurrentVehicleOptions ||
      requestDataByVinObservable.isLoading ||
      requestDataByLicensePlateObservable.isLoading,
    [
      isLoadingCurrentVehicleOptions,
      requestDataByVinObservable.isLoading,
      requestDataByLicensePlateObservable.isLoading
    ]
  )

  const handleCancelClick = useCallback(() => {
    setDiscardModalOpen(true)
  }, [setDiscardModalOpen])

  const isOldCarFlow = useMemo(() => currentFlow === 'Older Car', [currentFlow])
  const isVinFlow = useMemo(() => currentFlow === 'VIN', [currentFlow])

  const carName = useMemo(() => {
    const make = prefilledValues.make || (ctx.values.make as string)
    const year = prefilledValues.year || (ctx.values.year as string)
    const model = prefilledValues.model || (ctx.values.model as string)
    const trim = prefilledValues.trim || (ctx.values.trim as string) || ''

    return make && !isOldCarFlow ? `${year} ${make} ${model} ${trim}` : ''
  }, [
    prefilledValues?.year,
    prefilledValues?.make,
    prefilledValues?.model,
    prefilledValues?.trim,
    isOldCarFlow,
    isVinFlow,
    ctx.values
  ])

  const ControlsComponentsMap: ControlsComponentsMapType = useMemo(() => {
    return {
      'License Plate': (
        <LicensePlateFieldSet
          onSubmit={requestDataByLicensePlate}
          storage={combinedFormsStorage}
        />
      ),
      VIN: (
        <VinFieldSet
          onSubmit={requestDataByVin}
          storage={combinedFormsStorage}
        />
      ),
      'Older Car': null
    }
  }, [currentFlow])

  return (
    <>
      <Grid container gap={2}>
        <Grid item xs={12}>
          <AddVehicleFormControls
            flowList={flowList}
            currentFlow={currentFlow}
            onFlowChange={onFlowChange}
            ControlsComponentsMap={ControlsComponentsMap}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider text={carName} />
        </Grid>
        <Grid item xs={12}>
          <Condition
            condition={isLoading}
            trueContent={
              <Grid container justifyContent='center' width='100%'>
                <Loader />
              </Grid>
            }
            falseContent={
              <Condition
                condition={isOldCarFlow}
                trueContent={
                  <AddOldVehicleForm
                    ctx={ctx}
                    handleCancelClick={handleCancelClick}
                  />
                }
                falseContent={
                  <EditNewVehicleForm
                    prefilledValues={prefilledValues}
                    ctx={ctx}
                    currentVehicleOptions={currentVehicleOptions}
                    handleCancelClick={handleCancelClick}
                    isVinFlow={isVinFlow}
                  />
                }
              />
            }
          />
        </Grid>
      </Grid>
      <DiscardConfirmationModal
        isOpen={isDiscardModalOpen}
        setOpen={setDiscardModalOpen}
        onSubmit={handleDiscard}
      />
      <DiscardConfirmationModal
        isOpen={isNavigateConfirmationOpen}
        setOpen={setIsNavigateConfirmationOpen}
        onSubmit={handleNavigationDiscard}
      />
    </>
  )
}
