import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { BREAKPOINTS } from './types/breakpoints';
import { COLORS } from './types/colors';

const themeSingleton = responsiveFontSizes(createTheme({
  palette: {
    error: {
      main: COLORS.text_red
    }
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        asterisk: {
          color: COLORS.text_red
        }
      }
    },
    MuiSvgIcon: {
      variants: [
        {
          props: { color: 'action' },
          style: {
            color: COLORS.main_black
          }
        }
      ]
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: COLORS.main_yellow
        }
      }
    },
    MuiCardMedia: {
      styleOverrides: {
        img: {
          '&:-moz-loading': {
            visibility: 'hidden'
          }
        }
      }
    }
  },
  spacing: 8,
  typography: {
    allVariants: {
      color: COLORS.text_primary
    },
    badge: {
      fontSize: '10px',
      fontWeight: 500,
      letterSpacing: '1.5px',
      textTransform: 'uppercase'
    },
    body1: {
      fontSize: 14,
      color: COLORS.text_primary,
      fontWeight: 400
    },
    details: {
      fontSize: 16,
      fontWeight: 400,
      color: COLORS.text_secondary
    },
    emphasized: {
      fontWeight: 700,
      fontSize: 16,
      color: COLORS.text_primary,
      lineHeight: '27px'
    },
    error: {
      color: COLORS.error_message_red
    },
    fontSize: 14,
    label1: {
      color: COLORS.text_primary,
      fontSize: 16,
      fontWeight: 600
    },
    main: {
      fontSize: 16,
      fontWeight: 400
    },
    overline: {
      fontSize: 16,
      color: COLORS.text_white,
      fontWeight: 500,
      textDecoration: 'none',
      '&.active': {
        color: COLORS.main_yellow
      }
    },
    pagination: {
      fontSize: 12
    },
    subtitle1: {
      fontSize: 14
    },
    subtitle1_grey: {
      fontSize: 14,
      color: COLORS.text_gray
    },
    subtitle2: {
      fontSize: 14,
      color: COLORS.text_secondary,
      fontWeight: 400
    },
    title1: {
      color: COLORS.text_primary,
      fontSize: 28,
      fontWeight: 700
    }
  },
  breakpoints: {
    values: {
      xs: BREAKPOINTS.XS,
      sm: BREAKPOINTS.SM,
      md: BREAKPOINTS.MD,
      lg: BREAKPOINTS.LG,
      xl: BREAKPOINTS.XL
    }
  }
}))

themeSingleton.typography.h5 = {
  ...themeSingleton.typography.h5,
  fontWeight: 500,
}

themeSingleton.typography.title1 = {
  ...themeSingleton.typography.title1,
  [themeSingleton.breakpoints.down('md')]: {
    fontSize: 20
  }
}

export const theme = themeSingleton;
