import { FC } from 'react'
import { SmallPlayIcon } from '../../../../../../assets/icons/SmallPlayIcon'
import { ChatFileMessageWrapperProps } from '../../types'
import { ChatMediaMessageContainer } from '../ChatMediaMessageContainer'
import { AudioPlayer } from '../../../../../../atoms/AudioPlayer'
import { noop } from '../../../../../../utils'

export const SupportedAudioMessage: FC<ChatFileMessageWrapperProps> = (
  props
) => {
  const { contentPreviewURL } = props
  const icon = <SmallPlayIcon />

  return (
    <AudioPlayer link={contentPreviewURL}>
      <ChatMediaMessageContainer {...props} onClick={noop} icon={icon} />
    </AudioPlayer>
  )
}
