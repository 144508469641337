import { FC } from 'react'
import { ContentContainer } from '../../../../atoms/FindCarResult/FindCarResult.styles'
import { Pagination } from '../../../../atoms/Pagination'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const PaginationExample: FC = () => {
  return (
    <ContentContainer>
      <ComponentWithPaperBackground>
        <Pagination count={4} onChange={() => console.log('change')} />
      </ComponentWithPaperBackground>
    </ContentContainer>
  )
}
