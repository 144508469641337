import { ListItemIcon, MenuItem, SxProps, Theme, Typography } from '@mui/material';
import { FC } from 'react';
import { Show } from '../../../../../../atoms/JSXExtensions';
import { BORDERS, COLORS } from '../../../../../../types/colors';

interface AdditionalMenuItemProps {
  menuItemSx?: SxProps<Theme>
  label: string
  onClick: () => void
  icon?: JSX.Element
  withDivider?: boolean
  color?: string
}

export const AdditionalMenuItem: FC<AdditionalMenuItemProps> = ({
  onClick,
  menuItemSx = { display: { xs: 'flex' } },
  label,
  icon,
  withDivider = false,
  color = COLORS.text_primary
}) => {
  return (
    <MenuItem
      onClick={onClick}
      sx={{
        flexDirection: 'row-reverse',
        px: icon ? 1 : 2,
        borderBottom: withDivider ? BORDERS.SM_GRAY : '',
        height: '44px',
        color,
        ...menuItemSx,
      }}
    >
      <Show when={icon}>
        <ListItemIcon sx={{ ml: 1 }}>{icon}</ListItemIcon>
      </Show>
      <Typography variant='subtitle1' fontWeight={500} color={color}>
        {label}
      </Typography>
    </MenuItem>
  )
}
