import { Grid } from '@mui/material'
import { FC } from 'react'
import { TextMessage } from '../../../../../features/Chat/view/ChatTextMessage'
import { ComponentContainer } from '../../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../../ComponentsPreviewPage/components/ComponentWithPaperBackground'
import { TEXT_MESSAGE_TYPE } from '../../../../../constants/applicationConstants'
import { TextMessageType } from '../../../../../api/chat/types'

const mockLongMessage =
  "Lorem Ipsum is simply dummy text of the printing http://localhost:3000/components and typesetting industry. Lorem Ipsum has been the industry's www.google.com standard dummy https://jira.andersenlab.com/secure/RapidBoard.jspa?rapidView=2350&projectKey=CRSN&selectedIssue=CRSN-738&quickFilter=9701 text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
const mockShortText = 'Short text abcd@ukr.net'

const longMessage: TextMessageType = {
  id: '1',
  isSender: false,
  senderName: 'Buyer',
  message: mockLongMessage,
  timestamp: new Date().toISOString(),
  hasRead: true,
  transactionId: '1',
  type: TEXT_MESSAGE_TYPE
}

const shortText: TextMessageType = {
  id: '2',
  isSender: false,
  senderName: 'Buyer',
  message: mockShortText,
  timestamp: new Date().toISOString(),
  hasRead: true,
  transactionId: '1',
  type: TEXT_MESSAGE_TYPE
}

export const TextMessageExample: FC = () => {
  return (
    <ComponentContainer fullWidth>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ComponentWithPaperBackground title='Sender long text message'>
            <TextMessage {...longMessage} />
          </ComponentWithPaperBackground>
        </Grid>
        <Grid item xs={12}>
          <ComponentWithPaperBackground title='Sender short text message'>
            <TextMessage {...shortText} />
          </ComponentWithPaperBackground>
        </Grid>
        <Grid item xs={12}>
          <ComponentWithPaperBackground title='User short text message'>
            <TextMessage {...shortText} isSender senderName='Seller' />
          </ComponentWithPaperBackground>
        </Grid>
        <Grid item xs={6}>
          <ComponentWithPaperBackground title='Sender message for minimized view'>
            <TextMessage {...shortText} isMinimized />
          </ComponentWithPaperBackground>
        </Grid>
        <Grid item xs={6}>
          <ComponentWithPaperBackground title='User long message for minimized view'>
            <TextMessage
              {...longMessage}
              isMinimized
              isSender
              senderName='Seller'
            />
          </ComponentWithPaperBackground>
        </Grid>
        <Grid item xs={6}>
          <ComponentWithPaperBackground title='Sender long message for minimized view'>
            <TextMessage {...longMessage} isMinimized />
          </ComponentWithPaperBackground>
        </Grid>
        <Grid item xs={12}>
          <ComponentWithPaperBackground title='Full wide chat example'>
            <TextMessage {...shortText} />
            <TextMessage {...shortText} isSender senderName='Seller' />
            <TextMessage {...longMessage} />
            <TextMessage {...longMessage} isSender senderName='Seller' />
          </ComponentWithPaperBackground>
        </Grid>
        <Grid item xs={6}>
          <ComponentWithPaperBackground title='Minimized chat example'>
            <TextMessage {...shortText} isMinimized />
            <TextMessage
              {...shortText}
              isSender
              isMinimized
              senderName='Seller'
            />
            <TextMessage {...longMessage} isMinimized />
            <TextMessage
              {...longMessage}
              isSender
              isMinimized
              senderName='Seller'
            />
          </ComponentWithPaperBackground>
        </Grid>
      </Grid>
    </ComponentContainer>
  )
}
