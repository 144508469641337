import {
    FormControl,
    InputLabel,
    MenuItem,
    Select as MuiSelect,
    SelectChangeEvent
} from '@mui/material'
import { FieldValidator, useField } from 'formik'
import { useCallback, useMemo } from 'react'
import { ReferenceModel } from '../../types/ReferenceModel'
import { COLORS } from '../../types/colors'
import { getIsFieldRequired } from '../../utils/validators/getIsFieldRequired'
import { Show } from '../JSXExtensions'

export interface SelectFieldProps<T> {
  name: string
  label?: string
  options: Array<ReferenceModel & T>
  disabled?: boolean
  validate?: FieldValidator
  renderOption?: (option: SelectFieldProps<T>['options'][number]) => JSX.Element
  required?: boolean | ((name: string) => boolean)
}

const getSelectStyles = <T,>(label: SelectFieldProps<T>['label']) => {
  const nonLabelStyles = {
    minWidth: '270px',
    '& .MuiSelect-select': {
      py: '16px',
      fontSize: '14px'
    }
  }

  return {
    '&.MuiFilledInput-root': {
      backgroundColor: COLORS.white,
      border: `1px solid ${COLORS.border_gray}`,
      borderRadius: '4px',
      paddingLeft: label ? '8px' : 0,
      '&:hover': {
        border: `1px solid ${COLORS.text_blue}`
      }
    },
    '&.MuiFilledInput-root.Mui-disabled': {
      backgroundColor: COLORS.secondary_background,
      border: 'none'
    },
    '&.MuiFilledInput-root.Mui-disabled:hover': {
      border: 'none'
    },
    '&.MuiFilledInput-root.MuiFilledInput-underline:before': {
      borderBottom: 'none'
    },
    '&.MuiFilledInput-root.MuiFilledInput-underline:after': {
      borderBottom: `1px solid ${COLORS.text_blue}`
    },
    '&.MuiFilledInput-root:hover': {
      border: `1px solid ${COLORS.border_gray}`
    },
    '&.MuiFilledInput-root.Mui-focused': {
      border: `1px solid ${COLORS.text_blue}`
    },
    ...(!label && nonLabelStyles)
  }
}

const RenderSelectFieldOption = <T,>(
  option: SelectFieldProps<T>['options'][number]
) => option.name

export const SelectField = <T,>(props: SelectFieldProps<T>) => {
  const {
    name,
    label,
    options,
    disabled,
    validate,
    renderOption = RenderSelectFieldOption,
    required = false
  } = props
  const [field, meta, helpers] = useField<string>({
    name,
    validate,
    type: 'select'
  })

  const { value } = field

  const handleOpen = useCallback(() => {
    if (meta.touched && meta.error) {
      helpers.setError('')
    }
  }, [helpers])

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      helpers.setValue(event.target.value)
      helpers.setTouched(true)
    },
    [helpers]
  )

  const labelID = `${name}-label`

  const selectStyles = useMemo(() => getSelectStyles(label), [label])
  const isRequiredField = getIsFieldRequired(required, name)

  return (
    <FormControl fullWidth variant='filled'>
      <Show when={label}>
        <InputLabel
          id={labelID}
          sx={{
            '&.MuiInputLabel-root': {
              top: '3px',
              paddingLeft: '14px',
              color: COLORS.text_secondary,
              fontSize: '16px',
              '&:after': {
                content: required ? "' *'" : "''",
                color: COLORS.text_red
              }
            },
            '&.MuiInputLabel-root.Mui-disabled': {
              color: COLORS.disabled
            }
          }}
          disabled={disabled}
        >
          {label}
        </InputLabel>
      </Show>
      <MuiSelect
        variant='filled'
        id={name}
        name={name}
        labelId={labelID}
        value={value}
        disabled={disabled}
        label={label}
        onChange={handleChange}
        onOpen={handleOpen}
        onClose={() => {
          // TODO: find a way to remove this hack
          setTimeout(() => (document.activeElement as HTMLElement).blur(), 0)
        }}
        required={isRequiredField}
        MenuProps={{
          sx: {
            '& .MuiMenuItem-gutters': {
              height: '45px',
              '&.Mui-selected ': {
                backgroundColor: COLORS.hover_background_light_yellow
              },
              '&.Mui-focusVisible': {
                backgroundColor: COLORS.secondary_background
              }
            },
            maxHeight: 270
          }
        }}
        inputProps={{
          onBlur: field.onBlur
        }}
        sx={selectStyles}
      >
        {options.map((option) => {
          return (
            <MenuItem key={option.id} value={option.id}>
              {renderOption(option)}
            </MenuItem>
          )
        })}
      </MuiSelect>
    </FormControl>
  )
}
