import { Grid, GridProps } from '@mui/material'
import { FC, PropsWithChildren, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Condition } from '../../atoms/Condition'
import { Loader } from '../../atoms/Loader'
import { routes } from '../../routes'
import { UserInfoStatusMap } from '../../store/features/userInfo'
import { selectSubscriptionAutoupdate } from '../../store/units/subscriptionAutoupdate'
import {
  useAppSelector,
  useAuthenticateUser,
  useSessionVehicle,
  useUserInfo,
  useUserSession
} from '../../utils/hooks'

interface PageContainerProps extends GridProps {
  isLoading?: boolean
}

export const PageContainer: FC<PropsWithChildren<PageContainerProps>> = ({
  children,
  isLoading: outerIsLoading,
  ...props
}) => {
  const navigate = useNavigate()
  const userInfo = useUserInfo()
  const { loginBySession } = useAuthenticateUser()
  const { sessionCredentials } = useUserSession()
  const { isAutoupdating } = useAppSelector(selectSubscriptionAutoupdate)
  // Store the vid parameter in the session storage if it is present in the URL
  useSessionVehicle()

  const isLoading = isAutoupdating || outerIsLoading

  useEffect(() => {
    if (sessionCredentials && userInfo.status === UserInfoStatusMap.Unknown) {
      loginBySession(sessionCredentials)
      return
    }

    if (userInfo.status !== UserInfoStatusMap.Authenticated) {
      navigate(routes.login)
    }
  }, [userInfo.status, sessionCredentials])

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  return (
    <Condition
      condition={
        userInfo.status === UserInfoStatusMap.Authenticated && !isLoading
      }
      trueContent={
        <Grid justifyContent='center' padding={3} {...props}>
          {children}
        </Grid>
      }
      falseContent={
        <Grid
          justifyContent='center'
          padding={3}
          sx={{
            '.loader': {
              display: 'flex'
            }
          }}
          {...props}
        >
          <Loader />
        </Grid>
      }
    />
  )
}
