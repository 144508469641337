import { FC } from 'react'
import { ScreenContainer } from '../../../../layout/ScreenContainer'
import { ActionBlock } from '../../../../atoms/ActionBlock'
import { useAppDispatch } from '../../../../utils/hooks'
import SuccessIcon from '../../../../assets/icons/SuccessIcon'
import { SuccessVerificationCodeText } from './constants'
import { VerificationCodeScreenActions } from '../constants'
import {
  IconContainer,
  SuccessVerificationContainer
} from './SuccessVerification.styles'

type SuccessVerificationProps = {
  actions: VerificationCodeScreenActions
}

export const SuccessVerification: FC<SuccessVerificationProps> = ({
  actions
}) => {
  const dispatch = useAppDispatch()

  const nextHandler = () => {
    dispatch(actions.incrementScreenStep())
    dispatch(actions.incrementOnboardStep())
  }

  return (
    <ScreenContainer>
      <SuccessVerificationContainer>
        <IconContainer>
          <SuccessIcon />
        </IconContainer>
        <h3>{SuccessVerificationCodeText.title}</h3>
        <p>{SuccessVerificationCodeText.subtitle}</p>
        <ActionBlock
          schema={[
            {
              label: 'Next',
              type: 'primary',
              onClick: nextHandler
            }
          ]}
        />
      </SuccessVerificationContainer>
    </ScreenContainer>
  )
}
