import * as Yup from 'yup'
import { Paper } from '@mui/material'
import { Form, Formik } from 'formik'

import {
  AddOnsArray,
  IAddOnFormValues,
  validationPattern
} from '../../../../molecules/AddOn'
import { AgreeButton } from '../../../../atoms/Button'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const AddOnsExample = () => {
  const handleSubmit = (data: unknown) => {
    console.log('data', data)
  }

  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <div
          style={{
            width: '100%',
            height: 'auto',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Paper
            elevation={3}
            sx={{ width: '100%', maxWidth: '790px', padding: '8px' }}
          >
            <Formik<{ addOns: IAddOnFormValues[] }>
              initialValues={{ addOns: [] }}
              onSubmit={handleSubmit}
              validationSchema={Yup.object().shape({
                addOns: validationPattern
              })}
            >
              {({ values }) => (
                <Form>
                  <AddOnsArray name='addOns' values={values.addOns} />
                  <AgreeButton type='submit'>Save</AgreeButton>
                </Form>
              )}
            </Formik>
          </Paper>
        </div>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
