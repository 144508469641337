import { formatDecimalNumber } from './formatDecimalNumber'

export interface FormatNumberParams {
  start?: string
  includeDecimalPart?: boolean
  splitter?: string
  hideTrailingDecimalZeroes?: boolean
}

export const formatNumber = (
  value: number,
  params?: FormatNumberParams
): string => {
  const {
    start = '',
    includeDecimalPart = false,
    splitter = ',',
    hideTrailingDecimalZeroes = false
  } = params || ({} as FormatNumberParams)

  const stringValue = includeDecimalPart
    ? formatDecimalNumber(value)
    : `${Math.abs(Math.round(value))}`
  const startWithSign = value < 0 ? `-${start}` : start

  const valueIncludeDecimalPart = stringValue.includes('.')
  const [mainPart, decimalPart] = stringValue.split('.')
  let numberCounter = 0

  const formattedValue = mainPart
    .split('')
    .reduceRight(
      (acc, char) => {
        const isNumber = char.match(/[\d]/g) !== null

        if (numberCounter === 3 && isNumber) {
          numberCounter = 1
          return [...acc, splitter, char]
        }

        if (isNumber) {
          numberCounter += 1
        }
        return [...acc, char]
      },
      ['']
    )
    .reverse()
    .join('')
    .concat(decimalPart ? `.${decimalPart}` : '')

  if (
    includeDecimalPart &&
    !valueIncludeDecimalPart &&
    !hideTrailingDecimalZeroes
  ) {
    return `${startWithSign}${formattedValue}.00`
  }

  return `${startWithSign}${formattedValue}`
}

export const parseNumber = (cost: string) => {
  let parsedNumber = +cost.replace(/[^+.\d]/g, '');
  if (cost.startsWith('-')) {
    parsedNumber *= -1;
  }
  return parsedNumber;
}
