import { Grid, Typography } from '@mui/material'
import { FC, useCallback } from 'react'
import { SuccessIconCircle } from '../../../assets/icons/SuccessIconCircle'
import { LinkButton } from '../../../atoms/LinkButton'
import { FormContainer } from '../../../layout/FormContainer'
import { ScreenContainer } from '../../../layout/ScreenContainer'
import { ConfirmationLinkConstants } from './constants'

type ConfirmationLinkContainerProps = {
  isLoading: boolean
  email: string
  resendLinkService: (email: string) => Promise<unknown>
}

export const ConfirmationLinkContainer: FC<ConfirmationLinkContainerProps> = ({
  isLoading,
  email,
  resendLinkService
}) => {
  const resendMessage = useCallback(() => {
    resendLinkService(email)
  }, [email, resendLinkService])

  return (
    <FormContainer title={ConfirmationLinkConstants.title} minHeight='unset' mt={5}>
      <ScreenContainer isLoading={isLoading} wrapperProps={{ paddingX: 2 }}>
        <Grid item textAlign='center'>
          <SuccessIconCircle />
        </Grid>
        <Grid item mt={3}>
          <Typography variant='body1' textAlign='center'>
            {ConfirmationLinkConstants.firstParagraph}
          </Typography>
        </Grid>
        <Grid item mt={5}>
          <Typography variant='body1' textAlign='center'>
            {ConfirmationLinkConstants.secondParagraph}
          </Typography>
        </Grid>
        <Grid item mt={3}>
          <Typography variant='subtitle2' textAlign='center'>
            {ConfirmationLinkConstants.bottomText}{' '}
            <LinkButton
              label={ConfirmationLinkConstants.textLink}
              onClick={resendMessage}
            />
          </Typography>
        </Grid>
      </ScreenContainer>
    </FormContainer>
  )
}
