import { Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { NonNullableRecord } from '../../../types/NonNullableRecord'
import { COLORS } from '../../../types/colors'
import { formatPrice } from '../../../utils'
import { OfferDetailsWidgetProps } from '../types'
import { ItemRow } from './ItemRow'

type OfferTradeInProps = NonNullableRecord<
  Required<Pick<OfferDetailsWidgetProps, 'tradeinValue'>>
>

const TradeInValue: FC<OfferTradeInProps> = ({ tradeinValue }) => {
  return (
    <Typography variant='main' color={COLORS.text_red}>
      -{formatPrice(tradeinValue)}
    </Typography>
  )
}

export const OfferTradeIn = ({ tradeinValue }: OfferTradeInProps) => {
  return (
    <Grid container flexDirection='column' rowSpacing={2}>
      <Grid item>
        <Typography variant='main' fontWeight='600'>
          Trade In
        </Typography>
      </Grid>
      <Grid item>
        <ItemRow
          label='Trade In'
          value={<TradeInValue tradeinValue={tradeinValue} />}
        />
      </Grid>
    </Grid>
  )
}
