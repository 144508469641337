import { useMemo } from 'react'

type SwitchComponentProps<T extends string> = {
  case: T
  children: Partial<Record<T, JSX.Element>>
  default?: JSX.Element
}

export const SwitchComponent = <T extends string>({
  case: caseProp,
  default: defaultProp,
  children
}: SwitchComponentProps<T>): JSX.Element | null => {
  return useMemo(() => {
    const child = children[caseProp]

    if (child) {
      return child as JSX.Element
    }

    if (defaultProp) {
      return defaultProp
    }

    return null
  }, [caseProp, children, defaultProp])
}
