// TODO: Styles - we should start assembling these colors as Mui theme type

export enum BGCOLORS {
  gray = '#EBEBEB',
  snackbarError = '#FBF0F0',
  snackbarSuccess = '#E2FDEF',
  white = '#FAFAFA'
}

export enum COLORS {
  accent_blue = '#2C7CE0',
  accent_shade_2 = '#B2D8E7',
  active_image_page = '#368FE1',
  active_yellow = '#F1AE1D',
  border_gray = '#EBEBEB',
  disabled = '#D1D1D1',
  error_background = '#F9EFEB',
  error_message_red = '#f44336',
  hover_background_gray = '#646464',
  hover_background_blue = '#F2F7FD',
  hover_background_light_yellow = '#FEF8E2',
  hover_header = '#FFFFFF1A',
  hover_yellow = '#FBD865',
  main_black = '#333333',
  main_yellow = '#FACC34',
  secondary_background = '#FAFAFA',
  text_blue = '#3C91DE',
  text_disabled_black = '#646464',
  text_gray = '#BCBCBC',
  text_light_gray = '#F7F7FB',
  text_primary = '#333333',
  text_red = '#FC5725',
  text_secondary = '#848484',
  text_white = '#FFFFFF',
  utility_positive = '#0EAD69',
  white = '#FFFFFF'
}

// Only numeric enums can have computed members, but this expression has type 'string'.
// If you do not need exhaustiveness checks, consider using an object literal instead.ts(18033)
export const BORDERS = {
  CONTAINER_ACCENT: `1px solid ${COLORS.accent_blue}`,
  EMPTY_IMAGE_MAIN: `1px dashed ${COLORS.text_secondary}`,
  EMPTY_IMAGE: `1px dashed ${COLORS.text_blue}`,
  HOVERED_IMAGE: `1px solid ${COLORS.text_blue}`,
  HOVERED_PAYMENT: `1px solid ${COLORS.text_primary}`,
  M_GRAY: `2px solid ${COLORS.border_gray}`,
  SELECTED_IMAGE: `2px solid ${COLORS.main_yellow}`,
  SELECTED_PAYMENT: `1px solid ${COLORS.accent_blue}`,
  SM_ACTIVE_YELLOW: `1px solid ${COLORS.active_yellow}`,
  SM_BLACK: `1px solid ${COLORS.main_black}`,
  SM_ERROR: `1px solid ${COLORS.text_red}`,
  SM_GRAY: `1px solid ${COLORS.border_gray}`,
  SM_SECONDARY_BG: `1px solid ${COLORS.secondary_background}`,
  SM_YELLOW: `1px solid ${COLORS.main_yellow}`,
  TRANSPARENT_IMAGE: `2px solid transparent`,
  UNACTIVE_PAYMENT: `1px solid ${COLORS.text_gray}`,
  XSM_GRAY: `0.5px solid ${COLORS.border_gray}`
}

export const BADGE_COLORS = {
  beige: {
    background: '#FCFFE6',
    color: '#F1AE1D'
  },
  blue: {
    background: '#E6EAFF',
    color: '#2C7CE0'
  },
  gray: {
    background: '#E8E8E8',
    color: '#333333'
  },
  green: {
    background: '#E6FFE9',
    color: '#009B59'
  },
  lightGreen: {
    background: '#ECF9D3',
    color: '#444F31'
  },
  mint: {
    background: '#D9F8F7',
    color: '#336764'
  },
  orange: {
    background: '#FFF2E6',
    color: '#FA9369'
  },
  red: {
    background: '#F4EAEA',
    color: '#FC5725'
  },
  rose: {
    background: '#F6DEDE',
    color: COLORS.main_black
  },
  sand: {
    background: '#FFEFDB',
    color: '#9A8271'
  },
  violet: {
    background: '#F3E6FF',
    color: '#6314AB'
  }
}

export enum SHADOWS {
  avatar = 'inset 0px 0px 10px rgba(0, 0, 0, 0.1)',
  avatarGroup = '1.125px 2.25px 2.25px rgba(217, 221, 228, 0.4)',
  hover = '1px 2px 2px rgba(217, 221, 228, 0.4)',
  modal = '0px 1px 3px rgba(51, 51, 51, 0.11)',
  primary = '2px 2px 50px rgba(20, 38, 54, 0.07)',
  requestHover = '1px 1px 50px rgba(20, 23, 54, 0.04)'
}
