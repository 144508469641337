const separator = '@'
export const replace = '...'

export const formatEmail = (email: string, maxCount: number = 42): string => {
  if (email.length <= maxCount) {
    return email
  }

  const [nickname, domain] = email.split(separator)
  const tailLength = domain.length + replace.length + separator.length

  const extraChars = email.length - maxCount
  const endIndex =
    extraChars < replace.length
      ? nickname.length - replace.length - extraChars
      : maxCount - tailLength

  return `${nickname.substring(0, endIndex)}...@${domain}`
}
