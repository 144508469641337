import {
  FC,
  MouseEventHandler,
  useCallback,
  Dispatch,
  SetStateAction
} from 'react'

import { Grid } from '@mui/material'
import { Card } from '../../atoms/Card'
import { Member } from '../AccountMember'
import { MyTeamListHeader, MyTeamListBody } from './views/MyTeamListView'
import { MyTeamAddUsersView } from './views/MyTeamAddUsersView'
import { MY_TEAM_MODE } from './types'
import { Condition } from '../../atoms/Condition'
import { Loader } from '../../atoms/Loader'
import { MyTeamAddUsersFormValues } from './views/MyTeamAddUsersView/types'
import { useAddUsersService } from './hooks'

export type MyTeamCardProps = {
  members: Member[]
  setMembers: Dispatch<SetStateAction<Member[]>>
  mode: MY_TEAM_MODE
  setMode: (mode: MY_TEAM_MODE) => void
  onCloseClick: () => void
  onSaveClick: () => Promise<unknown> | void
  onAddNewUserClick: () => void
  isAdmin?: boolean
  isLoading?: boolean
  isOpenDirectly?: boolean
}

// TODO: ui-components Add to Components page (optional)
export const MyTeamCard: FC<MyTeamCardProps> = (props) => {
  const {
    isLoading,
    isAdmin,
    mode,
    isOpenDirectly,
    setMode,
    onCloseClick,
    onAddNewUserClick
  } = props

  const { addUsersObservable, addUsersService } = useAddUsersService()

  const handleCloseClick = useCallback<MouseEventHandler>(
    (e) => {
      e.stopPropagation()
      onCloseClick()
    },
    [onCloseClick]
  )

  const handleAddingCancelClick = useCallback<MouseEventHandler>(
    (e) => {
      e.stopPropagation()
      if (isOpenDirectly) {
        onCloseClick()
      } else {
        setMode(MY_TEAM_MODE.LIST)
      }
    },
    [setMode, onCloseClick]
  )

  const handleAddingSendClick = useCallback(
    (values: MyTeamAddUsersFormValues) => {
      const { emails } = values
      addUsersService(emails)
    },
    [addUsersService]
  )

  return (
    <Grid item container sx={{ maxWidth: '548px' }}>
      <Condition
        condition={mode === MY_TEAM_MODE.LIST}
        trueContent={
          <Card
            onCloseClick={handleCloseClick}
            header={
              <MyTeamListHeader
                isAdmin={isAdmin}
                onAddNewUserClick={onAddNewUserClick}
              />
            }
          >
            <Condition
              condition={!!isLoading}
              trueContent={
                <Grid container>
                  <Loader />
                </Grid>
              }
              falseContent={
                <MyTeamListBody {...props} onCloseClick={handleCloseClick} />
              }
            />
          </Card>
        }
        falseContent={
          <MyTeamAddUsersView
            isLoading={addUsersObservable.isLoading}
            isSuccess={addUsersObservable.isSuccess}
            onCloseClick={handleCloseClick}
            onCancelClick={handleAddingCancelClick}
            onSendClick={handleAddingSendClick}
          />
        }
      />
    </Grid>
  )
}
