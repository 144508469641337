import { Button, Grid, Typography } from '@mui/material'
import { FC, useCallback, useEffect, useState } from 'react'
import { AudioIcon } from '../../../../assets/icons/AudioIcon'
import { UploadDocumentIcon } from '../../../../assets/icons/UploadDocumentIcon'
import { Show } from '../../../../atoms/JSXExtensions'
import { DocumentView } from '../../../../molecules/DocumentView'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const DocumentViewExample: FC = () => {
  const [progress, setProgress] = useState(0)
  const [isDocumentRemoved, setRemovedFlag] = useState(false)

  const handleFileRemove = useCallback(() => {
    setRemovedFlag(true)
  }, [setRemovedFlag])
  const handleFileRestore = useCallback(() => {
    setRemovedFlag(false)
  }, [])

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0
        }
        const diff = Math.random() * 10
        return Math.round(Math.min(oldProgress + diff, 100))
      })
    }, 100)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <Grid container width='760px' spacing={1}>
          <Grid item xs={12}>
            <DocumentView
              documentName='Document name'
              documentIcon={<UploadDocumentIcon />}
              fileDescription='File description'
            />
          </Grid>
          <Grid item xs={12}>
            <DocumentView
              documentName='Document name'
              documentIcon={<UploadDocumentIcon />}
              fileDescription='File description'
              error='Document with error'
            />
          </Grid>
          <Grid item xs={12}>
            <DocumentView
              documentName='Document name'
              documentIcon={<UploadDocumentIcon />}
              fileDescription='File description'
              error={
                <Grid container flexDirection='column'>
                  <Typography variant='h6'>Custom</Typography>
                  <Typography variant='body1'>Error</Typography>
                </Grid>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <DocumentView
              documentName='Document with progress'
              documentIcon={<UploadDocumentIcon />}
              fileDescription='Uploading in progress...'
              progress={progress}
              isInProgress
            />
          </Grid>
          <Show when={!isDocumentRemoved}>
            <Grid item xs={12}>
              <DocumentView
                documentName='Possible to delete file'
                documentIcon={<AudioIcon size='medium' />}
                fileDescription='PDF - 01.01.2023'
                onFileDelete={handleFileRemove}
              />
            </Grid>
          </Show>
          <Show when={isDocumentRemoved}>
            <Grid item xs={12}>
              <Button onClick={handleFileRestore}>Restore document</Button>
            </Grid>
          </Show>
        </Grid>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
