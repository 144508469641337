import { Grid } from '@mui/material'
import { Form, Formik, FormikConfig } from 'formik'
import { FC, useCallback, useMemo, useState } from 'react'
import { AgreeButton } from '../../../../../../atoms/Button'
import { TextInputFieldNew } from '../../../../../../atoms/TextInputFieldNew'
import { CombinedStorage } from '../../../../../../utils/hooks'
import { isRequiredFormField } from '../../../../../../utils/validators/isRequiredFormField'
import { validationSchema } from './validationSchema'

export interface VinFieldSetValues {
  vin: string
}

export interface VinFieldSetProps {
  onSubmit: FormikConfig<VinFieldSetValues>['onSubmit']
  storage: CombinedStorage<'VIN', VinFieldSetValues>
}

export const vinInitialValues: VinFieldSetValues = {
  vin: ''
}

export const getVinInitialValues = ({
  vin = ''
}: Partial<VinFieldSetValues>): VinFieldSetValues => ({
  vin
})

export const VinFieldSet: FC<VinFieldSetProps> = ({ onSubmit, storage }) => {
  const [isChanged, setIsChanged] = useState(false)

  const initialValues = useMemo(() => {
    return storage.values.VIN
  }, [storage])

  const syncFormValues = useCallback(
    (values: VinFieldSetValues) => {
      storage.updateValues('VIN', values)
    },
    [storage]
  )

  const handleIsChanged = useCallback(() => {
    setIsChanged(true)
  }, [setIsChanged])

  const handleSubmit = useCallback(
    (...args: Parameters<typeof onSubmit>) => {
      setIsChanged(false)
      onSubmit(...args)
    },
    [onSubmit]
  )

  const isRequiredField = isRequiredFormField(validationSchema)

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnMount
      enableReinitialize
    >
      {(formik) => {
        syncFormValues(formik.values)
        return (
          <Form>
            <Grid container spacing={1}>
              <Grid item xs={12} md={9}>
                <TextInputFieldNew
                  name='vin'
                  label='VIN'
                  onIsChanged={handleIsChanged}
                  required={isRequiredField}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <AgreeButton
                  type='submit'
                  disabled={!formik.isValid || !isChanged}
                  sx={{ height: '60px', width: '100%' }}
                >
                  Lookup
                </AgreeButton>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}
