import { FC, SyntheticEvent, useCallback, useEffect, useMemo } from 'react'
import { useRequestedVehicles } from './useRequestedVehicles'
import { TabContainer } from '../../../atoms/TabContainer'
import { RequestsTab } from './RequestsTab'
import { tabs, requestsDictType } from '../constants'
import { InProgressTab } from '../InProgressTab/InProgressTab'
import { useDealsList, useDealsPageSlice } from '../hooks'
import { CompletedDeals } from '../CompletedDeals'
import { SearchComponentProps } from '../../../features/SearchInventory'
import { useTransactionSearch } from '../hooks/useTransactionSearch'
import {
  actionsInProgressDealsPage,
  actionsCompletedDealsPage,
  selectInProgressDealsPageModel,
  selectCompletedDealsPageModel
} from '../../../store/pages/Deals'
import { useAppSelector, useUserInfo } from '../../../utils/hooks'
import {
  ReadTransactionsStatusEnum,
  ReadTransactionsTypeEnum
} from '../../../api/carSnoopApi/transactions'

export const SellingFlow: FC = () => {
  const {
    readInventory,
    requestedVehicles,
    requestedSearch,
    resetSearchValues
  } = useRequestedVehicles()
  const { activeSellingTab, setActiveSellingTab, resetActiveTabFilters } =
    useDealsPageSlice()
  const { readTransactions: readInProgressTransactions } = useDealsList(
    ReadTransactionsTypeEnum.Selling,
    ReadTransactionsStatusEnum['In Progress']
  )
  const { readTransactions: readCompletedTransactions } = useDealsList(
    ReadTransactionsTypeEnum.Selling,
    ReadTransactionsStatusEnum.Completed
  )
  const { isDealer } = useUserInfo()

  const inProgressSearch = useTransactionSearch({
    readTransactions: readInProgressTransactions,
    setSearchFormValues: actionsInProgressDealsPage.setSearchFormValues,
    searchFormValues: useAppSelector(selectInProgressDealsPageModel)
      .searchFormValues
  })

  const completedSearch = useTransactionSearch({
    readTransactions: readCompletedTransactions,
    setSearchFormValues: actionsCompletedDealsPage.setSearchFormValues,
    searchFormValues: useAppSelector(selectCompletedDealsPageModel)
      .searchFormValues
  })

  const requestsDict: requestsDictType = {
    0: readInventory,
    1: readInProgressTransactions,
    2: readCompletedTransactions
  }

  const searchValuesDict: Record<
    number,
    SearchComponentProps['initialValues']
  > = {
    0: requestedSearch.initialValues,
    1: inProgressSearch.initialValues,
    2: completedSearch.initialValues
  }

  const setActiveTabHandler = useCallback(
    (e: SyntheticEvent, newValue: number) => {
      resetSearchValues()
      resetActiveTabFilters()
      setActiveSellingTab(newValue)
    },
    [setActiveSellingTab, resetActiveTabFilters]
  )

  useEffect(() => {
    requestsDict[activeSellingTab](searchValuesDict[activeSellingTab])
  }, [activeSellingTab])

  const inProgressSearchProps = useMemo(() => {
    if (isDealer) {
      return inProgressSearch
    }
  }, [isDealer, inProgressSearch])

  const completedSearchProps = useMemo(() => {
    if (isDealer) {
      return completedSearch
    }
  }, [isDealer, completedSearch])

  const requestedSearchProps = useMemo(() => {
    if (isDealer) {
      return requestedSearch
    }
  }, [isDealer, requestedSearch])

  return (
    <TabContainer
      tabNames={tabs}
      tabContents={[
        <RequestsTab
          requestedVehicles={requestedVehicles}
          searchProps={requestedSearchProps}
        />,
        <InProgressTab searchProps={inProgressSearchProps} />,
        <CompletedDeals searchProps={completedSearchProps} />
      ]}
      currentTabID={activeSellingTab}
      onChange={setActiveTabHandler}
    />
  )
}
