import { ReadRequestsResponse } from '../types'

export const normalizeReadRequestsTradeInVehicleStandartEquipment = (
  rawPayload: ReadRequestsResponse.Raw.ReadRequestsResponse_Tradein_StandartEquipment
): ReadRequestsResponse.Normilized.ReadRequestsResponse_Tradein_StandartEquipment => {
  const { safety, exterior, interior, warranty, dimensions, mechanical } =
    rawPayload

  const drivetrainEngine = rawPayload['drivetrain_-_engine']
  const drivetrainTransmission = rawPayload['drivetrain_-_transmission']

  return {
    safety,
    exterior,
    interior,
    warranty,
    dimensions,
    mechanical,
    drivetrainEngine,
    drivetrainTransmission
  }
}

export const normalizeReadRequestsTradeInVehicle = (
  rawPayload: ReadRequestsResponse.Raw.ReadRequestsResponse_Tradein
): ReadRequestsResponse.Normilized.ReadRequestsResponse_Tradein => {
  const {
    id,
    year,
    make,
    model,
    trim,
    vin,
    stock_number: stockNumber,
    mileage,
    status,
    created_on: createdOn,
    created_by: createdBy,
    changed_on: changedOn,
    changed_by: changedBy,
    condition,
    account_id: accountId,
    address_id: addressId,
    retail_value: retailValue,
    tradein_value: tradeinValue,
    notes,
    standard_equipment: standardEquipment,
    fuel_type: fuelType,
    engine_description: engineDescription,
    transmission,
    mpg,
    style,
    interior_color: interiorColor,
    exterior_color: exteriorColor,
    drivetrain,
    license_plate: licensePlate,
    license_plate_state: licensePlateState,
    deleted,
    city,
    state,
    zip_code: zipCode,
    amount_owed: amountOwed,
    document_content: documentContent
  } = rawPayload

  return {
    id,
    year,
    make,
    model,
    trim,
    vin,
    stockNumber,
    mileage,
    status,
    createdOn,
    createdBy,
    changedOn,
    changedBy,
    condition,
    accountId,
    addressId,
    retailValue,
    tradeinValue,
    notes,
    standardEquipment: standardEquipment
      ? normalizeReadRequestsTradeInVehicleStandartEquipment(standardEquipment)
      : null,
    fuelType,
    engineDescription,
    transmission,
    mpg,
    style,
    interiorColor,
    exteriorColor,
    drivetrain,
    licensePlate,
    licensePlateState,
    deleted,
    city,
    state,
    zipCode,
    amountOwed,
    documentContent
  }
}

export const normalizeReadRequestsTransactionVehicle = (
  rawPayload: ReadRequestsResponse.Raw.ReadRequestsResponse_Transaction_Vehicle
): ReadRequestsResponse.Normilized.ReadRequestsResponse_Transaction_Vehicle => {
  const {
    id,
    year,
    make,
    model,
    trim,
    vin,
    stock_number: stockNumber,
    mileage,
    status,
    created_on: createdOn,
    created_by: createdBy,
    changed_on: changedOn,
    changed_by: changedBy,
    condition,
    account_id: accountId,
    address_id: addressId,
    retail_value: retailValue,
    tradein_value: tradeinValue,
    notes,
    standard_equipment: standardEquipment,
    fuel_type: fuelType,
    engine_description: engineDescription,
    transmission,
    mpg,
    style,
    interior_color: interiorColor,
    exterior_color: exteriorColor,
    drivetrain,
    license_plate: licensePlate,
    license_plate_state: licensePlateState,
    deleted,
    city,
    state,
    zip_code: zipCode,
    amount_owed: amountOwed,
    shipping_cost: shippingCost,
    distance,
    document_content: documentContent
  } = rawPayload

  return {
    id,
    year,
    make,
    model,
    trim,
    vin,
    stockNumber,
    mileage,
    status,
    createdOn,
    createdBy,
    changedOn,
    changedBy,
    condition,
    accountId,
    addressId,
    retailValue,
    tradeinValue,
    notes,
    standardEquipment,
    fuelType,
    engineDescription,
    transmission,
    mpg,
    style,
    interiorColor,
    exteriorColor,
    drivetrain,
    licensePlate,
    licensePlateState,
    deleted,
    city,
    state,
    zipCode,
    amountOwed,
    shippingCost,
    distance,
    documentContent
  }
}

export const normalizeReadRequestsTransaction = (
  rawPayload: ReadRequestsResponse.Raw.ReadRequestsResponse_Transaction
): ReadRequestsResponse.Normilized.ReadRequestsResponse_Transaction => {
  const {
    id,
    created_by: createdBy,
    created_on: createdOn,
    changed_by: changedBy,
    changed_on: changedOn,
    request_id: requestId,
    buyer_id: buyerId,
    seller_id: sellerId,
    tracking_number: trackingNumber,
    price,
    notes,
    status,
    vehicle_id: vehicleId,
    document_content: documentContent,
    vehicle
  } = rawPayload

  return {
    id,
    createdBy,
    createdOn,
    changedBy,
    changedOn,
    requestId,
    buyerId,
    sellerId,
    trackingNumber,
    price,
    notes,
    status,
    vehicleId,
    documentContent,
    vehicle: normalizeReadRequestsTransactionVehicle(vehicle)
  }
}

export const normalizeReadRequestsPayload = (
  rawPayload: ReadRequestsResponse.Raw.ReadRequestsResponse[]
): ReadRequestsResponse.Normilized.ReadRequestsResponse[] => {
  const normilizePayloadItem = (
    rawItem: ReadRequestsResponse.Raw.ReadRequestsResponse
  ): ReadRequestsResponse.Normilized.ReadRequestsResponse => {
    const {
      id,
      created_by: createdBy,
      changed_on: changedOn,
      changed_by: changedBy,
      created_on: createdOn,
      account_id: accountId,
      year,
      make,
      model,
      trim,
      exterior_color: exteriorColor,
      interior_color: interiorColor,
      notes,
      deleted,
      status,
      mileage,
      expires_on: expiresOn,
      trade_in_vehicle: tradeInVehicle,
      transactions
    } = rawItem

    return {
      tradeInVehicle: tradeInVehicle
        ? normalizeReadRequestsTradeInVehicle(tradeInVehicle)
        : null,
      transactions: transactions.map(normalizeReadRequestsTransaction),
      id,
      createdBy,
      changedOn,
      changedBy,
      createdOn,
      accountId,
      year,
      make,
      model,
      trim,
      exteriorColor: (exteriorColor || '').split(','),
      interiorColor: (interiorColor || '').split(','),
      notes,
      deleted,
      status,
      mileage,
      expiresOn: new Date(expiresOn)
    }
  }

  return rawPayload.map(normilizePayloadItem)
}
