import { Grid } from '@mui/material'
import { Loader } from '../../../../../atoms/Loader'
import { BORDERS } from '../../../../../types/colors'

export const AgreementGenerationLoader = () => (
  <Grid
    item
    container
    xs={12}
    mt={1}
    justifyContent='center'
    alignItems='center'
    sx={{
      p: 2,
      borderRadius: '10px',
      border: BORDERS.SM_GRAY,
      height: '60px',
      zIndex: -1
    }}
  >
    <Grid item mt={-10}>
      <Loader small />
    </Grid>
  </Grid>
)
