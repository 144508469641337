import { FC } from 'react'
import { ErrorMessage, Field, FieldProps } from 'formik'
import MaskedInput from 'react-text-mask'
import { Label } from '../Label'
import {
  ErrorMessageContainer,
  PhoneInputFieldContainer
} from './PhoneInputField.styles'

type PhoneInputFieldProps = {
  name: string
  mask: Array<string | RegExp>
  label: string
  placeholder: string
  isValid?: boolean
  disabled?: boolean
  autoComplete?: string
}

export const PhoneInputField: FC<PhoneInputFieldProps> = ({
  name,
  mask,
  label,
  placeholder,
  isValid,
  autoComplete,
  disabled = false
}) => {
  return (
    <PhoneInputFieldContainer isError={!isValid}>
      <Label name={name} label={label} />
      <Field type='text' id={name} name={name}>
        {(props: FieldProps) => {
          return (
            <MaskedInput
              {...props.field}
              disabled={disabled}
              autoComplete={autoComplete}
              onFocus={() => {
                props.form.setTouched({
                  ...props.form.touched,
                  [props.field.name]: false
                })
              }}
              type='text'
              mask={mask}
              placeholder={placeholder}
            />
          )
        }}
      </Field>
      <ErrorMessageContainer>
        <ErrorMessage name={name} />
      </ErrorMessageContainer>
    </PhoneInputFieldContainer>
  )
}
