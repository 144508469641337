import { FC, SyntheticEvent, useCallback, useEffect } from 'react'
import { TabContainer } from '../../../atoms/TabContainer'
import { RequestsTab } from './RequestsTab'
import { useRequestList } from './useRequestList'
import { tabs, requestsDictType } from '../constants'
import { InProgressTab } from '../InProgressTab/InProgressTab'
import { useDealsPageSlice } from '../hooks/useDealsPageSlice'
import { useDealsList } from '../hooks/useDealsList'
import { CompletedDeals } from '../CompletedDeals'
import { SearchComponentProps } from '../../../features/SearchInventory'
import { useAppSelector } from '../../../utils/hooks'
import { selectInProgressDealsPageModel } from '../../../store/pages/Deals'
import {
  ReadTransactionsStatusEnum,
  ReadTransactionsTypeEnum
} from '../../../api/carSnoopApi/transactions'

export const BuyingFlow: FC = () => {
  const { readRequests, requests, cancelRequest, isLoading } = useRequestList()
  const { activeBuyingTab, setActiveBuyingTab, resetActiveTabFilters } =
    useDealsPageSlice()
  const { readTransactions: readInProgressTransactions } = useDealsList(
    ReadTransactionsTypeEnum.Buying,
    ReadTransactionsStatusEnum['In Progress']
  )
  const { readTransactions: readCompletedTransactions } = useDealsList(
    ReadTransactionsTypeEnum.Buying,
    ReadTransactionsStatusEnum.Completed
  )

  const requestsDict: requestsDictType = {
    0: readRequests,
    1: readInProgressTransactions,
    2: readCompletedTransactions
  }

  const searchValuesDict: Record<
    number,
    SearchComponentProps['initialValues']
  > = {
    0: { search: '' },
    1: useAppSelector(selectInProgressDealsPageModel).searchFormValues,
    2: { search: '' }
  }

  const setActiveTabHandler = useCallback(
    (_: SyntheticEvent, newValue: number) => {
      resetActiveTabFilters()
      setActiveBuyingTab(newValue)
    },
    [setActiveBuyingTab, resetActiveTabFilters]
  )

  useEffect(() => {
    requestsDict[activeBuyingTab](searchValuesDict[activeBuyingTab])
  }, [activeBuyingTab])

  return (
    <TabContainer
      tabNames={tabs}
      tabContents={[
        <RequestsTab
          requests={requests}
          onCancelRequest={cancelRequest}
          isLoading={isLoading}
        />,
        <InProgressTab />,
        <CompletedDeals />
      ]}
      currentTabID={activeBuyingTab}
      onChange={setActiveTabHandler}
    />
  )
}
