import { Box } from '@mui/material'
import { Formik, FormikProps } from 'formik'
import { FC, useRef } from 'react'
import { ActionBlock } from '../../../atoms/ActionBlock'
import { DatePicker } from '../../../atoms/DatePicker'
import { RequiredLabel } from '../../../atoms/RequiredText'
import { TextInputField } from '../../../atoms/TextInputField'
import { MAX_DATE } from '../../../constants/applicationConstants'
import { DATE_PICKER_INFO_MESSAGE } from '../../../constants/messages'
import { ScreenContainer } from '../../../layout/ScreenContainer'
import { readLocationAsync } from '../../../store/thunks/readLocationAsync'
import { selectNetworkLocation } from '../../../store/units/registrationForm/selectors'
import { FormActions, PersonalDetails } from '../../../types/forms/registration'
import {
  useAppDispatch,
  useAppSelector
} from '../../../utils/hooks'
import { RegistrationForm } from './PersonalDetails.styles'
import { validationSchema } from './validationSchema'

type PersonalDetailsScreenProps = {
  actions: FormActions<
    | 'incrementScreenStep'
    | 'decrementScreenStep'
    | 'incrementOnboardStep'
    | 'decrementOnboardStep'
    | 'setPersonalDetails'
    | 'resetLocation'
  >
  initialValues: PersonalDetails
  staticValues?: Partial<PersonalDetails>
}

export const PersonalDetailsScreen: FC<PersonalDetailsScreenProps> = ({
  actions,
  initialValues,
  staticValues = {}
}) => {
  const dispatch = useAppDispatch()
  const formikRef = useRef<FormikProps<PersonalDetails>>()
  const userLocation = useAppSelector(selectNetworkLocation)

  const onNextBtnClicked = (data: PersonalDetails): void => {
    dispatch(actions.setPersonalDetails(data))
    dispatch(readLocationAsync(data.zipCode)).then((res) => {
      if (res.payload) {
        const { city, state } = res.payload as {
          city: string
          state: string
          status: 'failed' | 'succeeded'
        }
        if (city && state) {
          dispatch(actions.incrementScreenStep())
          dispatch(actions.incrementOnboardStep())
        }
      }
    })
  }

  const onBackBtnClicked = (): void => {
    dispatch(actions.decrementScreenStep())
    dispatch(actions.decrementOnboardStep())
  }

  const validateZipCode = (value: string): string | undefined => {
    if (userLocation.error && value === initialValues.zipCode) {
      return userLocation.error
    }
  }

  return (
    <ScreenContainer isLoading={userLocation.status === 'pending'}>
      <Box sx={{ mb: 2 }}>
        <RequiredLabel />
      </Box>
      <Formik
        initialValues={{ ...initialValues, ...staticValues }}
        initialErrors={{ zipCode: userLocation.error }}
        initialTouched={{ zipCode: true }}
        validationSchema={validationSchema}
        onSubmit={onNextBtnClicked}
        validateOnMount
      >
        {(formik) => {
          formikRef.current = formik

          return (
            <RegistrationForm>
              <TextInputField
                label='First Name'
                placeholder='First Name'
                name='firstName'
                disabled={'firstName' in staticValues}
                isValid={
                  !(formik.touched?.firstName && formik.errors.firstName)
                }
              />
              <TextInputField
                label='Last Name'
                placeholder='Last Name'
                name='lastName'
                disabled={'lastName' in staticValues}
                isValid={!(formik.touched?.lastName && formik.errors.lastName)}
              />
              <DatePicker
                label='Date of Birth'
                name='birthDate'
                disabled={'birthDate' in staticValues}
                value={formik.values.birthDate}
                maxDate={MAX_DATE}
                isError={!!formik.errors.birthDate}
                infoMessage={DATE_PICKER_INFO_MESSAGE}
                onChange={(value) => formik.setFieldValue('birthDate', value)}
              />
              <TextInputField
                label='Zip Code'
                placeholder='e.g. 12345'
                name='zipCode'
                disabled={'zipCode' in staticValues}
                isValid={!(formik.touched?.zipCode && formik.errors.zipCode)}
                validate={validateZipCode}
              />
              <ActionBlock
                schema={[
                  {
                    label: 'Back',
                    type: 'secondary',
                    onClick: onBackBtnClicked
                  },
                  {
                    label: 'Next',
                    type: 'primary',
                    disabled: !formik.isValid,
                    props: {
                      type: 'submit'
                    }
                  }
                ]}
              />
            </RegistrationForm>
          )
        }}
      </Formik>
    </ScreenContainer>
  )
}
