import { Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { COLORS } from '../../types/colors'

interface ITradeInMessageProps {
  title: string
}

export const TradeInMessage: FC<ITradeInMessageProps> = ({ title }) => {
  return (
    <Grid container>
      <Grid
        item
        width='100%'
        sx={{
          background: COLORS.secondary_background
        }}
        padding='10px 0'
      >
        <Typography variant='body1' fontStyle='italic'>
          {title}
        </Typography>
      </Grid>
    </Grid>
  )
}
