import { FC, PropsWithChildren } from 'react'
import { Grid, Typography } from '@mui/material'

export const RequestDetailsItem: FC<PropsWithChildren<{ title: string }>> = ({
  title,
  children
}) => {
  return (
    <Grid
      container
      item
      justifyContent='space-between'
      alignItems='center'
      xs={12}
    >
      <Grid item>
        <Typography variant='subtitle2' textTransform='uppercase'>
          {title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant='main'>{children}</Typography>
      </Grid>
    </Grid>
  )
}
