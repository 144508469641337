import * as Yup from 'yup'
import {
  countOfNumbers,
  latinWithSpecial,
  notOnlyZeroes
} from '../../../../../../../../utils/validators'
import { SELLER_FIELDS_ERRORS, SELLER_FIELDS_NAMES } from './types'

export const validationSchema = Yup.object({
  [SELLER_FIELDS_NAMES.NAME]: Yup.string()
    .required('')
    .trim()
    .test(
      'is-latin-or-special',
      SELLER_FIELDS_ERRORS.NAME,
      latinWithSpecial([' '])
    ),
  [SELLER_FIELDS_NAMES.ADDRESS]: Yup.string()
    .required('')
    .trim()
    .test(
      'is-latin-or-special',
      SELLER_FIELDS_ERRORS.ADDRESS,
      latinWithSpecial([' '])
    )
    .nullable(),
  [SELLER_FIELDS_NAMES.ZIP_CODE]: Yup.string()
    .required('')
    .trim()
    .test('length', SELLER_FIELDS_ERRORS.ZIP_CODE, countOfNumbers(5))
    .test('not-only-zeroes', SELLER_FIELDS_ERRORS.ZIP_CODE, notOnlyZeroes()),
  [SELLER_FIELDS_NAMES.CITY]: Yup.string().required(''),
  [SELLER_FIELDS_NAMES.STATE]: Yup.string().required(''),
  [SELLER_FIELDS_NAMES.NOTES]: Yup.string().test(
    'is-latin-or-special',
    'Enter a valid data format.',
    latinWithSpecial(['\n', ' ', '€', '£'])
  )
})
