import { addCountryCode } from '../../../../../utils/formatters/addCountryCode'
import { RegisterInvitedUserOptionsType } from './options'

export const registerInvitedUserDataToSend = (
  data: RegisterInvitedUserOptionsType
) => {
  return {
    account_id: data.accountId,
    email: data.email,
    first_name: data.firstName,
    last_name: data.lastName,
    phone: addCountryCode(data.phone),
    birth_date: data.birthDate,
    idp: 'CarSnoop',
    password: data.password,
    // TODO: UNIQUE ID - we have to find a way to get unique id;
    device_id: data.deviceId,
    verification_code: data.verificationCode
  }
}
