import { ReadCarByYearResponse } from '../../../../api/carSnoopApi/vehicles'
import type { Option } from '../../../../types/Autoselect'
import { arrayUnique } from '../../../../utils/arrayUnique'
import { stringToReferenceModel } from '../../../../utils/autoselect/stringToReferenceModel'

export const carsByYearResponseToTrimOption = (response: {
  data: ReadCarByYearResponse
}): Option[] => {
  const { data } = response

  const allTrims = data.reduce<string[]>(
    (acc, { trims }) => [...acc, ...trims],
    []
  )

  const uniqueTrims = arrayUnique(allTrims).filter(Boolean)

  const trimOptions = uniqueTrims.map((trim) => stringToReferenceModel(trim))

  return trimOptions
}
