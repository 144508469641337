import { CreateContractParams } from '../../api/carSnoopApi/contracts/types'
import { IOfferForm } from '../Offers/hooks/useOfferForm'

export const extractCreateContractParams = (
  forms: IOfferForm
): Omit<CreateContractParams, 'transactionId'> => {
  const { buyerDetailsForm, cobuyerMicroform, paymentMicroform, buyerEmail } =
    forms

  const additionalBuyer = cobuyerMicroform?.cobuyerEmail
    ? cobuyerMicroform.cobuyerEmail.name
    : null

  const additionalBuyerDetails = cobuyerMicroform
    ? {
        legalName: cobuyerMicroform.cobuyerName,
        legalStreet: cobuyerMicroform.cobuyerAddress,
        legalCity: cobuyerMicroform.city,
        legalState: cobuyerMicroform.state,
        legalZipCode: cobuyerMicroform.zipCode
      }
    : null

  const params: Omit<CreateContractParams, 'transactionId'> = {
    primaryBuyer: buyerEmail,
    buyerDetails: {
      legalName: buyerDetailsForm.buyerName,
      legalStreet: buyerDetailsForm.buyerAddress,
      legalCity: buyerDetailsForm.city,
      legalState: buyerDetailsForm.state,
      legalZipCode: buyerDetailsForm.zipCode
    },

    additionalBuyer,
    additionalBuyerDetails,
    paymentMethod: paymentMicroform.paymentMethod,
    // Must be empty. They will be editet later
    notes: ''
  }
  return params
}
