import { Grid, GridProps, Typography } from '@mui/material'
import { FC, PropsWithChildren } from 'react'

type FormContainerProps = {
  title?: string
  inlined?: boolean
}

const FlatFormContainer: FC<FormContainerProps & GridProps> = (props) => {
  const {
    children,
    title,
    ...otherProps
  } = props
  return (
    <Grid
      container
      width='100%'
      alignSelf='flex-start'
      flexDirection='column'
      flexGrow={{
        xs: 1,
        md: 0
      }}
      {...otherProps}
    >
      <Grid item marginY={3}>
        <Typography
          variant='h6'
          textAlign='center'
          data-test-anchors='form-title'
        >
          {title}
        </Typography>
      </Grid>
      <Grid
        container
        item
        flexGrow={1}
        alignSelf='flex-start'
        flexDirection='column'
        rowSpacing={3}
      >
        {children}
      </Grid>
    </Grid>
  )
}

export const FormContainer: FC<
  PropsWithChildren<FormContainerProps & GridProps>
> = ({
  children,
  inlined = false,
  title = '',
  ...props
}) => {
    if (inlined) {
      return (
        <FlatFormContainer
          {...props}
          title={title}
        >
          {children}
        </FlatFormContainer>
      )
    }

    return (
      <Grid
        container
        item
        alignSelf={{
          xs: 'center',
          lg: 'flex-start'
        }}
        marginTop={{
          xs: 0,
          md: 3,
          lg: 0
        }}
        flexDirection='column'
        flexGrow={{
          xs: 1,
          md: 0
        }}
        width={{
          xs: '100%',
          md: '470px'
        }}
        minHeight={{
          xs: 'calc(100vh - 64px)',
          md: '624px'
        }}
        boxShadow={{
          xs: 'none',
          md: '1px 1px 50px rgba(20, 23, 54, 0.08)'
        }}
        borderRadius='14px'
        {...props}
      >
        <Grid
          item
          marginY={{
            xs: 3,
            md: 4
          }}
        >
          <Typography
            variant='h5'
            textAlign='center'
            data-test-anchors='form-title'
          >
            {title}
          </Typography>
        </Grid>
        <Grid
          container
          item
          flexGrow={1}
          alignSelf='flex-start'
          flexDirection='column'
          rowSpacing={3}
        >
          {children}
        </Grid>
      </Grid>
    )
  }
