import { Breadcrumbs as MUIBreadcrumbs } from '@mui/material'
import { FC, ReactNode } from 'react'
import { Separator } from './Breadcrumbs.styles'
import { BreadcrumbItem, IBreadcrumbItem } from './components/BreadcrumbItem'

interface IBreadcrumbsProps {
  breadcrumbs: IBreadcrumbItem[]
  separator?: ReactNode
}

const CommonSeparator: ReactNode = <Separator />

export const Breadcrumbs: FC<IBreadcrumbsProps> = ({
  separator = CommonSeparator,
  breadcrumbs
}) => {
  return (
    <MUIBreadcrumbs aria-label='breadcrumb' separator={separator}>
      {breadcrumbs.map(({ label, link }, index) => (
        <BreadcrumbItem
          label={label}
          link={link}
          isActive={index === breadcrumbs.length - 1}
        />
      ))}
    </MUIBreadcrumbs>
  )
}
