import { FC } from 'react'
import { Grid } from '@mui/material'
import { Show } from '../../atoms/JSXExtensions'
import { OfferNotes } from './components/Notes'
import { OfferAddons } from './components/Addons'
import { OfferTradeIn } from './components/TradeIn'
import { OfferTotals } from './components/TotalCalculation'
import { OfferDetailsWidgetProps } from './types'

export interface OfferDetailsBodyProps
  extends NonNullable<
    Omit<OfferDetailsWidgetProps, 'previousOffer' | 'actions'>
  > {
  isPreviousOffer?: boolean
}

export const OfferDetailsBody: FC<OfferDetailsBodyProps> = ({
  totalPrice,
  vehiclePrice,
  salesTax,
  addons,
  tradeinValue,
  notes,
  isPreviousOffer
}) => {
  return (
    <Grid container rowSpacing={2} flexDirection='column'>
      <Grid item>
        <OfferTotals
          totalPrice={totalPrice}
          vehiclePrice={vehiclePrice}
          salesTax={salesTax}
          isPreviousOffer={isPreviousOffer}
        />
      </Grid>
      <Show when={addons}>
        {(value) => (
          <Grid item>
            <OfferAddons addons={value} />
          </Grid>
        )}
      </Show>
      <Show when={tradeinValue}>
        {(value) => (
          <Grid item>
            <OfferTradeIn tradeinValue={value} />
          </Grid>
        )}
      </Show>
      <Show when={notes}>
        {(value) => (
          <Grid item>
            <OfferNotes notes={value} />
          </Grid>
        )}
      </Show>
    </Grid>
  )
}
