import { ButtonProps } from '@mui/material'
import { FC } from 'react'
import { ActionBlockBox, ActionButton } from './ActionBlock.styles'
import { ButtonType } from './ActionBlock.types'

export type ButtonSchemaProps = {
  disabled?: boolean
  label: string
  size?: 'small' | 'medium' | 'large'
  onClick?: ButtonProps['onClick']
  type: ButtonType
  props?: ButtonProps
}

type ActionBlockProps = {
  pushToBottom?: boolean
  className?: string | false
  schema: Array<ButtonSchemaProps>
}

export const ActionBlock: FC<ActionBlockProps> = ({
  schema,
  pushToBottom,
  className
}) => {
  const isSingle = schema.length === 1

  return (
    <ActionBlockBox
      className={className || ''}
      {...(pushToBottom && { marginTop: 'auto' })}
    >
      {schema.map((item, index) => {
        return item.type === 'empty' ? (
          <span key={index} />
        ) : (
          <ActionButton
            key={index}
            buttonType={item.type}
            isSingle={isSingle}
            disabled={item.disabled}
            onClick={item.onClick}
            variant='outlined'
            size={item?.size}
            {...item.props}
          >
            {item.label}
          </ActionButton>
        )
      })}
    </ActionBlockBox>
  )
}
