export const config = {
  AWS_API_KEY: process.env.REACT_APP_AWS_API_KEY || '',
  CHATS_API_ENDPOINT: process.env.REACT_APP_CHATS_API_ENDPOINT || '',
  EXTERNAL_API_ENDPOINT: process.env.REACT_APP_EXTERNAL_API_ENDPOINT || '',
  FEATURE_FLAG_SUBSCRIPTION: process.env.REACT_APP_FEATURE_FLAG_SUBSCRIPTION || 'enabled',
  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID || '',
  GTM_ID: process.env.REACT_APP_GTM_ID || '',
  INTERNAL_API_ENDPOINT: process.env.REACT_APP_INTERNAL_API_ENDPOINT || '',
  LINKEDIN_CLIENT_ID: process.env.REACT_APP_LINKEDIN_CLIENT_ID || '',
  NOTIFICATIONS_API_ENDPOINT: process.env.REACT_APP_NOTIFICATIONS_API_ENDPOINT || '',
  UPDATE_CHAT_PERIOD: process.env.REACT_APP_UPDATE_CHAT_PERIOD || '20000',
  UPDATE_CHATS_PERIOD: process.env.REACT_APP_UPDATE_CHATS_PERIOD || '300000',
}
