import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { logout } from '../../thunks/logout'
import { resetUserSession } from '../../../utils/resetUserSession'
import { OrNull } from '../../../types/OrNull'
import { DriverLicenseFileType } from '../../../api/carSnoopApi/users/types'
import { IDPTypes } from '../../../api/carSnoopApi/statusTypes'
import {
  SubscriptionNameType,
  UserTypeStatus
} from '../../../api/carSnoopApi/accounts/types'

export interface Address extends Record<string, string> {
  id: string
  nickname: string
  street: string
  city: string
  state: string
  zipCode: string
}

export enum UserInfoStatusMap {
  Unauthenticated = 'unauthenticated',
  Authenticated = 'authenticated',
  Preregistered = 'preregistered',
  Unknown = 'unknown'
}

export interface UserInfoState {
  status: UserInfoStatusMap
  id: string
  idp: IDPTypes
  // Unique Google id
  sub: string
  isDealer: boolean
  public: boolean
  dealershipName: string
  accountID: string
  sessionId: string
  email: string
  firstName: string
  lastName: string
  fullName: string
  avatar: string
  isAdmin: boolean
  phone: string
  dateOfBirth: string
  taxID: string
  driversLicense: string
  driversLicenseState: string
  driversLicenseExpiration: string
  driverLicenseFile: DriverLicenseFileType
  users: {
    id: string
    isAdmin: boolean
    fullName: string
    active: boolean
    status: UserTypeStatus
    avatar: string
  }[]
  addresses: Address[]
  subscription: {
    name: SubscriptionNameType | ''
    completedDeals: number
    // Null means infinity
    maxDeals: OrNull<number>
    price: number
  }
  submitRequestFeePaid: boolean
}

export const initialState: UserInfoState = {
  status: UserInfoStatusMap.Unknown,
  id: '',
  idp: 'CarSnoop',
  sub: '',
  isDealer: false,
  public: false,
  dealershipName: '',
  accountID: '',
  sessionId: '',
  email: '',
  firstName: '',
  lastName: '',
  fullName: '',
  avatar: '',
  isAdmin: false,
  phone: '',
  dateOfBirth: '',
  taxID: '',
  driversLicense: '',
  driversLicenseState: '',
  driversLicenseExpiration: '',
  driverLicenseFile: null,
  users: [],
  addresses: [],
  subscription: {
    name: '',
    completedDeals: 0,
    maxDeals: 0,
    price: 0
  },
  submitRequestFeePaid: false
}

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    authorize: (
      store,
      action: PayloadAction<
        Partial<
          Pick<
            UserInfoState,
            | 'id'
            | 'idp'
            | 'accountID'
            | 'sessionId'
            | 'isDealer'
            | 'users'
            | 'isAdmin'
            | 'phone'
            | 'email'
            | 'dateOfBirth'
            | 'firstName'
            | 'lastName'
            | 'fullName'
            | 'avatar'
            | 'addresses'
            | 'dealershipName'
            | 'taxID'
            | 'driversLicense'
            | 'driversLicenseState'
            | 'driversLicenseExpiration'
            | 'driverLicenseFile'
            | 'public'
            | 'subscription'
            | 'submitRequestFeePaid'
          >
        >
      >
    ) => {
      const {
        id = initialState.id,
        idp = initialState.idp,
        accountID = initialState.accountID,
        sessionId = initialState.sessionId,
        isDealer = initialState.isDealer,
        dealershipName = initialState.dealershipName,
        users = initialState.users,
        isAdmin = initialState.isAdmin,
        phone = initialState.phone,
        dateOfBirth = initialState.dateOfBirth,
        email = initialState.email,
        firstName = initialState.firstName,
        lastName = initialState.lastName,
        fullName = initialState.fullName,
        avatar = initialState.avatar,
        addresses = initialState.addresses,
        taxID = initialState.taxID,
        driversLicense = initialState.driversLicense,
        driversLicenseState = initialState.driversLicenseState,
        driversLicenseExpiration = initialState.driversLicenseExpiration,
        driverLicenseFile = initialState.driverLicenseFile,
        public: isPublicInventory = initialState.public,
        subscription = initialState.subscription,
        submitRequestFeePaid = initialState.submitRequestFeePaid
      } = action.payload

      return {
        ...store,
        id,
        idp,
        accountID,
        sessionId,
        isDealer,
        dealershipName,
        status: UserInfoStatusMap.Authenticated,
        loadingStatus: 'succeeded',
        users,
        isAdmin,
        phone,
        dateOfBirth,
        email,
        firstName,
        lastName,
        fullName,
        avatar,
        addresses,
        taxID,
        driversLicense,
        driversLicenseState,
        driversLicenseExpiration,
        driverLicenseFile,
        public: isPublicInventory,
        subscription,
        submitRequestFeePaid
      }
    },

    register: (
      store,
      action: PayloadAction<
        Partial<Omit<UserInfoState, 'status' | 'accountID'>> &
          Pick<UserInfoState, 'id' | 'idp'>
      >
    ) => {
      const {
        email = initialState.email,
        firstName = initialState.firstName,
        lastName = initialState.lastName,
        avatar = initialState.avatar,
        sub = initialState.sub
      } = action.payload

      return {
        ...store,
        id: action.payload.id,
        idp: action.payload.idp,
        sub,
        status: UserInfoStatusMap.Preregistered,
        email,
        firstName,
        lastName,
        avatar
      }
    },
    logout: () => {
      resetUserSession()
      return {
        ...initialState,
        status: UserInfoStatusMap.Unauthenticated
      }
    },
    setUserStatus: (
      store,
      action: PayloadAction<Pick<UserInfoState, 'status'>>
    ) => {
      return {
        ...store,
        ...action.payload
      }
    },
    setUserSubscription: (
      store,
      action: PayloadAction<Pick<UserInfoState, 'subscription'>>
    ) => {
      return {
        ...store,
        ...action.payload
      }
    },
    setSubmitRequestFeePaid: (
      store,
      action: PayloadAction<Pick<UserInfoState, 'submitRequestFeePaid'>>
    ) => {
      return {
        ...store,
        ...action.payload
      }
    },
    setConnectedUsers: (
      store,
      action: PayloadAction<Pick<UserInfoState, 'users'>>
    ) => {
      return {
        ...store,
        ...action.payload
      }
    },
    setAddresses: (
      store,
      action: PayloadAction<Pick<UserInfoState, 'addresses'>>
    ) => {
      return {
        ...store,
        ...action.payload
      }
    },
    setUserPersonalInfo: (
      store,
      action: PayloadAction<
        Partial<
          Pick<
            UserInfoState,
            | 'firstName'
            | 'lastName'
            | 'fullName'
            | 'avatar'
            | 'phone'
            | 'dateOfBirth'
            | 'taxID'
            | 'driversLicense'
            | 'driversLicenseState'
            | 'driversLicenseExpiration'
            | 'driverLicenseFile'
            | 'public'
          >
        >
      >
    ) => {
      const {
        firstName = store.firstName,
        lastName = store.lastName,
        avatar = store.avatar,
        phone = store.phone,
        fullName = store.fullName,
        dateOfBirth = store.dateOfBirth,
        taxID = store.taxID,
        driversLicense = store.driversLicense,
        driversLicenseState = store.driversLicenseState,
        driversLicenseExpiration = store.driversLicenseExpiration,
        driverLicenseFile = store.driverLicenseFile,
        public: isPublicInventory = store.public
      } = action.payload

      return {
        ...store,
        firstName,
        lastName,
        phone,
        dateOfBirth,
        taxID,
        driversLicense,
        driversLicenseState,
        avatar,
        fullName,
        driversLicenseExpiration,
        driverLicenseFile,
        public: isPublicInventory
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, () => {
      resetUserSession()
      return {
        ...initialState,
        status: UserInfoStatusMap.Unauthenticated
      }
    })
  }
})
