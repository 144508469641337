import {
  IUpdateOfferParams,
  UpdateOfferError,
  UpdateOfferSuccess
} from '../../../api/carSnoopApi/offers/types'
import {
  RemoveTradeInError,
  RemoveTradeInSuccess
} from '../../../api/carSnoopApi/transactions/types'
import { ErrorResponse, SuccessResponse } from '../../../api/private.types'
import { ResponseStatusMap } from '../../../types/status'
import {
  RequestObservable,
  useApiContext,
  useMakeService
} from '../../../utils/hooks'

export const useRequests = (): {
  updateOffer: (
    data: IUpdateOfferParams
  ) => Promise<
    SuccessResponse<UpdateOfferSuccess> | ErrorResponse<UpdateOfferError> | void
  >
  updateOfferObservable: RequestObservable<ReturnType<typeof useMakeService>>
  removeTradeIn: (
    id: string
  ) => Promise<
    | SuccessResponse<RemoveTradeInSuccess>
    | ErrorResponse<RemoveTradeInError>
    | void
  >
  removeTradeInObservable: RequestObservable<ReturnType<typeof useMakeService>>
} => {
  const { carSnoopApi } = useApiContext()

  const [updateOffer, updateOfferObservable] = useMakeService(
    async ({
      id,
      totalPrice,
      addOns,
      priceComponents,
      notes
    }: IUpdateOfferParams) => {
      const response = await carSnoopApi.offers.updateOffer({
        id,
        totalPrice,
        priceComponents,
        notes,
        addOns
      })

      if (response.status === ResponseStatusMap.Success) {
        return {
          status: response.status,
          id: response.id,
          message: response.message
        }
      }

      if (response.status === ResponseStatusMap.Error) {
        return {
          status: response.status,
          message: response.message
        }
      }
    },
    { includeCheckAccess: true }
  )

  const [removeTradeIn, removeTradeInObservable] = useMakeService(
    async (id: string) => {
      const response = await carSnoopApi.transactions.removeTradeIn(id)

      if (response.status === ResponseStatusMap.Success) {
        return {
          status: response.status,
          id: response.id,
          message: response.message
        }
      }

      if (response.status === ResponseStatusMap.Error) {
        return {
          status: response.status,
          id: response.id,
          message: response.message
        }
      }
    },
    {
      withStatusNotification: true
    }
  )

  return {
    updateOffer,
    updateOfferObservable,
    removeTradeIn,
    removeTradeInObservable
  }
}
