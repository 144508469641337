export const arrayUnique = <T>(
  ...args: T extends object ? [T[], (elem: T) => unknown] : [arr: T[]]
): T[] => {
  const [arr, toPrim] = args
  const map = new Map()

  return arr.filter((elem) => {
    const key = toPrim ? toPrim(elem) : elem
    if (map.has(key)) {
      return false
    }
    map.set(key, true)
    return true
  })
}
