import { Grid, styled, Typography } from '@mui/material'
import { COLORS } from '../../../../types/colors'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

const TypographyContainer = styled(Grid)`
  & > div {
    margin: 8px;
  }
`

export const TypographyExample = () => {
  return (
    <ComponentContainer fullWidth>
      <ComponentWithPaperBackground>
        <TypographyContainer container flexDirection='column'>
          <Grid item>
            <Typography variant='emphasized'>emphasized Typography</Typography>
          </Grid>
          <Grid item>
            <Typography variant='error'>error Typography</Typography>
          </Grid>
          <Grid item sx={{ backgroundColor: COLORS.text_gray }}>
            <Typography variant='overline'>overline Typography</Typography>
          </Grid>
          <Grid item>
            <Typography variant='details'>details Typography</Typography>
          </Grid>
          <Grid item>
            <Typography variant='badge'>badge Typography</Typography>
          </Grid>
          <Grid item>
            <Typography variant='caption'>caption Typography</Typography>
          </Grid>
          <Grid item>
            <Typography variant='body1'>body1 Typography</Typography>
          </Grid>
          <Grid item>
            <Typography variant='body2'>body2 Typography</Typography>
          </Grid>
          <Grid item>
            <Typography variant='main'>main Typography</Typography>
          </Grid>
          <Grid item>
            <Typography variant='subtitle1_grey'>
              subtitle1_grey Typography
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='subtitle2'>subtitle2 Typography</Typography>
          </Grid>
          <Grid item>
            <Typography variant='subtitle1'>subtitle1 Typography</Typography>
          </Grid>
          <Grid item>
            <Typography variant='title1'>title1 Typography</Typography>
          </Grid>
          <Grid item>
            <Typography variant='label1'>label1 Typography</Typography>
          </Grid>
          <Grid item>
            <Typography variant='h6'>H6 Typography</Typography>
          </Grid>
          <Grid item>
            <Typography variant='h5'>H5 Typography</Typography>
          </Grid>
          <Grid item>
            <Typography variant='h4'>H4 Typography</Typography>
          </Grid>
          <Grid item>
            <Typography variant='h3'>H3 Typography</Typography>
          </Grid>
          <Grid item>
            <Typography variant='h2'>H2 Typography</Typography>
          </Grid>
          <Grid item>
            <Typography variant='h1'>H1 Typography</Typography>
          </Grid>
        </TypographyContainer>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
