import { ReactNode } from 'react'
import { Link } from '@mui/material'
import { COLORS } from '../../types/colors'

export const linkifyDecorator = (
  decoratedHref: string,
  decoratedText: string,
  key: number
): ReactNode => (
  <Link
    href={decoratedHref}
    key={key}
    sx={{
      color: COLORS.accent_blue
    }}
    target='_blank'
  >
    {decoratedText}
  </Link>
)
