import CloseIcon from '@mui/icons-material/Close'
import { Box, Grid, Typography } from '@mui/material'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { IPriceComponent } from '../../../../api/carSnoopApi/offers/types'
import { NormalizedTransactionType } from '../../../../api/carSnoopApi/transactions/types'
import { DefaultVehicle } from '../../../../assets/imgs/DefaultVehicle'
import { Badge } from '../../../../atoms/Badge'
import { AgreeButton, CancelButton, RedButton } from '../../../../atoms/Button'
import { CollapsibleNotes } from '../../../../atoms/CollapsibleNotes'
import { Condition } from '../../../../atoms/Condition'
import { FixedContainer } from '../../../../atoms/FixedContainer'
import { Show } from '../../../../atoms/JSXExtensions'
import { NavigationBackButton } from '../../../../atoms/NavigationBackButton'
import { CREATE_OFFER_MODAL_IS_OPEN_FORM_KEY } from '../../../../constants/localStorageKeys'
import { DisplayTradeInDetails } from '../../../../features/DisplayTradeInDetails'
import { DisplayVehicleInfo } from '../../../../features/DisplayVehicleInfo'
import { OfferModal } from '../../../../features/OfferModal'
import {
  calculateTotalCost,
  mapOfferData
} from '../../../../features/OfferModal/OfferModal.helpers'
import { IOfferModalForm } from '../../../../features/OfferModal/OfferModal.types'
import { useCheckSubscription } from '../../../../features/Subscription/hooks/useCheckSubscription'
import { CommonConfirmationModal } from '../../../../molecules/Modal/CommonConfirmationModal'
import { VehicleBadge } from '../../../../molecules/VehicleItem/VehicleBadge'
import { completedDealStatuses } from '../../../../store/pages/Deals'
import { BORDERS, COLORS } from '../../../../types/colors'
import { DEAL_FLOW } from '../../../../types/enums/dealFlow'
import { ResponseStatusMap } from '../../../../types/status'
import { formatPrice } from '../../../../utils'
import {
  useDealsRoute,
  useMakeService,
  useModal,
  useRequestToast,
  useRestoreData,
  useUserInfo
} from '../../../../utils/hooks'
import { isNewTab } from '../../../../utils/isNewTab'
import { useReturnToDealsPage } from '../../hooks'
import { RequestTableBody } from './components/RequestTableBody'
import { RequestTableHeader } from './components/RequestTableHeader'
import { useRequests } from './hooks/useRequests'

export const DealDetailsPage: FC<{
  transaction: NormalizedTransactionType
  onCancelDeal: () => Promise<unknown>
  onDeclineDeal: () => Promise<unknown>
}> = (props) => {
  const { transaction, onCancelDeal, onDeclineDeal } = props
  const { isAdmin } = useUserInfo()
  const [isOfferModalOpen, setOfferModalFlag] = useState(false)
  const [includeTradeIn, setIncludeTradeIn] = useState(
    !!transaction.tradeInVehicle
  )
  const navigate = useNavigate()
  const { mode = DEAL_FLOW.BUY } = useParams()
  const { openRequestToast } = useRequestToast()
  const { getPathToDeal } = useDealsRoute()
  const {
    restoredData: isModalOpen,
    storeData,
    clearData
  } = useRestoreData<boolean>(CREATE_OFFER_MODAL_IS_OPEN_FORM_KEY)
  const { createOfferObservable, createOffer, removeTradeIn } = useRequests()

  const isBuyingFlow = mode === DEAL_FLOW.BUY

  const cancelDealConfirmation = useModal(onCancelDeal)
  const declineDealConfirmation = useModal(onDeclineDeal)

  const { checkSubscriptionServiceObservable, checkSubscriptionService } =
    useCheckSubscription()

  const { id, vehicle, request, tradeIn, status, sellerID } = transaction
  const isRequested = useMemo(() => status === 'Requested', [status])
  const imageUrl = vehicle.documentContent

  const { retailValue } = vehicle
  const { notes } = request
  const backToDealsPage = useReturnToDealsPage(status, sellerID)

  const handleCreateOffer = useCallback(async () => {
    const subscriptionCheckingResult = await checkSubscriptionService()
    if (subscriptionCheckingResult.ok) {
      setOfferModalFlag(true)
    }
  }, [id, checkSubscriptionService])

  const handleCloseModal = useCallback(() => {
    setIncludeTradeIn(true)
    setOfferModalFlag(false)
  }, [setIncludeTradeIn, setOfferModalFlag])

  const showFixedContainer = !isBuyingFlow && isRequested

  const isDealCompleted = useMemo(
    () => completedDealStatuses.includes(status),
    [status]
  )

  const formattedRetailValue = useMemo(
    () => (retailValue ? formatPrice(retailValue) : 'Contact CarSnoop'),
    [retailValue]
  )

  const nonRequestedSellingFlow = useMemo(
    () => !isBuyingFlow && !isRequested,
    [isBuyingFlow, isRequested]
  )

  const [createOfferRequest] = useMakeService(
    async ({
      data,
      priceComponents,
      addOns
    }: {
      data: IOfferModalForm
      priceComponents: IPriceComponent<number>[]
      addOns: IPriceComponent<number>[]
    }) => {
      const createOfferResponse = await createOffer({
        transactionId: transaction.id,
        totalPrice: calculateTotalCost(data),
        notes: data.notes,
        priceComponents,
        addOns
      })

      const isSuccess =
        createOfferResponse?.status === ResponseStatusMap.Success
      const isError = createOfferResponse?.status === ResponseStatusMap.Error
      const { isSubscriptionError } = createOfferResponse

      if (isSuccess) {
        clearData()
        navigate(
          `${getPathToDeal({
            transactionId: transaction.id,
            flow: DEAL_FLOW.SELL
          })}/completed`
        )

        return true
      }

      if (isError && !isSubscriptionError) {
        openRequestToast({ status: 'failed' })
      }

      return false
    }
  )

  const handleOfferSubmit = useCallback(
    async (data: IOfferModalForm) => {
      const { priceComponents, addOns } = mapOfferData(data, includeTradeIn)
      storeData(true)

      if (tradeIn && !includeTradeIn) {
        const removeTradeInResponse = await removeTradeIn(transaction.id)
        if (removeTradeInResponse?.status === ResponseStatusMap.Success) {
          const createOfferResponse = createOfferRequest({
            data,
            priceComponents,
            addOns
          })
          return createOfferResponse
        }
        return false
      }
      const createOfferResponse = await createOfferRequest({
        data,
        priceComponents,
        addOns
      })
      return createOfferResponse
    },
    [
      transaction.id,
      includeTradeIn,
      storeData,
      removeTradeIn,
      tradeIn,
      createOfferRequest
    ]
  )

  const handleRemoveTradeIn = useCallback(() => {
    setIncludeTradeIn(false)
  }, [setIncludeTradeIn])

  useEffect(() => {
    if (isModalOpen) {
      setOfferModalFlag(true)
    }
  }, [isModalOpen])

  return (
    <>
      <CommonConfirmationModal
        {...cancelDealConfirmation}
        message='Are you sure you would like to cancel deal?'
        hint='Canceled deal cannot be recovered'
      />

      <CommonConfirmationModal
        {...declineDealConfirmation}
        message='Are you sure you would like to decline the request?'
        hint='Declined request can not be recovered'
      />

      <Grid container spacing={3} mb={showFixedContainer ? 15 : 0}>
        <Show when={isBuyingFlow || nonRequestedSellingFlow || isDealCompleted}>
          <Grid container item justifyContent='space-between' wrap='nowrap'>
            <NavigationBackButton
              showBackButton={!isNewTab()}
              buttonText='Deal details'
              onBackClick={backToDealsPage}
              textVariant='title1'
            />
            <Condition
              condition={isRequested}
              trueContent={
                <Grid item xs='auto'>
                  <RedButton
                    onClick={cancelDealConfirmation.onOpen}
                    startIcon={<CloseIcon />}
                  >
                    Cancel Deal
                  </RedButton>
                </Grid>
              }
              falseContent={
                <Grid item>
                  <VehicleBadge status={status} />
                </Grid>
              }
            />
          </Grid>
        </Show>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={12} lg={5}>
            <Box
              sx={{
                aspectRatio: '16/9',
                background: imageUrl
                  ? `no-repeat center/cover url(${imageUrl})`
                  : `no-repeat center/cover ${COLORS.border_gray}`,

                borderRadius: '3px',
                position: 'relative',
              }}
            >
              <Box sx={{ position: 'absolute', left: '24px', bottom: '24px' }}>
                <Badge
                  text={formattedRetailValue}
                  background={COLORS.hover_background_blue}
                  color={COLORS.main_black}
                  textSx={{
                    fontSize: '18px'
                  }}
                  border='none'
                  sx={{ height: 'auto' }}
                />
              </Box>

              <Show when={!imageUrl}>
                <Box
                  sx={{
                    p: 2,
                    height: '100%',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <DefaultVehicle />
                </Box>
              </Show>
            </Box>
          </Grid>
          <Grid container item xs={12} lg={7}>
            <DisplayVehicleInfo {...vehicle} />
          </Grid>
        </Grid>

        <Grid container item flexDirection='column' spacing={3} wrap='nowrap'>
          <Grid item>
            <Typography variant='label1'>Request</Typography>
          </Grid>
          <Grid container item>
            <RequestTableHeader />
          </Grid>
          <Grid container item>
            <RequestTableBody {...request} tradeinVehicle={tradeIn} />
          </Grid>
          <Grid container item>
            <Grid item xs={12}>
              <CollapsibleNotes
                header={<Typography variant='label1'>Notes</Typography>}
              >
                {notes}
              </CollapsibleNotes>
            </Grid>
          </Grid>
          <Grid item>
            <DisplayTradeInDetails
              tradeinVehicle={tradeIn}
              noTradeinText='There are no Trade Ins added for this Deal'
              navigateToNewTab={isBuyingFlow}
            />
          </Grid>
        </Grid>

        <Show when={showFixedContainer}>
          <FixedContainer
            bottom
            sx={{
              py: 4,
              background: COLORS.white,
              borderTop: BORDERS.SM_GRAY
            }}
            background={COLORS.white}
          >
            <Grid container item flexDirection='column'>
              <Grid item container justifyContent='space-between' px={4}>
                <CancelButton
                  onClick={declineDealConfirmation.onOpen}
                  sx={{
                    borderColor: COLORS.text_red
                  }}
                >
                  Decline
                </CancelButton>
                <Show when={isAdmin}>
                  <AgreeButton
                    onClick={handleCreateOffer}
                    sx={{
                      whiteSpace: 'nowrap',
                      width: 'auto'
                    }}
                    disabled={checkSubscriptionServiceObservable.isLoading}
                  >
                    Create Offer
                  </AgreeButton>
                </Show>
              </Grid>
            </Grid>
          </FixedContainer>
        </Show>
      </Grid>
      <OfferModal
        onConfirm={handleOfferSubmit}
        onCloseClick={handleCloseModal}
        withConfirmationModal
        withDeclineModal
        isModalLoading={createOfferObservable.isLoading}
        isOpen={isOfferModalOpen}
        vehiclePrice={vehicle.retailValue}
        tradeIn={tradeIn?.tradeInValue}
        includeTradeIn={!!tradeIn}
        vehicleZipCode={vehicle.zipCode}
        onRemoveTradeInConfirm={handleRemoveTradeIn}
      />
    </>
  )
}
