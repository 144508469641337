import { createContext } from 'react'
import { noop } from '../noop'

export type NavigationControllerCallback = (onConfirm: () => void) => void

export class NavigationController {
  isPathBlocked: boolean = false
  callback: NavigationControllerCallback = noop

  blockPath = (callback: NavigationControllerCallback) => {
    this.isPathBlocked = true
    this.callback = callback
  }

  releasePath = () => {
    this.isPathBlocked = false
    this.callback = noop
  }
}

export const navigationController = new NavigationController()

export const NavigationControllerContext =
  createContext<NavigationController>(navigationController)
