import { FC, useCallback, useMemo, PropsWithChildren } from 'react'
import { Grid, Typography } from '@mui/material'
import { Formik, Form } from 'formik'
import { Condition } from '../../../../../atoms/Condition'
import {
  useUserInfo,
  useApiContext,
  useMakeService
} from '../../../../../utils/hooks'
import { Divider } from '../../../../../atoms/Divider'
import { TextInputFieldNew } from '../../../../../atoms/TextInputFieldNew'
import {
  NumericField,
  NumericFieldProps
} from '../../../../../atoms/NumericField'
import { AutoselectInputField } from '../../../../../atoms/AutoselectInputField'
import {
  DependentRetailValueField,
  DependentTradeinValueField
} from './DependentFields'
import { NotesField } from '../../../../../atoms/NotesField'
import { InformationIcon } from '../../../../../assets/icons/Information'
import {
  AddVehicleFormFieldsTypes,
  ADD_VEHICLE_FIELD_NAMES,
  ADD_VEHICLE_FIELD_LABELS,
  AddressOption
} from '../../../constants'
import { VehicleConditionOptions } from '../../../../VehicleEdit/constants'
import { getValidationSchema } from '../../../validationSchema'
import { AutoselectOption } from '../../../../../atoms/AutoselectOption'
import { AgreeButton, CancelButton } from '../../../../../atoms/Button'
import { ReferenceModel } from '../../../../../types/ReferenceModel'
import { ColorOption } from '../../../../../types/Autoselect'
import {
  ResponseStatusMap,
  VehicleCondition
} from '../../../../../types/status'
import { DependentFieldHocEffect } from '../../../../../atoms/DependentField'
import { useReadModelYear } from '../hooks/useReadModelYear'
import { FormOrchestratorStepProps } from '../../../../../layout/FormOrchestrator/types'
import { isOptionEqualToValue } from '../../../../../utils/autoselect/isOptionEqualToValue'
import { getOptionLabel } from '../../../../../utils/autoselect/getOptionLabel'
import { PrefilledValues } from './types'
import { StateOptions } from '../../../../../constants/applicationConstants'
import { AutoselectColorOption } from '../../../../../atoms/AutoselectColorOption'
import { isRequiredFormField } from '../../../../../utils/validators/isRequiredFormField'
import { Show } from '../../../../../atoms/JSXExtensions'

export const FieldContainer: FC<
  PropsWithChildren<Parameters<typeof Grid>[0]>
> = ({ children, ...otherProps }) => (
  <Grid item xs={12} {...otherProps}>
    {children}
  </Grid>
)

interface EditNewVehicleFormProps extends FormOrchestratorStepProps {
  prefilledValues: PrefilledValues
  currentVehicleOptions: ReturnType<typeof useReadModelYear>[1]
  handleCancelClick: () => void
  isVinFlow?: boolean
}

export const EditNewVehicleForm: FC<EditNewVehicleFormProps> = ({
  ctx,
  prefilledValues,
  currentVehicleOptions,
  handleCancelClick,
  isVinFlow = false
}) => {
  const { values: orchestratorValues } = ctx
  const { isDealer, addresses } = useUserInfo()
  const { externalCarSnoopApi } = useApiContext()

  const currentVin = useMemo(() => {
    if ('vin' in prefilledValues && prefilledValues.vin) {
      return prefilledValues.vin
    }
    return orchestratorValues.vin || ''
  }, [prefilledValues, orchestratorValues])

  const [readVehicleValue, readVehicleValueObservable] = useMakeService(
    async ({
      mileage,
      condition
    }: {
      mileage: string
      condition: VehicleCondition
    }) => {
      const response = await externalCarSnoopApi.vehicles.readVehicleValue({
        vin: currentVin,
        mileage,
        condition
      })

      if (response.status === ResponseStatusMap.Success) {
        return {
          status: response.status,
          tradeIn: response.tradeIn,
          retail: response.retail
        }
      }

      return response
    }
  )

  const handleSubmitClick = useCallback(
    (formValues: AddVehicleFormFieldsTypes) => {
      ctx.setValues({
        ...formValues,
        vin: currentVin,
        trim: prefilledValues.trim || ctx.values.trim,
        make: prefilledValues.make || ctx.values.make,
        model: prefilledValues.model || ctx.values.model,
        year: prefilledValues.year || ctx.values.year,
        style: prefilledValues.style || ctx.values.style,
        fuelType: prefilledValues.fuelType || ctx.values.fuelType,
        engineDescription:
          prefilledValues.engineDescription || ctx.values.engineDescription,
        transmission: prefilledValues.transmission || ctx.values.transmission,
        mpg: prefilledValues.mpg || ctx.values.mpg,
        drivetrain: prefilledValues.drivetrain || ctx.values.drivetrain,
        standardEquipment:
          prefilledValues.standardEquipment || ctx.values.standardEquipment
      })

      ctx.nextStep()
    },
    []
  )

  const addressesOptions: AddressOption[] = useMemo(
    () =>
      addresses.map<AddressOption>((a, index) => {
        const { id, nickname, street, city, zipCode, state } = a
        const addressName: string = `${nickname || `Home ${index + 1}`} (${
          street ? `${street}, ` : ''
        }${zipCode}, ${city}, ${state})`
        return { id, name: addressName, rawValue: a }
      }),
    [addresses]
  )

  const retailValueFieldEffect: DependentFieldHocEffect<
    NumericFieldProps,
    AddVehicleFormFieldsTypes
  > = useCallback(
    async (___, context) => {
      const { values, setFieldValue } = context
      const { currentMileage: mileage, condition } = values
      const conditionName = condition?.id

      if (mileage && condition) {
        if (currentVin) {
          const result = await readVehicleValue({
            mileage,
            condition: conditionName as VehicleCondition
          })

          if (result.status === 'success') {
            setFieldValue(
              ADD_VEHICLE_FIELD_NAMES.RETAIL_VALUE,
              result.retail.toString()
            )
            setFieldValue(
              ADD_VEHICLE_FIELD_NAMES.TRADE_IN_VALUE,
              result.tradeIn.toString()
            )
          }

          if (result.status === 'error') {
            setFieldValue(ADD_VEHICLE_FIELD_NAMES.RETAIL_VALUE, '0.00')
            setFieldValue(ADD_VEHICLE_FIELD_NAMES.TRADE_IN_VALUE, '0.00')
          }
        }
      }
    },
    [readVehicleValue, currentVin]
  )

  const retailValueFieldOnBlurEffect: DependentFieldHocEffect<
    NumericFieldProps,
    AddVehicleFormFieldsTypes
  > = useCallback(async (___, context) => {
    const { validateForm } = context
    validateForm()
  }, [])

  const tradeinValueFieldOnBlurEffect: DependentFieldHocEffect<
    NumericFieldProps,
    AddVehicleFormFieldsTypes
  > = retailValueFieldOnBlurEffect

  const isFormDisabled = useMemo(
    () => !prefilledValues.make && !ctx.values.make,
    [prefilledValues.make, ctx.values.make]
  )

  const noCheckboxExteriorColorOptions = useMemo(
    () =>
      currentVehicleOptions?.extColors.map((option) => ({
        ...option,
        disableCheckbox: true
      })) || [],
    [currentVehicleOptions?.extColors]
  )
  const noCheckboxInteriorColorOptions = useMemo(
    () =>
      currentVehicleOptions?.intColors.map((option) => ({
        ...option,
        disableCheckbox: true
      })) || [],
    [currentVehicleOptions?.intColors]
  )

  const defaultAddress = useMemo(
    () => (isDealer ? addressesOptions[0] : null),
    [isDealer, addressesOptions]
  )

  const hasExteriorColorOptions = !!noCheckboxExteriorColorOptions.length
  const hasInteriorColorOptions = !!noCheckboxInteriorColorOptions.length

  const currentValidationSchema = useMemo(() => {
    return getValidationSchema(
      isDealer,
      hasExteriorColorOptions,
      hasInteriorColorOptions
    )
  }, [isDealer, hasExteriorColorOptions, hasInteriorColorOptions])

  const isRequiredField = isRequiredFormField(currentValidationSchema)

  return (
    <Grid container>
      {/* Logically VIN flow and licence plate are different forms but technically they are the same form with common fields.
      So in case of flow switching that fields save its values that is wrong behaviour. So i added 'reinitializeSignal' field
      that tells to Formik that flow is changed (we start working with another form) */}
      <Formik<AddVehicleFormFieldsTypes & { reinitializeSignal: boolean }>
        initialValues={{
          formLicensePlate: '',
          formLicensePlateState: null,
          address: defaultAddress,
          stockNumber: '',
          exteriorColor: '',
          interiorColor: '',
          currentMileage: '0.00',
          condition: null,
          howMuchIsOwed: '0.00',
          notes: '',
          tradeinValue: '0.00',
          retailValue: '0.00',
          reinitializeSignal: isVinFlow,
          ...orchestratorValues
        }}
        validationSchema={currentValidationSchema}
        onSubmit={handleSubmitClick}
        validateOnMount
        validateOnChange={false}
        enableReinitialize
      >
        {(formik) => {
          return (
            <Form style={{ width: '100%' }}>
              <Grid container item spacing={3} xs={12}>
                <Grid
                  container
                  item
                  sm={12}
                  lg={6}
                  spacing={2}
                  alignContent='flex-start'
                >
                  <Condition
                    condition={isVinFlow}
                    trueContent={
                      <FieldContainer container spacing='12px'>
                        <Grid item xs={8}>
                          <TextInputFieldNew
                            name={ADD_VEHICLE_FIELD_NAMES.LICENSE_PLATE}
                            label={ADD_VEHICLE_FIELD_LABELS.LICENSE_PLATE}
                            required={isRequiredField}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <AutoselectInputField<ReferenceModel>
                            name={ADD_VEHICLE_FIELD_NAMES.LICENSE_PLATE_STATE}
                            label={ADD_VEHICLE_FIELD_LABELS.LICENSE_PLATE_STATE}
                            options={StateOptions}
                            renderOption={AutoselectOption}
                            getOptionLabel={getOptionLabel}
                            isOptionEqualToValue={isOptionEqualToValue}
                            required={isRequiredField}
                          />
                        </Grid>
                      </FieldContainer>
                    }
                  />
                  <Condition
                    condition={isDealer}
                    trueContent={
                      <FieldContainer>
                        <TextInputFieldNew
                          name={ADD_VEHICLE_FIELD_NAMES.STOCK_NUMBER}
                          label={ADD_VEHICLE_FIELD_LABELS.STOCK_NUMBER}
                          disabled={isFormDisabled}
                          required={isRequiredField}
                        />
                      </FieldContainer>
                    }
                    falseContent={
                      <Grid item xs={12}>
                        <Grid item xs={12} sx={{ mb: 1 }}>
                          <AutoselectInputField<ReferenceModel>
                            name={ADD_VEHICLE_FIELD_NAMES.ADDRESS}
                            label={ADD_VEHICLE_FIELD_LABELS.ADDRESS}
                            renderOption={AutoselectOption}
                            options={addressesOptions}
                            getOptionLabel={getOptionLabel}
                            isOptionEqualToValue={isOptionEqualToValue}
                            disabled={isFormDisabled}
                            required={isRequiredField}
                          />
                        </Grid>
                        <FieldContainer>
                          <Grid container spacing={1} alignContent='center'>
                            <Grid item>
                              <InformationIcon />
                            </Grid>
                            <Grid item>
                              <Typography variant='subtitle2'>
                                Select the location of this vehicle.
                              </Typography>
                            </Grid>
                          </Grid>
                        </FieldContainer>
                      </Grid>
                    }
                  />
                  <Grid item container xs={12} spacing={2}>
                    <FieldContainer>
                      <Show
                        when={hasExteriorColorOptions}
                        fallback={
                          <TextInputFieldNew
                            name={ADD_VEHICLE_FIELD_NAMES.EXTERIOR_COLOR}
                            label={ADD_VEHICLE_FIELD_LABELS.EXTERIOR_COLOR}
                            disabled={isFormDisabled}
                            required={isRequiredField}
                          />
                        }
                      >
                        <AutoselectInputField<ColorOption>
                          name={ADD_VEHICLE_FIELD_NAMES.EXTERIOR_COLOR}
                          label={ADD_VEHICLE_FIELD_LABELS.EXTERIOR_COLOR}
                          renderOption={AutoselectColorOption}
                          options={noCheckboxExteriorColorOptions}
                          getOptionLabel={getOptionLabel}
                          isOptionEqualToValue={isOptionEqualToValue}
                          disabled={isFormDisabled}
                          required={isRequiredField}
                        />
                      </Show>
                    </FieldContainer>

                    <FieldContainer>
                      <Show
                        when={hasInteriorColorOptions}
                        fallback={
                          <TextInputFieldNew
                            name={ADD_VEHICLE_FIELD_NAMES.INTERIOR_COLOR}
                            label={ADD_VEHICLE_FIELD_LABELS.INTERIOR_COLOR}
                            disabled={isFormDisabled}
                            required={isRequiredField}
                          />
                        }
                      >
                        <AutoselectInputField<ColorOption>
                          name={ADD_VEHICLE_FIELD_NAMES.INTERIOR_COLOR}
                          label={ADD_VEHICLE_FIELD_LABELS.INTERIOR_COLOR}
                          renderOption={AutoselectColorOption}
                          options={noCheckboxInteriorColorOptions}
                          getOptionLabel={getOptionLabel}
                          isOptionEqualToValue={isOptionEqualToValue}
                          disabled={isFormDisabled}
                          required={isRequiredField}
                        />
                      </Show>
                    </FieldContainer>
                    <FieldContainer>
                      <NumericField
                        name={ADD_VEHICLE_FIELD_NAMES.CURRENT_MILEAGE}
                        label={ADD_VEHICLE_FIELD_LABELS.CURRENT_MILEAGE}
                        required={isRequiredField}
                      />
                    </FieldContainer>
                    <FieldContainer>
                      <AutoselectInputField<ReferenceModel>
                        name={ADD_VEHICLE_FIELD_NAMES.CONDITION}
                        label={ADD_VEHICLE_FIELD_LABELS.CONDITION}
                        renderOption={AutoselectOption}
                        options={VehicleConditionOptions}
                        getOptionLabel={getOptionLabel}
                        isOptionEqualToValue={isOptionEqualToValue}
                        disabled={isFormDisabled}
                        required={isRequiredField}
                      />
                    </FieldContainer>
                    <Condition
                      condition={isDealer}
                      trueContent={
                        <FieldContainer>
                          <NumericField
                            startWith='$'
                            name={ADD_VEHICLE_FIELD_NAMES.HOW_MUCH_IS_OWED}
                            label={ADD_VEHICLE_FIELD_LABELS.HOW_MUCH_IS_OWED}
                            required={isRequiredField}
                          />
                        </FieldContainer>
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  sm={12}
                  lg={6}
                  spacing={2}
                  alignContent='flex-start'
                >
                  <Condition
                    condition={!isDealer}
                    trueContent={
                      <FieldContainer>
                        <NumericField
                          startWith='$'
                          name={ADD_VEHICLE_FIELD_NAMES.HOW_MUCH_IS_OWED}
                          label={ADD_VEHICLE_FIELD_LABELS.HOW_MUCH_IS_OWED}
                          required={isRequiredField}
                        />
                      </FieldContainer>
                    }
                  />
                  <FieldContainer sx={{ mb: isDealer ? '32px' : '0' }}>
                    <NotesField
                      name={ADD_VEHICLE_FIELD_NAMES.NOTES}
                      label={ADD_VEHICLE_FIELD_LABELS.NOTES}
                      disabled={isFormDisabled}
                      rows={{
                        min: 8,
                        max: 8
                      }}
                      required={isRequiredField}
                    />
                  </FieldContainer>
                  <Grid container item spacing={2}>
                    <FieldContainer>
                      <Typography variant='details' fontStyle='italic'>
                        Estimated market values are based on the data you have
                        provided. Change it if needed.
                      </Typography>
                    </FieldContainer>
                    <FieldContainer>
                      <DependentTradeinValueField
                        startWith='$'
                        name={ADD_VEHICLE_FIELD_NAMES.TRADE_IN_VALUE}
                        label={ADD_VEHICLE_FIELD_LABELS.TRADE_IN_VALUE}
                        onBlurEffect={tradeinValueFieldOnBlurEffect}
                        disabled={!formik.touched.tradeinValue}
                        required={isRequiredField}
                      />
                    </FieldContainer>
                    <FieldContainer>
                      <DependentRetailValueField
                        startWith='$'
                        name={ADD_VEHICLE_FIELD_NAMES.RETAIL_VALUE}
                        label={ADD_VEHICLE_FIELD_LABELS.RETAIL_VALUE}
                        onChangeEffect={retailValueFieldEffect}
                        onBlurEffect={retailValueFieldOnBlurEffect}
                        disabled={!formik.touched.retailValue}
                        required={isRequiredField}
                      />
                    </FieldContainer>
                  </Grid>
                </Grid>
                <Grid container item>
                  <Divider />
                </Grid>
                <Grid container item justifyContent='flex-end' spacing={2}>
                  <Grid item>
                    <CancelButton onClick={handleCancelClick}>
                      CANCEL
                    </CancelButton>
                  </Grid>
                  <Grid item>
                    <AgreeButton
                      type='submit'
                      disabled={
                        !formik.isValid || readVehicleValueObservable.isLoading
                      }
                    >
                      NEXT
                    </AgreeButton>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )
        }}
      </Formik>
    </Grid>
  )
}
