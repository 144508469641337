import { FC } from 'react'
import { Grid, Typography } from '@mui/material'

interface NotAddedPlugProps {
  title?: string
}

export const NotAddedPlug: FC<NotAddedPlugProps> = ({
  title = 'Not added'
}) => {
  return (
    <Grid container>
      <Grid item>
        <Typography variant='details' sx={{ fontStyle: 'italic' }} noWrap>
          {title}
        </Typography>
      </Grid>
    </Grid>
  )
}
