import { NoInternetScreen } from '../../../../molecules/NoInternetScreen'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const NoInternetScreenExample = () => {
  const handleClick = () => {
    // eslint-disable-next-line
    console.log('>>>', 'Retry')
  }
  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <NoInternetScreen onRetry={handleClick} />
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
