import { createAsyncThunk } from '@reduxjs/toolkit'
import { carSnoopApi } from '../../api'

export const readDealershipListAsync = createAsyncThunk(
  'dealershipList/fetchDealershipListByZipCode',
  async (zipCode: string) => {
    const response = await carSnoopApi.accounts.readDealershipList(zipCode)

    if (!response.ok) {
      throw new Error('Server Error!')
    }

    return response.json()
  }
)
