import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import format from 'date-fns/format'
import { FormikProps, FormikValues } from 'formik'
import {
    Dispatch,
    FC,
    MouseEventHandler,
    PropsWithChildren,
    SetStateAction,
    useCallback,
    useMemo
} from 'react'
import { DriverLicenseFileType } from '../../../api/carSnoopApi/users/types'
import { UploadIcon } from '../../../assets/icons/Upload'
import { DatePickerField } from '../../../atoms/DatePicker'
import { EditPersonalInformationContainer } from '../../../atoms/EditPersonalInformationContainer'
import { Show } from '../../../atoms/JSXExtensions'
import { Label } from '../../../atoms/Label'
import { MaskedInput } from '../../../atoms/MaskedInput'
import { SelectField } from '../../../atoms/SelectElement'
import { StateOptions, YYYYMMDD } from '../../../constants/applicationConstants'
import { COLORS } from '../../../types/colors'
import { StatesList } from '../../../types/states'
import { isValidForm } from '../../../utils/isValidForm'
import { isRequiredFormField } from '../../../utils/validators/isRequiredFormField'
import { DocumentInfoCard } from './DocumentInfoCard'
import { validationSchema } from './validation'

export interface EditDriverLicenseProps {
  licenseNumber?: string
  expirationDate?: string
  state?: string
  licenseDocument?: DriverLicenseFileType
  setIsEditMode: Dispatch<SetStateAction<boolean>>
  submitChanges: (options: {
    dnl: string
    expirationDate: string
    state: typeof StatesList[number]
  }) => void | Promise<void>
  deleteDNL: (
    licenseNumber: EditDriverLicenseProps['licenseNumber']
  ) => void | Promise<void>
  onUploadDocument: MouseEventHandler<Element>
}

export const EditDriverLicense: FC<
  PropsWithChildren<EditDriverLicenseProps>
> = ({
  licenseNumber,
  expirationDate,
  state,
  licenseDocument,
  submitChanges,
  deleteDNL,
  setIsEditMode,
  onUploadDocument
}) => {
  const theme = useTheme()
  const isMinWidthLaptop = useMediaQuery(theme.breakpoints.between('lg', 1200))

  const onSubmit = useCallback(
    (formikValues: FormikValues) => {
      submitChanges({
        dnl: formikValues.dnl || '',
        expirationDate: format(formikValues.expirationDate, YYYYMMDD) || '',
        state: formikValues.state
      })
    },
    [submitChanges]
  )

  const onCancel = useCallback(() => {
    setIsEditMode(false)
  }, [setIsEditMode])

  const onDelete = useCallback(() => {
    deleteDNL(licenseNumber)
  }, [deleteDNL, licenseNumber])

  const isSubmitButtonDisabled = useCallback(
    (formik: FormikProps<FormikValues>) =>
      !(
        isValidForm(formik, {
          requiredFields: ['dnl', 'expirationDate', 'state']
        }) &&
        formik.dirty &&
        licenseDocument
      ),
    [licenseDocument]
  )

  const editContainerProps = useMemo(
    () => ({
      ...((licenseNumber || licenseDocument) && {
        deleteAction: { label: 'Delete Driver’s License', onClick: onDelete }
      })
    }),
    [onDelete, licenseNumber, licenseDocument]
  )

  const isRequiredField = isRequiredFormField(validationSchema)

  return (
    <EditPersonalInformationContainer
      title='Driver’s License'
      initialValues={{
        dnl: licenseNumber || '',
        expirationDate: expirationDate ? new Date(expirationDate) : null,
        state: state || ''
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onCancel={onCancel}
      isSubmitDisabled={isSubmitButtonDisabled}
      {...editContainerProps}
    >
      <Grid
        container
        flexDirection='column'
        justifyContent='space-between'
        rowSpacing={2}
      >
        <Grid item>
          <MaskedInput
            mask={false}
            name='dnl'
            label='DLN'
            hiddable
            guide={false}
            required={isRequiredField}
          />
        </Grid>
        <Grid item>
          <DatePickerField
            name='expirationDate'
            label='Expiration date'
            showError
            required={isRequiredField}
          />
        </Grid>
        <Grid item>
          <SelectField
            name='state'
            label='State'
            options={StateOptions}
            required={isRequiredField}
          />
        </Grid>
        <Grid item container>
          <Grid item xs={12}>
            <Label
              name='upload-driver-licence'
              label='Photo of your Driver’s license is required'
            />
          </Grid>
          <Show
            when={licenseDocument}
            fallback={
              <Grid item xs={12} my={1} id='upload-driver-licence'>
                <Grid
                  container
                  alignItems='center'
                  role='button'
                  sx={{
                    p: 1,
                    cursor: 'pointer',
                    backgroundColor: COLORS.secondary_background
                  }}
                  onClick={onUploadDocument}
                >
                  <Grid item>
                    <UploadIcon />
                  </Grid>
                  <Grid item ml={isMinWidthLaptop ? '6px' : '16px'}>
                    <Typography variant='main' color={COLORS.text_blue}>
                      Click here to upload front side
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            }
          >
            {(document) => (
              <Grid item mt={1} mr={1} xs={12}>
                <DocumentInfoCard
                  name="Driver's License"
                  type={document.type}
                  createdOn={document.createdOn}
                  link={document.link}
                />
              </Grid>
            )}
          </Show>
        </Grid>
      </Grid>
    </EditPersonalInformationContainer>
  )
}
