import { useLayoutEffect, useCallback, ReactNode } from 'react'
import { useField } from 'formik'
import { Autocomplete } from '@mui/material'
import { RenderOptionFunction } from '../../types/Autoselect'
import { ReferenceModel } from '../../types/ReferenceModel'
import { filterMultiSelectorValues } from '../../utils/filterMultiSelectorValues'
import { InputField } from '../InputField/InputField'
import { AutoselectPaper, AutoselectTag } from './AutoselectInputField.styles'
import { getIsFieldRequired } from '../../utils/validators/getIsFieldRequired'

interface AutoselectInputFieldProps<T extends ReferenceModel> {
  name: string
  label: string
  placeholder?: string
  options: T[]
  renderOption: RenderOptionFunction<T>
  getOptionLabel: (option: T) => string
  isOptionEqualToValue: (option: T, value: T) => boolean
  onSelect?: () => void
  disabled?: boolean
  multiple?: boolean
  defaultValue?: T | T[]
  exactMultiValue?: T
  customTags?: boolean
  noOptionText?: ReactNode
  required?: boolean | ((name: string) => boolean)
}

export const AutoselectInputField = <T extends ReferenceModel>(
  props: AutoselectInputFieldProps<T>
): JSX.Element => {
  const {
    name,
    placeholder,
    label,
    options,
    disabled = false,
    multiple,
    defaultValue,
    exactMultiValue,
    customTags = false,
    renderOption,
    isOptionEqualToValue,
    getOptionLabel,
    onSelect,
    noOptionText,
    required = false
  } = props

  const [field, , helpers] = useField<T | T[] | null>(name)
  const { onChange, onBlur, ...otherParams } = field
  const { value } = otherParams
  const isRequiredField = getIsFieldRequired(required, name)

  const renderTags = useCallback(
    (renderOptions: T[]) =>
      renderOptions.map((option, index) => {
        if (Array.isArray(value) && !index) {
          return <AutoselectTag key={option.name}>{option.name}</AutoselectTag>
        }

        return null
      }),
    [value]
  )

  useLayoutEffect(() => {
    const isMulti = multiple && Array.isArray(value)

    if (exactMultiValue && isMulti) {
      helpers.setValue(
        filterMultiSelectorValues(
          value,
          (item) => exactMultiValue.id === item.id
        )
      )
    }
  }, [value, exactMultiValue])

  return (
    <Autocomplete
      sx={{
        width: '100%'
      }}
      limitTags={customTags ? 1 : 2}
      options={options}
      clearIcon={null}
      disabled={disabled}
      multiple={multiple}
      disableCloseOnSelect={multiple}
      defaultValue={defaultValue}
      value={otherParams.value}
      openOnFocus
      blurOnSelect={!multiple ? 'touch' : false}
      PaperComponent={AutoselectPaper}
      renderInput={(params) => {
        return (
          <InputField
            ref={params.InputProps.ref}
            {...params}
            name={name}
            placeholder={placeholder}
            required={isRequiredField}
            label={label}
            hasMultiValue={multiple && !!(otherParams.value as T[])?.length}
          />
        )
      }}
      renderOption={renderOption}
      noOptionsText={noOptionText}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      onChange={(_, option) => {
        helpers.setValue(option)
        onSelect && onSelect()
      }}
      onBlur={onBlur}
      renderTags={customTags ? renderTags : undefined}
    />
  )
}
