import { FC } from 'react'
import { SuccessScreen } from '../../../molecules/SuccessScreen'
import { INVITATION_LINK_SUCCESS } from './constants'

export const RegistrationByEmailSuccessScreen: FC = () => {
  return (
    <SuccessScreen
      title={INVITATION_LINK_SUCCESS.TITLE}
      subtitle={INVITATION_LINK_SUCCESS.SUBTITLE}
    />
  )
}
