import { AutoselectInputField } from '../../../../atoms/AutoselectInputField'
import { DependentField } from '../../../../atoms/DependentField'
import {
  MaskedInput,
  MaskedInputFieldProps
} from '../../../../atoms/MaskedInput'
import { FIELD_NAMES, CobuyerFieldsValues } from './constants'

export type DependentZipCodeFieldProps = MaskedInputFieldProps

export type CobuyerOptionsType = {
  status: 'Admin' | 'User' | 'Pending'
  id: string
  name: string
}
export type CobuyerEmailFieldProps = Parameters<
  typeof AutoselectInputField<CobuyerOptionsType>
>[0]

export const DependentZipCodeField = DependentField<
  MaskedInputFieldProps,
  CobuyerFieldsValues
>(MaskedInput, {
  deps: [FIELD_NAMES.ZIP_CODE]
})

export const DependentCobuyerEmailField = DependentField<
  CobuyerEmailFieldProps,
  CobuyerFieldsValues
>(AutoselectInputField<CobuyerOptionsType>, {
  deps: [FIELD_NAMES.COBUYER_EMAIL]
})
