import { ButtonProps } from '@mui/material'
import { FC, PropsWithChildren } from 'react'
import { CircleArrowIcon } from '../../../../assets/icons/CircleArrow'
import { BlueButton } from '../../../../atoms/Button'
import { COLORS } from '../../../../types/colors'

export const CarouselButton: FC<PropsWithChildren<ButtonProps>> = ({
  disabled,
  onClick,
  ...props
}) => {
  return (
    <BlueButton
      disabled={disabled}
      {...props}
      onClick={onClick}
      sx={{
        color: disabled ? COLORS.text_secondary : COLORS.text_blue
      }}
    >
      <CircleArrowIcon />
    </BlueButton>
  )
}
