import { Box, Divider, Grid, Link, Typography } from '@mui/material'
import { FC, ReactNode, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { DefaultVehicle } from '../../assets/imgs/DefaultVehicle'
import { COLORS, SHADOWS } from '../../types/colors'
import { useBackPath, useVehicleDetailsRoute } from '../../utils/hooks'
import { EllipsisText } from '../EllipsisText'
import { Show } from '../JSXExtensions'
import { VehicleBadge } from './VehicleBadge'

export interface VehicleComponentProps {
  id: string
  disabled?: boolean
  imageSource?: string
  title?: string
  titleDivider?: boolean
  year?: string | number
  make?: string
  model?: string
  trim?: string
  details: ReactNode
  badges?: Array<{
    position: string
    label: string
    color?: string
    background?: string
    component?: ReactNode
  }>
  navigateToNewTab?: boolean
  navigationURL?: string | ((id: string) => string)
}

export const VehicleComponent: FC<VehicleComponentProps> = (props) => {
  const {
    title,
    id,
    imageSource,
    year,
    make,
    model,
    trim,
    badges,
    titleDivider = true,
    details,
    disabled = false,
    navigateToNewTab = false,
    navigationURL
  } = props
  const { getPathToVehicle } = useVehicleDetailsRoute()
  const { currentLocation } = useBackPath()

  const vehicleTitle = useMemo(() => {
    return title || `${year} ${make} ${model}${trim ? ` ${trim}` : ''}`
  }, [title, year, make, model, trim])

  const navigationLink = useMemo(() => {
    if (navigationURL) {
      return typeof navigationURL === 'string'
        ? navigationURL
        : navigationURL(id)
    }
    return getPathToVehicle(id)
  }, [navigationURL, getPathToVehicle, id])

  return (
    <Grid
      container
      wrap='nowrap'
      sx={{
        background: COLORS.white,
        p: 2,
        ...(disabled && { opacity: 0.5, pointerEvents: 'none' }),
        borderRadius: '10px',
        boxShadow: SHADOWS.primary
      }}
    >
      <Grid item xs={6} position='relative'>
        <VehicleBadge badges={badges} />
        <Link
          underline='none'
          component={NavLink}
          to={navigationLink}
          target={navigateToNewTab ? '_blank' : '_self'}
          state={{ backPath: currentLocation }}
        >
          <Box
            sx={{
              aspectRatio: '16/9',
              background: imageSource
                ? `no-repeat center/cover url(${imageSource})`
                : `no-repeat center/cover ${COLORS.border_gray}`,
              borderRadius: '3px',
              height: 'auto',
              maxWidth: '100%',
            }}
          >
            <Show when={!imageSource}>
              <Box
                sx={{
                  height: '100%',
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <DefaultVehicle />
              </Box>
            </Show>
          </Box>
        </Link>
      </Grid>
      <Grid item xs={6} pl={2}>
        <Grid container flexDirection='column'>
          <Grid item xs={12}>
            <Typography variant='label1'>
              <EllipsisText tooltipText={vehicleTitle}>
                {vehicleTitle}
              </EllipsisText>
            </Typography>
          </Grid>
          <Show when={titleDivider}>
            <Grid item xs={12}>
              <Divider
                sx={{
                  my: 1
                }}
              />
            </Grid>
          </Show>
          <Grid item xs={12}>
            {details}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
