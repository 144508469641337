import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC, useMemo } from 'react';

import { For } from '../../../../../atoms/JSXExtensions';

const tableHeadersDesktop: { label: string; grid: number }[] = [
  { label: 'Make, Model, Trim', grid: 1 },
  { label: 'Year', grid: 1 },
  { label: 'Exterior/Interior Color', grid: 1 },
  { label: 'Trade In', grid: 1 },
  { label: 'Expires On', grid: 1 }
]

const tableHeadersTablet: { label: string; grid: number }[] = [
  { label: 'Make, Model, Year', grid: 6 },
  { label: 'Ext/Interior Color', grid: 6 },
  { label: 'Trade In', grid: 6 },
  { label: 'Expires On', grid: 6 }
]

export const RequestTableHeader: FC = () => {
  const theme = useTheme()
  const isBreakpointDownLg = useMediaQuery(theme.breakpoints.down('lg'))

  const tableHeaders = useMemo(
    () => (isBreakpointDownLg ? tableHeadersTablet : tableHeadersDesktop),
    [isBreakpointDownLg]
  )

  return (
    <Grid container item
      justifyContent='space-between'
      columns={{ xs: 12, md: 24, lg: 5 }}
    >
      <For of={tableHeaders}>
        {({ label, grid }) => (
          <Grid item xs={grid}>
            <Typography variant='subtitle2' textTransform='uppercase'>
              {label}
            </Typography>
          </Grid>
        )}
      </For>
    </Grid>
  )
}
