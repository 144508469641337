import { Utils } from '../../utils'
import { ResponseStatusMap } from '../../../types/status'
import type {
  ServiceResponse,
  CreateRequestParams,
  SubmitRequestParams,
  ReadRequestsParams,
  ReadRequestsResponse,
  ReadRequestParams,
  ReadRequestResponse
} from './types'
import { ErrorResponse, SuccessResponse } from '../../private.types'
import { normalizeReadRequestsPayload } from './utils'

export class Requests {
  protected readonly utils: Utils
  protected readonly operations = {
    createRequest: 'createRequest',
    submitRequest: 'submitRequest',
    readRequests: 'readRequests',
    readRequest: 'readRequest',
    cancelRequest: 'cancelRequest',
    cancelTransaction: 'cancelTransaction',
    declineTransaction: 'declineTransaction',
    declineAllRequests: 'declineAllRequests'
  }

  constructor(utils: Utils) {
    this.utils = utils
  }

  async createRequest(params: CreateRequestParams): Promise<ServiceResponse> {
    type SuccessResponse = {
      id: string
      message: string
    }

    type ErrorResponse = {
      id: string
      message: string
    }

    const dataToSend = {
      account_id: params.accountId,
      trade_in_vehicle: params.tradeinVehicle,
      make: params.make,
      model: params.model,
      year: params.year,
      trim: params.trim,
      exterior_color: params.exteriorColor,
      interior_color: params.interiorColor,
      notes: params.notes,
      deliver_to_address_id: params.deliverToAddressId
    }

    const { type, payload } = await this.utils.makeJSONRequest<
      SuccessResponse,
      ErrorResponse
    >(this.operations.createRequest, dataToSend)

    if (type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        id: payload.id,
        message: payload.message
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: payload.id,
      message: payload.message
    }
  }

  async submitRequest(params: SubmitRequestParams): Promise<ServiceResponse> {
    type SuccessResponse = {
      id: string
      message: string
    }

    type ErrorResponse = {
      id: string
      message: string
    }

    const dataToSend = {
      id: params.id,
      vehicles: params.vehicles.map(({ id, accountId }) => ({
        id,
        account_id: accountId
      }))
    }

    const { type, payload } = await this.utils.makeJSONRequest<
      SuccessResponse,
      ErrorResponse
    >(this.operations.submitRequest, dataToSend)

    if (type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        id: payload.id,
        message: payload.message
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: payload.id,
      message: payload.message
    }
  }

  async readRequests(params: ReadRequestsParams): Promise<
    | SuccessResponse<{
        payload: ReadRequestsResponse.Normilized.ReadRequestsResponse[]
      }>
    | ErrorResponse<{
        message: string
      }>
  > {
    type SuccessResponse = ReadRequestsResponse.Raw.ReadRequestsResponse[]

    type ErrorResponse = {
      id: string
      message: string
    }

    const dataToSend = {
      account_id: params.accountId
    }

    const { type, payload } = await this.utils.makeJSONRequest<
      SuccessResponse,
      ErrorResponse
    >(this.operations.readRequests, dataToSend)

    if (type === ResponseStatusMap.Success) {
      const normilizedPayload = normalizeReadRequestsPayload(payload)
      return {
        status: ResponseStatusMap.Success,
        payload: normilizedPayload
      }
    }

    return {
      status: ResponseStatusMap.Error,
      message: payload.message
    }
  }

  async readRequest(params: ReadRequestParams): Promise<
    | SuccessResponse<{
        payload: ReadRequestResponse.Normilized
      }>
    | ErrorResponse<{
        message: string
      }>
  > {
    type SuccessResponse = ReadRequestResponse.Raw

    type ErrorResponse = {
      id: string
      message: string
    }

    const dataToSend = {
      id: params.id
    }

    const { type, payload } = await this.utils.makeJSONRequest<
      SuccessResponse,
      ErrorResponse
    >(this.operations.readRequest, dataToSend)

    if (type === ResponseStatusMap.Success) {
      const {
        id,
        created_by: createdBy,
        created_on: createdOn,
        changed_by: changedBy,
        changed_on: changedOn,
        account_id: accountId,
        year,
        make,
        model,
        trim,
        exterior_color: exteriorColor,
        interior_color: interiorColor,
        notes,
        deleted,
        status,
        mileage,
        expires_on: expiresOn,
        trade_in_vehicle: tradeinVehicle,
        deliver_to_address_id: deliverToAddressId
      } = payload
      return {
        status: ResponseStatusMap.Success,
        payload: {
          id,
          createdBy,
          createdOn,
          changedBy,
          changedOn,
          accountId,
          year,
          make,
          model,
          trim,
          exteriorColor,
          interiorColor,
          notes,
          deleted,
          status,
          mileage,
          expiresOn,
          tradeinVehicle,
          deliverToAddressId
        }
      }
    }

    return {
      status: ResponseStatusMap.Error,
      message: payload.message
    }
  }

  async cancelTransaction({
    id
  }: {
    id: string
  }): Promise<
    | SuccessResponse<{ id: string; message: string }>
    | ErrorResponse<{ id: string; message: string }>
  > {
    type RequestResponse = {
      id: string
      message: string
    }

    const { type, payload } = await this.utils.makeJSONRequest<
      RequestResponse,
      RequestResponse
    >(this.operations.cancelTransaction, {
      transaction_id: id
    })

    if (type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        id: payload.id,
        message: payload.message
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: payload.id,
      message: payload.message
    }
  }

  async cancelRequest({
    id
  }: {
    id: string
  }): Promise<
    | SuccessResponse<{ id: string; message: string }>
    | ErrorResponse<{ id: string; message: string }>
  > {
    type RequestResponse = {
      id: string
      message: string
    }

    const { type, payload } = await this.utils.makeJSONRequest<
      RequestResponse,
      RequestResponse
    >(this.operations.cancelRequest, {
      request_id: id
    })

    if (type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        id: payload.id,
        message: payload.message
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: payload.id,
      message: payload.message
    }
  }

  async declineTransaction({
    id
  }: {
    id: string
  }): Promise<
    | SuccessResponse<{ id: string; message: string }>
    | ErrorResponse<{ id: string; message: string }>
  > {
    type RequestResponse = {
      id: string
      message: string
    }

    const { type, payload } = await this.utils.makeJSONRequest<
      RequestResponse,
      RequestResponse
    >(this.operations.declineTransaction, {
      transaction_id: id
    })

    if (type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        id: payload.id,
        message: payload.message
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: payload.id,
      message: payload.message
    }
  }

  async declineAllRequests(
    vehicleId: string
  ): Promise<
    | SuccessResponse<{ id: string; message: string }>
    | ErrorResponse<{ id: string; message: string }>
  > {
    type RequestResponse = {
      id: string
      message: string
    }

    const { type, payload } = await this.utils.makeJSONRequest<
      RequestResponse,
      RequestResponse
    >(this.operations.declineAllRequests, {
      vehicle_id: vehicleId
    })

    if (type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        id: payload.id,
        message: payload.message
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: payload.id,
      message: payload.message
    }
  }
}
