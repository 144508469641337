import { Grid, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { FC, ReactNode, useCallback, useMemo, useState } from 'react'
import { ContactSupportParams } from '../../api/notificationsApi/support/types'
import { ActionBlock } from '../../atoms/ActionBlock'
import { Show } from '../../atoms/JSXExtensions'
import { Loader } from '../../atoms/Loader'
import { NotesField } from '../../atoms/NotesField'
import { RequiredLabel } from '../../atoms/RequiredText'
import { TextInputFieldNew } from '../../atoms/TextInputFieldNew'
import { OperationResultScreen } from '../../molecules/OperationResultScreen'
import { OperationResultInfoType } from '../../molecules/OperationResultScreen/types'
import { sideBarActions } from '../../store/features/SideBar'
import { COLORS } from '../../types/colors'
import { ResponseStatusMap } from '../../types/status'
import {
  useApiContext,
  useAppDispatch,
  useMakeService
} from '../../utils/hooks'
import { isRequiredFormField } from '../../utils/validators/isRequiredFormField'
import {
  MAX_REASON_LENGTH,
  SUPPORT_FORM_LABELS,
  SUPPORT_FORM_NAMES,
  SupportFormFieldsTypes
} from './constants'
import { validationSchema } from './validationSchema'

type SupportFormProps = {
  email: string
  prefilledReason: string
  mainText: ReactNode
  buttonText: string
  icon: JSX.Element
}

export const SupportForm: FC<SupportFormProps> = ({
  email,
  prefilledReason,
  mainText,
  buttonText,
  icon
}) => {
  const dispatch = useAppDispatch()
  const [showSuccessScreen, setShowSuccessScreen] = useState(false)
  const { closeSideBar } = sideBarActions
  const { notificationsApi } = useApiContext()

  const [contactSupport, contactSupportObservable] = useMakeService(
    async (values: ContactSupportParams) => {
      const res = await notificationsApi.support.contactSupport(values)

      if (res.status === ResponseStatusMap.Success) {
        setShowSuccessScreen(true)
      }

      return res
    },
    {
      withStatusNotification: [1]
    }
  )

  const onCloseClick = useCallback(() => {
    dispatch(closeSideBar())
  }, [closeSideBar])

  const operationResultInfo: OperationResultInfoType = useMemo(
    () => ({
      mainText,
      onClick: onCloseClick,
      buttonText,
      icon
    }),
    [onCloseClick, icon, buttonText, mainText]
  )

  const isRequiredField = isRequiredFormField(validationSchema)

  return (
    <Show
      when={!contactSupportObservable.isLoading}
      fallback={
        <Grid container alignItems='center'>
          <Loader />
        </Grid>
      }
    >
      <Show
        when={!showSuccessScreen}
        fallback={
          <OperationResultScreen
            successOperationInfo={operationResultInfo}
            withoutHeader
            sx={{ mt: 4 }}
          />
        }
      >
        <Grid container flexDirection='column' rowSpacing={3} wrap='nowrap'>
          <Grid item>
            <Typography variant='main'>
              Get in touch and let us know how we can help
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='main'>Your contact email is</Typography>{' '}
            <Typography
              variant='main'
              fontWeight='500'
              color={COLORS.active_yellow}
            >
              {email}
            </Typography>
          </Grid>
          <Grid
            container
            item
            flexDirection='column'
            justifyContent='space-between'
            height='90%'
          >
            <Formik<SupportFormFieldsTypes>
              onSubmit={contactSupport}
              initialValues={{
                [SUPPORT_FORM_NAMES.REASON]: prefilledReason,
                [SUPPORT_FORM_NAMES.MESSAGE]: ''
              }}
              validateOnChange={false}
              validateOnMount
              enableReinitialize
              validationSchema={validationSchema}
            >
              {({ isValid, submitForm, values }) => (
                <>
                  <Grid item>
                    <Form>
                      <Grid container item spacing='16px'>
                        <Grid item xs={12}>
                          <RequiredLabel marginTop={0} />
                        </Grid>
                        <Grid item xs={12}>
                          <TextInputFieldNew
                            name={SUPPORT_FORM_NAMES.REASON}
                            label={SUPPORT_FORM_LABELS.REASON}
                            maxChars={MAX_REASON_LENGTH}
                            required={isRequiredField}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography component='p' variant='label1' my={2}>
                            Please describe your question below
                          </Typography>
                          <NotesField
                            name={SUPPORT_FORM_NAMES.MESSAGE}
                            label={SUPPORT_FORM_LABELS.MESSAGE}
                            required={isRequiredField}
                          />
                        </Grid>
                      </Grid>
                    </Form>
                  </Grid>
                  <Grid item>
                    <ActionBlock
                      pushToBottom
                      schema={[
                        {
                          label: 'close',
                          type: 'secondary',
                          onClick: onCloseClick
                        },
                        {
                          label: 'send',
                          type: 'primary',
                          disabled: !isValid || !values.reason,
                          onClick: submitForm
                        }
                      ]}
                    />
                  </Grid>
                </>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Show>
    </Show>
  )
}
