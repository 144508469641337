import * as Yup from 'yup'
import { latinWithSpecial } from '../../utils/validators'
import { notOnlySpaces } from '../../utils/validators/notOnlySpaces'

import { ERRORS } from './AddOn.constants'

export const validationPattern = Yup.array().of(
  Yup.object().shape({
    description: Yup.string()
      .test('is-latin-or-special', ERRORS.NOT_VALID, latinWithSpecial([' ']))
      .test('not-only-spaces', ERRORS.NOT_VALID, notOnlySpaces())
      .required('')
  })
)
