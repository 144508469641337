import {
  NormalizedReadTransactionsItemType,
  RawReadTransactionsItemType
} from '../types'

export const transactionsMapper = (
  transaction: RawReadTransactionsItemType
): NormalizedReadTransactionsItemType => {
  return {
    id: transaction.id,
    buyerID: transaction.buyer_id,
    sellerID: transaction.seller_id,
    status: transaction.status,
    vehicle: {
      id: transaction.vehicle.id,
      year: transaction.vehicle.year,
      make: transaction.vehicle.make,
      model: transaction.vehicle.model,
      trim: transaction.vehicle.trim,
      vin: transaction.vehicle.vin,
      stockNumber: transaction.vehicle.stock_number,
      mileage: transaction.vehicle.mileage,
      retailValue: transaction.vehicle.retail_value,
      documentContent: transaction.vehicle.document_content
    },
    offer: transaction.offer
      ? {
          id: transaction.offer.id,
          status: transaction.offer.status,
          expiresOn: new Date(transaction.offer.expires_on),
          totalPrice: transaction.offer.total_price
        }
      : null
  }
}
