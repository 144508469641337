import { FC, useState, useCallback, useMemo, MutableRefObject } from 'react'
import { Grid, Typography } from '@mui/material'
import { Condition } from '../Condition'
import { ResultScreen } from '../../molecules/ResultScreen'
import { useCountLines, useDeviceInfo } from '../../utils/hooks'
import { MAX_TEXT_ROWS_SIZE } from '../../constants/applicationConstants'
import { NoDocuments } from '../../assets/imgs/NoDocuments'
import { Show } from '../JSXExtensions'
import { LinkButton } from '../LinkButton'

interface CollapsibleNotesProps {
  children?: string | null
  initialExpanded?: boolean
  header?: JSX.Element
  fallback?: JSX.Element
  nonCollapsibleRows?: number
}

const DefaultFallback = (
  <ResultScreen
    inlined
    title=''
    icon={<NoDocuments />}
    subtitle='There are no notes for this request'
  />
)

export const CollapsibleNotes: FC<CollapsibleNotesProps> = ({
  children,
  initialExpanded = false,
  header = <Typography variant='h6'>Seller’s Notes</Typography>,
  fallback = DefaultFallback,
  nonCollapsibleRows = MAX_TEXT_ROWS_SIZE
}) => {
  const { isTablet } = useDeviceInfo()
  const childrenString = useMemo(
    () => (typeof children === 'string' ? children.trim() : ''),
    [children]
  )

  const initialShowExpanded = useMemo(
    () => isTablet || initialExpanded,
    [isTablet, initialExpanded]
  )
  const [numberOfLines, targetRef] = useCountLines(childrenString)
  const [showFullText, setShowFullText] = useState(initialShowExpanded)

  const toggleShowFullText = useCallback(
    () => setShowFullText((showText) => !showText),
    [setShowFullText]
  )
  const buttonText = useMemo(
    () => (showFullText ? 'Read less' : 'Read more'),
    [showFullText]
  )

  const textLineClamp = useMemo(
    () => (showFullText ? 'initial' : nonCollapsibleRows),
    [showFullText, nonCollapsibleRows]
  )

  return (
    <Grid item>
      {header}
      <Show when={childrenString} fallback={fallback}>
        <>
          <Typography
            variant='body1'
            ref={targetRef as MutableRefObject<HTMLElement>}
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: textLineClamp,
              lineHeight: 1.8,
              mt: 2,
              whiteSpace: 'break-spaces',
              wordBreak: 'normal',
              overflowWrap: 'anywhere'
            }}
          >
            {children}
          </Typography>
          <Condition
            condition={numberOfLines > nonCollapsibleRows && !isTablet}
            trueContent={
              <Grid item xs={12}>
                <LinkButton onClick={toggleShowFullText} label={buttonText} />
              </Grid>
            }
          />
        </>
      </Show>
    </Grid>
  )
}
