import { FC, ReactNode, useCallback } from 'react'
import { Grid } from '@mui/material'
import { useFormikContext } from 'formik'
import { For } from '../JSXExtensions'
import { RadioActionButton } from '../RadioToggle'

interface RadioActionOption {
  name: string
  image: ReactNode
  label?: string
}

interface RadioActionGroupProps {
  name: string
  options: RadioActionOption[]
}

export const RadioActionGroup: FC<RadioActionGroupProps> = ({
  name,
  options
}) => {
  const { setFieldValue, values } =
    useFormikContext<Record<typeof name, string>>()

  const onChange = useCallback(
    (value: string) => setFieldValue(name, value),
    [setFieldValue]
  )

  return (
    <Grid container spacing={3}>
      <For of={options}>
        {({ name: optionName, image, label }) => (
          <Grid item key={optionName} xs={4} lg={2}>
            <RadioActionButton
              onChange={onChange}
              name={optionName}
              image={image}
              checked={optionName === values[name]}
              label={label}
            />
          </Grid>
        )}
      </For>
    </Grid>
  )
}
