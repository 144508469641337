import { VehicleDetailsOverview } from '../../../VehicleDetails/components/VehicleDetailsOverview'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const FullVehicleDetailsOverviewExample = () => {
  return (
    <ComponentContainer>
      <ComponentWithPaperBackground title='Not in molecules folder (in VehicleDetails route)'>
        <VehicleDetailsOverview
          fullView
          fuelType='Petrol'
          engineDescription='AndUIE12ppppfsdfdsfdsffds'
          transmission='Automatic'
          mpg='123 cty / 112 hwy'
          style={null}
          interiorColor='Black'
          exteriorColor='Red'
          drivetrain={null}
        />
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}

export const OldVehicleDetailsOverviewExample = () => {
  return (
    <ComponentContainer fullWidth>
      <ComponentWithPaperBackground title='Not in molecules folder (in VehicleDetails route)'>
        <VehicleDetailsOverview
          fullView={false}
          interiorColor='Red'
          exteriorColor='Blue'
        />
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
