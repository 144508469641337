import { ResponseStatusMap, VehicleCondition } from '../../../types/status'
import { ErrorResponse, SuccessResponse } from '../../private.types'
import { Utils } from '../../utils'
import { formattedIntegerNumber } from '../../../utils/formatters/formattedIntegerNumber'
import { formatEmptyObjectToNull } from '../../../utils/formatters/formatEmptyObjectToNull'
import {
  ReadLicensePlateResponse,
  ReadVINResponse,
  ReadVINApiResponse,
  ReadLicensePlateApiResponse,
  ReadShippingCostParams,
  ReadShippingCostApiResponse,
  ReadShippingCostResponse,
  ReadShippingCostApiParams
} from './types'
import { formatNumber } from '../../../utils/formatters/formatNumber'

export class Vehicles {
  protected readonly utils: Utils
  protected readonly operations = {
    readVehicleValue: 'readVehicleValue',
    readVIN: 'readVIN',
    readLicensePlate: 'readLicensePlate',
    readCarfaxLink: 'readCarfaxLink',
    readShippingCost: 'readShippingCost'
  }

  constructor(utils: Utils) {
    this.utils = utils
  }

  async readVehicleValue({
    vin = '',
    make = '',
    model = '',
    year = '',
    trim = '',
    mileage,
    condition
  }: {
    vin?: string
    make?: string
    model?: string
    year?: string
    trim?: string
    mileage: string
    condition: VehicleCondition
  }): Promise<
    | SuccessResponse<{
        year: string
        make: string
        model: string
        tradeIn: number
        retail: number
      }>
    | ErrorResponse<{
        id?: string
        message: string
      }>
  > {
    type SuccessResponse = {
      year: string
      make: string
      model: string
      trade_in: number
      retail: number
    }

    type ErrorResponse = {
      id: string
      message: string
    }

    try {
      const { type, payload } = await this.utils.makeJSONRequest<
        SuccessResponse,
        ErrorResponse
      >(this.operations.readVehicleValue, {
        vin,
        make,
        model,
        year,
        trim,
        mileage: formattedIntegerNumber(mileage) || '',
        condition
      })

      if (type === ResponseStatusMap.Success) {
        return {
          status: ResponseStatusMap.Success,
          year: payload.year,
          make: payload.make,
          model: payload.model,
          tradeIn: payload.trade_in,
          retail: payload.retail
        }
      }
      return {
        status: ResponseStatusMap.Error,
        id: payload.id,
        message: payload.message
      }
    } catch {
      return {
        status: ResponseStatusMap.Error,
        message: 'Something went wrong'
      }
    }
  }

  async readVIN(options: { vin: string }): Promise<
    | SuccessResponse<ReadVINResponse>
    | ErrorResponse<{
        message: string
      }>
  > {
    type SuccessResponse = ReadVINApiResponse

    type ErrorResponse = {
      message: string
    }

    const response = await this.utils.makeJSONRequest<
      SuccessResponse,
      ErrorResponse
    >(this.operations.readVIN, options)

    if (response.type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        country: response.payload.country,
        uvc: response.payload.uvc,
        year: response.payload.year,
        make: response.payload.make,
        model: response.payload.model,
        trim: response.payload.trim,
        style: response.payload.style,
        class: response.payload.class,
        fuelType: response.payload.fuel_type,
        engineDescription: response.payload.engine_description,
        transmission: response.payload.transmission,
        mpg: response.payload.mpg,
        drivetrain: response.payload.drivetrain,
        standardEquipment: formatEmptyObjectToNull(
          response.payload.standard_equipment
        )
      }
    }

    return {
      status: ResponseStatusMap.Error,
      message: response.payload.message
    }
  }

  async readLicensePlate(options: {
    plate: string
    state: string
  }): Promise<
    | SuccessResponse<ReadLicensePlateResponse>
    | ErrorResponse<{ message: string }>
  > {
    type SuccessResponse = ReadLicensePlateApiResponse

    type ErrorResponse = {
      message: string
    }

    const response = await this.utils.makeJSONRequest<
      SuccessResponse,
      ErrorResponse
    >(this.operations.readLicensePlate, options)

    if (response.type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        state: response.payload.state,
        country: response.payload.country,
        vin: response.payload.vin,
        uvc: response.payload.uvc,
        year: response.payload.year,
        make: response.payload.make,
        model: response.payload.model,
        trim: response.payload.trim,
        style: response.payload.style,
        class: response.payload.class,
        fuelType: response.payload.fuel_type,
        engineDescription: response.payload.engine_description,
        transmission: response.payload.transmission,
        mpg: response.payload.mpg,
        drivetrain: response.payload.drivetrain,
        standardEquipment: formatEmptyObjectToNull(
          response.payload.standard_equipment
        )
      }
    }

    return {
      status: ResponseStatusMap.Error,
      message: response.payload.message
    }
  }

  async readCarfaxLink(vin: string): Promise<
    | SuccessResponse<{
        url: string
        message?: string
      }>
    | ErrorResponse<{
        message: string
        url?: string
      }>
  > {
    type SuccessResponse = {
      url: string
      message?: string
    }

    type ErrorResponse = {
      url?: string
      message: string
    }

    const response = await this.utils.makeJSONRequest<
      SuccessResponse,
      ErrorResponse
    >(this.operations.readCarfaxLink, {
      vin
    })

    if (response.type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        url: response.payload.url
      }
    }
    return {
      status: ResponseStatusMap.Error,
      message: response.payload.message,
      url: response.payload.url
    }
  }

  async readShippingCost(params: ReadShippingCostParams): Promise<
    | SuccessResponse<ReadShippingCostResponse>
    | ErrorResponse<{
        message: string
      }>
  > {
    type ErrorResponse = {
      message: string
    }

    const tradeinVehicle: ReadShippingCostApiParams['trade_in_vehicle'] =
      params.tradeinVehicle
        ? {
            vin: params.tradeinVehicle.vin,
            year: params.tradeinVehicle.year,
            make: params.tradeinVehicle.make,
            model: params.tradeinVehicle.model
          }
        : undefined

    const dataToSend: ReadShippingCostApiParams = {
      from_zip_code: params.fromZipCode,
      to_zip_code: params.toZipCode,
      vehicle: {
        vin: params.vehicle.vin,
        year: params.vehicle.year,
        make: params.vehicle.make,
        model: params.vehicle.model
      },
      trade_in_vehicle: tradeinVehicle
    }

    const response = await this.utils.makeJSONRequest<
      ReadShippingCostApiResponse,
      ErrorResponse
    >(this.operations.readShippingCost, dataToSend)

    if (response.type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        cost: Number(
          formatNumber(response.payload.cost, {
            includeDecimalPart: true,
            splitter: ''
          })
        ),
        distance: Number(
          formatNumber(response.payload.distance, {
            includeDecimalPart: true,
            splitter: ''
          })
        )
      }
    }
    return {
      status: ResponseStatusMap.Error,
      message: response.payload.message
    }
  }
}
