import { Ref, useCallback, useEffect, useRef, useState } from 'react'
import { OrNull } from '../../../types/OrNull'
import { useUserInfo } from '../../../utils/hooks'
import { BuyerDetailsRefTargetType } from '../forms/BuyerDetailsForm'
import { BuyerFieldsTypes } from '../forms/BuyerDetailsForm/types'
import { FormRefTargetType } from '../forms/CobuyerMicroform'
import {
  FIELD_NAMES,
  CobuyerFieldsValues
} from '../forms/CobuyerMicroform/constants'
import { PaymentFormRefTargetType } from '../forms/PaymentMicroform'
import { PaymentFormValues } from '../forms/PaymentMicroform/constants'

export interface IOfferForm {
  cobuyerMicroform: OrNull<CobuyerFieldsValues>
  buyerDetailsForm: BuyerFieldsTypes
  paymentMicroform: PaymentFormValues
  buyerEmail: string
}

interface IOfferFormControls {
  getFormValues(): IOfferForm | null
  paymentMicroformRef: Ref<PaymentFormRefTargetType>
  setCobuyerMicroformRef: (node: FormRefTargetType) => void
  setBuyerMicroformRef: (node: BuyerDetailsRefTargetType) => void
  isFormValid: boolean
}

export const useOfferForm = (): IOfferFormControls => {
  const [isCobuyerValid, setCobuyerValid] = useState(false)
  const [isBuyerValid, setBuyerValid] = useState(false)
  const { id: buyerId, isDealer } = useUserInfo()
  const [isCobuyerNotActive, setCobuyerActive] = useState(isDealer)

  const cobuyerMicroformRef = useRef<FormRefTargetType | null>(null)
  const buyerDetailsFormRef = useRef<BuyerDetailsRefTargetType | null>(null)
  const paymentMicroformRef = useRef<PaymentFormRefTargetType>(null)

  useEffect(() => {
    setCobuyerActive(isDealer)
  }, [setCobuyerActive, isDealer])

  const setCobuyerMicroformRef = useCallback(
    (node: FormRefTargetType) => {
      cobuyerMicroformRef.current = node
      if (cobuyerMicroformRef.current) {
        setCobuyerValid(cobuyerMicroformRef.current.isValid)
        setCobuyerActive(
          cobuyerMicroformRef.current?.values[FIELD_NAMES.ADD_COBUYER_TOGGLE]
        )
      }
    },
    [setCobuyerValid, setCobuyerActive]
  )
  const setBuyerMicroformRef = useCallback(
    (node: BuyerDetailsRefTargetType) => {
      buyerDetailsFormRef.current = node

      buyerDetailsFormRef.current &&
        setBuyerValid(buyerDetailsFormRef.current.isValid)
    },
    [setBuyerValid]
  )
  const getFormValues = useCallback(() => {
    const cobuyerMicroformValues = isCobuyerNotActive
      ? null
      : cobuyerMicroformRef.current?.values || null

    if (buyerDetailsFormRef.current && paymentMicroformRef.current) {
      return {
        cobuyerMicroform: cobuyerMicroformValues,
        buyerDetailsForm: buyerDetailsFormRef.current.values,
        paymentMicroform: paymentMicroformRef.current.values,
        buyerEmail: buyerId
      }
    }
    return null
  }, [isCobuyerNotActive])

  return {
    paymentMicroformRef,
    setCobuyerMicroformRef,
    setBuyerMicroformRef,
    getFormValues,
    isFormValid:
      isBuyerValid &&
      ((isCobuyerValid && !isCobuyerNotActive) || isCobuyerNotActive)
  }
}
