import { useCallback } from 'react'
import { useApiContext } from '../useApiContext'
import { useMakeService } from '../useMakeService'

export const useCheckStripe = () => {
  const { carSnoopApi } = useApiContext()

  const checkStripe = useCallback(async () => {
    const checkResult = await carSnoopApi.accounts.checkStripePaymentMethod()

    return checkResult
  }, [])

  const [checkStripeService, checkStripeObservable] =
    useMakeService(checkStripe)

  return { checkStripeObservable, checkStripeService }
}
