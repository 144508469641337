import { ChangeEvent, FC, useMemo } from 'react'
import { VehicleComponent } from '../VehicleComponent'
import { TradeInVehicleDetails } from '../TradeInVehicleDetails'
import { CustomRadio } from './CustomRadio'

export interface RadioVehicleComponentProps {
  id: string
  showControl: boolean
  imageSource?: string
  title?: string
  year?: string | number
  make?: string
  model?: string
  trim?: string
  checked: boolean | ((id: string) => boolean)
  onChange: (
    id: RadioVehicleComponentProps['id'],
    nextValue: boolean,
    event?: ChangeEvent<HTMLInputElement>
  ) => void
  mileage: number
  tradeInPrice: number
  stockNumber?: string
}

export const RadioVehicleComponent: FC<RadioVehicleComponentProps> = (
  props
) => {
  const {
    title,
    id,
    imageSource,
    year,
    make,
    model,
    trim,
    mileage,
    tradeInPrice,
    showControl,
    checked,
    stockNumber,
    onChange
  } = props

  const badges = useMemo(() => {
    if (!showControl) {
      return []
    }

    return [
      {
        position: 'top-left',
        label: 'Control',
        component: (
          <CustomRadio
            checked={typeof checked === 'boolean' ? checked : checked(id)}
            onChange={(event, nextValue) => onChange(id, nextValue, event)}
          />
        )
      }
    ]
  }, [checked, onChange, showControl])

  const details = useMemo(() => {
    return (
      <TradeInVehicleDetails
        mileage={mileage}
        tradeInValue={tradeInPrice}
        stockNumber={stockNumber}
      />
    )
  }, [mileage, tradeInPrice])

  return (
    <VehicleComponent
      id={id}
      title={title}
      year={year}
      make={make}
      model={model}
      trim={trim}
      imageSource={imageSource}
      details={details}
      badges={badges}
    />
  )
}
