import { FC, memo } from 'react'
import { AudioIcon } from '../../../assets/icons/AudioIcon'
import { UploadDocumentIcon } from '../../../assets/icons/UploadDocumentIcon'
import { FILE_EXTENSIONS } from '../../../constants/applicationConstants'
import { PLAYABLE_FILE_EXTENSIONS } from '../UploadFileComponent.constants'

interface IFileIconProps {
  fileExtension: FILE_EXTENSIONS | string
}

export const FileIcon: FC<IFileIconProps> = memo(({ fileExtension }) => {
  return PLAYABLE_FILE_EXTENSIONS.includes(fileExtension as FILE_EXTENSIONS) ? (
    <AudioIcon size='medium' />
  ) : (
    <UploadDocumentIcon />
  )
})
