import { createAsyncThunk } from '@reduxjs/toolkit'
import { carSnoopApi } from '../../../../api'
import { ReadYearsParams } from '../../../../api/carSnoopApi/vehicles'
import type { ReadYearsResponse } from './types'

export const readYearsFromNonDealerInventory = createAsyncThunk(
  'readYears/byModel',
  async (params: ReadYearsParams): Promise<ReadYearsResponse> => {
    const response = await carSnoopApi.vehicles.readYearsFromNonDealerInventory(params)

    if (response.status >= 200 && response.status < 300) {
      const data = await response.json()

      return {
        status: 'succeeded',
        data
      }
    }

    return {
      status: 'failed',
      data: []
    }
  }
)
