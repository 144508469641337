import { Grid, Typography } from '@mui/material'
import { FC, useMemo } from 'react'
import { For } from '../../../atoms/JSXExtensions'
import { formatPrice } from '../../../utils'
import { OfferDetailsWidgetProps } from '../types'
import { ItemRow } from './ItemRow'

interface OfferTotalsProps
  extends Pick<
    OfferDetailsWidgetProps,
    'totalPrice' | 'vehiclePrice' | 'salesTax'
  > {
  isPreviousOffer?: boolean
}

export const OfferTotals: FC<OfferTotalsProps> = ({
  totalPrice,
  salesTax,
  vehiclePrice,
  isPreviousOffer
}) => {
  const mainInfoList = useMemo(() => {
    return [
      {
        label: 'Total Price',
        value: (
          <Typography
            component='span'
            variant={isPreviousOffer ? 'label1' : 'title1'}
          >
            {formatPrice(Number(totalPrice), { includeDecimalPart: true })}
          </Typography>
        )
      },
      { label: 'Vehicle Price', value: vehiclePrice },
      { label: 'Sales Tax', value: salesTax }
    ]
  }, [totalPrice, vehiclePrice, salesTax])

  return (
    <Grid container flexDirection='column' rowSpacing={2}>
      <Grid item>
        <Typography variant='main' fontWeight='600'>
          Total calculation
        </Typography>
      </Grid>
      <For of={mainInfoList}>
        {({ label, value }) => (
          <Grid item key={label}>
            <ItemRow label={label} value={value} />
          </Grid>
        )}
      </For>
    </Grid>
  )
}
