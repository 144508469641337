import { Grid, Typography } from '@mui/material'
import { FC, useMemo } from 'react'
import { NormalizedReadTransactionsItemType } from '../../../../../api/carSnoopApi/transactions/types'
import { SpeedometerIcon } from '../../../../../assets/icons/Speedometer'
import { Badge } from '../../../../../atoms/Badge'
import { Condition } from '../../../../../atoms/Condition'
import { EllipsisText } from '../../../../../atoms/EllipsisText'
import { VehicleComponent } from '../../../../../atoms/VehicleComponent'
import { VehicleComponentDetailsItem } from '../../../../../atoms/VehicleComponentDetailsItem'
import { selectDealsPageState } from '../../../../../store/pages/Deals'
import { BADGE_COLORS } from '../../../../../types/colors'
import { DEAL_FLOW } from '../../../../../types/enums/dealFlow'
import { formatNumber, formatPrice } from '../../../../../utils'
import { useAppSelector, useUserInfo } from '../../../../../utils/hooks'
import { useStatusPathResolver } from '../../../../../utils/hooks/useStatusPathResolver'
import { closedRequestsStatuses } from '../../../constants'
import { DealStatusColorsMap } from './constants'

export interface DealCardProps {
  deal: NormalizedReadTransactionsItemType
}

const Details: FC<DealCardProps> = (props) => {
  const { deal } = props
  const { status, vehicle, offer } = deal
  const { mileage, stockNumber } = vehicle
  const { isDealer } = useUserInfo()
  const { isSellingView } = useAppSelector(selectDealsPageState)
  const formattedMileage = useMemo(() => {
    return `${formatNumber(mileage)} miles`
  }, [mileage])

  const { retailValue } = vehicle
  const formattedRetailValue = useMemo(() => {
    return `${formatPrice(retailValue || 0)}`
  }, [retailValue])

  const formattedTotalPrice = useMemo(() => {
    return `${formatPrice(Number(offer?.totalPrice) || 0, {
      includeDecimalPart: true
    })}`
  }, [offer?.totalPrice])

  const badgeColor = useMemo(() => {
    return DealStatusColorsMap[status]
  }, [status])

  const displayedInfo = useMemo(() => {
    const isClosedRequest = closedRequestsStatuses.includes(status)
    return {
      infoTitle: isClosedRequest ? 'Retail value: ' : 'Total price: ',
      infoData: isClosedRequest
        ? formattedRetailValue
        : formattedTotalPrice
    }
  }, [status, formattedRetailValue, formattedTotalPrice])

  return (
    <Grid container item flexDirection='column' spacing={1}>
      <Grid item container spacing={1} flexWrap='nowrap'>
        <Condition
          condition={!!stockNumber && isSellingView && isDealer}
          trueContent={
            <Grid container item flexWrap='nowrap' xs={6} spacing={0.5}>
              <Grid
                container
                item
                alignItems='center'
                flexWrap='nowrap'
                xs='auto'
              >
                <Typography variant='body2' color='textSecondary'>
                  №
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant='subtitle1'>
                  <EllipsisText tooltipText={stockNumber}>
                    {stockNumber}
                  </EllipsisText>
                </Typography>
              </Grid>
            </Grid>
          }
        />
        <Grid container item flexWrap='nowrap'>
          <Grid item display='flex' alignItems='center' sx={{ mr: 0.5 }}>
            <SpeedometerIcon />
          </Grid>
          <Grid item xs={6}>
            <Typography variant='subtitle1' whiteSpace='nowrap'>
              <EllipsisText tooltipText={formattedMileage}>
                {formattedMileage}
              </EllipsisText>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <VehicleComponentDetailsItem
        title={displayedInfo.infoTitle}
        value={displayedInfo.infoData}
      />
      <Grid item container>
        <Grid item>
          <Badge
            text={status}
            background={BADGE_COLORS[badgeColor].background}
            color={BADGE_COLORS[badgeColor].color}
            textSx={{ letterSpacing: 1 }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export const DealCard: FC<DealCardProps> = (props) => {
  const { deal } = props
  const { vehicle, status, id, sellerID } = deal
  const { accountID } = useUserInfo()
  const flow = useMemo(
    () => (sellerID === accountID ? DEAL_FLOW.SELL : DEAL_FLOW.BUY),
    [sellerID, accountID]
  )
  const { getPathByStatus } = useStatusPathResolver(status)

  const navigationURL = useMemo(() => {
    return getPathByStatus({ transactionId: id, flow })
  }, [id, getPathByStatus])

  return (
    <VehicleComponent
      {...vehicle}
      imageSource={vehicle.documentContent}
      details={<Details {...props} />}
      navigationURL={navigationURL}
    />
  )
}
