import { Grid } from '@mui/material'
import { FC } from 'react'
import { Divider } from '../../../../atoms/Divider'
import { NavigationBackButton } from '../../../../atoms/NavigationBackButton'
import { noop } from '../../../../utils'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const NavigationBackButtonExample: FC = () => {
  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <Grid container flexDirection='column' spacing={3} padding={3}>
          <Grid item>
            <NavigationBackButton
              buttonText='Back with arrow'
              onBackClick={noop}
            />
            <Divider />
          </Grid>
          <Grid item>
            <NavigationBackButton
              showBackButton={false}
              buttonText='Back without arrow'
              onBackClick={noop}
            />
            <Divider />
          </Grid>
          <Grid item>
            <NavigationBackButton
              buttonText='Different text variants'
              onBackClick={noop}
              textVariant='h6'
            />
            <Divider />
          </Grid>
        </Grid>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
