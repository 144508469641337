const specialCharactersList = [
  '!',
  '#',
  '$',
  '%',
  '&',
  "'",
  '(',
  ')',
  '*',
  '+',
  '-',
  '/',
  '=',
  '?',
  '^',
  '_',
  '`',
  '{',
  '|',
  '}',
  '~',
  '"',
  ',',
  '.',
  ':',
  ';',
  '<',
  '>',
  '@',
  '[',
  '\\',
  ']',
  '№'
]

export const latinWithSpecial =
  (allowList: Array<string> = [], includesDefaultSpecial: boolean = true) =>
  (value?: string) => {
    if (!value) {
      return true
    }
    const specialCharacters = value.replace(/[a-z\d]+/gi, '')
    const defaultSpecial = includesDefaultSpecial ? specialCharactersList : []
    const extraChars = [...defaultSpecial, ...allowList].reduce((acc, char) => {
      return acc.replace(new RegExp(`\\${char}`, 'g'), '')
    }, specialCharacters)

    return !extraChars
  }
