import { Grid, Typography } from '@mui/material'
import { FC, memo, useMemo } from 'react'
import { Show } from '../../../atoms/JSXExtensions'
import { COLORS } from '../../../types/colors'
import { formatNumber } from '../../../utils'

interface ITotalPrice {
  showTotalPrice: boolean
  totalPrice: string
}

export const TotalPrice: FC<ITotalPrice> = memo(
  ({ showTotalPrice, totalPrice }) => {
    const isNegative = +totalPrice < 0
    const totalPriceText = useMemo(() => {
      const formattedValue = formatNumber(+totalPrice, {
        start: '$',
        includeDecimalPart: true
      })

      return `Total: ${formattedValue}`
    }, [isNegative, totalPrice])

    return (
      <Show when={showTotalPrice}>
        <>
          <Grid
            item
            display='flex'
            justifyContent='flex-end'
            padding='6px 4px'
            sx={{
              background: isNegative
                ? COLORS.error_background
                : COLORS.hover_background_blue
            }}
            width='100%'
          >
            <Typography variant='body1'>{totalPriceText}</Typography>
          </Grid>
          <Show when={isNegative}>
            <Grid item>
              <Typography color={COLORS.text_red}>
                Total cost cannot be negative. Change the values or decline the
                request.
              </Typography>
            </Grid>
          </Show>
        </>
      </Show>
    )
  }
)
