import { Grid } from '@mui/material'
import { FC } from 'react'
import {
    AddButton,
    AgreeButton,
    BlueButton,
    CancelButton,
    ColoredButton,
    OrangeButton,
    RedButton,
    RemoveButton
} from '../../../../atoms/Button'
import { COLORS } from '../../../../types/colors'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const ButtonsExample: FC = () => {
  return (
    <ComponentContainer fullWidth>
      <ComponentWithPaperBackground>
        <Grid container spacing={1} alignItems='center'>
          <Grid item>
            <AgreeButton>Agree</AgreeButton>
          </Grid>
          <Grid item>
            <AgreeButton disabled>Disabled</AgreeButton>
          </Grid>
          <Grid item>
            <CancelButton>Cancel</CancelButton>
          </Grid>
          <Grid item>
            <ColoredButton customColor={COLORS.main_yellow}>
              Colored
            </ColoredButton>
          </Grid>
          <Grid item>
            <BlueButton>Blue</BlueButton>
          </Grid>
          <Grid item>
            <RedButton>Red</RedButton>
          </Grid>
          <Grid item>
            <OrangeButton>Orange</OrangeButton>
          </Grid>
          <Grid item>
            <AddButton>Add</AddButton>
          </Grid>
          <Grid item>
            <RemoveButton>Remove</RemoveButton>
          </Grid>
        </Grid>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
