import { FC, useCallback, useRef, Dispatch, SetStateAction } from 'react'
import { Grid, Typography } from '@mui/material'
import { Formik, Form, FormikHelpers, FormikProps } from 'formik'
import {
  ConfirmationModal,
  DiscardConfirmationModal
} from '../../../../molecules/Modal'
import { TextInputFieldNew } from '../../../../atoms/TextInputFieldNew'
import {
  useApiContext,
  useAppDispatch,
  useMakeService,
  useModal
} from '../../../../utils/hooks'
import { AddDocumentForm, FIELD, LABEL } from './types'
import { validationSchema } from './ValidationSchema'
import { actionsContractPage } from '../../../../store/pages/Contract'
import { useContractPageInfo } from '../../hooks/useContractPageInfo'
import { ResponseStatusMap } from '../../../../types/status'
import { CONTRACT_TABS } from '../../../../store/pages/Contract/contractPage'
import { isRequiredFormField } from '../../../../utils/validators/isRequiredFormField'
import { RequiredLabel } from '../../../../atoms/RequiredText'

interface AddDocumentModalProps {
  isOpen: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  onCancel: () => void
}
export const AddDocumentModal: FC<AddDocumentModalProps> = (props) => {
  const { isOpen, setOpen, onCancel } = props
  const dispatch = useAppDispatch()
  const { carSnoopApi } = useApiContext()
  const { readTransaction, contractID, transactionID } = useContractPageInfo()
  const addDocumentRef = useRef<FormikProps<AddDocumentForm>>(null)

  const handleDiscard = () => {
    onCancel()
    addDocumentRef.current?.resetForm()
  }

  const discardAddDocumentModal = useModal(handleDiscard)

  const [addContractDocument] = useMakeService(
    async (documentName: string) => {
      const response = await carSnoopApi.documents.addContractDocument({
        contractID,
        documentName
      })
      if (response.status === ResponseStatusMap.Success) {
        readTransaction({ id: transactionID })
        dispatch(actionsContractPage.setCurrentTab(CONTRACT_TABS.documents))

        return response
      }

      return response
    },
    { withStatusNotification: true }
  )

  const onSubmit = useCallback(
    async (
      values: AddDocumentForm,
      helpers: FormikHelpers<AddDocumentForm>
    ) => {
      await addContractDocument(values[FIELD.DOCUMENT_NAME])
      helpers.resetForm()
    },
    [addContractDocument]
  )

  const isRequiredField = isRequiredFormField(validationSchema)

  return (
    <>
      <Formik<AddDocumentForm>
        initialValues={{ [FIELD.DOCUMENT_NAME]: '' }}
        onSubmit={onSubmit}
        validateOnMount
        validateOnChange={false}
        validationSchema={validationSchema}
        innerRef={addDocumentRef}
      >
        {(formik) => (
          <Form>
            <ConfirmationModal
              sx={{ width: '500px' }}
              header={
                <Grid item>
                  <Typography variant='h6'>Add document</Typography>
                </Grid>
              }
              isOpen={isOpen}
              setIsOpen={setOpen}
              cancel={{
                text: 'Cancel',
                callback: discardAddDocumentModal.onOpen,
                closeModal: false
              }}
              submit={{
                text: 'Save',
                callback: formik.submitForm,
                closeModal: true,
                disabled: !formik.isValid || formik.isSubmitting
              }}
            >
              <Grid p={5} pb={2}>
                <Grid item xs={12} mb={2}>
                  <RequiredLabel marginTop={0} />
                </Grid>
                <TextInputFieldNew
                  name={FIELD.DOCUMENT_NAME}
                  label={LABEL.DOCUMENT_NAME}
                  required={isRequiredField}
                />
              </Grid>
            </ConfirmationModal>
          </Form>
        )}
      </Formik>
      <DiscardConfirmationModal {...discardAddDocumentModal} />
    </>
  )
}
