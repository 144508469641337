import { NormalizedTradeInType, RawTradeInType } from '../types'

export const tradeInMapper = (
  tradeIn: RawTradeInType
): NormalizedTradeInType => {
  return {
    id: tradeIn.id,
    year: tradeIn.year,
    make: tradeIn.make,
    model: tradeIn.model,
    trim: tradeIn.trim,
    vin: tradeIn.vin,
    stockNumber: tradeIn.stock_number,
    mileage: tradeIn.mileage,
    status: tradeIn.status,
    createdOn: new Date(tradeIn.created_on),
    createdBy: tradeIn.created_by,
    changedOn: tradeIn.changed_on ? new Date(tradeIn.changed_on) : null,
    changedBy: tradeIn.changed_by,
    condition: tradeIn.condition,
    accountID: tradeIn.account_id,
    addressID: tradeIn.address_id,
    retailValue: tradeIn.retail_value,
    tradeInValue: tradeIn.tradein_value,
    notes: tradeIn.notes,
    standardEquipment: tradeIn.standard_equipment,
    fuelType: tradeIn.fuel_type,
    engineDescription: tradeIn.engine_description,
    transmission: tradeIn.transmission,
    mpg: tradeIn.mpg,
    style: tradeIn.style,
    interiorColor: tradeIn.interior_color,
    exteriorColor: tradeIn.exterior_color,
    drivetrain: tradeIn.drivetrain,
    licensePlate: tradeIn.license_plate,
    licensePlateState: tradeIn.license_plate_state,
    deleted: tradeIn.deleted,
    city: tradeIn.city,
    state: tradeIn.state,
    zipCode: tradeIn.zip_code,
    amountOwed: tradeIn.amount_owed,
    documentContent: tradeIn.document_content
  }
}
