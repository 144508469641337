import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OrNull } from '../../../types/OrNull'
import { MediaMessageType, TextMessageType } from '../../../api/chat/types'
import { pageNavigation } from '../../../store/thunks/pageNavigation'
import { logout } from '../../../store/thunks/logout'

export interface ChatState {
  chatId: OrNull<string>
  messages: OrNull<Array<TextMessageType | MediaMessageType>>
}

export const initialState: ChatState = {
  chatId: null,
  messages: null
}

export const chatStoreSlice = createSlice({
  name: 'chatStoreSlice',
  initialState,
  reducers: {
    setChatId: (store, action: PayloadAction<string>) => {
      store.chatId = action.payload
    },
    setMessages: (
      store,
      action: PayloadAction<Array<TextMessageType | MediaMessageType>>
    ) => {
      store.messages = action.payload
    },
    resetMessages: (store) => {
      store.messages = null
    }
  },

  extraReducers: (builder) => {
    builder.addCase(pageNavigation.fulfilled, () => {
      return initialState
    })
    builder.addCase(logout.fulfilled, () => {
      return initialState
    })
  }
})
