import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { splitByProperty } from '../../../utils/splitByProperty'
import { SearchFormValues } from '../../../features/SearchInventory'
import { FiltersFormValues } from '../../../routes/Inventory/FilterInventory'
import { pageNavigation } from '../../thunks/pageNavigation'
import { VehicleStatusTypes } from '../../../api/carSnoopApi/statusTypes'
import { NormalizedReadInventoryItem } from '../../../api/carSnoopApi/accounts/types'

export const selectOptionsList = [
  'All',
  'For Sale',
  'Requested',
  'In Request',
  'Offered',
  'In Offer',
  'Under Contract',
  'Awaiting Payment',
  'Shipping'
]

export type SelectedStatus = typeof selectOptionsList[number]
export type VehiclesListType = NormalizedReadInventoryItem[]
export type VehiclesListMap = Record<SelectedStatus, VehiclesListType>
export type InventoryActionType = 'select' | 'search' | 'filter'

const splitSchema: { status: Array<VehicleStatusTypes> } = {
  status: selectOptionsList.slice(1) as Array<VehicleStatusTypes>
}

export const getVehicleListMap = (vehiclesList: VehiclesListType) => {
  return splitByProperty(vehiclesList, splitSchema).reduce(
    (acc, vehicleList, index) => {
      return Object.assign(acc, { [splitSchema.status[index]]: vehicleList })
    },
    { All: vehiclesList } as VehiclesListMap
  )
}

const getMatchedVehiclesList = (inventory: VehiclesListType) =>
  inventory.filter((item) => selectOptionsList.includes(item.status))

interface InventoryFiltersFormState {
  action: InventoryActionType
  selectedStatus: SelectedStatus
  searchFormValues: SearchFormValues
  filterFormValues: FiltersFormValues
  selectOptions: readonly SelectedStatus[]
  vehiclesList: VehiclesListType
  vehiclesListMap: VehiclesListMap
  displayList: VehiclesListType
}

export const initialState: InventoryFiltersFormState = {
  action: 'select',
  selectedStatus: 'All',
  searchFormValues: { search: '' },
  filterFormValues: {
    make: null,
    model: null,
    yearFrom: null,
    yearTo: null
  },
  selectOptions: selectOptionsList,
  vehiclesListMap: getVehicleListMap([]),
  vehiclesList: [],
  displayList: []
}

export const inventoryPageSlice = createSlice({
  name: 'inventoryPage',
  initialState,
  reducers: {
    setVehiclesList: (store, action: PayloadAction<VehiclesListType>) => {
      const inventory = getMatchedVehiclesList(action.payload)

      store.vehiclesList = inventory
      store.displayList = inventory
      store.vehiclesListMap = getVehicleListMap(inventory)
    },
    setSearchResults: (
      store,
      action: PayloadAction<{
        data: VehiclesListType
        formValues: SearchFormValues
      }>
    ) => {
      store.action = 'search'
      store.selectedStatus = 'All'
      store.displayList = getMatchedVehiclesList(action.payload.data)
      store.searchFormValues = action.payload.formValues
    },
    setFilterResults: (
      store,
      action: PayloadAction<{
        data: VehiclesListType
        formValues: InventoryFiltersFormState['filterFormValues']
      }>
    ) => {
      store.action = 'filter'
      store.displayList = getMatchedVehiclesList(action.payload.data)
      store.searchFormValues = initialState.searchFormValues
      store.filterFormValues = action.payload.formValues
    },
    setSelectResults: (
      store,
      action: PayloadAction<{ data: VehiclesListType; status: SelectedStatus }>
    ) => {
      store.action = 'select'
      store.selectedStatus = action.payload.status
      store.displayList = getMatchedVehiclesList(action.payload.data)
      store.searchFormValues = initialState.searchFormValues
      store.filterFormValues = initialState.filterFormValues
    },
    resetFilterResults: (store) => {
      store.action = 'select'
      store.displayList = store.vehiclesListMap[store.selectedStatus]
      store.searchFormValues = initialState.searchFormValues
      store.filterFormValues = initialState.filterFormValues
    },
    resetSelectedStatus: (store) => {
      store.selectedStatus = 'All'
    }
  },
  extraReducers: (builder) => {
    builder.addCase(pageNavigation.fulfilled, (store) => {
      return {
        ...initialState,
        selectedStatus: store.selectedStatus,
        vehiclesList: store.vehiclesList,
        displayList: store.displayList,
        vehiclesListMap: store.vehiclesListMap
      }
    })
  }
})
