import { styled } from '@mui/material'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import { SHADOWS } from '../../types/colors'

export const Accordion = styled(({ children, ...props }: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props}>
    {children}
  </MuiAccordion>
))(() => ({
  '&:before': {
    display: 'none'
  },
  '&:hover': {
    boxShadow: SHADOWS.requestHover,
    zIndex: 1
  },
  '& .MuiAccordionSummary-root': {
    alignItems: 'center'
  }
}))
