import { IconButton } from '@mui/material'
import { styled } from '@mui/system'
import { BORDERS, COLORS } from '../../types/colors'

export const IconButtonStyles = styled(IconButton)`
  width: 36px;
  height: 36px;
  background-color: ${COLORS.secondary_background};
  border: ${BORDERS.SM_GRAY};
  box-shadow: 1px 2px 2px rgba(217, 221, 228, 0.4);
`
