import { useCallback, useMemo } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { OfferDetailsWidgetProps } from '../../../features/OfferDetailsWidget'
import { routes } from '../../../routes'
import { IConfirmationModal, useUserInfo } from '../../../utils/hooks'

export const useOfferDetailsActions = (
  sellerID: string,
  editModal: IConfirmationModal,
  cancelModal: IConfirmationModal,
  declineModal: IConfirmationModal
): OfferDetailsWidgetProps['actions'] => {
  const { accountID } = useUserInfo()
  const { transactionId = '' } = useParams()
  const navigate = useNavigate()

  const isSeller = useMemo(() => sellerID === accountID, [sellerID, accountID])

  const onAcceptClick = useCallback(() => {
    navigate(generatePath(routes.offers.payment, { transactionId }))
  }, [transactionId])

  const onEditClick = useCallback(() => {
    editModal.setOpen(true)
  }, [])

  if (isSeller) {
    return {
      onEditClick,
      onCancelClick: cancelModal.onOpen
    }
  }

  return {
    onAcceptClick,
    onDeclineClick: declineModal.onOpen
  }
}
