import { Grid, styled } from '@mui/material'
import { COLORS } from '../../types/colors'

export const AutoselectOptionContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'selected'
}) <{ selected: boolean }>`
  padding: 12px 16px;
  background-color: ${({ selected }) =>
    selected ? COLORS.hover_background_light_yellow : ''};
  &:hover {
    background-color: ${({ selected }) =>
    selected
      ? COLORS.hover_background_light_yellow
      : COLORS.secondary_background};
  }
`
