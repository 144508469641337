import { Box, Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { COLORS } from '../../types/colors'
import { RequestStatus } from '../../types/status'
import { ErrorTextWithRetry } from '../ErrorTextWithRetry'
import { Spinner } from '../Spinner'

interface ManualDataGettingProps {
  status: RequestStatus
  onGetClick: () => void
  onRetryClick: () => void
  dataIcon: JSX.Element
  dataText: string
  // Children must be JSX.Element or 'return children' doesn't work. The same as in Show.tsx
  children?: JSX.Element
}

export const ManualDataGetting: FC<ManualDataGettingProps> = (props) => {
  const { status, children, dataIcon, dataText, onGetClick, onRetryClick } =
    props

  switch (status) {
    case 'idle':
      return (
        <Grid container item spacing={1} wrap='nowrap'>
          <Grid item>
            <Box display='flex' alignItems='center' height='100%'>
              {dataIcon}
            </Box>
          </Grid>

          <Grid item>
            <Typography
              variant='subtitle1'
              color={COLORS.text_blue}
              sx={{
                cursor: 'pointer',
                textDecoration: 'underline'
              }}
              onClick={onGetClick}
              noWrap
            >
              {dataText}
            </Typography>
          </Grid>
        </Grid>
      )
    case 'pending':
      return (
        <Grid container item alignItems='center' spacing={1}>
          <Grid item>
            <Spinner size={18} />
          </Grid>
          <Grid item>
            <Typography variant='subtitle1' color={COLORS.active_yellow}>
              Loading...
            </Typography>
          </Grid>
        </Grid>
      )
    case 'failed':
      return (
        <ErrorTextWithRetry
          errorText='Error.'
          retryButtonText='Retry'
          onRetryClick={onRetryClick}
          withRetryIcon
        />
      )
    case 'succeeded':
      return children || null

    default:
      return null
  }
}
