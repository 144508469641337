import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RegisterStep } from '../../../types/enums/RegisterStep'
import { UserTypes } from '../../../types/enums/UserTypes'
import {
  ContactDetails,
  PersonalDetails
} from '../../../types/forms/registration'
import { readLocationAsync } from '../../thunks/readLocationAsync'
import { RequestStatus } from '../../../types/status'

export type ExtrnalRegistrationStep = -1 | 0 | 1 | 2

interface externalServiceRegistration {
  length: number
  step: ExtrnalRegistrationStep
  [RegisterStep.stepOne]: PersonalDetails
  [RegisterStep.stepTwo]: ContactDetails
  network: {
    location: {
      status: RequestStatus
      error: string
      city: string
      state: string
    }
  }
}

export const initialState: externalServiceRegistration = {
  length: 3,
  step: RegisterStep.stepOne,
  [RegisterStep.stepOne]: {
    firstName: '',
    lastName: '',
    birthDate: null,
    zipCode: '',
    userType: UserTypes.BUYER,
    dealership: { id: '', name: '' }
  },
  [RegisterStep.stepTwo]: {
    email: '',
    phone: '',
    state: {
      id: '',
      name: ''
    },
    city: {
      id: '',
      name: ''
    }
  },
  network: {
    location: {
      status: 'idle',
      error: '',
      state: '',
      city: ''
    }
  }
}

export const externalServiceRegistrationSlice = createSlice({
  name: 'externalServiceRegistration',
  initialState,
  reducers: {
    incrementScreenStep: (state, action: PayloadAction<number | undefined>) => {
      const target = action.payload || 1
      const diff = state.step + target

      state.step += diff < state.length ? target : state.length
    },
    decrementScreenStep: (state, action: PayloadAction<number | undefined>) => {
      const target = action.payload || 1
      const diff = state.step - target

      if (diff >= -1) {
        state.step -= target
      }
    },
    setPersonalDetails: (state, action: PayloadAction<PersonalDetails>) => {
      state[RegisterStep.stepOne] = action.payload
    },
    setContactDetails: (state, action: PayloadAction<ContactDetails>) => {
      state[RegisterStep.stepTwo] = action.payload
    },
    resetState: () => {
      return { ...initialState }
    },
    resetLocation: (store) => {
      store.network.location = { ...initialState.network.location }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(readLocationAsync.pending, (store) => {
      store.network.location.status = 'pending'
      store.network.location.error = ''
    })
    builder.addCase(readLocationAsync.fulfilled, (store, action) => {
      store.network.location.status = 'succeeded'
      store.network.location.error = ''
      store.network.location.city = action.payload.city
      store.network.location.state = action.payload.state

      store[RegisterStep.stepTwo].state = {
        id: action.payload.state,
        name: action.payload.state
      }

      store[RegisterStep.stepTwo].city = {
        id: action.payload.city,
        name: action.payload.city
      }
    })
    builder.addCase(readLocationAsync.rejected, (store) => {
      store.network.location.status = 'failed'
      store.network.location.error = 'Enter a valid Zip Code.'
      store.network.location.city = initialState.network.location.city
      store.network.location.state = initialState.network.location.state
    })
  }
})
