import { Grid } from '@mui/material'
import { Form, Formik } from 'formik'
import { FILTER_CAR_FIELD_LABELS, FilterComponentProps } from './types'
import { AutoselectInputField } from '../../../atoms/AutoselectInputField'
import { AgreeButton, BlueButton } from '../../../atoms/Button'
import { FIND_CAR_FIELD_NAMES } from '../../../types/enums/findCar'
import { Option } from '../../../types/Autoselect'
import { AutoselectOption } from '../../../atoms/AutoselectOption'
import {
  DependentModelField,
  DependentYearFromField,
  DependentYearToField
} from './FilterDependentFields'
import { isOptionEqualToValue } from '../../../utils/autoselect/isOptionEqualToValue'
import { getOptionLabel } from '../../../utils/autoselect/getOptionLabel'
import { useDeviceInfo } from '../../../utils/hooks'
import { useFilterOptionEffects } from './useFilterOptionEffects'
import { NormalizedReadInventoryItem } from '../../../api/carSnoopApi/accounts/types'

export const FilterInventoryControl = <V extends NormalizedReadInventoryItem>(
  props: FilterComponentProps<V>
) => {
  const { isTablet } = useDeviceInfo()

  const {
    makeOptions,
    modelOptions,
    yearFromOptions,
    yearToOptions,
    modelFieldEffect,
    yearFromFieldEffect,
    yearToFieldEffect,
    onApplyFilters
  } = useFilterOptionEffects(props)

  const { initialValues, resetFilters, formRef, isClearDisabled } = props

  return (
    <Grid container alignContent='center' spacing={2} mb={1}>
      <Grid item xs={12}>
        <Formik
          initialValues={initialValues}
          onSubmit={onApplyFilters}
          validateOnMount
          enableReinitialize
          innerRef={formRef}
        >
          {(formik) => {
            return (
              <Form style={{ width: '100%' }}>
                <Grid
                  container
                  spacing={1}
                  columns={24}
                  wrap='nowrap'
                  alignItems='center'
                >
                  <Grid item xs={6} lg={7}>
                    <AutoselectInputField<Option>
                      renderOption={AutoselectOption}
                      name={FIND_CAR_FIELD_NAMES.MAKE}
                      label={FILTER_CAR_FIELD_LABELS.MAKE}
                      options={makeOptions}
                      isOptionEqualToValue={isOptionEqualToValue}
                      getOptionLabel={getOptionLabel}
                    />
                  </Grid>
                  <Grid item xs={6} lg={7} overflow='hidden'>
                    <DependentModelField
                      renderOption={AutoselectOption}
                      name={FIND_CAR_FIELD_NAMES.MODEL}
                      label={FILTER_CAR_FIELD_LABELS.MODEL}
                      options={modelOptions}
                      isOptionEqualToValue={isOptionEqualToValue}
                      getOptionLabel={getOptionLabel}
                      onChangeEffect={modelFieldEffect}
                    />
                  </Grid>
                  <Grid item xs={6} lg={5}>
                    <DependentYearFromField
                      renderOption={AutoselectOption}
                      name={FIND_CAR_FIELD_NAMES.YEAR_FROM}
                      label={FILTER_CAR_FIELD_LABELS.YEAR_FROM}
                      options={yearFromOptions}
                      isOptionEqualToValue={isOptionEqualToValue}
                      getOptionLabel={getOptionLabel}
                      onChangeEffect={yearFromFieldEffect}
                    />
                  </Grid>

                  <Grid item xs={6} lg={5}>
                    <DependentYearToField
                      renderOption={AutoselectOption}
                      name={FIND_CAR_FIELD_NAMES.YEAR_TO}
                      label={FILTER_CAR_FIELD_LABELS.YEAR_TO}
                      options={yearToOptions}
                      isOptionEqualToValue={isOptionEqualToValue}
                      getOptionLabel={getOptionLabel}
                      onChangeEffect={yearToFieldEffect}
                    />
                  </Grid>
                  <Grid item xs='auto' container spacing={isTablet ? 1 : 2}>
                    <Grid item>
                      <BlueButton
                        onClick={resetFilters}
                        disabled={isClearDisabled}
                      >
                        Clear All
                      </BlueButton>
                    </Grid>
                    <Grid item>
                      <AgreeButton type='submit' disabled={!formik.dirty}>
                        Apply
                      </AgreeButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )
          }}
        </Formik>
      </Grid>
    </Grid>
  )
}
