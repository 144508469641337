import { FC } from 'react'
import { AudioIcon } from '../../../../../../assets/icons/AudioIcon'
import { ChatFileMessageWrapperProps } from '../../types'
import { ChatMediaMessageContainer } from '../ChatMediaMessageContainer'

export const UnsupportedAudioMessage: FC<ChatFileMessageWrapperProps> = (
  props
) => {
  const icon = <AudioIcon />
  return <ChatMediaMessageContainer {...props} icon={icon} />
}
