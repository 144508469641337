import { FC } from 'react'
import { MainCarImg } from '../../assets/imgs/MainCarImg'
import { PromotionBlockText } from '../../types/enums/PromotionBlock'
import {
  PromotionBlockContainer,
  PromotionDescription,
  PromotionImage,
  PromotionTitle
} from './PromotionBlock.styles'

type PromotionBlockProps = {
  title?: string
  subtitle?: string
  description?: string
  image?: JSX.Element
}

export const PromotionBlock: FC<PromotionBlockProps> = ({
  title = PromotionBlockText.title,
  subtitle = PromotionBlockText.subtitle,
  description = PromotionBlockText.description,
  image = <MainCarImg />
}) => {
  return (
    <PromotionBlockContainer>
      <PromotionTitle>{title}</PromotionTitle>
      <PromotionTitle>{subtitle}</PromotionTitle>
      <PromotionDescription>{description}</PromotionDescription>
      <PromotionImage>{image}</PromotionImage>
    </PromotionBlockContainer>
  )
}
