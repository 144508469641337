import { Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { For, Show } from '../../../atoms/JSXExtensions'
import { NonNullableRecord } from '../../../types/NonNullableRecord'
import { OfferDetailsWidgetProps } from '../types'
import { ItemRow } from './ItemRow'

type OfferAddonsProps = NonNullableRecord<
  Pick<OfferDetailsWidgetProps, 'addons'>
>

export const OfferAddons: FC<OfferAddonsProps> = ({ addons }) => {
  return (
    <Show when={!!addons.length}>
      <Grid item container flexDirection='column' rowSpacing={2}>
        <Grid item>
          <Typography variant='main' fontWeight='600'>
            Add-ons
          </Typography>
        </Grid>
        <For of={addons}>
          {({ description, price, quantity }) => (
            <Grid item key={description}>
              <ItemRow label={description} value={price} quantity={quantity} />
            </Grid>
          )}
        </For>
      </Grid>
    </Show>
  )
}
