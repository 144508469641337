import { ButtonProps, Grid, Typography } from '@mui/material'
import { useMemo } from 'react'
import { LinkButtonWithDisable } from '../../../../atoms/Button'
import { EllipsisText } from '../../../../atoms/EllipsisText'
import { UserInfoState } from '../../../../store/types'
import { formatNumber, noop } from '../../../../utils'
import { currentSubscriptionStyles } from './CurrentSubscription.styles'

const correctDealWording = (dealsCount: number) => {
  return dealsCount === 1 ? 'Deal' : 'Deals'
}
const modifyPrice = (price: number) =>
  price ? `$${formatNumber(price)}` : 'free'

export interface CurrentSubscriptionProps {
  isDefault: boolean
  disabled?: boolean
  subscription: UserInfoState['subscription']
  onDeactivate?: ButtonProps['onClick']
}

export const CurrentSubscription = (props: CurrentSubscriptionProps) => {
  const {
    subscription,
    isDefault,
    disabled = false,
    onDeactivate = noop
  } = props

  const { maxDeals, completedDeals, price, name } = subscription

  const { dealsPerMonthText, dealsLeftText } = useMemo(() => {
    if (maxDeals !== null) {
      return {
        dealsPerMonthText: `${maxDeals} ${correctDealWording(
          +maxDeals
        )} per month/`,
        dealsLeftText: `${completedDeals} ${correctDealWording(
          completedDeals
        )} this month`
      }
    }

    return {
      dealsPerMonthText: 'Unlimited/',
      dealsLeftText: `${completedDeals} ${correctDealWording(
        completedDeals
      )} this month`
    }
  }, [maxDeals, completedDeals])

  const currentSubscriptionAction = useMemo(() => {
    return isDefault ? (
      <Typography variant='main' fontStyle='italic'>
        Default plan
      </Typography>
    ) : (
      <LinkButtonWithDisable onClick={onDeactivate} disabled={disabled}>
        <Typography fontSize='16px' fontStyle='italic' color='inherit' noWrap>
          Deactivate plan
        </Typography>
      </LinkButtonWithDisable>
    )
  }, [isDefault, onDeactivate, completedDeals])

  return (
    <Grid sx={currentSubscriptionStyles}>
      <Grid
        container
        direction='column'
        height='50%'
        justifyContent='space-between'
      >
        <Typography variant='main' fontStyle='italic'>
          {name}
        </Typography>
        <Grid item>
          <Typography variant='label1'>{dealsPerMonthText}</Typography>
          <Typography variant='main' fontStyle='italic'>
            {modifyPrice(price)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item justifyContent='space-between' wrap='nowrap'>
        <Typography variant='main' noWrap>
          <EllipsisText tooltipText={dealsLeftText}>
            {dealsLeftText}
          </EllipsisText>
        </Typography>
        {currentSubscriptionAction}
      </Grid>
    </Grid>
  )
}
