import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Grid, Typography } from '@mui/material'
import { format, parse } from 'date-fns'
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useMemo
} from 'react'
import { DriverLicenseFileType } from '../../../api/carSnoopApi/users/types'
import { CarIcon } from '../../../assets/icons/CarIcon'
import { PenIcon } from '../../../assets/icons/Pen'
import { BlueButton } from '../../../atoms/Button'
import { Condition } from '../../../atoms/Condition'
import { IconText } from '../../../atoms/IconText'
import { Show } from '../../../atoms/JSXExtensions'
import {
  MMDDYYYYSlash,
  YYYYMMDD
} from '../../../constants/applicationConstants'
import { DocumentInfoCard } from './DocumentInfoCard'

export interface EmptyStateProps {
  setEditCallback: () => void
}

export interface FullStateProps {
  fullName: string
  licenseNumber: string
  state: string
  licenseDocument?: DriverLicenseFileType
  expirationDate: string
  setEditCallback: () => void
}

const EmptyState: FC<PropsWithChildren<EmptyStateProps>> = ({
  setEditCallback
}) => {
  return (
    <Grid container spacing={2}>
      <Grid container item xs={12}>
        <Typography variant='label1'>Driver’s License</Typography>
      </Grid>
      <Grid item xs={12}>
        <BlueButton
          startIcon={<AddCircleOutlineIcon />}
          onClick={setEditCallback}
        >
          Add Driver’s License
        </BlueButton>
      </Grid>
    </Grid>
  )
}

const FullyState: FC<PropsWithChildren<FullStateProps>> = (props) => {
  const {
    fullName,
    licenseNumber,
    state,
    expirationDate,
    licenseDocument,
    setEditCallback
  } = props

  const licenceNumberString = useMemo(
    () => !!licenseNumber && `DLN ${licenseNumber}`,
    [licenseNumber]
  )

  const expirationDateString = useMemo(
    () => format(parse(expirationDate, YYYYMMDD, new Date()), MMDDYYYYSlash),
    [expirationDate]
  )

  return (
    <>
      <Grid
        container
        item
        xs={12}
        justifyContent='space-between'
        alignItems='center'
        wrap='nowrap'
        sx={{ mb: 2 }}
      >
        <Typography variant='label1'>Driver’s License</Typography>
        <BlueButton onClick={setEditCallback}>
          <PenIcon />
        </BlueButton>
      </Grid>
      <Grid container item xs={12} spacing='8px'>
        <Grid item xs={12} sx={{ mb: 1 }}>
          {fullName}
        </Grid>
        <Grid item xs={12}>
          <IconText icon={<CarIcon />}>{licenceNumberString}</IconText>
        </Grid>
        <Grid item xs={12}>
          {state}
        </Grid>
        <Grid item xs={12}>
          {`Exp. ${expirationDateString}`}
        </Grid>
        <Grid item xs={12} my={1}>
          <Show when={licenseDocument}>
            {(document) => (
              <DocumentInfoCard
                name="Driver's License"
                type={document.type}
                createdOn={document.createdOn}
                link={document.link}
              />
            )}
          </Show>
        </Grid>
      </Grid>
    </>
  )
}

type DisplayDriverLicenseCardProps = Omit<
  Partial<FullStateProps>,
  'setEditCallback'
> & {
  setEditMode: Dispatch<SetStateAction<boolean>>
}

export const DisplayDriverLicenseCard: FC<
  PropsWithChildren<DisplayDriverLicenseCardProps>
> = ({
  fullName,
  licenseNumber,
  state,
  expirationDate,
  licenseDocument,
  setEditMode
}) => {
    const setEditCallback = useCallback(() => {
      setEditMode(true)
    }, [setEditMode])

    return (
      <Condition
        condition={!!licenseNumber}
        trueContent={
          <FullyState
            fullName={fullName as string}
            licenseNumber={licenseNumber as string}
            expirationDate={expirationDate as string}
            state={state as string}
            licenseDocument={licenseDocument}
            setEditCallback={setEditCallback}
          />
        }
        falseContent={<EmptyState setEditCallback={setEditCallback} />}
      />
    )
  }
