import {
  FC,
  PropsWithChildren,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo
} from 'react'
import { FormikProps, FormikValues } from 'formik'
import { MaskedInput } from '../../../atoms/MaskedInput'
import { validationSchema } from './validation'
import { EditPersonalInformationContainer } from '../../../atoms/EditPersonalInformationContainer'
import { MaskValues } from '../../../types/maskValues'
import { isValidForm } from '../../../utils/isValidForm'
import { isRequiredFormField } from '../../../utils/validators/isRequiredFormField'

export interface EditSSNInformationProps {
  ssn?: string | null
  setIsEditMode: Dispatch<SetStateAction<boolean>>
  submitChangeSSN: (ssn: string) => void | Promise<unknown>
  deleteSSN: () => void | Promise<void>
}

export const ssnMaskPattern = [
  MaskValues.digit,
  MaskValues.digit,
  MaskValues.digit,
  ' ',
  MaskValues.dash,
  ' ',
  MaskValues.digit,
  MaskValues.digit,
  ' ',
  MaskValues.dash,
  ' ',
  MaskValues.digit,
  MaskValues.digit,
  MaskValues.digit,
  MaskValues.digit
]

export const EditSSNInformation: FC<
  PropsWithChildren<EditSSNInformationProps>
> = ({ ssn, submitChangeSSN, deleteSSN, setIsEditMode }) => {
  const onSubmit = useCallback(
    (formikValues: FormikValues) => {
      submitChangeSSN(formikValues.ssn.match(/\d/g)?.join('') || '')
    },
    [submitChangeSSN]
  )

  const onCancel = useCallback(() => {
    setIsEditMode(false)
  }, [setIsEditMode])

  const isSubmitButtonDisabled = useCallback(
    (formik: FormikProps<FormikValues>) =>
      !isValidForm(formik, { requiredFields: ['ssn'] }),
    []
  )

  const editContainerProps = useMemo(
    () => ({
      ...(ssn && { deleteAction: { label: 'Delete SSN', onClick: deleteSSN } })
    }),
    [deleteSSN, ssn]
  )

  const isRequiredField = isRequiredFormField(validationSchema)

  return (
    <EditPersonalInformationContainer
      title='SSN'
      initialValues={{
        ssn: ssn || ''
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onCancel={onCancel}
      isSubmitDisabled={isSubmitButtonDisabled}
      disableAutocomplete
      {...editContainerProps}
    >
      <MaskedInput
        name='ssn'
        label='Social Security Number'
        mask={ssnMaskPattern}
        hiddable
        required={isRequiredField}
      />
    </EditPersonalInformationContainer>
  )
}
