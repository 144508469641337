import { useRef } from 'react'
import { Renovator } from './Renovator'

export const useRenovator = (
  params: ConstructorParameters<typeof Renovator>[0]
) => {
  const renovator = useRef<Renovator>()

  if (!renovator.current) {
    renovator.current = new Renovator(params)
  }

  return {
    renovator: renovator.current
  }
}
