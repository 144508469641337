import * as Yup from 'yup'
import {
  isValidEmail,
  latinWithSpecial,
  nonSpace
} from '../../../utils/validators'
import { passwordLength } from '../../../constants/applicationConstants'

export const validationSchema = Yup.object({
  email: Yup.string()
    .test('is-email', 'Enter a valid email address.', isValidEmail())
    .test(
      'is-latin-or-special',
      'Enter a valid email address.',
      latinWithSpecial()
    ),
  password: Yup.string()
    .min(
      passwordLength.min,
      `Minimal length of the password is ${passwordLength.min}, maximum length is ${passwordLength.max}.`
    )
    .max(
      passwordLength.max,
      `Minimal length of the password is ${passwordLength.min}, maximum length is ${passwordLength.max}.`
    )
    .test('space', 'Password must not contain spaces.', nonSpace)
    .test('is-latin-or-special', 'Enter a valid password.', latinWithSpecial()),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Password does not match.'
  ),
  terms: Yup.boolean().oneOf([true], '')
})
