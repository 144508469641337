import { FC, PropsWithChildren, ReactNode } from 'react'
import { Box, Grid } from '@mui/material'
import { Condition } from '../Condition'
import { useDeviceInfo } from '../../utils/hooks'
import { VehicleItem } from '../../molecules/VehicleItem'
import { DefaultEmptyState } from '../DefaultEmptyState'
import { NormalizedReadInventoryItem } from '../../api/carSnoopApi/accounts/types'
import {
  desktopSizes,
  fiveColumns,
  tabletSizes,
  threeColumns
} from './constants'

type VehiclesListProps = {
  vehiclesList: NormalizedReadInventoryItem[]
  noVehiclesComponent?: ReactNode
  before?: ReactNode
  allowDealsRedirect?: boolean
}

const EmptyState: FC<PropsWithChildren> = ({ children }) => {
  const { isTablet } = useDeviceInfo()
  const sizes = isTablet ? tabletSizes : desktopSizes

  return (
    <Grid
      container
      justifyContent='center'
      alignContent='center'
      marginTop={8}
      minHeight={sizes.container}
      overflow='hidden'
    >
      <Box
        sx={{
          height: sizes.height,
          width: sizes.width
        }}
      >
        {children}
      </Box>
    </Grid>
  )
}

export const VehiclesList: FC<VehiclesListProps> = ({
  vehiclesList,
  noVehiclesComponent = <DefaultEmptyState />,
  before,
  allowDealsRedirect = false
}) => {
  return (
    <Grid container spacing={2}>
      <Condition
        condition={!!before}
        trueContent={
          <Grid item xs={12}>
            {before}
          </Grid>
        }
      />
      <Grid item xs={12}>
        <Condition
          condition={!vehiclesList.length}
          trueContent={<EmptyState>{noVehiclesComponent}</EmptyState>}
          falseContent={
            <Grid container spacing={1}>
              {vehiclesList.map(
                ({
                  id,
                  year,
                  make,
                  documentContent,
                  model,
                  trim,
                  ...vehicle
                }) => (
                  <Grid item key={id} lg={fiveColumns} md={threeColumns}>
                    <VehicleItem
                      id={id}
                      title={`${year} ${make} ${model} ${trim}`}
                      imageSource={documentContent}
                      allowDealsRedirect={allowDealsRedirect}
                      {...vehicle}
                    />
                  </Grid>
                )
              )}
            </Grid>
          }
        />
      </Grid>
    </Grid>
  )
}
