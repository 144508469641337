import { Grid } from '@mui/material'
import { FC, PropsWithChildren, useMemo } from 'react'
import { FormContainer } from '../../layout/FormContainer'
import { ScreenContainer } from '../../layout/ScreenContainer'
import { ResultScreenBody } from './ResultScreenBody'

export type ResultScreenProps = {
  title?: string
  subtitle?: string
  icon: JSX.Element
  description?: string
  isLoading?: boolean
  excludeLoadingTitle?: boolean
  inlined?: boolean
} & PropsWithChildren

// TODO: ui-components Add to Components demo page (optional)
export const ResultScreen: FC<ResultScreenProps> = (props) => {
  const {
    title = '',
    subtitle = '',
    icon,
    description,
    isLoading,
    excludeLoadingTitle,
    inlined,
    children
  } = props

  const formTitle = useMemo(
    () => (excludeLoadingTitle && isLoading ? '' : title),
    [excludeLoadingTitle, isLoading]
  )

  return (
    <FormContainer
      title={formTitle}
      minHeight={{
        xs: '100%',
        md: inlined ? '100%' : '470px'
      }}
      inlined={inlined}
    >
      <ScreenContainer isLoading={isLoading}>
        <ResultScreenBody
          icon={icon}
          subtitle={subtitle}
          description={description}
        />
        <Grid container item flexGrow={1}>
          {children}
        </Grid>
      </ScreenContainer>
    </FormContainer>
  )
}
