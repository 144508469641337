import yup from 'yup'
import { TypedSchema } from 'yup/lib/util/types'
import { getIn } from 'formik'

export const isRequiredFormField =
  (validationSchema: yup.InferType<TypedSchema>) =>
  (name: string): boolean => {
    const schemaDescription = validationSchema?.describe()
    const accessor = name.split('.').join('.fields.')
    const field = getIn(schemaDescription?.fields, accessor)

    if (!field) {
      return false
    }
    const isRequired = field?.tests.some(
      (test: Record<string, string>) => test.name === 'required'
    )

    return isRequired
  }
