import { NormalizedOfferType, RawOfferType } from '../types'

const formatAddons = (
  addons: RawOfferType['add_ons']
): NormalizedOfferType['addons'] => {
  if (!addons.length) {
    return null
  }

  return addons.map((addOn, index) => ({
    ...addOn,
    price: addOn.price.toString(),
    id: index
  }))
}

export const offerMapper = (offer: RawOfferType): NormalizedOfferType => {
  return {
    id: offer.id,
    status: offer.status,
    createdBy: offer.created_by,
    createdOn: new Date(offer.created_on),
    changedBy: offer.changed_by,
    changedOn: offer.changed_on ? new Date(offer.changed_on) : null,
    expiresOn: offer.expires_on ? new Date(offer.expires_on) : null,
    transactionID: offer.transaction_id,
    totalPrice: offer.total_price,
    priceComponents: offer.price_components,
    addons: formatAddons(offer.add_ons),
    notes: offer.notes
  }
}
