import { useState } from 'react'
import { AddressCard } from '../../../PersonalDetails/Addresses/components/AddressCard'
import type { Address } from '../../../../store/types'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

const address: Address = {
  id: '1',
  nickname: 'Street of big love',
  state: 'UK',
  city: 'London',
  street: 'Alexandra Gray Street 69',
  zipCode: '46077'
}

export const AddressCardExample = () => {
  const [mode, setMode] = useState<'edit' | 'view'>('view')

  const handleEditClick = () => {
    setMode('edit')
  }

  const handleCancelClick = () => {
    setMode('view')
  }

  return (
    <ComponentContainer>
      <ComponentWithPaperBackground title='Not in molecules folder (in routes/PersonalDetails) - (looks like feature component)'>
        <AddressCard
          mode='create'
          address={address}
          onEditClick={handleEditClick}
          onCancelClick={handleCancelClick}
          isUserAdmin
        />
        <AddressCard
          mode={mode}
          address={address}
          onEditClick={handleEditClick}
          onCancelClick={handleCancelClick}
          isUserAdmin
        />
        <AddressCard
          mode={mode}
          address={{ ...address, nickname: '(☞ﾟヮﾟ)☞' }}
          onEditClick={handleEditClick}
          onCancelClick={handleCancelClick}
        />
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
