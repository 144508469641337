import { FC } from 'react'
import { Typography } from '@mui/material'
import { UsePaginationItem } from '@mui/material/usePagination'
import { PaginationButton, PaginationControls } from './PaginationItem.styles'

interface PaginationItemProps {
  item: UsePaginationItem
}

export const PaginationItem: FC<PaginationItemProps> = ({ item }) => {
  const { page, type, selected, disabled, ...props } = item

  switch (type) {
    case 'start-ellipsis':
    case 'end-ellipsis':
      return <Typography variant='body1'>…</Typography>
    case 'page':
      return (
        <PaginationButton type='button' isSelected={selected} {...props}>
          <Typography variant='body1'>{page}</Typography>
        </PaginationButton>
      )
    default:
      return (
        <PaginationControls type='button' disabled={disabled} {...props}>
          <Typography variant='pagination'>{type.slice(0, 4)}</Typography>
        </PaginationControls>
      )
  }
}
