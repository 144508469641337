import { Spinner } from '../../../../atoms/Spinner'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const SpinnerExample = () => {
  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <Spinner />
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
