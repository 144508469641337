import { RequestedVehicleItem } from '../../../../molecules/RequestedVehicleItem'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const RequestedVehicleItemExample = () => {
  const vehicle = {
    make: 'BMW',
    model: 'X6',
    trim: 'Bo-o-o-o',
    years: ['2020', '2022', '2023'],
    interiorColors: [
      { name: 'Red', id: 'Red' },
      { name: 'Pink', id: 'Pink' },
      { name: 'Gray', id: 'Gray' }
    ],
    exteriorColors: [{ name: 'Blue', id: 'Blue' }]
  }
  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <RequestedVehicleItem vehicle={vehicle} />
        <RequestedVehicleItem vehicle={{ ...vehicle, years: ['2020'] }} />
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
