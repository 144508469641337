import { Button, Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { CloseIcon } from '../../../../assets/icons/Close'
import { BGCOLORS } from '../../../../types/colors'

interface MiniChatHeaderProps {
  onClose: () => void
}

export const MiniChatHeader: FC<MiniChatHeaderProps> = ({ onClose }) => {
  return (
    <Grid
      item
      container
      justifyContent='space-between'
      height={80}
      alignItems='center'
      p={3}
      sx={{
        backgroundColor: BGCOLORS.white
      }}
    >
      <Grid item>
        <Typography variant='h6'>All chats</Typography>
      </Grid>
      <Grid item>
        <Button
          disableElevation
          variant='text'
          sx={{ minWidth: 0, padding: 0 }}
          onClick={onClose}
        >
          <CloseIcon />
        </Button>
      </Grid>
    </Grid>
  )
}
