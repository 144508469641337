import { Grid, Paper, styled } from '@mui/material'
import { COLORS } from '../../../../types/colors'

export const PaperContainer = styled(Paper)(() => ({
  width: '278px',
  borderRadius: '10px',
  overflow: 'hidden',
  '&:hover': {
    cursor: 'pointer'
  }
}))

export const CardHeader = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'isChosen'
})<{ isChosen: boolean }>(({ isChosen }) => ({
  background: isChosen ? COLORS.main_yellow : COLORS.border_gray,
  padding: '28px 24px 20px'
}))
