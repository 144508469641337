import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { pageNavigation } from '../../thunks/pageNavigation'

export type FormFlow = 'License Plate' | 'VIN' | 'Older Car'

interface AddVehicleFormState {
  flow: FormFlow
  newVehicleId: string
  isAddVehicleModalOpen: boolean
}

export const initialState: AddVehicleFormState = {
  flow: 'License Plate',
  newVehicleId: '',
  isAddVehicleModalOpen: false
}

export const addVehicleFormSlice = createSlice({
  name: 'addVehicleForm',
  initialState,
  reducers: {
    setFlow: (store, action: PayloadAction<FormFlow>) => {
      store.flow = action.payload
    },
    setNewVehicleId: (store, action: PayloadAction<string>) => {
      store.newVehicleId = action.payload
    },
    resetNewVehicleId: (store) => {
      store.newVehicleId = initialState.newVehicleId
    },
    setAddVehicleModalOpen: (store, action: PayloadAction<boolean>) => {
      store.isAddVehicleModalOpen = action.payload
    },
    resetAddVehicleForm: () => {
      return initialState
    }
  },
  extraReducers: (builder) => {
    builder.addCase(pageNavigation.fulfilled, () => {
      return {
        ...initialState
      }
    })
  }
})
