import { css } from '@mui/material';
import { styled } from '@mui/system';
import { BORDERS, COLORS } from '../../types/colors';

export const DatePickerContainer = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'isNewFieldVariant' && prop !== 'hasError' && prop !== 'hasFocus'
}) <{ isNewFieldVariant: boolean; hasError: boolean; hasFocus: boolean }>`
  display: flex;
  flex-direction: column;

  & label::after {
    content: '*';
    margin-left: 5px;
    color: red;
  }

  & > div:first-of-type {
    height: 46px;
    border: ${BORDERS.SM_GRAY};
    border-radius: 4px;
    background: white;
  }

  & > div:first-of-type,
  & > div:first-of-type > div {
    min-height: ${({ isNewFieldVariant }) => (isNewFieldVariant ? '60px' : '')};
  }

  & > div:hover {
    border-color: ${({ isNewFieldVariant, hasError }) =>
    isNewFieldVariant && !hasError ? COLORS.text_blue : ''};
  }

  & button {
    margin-left: 0;
  }

  & input {
    padding: 11px 14px 8px 0;
    text-transform: uppercase;
    font-size: 16px;
  }

  & input::placeholder {
    color: ${COLORS.text_secondary};
    opacity: 1;
  }

  & fieldset {
    border: none;
  }

  ${({ hasError }) =>
    hasError
      ? css`
          & > div:first-of-type {
            border-color: ${COLORS.error_message_red};
          }

          & > div input {
            color: ${COLORS.error_message_red};
          }

          & > div input::placeholder {
            color: ${COLORS.text_secondary};
          }

          & button {
            color: ${COLORS.error_message_red};
          }
        `
      : ''}
  ${({ hasFocus, isNewFieldVariant }) =>
    hasFocus
      ? css`
          & > div:first-of-type {
            border-color: ${isNewFieldVariant
          ? COLORS.text_blue
          : COLORS.text_primary};
          }
        `
      : ''}
`

export const ErrorMessageContainer = styled('div')`
  height: 18px;
  border: none;
`

export const InfoMessageContainer = styled('div')`
  margin-top: 12px;
  height: 22px;
  font-size: 14px;
  color: ${COLORS.text_secondary};
  border: none;
`
