import { FC } from 'react'
import { Box } from '@mui/material'

export const OrSeparator: FC = () => {
  return (
    <Box margin='24px 0' textAlign='center'>
      Or
    </Box>
  )
}
