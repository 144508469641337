export const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise<string>((resolve) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      if (reader.result) {
        resolve(reader.result.toString())
      }

      resolve('')
    }

    reader.readAsDataURL(blob)
  })
}
