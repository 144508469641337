import { useCallback } from 'react'
import { AddShipmentDetailsServiceParams } from '../../../../../../api/carSnoopApi/shipments/types'
import {
  INTERNAL_SHIPMENT_TYPE,
  ShipmentTypes
} from '../../../../../../constants/applicationConstants'
import { ShipmentInfoTypes } from '../../../../../../features/DeliveryCard'
import { ResponseStatusMap } from '../../../../../../types/status'
import { useApiContext, useMakeService } from '../../../../../../utils/hooks'

type SendShipmentInfoParams =
  | {
      shipmentType: ShipmentTypes.local
      transactionId: string
    }
  | {
      shipmentType: ShipmentTypes.shipping
      transactionId: string
      shipmentInfo: ShipmentInfoTypes
    }

export const useAddShipmentDetails = () => {
  const { carSnoopApi } = useApiContext()

  const addShipmentDetailsService = useCallback(
    async (params: SendShipmentInfoParams) => {
      const { shipmentType, transactionId } = params

      // Omg, let again ^.^
      let addShipmentDetailsParams: AddShipmentDetailsServiceParams

      if (shipmentType === ShipmentTypes.local) {
        addShipmentDetailsParams = {
          transactionId,
          shipmentType: INTERNAL_SHIPMENT_TYPE.local
        }
      } else {
        const {
          shipmentInfo: {
            vehicle: { pickup, dropOff }
          }
        } = params

        addShipmentDetailsParams = {
          transactionId,
          shipmentType: INTERNAL_SHIPMENT_TYPE.shipping,
          pickup: {
            contactName: pickup.contactName,
            contactNumber: pickup.contactNumber,
            contactEmail: pickup.contactEmail,
            addressName: pickup.addressName,
            street: pickup.street,
            city: pickup.city,
            state: pickup.state,
            zipCode: pickup.zipCode,
            notes: pickup.notes
          },
          dropOff: {
            contactName: dropOff.contactName,
            contactNumber: dropOff.contactNumber,
            contactEmail: dropOff.contactEmail,
            addressName: dropOff.addressName,
            street: dropOff.street,
            city: dropOff.city,
            state: dropOff.state,
            zipCode: dropOff.zipCode,
            notes: dropOff.notes
          }
        }
      }

      const response = await carSnoopApi.shipments.addShipmentDetails(
        addShipmentDetailsParams
      )

      if (response.status === ResponseStatusMap.Success) {
        return response
      }

      return response
    },
    []
  )

  const [sendShipmentInfo, sendShipmentInfoObservable] = useMakeService(
    addShipmentDetailsService
  )

  return {
    sendShipmentInfoObservable,
    sendShipmentInfo
  }
}
