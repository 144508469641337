import { FC, useCallback } from 'react'
import { COLORS } from '../../../../../../types/colors'
import { FileDocument } from '../../../../../../assets/icons/Document'
import { ChatMediaMessageContainer } from '../ChatMediaMessageContainer'
import { ChatFileMessageWrapperProps } from '../../types'

export const SupportedFileMessage: FC<ChatFileMessageWrapperProps> = ({
  id,
  ...props
}) => {
  const icon = <FileDocument color={COLORS.accent_blue} />
  const openDocumentClick = useCallback((link: string) => {
    window.open(link, '_blank')?.focus()
  }, [])

  return (
    <ChatMediaMessageContainer
      {...props}
      id={id}
      onClick={openDocumentClick}
      icon={icon}
    />
  )
}
