import { useState } from 'react'
import { OrNull } from '../../../types/OrNull'
import { ResponseStatusMap } from '../../../types/status'
import {
  useApiContext,
  useUserInfo,
  useMakeService
} from '../../../utils/hooks'
import { ReadRequestsResponse } from '../../../api/carSnoopApi/requests/types'

export const useRequestList = () => {
  const { accountID } = useUserInfo()
  const { carSnoopApi } = useApiContext()

  const [requests, setRequests] =
    useState<OrNull<ReadRequestsResponse.Normilized.ReadRequestsResponse[]>>(
      null
    )

  const [readRequests, readRequestsObservable] = useMakeService(async () => {
    setRequests(null)
    const response = await carSnoopApi.requests.readRequests({
      accountId: accountID
    })

    if (response.status === ResponseStatusMap.Success) {
      setRequests(
        response.payload.filter((request) => request.status !== 'Closed')
      )
    }

    return response
  })

  const [cancelRequest, cancelRequestObservable] = useMakeService(
    async (id: string) => {
      const res = await carSnoopApi.requests.cancelRequest({
        id
      })

      if (res.status === ResponseStatusMap.Success) {
        const response = await carSnoopApi.requests.readRequests({
          accountId: accountID
        })

        if (response.status === ResponseStatusMap.Success) {
          setRequests(
            response.payload.filter((request) => request.status !== 'Closed')
          )
          return response
        }

        return { status: ResponseStatusMap.Error }
      }
    },
    { withStatusNotification: true }
  )

  return {
    readRequests,
    requests,
    isLoading:
      readRequestsObservable.isLoading || cancelRequestObservable.isLoading,
    cancelRequest
  }
}
