import { RootState } from '../../types'
import {
  FIND_CAR_FIELD_NAMES,
  FIND_CAR_STEPS
} from '../../../types/enums/findCar'

export const selectFindCarFormStep = (state: RootState) =>
  state.findCarForm.step

export const selectBasicInfoScreenData = (state: RootState) => {
  return state.findCarForm[FIND_CAR_STEPS.stepOne]
}

export const selectSelectedVehiclesData = (state: RootState) => {
  return state.findCarForm[FIND_CAR_STEPS.stepTwo]
}

export const selectTradeInInfoScreenData = (state: RootState) => {
  return state.findCarForm[FIND_CAR_STEPS.stepThree]
}

export const selectFindCarFormScreenLength = (state: RootState) =>
  state.findCarForm.length

export const selectFindCarFormMakeOptions = (state: RootState) =>
  state.findCarForm.options[FIND_CAR_FIELD_NAMES.MAKE]

export const selectFindCarFormModelOptions = (state: RootState) =>
  state.findCarForm.options[FIND_CAR_FIELD_NAMES.MODEL]

export const selectFindCarFormYearFromOptions = (state: RootState) =>
  state.findCarForm.options[FIND_CAR_FIELD_NAMES.YEAR_FROM]

export const selectFindCarFormYearToOptions = (state: RootState) =>
  state.findCarForm.options[FIND_CAR_FIELD_NAMES.YEAR_TO]

export const selectFindCarFormTrimOptions = (state: RootState) =>
  state.findCarForm.options[FIND_CAR_FIELD_NAMES.TRIM]

export const selectFindCarFormInteriorColorsOptions = (state: RootState) =>
  state.findCarForm.options[FIND_CAR_FIELD_NAMES.INTERIOR_COLOR]

export const selectFindCarFormExteriorColorsOptions = (state: RootState) =>
  state.findCarForm.options[FIND_CAR_FIELD_NAMES.EXTERIOR_COLOR]

export const selectFindCarFormStandardEquipment = (state: RootState) =>
  state.findCarForm.standardEquipment

export const selectFindCarFormState = (state: RootState) => state.findCarForm

export const selectIsReadCarModelYearTrimLoading = (state: RootState) =>
  state.findCarForm.isReadCarModelYearTrimLoading
