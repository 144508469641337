import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { logout } from '../../thunks/logout'
import { pageNavigation } from '../../thunks/pageNavigation'

export enum DealsActiveTabEnum {
  Requests = 0,
  'In Progress',
  Completed
}

interface DealsPageState {
  isSellingView: boolean
  activeBuyingTab: number
  activeSellingTab: number
}

export const initialState: DealsPageState = {
  isSellingView: false,
  activeBuyingTab: 0,
  activeSellingTab: 0
}

export const dealsPageSlice = createSlice({
  name: 'dealsPage',
  initialState,
  reducers: {
    setSellingView: (store, action: PayloadAction<boolean>) => {
      store.isSellingView = action.payload
    },
    setActiveBuyingTab: (store, action: PayloadAction<number>) => {
      store.activeBuyingTab = action.payload
    },
    setActiveSellingTab: (store, action: PayloadAction<number>) => {
      store.activeSellingTab = action.payload
    },
    resetActiveTabs: (store) => {
      store.activeSellingTab = DealsActiveTabEnum.Requests
      store.activeBuyingTab = DealsActiveTabEnum.Requests
    }
  },
  extraReducers: (builder) => {
    builder.addCase(pageNavigation.fulfilled, () => {
      return initialState
    })
    builder.addCase(logout.fulfilled, () => {
      return initialState
    })
  }
})
