import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { SocialNetLogIn } from './SocialNetLogIn'
import {
  useUserInfo,
  useAuthenticateUser,
  useAppDispatch
} from '../../utils/hooks'
import { PromotionBlock } from '../../atoms/PromotionBlock'
import { routes } from '../../routes'
import { LogInScreen } from '../../forms/screens/LogIn'
import { OrSeparator } from './OrSeparator'
import { ConditionsHints } from './ConditionsHints'
import { ServicePageContainer } from '../../layout/ServicePageContainer'
import { Condition } from '../../atoms/Condition'
import { Loader } from '../../atoms/Loader'
import { FormContainer } from '../../layout/FormContainer'
import { ScreenContainer } from '../../layout/ScreenContainer'
import { pageNavigation } from '../../store/thunks/pageNavigation'
import { UserInfoStatusMap } from '../../store/features/userInfo'

export const LogInPage: FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const userInfo = useUserInfo()
  const { loginObservable, loginService } = useAuthenticateUser()

  useEffect(() => {
    if (userInfo.status === UserInfoStatusMap.Preregistered) {
      // TODO: move action to buttons callback
      navigate(routes.registration.external)
    }
  }, [userInfo])

  useEffect(
    () => () => {
      dispatch(pageNavigation())
    },
    []
  )

  return (
    <ServicePageContainer>
      <PromotionBlock />
      <FormContainer title='Log In'>
        <Condition
          condition={loginObservable.isLoading}
          trueContent={
            <ScreenContainer>
              <Loader />
            </ScreenContainer>
          }
          falseContent={
            <ScreenContainer>
              <LogInScreen
                onSubmit={loginService}
                temporaryData={loginObservable.props}
              />
              <OrSeparator />
              <SocialNetLogIn />
              <ConditionsHints />
            </ScreenContainer>
          }
        />
      </FormContainer>
    </ServicePageContainer>
  )
}
