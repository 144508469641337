import { Box } from '@mui/material'
import { Formik } from 'formik'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { ActionBlock } from '../../../atoms/ActionBlock'
import { ScreenContainer } from '../../../layout/ScreenContainer'

import {
  ConfirmPassword,
  ContactDetails,
  FormActions
} from '../../../types/forms/registration'
import { useAppDispatch } from '../../../utils/hooks'
import { isValidForm } from '../../../utils/isValidForm'

import { carSnoopApi } from '../../../api'
import { PasswordInputField } from '../../../atoms/PasswordInputField'
import { RequiredLabel } from '../../../atoms/RequiredText'
import { TermsCheckbox } from '../../../atoms/TermsCheckbox'
import { CREATE_PASSWORD_HINT } from '../../../constants/messages'
import { routes } from '../../../routes'
import {
  ContactDetailsForm,
  PasswordHint,
  TermsCheckboxContainer
} from './CreatePassword.styles'
import { TermsHint } from './TermsHint'
import { validationSchema } from './validationSchema'

type CreatePasswordScreenProps = {
  initialValues: ConfirmPassword
  actions: FormActions<
    | 'decrementOnboardStep'
    | 'decrementScreenStep'
    | 'resetState'
    | 'incrementScreenStep'
  >
  dependencies: ContactDetails
}

export const CreatePasswordScreen: FC<CreatePasswordScreenProps> = ({
  initialValues,
  actions,
  dependencies
}) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const onNextBtnClicked = async (data: ConfirmPassword): Promise<void> => {
    dispatch(actions.incrementScreenStep())
    const { status } = await carSnoopApi.users.resetPassword({
      id: dependencies.email,
      password: data.confirmPassword
    })

    if (status === 'success') {
      navigate(routes.login)
      dispatch(actions.resetState())
    } else {
      dispatch(actions.decrementScreenStep())
    }
  }

  const onBackBtnClicked = (): void => {
    dispatch(actions.decrementScreenStep())
    dispatch(actions.decrementOnboardStep())
  }

  return (
    <ScreenContainer>
      <Box sx={{ mb: 2 }}>
        <RequiredLabel />
      </Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onNextBtnClicked}
        validateOnMount
      >
        {(formik) => {
          return (
            <ContactDetailsForm>
              <PasswordInputField
                name='password'
                label='Password'
                placeholder='Password'
                isValid={!(formik.touched?.password && formik.errors.password)}
              />
              <PasswordInputField
                name='confirmPassword'
                label='Confirm Password'
                placeholder='Confirm Password'
                isValid={
                  !(
                    formik.touched?.confirmPassword &&
                    formik.errors.confirmPassword
                  )
                }
              />
              <PasswordHint>{CREATE_PASSWORD_HINT}</PasswordHint>
              <TermsCheckboxContainer>
                <TermsCheckbox hint={<TermsHint />} name='terms' />
              </TermsCheckboxContainer>
              <ActionBlock
                schema={[
                  {
                    label: 'Back',
                    type: 'secondary',
                    onClick: onBackBtnClicked
                  },
                  {
                    label: 'Log In',
                    type: 'primary',
                    disabled: !isValidForm<ConfirmPassword>(formik, {
                      requiredFields: ['password', 'confirmPassword']
                    }),
                    props: {
                      type: 'submit'
                    }
                  }
                ]}
              />
            </ContactDetailsForm>
          )
        }}
      </Formik>
    </ScreenContainer>
  )
}
