import { Box } from '@mui/material'
import { FC, MouseEvent, PropsWithChildren, useCallback, useMemo } from 'react'
import { COLORS } from '../../../../types/colors'

export type IconButtonProps = PropsWithChildren<{
  isMain?: boolean
  onClick?: () => void
}>

export const IconButton: FC<IconButtonProps> = (props) => {
  const { children, isMain, onClick } = props

  const handleClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
      onClick && onClick()
    },
    [onClick]
  )

  const size = useMemo(() => (isMain ? '56px' : '32px'), [isMain])
  return (
    <Box
      onClick={handleClick}
      sx={{
        backgroundColor: COLORS.border_gray,
        borderRadius: '50%',
        width: size,
        height: size,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
      }}
    >
      {children}
    </Box>
  )
}
