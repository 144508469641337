import { Box, Typography } from '@mui/material'
import { FC } from 'react'

interface IModalHeader {
  title: string
}

export const ModalHeader: FC<IModalHeader> = ({ title }) => {
  return (
    <Box width='100%'>
      <Typography variant='h6'>{title}</Typography>
    </Box>
  )
}
