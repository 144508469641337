import { Box, Grid, Typography } from '@mui/material'
import { FC, useMemo } from 'react'
import { RequestItem } from '../../api/carSnoopApi/requests/types'
import { DisplayColors } from '../../atoms/DisplayColors'
import { For } from '../../atoms/JSXExtensions'
import { COLORS } from '../../types/colors'
import { formatPrice } from '../../utils'
import { firstOf, lastOf } from '../../utils/array'
import { RequestDetailsItem } from './components/RequestDetailsItem'

export type RequestDetailsProps = Omit<
  RequestItem['request'],
  'tradeIn' | 'expirationDate'
> & {
  tradeinPrice?: number
  trim?: string
}

export const RequestDetailsWidget: FC<RequestDetailsProps> = (props) => {
  const {
    make,
    model,
    trim,
    years,
    interiorColors,
    exteriorColors,
    tradeinPrice
  } = props

  const carName = useMemo(
    () => [make, model, trim].filter(Boolean).join(' '),
    [make, model, trim]
  )

  const carYears = useMemo(
    () =>
      years.length > 1 ? `${firstOf(years)} - ${lastOf(years)}` : `${years[0]}`,
    [years, firstOf, lastOf]
  )

  const tradeInFontStyle = useMemo(
    () => (tradeinPrice ? 'normal' : 'italic'),
    [tradeinPrice]
  )

  const formattedTradeinPrice = useMemo(
    () => (tradeinPrice ? formatPrice(tradeinPrice) : 'Not Included'),
    [formatPrice, tradeinPrice]
  )

  const requestDetailsData = [
    { title: 'Make, Model, Trim', children: carName },
    { title: 'Year', children: carYears },
    {
      title: 'Ext/Interior Color',
      children: (
        <>
          <DisplayColors colors={exteriorColors} />
          {' '}/{' '}
          <DisplayColors colors={interiorColors} />
        </>
      )
    },
    {
      title: 'Trade In',
      children: <Box fontStyle={tradeInFontStyle}>{formattedTradeinPrice}</Box>
    }
  ]

  return (
    <Grid
      container
      item
      justifyContent='space-between'
      rowSpacing={2}
      sx={{
        background: COLORS.hover_background_blue,
        borderRadius: '10px',
        px: 2,
        pb: 3,
        pt: 1,
        mt: 0
      }}
    >
      <Grid item xs={12}>
        <Typography variant='emphasized'>Request Details</Typography>
      </Grid>
      <For of={requestDetailsData}>
        {({ title, children }) => (
          <RequestDetailsItem title={title}>{children}</RequestDetailsItem>
        )}
      </For>
    </Grid>
  )
}
