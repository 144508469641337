import { OrNull } from '../../../types/OrNull'

type Timer = OrNull<ReturnType<typeof setTimeout>>
type RenovateFunction = () => Promise<unknown>

interface RenovatorParams {
  renovateFunction: RenovateFunction
  renovatePeriod: number
}

export class Renovator {
  timer: Timer = null
  renovateFunction: RenovateFunction
  renovatePeriod: number
  isRunning: boolean = false

  constructor(params: RenovatorParams) {
    this.renovateFunction = params.renovateFunction
    this.renovatePeriod = params.renovatePeriod
  }

  renovate = async () => {
    if (this.timer) {
      clearTimeout(this.timer)
    }

    this.timer = null
    await this.renovateFunction()

    if (this.isRunning) {
      this.planRenovate()
    }
  }

  private planRenovate() {
    this.timer = setTimeout(this.renovate, this.renovatePeriod)
  }

  start() {
    if (this.isRunning) {
      return
    }

    this.isRunning = true
    this.planRenovate()
  }

  stop() {
    this.isRunning = false
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }
}
