import { useLocation } from 'react-router-dom'

export const useBackPath = () => {
  const location = useLocation()
  const { state } = location
  const currentLocation = location.pathname

  const { backPath = '/' } = (state || {}) as { backPath: string }

  return { backPath, currentLocation }
}
