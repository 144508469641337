import { FC, useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Grid, Typography } from '@mui/material'
import { PageContainer } from '../../layout/PageContainer'
import { Toggle } from '../../atoms/Toggle'
import { useUserInfo, useAppDispatch } from '../../utils/hooks'
import { Show } from '../../atoms/JSXExtensions'
import { BuyingFlow } from './BuyingFlow'
import { SellingFlow } from './SellingFlow'
import {
  IDealsLocationParams,
  useDealsPageSlice
} from './hooks/useDealsPageSlice'
import { requestTabNavigation } from '../../store/thunks/pageNavigation'

export const DealsPage: FC = () => {
  const dispatch = useAppDispatch()
  const { isDealer } = useUserInfo()
  const { state: locationState } = useLocation()
  const {
    isSellingView,
    setSellingView,
    resetActiveTabs,
    dealPageTabSwitcher
  } = useDealsPageSlice()

  const toggleDealsView = () => {
    setSellingView(!isSellingView)
    resetActiveTabs()
    dispatch(requestTabNavigation())
  }

  useLayoutEffect(() => {
    if (locationState) {
      dealPageTabSwitcher(locationState as IDealsLocationParams)
    } else isDealer && setSellingView(true)
  }, [isDealer, setSellingView, locationState])

  return (
    <PageContainer>
      <Grid container>
        <Grid item container justifyContent='space-between' alignItems='center'>
          <Grid container item xs='auto' alignItems='center' spacing={2}>
            <Grid item>
              <Typography variant='h6'>My Deals</Typography>
            </Grid>
            <Grid item>
              <Toggle
                checked={isSellingView}
                onChange={toggleDealsView}
                leftLabel='Buying'
                rightLabel='Selling'
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Show when={isSellingView} fallback={<BuyingFlow />}>
            <SellingFlow />
          </Show>
        </Grid>
      </Grid>
    </PageContainer>
  )
}
