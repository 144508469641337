import { styled } from '@mui/material'
import { BORDERS } from '../../types/colors'

export const FindCarResultContainer = styled('div')`
  border: 1px solid ${BORDERS.SM_SECONDARY_BG};
  box-shadow: $box-shadow;
  border-radius: 10px;
  padding: 24px 32px;
`

export const ContentContainer = styled('div')`
  padding: 10px;
  border: ${BORDERS.XSM_GRAY};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const ResultTitle = styled('div')`
  margin-bottom: 16px;
`

export const ImgContainer = styled('div')`
  margin: 5% auto;
  & > svg {
    width: 100%;
    height: 100%;
  }
`
