import { useState, useEffect } from 'react'
import { ContactInformationCard } from '../../../../molecules/ContactInformationCard'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

const imageURL =
  'https://tesla-cdn.thron.com/delivery/public/image/tesla/2391415a-7273-4735-92b2-a37c84c26f04/bvlatuR/std/4096x2560/Homepage-Model-S-Desktop-RHD'

export const ContactInformationExample = () => {
  const [imageSource, setImageSource] = useState('')

  useEffect(() => {
    fetch(imageURL)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader()

        reader.onloadend = () => {
          if (reader.result) {
            setImageSource(
              reader.result.toString().replace('data:image/jpeg;base64,', '')
            )
          }
        }

        reader.readAsDataURL(blob)
      })
  }, [])
  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <ContactInformationCard
          id='I love my work'
          fullName='Mary Cook'
          email='Grzegorz@mail.com'
          dob='1991-07-12'
          phone='8005553535'
          avatar={imageSource}
        />
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
