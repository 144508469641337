import { Box, Grid } from '@mui/material'
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { AgreeButton, CancelButton } from '../../atoms/Button'
import { FixedContainer } from '../../atoms/FixedContainer'
import { ImageWithBadge } from '../../atoms/ImageWithBadge'
import { Show } from '../../atoms/JSXExtensions'
import { Loader } from '../../atoms/Loader'
import { NumberBadge } from '../../atoms/NumberBadge'
import { TabContainer } from '../../atoms/TabContainer'
import { Chat, ChatProps } from '../../features/Chat'
import { chatActions } from '../../features/Chat/model/actions'
import { selectMessages } from '../../features/Chat/model/selectors'
import { DisplayVehicleInfo } from '../../features/DisplayVehicleInfo'
import { selectUnreadChatsCounters } from '../../features/MiniChat/model/selectors'
import { PageContainer } from '../../layout/PageContainer'
import { CommonConfirmationModal } from '../../molecules/Modal/CommonConfirmationModal'
import { routes } from '../../routes'
import { actionsContractPage } from '../../store/pages/Contract'
import { CONTRACT_TABS } from '../../store/pages/Contract/contractPage'
import { COLORS, SHADOWS } from '../../types/colors'
import { ResponseStatusMap } from '../../types/status'
import {
  useApiContext,
  useAppDispatch,
  useAppSelector,
  useBackPath,
  useChatSearchParams,
  useDeviceInfo,
  useMakeService,
  useModal,
  useSubscriptionModal
} from '../../utils/hooks'
import { isNewTab } from '../../utils/isNewTab'
import { useReturnToDealsPage } from '../Deals/hooks'
import { DocumentsTab } from './DocumentsTab'
import { ContractInformation } from './components/ContractInformation'
import { Header } from './components/Header'
import {
  TABS,
  buyerDeclineDealMainText,
  buyerDeclineDealText,
  completeContractMainText,
  completeContractText,
  sellerDeclineDealMainText,
  sellerDeclineDealText
} from './constants'
import { useContractPageInfo } from './hooks/useContractPageInfo'
import { ContractViewStates } from './hooks/useContractStateDecoder'
import { ShareErrorContext } from './utils/ShareErrorContext'

export const ContractPage = () => {
  const {
    transactionID,
    transaction,
    readTransaction,
    isLoading,
    isAdmin,
    contractViewState,
    isCurrentUserASeller,
    currentTab,
    isContractEditable,
    shouldShowCancelDealButton
  } = useContractPageInfo()

  const unreadChats = useAppSelector(selectUnreadChatsCounters)
  const unreadMessagesCounter = useMemo(
    () => unreadChats[transactionID],
    [unreadChats, transactionID]
  )

  const navigate = useNavigate()
  const { carSnoopApi } = useApiContext()
  const dispatch = useAppDispatch()
  const { isTablet } = useDeviceInfo()
  const { openSubscriptionModal } = useSubscriptionModal()
  const backToDealsPage = useReturnToDealsPage(
    transaction?.status,
    transaction?.sellerID || ''
  )
  const { backPath } = useBackPath()
  const [hasError, setHasError] = useState(false)
  const isChatOpen = useChatSearchParams()

  const onBackClick = useCallback(() => {
    if (backPath !== routes.deals.index) {
      navigate(backPath)
    } else {
      backToDealsPage()
    }
  }, [backToDealsPage, backPath, navigate])

  useLayoutEffect(() => {
    if (isChatOpen) {
      dispatch(actionsContractPage.setCurrentTab(CONTRACT_TABS.chat))
    }
  }, [actionsContractPage, isChatOpen, transaction])

  const onSuccessContractCompletion = useCallback(() => {
    const path = generatePath(routes.contracts.completed, {
      transactionId: transactionID
    })
    navigate(path)
  }, [transactionID, navigate])

  const [completeContract, completeContractObservable] = useMakeService(
    async () => {
      const res = await carSnoopApi.contracts.completeContract(transactionID)

      if (res.status === ResponseStatusMap.Success) {
        onSuccessContractCompletion()
        return res
      }
      if (res.isSubscriptionError) {
        openSubscriptionModal()

        return res
      }

      return res
    },
    { includeCheckAccess: true, withStatusNotification: true }
  )

  const [terminateContract] = useMakeService(
    async () => {
      const res = await carSnoopApi.contracts.terminateContract(transactionID)

      if (res.status === ResponseStatusMap.Success) {
        readTransaction({ id: transactionID })

        return res
      }

      return res
    },
    { includeCheckAccess: true, withStatusNotification: true }
  )

  const [declinePayment] = useMakeService(
    async () => {
      const res = await carSnoopApi.payments.declinePayment({
        id: transactionID
      })
      if (res.status === ResponseStatusMap.Success) {
        readTransaction({ id: transactionID })

        return res
      }

      return res
    },
    { includeCheckAccess: true, withStatusNotification: true }
  )

  const [generatePurchaseAgreement, generatePurchaseAgreementObservable] =
    useMakeService(
      async () => {
        const res = await carSnoopApi.documents.generatePurchaseAgreement({
          contractID: transaction?.contract?.id || ''
        })
        if (res.status === ResponseStatusMap.Success) {
          await readTransaction({
            id: transactionID,
            skipLoader: true,
            openContractInformation: false
          })
          return res
        }

        return res
      },
      { withStatusNotification: true }
    )

  const dealTerminationAPI = isContractEditable
    ? terminateContract
    : declinePayment

  const completeContractModal = useModal(completeContract)
  const terminateContractModal = useModal(dealTerminationAPI)

  const handleTabChange = useCallback(
    (_event: SyntheticEvent, newValue: number) => {
      readTransaction({
        id: transactionID,
        openContractInformation: false,
        skipLoader: true
      })
      dispatch(actionsContractPage.setCurrentTab(newValue))
    },
    [transactionID, readTransaction, dispatch]
  )

  const shouldShowControlsPanel = useMemo(() => {
    return (
      isAdmin &&
      isCurrentUserASeller &&
      contractViewState === ContractViewStates.UNDER_CONTRACT &&
      currentTab !== CONTRACT_TABS.chat
    )
  }, [isAdmin, contractViewState, isCurrentUserASeller, currentTab])

  const declineDealMainText = useMemo(
    () =>
      isCurrentUserASeller
        ? sellerDeclineDealMainText
        : buyerDeclineDealMainText,
    [isCurrentUserASeller]
  )

  const declineDealText = useMemo(
    () => (isCurrentUserASeller ? sellerDeclineDealText : buyerDeclineDealText),
    [isCurrentUserASeller]
  )

  useEffect(() => {
    if (transactionID) {
      readTransaction({ id: transactionID })
    }
  }, [transactionID])

  const chatTabContentPadding = useMemo(
    () => (currentTab === CONTRACT_TABS.chat ? 0 : 3),
    [currentTab]
  )

  const errorProviderValue = useMemo(
    () => ({
      hasError,
      setHasError
    }),
    [hasError, setHasError]
  )

  const handleMessagesRead = useCallback<ChatProps['onMessagesRead']>(
    (messages) => {
      dispatch(chatActions.setMessages(messages))
    },
    [dispatch]
  )

  const handleChatUnmount = useCallback(() => {
    dispatch(chatActions.resetMessages())
  }, [dispatch])

  return (
    <ShareErrorContext.Provider value={errorProviderValue}>
      <PageContainer>
        <Show
          when={transaction}
          fallback={
            <Grid container justifyContent='center' alignItems='center'>
              <Loader />
            </Grid>
          }
        >
          {(deal) => (
            <>
              <Grid
                container
                flexDirection='column'
                sx={{ pb: shouldShowControlsPanel ? '64px' : 'auto' }}
              >
                <Grid item>
                  <Header
                    contractStatus={deal.status}
                    onCancelDealClick={terminateContractModal.onOpen}
                    onBackClick={onBackClick}
                    showBackButton={!isNewTab()}
                    showCancelButton={shouldShowCancelDealButton}
                  />
                </Grid>

                {/* Vehicle info */}
                <Grid container item spacing={3} pb={isTablet ? 1 : 5}>
                  <Grid item xs={12} lg={5}>
                    <ImageWithBadge
                      imageURL={deal.vehicle.documentContent}
                      badgeValue={transaction?.offer?.totalPrice || 0}
                    />
                  </Grid>
                  <Grid item xs={12} lg={7}>
                    <DisplayVehicleInfo {...deal.vehicle} openDetailsInNewTab />
                  </Grid>
                </Grid>
                {/* /Vehicle info */}

                <Grid item xs={12}>
                  <TabContainer
                    tabNames={[
                      TABS.CONTRACT_INFORMATION,
                      TABS.DOCUMENTS,
                      TABS.CHAT
                    ]}
                    trailingContent={
                      <Box sx={{ position: 'relative' }}>
                        <Grid
                          item
                          sx={{
                            position: 'absolute',
                            left: '-26px',
                            top: '8px'
                          }}
                        >
                          <Show when={unreadMessagesCounter}>
                            <NumberBadge
                              width='16px'
                              height='16px'
                              fontSize='10px'
                              count={unreadMessagesCounter}
                            />
                          </Show>
                        </Grid>
                      </Box>
                    }
                    tabContents={[
                      <ContractInformation
                        transaction={transaction}
                        isLoading={isLoading}
                      />,
                      <DocumentsTab
                        transaction={transaction}
                        generatePurchaseAgreement={generatePurchaseAgreement}
                        agreementGenerationInProcess={
                          generatePurchaseAgreementObservable.isLoading
                        }
                        isLoading={isLoading}
                      />,
                      <Chat
                        chatId={deal.id || ''}
                        messagesSelector={selectMessages}
                        onMessagesRead={handleMessagesRead}
                        onChatUnmount={handleChatUnmount}
                        height='640px'
                      />
                    ]}
                    tabContentsSx={{ pb: chatTabContentPadding }}
                    currentTabID={currentTab}
                    onChange={handleTabChange}
                  />
                </Grid>

                {/* Bottom panel */}
                <Show when={shouldShowControlsPanel}>
                  <FixedContainer
                    bottom
                    p={3}
                    background={COLORS.white}
                    justifyContent='space-between'
                    boxShadow={SHADOWS.modal}
                  >
                    <Grid item>
                      <CancelButton
                        sx={{
                          borderColor: COLORS.text_red
                        }}
                        onClick={terminateContractModal.onOpen}
                      >
                        Decline
                      </CancelButton>
                    </Grid>
                    <Grid item ml={2}>
                      <AgreeButton
                        onClick={completeContractModal.onOpen}
                        sx={{ wrap: 'nowrap', width: 'auto' }}
                        disabled={hasError}
                      >
                        Complete contract
                      </AgreeButton>
                    </Grid>
                  </FixedContainer>
                </Show>
                {/* /Bottom panel */}
              </Grid>
              <CommonConfirmationModal
                {...completeContractModal}
                isSubmitDisabled={completeContractObservable.isLoading}
                message={completeContractMainText}
                hint={completeContractText}
              />
              <CommonConfirmationModal
                {...terminateContractModal}
                message={declineDealMainText}
                hint={declineDealText}
              />
            </>
          )}
        </Show>
      </PageContainer>
    </ShareErrorContext.Provider>
  )
}
