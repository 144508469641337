import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OrNull } from '../../../types/OrNull'
import type { Address } from '../../types'

interface EditingAddressState {
  editingAddress: OrNull<Address>
}

export const initialState: EditingAddressState = {
  editingAddress: null
}

export const editingAddressSlice = createSlice({
  name: 'editingAddress',
  initialState,
  reducers: {
    setEditingAddress: (store, action: PayloadAction<OrNull<Address>>) => {
      store.editingAddress = action.payload
    },
    updateEditingAddress: (store, action: PayloadAction<Address>) => {
      store.editingAddress = { ...store.editingAddress, ...action.payload }
    }
  }
})
