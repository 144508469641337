import { Grid, Typography } from '@mui/material'

export const SellerDefaultShipping = () => {
  return (
    <Grid item>
      <Typography variant='subtitle2'>
        Please contact the buyer regarding the delivery
      </Typography>
    </Grid>
  )
}
