import { useCallback, useEffect } from 'react'
import {
  selectStatistic,
  actionsStatisticSlice
} from '../../../../../store/units/statistic'
import { ResponseStatusMap } from '../../../../../types/status'
import type { StatisticCardSchema } from '../../../../../types/statisticCard'
import {
  useApiContext,
  useAppDispatch,
  useAppSelector,
  useMakeService,
  useUserInfo
} from '../../../../../utils/hooks'
import { useUserDashboardClickHandlers } from './useUserDashboardClickHandlers'
import { ReturnTypeGetAccountStatistics } from '../../../../../api/carSnoopApi/accounts/returnTypes'
import { UserInfoStatusMap } from '../../../../../store/features/userInfo'

type HookReturnType = {
  schema: StatisticCardSchema
  getAccountStatistics: () => ReturnTypeGetAccountStatistics
}

export const useUserDashboardStatistic = (): HookReturnType => {
  const dispatch = useAppDispatch()
  const { isDealer, status } = useUserInfo()
  const { requestsClickHandler, offersClickHandler, contractsClickHandler } =
    useUserDashboardClickHandlers(isDealer)

  const { requests, offers, contracts } = useAppSelector(selectStatistic)
  const { carSnoopApi } = useApiContext()

  const getStatisticsService = useCallback(async () => {
    const response = await carSnoopApi.accounts.getAccountStatistics()

    if (response.status === ResponseStatusMap.Success) {
      dispatch(
        actionsStatisticSlice.setState({
          requests: response.requests,
          offers: response.offers,
          contracts: response.contracts
        })
      )

      return response
    }

    dispatch(actionsStatisticSlice.reset())
    return response
  }, [dispatch])

  const [getAccountStatistics] = useMakeService(getStatisticsService)

  useEffect(() => {
    if (status === UserInfoStatusMap.Authenticated) {
      getAccountStatistics()
    }
  }, [status])

  return {
    schema: [
      {
        onClick: requestsClickHandler,
        text: 'requests',
        value: requests
      },
      {
        onClick: offersClickHandler,
        text: 'offers',
        value: offers
      },
      {
        onClick: contractsClickHandler,
        text: 'contracts',
        value: contracts
      }
    ],
    getAccountStatistics
  }
}
