import { Grid } from '@mui/material'
import { FC } from 'react'
import { AgreeButton, BlueButton, CancelButton } from '../../../atoms/Button'
import { FixedContainer } from '../../../atoms/FixedContainer'
import { COLORS, SHADOWS } from '../../../types/colors'

interface FindCarActionBlockProps {
  nextButtonDisabled?: boolean
  onCancelClick: () => void
  onBackClick: () => void
  onNextClick: () => void
}

export const FindCarActionBlock: FC<FindCarActionBlockProps> = ({
  nextButtonDisabled = false,
  onCancelClick,
  onBackClick,
  onNextClick
}) => {
  return (
    <FixedContainer
      background={COLORS.white}
      bottom
      boxShadow={SHADOWS.modal}
      columnGap={{ xs: 2, md: 3 }}
      padding={{ xs: 2, md: 3 }}
    >
      <Grid item>
        <CancelButton onClick={onCancelClick}>Cancel</CancelButton>
      </Grid>
      <Grid item flexGrow={1}>
        <BlueButton onClick={onBackClick} sx={{ display: { xs: 'none', md: 'flex' } }}>BACK TO PREVIOUS STEP</BlueButton>
        <BlueButton onClick={onBackClick} sx={{ display: { xs: 'flex', md: 'none' } }}>BACK</BlueButton>
      </Grid>
      <Grid item>
        <AgreeButton disabled={nextButtonDisabled} onClick={onNextClick}>
          Next
        </AgreeButton>
      </Grid>
    </FixedContainer>
  )
}
