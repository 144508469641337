import { config } from '../../constants/config'
import { noop } from '../../utils'
import { ApiOptions } from '../types'
import { Utils } from '../utils'
import { Accounts } from './accounts'
import { Addresses } from './addresses'
import { Contracts } from './contracts'
import { Documents } from './documents'
import { Duty } from './duty'
import { Offers } from './offers'
import { Payments } from './payments'
import { Requests } from './requests'
import { Shipments } from './shipments'
import { Subscription } from './subscription'
import { Transactions } from './transactions'
import { Users } from './users'
import { Vehicles } from './vehicles'

export class CarSnoopApi {
  readonly utils: Utils
  users: Users
  accounts: Accounts
  vehicles: Vehicles
  addresses: Addresses
  requests: Requests
  offers: Offers
  transactions: Transactions
  contracts: Contracts
  documents: Documents
  subscription: Subscription
  payments: Payments
  shipments: Shipments
  duty: Duty

  constructor(options: ApiOptions = {}) {
    const {
      endpoint = config.INTERNAL_API_ENDPOINT,
      token = config.AWS_API_KEY,
      session,
      logoutCallback = noop
    } = options

    this.utils = new Utils({
      endpoint,
      token,
      session,
      logoutCallback
    })

    this.users = new Users(this.utils)
    this.accounts = new Accounts(this.utils)
    this.vehicles = new Vehicles(this.utils)
    this.addresses = new Addresses(this.utils)
    this.requests = new Requests(this.utils)
    this.offers = new Offers(this.utils)
    this.transactions = new Transactions(this.utils)
    this.documents = new Documents(this.utils)
    this.contracts = new Contracts(this.utils)
    this.subscription = new Subscription(this.utils)
    this.payments = new Payments(this.utils)
    this.shipments = new Shipments(this.utils)
    this.duty = new Duty(this.utils)
  }
}

export const carSnoopApi = new CarSnoopApi()
