import { Grid, Typography } from '@mui/material'
import { FC, useMemo } from 'react'
import { AttentionIcon } from '../../../assets/icons/Attention'
import { Condition } from '../../../atoms/Condition'
import { EllipsisText } from '../../../atoms/EllipsisText'
import { Tooltip } from '../../../atoms/Tooltip'
import { ReferenceModel } from '../../../types/ReferenceModel'
import { nbsp } from '../../../types/strings'

export const ColorView: FC<{ colors: ReferenceModel[]; title: string }> = (
  props
) => {
  const { colors, title } = props

  const isMultiply = colors.length > 1
  const tooltipText = isMultiply ? '' : colors[0].name.toUpperCase()
  const vehicleColors = useMemo(() => {
    switch (isMultiply) {
      case false: {
        return colors[0].name.toUpperCase()
      }
      default: {
        return [colors[0].name.toUpperCase(), `(+${colors.length - 1})`].join(
          ' '
        )
      }
    }
  }, [colors])

  const allColors = useMemo(() => {
    return isMultiply ? colors.map((c) => c.name).join(', ') : null
  }, [colors])

  return (
    <Grid
      container
      item
      xs={12}
      wrap='nowrap'
      width='auto'
      alignItems='flex-end'
    >
      <Grid container item alignItems='center' width='auto'>
        <Typography variant='subtitle2' whiteSpace='nowrap'>
          {title}
          {nbsp}
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs={isMultiply ? 2 : 5.5}
        lg={isMultiply ? 3 : 4.5}
        xl={6}
      >
        <Typography variant='body1' whiteSpace='nowrap' width='100%'>
          <EllipsisText tooltipText={tooltipText}>{vehicleColors}</EllipsisText>
        </Typography>
      </Grid>
      <Grid
        container
        item
        alignItems='flex-end'
        ml={0.5}
        sx={{ cursor: 'pointer' }}
      >
        <Condition
          condition={isMultiply}
          trueContent={
            <Tooltip title={allColors || ''}>
              {/* Any node is needed for tooltip working */}
              <Grid container item>
                <AttentionIcon />
              </Grid>
            </Tooltip>
          }
        />
      </Grid>
    </Grid>
  )
}
