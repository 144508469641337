import { Grid, Typography } from '@mui/material'
import { FC, useMemo } from 'react'
import { EllipsisText } from '../../../../../../atoms/EllipsisText'
import { COLORS } from '../../../../../../types/colors'
import { VehicleDetailsOverviewText } from '../../constants'

interface OverviewItemProps {
  icon: JSX.Element
  title: string
  value: string
}

export const OverviewItem: FC<OverviewItemProps> = ({
  icon,
  title,
  value
}) => {
  const valueFontStyle = useMemo(
    () =>
      value === VehicleDetailsOverviewText.NotAvailable ? 'italic' : 'inherit',
    [value, VehicleDetailsOverviewText.NotAvailable]
  )

  return (
    <Grid
      container
      item
      spacing={2}
      xs={12} md={4}
    >
      <Grid container item spacing={2} wrap='nowrap'>
        <Grid item
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          {icon}
        </Grid>
        <Grid item sx={{ overflow: 'hidden' }}>
          <Typography
            variant='subtitle1'
            sx={{ color: COLORS.text_disabled_black, textTransform: 'uppercase' }}
          >
            <EllipsisText tooltipText={title.toUpperCase()}>{title}</EllipsisText>
          </Typography>
          <Typography variant='main' sx={{ fontStyle: valueFontStyle }}>
            <EllipsisText tooltipText={value}>{value}</EllipsisText>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
