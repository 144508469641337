import { FC, useState } from 'react'
import VerificationInputComponent, {
  VerificationInputProps
} from 'react-verification-input'
import { Grid, Typography } from '@mui/material'
import styles from './VerificationInput.module.css'
import { Condition } from '../Condition'

type Props = Omit<VerificationInputProps, 'classNames'> & {
  error?: string | false
  disabled?: boolean
}

export const VerificationInput: FC<Props> = ({
  error,
  disabled = false,
  ...props
}: Props) => {
  const [isError, setIsError] = useState(!!error)
  const onFocus = () => {
    if (props.onFocus) {
      props.onFocus()
    }

    setIsError(false)
  }

  const onBlur = () => {
    if (props.onBlur) {
      props.onBlur()
    }

    setIsError(!!error)
  }

  return (
    <Grid item container sx={{ justifyContent: 'center' }}>
      <VerificationInputComponent
        {...props}
        placeholder=''
        removeDefaultStyles
        classNames={{
          container: styles.container,
          character: isError ? styles.character__error : styles.character,
          characterInactive: styles.characterInactive,
          characterSelected: styles.characterSelected
        }}
        onFocus={onFocus}
        onBlur={onBlur}
        inputProps={{
          disabled
        }}
      />
      <Condition
        condition={isError}
        trueContent={
          <Grid item sx={{ ml: '20px', fontSize: '14px' }} xs={12}>
            <Typography
              variant='error'
              sx={{
                textAlign: 'left'
              }}
            >
              {error}
            </Typography>
          </Grid>
        }
      />
    </Grid>
  )
}
