import { Grid, Typography } from '@mui/material'
import { FC } from 'react'

export const ListHeaderTablet: FC = () => {
  return (
    <Grid container item xs={12} spacing={1} alignItems='center'>
      <Grid item xs={6}>
        <Typography variant='subtitle2' textTransform='uppercase'>
          Make, Model, Year
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='subtitle2' textTransform='uppercase'>
          Ext/Interior Color
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='subtitle2' textTransform='uppercase'>
          Trade In
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='subtitle2' textTransform='uppercase'>
          Expires On
        </Typography>
      </Grid>
    </Grid>
  )
}
