import { FC } from 'react'
import { Grid } from '@mui/material'
import { For, Show } from '../../../../../atoms/JSXExtensions'
import { DocumentRow } from '../DocumentRow'
import { DocumentsTableHeader } from '../DocumentsTableHeader'
import { NormalizedContractDocumentType } from '../../../../../api/carSnoopApi/transactions/types'
import { AgreementGenerationLoader } from './AgreementGenerationLoader'
import {
  AGREEMENT_DOCUMENT_NAME,
  SIGNED_AGREEMENT_DOCUMENT_NAME
} from '../../../../../constants/applicationConstants'

const isCarsnoopAgreement = (name: string) =>
  name === AGREEMENT_DOCUMENT_NAME || name === SIGNED_AGREEMENT_DOCUMENT_NAME

interface DocumentsTableProps {
  viewType: 'seller' | 'buyer'
  documents: Array<NormalizedContractDocumentType>
  isEditable: boolean
  sellerID?: string
  buyerID?: string
  isUploadAllowed: boolean
  showAgreementLoader?: boolean
}

export const DocumentsTable: FC<DocumentsTableProps> = ({
  documents,
  isEditable,
  viewType,
  isUploadAllowed,
  showAgreementLoader,
  ...transactionIDs
}) => {
  return (
    <Grid container rowSpacing={1}>
      <Grid item xs={12}>
        <DocumentsTableHeader viewType={viewType} />
      </Grid>
      <Show when={showAgreementLoader}>
        <AgreementGenerationLoader />
      </Show>
      <For of={documents}>
        {(document) => (
          <Grid item xs={12}>
            <DocumentRow
              isEditable={isEditable}
              isUploadAllowed={isUploadAllowed}
              viewType={viewType}
              {...document}
              {...transactionIDs}
              isCarsnoopAgreement={isCarsnoopAgreement(document.name)}
            />
          </Grid>
        )}
      </For>
    </Grid>
  )
}
