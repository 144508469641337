import { Switch as MUISwitch, styled } from '@mui/material'
import { ChangeEventHandler, FC, useCallback } from 'react'
import { COLORS } from '../../types/colors'

const StyledSwitch = styled(MUISwitch)(() => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    '& + .MuiSwitch-track': {
      backgroundColor: '#7C8EA6A1'
    }
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: COLORS.main_yellow,
    width: 20,
    height: 20
  },
  '& .MuiSwitch-track': {
    backgroundColor: '#7C8EA6A1'
  }
}))

export type SwitchProps = {
  checked: boolean
  onChange?: (checked: boolean) => void
}

export const Switch: FC<SwitchProps> = (props) => {
  const { checked, onChange } = props

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const {
        target: { checked: isChecked }
      } = e
      onChange && onChange(isChecked)
    },
    [onChange]
  )

  return <StyledSwitch checked={checked} onChange={handleChange} />
}
