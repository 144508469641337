export const desktopSizes = {
  container: '30vh',
  height: '30%',
  width: 'fit-content'
}

export const tabletSizes = {
  container: '20vh',
  height: '50%',
  width: 'fit-content'
}

export const fiveColumns = 12 / 5
export const threeColumns = 12 / 3
