import { useState } from 'react'
import { Toggle } from '../../../../atoms/Toggle'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const ToggleExample = () => {
  const [isPublic, setIsPublic] = useState(false)

  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <Toggle
          checked={isPublic}
          onChange={(_, checked) => setIsPublic(checked)}
          leftLabel='Bicycle'
          rightLabel='Vehicle'
        />
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
