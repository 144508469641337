import { useCallback } from 'react'
import type { FindCarFormFormState } from '../../../store/units/findCarForm/types'
import { useRestoreData } from '../../../utils/hooks'
import { useVerificationPaymentRedirect } from './useVerificationPaymentRedirect'

export const FIND_CAR_FORM_FORM_KEY = 'findCarFormKey'
export const RESULT_PARAM = 'result'

// Just to separate logic
const usePaymentRedirect = () => {
  const generateSuccessUrl = useCallback((): string => {
    const baseUrl = window.location.origin
    const baseUrlPath = window.location.pathname

    const successUrl = new URL('/', baseUrl)
    successUrl.pathname = `${baseUrlPath}`
    successUrl.searchParams.append(RESULT_PARAM, 'show')

    return successUrl.href
  }, [])

  const {
    getPaymentRedirectUrl,
    isPaymentOpen,
    redirectToPayment,
    handleRedirectFail
  } = useVerificationPaymentRedirect(generateSuccessUrl)

  return {
    getPaymentRedirectUrl,
    isPaymentOpen,
    redirectToPayment,
    handleRedirectFail
  }
}

export const useRestoreFindCarForm = () => {
  const { restoredData, isRestoring, storeData, clearData } =
    useRestoreData<FindCarFormFormState | null>(FIND_CAR_FORM_FORM_KEY)

  const {
    getPaymentRedirectUrl,
    isPaymentOpen,
    redirectToPayment,
    handleRedirectFail
  } = usePaymentRedirect()

  return {
    isRestoring,
    restoredData,
    isPaymentOpen,
    redirectToPayment,
    storeData,
    clearData,
    handleRedirectFail,
    getPaymentRedirectUrl
  }
}
