export enum FIELDS_NAMES {
  ADDRESS_NAME = 'addressName',
  STREET = 'street',
  ZIP_CODE = 'zipCode',
  CITY = 'city',
  STATE = 'state',
  CONTACT_NAME = 'contactName',
  CONTACT_NUMBER = 'contactNumber',
  CONTACT_EMAIL = 'contactEmail',
  NOTES = 'notes'
}

export enum FIELDS_ERRORS {
  ADDRESS_NAME = 'Enter a valid address name.',
  STREET = 'Enter a valid Street.',
  ZIP_CODE = 'Enter a valid Zip Code.',
  CONTACT_NAME = 'Enter a valid Contact Name.',
  CONTACT_NUMBER = 'Enter a valid Contact Number.',
  CONTACT_EMAIL = 'Enter a valid Contact Email.',
  NOTES = 'Enter a valid data format.'
}

export enum FIELDS_LABELS {
  ADDRESS_NAME = 'Address Name',
  STREET = 'Street',
  ZIP_CODE = 'Zip Code',
  CITY = 'City',
  STATE = 'State',
  CONTACT_NAME = 'Contact Name',
  CONTACT_NUMBER = 'Contact Number',
  CONTACT_EMAIL = 'Contact Email',
  NOTES = 'You may leave any additional information here.'
}

export type FormFields = {
  [FIELDS_NAMES.ADDRESS_NAME]: string
  [FIELDS_NAMES.STREET]: string
  [FIELDS_NAMES.ZIP_CODE]: string
  [FIELDS_NAMES.CITY]: string
  [FIELDS_NAMES.STATE]: string
  [FIELDS_NAMES.CONTACT_NAME]: string
  [FIELDS_NAMES.CONTACT_NUMBER]: string
  [FIELDS_NAMES.CONTACT_EMAIL]: string
  [FIELDS_NAMES.NOTES]: string
}
