import { useState, ChangeEvent } from 'react'
import { Grid, Typography } from '@mui/material'
import { RadioGroup } from '../../../../atoms/RadioGroup'
import { stringToReferenceModel } from '../../../../utils/autoselect/stringToReferenceModel'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

const options = ['Option 1', 'Option 2', 'Option 3'].map(stringToReferenceModel)

export const RadioGroupExample = () => {
  const [value, setValue] = useState(options[0].id)

  const handleChange = (
    _: ChangeEvent<HTMLInputElement>,
    nextValue: string
  ) => {
    setValue(nextValue)
  }

  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <Grid container spacing={4}>
          <Grid item>
            <Typography variant='main'>Column</Typography>
            <RadioGroup
              name='radio-group'
              options={options}
              value={value}
              onChange={handleChange}
            />
          </Grid>

          <Grid item>
            <Typography variant='main'>Row</Typography>
            <RadioGroup
              name='radio-group-row'
              options={options}
              value={value}
              onChange={handleChange}
              row
            />
          </Grid>
        </Grid>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
