import { Grid, Link, Typography } from '@mui/material'
import { FC, ReactNode, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { routes } from '../../../../routes'
import { COLORS } from '../../../../types/colors'
import { useUserInfo } from '../../../../utils/hooks'

interface UploadVehiclePhotoGreetingMessageProps {
  carName: string
  hintComponent?: ReactNode
}

export const UploadVehiclePhotoGreetingMessage: FC<
  UploadVehiclePhotoGreetingMessageProps
> = ({ carName, hintComponent }) => {
  const { isDealer } = useUserInfo()

  const { linkText, linkRoute } = useMemo(
    () => ({
      linkText: isDealer ? 'Inventory' : 'Garage',
      linkRoute: isDealer ? routes.inventory : routes.garage
    }),
    [isDealer]
  )

  const hintElement = useMemo(
    () =>
      hintComponent || (
        <Typography variant='h6'>
          You may add photos here or go to{' '}
          <Link
            underline='none'
            component={NavLink}
            to={linkRoute}
            display='inline-flex'
          >
            <Typography variant='h6' color={COLORS.text_blue}>
              {linkText} Page.
            </Typography>
          </Link>
        </Typography>
      ),
    [hintComponent]
  )

  return (
    <Grid item container xs={12} alignItems='baseline'>
      <Grid item mr={1}>
        <Typography variant='h6'>
          Thank you for adding your vehicle{' '}
          <Typography variant='title1'>{carName}</Typography>!
        </Typography>
      </Grid>
      <Grid item>{hintElement}</Grid>
    </Grid>
  )
}
