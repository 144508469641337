import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { splitByProperty } from '../../../utils/splitByProperty'
import { requestTabNavigation } from '../../thunks/pageNavigation'
import { SearchComponentProps } from '../../../features/SearchInventory'
import { completedSelectOptionsList, splitSchema } from './constants'
import { NormalizedDealsListType } from '../../types'
import { NormalizedReadTransactionsList } from '../../../api/carSnoopApi/transactions/types'

export type SelectedCompletedStatus = typeof completedSelectOptionsList[number]

export type CompletedDealsListMap = Record<
  SelectedCompletedStatus,
  NormalizedReadTransactionsList
>

export const getDealsListMap = (dealsList: NormalizedDealsListType) => {
  const deals = dealsList || []
  return splitByProperty(deals, splitSchema).reduce(
    (acc, dealList, index) => {
      return Object.assign(acc, { [splitSchema.status[index]]: dealList })
    },
    { All: dealsList } as CompletedDealsListMap
  )
}

const getMatchedDealsList = (deals: NormalizedDealsListType) => {
  if (deals) {
    return deals.filter((item) =>
      completedSelectOptionsList.includes(item.status)
    )
  }

  return deals
}
interface CompletedDealsState {
  selectedStatus: SelectedCompletedStatus
  selectOptions: readonly SelectedCompletedStatus[]
  dealsList: NormalizedDealsListType
  dealsListMap: CompletedDealsListMap
  displayList: NormalizedDealsListType
  searchFormValues: SearchComponentProps['initialValues']
}

export const initialState: CompletedDealsState = {
  selectedStatus: 'All',
  selectOptions: completedSelectOptionsList,
  dealsListMap: getDealsListMap(null),
  dealsList: null,
  displayList: null,
  searchFormValues: {
    search: ''
  }
}

export const completedDealsPageSlice = createSlice({
  name: 'completedDealsPage',
  initialState,
  reducers: {
    setDealsList: (store, action: PayloadAction<NormalizedDealsListType>) => {
      const deals = getMatchedDealsList(action.payload)

      store.dealsList = deals
      store.displayList = deals
      store.dealsListMap = getDealsListMap(deals)
    },
    setSelectResults: (
      store,
      action: PayloadAction<{
        data: NormalizedDealsListType
        status: SelectedCompletedStatus
      }>
    ) => {
      store.selectedStatus = action.payload.status
      store.displayList = getMatchedDealsList(action.payload.data)
    },
    setSearchFormValues: (
      store,
      action: PayloadAction<CompletedDealsState['searchFormValues']>
    ) => {
      store.searchFormValues = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(requestTabNavigation.fulfilled, () => {
      return {
        ...initialState
      }
    })
  }
})
