import { Grid } from '@mui/material'
import { FC, useCallback, useState } from 'react'
import { SelectElement } from '../../../../atoms/SelectElement'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

const testOptions = [
  { id: '1', name: 'Opt 1' },
  { id: '2', name: 'Opt 2' },
  { id: '3', name: 'Opt 3' }
]

type SelectStatuses = 'idle' | 'pending' | 'succeeded' | 'failed'

export const SelectElementExample: FC = () => {
  const [selectedValueFirst, setValueFirst] = useState({ id: '', name: '' })
  const [selectedValueSecond, setValueSecond] = useState({ id: '', name: '' })
  const [selectError, setSelectError] = useState('')
  const [selectStatus, setStatus] = useState<SelectStatuses>('succeeded')
  const handleSelectItemFirst = useCallback(
    (chosenValue: { id: string; name: string }) => {
      setValueFirst(chosenValue)
    },
    [setValueFirst]
  )
  const handleSelectItemSecond = useCallback(
    (chosenValue: { id: string; name: string }) => {
      setValueSecond(chosenValue)
    },
    [setValueSecond]
  )

  const handleSecondSelectClick = useCallback(() => {
    setStatus('pending')
    setTimeout(() => {
      setStatus('succeeded')
    }, 1000)
  }, [setStatus])

  const handleThirdSelectClick = useCallback(() => {
    setStatus('pending')
    setTimeout(() => {
      setStatus('failed')
      setSelectError('Something heppend')
    }, 1000)
  }, [setStatus])

  return (
    <ComponentContainer fullWidth>
      <ComponentWithPaperBackground>
        <Grid
          container
          display='flex'
          flexDirection='column'
          spacing={3}
          xs={6}
        >
          <Grid item>
            <SelectElement
              value={selectedValueFirst.id}
              name='select-test'
              label='Simple select'
              placeholder='Choose something'
              status='succeeded'
              options={testOptions}
              onClick={handleSelectItemFirst}
            />
          </Grid>
          <Grid item>
            <SelectElement
              value={selectedValueSecond.id}
              name='select-test'
              label='Select with fetching'
              placeholder='Choose something'
              status={selectStatus}
              options={testOptions}
              onClick={handleSelectItemSecond}
              onSelectClick={handleSecondSelectClick}
            />
          </Grid>
          <Grid item>
            <SelectElement
              value=''
              name='select-test'
              label='Select with fetching error'
              placeholder='Choose something'
              status={selectStatus}
              options={testOptions}
              loadingError={selectError}
              onSelectClick={handleThirdSelectClick}
            />
          </Grid>
          <Grid item>
            <SelectElement
              value={selectedValueSecond.id}
              name='select-test'
              label='Disabled'
              placeholder='Choose something'
              isDisabled
              status={selectStatus}
              options={testOptions}
            />
          </Grid>
        </Grid>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
