import { CompressImageSuccessResponse } from '../../../utils/compressImages'
import { useApiContext, useMakeService } from '../../../utils/hooks'

export const useUploadImage = (
  vehicleId: string,
  onLoadCallback?: () => void
) => {
  const { carSnoopApi } = useApiContext()

  const [uploadVehiclePhoto, uploadVehiclePhotoObservable] = useMakeService(
    async ({
      data,
      thumbnail = false
    }: {
      data: CompressImageSuccessResponse
      id?: string
      thumbnail: boolean
    }) => {
      const { cleanBase64, name, fileExtension } = data
      const fileName = name.split('.')[0]
      const res = await carSnoopApi.vehicles.createVehiclePhoto({
        vehicleId,
        name: fileName,
        type: fileExtension,
        content: cleanBase64,
        thumbnail
      })

      onLoadCallback?.()
      return res
    }
  )

  return [uploadVehiclePhoto, uploadVehiclePhotoObservable] as [
    typeof uploadVehiclePhoto,
    typeof uploadVehiclePhotoObservable
  ]
}
