import { FC } from 'react'
import { OrNull } from '../../../../types/OrNull'
import { Show } from '../../../../atoms/JSXExtensions'
import { MiniChatHeader } from './MiniChatHeader'
import { ChatHeader } from './ChatHeader'
import { ChatListItemType } from '../../model/types'

interface MiniChatHeaderContainerProps {
  onClose: () => void
  onBack: () => void
  currentChat: OrNull<ChatListItemType>
}

export const MiniChatHeaderContainer: FC<MiniChatHeaderContainerProps> = ({
  currentChat,
  onClose,
  onBack
}) => {
  return (
    <Show when={currentChat} fallback={<MiniChatHeader onClose={onClose} />}>
      {(chat) => <ChatHeader chat={chat} onBack={onBack} />}
    </Show>
  )
}
