import { FC, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import { FormContainer } from '../../layout/FormContainer'
import { FormStepper } from '../../atoms/FormStepper'
import { PersonalDetailsScreen } from '../screens/PersonalDetails'
import {
  useAppDispatch,
  useAppSelector,
  useMakeService,
  useRegisterUser,
  useUserInfo
} from '../../utils/hooks'
import {
  actionsExternalServiceRegistration,
  extSelectFormLength,
  extSelectRegistrationStep,
  extSelectPersonalDetailsData,
  extSelectContactDetailsData,
  extSelectIsExitState
} from '../../store/pages/externalServiceRegistration'
import {
  actionsUserInfo,
  UserInfoStatusMap
} from '../../store/features/userInfo'
import { ContactDetailsScreen } from '../screens/ContactDetails'
import { PreparingAccountScreen } from '../screens/PreparingAccount'
import { routes } from '../../routes'
import { ContactDetails } from '../../types/forms/registration'
import { ResponseStatusMap } from '../../types/status'
import { UserTypes } from '../../types/enums/UserTypes'

export const RegistrationExternalServiceForm: FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [registerUser] = useRegisterUser()
  const isExitState = useAppSelector(extSelectIsExitState)

  useEffect(() => {
    if (isExitState) {
      dispatch(actionsExternalServiceRegistration.resetState())
      dispatch(actionsUserInfo.logout())
      navigate(routes.login)
    }
  }, [isExitState])

  const screenStep = useAppSelector(extSelectRegistrationStep)
  const screenLength = useAppSelector(extSelectFormLength)
  const personalDetailsScreenData = useAppSelector(extSelectPersonalDetailsData)
  const contactDetailsScreenData = useAppSelector(extSelectContactDetailsData)
  const userInfo = useUserInfo()

  const [registrationService, registrationObservable] = useMakeService(
    async (data: ContactDetails) => {
      dispatch(actionsExternalServiceRegistration.setContactDetails(data))
      dispatch(actionsExternalServiceRegistration.incrementScreenStep())

      const response = await registerUser({
        personalDetails: personalDetailsScreenData,
        contactDetails: data,
        options: {
          idp: userInfo.idp,
          accountId:
            personalDetailsScreenData.userType === UserTypes.DEALER &&
            personalDetailsScreenData.dealership?.id,
          externalServiceToken: userInfo.sub,
          avatar: userInfo.avatar
        }
      })

      if (response.status === ResponseStatusMap.Success) {
        dispatch(
          actionsUserInfo.setUserStatus({
            status: UserInfoStatusMap.Unauthenticated
          })
        )
        navigate(routes.login)
        dispatch(actionsExternalServiceRegistration.resetState())
      } else {
        dispatch(actionsExternalServiceRegistration.decrementScreenStep())
      }

      return response
    },
    { withStatusNotification: [1] }
  )

  const initialContactErrors = useMemo(
    () => ({
      email: registrationObservable.payload.isNotVerified
        ? ''
        : registrationObservable.payload.message
    }),
    [registrationObservable]
  )

  const screenFlow = [
    <PersonalDetailsScreen
      initialValues={{
        ...personalDetailsScreenData,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName
      }}
      actions={actionsExternalServiceRegistration}
    />,
    <ContactDetailsScreen
      initialValues={contactDetailsScreenData}
      actions={actionsExternalServiceRegistration}
      staticValues={{
        email: userInfo.id
      }}
      onSubmit={registrationService}
      isLoading={registrationObservable.isLoading}
      initialErrors={initialContactErrors}
    />,
    <PreparingAccountScreen />
  ]

  const formTitle = screenStep === 2 ? 'Preparing the Account...' : 'Register'

  return (
    <FormContainer title={formTitle}>
      <Grid item>
        <FormStepper target={screenStep} count={screenLength - 1} />
      </Grid>
      <Grid container item flexGrow={1}>
        {screenFlow[screenStep]}
      </Grid>
    </FormContainer>
  )
}
