import { OrNull } from '../../../../types/OrNull'
import { CobuyerOptionsType } from './dependentFields'

export enum FIELD_NAMES {
  ADD_COBUYER_TOGGLE = 'addCobuyerToggle',
  COBUYER_EMAIL = 'cobuyerEmail',
  COBUYER_NAME = 'cobuyerName',
  COBUYER_ADDRESS = 'cobuyerAddress',
  ZIP_CODE = 'zipCode',
  CITY = 'city',
  STATE = 'state'
}

export enum FIELD_LABELS {
  COBUYER_EMAIL = 'Co-Buyer Email',
  COBUYER_NAME = 'Co-Buyer Legal Name',
  COBUYER_ADDRESS = 'Co-Buyer Legal Address',
  ZIP_CODE = 'Zip code',
  CITY = 'City',
  STATE = 'State'
}

export enum FIELD_ERRORS {
  COBUYER_NAME = 'Enter a valid Legal Name.',
  COBUYER_ADDRESS = 'Enter a valid Street format.',
  ZIP_CODE = 'Enter a valid Zip Code.'
}

export interface CobuyerFieldsValues {
  [FIELD_NAMES.ADD_COBUYER_TOGGLE]: boolean
  [FIELD_NAMES.COBUYER_EMAIL]: OrNull<CobuyerOptionsType>
  [FIELD_NAMES.COBUYER_NAME]: string
  [FIELD_NAMES.COBUYER_ADDRESS]: string
  [FIELD_NAMES.ZIP_CODE]: string
  [FIELD_NAMES.CITY]: string
  [FIELD_NAMES.STATE]: string
}

export enum TOGGLE_OPTIONS {
  YES = 'Yes',
  NO = 'No'
}

export const initialValues: CobuyerFieldsValues = {
  // True is a left position of toggle so "Yes" option is false
  [FIELD_NAMES.ADD_COBUYER_TOGGLE]: true,
  [FIELD_NAMES.COBUYER_EMAIL]: null,
  [FIELD_NAMES.COBUYER_NAME]: '',
  [FIELD_NAMES.COBUYER_ADDRESS]: '',
  [FIELD_NAMES.ZIP_CODE]: '',
  [FIELD_NAMES.CITY]: '',
  [FIELD_NAMES.STATE]: ''
}
