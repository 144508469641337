import { Grid } from '@mui/material'
import { FC } from 'react'
import { DefaultEmptyState } from '../../../../atoms/DefaultEmptyState'
import { For, Show } from '../../../../atoms/JSXExtensions'
import { ChatListItemType } from '../../model/types'
import { ChatsContainer } from './ChatList.styles'
import { ChatItem } from './components/ChatItem'

interface IChatList {
  chatList: Array<ChatListItemType>
  onChatClick(chat: ChatListItemType): void
}

export const ChatList: FC<IChatList> = ({ chatList, onChatClick }) => {
  return (
    <ChatsContainer container>
      <Show
        when={chatList.length}
        fallback={
          <Grid container justifyContent='center' mt='140px'>
            <DefaultEmptyState text='The chat list is empty' />
          </Grid>
        }
      >
        <For of={chatList}>
          {(chatInfo) => (
            <Grid item key={chatInfo.transactionId}>
              <ChatItem {...chatInfo} onChatClick={onChatClick} />
            </Grid>
          )}
        </For>
      </Show>
    </ChatsContainer>
  )
}
