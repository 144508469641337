import { Grid, Typography, TypographyTypeMap } from '@mui/material'
import { FC } from 'react'
import { BackArrow } from '../../assets/icons/BackArrow'
import { ColoredButton } from '../Button'
import { Condition } from '../Condition'
import { Show } from '../JSXExtensions'

interface INavigationBackButtonProps {
  buttonMinWidth?: string
  buttonText: string | JSX.Element
  gridSpacing?: number
  onBackClick: () => void
  showBackButton?: boolean
  textVariant?: TypographyTypeMap['props']['variant']
}

export const NavigationBackButton: FC<INavigationBackButtonProps> = ({
  buttonMinWidth = 'unset',
  buttonText,
  gridSpacing = 0,
  onBackClick,
  showBackButton = true,
  textVariant = 'title1',
}) => {
  return (
    <Grid
      container
      item
      alignItems='center'
      spacing={gridSpacing}
      maxHeight='66px'
      wrap='nowrap'
    >
      <Show when={showBackButton}>
        <Grid item alignItems='center'>
          <ColoredButton
            sx={{
              minWidth: buttonMinWidth,
              padding: '6px 8px 6px 0px',
            }}
            onClick={onBackClick}
          >
            <BackArrow />
          </ColoredButton>
        </Grid>
      </Show>
      <Grid item>
        <Condition
          condition={typeof buttonText === 'string'}
          trueContent={
            <Typography component='h2' variant={textVariant}>{buttonText}</Typography>
          }
          falseContent={buttonText as JSX.Element}
        />
      </Grid>
    </Grid>
  )
}
