import { FC, useMemo, useCallback } from 'react'
import { Grid, Typography } from '@mui/material'
import { Field, FieldProps, Formik } from 'formik'
import LockIcon from '../../../assets/icons/LockIcon'
import { ScreenContainer } from '../../../layout/ScreenContainer'
import { VerificationInput } from '../../../atoms/VerificationInput'
import { ActionBlock } from '../../../atoms/ActionBlock'
import { LinkButton } from '../../../atoms/LinkButton'
import {
  VerificationCode,
  VerificationCodeStep
} from '../../../types/forms/registration'
import { formatEmail } from '../../../utils/formatters/formatEmail'
import { validationSchema } from './validationSchema'
import {
  VERIFICATION_CODE_LENGTH,
  VERIFICATION_CODE_BLOCKED_ERROR,
  VerificationCodeText
} from './constants'
import { carSnoopApi } from '../../../api'
import { Condition } from '../../../atoms/Condition'
import {
  useCountdown,
  useAppDispatch,
  returnTimerString
} from '../../../utils/hooks'
import { actionsManualRegistration } from '../../../store/units/registrationForm/actions'
import {
  IconContainer,
  ResendButton,
  SubtitleStyles,
  TitleStyles,
  VerificationCodeForm,
  VerificationInputContainer
} from './VerificationCodeScreen.styles'

type VerificationCodeScreenProps = {
  isLoading?: boolean
  onNextBtnClicked: (value: VerificationCode) => void
  onBackBtnClicked: () => void
  initialValues: VerificationCodeStep
  initialErrors?: Partial<VerificationCode>
  dependencies: {
    email: string
  }
}

export const VerificationCodeScreen: FC<VerificationCodeScreenProps> = ({
  onNextBtnClicked,
  onBackBtnClicked,
  dependencies,
  initialValues,
  initialErrors,
  isLoading
}) => {
  const email = formatEmail(dependencies.email)
  const dispatch = useAppDispatch()

  const onTimerEnd = useCallback(() => {
    dispatch(
      actionsManualRegistration.setIsVerificationBlocked({
        isVerificationBlocked: false,
        blockedUntil: 0
      })
    )
  }, [dispatch])

  const { minutes, seconds } = useCountdown(
    initialValues.blockedUntil,
    onTimerEnd
  )

  const resendCodeHandler = useCallback(async () => {
    const response = await carSnoopApi.users.resendCode(dependencies.email)

    dispatch(
      actionsManualRegistration.setIsVerificationBlocked({
        isVerificationBlocked: response.isBlocked,
        blockedUntil: response.blockedUntil
      })
    )
  }, [dependencies.email])

  const validateVerificationCode = (value: string): string | undefined => {
    if (!value && initialErrors?.verificationCode) {
      return initialErrors.verificationCode
    }
  }

  const timerString = useMemo(
    () => returnTimerString(minutes, seconds),
    [minutes, seconds]
  )

  const isInitiallyBlocked = initialValues.isVerificationBlocked
  const isTimerGoing = minutes + seconds > 0
  const isPermanentlyBlocked = !initialValues.blockedUntil

  const blockedError = useMemo(
    () =>
      isInitiallyBlocked && isPermanentlyBlocked
        ? VERIFICATION_CODE_BLOCKED_ERROR.PERMANENTLY
        : VERIFICATION_CODE_BLOCKED_ERROR.TEMPORARY.replace(
            '{{locked_until}}',
            timerString
          ),
    [isInitiallyBlocked, isPermanentlyBlocked, timerString]
  )

  const isVerificationBlocked = useMemo(
    () => isInitiallyBlocked && (isTimerGoing || isPermanentlyBlocked),
    [isInitiallyBlocked, isTimerGoing, isPermanentlyBlocked]
  )

  return (
    <ScreenContainer isLoading={isLoading}>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        initialErrors={initialErrors}
        initialTouched={{
          verificationCode: true
        }}
        onSubmit={onNextBtnClicked}
        validateOnMount
      >
        {(formik) => {
          return (
            <VerificationCodeForm>
              <IconContainer>
                <LockIcon />
              </IconContainer>
              <TitleStyles>{VerificationCodeText.title}</TitleStyles>
              <SubtitleStyles>
                <p>{VerificationCodeText.subtitle}</p>
                <h3>{email}</h3>
              </SubtitleStyles>
              <VerificationInputContainer>
                <Field
                  name='verificationCode'
                  validate={validateVerificationCode}
                >
                  {({ field }: FieldProps) => (
                    <VerificationInput
                      autoFocus={
                        !initialErrors?.verificationCode &&
                        !isVerificationBlocked
                      }
                      validChars='0-9'
                      value={field.value}
                      length={VERIFICATION_CODE_LENGTH}
                      error={
                        !isInitiallyBlocked && formik.errors.verificationCode
                      }
                      onChange={(value) =>
                        formik.setFieldValue(field.name, value)
                      }
                      disabled={isVerificationBlocked}
                    />
                  )}
                </Field>
              </VerificationInputContainer>
              <Condition
                condition={isVerificationBlocked}
                trueContent={
                  <Grid item sx={{ mb: '40px', textAlign: 'center' }} xs={12}>
                    <Typography
                      variant='error'
                      sx={{
                        whiteSpace: 'pre-wrap',
                        fontSize: '14px'
                      }}
                    >
                      {blockedError}
                    </Typography>
                  </Grid>
                }
                falseContent={
                  <ResendButton>
                    <p>
                      {VerificationCodeText.resend}{' '}
                      <LinkButton label='Resend' onClick={resendCodeHandler} />
                    </p>
                  </ResendButton>
                }
              />
              <ActionBlock
                schema={[
                  {
                    label: 'Back',
                    type: 'secondary',
                    onClick: onBackBtnClicked
                  },
                  {
                    label: 'Next',
                    type: 'primary',
                    disabled: !(formik.dirty && formik.isValid),
                    props: {
                      type: 'submit'
                    }
                  }
                ]}
              />
            </VerificationCodeForm>
          )
        }}
      </Formik>
    </ScreenContainer>
  )
}
