import { IPriceComponent } from '../../api/carSnoopApi/offers/types'
import { parseNumber } from '../../utils'
import { IAddOnFormValues } from '../../molecules/AddOn'
import { OFFER_MODAL_FIELDS } from './OfferModal.constants'
import { IOfferModalForm } from './OfferModal.types'
import { NormalizedOfferType } from '../../api/carSnoopApi/transactions/types'
import { OrNull } from '../../types/OrNull'

// total = [subtotal] + [sales tax] (this value we display as total on the ui)
export const calculateTotalCost = (formValues: IOfferModalForm) => {
  const price = parseNumber(formValues[OFFER_MODAL_FIELDS.VEHICLE_PRICE])
  const tradeIn = parseNumber(formValues[OFFER_MODAL_FIELDS.TRADE_IN] || '0')
  const salesTax = parseNumber(formValues[OFFER_MODAL_FIELDS.SALES_TAX])
  const addOnsCost = formValues[OFFER_MODAL_FIELDS.ADD_ONS].reduce(
    (acc: number, addOn: IAddOnFormValues) => {
      return acc + parseNumber(addOn.price) * addOn.quantity
    },
    0
  )
  const totalSum = price - tradeIn + addOnsCost + salesTax

  return totalSum.toFixed(2)
}

// subtotal = [vehicle price] + [add-ons] - [trade in] (this is a subject for sales tax calculation)
export const calculateSubtotalCost = (formValues: IOfferModalForm) => {
  const price = parseNumber(formValues[OFFER_MODAL_FIELDS.VEHICLE_PRICE])
  const tradeIn = parseNumber(formValues[OFFER_MODAL_FIELDS.TRADE_IN] || '0')
  const addOnsCost = formValues[OFFER_MODAL_FIELDS.ADD_ONS].reduce(
    (acc: number, addOn: IAddOnFormValues) => {
      return acc + parseNumber(addOn.price) * addOn.quantity
    },
    0
  )

  return (price - tradeIn + addOnsCost).toString()
}

const descriptionMap: Record<
  | OFFER_MODAL_FIELDS.SALES_TAX
  | OFFER_MODAL_FIELDS.VEHICLE_PRICE
  | OFFER_MODAL_FIELDS.TRADE_IN,
  string
> = {
  [OFFER_MODAL_FIELDS.SALES_TAX]: 'Sales Tax',
  [OFFER_MODAL_FIELDS.VEHICLE_PRICE]: 'Vehicle Price',
  [OFFER_MODAL_FIELDS.TRADE_IN]: 'Trade-In Vehicle'
}

export const mapOfferData = (
  formData: IOfferModalForm,
  includeTradeIn: boolean
) => {
  const resultData: IPriceComponent<number>[] = [
    {
      description: descriptionMap[OFFER_MODAL_FIELDS.SALES_TAX],
      quantity: 1,
      price: parseNumber(formData[OFFER_MODAL_FIELDS.SALES_TAX])
    },
    {
      description: descriptionMap[OFFER_MODAL_FIELDS.VEHICLE_PRICE],
      quantity: 1,
      price: parseNumber(formData[OFFER_MODAL_FIELDS.VEHICLE_PRICE])
    },
    {
      description: descriptionMap[OFFER_MODAL_FIELDS.TRADE_IN],
      quantity: 1,
      price: parseNumber(formData[OFFER_MODAL_FIELDS.TRADE_IN] || '0')
    }
  ]

  const filteredResultData = resultData.filter(
    (priceItem) => priceItem.description !== 'Trade-In Vehicle'
  )

  const addOnsWithDescription: IPriceComponent<number>[] = formData.addOns.map(
    (addOn: IAddOnFormValues) => {
      return {
        description: addOn.description,
        quantity: addOn.quantity,
        price: parseNumber(addOn.price)
      }
    }
  )

  return {
    priceComponents: includeTradeIn ? resultData : filteredResultData,
    addOns: addOnsWithDescription
  }
}

export const getOfferComponentPrice = (
  offer: OrNull<NormalizedOfferType>,
  component: 'Vehicle Price' | 'Sales Tax' | 'Trade-In Vehicle' | 'Deposit'
) =>
  Number(
    (offer?.priceComponents &&
      offer?.priceComponents.find((price) => price.description === component)
        ?.price) ||
      0
  )
