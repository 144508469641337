import { Grid } from '@mui/material'
import { FC, useEffect } from 'react'
import { Show } from '../../atoms/JSXExtensions'
import { Loader } from '../../atoms/Loader'
import { PageContainer } from '../../layout/PageContainer'
import { ContactInformationCard } from '../../molecules/ContactInformationCard'
import { actionsUserInfo } from '../../store/features/userInfo'
import { ResponseStatusMap } from '../../types/status'
import {
  useApiContext,
  useAppDispatch,
  useMakeService,
  useUserInfo
} from '../../utils/hooks'
import { Addresses } from './Addresses'
import { DealerAddressCard } from './DealerAddressCard'
import { DriverLicenseInformationCard } from './DriverLicenseInformationCard'
import { SSNInformationCard } from './SSNInformationCard'

export const PersonalDetailsPage: FC = () => {
  const {
    addresses,
    avatar,
    dateOfBirth,
    driverLicenseFile,
    driversLicense,
    driversLicenseExpiration,
    driversLicenseState,
    email,
    firstName,
    fullName,
    id,
    isAdmin,
    isDealer,
    lastName,
    phone,
    taxID,
  } = useUserInfo()
  const dispatch = useAppDispatch()
  const { carSnoopApi } = useApiContext()

  const [readUser, readUserObservable] = useMakeService(async () => {
    const userData = await carSnoopApi.users.readUser(id)

    if (userData.status === ResponseStatusMap.Success) {
      dispatch(actionsUserInfo.setUserPersonalInfo(userData))
      return userData
    }
    return { status: ResponseStatusMap.Error }
  })

  useEffect(() => {
    readUser()
  }, [])

  return (
    <PageContainer>
      <Grid
        container
        item
        spacing={2}
        xs={12}
      >
        <Show
          when={readUserObservable.isSuccess || readUserObservable.isError}
          fallback={<Loader />}
        >
          <>
            <Grid item xs={12} md={6}>
              <ContactInformationCard
                id={id}
                fullName={`${firstName} ${lastName}`}
                avatar={avatar}
                email={email}
                phone={phone}
                dob={dateOfBirth}
              />
            </Grid>
            <Show when={isDealer}>
              <Grid item xs={12} md={6}>
                <DealerAddressCard />
              </Grid>
            </Show>
            <Show when={!isDealer}>
              <>
                <Grid item xs={12} md={6}>
                  <Addresses
                    addresses={addresses}
                    isUserAdmin={isAdmin}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SSNInformationCard ssn={taxID} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DriverLicenseInformationCard
                    licenseNumber={driversLicense}
                    state={driversLicenseState}
                    fullName={fullName}
                    expirationDate={driversLicenseExpiration}
                    licenseDocument={driverLicenseFile}
                  />
                </Grid>
              </>
            </Show>
          </>
        </Show>
      </Grid>
    </PageContainer>
  )
}
