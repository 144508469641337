import { useCallback } from 'react'
import { sideBarActions } from '../../../store/features/SideBar'
import { SIDE_BAR } from '../../../store/features/SideBar/SideBar.types'
import { useAppDispatch } from '../../../utils/hooks'

export const useSupportSideBar = () => {
  const dispatch = useAppDispatch()
  const { openSideBar } = sideBarActions

  const openSupportSideBar = useCallback(
    ({ email, reason }: { email: string; reason: string }) => {
      dispatch(
        openSideBar({
          sideBarType: SIDE_BAR.CONTACT_SUPPORT,
          header: 'Contact Support',
          content: { email, reason }
        })
      )
    },
    [openSideBar]
  )

  return {
    openSupportSideBar
  }
}
