import { FC, useState, useEffect } from 'react'
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import OutlinedInput from '@mui/material/OutlinedInput'
import { Label } from '../Label'
import styles from './Select.module.css'
import { Loader } from '../Loader'
import { ErrorMessageBlock } from '../ErrorMessageBlock'
import { Condition } from '../Condition'
import { SelectElementContainer } from './SelectElement.styles'

type SelectElementProps = {
  name: string
  label?: string
  isDisabled?: boolean
  value?: string
  placeholder: string
  noResultMessage?: string
  loadingError?: string
  validationError?: string
  status: 'idle' | 'pending' | 'succeeded' | 'failed'
  options: Array<{
    id: string
    name: string
  }>
  onClick?: (option: { id: string; name: string }) => void
  onSelectClick?: () => void
}

export const SelectElement: FC<SelectElementProps> = ({
  name,
  label = '',
  isDisabled,
  value,
  placeholder,
  noResultMessage,
  loadingError,
  validationError,
  status,
  options,
  onClick,
  onSelectClick
}) => {
  const [isPlaceholder, setIsPlaceholder] = useState<boolean>(!value)
  const [isError, setIsError] = useState<boolean>(false)

  useEffect(() => {
    if (!value) {
      setIsPlaceholder(true)
    }
  }, [value, setIsPlaceholder])

  return (
    <SelectElementContainer
      hasError={isError && !!validationError}
      isDisabled={!!isDisabled}
    >
      {label && <Label name={name} label={label} />}
      <FormControl fullWidth focused={false}>
        {placeholder && isPlaceholder && (
          <InputLabel id={name}>{label}</InputLabel>
        )}
        <Select
          labelId={name}
          value={value}
          disabled={isDisabled}
          input={
            <OutlinedInput
              classes={{ disabled: styles.disabled, focused: styles.focused }}
            />
          }
          onOpen={() => {
            setIsPlaceholder(false)
            onSelectClick && onSelectClick()
          }}
          onBlur={() => {
            setIsPlaceholder(!value)
            setIsError(!value)
          }}
        >
          {status === 'pending' && (
            <MenuItem className={styles.SelectElement_InfoBlock}>
              <Loader />
            </MenuItem>
          )}
          {status === 'failed' && loadingError && (
            <MenuItem className={styles.SelectElement_InfoBlock}>
              {loadingError}
            </MenuItem>
          )}
          {status === 'succeeded' && !options.length && noResultMessage && (
            <MenuItem className={styles.SelectElement_InfoBlock}>
              {noResultMessage}
            </MenuItem>
          )}
          {status === 'succeeded' &&
            options.map((option) => {
              return (
                <MenuItem
                  key={option.id}
                  value={option.id}
                  onClick={() => onClick && onClick(option)}
                >
                  {option.name}
                </MenuItem>
              )
            })}
        </Select>
      </FormControl>
      <Condition
        condition={isError && !!validationError}
        trueContent={<ErrorMessageBlock message={validationError as string} />}
      />
    </SelectElementContainer>
  )
}
