import { Grid, Typography } from '@mui/material'
import { FC, useCallback, useState } from 'react'
import { objectKeys } from '../../../utils/objectKeys'
import {
  COMPONENT,
  COMPONENTS_MAP,
  ComponentsType
} from './ComponentsPreview.constants'
import { AccordionItem } from './components/AccordionItem'

interface IComponentState {
  label: keyof typeof COMPONENT
  itemName: ComponentsType
}

const DEFAULT_COMPONENT_TYPE = COMPONENT.Atoms
const DEFAULT_ITEM_INDEX = 0

export const ComponentsPreviewPage: FC = () => {
  const [selectedComponent, setComponent] = useState<IComponentState>({
    label: DEFAULT_COMPONENT_TYPE as keyof typeof COMPONENT,
    itemName: objectKeys(COMPONENTS_MAP[DEFAULT_COMPONENT_TYPE])[
      DEFAULT_ITEM_INDEX
    ] as ComponentsType
  })
  const handleMenuItemClick = useCallback(
    (label: keyof typeof COMPONENT, itemName: ComponentsType) => {
      setComponent({ label, itemName })
    },
    [setComponent]
  )

  return (
    <Grid container height='100vh' width='100%' flexWrap='nowrap'>
      <Grid
        item
        p='24px 0 70px 16px'
        minWidth='300px'
        width='300px'
        overflow='scroll'
        boxShadow='rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px'
        sx={{ backgroundColor: 'white' }}
      >
        <AccordionItem
          defaultExpanded={
            (DEFAULT_COMPONENT_TYPE as keyof typeof COMPONENT) ===
            COMPONENT.Atoms
          }
          selectedItem={selectedComponent.itemName}
          onItemClick={handleMenuItemClick}
          itemsList={
            Object.keys(COMPONENTS_MAP[COMPONENT.Atoms]) as ComponentsType[]
          }
          label={COMPONENT.Atoms}
        />
        <AccordionItem
          defaultExpanded={
            (DEFAULT_COMPONENT_TYPE as keyof typeof COMPONENT) ===
            COMPONENT.Molecules
          }
          selectedItem={selectedComponent.itemName}
          onItemClick={handleMenuItemClick}
          itemsList={
            Object.keys(COMPONENTS_MAP[COMPONENT.Molecules]) as ComponentsType[]
          }
          label={COMPONENT.Molecules}
        />
        <AccordionItem
          defaultExpanded={
            (DEFAULT_COMPONENT_TYPE as keyof typeof COMPONENT) ===
            COMPONENT.Features
          }
          selectedItem={selectedComponent.itemName}
          onItemClick={handleMenuItemClick}
          itemsList={
            Object.keys(COMPONENTS_MAP[COMPONENT.Features]) as ComponentsType[]
          }
          label={COMPONENT.Features}
        />
        <AccordionItem
          defaultExpanded={
            (DEFAULT_COMPONENT_TYPE as keyof typeof COMPONENT) ===
            COMPONENT.Icons
          }
          selectedItem={selectedComponent.itemName}
          onItemClick={handleMenuItemClick}
          itemsList={
            Object.keys(COMPONENTS_MAP[COMPONENT.Icons]) as ComponentsType[]
          }
          label={COMPONENT.Icons}
        />
      </Grid>
      <Grid item flexGrow={1} p={4} overflow='scroll'>
        <Grid item ml='8px'>
          <Typography variant='h6'>
            {selectedComponent.label} / {selectedComponent.itemName}
          </Typography>
        </Grid>
        <Grid item overflow='scroll'>
          {COMPONENTS_MAP[selectedComponent.label][selectedComponent.itemName]}
        </Grid>
      </Grid>
    </Grid>
  )
}
