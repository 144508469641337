import isEmail from 'validator/lib/isEmail'

export const isValidEmail =
  (ignoreEmpty: boolean = true) =>
  (email?: string) => {
    if (!email) {
      return ignoreEmpty
    }

    return isEmail(email.replace(/(\(.+\))/g, '$0'))
  }
