import { useState, useEffect, useCallback } from 'react'
import { getMakes, getModels, getYears } from './transformers'
import { DependentFieldHocEffect } from '../../../atoms/DependentField'
import { Option } from '../../../types/Autoselect'
import { FIND_CAR_FIELD_NAMES } from '../../../types/enums/findCar'
import {
  FilterFieldSetInfo,
  FilterComponentProps,
  FiltersFormValues
} from './types'
import {
  ModelFieldProps,
  YearFromFieldProps,
  YearToFieldProps
} from './FilterDependentFields'
import { NormalizedReadInventoryItem } from '../../../api/carSnoopApi/accounts/types'

export const useFilterOptionEffects = <V extends NormalizedReadInventoryItem>({
  applyFilters,
  defaultDisplayVehicleList
}: FilterComponentProps<V>) => {
  const [makeOptions, setMake] = useState<Option[]>([])
  const [modelOptions, setModel] = useState<Option[]>([])
  const [yearFromOptions, setYearFrom] = useState<Option[]>([])
  const [yearToOptions, setYearTo] = useState<Option[]>([])

  useEffect(() => {
    setYearFrom(getYears(defaultDisplayVehicleList))
    setYearTo(getYears(defaultDisplayVehicleList))
    setMake(getMakes(defaultDisplayVehicleList))
  }, [defaultDisplayVehicleList, setMake, setYearFrom, setYearTo])

  /* Transform field values */

  const modelFieldEffect: DependentFieldHocEffect<
    ModelFieldProps,
    FilterFieldSetInfo
  > = useCallback(
    (_, ctx, prevValues) => {
      const { values, setFieldValue } = ctx
      const { make } = values

      setYearFrom(getYears(defaultDisplayVehicleList, make?.name))
      setYearTo(getYears(defaultDisplayVehicleList, make?.name))

      if (!make) {
        return setFieldValue(FIND_CAR_FIELD_NAMES.MODEL, null)
      }

      setModel(getModels(defaultDisplayVehicleList, make.name))

      if (prevValues) {
        const { make: prevMake } = prevValues
        if (make?.id !== prevMake?.id) {
          setFieldValue(FIND_CAR_FIELD_NAMES.MODEL, null)
        }
      }
    },
    [defaultDisplayVehicleList, setModel, makeOptions, setYearFrom, setYearTo]
  )

  const yearFromFieldEffect: DependentFieldHocEffect<
    YearFromFieldProps,
    FilterFieldSetInfo
  > = useCallback(
    (_, ctx, prevValues) => {
      const { values, setFieldValue } = ctx
      const { make, model } = values

      setYearFrom(getYears(defaultDisplayVehicleList, make?.name, model?.name))
      setYearTo(getYears(defaultDisplayVehicleList, make?.name, model?.name))

      if (!model) {
        return setFieldValue(FIND_CAR_FIELD_NAMES.YEAR_FROM, null)
      }

      if (prevValues) {
        const { model: prevModel } = prevValues

        if (model.name !== prevModel?.name) {
          setFieldValue(FIND_CAR_FIELD_NAMES.YEAR_FROM, null)
          setFieldValue(FIND_CAR_FIELD_NAMES.YEAR_TO, null)
        }
      }
    },
    [defaultDisplayVehicleList, setYearFrom, makeOptions, modelOptions]
  )

  const yearToFieldEffect: DependentFieldHocEffect<
    YearToFieldProps,
    FilterFieldSetInfo
  > = useCallback(
    (_, ctx, prevValues) => {
      const { values, setFieldValue } = ctx
      const { make, model, yearFrom } = values

      if (make && model) {
        setYearTo(getYears(defaultDisplayVehicleList, make.name, model.name))
      }

      if (prevValues) {
        const { yearFrom: prevYearFrom } = prevValues
        if (yearFrom?.id !== prevYearFrom?.id || !yearFrom) {
          setFieldValue(FIND_CAR_FIELD_NAMES.YEAR_TO, null)
        }
      }
    },
    [
      defaultDisplayVehicleList,
      setYearTo,
      makeOptions,
      modelOptions,
      yearFromOptions
    ]
  )

  const onApplyFilters = useCallback(
    (fomValues: FiltersFormValues) => {
      const { make, model, yearFrom, yearTo } = fomValues

      const data = defaultDisplayVehicleList.filter((vehicle) => {
        if (make && vehicle.make !== make.name) {
          return false
        }

        if (model && vehicle.model !== model.name) {
          return false
        }

        if (yearFrom && vehicle.year < Number(yearFrom.name)) {
          return false
        }

        return !(yearTo && vehicle.year > Number(yearTo.name))
      })

      applyFilters(data, fomValues)
    },
    [defaultDisplayVehicleList]
  )

  return {
    makeOptions,
    modelOptions,
    yearFromOptions,
    yearToOptions,
    modelFieldEffect,
    yearFromFieldEffect,
    yearToFieldEffect,
    onApplyFilters
  }
}
