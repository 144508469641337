import { useCallback, useState } from 'react'
import { SUBSCRIPTION_CHECKING_MESSAGE } from '../../../features/Subscription/SubscriptionCheckingError'
import { useRequestToast } from '../../../utils/hooks'
import { SubmitRequestFeeCheckoutType } from '../../../api/externalCarSnoopApi/requests/types'
import { useSubmitRequestFeeCheckout } from './useSubmitRequestFeeCheckout'
import { ResponseStatusMap } from '../../../types/status'

const generateCancelUrl = (): string => {
  const baseUrl = window.location.origin
  const baseUrlPath = window.location.pathname
  const cancelUrl = new URL('/', baseUrl)
  cancelUrl.pathname = baseUrlPath

  return cancelUrl.href
}

export const useVerificationPaymentRedirect = (
  generateSuccessUrl: () => string
) => {
  const { submitRequestFeeCheckout } = useSubmitRequestFeeCheckout()
  const [isPaymentOpen, setIsPaymentOpen] = useState(false)
  const { openRequestToast } = useRequestToast()

  const redirectToPayment = useCallback(() => {
    setIsPaymentOpen(true)
  }, [setIsPaymentOpen])

  const handleRedirectFail = useCallback(
    (e: Error) => {
      if (e.message !== SUBSCRIPTION_CHECKING_MESSAGE) {
        openRequestToast({ status: 'failed' })
      }

      setIsPaymentOpen(false)
    },
    [setIsPaymentOpen, openRequestToast]
  )

  const getPaymentRedirectUrl = useCallback(async () => {
    const requestParams: SubmitRequestFeeCheckoutType.Params = {
      successUrl: generateSuccessUrl(),
      cancelUrl: generateCancelUrl()
    }

    const response = await submitRequestFeeCheckout(requestParams)

    if (response.status === ResponseStatusMap.Success) {
      const { paymentUrl } = response

      return paymentUrl
    }

    throw new Error('Getting url error')
  }, [])

  return {
    getPaymentRedirectUrl,
    isPaymentOpen,
    redirectToPayment,
    handleRedirectFail
  }
}
