import { FC, MouseEventHandler, useCallback } from 'react'
import DownloadIcon from '@mui/icons-material/Download'
import { BlueButton } from '../../../../../atoms/Button'
import { COLORS } from '../../../../../types/colors'
import { useDeviceInfo } from '../../../../../utils/hooks'
import { Condition } from '../../../../../atoms/Condition'
import { useFiles } from '../../../../../utils/hooks/files'

interface DownloadActionProps {
  name: string
  fileId: string
  extension: string
}

export const DownloadAction: FC<DownloadActionProps> = ({
  name,
  fileId,
  extension
}) => {
  const { isTablet } = useDeviceInfo()
  const { getMediaLink, getFileById } = useFiles()
  const handleDownload: MouseEventHandler = useCallback(
    async (event) => {
      event.stopPropagation()

      getFileById(fileId, `${name}.${extension}`)
    },
    [getMediaLink]
  )

  return (
    <Condition
      condition={isTablet}
      trueContent={
        <BlueButton onClick={handleDownload}>
          <DownloadIcon sx={{ color: COLORS.accent_blue }} />
        </BlueButton>
      }
      falseContent={
        <BlueButton
          startIcon={<DownloadIcon sx={{ color: COLORS.accent_blue }} />}
          onClick={handleDownload}
        >
          Download
        </BlueButton>
      }
    />
  )
}
