import { FC } from 'react'
import { CardMedia, Grid, SxProps } from '@mui/material'
import { BORDERS, COLORS } from '../../../../../types/colors'
import { ConfirmationModal } from '../../../../../molecules/Modal'
import { OrNull } from '../../../../../types/OrNull'

interface DocumentPreviewProps {
  type: OrNull<string>
  link: string
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  sx?: SxProps
}

export const DocumentPreview: FC<DocumentPreviewProps> = ({
  link,
  type,
  isOpen,
  setIsOpen,
  sx = {}
}) => {
  if (type !== 'pdf') {
    return (
      <ConfirmationModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        header={<Grid item />}
        sx={sx}
      >
        <Grid
          container
          sx={{
            mt: 2,
            p: 3,
            backgroundColor: COLORS.white
          }}
          alignItems='center'
        >
          <Grid
            container
            item
            sx={{
              width: '70vw',
              height: '70vh'
            }}
          >
            <CardMedia
              component='img'
              src={link}
              alt='Vehicle'
              height='100%'
              sx={{
                p: 2,
                objectFit: 'contain',
                border: BORDERS.EMPTY_IMAGE_MAIN
              }}
            />
          </Grid>
        </Grid>
      </ConfirmationModal>
    )
  }

  return null
}
