import { FC, Dispatch, SetStateAction } from 'react'
import { Grid, Typography } from '@mui/material'
import { ConfirmationModal } from '../../../../../molecules/Modal'
import {
  ModalContainer,
  ModalInnerContainer
} from './BuildCarPreviewVerificationModal.styles'

interface BuildCarPreviewVerificationModalProps {
  isOpen: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  onSubmit: () => void
}

export const BuildCarPreviewVerificationModal: FC<
  BuildCarPreviewVerificationModalProps
> = ({ isOpen, setOpen, onSubmit }) => {
  return (
    <ConfirmationModal
      isOpen={isOpen}
      setIsOpen={setOpen}
      header={
        <Grid item>
          <Typography variant='h6'>Verification Required</Typography>
        </Grid>
      }
      cancel={{
        text: 'Cancel'
      }}
      submit={{
        text: 'Proceed',
        callback: onSubmit
      }}
    >
      <ModalContainer container>
        <ModalInnerContainer container>
          <Grid item justifyContent='center' xs={12} sx={{ mb: '20px' }}>
            <Typography variant='main'>
              In order to verify your identity we need to charge $1 fee.
            </Typography>
          </Grid>
          <Grid item justifyContent='center' xs={12}>
            <Typography variant='details'>
              Please note: This fee will be charged only once for the first
              request. Then you’ll be able to create as many requests as you
              want for free.
            </Typography>
          </Grid>
        </ModalInnerContainer>
      </ModalContainer>
    </ConfirmationModal>
  )
}
