import { useMemo } from 'react'
import { NormalizedTransactionType } from '../../api/carSnoopApi/transactions/types'
import { DeliveryCardProps } from './DeliveryCard'
import { OrNull } from '../../types/OrNull'
import { ShippingAddress } from './DeliveryCard.types'
import { AddShipmentDetailsParamsAddress } from '../../api/carSnoopApi/shipments/types'

export interface UseDeliveryCardProps {
  vehicle: NormalizedTransactionType['vehicle']
  tradeIn: NormalizedTransactionType['tradeIn']
  shipmentInfo: {
    vehicle: ShippingAddress
    tradeIn: OrNull<ShippingAddress>
  }
  setPickup?: (pickup: AddShipmentDetailsParamsAddress) => void
  setDropOff?: (dropOff: AddShipmentDetailsParamsAddress) => void
  hasAddressError?: boolean
}

interface UseDeliveryCardReturn {
  deliveryVehicleProps: DeliveryCardProps
  tradeInVehicleProps: OrNull<DeliveryCardProps>
}

export const useDeliveryCard = ({
  vehicle,
  tradeIn,
  shipmentInfo,
  hasAddressError = false,
  setPickup,
  setDropOff
}: UseDeliveryCardProps): UseDeliveryCardReturn => {
  const vehicleInfo = useMemo(() => {
    return vehicle.vin
      ? {
          title: 'VIN',
          info: vehicle.vin
        }
      : {
          title: 'Vehicle',
          info: `${vehicle.year} ${vehicle.make} ${vehicle.model}`
        }
  }, [vehicle])

  const tradeInVehicleInfo = useMemo(() => {
    if (!tradeIn) {
      return null
    }

    return tradeIn.vin
      ? {
          title: 'VIN',
          info: tradeIn.vin
        }
      : {
          title: 'Trade In',
          info: `${tradeIn.year} ${tradeIn.make} ${tradeIn.model}`
        }
  }, [tradeIn])

  const deliveryVehicleProps = useMemo(() => {
    return {
      cardTitle: 'Vehicle delivery',
      pickup: shipmentInfo.vehicle.pickup,
      dropOff: shipmentInfo.vehicle.dropOff,
      carInfo: vehicleInfo,
      hasAddressError,
      onPickupEdit: setPickup,
      onDropOffEdit: setDropOff
    }
  }, [
    shipmentInfo.vehicle.pickup,
    shipmentInfo.vehicle.dropOff,
    vehicleInfo,
    setPickup,
    setDropOff
  ])

  const tradeInVehicleProps = useMemo(() => {
    if (!tradeInVehicleInfo) {
      return null
    }

    if (!shipmentInfo.tradeIn) {
      return null
    }

    return {
      cardTitle: 'Trade-in delivery',
      carInfo: tradeInVehicleInfo,
      pickup: shipmentInfo.tradeIn.pickup,
      dropOff: shipmentInfo.tradeIn.dropOff,
      hasAddressError
    }
  }, [shipmentInfo.tradeIn, vehicleInfo])

  return {
    deliveryVehicleProps,
    tradeInVehicleProps
  }
}
