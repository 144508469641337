import { registrationFormSlice } from './index'

export const actionsManualRegistration = {
  incrementScreenStep: registrationFormSlice.actions.incrementScreenStep,
  decrementScreenStep: registrationFormSlice.actions.decrementScreenStep,
  incrementOnboardStep: registrationFormSlice.actions.incrementOnboardStep,
  decrementOnboardStep: registrationFormSlice.actions.decrementOnboardStep,
  setPersonalDetails: registrationFormSlice.actions.setPersonalDetails,
  setContactDetails: registrationFormSlice.actions.setContactDetails,
  setVerificationCode: registrationFormSlice.actions.setVerificationCode,
  setConfirmPassword: registrationFormSlice.actions.setConfirmPassword,
  resetState: registrationFormSlice.actions.resetState,
  resetLocation: registrationFormSlice.actions.resetLocation,
  setIsVerificationBlocked:
    registrationFormSlice.actions.setIsVerificationBlocked
}
