import { FC, useCallback, useState } from 'react'
import { Grid } from '@mui/material'
import { ChatList } from './view/ChatList'
import { Show } from '../../atoms/JSXExtensions'
import { Chat, ChatProps } from '../Chat'
import { MiniChatHeaderContainer } from './view/Header'
import { useMiniChatReader } from './controller/reader'
import { Loader } from '../../atoms/Loader'
import { ChatListItemType } from './model/types'
import { useAppDispatch } from '../../utils/hooks'
import { actionsMiniChat } from './model/actions'
import { selectMessages as selectMiniChatMessages } from './model/selectors'
import { Renovator } from '../Chat/Renovator/Renovator'
import { useCalulateChatListHeight } from './hooks/useCalculateChatListHeight'

interface MiniChatProps {
  onClose: () => void
  renovator: Renovator
}

export const MiniChat: FC<MiniChatProps> = ({ onClose, renovator }) => {
  const dispatch = useAppDispatch()

  const { chatList } = useMiniChatReader()
  const chatListHeight = useCalulateChatListHeight()

  const [currentChat, setCurrentChat] = useState<ChatListItemType | null>(null)

  const handleChatClick = useCallback(
    (chatMessage: ChatListItemType) => {
      setCurrentChat(chatMessage)
    },
    [setCurrentChat]
  )

  const onChatClose = useCallback(() => {
    dispatch(actionsMiniChat.resetMessages())
    dispatch(actionsMiniChat.resetChatList())
    renovator.renovate()
    setCurrentChat(null)
  }, [setCurrentChat, dispatch, renovator])

  const handleMessagesRead = useCallback<ChatProps['onMessagesRead']>(
    (messages) => {
      dispatch(actionsMiniChat.setMessages(messages))
    },
    [dispatch]
  )

  const handleChatUnmount = useCallback(() => {
    dispatch(actionsMiniChat.resetMessages())
  }, [dispatch])

  return (
    <Grid container>
      <Grid item xs={12}>
        <MiniChatHeaderContainer
          onClose={onClose}
          currentChat={currentChat}
          onBack={onChatClose}
        />
      </Grid>
      <Grid container item xs={12} pt={2} height={chatListHeight}>
        <Show
          when={chatList}
          fallback={
            <Grid container justifyContent='center' alignItems='center'>
              <Loader small />
            </Grid>
          }
        >
          {(chats) => (
            <Show
              when={currentChat}
              fallback={
                <ChatList chatList={chats} onChatClick={handleChatClick} />
              }
            >
              {(chat) => (
                <Chat
                  onMessagesRead={handleMessagesRead}
                  messagesSelector={selectMiniChatMessages}
                  onChatUnmount={handleChatUnmount}
                  chatId={chat.transactionId}
                  isMinimized
                />
              )}
            </Show>
          )}
        </Show>
      </Grid>
    </Grid>
  )
}
