import { Link } from '@mui/material'
import { FC, HTMLAttributeAnchorTarget } from 'react'
import { LinkProps, Link as RouterLink } from 'react-router-dom'

type LinkButtonProps = {
  label: string
  to?: LinkProps['to']
  href?: string
  onClick?: () => void
  target?: HTMLAttributeAnchorTarget
}

export const LinkButton: FC<LinkButtonProps> = ({
  label,
  to = '#',
  href,
  onClick,
  target = '_self'
}) => {
  if (onClick) {
    return (
      <Link
        component='label'
        underline='hover'
        variant='main'
        onClick={onClick}
        sx={{ cursor: 'pointer' }}
      >
        {label}
      </Link>
    )
  }

  if (href) {
    return (
      <Link underline='none' href={href} target='_blank'>
        {label}
      </Link>
    )
  }

  return (
    <Link underline='none' component={RouterLink} to={to} target={target}>
      {label}
    </Link>
  )
}
