import { Utils } from '../../utils'
import { ResponseStatusMap } from '../../../types/status'
import { ErrorResponse, SuccessResponse } from '../../private.types'
import { shipmentMapper } from './types/readShipment/shipmentMapper'
import type {
  NormalizedShipmentResponse,
  RawShipmentResponse,
  CreateShipmentResponse
} from './types'

export class Shipments {
  protected readonly utils: Utils
  protected readonly operations = {
    readShipment: 'readShipment',
    createShipment: 'createShipment'
  }

  constructor(utils: Utils) {
    this.utils = utils
  }

  async readShipment({
    trackingNumber
  }: {
    trackingNumber: string
  }): Promise<
    | SuccessResponse<{ data: NormalizedShipmentResponse }>
    | ErrorResponse<{ message: string }>
  > {
    type ErrorResponseType = { message: string }

    const dataToSend = {
      tracking_number: trackingNumber
    }

    const response = await this.utils.makeJSONRequest<
      RawShipmentResponse,
      ErrorResponseType
    >(this.operations.readShipment, dataToSend)

    if (response.type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        data: shipmentMapper(response.payload)
      }
    }

    return {
      status: ResponseStatusMap.Error,
      message: response.payload.message
    }
  }

  async createShipment({
    transactionId
  }: {
    transactionId: string
  }): Promise<
    | SuccessResponse<CreateShipmentResponse>
    | ErrorResponse<CreateShipmentResponse>
  > {
    try {
      const dataToSend = {
        transaction_id: transactionId
      }

      const response = await this.utils.makeJSONRequest<
        CreateShipmentResponse,
        CreateShipmentResponse
      >(this.operations.createShipment, dataToSend)

      if (response.type === ResponseStatusMap.Success) {
        return {
          status: ResponseStatusMap.Success,
          message: response.payload.message
        }
      }

      return {
        status: ResponseStatusMap.Error,
        message: response.payload.message
      }
    } catch {
      return {
        status: ResponseStatusMap.Error,
        message: 'Something went wrong'
      }
    }
  }
}
