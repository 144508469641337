export enum FIELD {
  DOCUMENT_NAME = 'documentName'
}

export enum LABEL {
  DOCUMENT_NAME = 'Document name'
}

export interface AddDocumentForm {
  [FIELD.DOCUMENT_NAME]: string
}

export enum ERROR {
  NOT_VALID = 'Enter a valid Document Name.'
}
