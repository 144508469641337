import { ResponseStatusMap } from '../../../types/status'
import { ErrorResponse, SuccessResponse } from '../../private.types'
import { Utils } from '../../utils'

export class Addresses {
  protected readonly utils: Utils
  protected readonly operations = {
    validateZipCode: 'validateZipCode',
    validateAddress: 'validateAddress',
    readLinkedInProfile: 'readLinkedInProfile'
  }

  constructor(utils: Utils) {
    this.utils = utils
  }

  async validateZipCode(zipCode: string): Promise<
    | SuccessResponse<{
        city: string
        state: string
      }>
    | ErrorResponse<{
        id: string
        message: string
      }>
  > {
    type SuccessResponse = {
      city: string
      state: string
    }

    type ErrorResponse = {
      id: string
      message: string
    }

    const response = await this.utils.makeJSONRequest<
      SuccessResponse,
      ErrorResponse
    >(this.operations.validateZipCode, {
      zip_code: zipCode
    })

    if (response.type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        city: response.payload.city,
        state: response.payload.state
      }
    }
    return {
      status: ResponseStatusMap.Error,
      id: response.payload.id,
      message: response.payload.message
    }
  }

  async validateAddress({
    street,
    city,
    state,
    zipCode
  }: {
    street: string
    city: string
    state: string
    zipCode: string
  }): Promise<
    | SuccessResponse<{
        street: string
        city: string
        township: string
        county: string
        state: string
        country: string
        zipCode: string
        zipCodeSuffix: string
        message: string
      }>
    | ErrorResponse<{
        id: string
        message: string
      }>
  > {
    type SuccessResponse = {
      street: string
      city: string
      township: string
      county: string
      state: string
      country: string
      zip_code: string
      zip_code_suffix: string
    }

    type ErrorResponse = {
      id: string
      message: string
    }

    const response = await this.utils.makeJSONRequest<
      SuccessResponse,
      ErrorResponse
    >(this.operations.validateAddress, {
      zip_code: zipCode,
      street,
      city,
      state
    })

    if (response.type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        street: response.payload.street,
        city: response.payload.city,
        township: response.payload.township,
        county: response.payload.county,
        state: response.payload.state,
        country: response.payload.country,
        zipCode: response.payload.zip_code,
        zipCodeSuffix: response.payload.zip_code_suffix,
        message: 'success'
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: response.payload.id,
      message: response.payload.message
    }
  }
}
