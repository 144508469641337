export enum FIELDS {
  EMAILS = 'emails'
}

export interface MyTeamAddUsersFormValues {
  [FIELDS.EMAILS]: string[]
}

export enum ERRORS {
  NOT_VALID = 'Enter a valid email address.',
  ALREADY_EXIST = 'This email already exists on another account.'
}
