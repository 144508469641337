import { Box } from '@mui/material'
import { EllipsisText } from '../../../../atoms/EllipsisText'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

const text = "I'm too lo-o-o-o-o-o-ng"

export const EllipsisTextExample = () => {
  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <Box width='100px'>
          <EllipsisText tooltipText={text}>{text}</EllipsisText>
        </Box>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
