import { FC, PropsWithChildren } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  AccordionDetails,
  AccordionSummary,
  Grid,
  GridProps,
  Typography
} from '@mui/material'

import { BORDERS } from '../../../../../../types/colors'
import { Show } from '../../../../../../atoms/JSXExtensions'
import { EditIcon } from '../../../../../../assets/icons/Edit'
import { Accordion } from '../../../../../../atoms/Accordion'

export interface DetailsContainerProps extends GridProps {
  title?: string
  allowEdit?: boolean
  emphasize?: boolean
  onEditClick?: () => void
}

export const DetailsContainer: FC<PropsWithChildren<DetailsContainerProps>> = (
  props
) => {
  const {
    title,
    allowEdit,
    emphasize = false,
    children,
    onEditClick,
    ...containerProps
  } = props
  return (
    <Grid
      container
      item
      flexDirection='column'
      sx={{
        border: BORDERS.SM_GRAY,
        borderRadius: '10px',
        p: '24px 32px',
        backgroundColor: emphasize ? '#F2F7FD' : ''
      }}
      {...containerProps}
    >
      {(title || onEditClick) && (
        <Grid container item sx={{ pb: 4 }} justifyContent='space-between'>
          <Grid item>
            <Typography variant='badge' fontSize={18}>
              {title}
            </Typography>
          </Grid>
          <Show when={allowEdit}>
            <Grid item zIndex={1}>
              <EditIcon onClick={onEditClick} />
            </Grid>
          </Show>
        </Grid>
      )}
      {children}
    </Grid>
  )
}

export const DetailsContainerAccordion: FC<
  PropsWithChildren<Omit<DetailsContainerProps, 'allowEdit' | 'onEditClick'>>
> = (props) => {
  const { children, title } = props
  return (
    <Accordion
      sx={{
        border: BORDERS.SM_GRAY,
        borderRadius: '10px',
        p: '24px 32px'
      }}
    >
      <AccordionSummary sx={{ p: 0 }} expandIcon={<ExpandMoreIcon />}>
        <Grid container item justifyContent='space-between'>
          <Grid item>
            <Typography variant='badge' fontSize={18}>
              {title}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0, pt: 4 }}>{children}</AccordionDetails>
    </Accordion>
  )
}
