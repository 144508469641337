import SearchIcon from '@mui/icons-material/Search'
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { Form, Formik, useFormikContext } from 'formik'
import { FC, useCallback, useMemo } from 'react'
import { CloseIcon } from '../../assets/icons/Close'
import { Show } from '../../atoms/JSXExtensions'
import { SearchField } from '../../atoms/SearchField'
import { TextInputFieldNew } from '../../atoms/TextInputFieldNew'
import { BGCOLORS } from '../../types/colors'
import type { SearchComponentProps } from './types'

const ResetButton = () => {
  const { submitForm, setFieldValue } = useFormikContext()

  const onResetClick = useCallback(() => {
    setFieldValue('search', '')
    submitForm()
  }, [])

  return (
    <InputAdornment position='end'>
      <IconButton aria-label='reset field' onClick={onResetClick} edge='end'>
        <CloseIcon />
      </IconButton>
    </InputAdornment>
  )
}

export const SearchInventoryControl: FC<SearchComponentProps> = ({
  formRef,
  initialValues,
  onSearchApply,
  includeReset = false
}) => {
  const theme = useTheme()
  const isLaptop = useMediaQuery(
    theme.breakpoints.up('lg') && theme.breakpoints.down('xl')
  )

  const searchPlaceholder = useMemo(() => {
    if (isLaptop) {
      return 'Stock Number...'
    }
    return 'Search by Stock Number...'
  }, [isLaptop])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSearchApply}
      validateOnMount
      enableReinitialize
      innerRef={formRef}
    >
      {(formik) => {
        return (
          <Form style={{ width: '100%' }}>
            <Grid container spacing={1} wrap='nowrap'>
              <Grid item width='100%'>
                <TextInputFieldNew
                  name='search'
                  placeholder={searchPlaceholder}
                  component={SearchField}
                  endAdornment={
                    <Show when={includeReset && formik.values.search}>
                      <ResetButton />
                    </Show>
                  }
                />
              </Grid>
              <Grid item>
                <Button
                  sx={{
                    height: '100%',
                    backgroundColor: BGCOLORS.gray,
                    borderRadius: '5px'
                  }}
                  type='submit'
                >
                  <SearchIcon color='action' />
                </Button>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}
