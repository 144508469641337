import { Button, Grid } from '@mui/material'
import { useState } from 'react'
import { FindCarFormStepper, Step } from '../../../../atoms/FindCarFormStepper'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

const steps: Step[] = [
  { label: 'Search' },
  { label: 'Select' },
  { label: 'Trade' },
  { label: 'Submit' }
]

export const FindCarFormStepperExample = () => {
  const [step, setStep] = useState(0)

  const handleNextStepClick = () => {
    if (step === steps.length) {
      return
    }
    setStep(step + 1)
  }

  const handlePrevStepClick = () => {
    if (step === 0) {
      return
    }
    setStep(step - 1)
  }

  return (
    <ComponentContainer fullWidth>
      <ComponentWithPaperBackground>
        <Grid container flexDirection='column' spacing={2}>
          <Grid item>
            <FindCarFormStepper steps={steps} currentStep={step} />
          </Grid>
          <Grid item>
            <Button onClick={handlePrevStepClick}>Prev step</Button>
            <Button onClick={handleNextStepClick}>Next step</Button>
          </Grid>
        </Grid>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
