import { Grid } from '@mui/material'
import { useField } from 'formik'
import { FC, useCallback } from 'react'

import { TradeInMessage } from './TradeInMessage'

import { RemoveButton } from '../../atoms/Button'
import { Condition } from '../../atoms/Condition'
import { NumericField } from '../../atoms/NumericField'
import { COLORS } from '../../types/colors'
import { useModal } from '../../utils/hooks'
import { CommonConfirmationModal } from '../Modal/CommonConfirmationModal'

interface ITradeInProps {
  name: string
  onRemoveTradeInConfirm: () => void
  onBlur?: () => void
  required?: boolean | ((name: string) => boolean)
}

export const TradeInField: FC<ITradeInProps> = ({
  name,
  onRemoveTradeInConfirm,
  onBlur,
  required = false
}) => {
  const tradeInFlagName = `has_${name}`
  const [, , tradeInHelpers] = useField(name)
  const [tradeInFlag, , tradeInFlagHelpers] = useField<string>(tradeInFlagName)
  const handleRemoveTradeIn = useCallback(() => {
    onRemoveTradeInConfirm()
    tradeInFlagHelpers.setValue('false')
    tradeInHelpers.setValue('0.00')
  }, [onRemoveTradeInConfirm])

  const removeTradInConfirmationModal = useModal(handleRemoveTradeIn)

  return (
    <>
      <input type='hidden' value={tradeInFlag.value} name={tradeInFlagName} />
      <Condition
        condition={tradeInFlag.value === 'false'}
        trueContent={
          <TradeInMessage title='Trade in has been removed by Seller' />
        }
        falseContent={
          <Grid
            container
            width='100%'
            alignItems='center'
            justifyContent='space-between'
            wrap='nowrap'
          >
            <Grid item xs={9}>
              <NumericField
                textColor={COLORS.text_red}
                startWith='-$'
                name={name}
                placeholder='Trade in'
                label='TRADE IN'
                onBlur={onBlur}
                required={required}
              />
            </Grid>
            <Grid item xs='auto' ml={1}>
              <RemoveButton onClick={removeTradInConfirmationModal.onOpen}>
                Delete Trade In
              </RemoveButton>
            </Grid>
          </Grid>
        }
      />
      <CommonConfirmationModal
        {...removeTradInConfirmationModal}
        message='Remove Trade In?'
        hint='Once Trade In is removed, it cannot be added back'
      />
    </>
  )
}
