import { styled } from '@mui/system'

interface IEllipsisTextSpan {
  ellipsisRows: number
}

export const EllipsisTextSpan = styled('span', {
  shouldForwardProp: (prop) => prop !== 'ellipsisRows'
})<IEllipsisTextSpan>(({ ellipsisRows }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  ...(ellipsisRows === 1
    ? {
        display: 'block',
        whiteSpace: 'nowrap'
      }
    : {
        display: ['-webkit-box', '-ms-box', '-moz-box'],
        '-webkit-line-clamp': `${ellipsisRows}`,
        '-webkit-box-orient': 'vertical',
        '-moz-box-orient': 'vertical',
        wordBreak: 'break-word'
      })
}))
