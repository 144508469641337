import { Box, Grid } from '@mui/material'
import { FC, useCallback } from 'react'
import { COLORS } from '../../../../types/colors'

export interface ImagesCarouselStepperProps {
  totalPages: number
  currentPage: number
  onClick: (page: number) => void
}

export const ImagesCarouselStepper: FC<ImagesCarouselStepperProps> = (
  props
) => {
  const { totalPages, currentPage, onClick } = props

  const handleChangePageClick = useCallback(
    (page: number) => () => {
      onClick(page)
    },
    [onClick]
  )

  return (
    <Grid container item spacing={2} justifyContent='center'>
      {Array.from({ length: totalPages }).map((item, i) => {
        const isActive = i === currentPage
        return (
          <Grid item key={i} onClick={handleChangePageClick(i)}>
            <Box
              sx={{
                width: '8px',
                height: '8px',
                borderRadius: '50%',
                backgroundColor: isActive
                  ? COLORS.active_image_page
                  : COLORS.accent_shade_2
              }}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}
