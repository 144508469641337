import { useState, useEffect } from 'react'
import { Grid, Typography } from '@mui/material'
import { VehicleItem } from '../../../../molecules/VehicleItem'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

const imageURL =
  'https://tesla-cdn.thron.com/delivery/public/image/tesla/2391415a-7273-4735-92b2-a37c84c26f04/bvlatuR/std/4096x2560/Homepage-Model-S-Desktop-RHD'

export const CarItemExample = () => {
  const [imageSource, setImageSource] = useState('')

  useEffect(() => {
    fetch(imageURL)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader()

        reader.onloadend = () => {
          if (reader.result) {
            setImageSource(
              reader.result.toString().replace('data:image/jpeg;base64,', '')
            )
          }
        }

        reader.readAsDataURL(blob)
      })
  }, [])

  if (!imageSource) {
    return <Typography>Loading...</Typography>
  }

  return (
    <ComponentContainer fullWidth>
      <ComponentWithPaperBackground>
        <Grid container flexDirection='column'>
          <Grid
            item
            container
            spacing={3}
            sx={{
              width: '512px'
            }}
          >
            <Grid item>
              <Typography variant='h6' gutterBottom>
                Full width
              </Typography>
            </Grid>
            <Grid item>
              <VehicleItem
                id='86bd1692-2a68-43f2-acd5-569bd95ababe'
                title='2017 BMW'
                stockNumber='8228'
                mileage={1200.0}
                vin='1G3NL52E24C209053'
                status='Awaiting Payment'
              />
            </Grid>
            <Grid item width='100%'>
              <VehicleItem
                id='86bd1692-2a68-43f2-acd5-569bd95ababe'
                title='2022 Banana'
                stockNumber='9267'
                mileage={2800.0}
                vin='1G3NL52E24C209053'
                imageSource={imageSource}
                status='Requested'
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            spacing={3}
            sx={{
              width: '262px'
            }}
          >
            <Grid item>
              <Typography variant='h6' gutterBottom>
                Desktop
              </Typography>
            </Grid>
            <Grid item>
              <VehicleItem
                id='86bd1692-2a68-43f2-acd5-569bd95ababe'
                title='2017 BMW'
                stockNumber='8228'
                mileage={12000}
                vin='1G3NL52E24C209053'
                status='For Sale'
              />
            </Grid>
            <Grid item>
              <VehicleItem
                id='86bd1692-2a68-43f2-acd5-569bd95ababe'
                title='2022 Banana'
                stockNumber='9267'
                mileage={28000}
                vin='1G3NL52E24C209053'
                imageSource={imageSource}
                status='Offered'
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            spacing={3}
            sx={{
              width: '262px'
            }}
          >
            <Grid item>
              <Typography variant='h6' gutterBottom>
                Desktop (long info)
              </Typography>
            </Grid>
            <Grid item>
              <VehicleItem
                id='86bd1692-2a68-43f2-acd5-569bd95ababe'
                title='2017 BMW X6 Supeg galaxy edition'
                stockNumber='8228000000000000000'
                mileage={12000}
                vin='1G3NL52E24C2090530000000000'
                status='Under Contract'
              />
            </Grid>
            <Grid item>
              <VehicleItem
                id='86bd1692-2a68-43f2-acd5-569bd95ababe'
                title='2022 Banana Nirvana Smells like teen spirit'
                stockNumber='926700000000'
                mileage={1125983000}
                vin='1G3NL52E24C2090530000000000'
                imageSource={imageSource}
                status='In Request'
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            spacing={3}
            sx={{
              width: '226px'
            }}
          >
            <Grid item>
              <Typography variant='h6' gutterBottom>
                Tablet
              </Typography>
            </Grid>
            <Grid item>
              <VehicleItem
                id='86bd1692-2a68-43f2-acd5-569bd95ababe'
                title='2017 BMW'
                stockNumber='8228'
                mileage={1200.0}
                vin='1G3NL52E24C209053'
              />
            </Grid>
            <Grid item>
              <VehicleItem
                id='86bd1692-2a68-43f2-acd5-569bd95ababe'
                title='2022 Banana'
                stockNumber='9267'
                mileage={2800.0}
                vin='1G3NL52E24C209053'
                imageSource={imageSource}
              />
            </Grid>
          </Grid>
        </Grid>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
