import { styled } from '@mui/material'
import { Form } from 'formik'

export const ForgotPasswordForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  & > div:last-child {
    margin-top: auto;
  }
`
