import { useMemo } from 'react';
import { routes } from '../../routes';
import { AppLinks } from '../../types/AppLinks';
import { useUserAuthStatus } from './useUserAuthStatus';
import { useUserInfo } from './useUserInfo';

const publicAppLinks: AppLinks = [
  {
    label: 'Cars for Sale',
    route: routes.carsForSale
  }
]

const privateAppLinks = (isDealer: boolean): AppLinks => {
  return [
    {
      label: 'Dashboard',
      route: routes.dashboard
    },
    {
      label: 'Deals',
      route: routes.deals.index
    },
    {
      label: isDealer ? 'Inventory' : 'Garage',
      route: isDealer ? routes.inventory : routes.garage
    }
  ]
}

export const useAppLinks = (): AppLinks => {
  const isUserAuthenticated = useUserAuthStatus()
  const { isDealer } = useUserInfo()
  const appLinks = useMemo(() => {
    if (!isUserAuthenticated) {
      return publicAppLinks
    }
    return privateAppLinks(isDealer)
  }, [isDealer, isUserAuthenticated])

  return appLinks
}
