import * as Yup from 'yup'
import {
  latinWithSpecial,
  countOfNumbers,
  nonSpace
} from '../../../utils/validators'
import {
  MAX_DATE,
  MIN_DATE,
  phoneNumberLength,
  passwordLength
} from '../../../constants/applicationConstants'

export const validationSchema = Yup.object({
  firstName: Yup.string()
    .required('')
    .trim()
    .test(
      'is-latin-or-special',
      'Enter a valid First Name.',
      latinWithSpecial([' '])
    ),
  lastName: Yup.string()
    .required('')
    .trim()
    .test(
      'is-latin-or-special',
      'Enter a valid Last Name.',
      latinWithSpecial([' '])
    ),
  birthDate: Yup.date().required('').max(MAX_DATE).min(MIN_DATE),
  phone: Yup.string()
    .required('')
    .trim()
    .test(
      'length',
      'Enter a valid phone number.',
      countOfNumbers(phoneNumberLength)
    ),
  password: Yup.string()
    .required('')
    .min(
      passwordLength.min,
      `Minimal length of the password is ${passwordLength.min}, maximum length is ${passwordLength.max}.`
    )
    .max(
      passwordLength.max,
      `Minimal length of the password is ${passwordLength.min}, maximum length is ${passwordLength.max}.`
    )
    .test('space', 'Password must not contain spaces.', nonSpace)
    .test('is-latin-or-special', 'Enter a valid password.', latinWithSpecial()),
  confirmPassword: Yup.string()
    .required('')
    .oneOf([Yup.ref('password'), null], 'Password does not match.')
})
