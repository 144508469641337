import { IconsExample } from '../assets/IconsExample'
import { ImagesExample } from '../assets/ImagesExample'
import { LogoExample } from '../assets/LogosExample'
import { AudioPlayerExample } from '../atoms/AudioPlayerExample'
import { AutoSelectExample } from '../atoms/AutoSelectExample'
import { AutoselectColorOptionExample } from '../atoms/AutoselectColorOptionExample'
import { BreadcrumbsExample } from '../atoms/BreadcrumbsExample'
import { ButtonsExample } from '../atoms/ButtonsExample'
import { CounterExample } from '../atoms/CounterExample'
import { DefaultEmptyStateExample } from '../atoms/DefaultEmptyStateExample'
import {
  EmptyDividerExample,
  FilledDividerExample
} from '../atoms/DividerExample'
import { EllipsisEmailExample } from '../atoms/EllipsisEmailExample'
import { EllipsisTextExample } from '../atoms/EllipsisTextExample'
import { ErrorTextWithRetryExample } from '../atoms/ErrorTextWithRetryExample'
import { FindCarFormStepperExample } from '../atoms/FindCarFormStepperExample'
import { FindCarResultExample } from '../atoms/FindCarResultExample'
import { IconTextExample } from '../atoms/IconTextExample'
import { InformationLabelExample } from '../atoms/InformationLabelExample'
import { InputFieldExample } from '../atoms/InputFieldExample'
import { LabelExample } from '../atoms/LabelExample'
import { LoaderExample } from '../atoms/LoaderExample'
import { ManualDataGettingExample } from '../atoms/ManualDataGettingExample'
import { NavigationBackButtonExample } from '../atoms/NavigationBackButtonExample'
import { NotesFieldExample } from '../atoms/NotesFieldExample'
import { NumberBadgeExample } from '../atoms/NumberBadgeExample'
import { NumericFieldExample } from '../atoms/NumericFieldExample'
import { PaginationExample } from '../atoms/PaginationExample'
import { ProgressExample } from '../atoms/ProgressExample'
import { RadioGroupExample } from '../atoms/RadioGroupExample'
import {
  MultipleRadioVehicleComponentExample,
  RadioVehicleComponentExample
} from '../atoms/RadioVehicleComponent'
import { SelectElementExample } from '../atoms/SelectElement'
import {
  EmptySellerNotesExample,
  FilledSellerNotesExample
} from '../atoms/SellerNotesExample'
import { SnackbarExample } from '../atoms/SnackbarExample'
import { SpinnerExample } from '../atoms/SpinnerExample'
import { SwitchExample } from '../atoms/SwitchExample'
import { ToggleExample } from '../atoms/ToggleExample'
import { TypographyExample } from '../atoms/TypographyExample'
import { UploadVehiclePhotoGreetingExample } from '../atoms/UploadVehiclePhotoGreetingExample'
import { VideoPlayerExample } from '../atoms/VideoPlayerExample'
import { BuildCarPreviewScreenExample } from '../features/BuildCarPreviewScreenExample'
import { BuildCarTradeInExample } from '../features/BuildCarTradeInExample'
import { ContactSupportSideBar } from '../features/ContactSupportSideBar'
import { DeliveryCardExample } from '../features/DeliveryCardExample'
import { DisplayStandardEquipmentExample } from '../features/DisplayStandardEquipmentExample'
import {
  EmptyDriverLicenseInformationCardExample,
  FilledDriverLicenseInformationCardExample
} from '../features/DriverLicenseCardExample'
import { FindCarFormExample } from '../features/FindCarFormExample'
import { FormOrchestratorExample } from '../features/FormOrchestratorExample'
import { OfferDetailsWidgetExample } from '../features/OfferDetailsWidgetExample'
import { RequestDetailsExample } from '../features/RequestDetailsExample'
import { SubscriptionModalExample } from '../features/SubscriptionModalExample'
import { SubscriptionMicroformExample } from '../features/SubscriptionModalExample/SubscriptionMicroformExample'
import { UploadNewDocumentModalExample } from '../features/UploadNewDocumentModalExample'
import { AccountMemberExample } from '../molecules/AccountMemberExample'
import { AddDocumentModalExample } from '../molecules/AddDocumentModalExample'
import { AddOnsExample } from '../molecules/AddOnsExample'
import { AddressCardExample } from '../molecules/AddressCardExample'
import { CarItemExample } from '../molecules/CarItem'
import { ChatContainerExample } from '../molecules/ChatContainer'
import { ChatListExample } from '../molecules/ChatListExample'
import { ChatMediaMessageExample } from '../molecules/ChatMessageExample/FileMessageExample'
import { TextMessageExample } from '../molecules/ChatMessageExample/TextMessageExample'
import { CheckboxVehicleComponentExample } from '../molecules/CheckboxVehicleComponentExample'
import { ContactInformationExample } from '../molecules/ContactInformationExample'
import { CurrentSubscriptionExample } from '../molecules/CurrentSubscriptionExample'
import { DocumentViewExample } from '../molecules/DocumentViewExample'
import { ErrorScreenExample } from '../molecules/ErrorScreenExample'
import { ImagesCarouselExample } from '../molecules/ImagesCarouselExample'
import { LicencePlateFieldSetExample } from '../molecules/LicencePlateFieldSetExample'
import { ModalsExample } from '../molecules/ModalsExample'
import { MyTeamCardModalExample } from '../molecules/MyTeamCardModalExample'
import { NoInternetScreenExample } from '../molecules/NoInternetScreenExample'
import { PaymentRedirectExample } from '../molecules/PaymentRedirectExample'
import { RadioGroupFieldExample } from '../molecules/RadioGroupFieldExample'
import { RequestedVehicleItemExample } from '../molecules/RequestedVehicleItemExample'
import { ResetLinkExpiredContainerExample } from '../molecules/ResetLinkExpired'
import { ResetPasswordSuccessScreenExample } from '../molecules/ResetPasswordSuccessScreenExample'
import {
  EmptySSNInformationCardExample,
  FilledSSNInformationCardExample
} from '../molecules/SSNInformationCardExample'
import { SupportFormExample } from '../molecules/SupportForm'
import { TradeInExample } from '../molecules/TradeInExample'
import { UploadFileComponentExample } from '../molecules/UploadFileComponentExample'
import { VehicleDetailsMainInfoExample } from '../molecules/VehicleDetailsMainInfoExample'
import {
  FullVehicleDetailsOverviewExample,
  OldVehicleDetailsOverviewExample
} from '../molecules/VehicleDetailsOverview'
import { VinFieldSetExample } from '../molecules/VinFieldSetExample'

export enum COMPONENT {
  Atoms = 'Atoms',
  Molecules = 'Molecules',
  Features = 'Features',
  Icons = 'Icons'
}

const ATOMS = {
  AudioPlayer: <AudioPlayerExample />,
  AutoSelect: <AutoSelectExample />,
  AutoselectColorOption: <AutoselectColorOptionExample />,
  Breadcrumbs: <BreadcrumbsExample />,
  Buttons: <ButtonsExample />,
  Counter: <CounterExample />,
  DefaultEmptyState: <DefaultEmptyStateExample />,
  EllipsisEmail: <EllipsisEmailExample />,
  EllipsisText: <EllipsisTextExample />,
  EmptyDivider: <EmptyDividerExample />,
  EmptySellerNotes: <EmptySellerNotesExample />,
  ErrorTextWithRetry: <ErrorTextWithRetryExample />,
  FilledDivider: <FilledDividerExample />,
  FilledSellerNotes: <FilledSellerNotesExample />,
  FindCarFormStepper: <FindCarFormStepperExample />,
  FindCarResult: <FindCarResultExample />,
  IconText: <IconTextExample />,
  InformationLabel: <InformationLabelExample />,
  InputField: <InputFieldExample />,
  Label: <LabelExample />,
  Loader: <LoaderExample />,
  ManualDataGetting: <ManualDataGettingExample />,
  MultipleRadioVehicleComponent: <MultipleRadioVehicleComponentExample />,
  NavigationBackButton: <NavigationBackButtonExample />,
  NotesField: <NotesFieldExample />,
  NumberBadge: <NumberBadgeExample />,
  NumericField: <NumericFieldExample />,
  Pagination: <PaginationExample />,
  Progress: <ProgressExample />,
  RadioGroup: <RadioGroupExample />,
  RadioVehicleComponent: <RadioVehicleComponentExample />,
  SelectElement: <SelectElementExample />,
  Snackbar: <SnackbarExample />,
  Spinner: <SpinnerExample />,
  Switch: <SwitchExample />,
  Toggle: <ToggleExample />,
  Typography: <TypographyExample />,
  UploadVehiclePhotoGreeting: <UploadVehiclePhotoGreetingExample />,
  VideoPlayer: <VideoPlayerExample />,
}
const MOLECULES = {
  AccountMember: <AccountMemberExample />,
  AddDocumentModal: <AddDocumentModalExample />,
  AddOns: <AddOnsExample />,
  AddressCard: <AddressCardExample />,
  CarItem: <CarItemExample />,
  ChatContainer: <ChatContainerExample />,
  ChatList: <ChatListExample />,
  ChatMessage: <TextMessageExample />,
  CheckboxVehicleComponent: <CheckboxVehicleComponentExample />,
  ContactInformation: <ContactInformationExample />,
  CurrentSubscription: <CurrentSubscriptionExample />,
  DocumentViewExample: <DocumentViewExample />,
  EmptySSNInformationCard: <EmptySSNInformationCardExample />,
  ErrorScreen: <ErrorScreenExample />,
  FilledSSNInformationCard: <FilledSSNInformationCardExample />,
  FullVehicleDetailsOverview: <FullVehicleDetailsOverviewExample />,
  ImagesCarousel: <ImagesCarouselExample />,
  LicencePlateFieldSet: <LicencePlateFieldSetExample />,
  MediaMessage: <ChatMediaMessageExample />,
  Modals: <ModalsExample />,
  MyTeamCardModal: <MyTeamCardModalExample />,
  NoInternetScreen: <NoInternetScreenExample />,
  OldVehicleDetailsOverview: <OldVehicleDetailsOverviewExample />,
  PaymentRedirect: <PaymentRedirectExample />,
  RadioGroupField: <RadioGroupFieldExample />,
  RequestedVehicleItem: <RequestedVehicleItemExample />,
  ResetLinkExpiredContainer: <ResetLinkExpiredContainerExample />,
  ResetPasswordSuccessScreen: <ResetPasswordSuccessScreenExample />,
  SupportForm: <SupportFormExample />,
  TradeIn: <TradeInExample />,
  UploadFileComponent: <UploadFileComponentExample />,
  VehicleDetailsMainInfo: <VehicleDetailsMainInfoExample />,
  VinFieldSet: <VinFieldSetExample />,
}
const FEATURES = {
  BuildCarPreviewScreen: <BuildCarPreviewScreenExample />,
  BuildCarTradeIn: <BuildCarTradeInExample />,
  ContactSupportSideBar: <ContactSupportSideBar />,
  DeliveryCard: <DeliveryCardExample />,
  DisplayStandardEquipment: <DisplayStandardEquipmentExample />,
  EmptyDriverLicenseInformationCard: <EmptyDriverLicenseInformationCardExample />,
  FilledDriverLicenseInformationCard: <FilledDriverLicenseInformationCardExample />,
  FindCarForm: <FindCarFormExample />,
  FormOrchestrator: <FormOrchestratorExample />,
  OfferDetailsWidget: <OfferDetailsWidgetExample />,
  RequestDetails: <RequestDetailsExample />,
  SubscriptionMicroform: <SubscriptionMicroformExample />,
  SubscriptionModal: <SubscriptionModalExample />,
  UploadNewDocumentModal: <UploadNewDocumentModalExample />,
}
export const ICONS = {
  Icons: <IconsExample />,
  Images: <ImagesExample />,
  Logos: <LogoExample />
}

export type ComponentsType = keyof typeof ATOMS &
  keyof typeof MOLECULES &
  keyof typeof FEATURES

export const COMPONENTS_MAP: {
  [key in COMPONENT]: { [key1 in ComponentsType]: Element }
} = {
  [COMPONENT.Atoms]: ATOMS,
  [COMPONENT.Molecules]: MOLECULES,
  [COMPONENT.Features]: FEATURES,
  [COMPONENT.Icons]: ICONS
}
