import { FC } from 'react'
import { RegistrationForm } from '../../forms/Registration'

import { MainCarImg } from '../../assets/imgs/MainCarImg'
import { OnboardingStepFourImg } from '../../assets/imgs/OnboardingStepFourImg'
import { OnboardingStepOneImg } from '../../assets/imgs/OnboardingStepOneImg'
import { OnboardingStepThreeImg } from '../../assets/imgs/OnboardingStepThreeImg'
import { OnboardingStepTwoImg } from '../../assets/imgs/OnboardingStepTwoImg'
import { PromotionBlock } from '../../atoms/PromotionBlock'
import { ServicePageContainer } from '../../layout/ServicePageContainer'
import { selectOnboardingStep } from '../../store/units/registrationForm/selectors'
import { OnboardText } from '../../types/enums/OnboardText'
import { useAppSelector } from '../../utils/hooks'

export const descriptions = {
  0: OnboardText.stepOneDesc,
  1: OnboardText.stepTwoDesc,
  2: OnboardText.stepThreeDesc,
  3: OnboardText.stepThreeSubmittedDesc,
  4: OnboardText.stepFourDesc
}

export const images = {
  0: <OnboardingStepOneImg />,
  1: <OnboardingStepTwoImg />,
  2: <OnboardingStepThreeImg />,
  3: <MainCarImg />,
  4: <OnboardingStepFourImg />
}

export const CreateAccountPage: FC = () => {
  const step = useAppSelector(selectOnboardingStep)

  return (
    <ServicePageContainer>
      <PromotionBlock
        title={OnboardText.title}
        subtitle={OnboardText.subtitle}
        description={descriptions[step]}
        image={images[step]}
      />
      <RegistrationForm />
    </ServicePageContainer>
  )
}
