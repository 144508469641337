import { Box } from '@mui/material'
import { styled } from '@mui/system'
import { BORDERS, COLORS } from '../../../../types/colors'

export const UserInfoCardContainer = styled('div')`
  display: flex;
  margin-right: 16px;
`

export const ImgBlock = styled('div')`
  position: relative;
  width: fit-content;
  padding-right: 32px;
  line-height: 0;
`

export const SettingButtonContainer = styled(Box)`
  background-color: ${COLORS.secondary_background};
  border: ${BORDERS.SM_GRAY};
  border-radius: 100%;
`

export const PersonalDetails = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`

export const NameContainer = styled('div')`
  font-weight: 500;
  font-size: 24px;
`

export const LocationContainer = styled('div')`
  display: flex;
`
