export enum BUYER_FIELDS_LABELS {
  NAME = 'Buyer legal name',
  ADDRESS = 'Buyer legal address',
  ZIP_CODE = 'Zip code',
  CITY = 'City',
  STATE = 'State'
}

export enum BUYER_FIELDS_NAMES {
  NAME = 'buyerName',
  ADDRESS = 'buyerAddress',
  ZIP_CODE = 'zipCode',
  CITY = 'city',
  STATE = 'state'
}

export interface BuyerFieldsTypes {
  [BUYER_FIELDS_NAMES.NAME]: string
  [BUYER_FIELDS_NAMES.ADDRESS]: string
  [BUYER_FIELDS_NAMES.ZIP_CODE]: string
  [BUYER_FIELDS_NAMES.CITY]: string
  [BUYER_FIELDS_NAMES.STATE]: string
}

export enum BUYER_FIELDS_ERRORS {
  NAME = 'Enter a valid Legal Name.',
  ADDRESS = 'Enter a valid Street format.',
  ZIP_CODE = 'Enter a valid Zip Code.'
}
