import { FC } from 'react'
import { PageContainer } from '../../layout/PageContainer'
import { VehicleDetails } from './components/VehicleDetails'

export const VehicleDetailsPage: FC = () => {
  return (
    <PageContainer>
      <VehicleDetails />
    </PageContainer>
  )
}
