import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { format } from 'date-fns'
import { FC, useMemo } from 'react'

import {
  NormalizedRequestType,
  NormalizedTradeInType
} from '../../../../../api/carSnoopApi/transactions/types'
import { DisplayColors } from '../../../../../atoms/DisplayColors'
import { For } from '../../../../../atoms/JSXExtensions'
import { OrNull } from '../../../../../types/OrNull'
import { formatPrice } from '../../../../../utils'
import { firstOf, lastOf } from '../../../../../utils/array'

export type RequestTableBodyProps = NormalizedRequestType & {
  tradeinVehicle: OrNull<NormalizedTradeInType>
}

export const RequestTableBody: FC<RequestTableBodyProps> = (props) => {
  const {
    make,
    model,
    trim,
    years,
    interiorColors,
    exteriorColors,
    tradeinVehicle,
    expiresOn
  } = props

  const theme = useTheme()
  const isBreakpointDownLg = useMediaQuery(theme.breakpoints.down('lg'))

  const tradeinValue = useMemo(
    () =>
      tradeinVehicle
        ? formatPrice(tradeinVehicle.tradeInValue)
        : 'Not included',
    [tradeinVehicle]
  )

  const displayExpirationDate = useMemo(() => {
    return format(expiresOn, 'MM / dd / yyyy')
  }, [expiresOn])

  const tableData: { element: JSX.Element; grid: number }[] = useMemo(() => {
    const title = [make, model, trim].filter(Boolean).join(' ')

    const year =
      years.length > 1 ? `${firstOf(years)} - ${lastOf(years)}` : `${years[0]}`

    const tabletElements = [
      {
        element: (
          <Grid container>
            <Grid item xs={12}>
              <Typography variant='main'>{title} {year}</Typography>
            </Grid>
          </Grid>
        ),
        grid: 6
      },
      {
        element: (
          <Typography variant='main'>
            <DisplayColors colors={exteriorColors} />/{' '}
            <DisplayColors colors={interiorColors} />
          </Typography>
        ),
        grid: 6
      },
      {
        element: <Typography variant='main'>{tradeinValue}</Typography>,
        grid: 6
      },
      {
        element: (
          <Grid container gap={1}>
            <Grid item>
              <AccessTimeIcon />
            </Grid>
            <Grid item>
              <Typography variant='main'>{displayExpirationDate}</Typography>
            </Grid>
          </Grid>
        ),
        grid: 6
      }
    ]

    const desktopElements = [
      { element: <Typography variant='main'>{title}</Typography>, grid: 1 },
      { element: <Typography variant='main'>{year}</Typography>, grid: 1 },
      {
        element: (
          <Typography variant='main'>
            <DisplayColors colors={exteriorColors} />
            {' '}/{' '}
            <DisplayColors colors={interiorColors} />
          </Typography>
        ),
        grid: 1
      },
      {
        element: <Typography variant='main'>{tradeinValue}</Typography>,
        grid: 1
      },
      {
        element: (
          <Grid container gap={1}>
            <Grid item>
              <AccessTimeIcon />
            </Grid>
            <Grid item>
              <Typography variant='main'>{displayExpirationDate}</Typography>
            </Grid>
          </Grid>
        ),
        grid: 1
      }
    ]

    return isBreakpointDownLg ? tabletElements : desktopElements
  }, [isBreakpointDownLg, displayExpirationDate])

  return (
    <Grid
      container
      item
      justifyContent='space-between'
      columns={{ xs: 12, md: 24, lg: 5 }}
    >
      <For of={tableData}>
        {({ element, grid }) => (
          <Grid item flexGrow={1} xs={grid}>
            {element}
          </Grid>
        )}
      </For>
    </Grid>
  )
}
