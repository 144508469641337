import { Grid, Radio, Typography } from '@mui/material'
import { FC, useCallback, useMemo } from 'react'
import { COLORS } from '../../../../types/colors'
import { formatNumber } from '../../../../utils'
import { ISubscriptionOption } from '../SubscriptionMicroform.types'
import { CardHeader, PaperContainer } from './SubscriptionRadioButton.styles'

interface ISubscriptionRadioButtonProps extends ISubscriptionOption {
  onChange: (value: string) => void
  checked: boolean
}

export const SubscriptionRadioButton: FC<ISubscriptionRadioButtonProps> = ({
  checked,
  name,
  label,
  dealsPerMonth,
  cost,
  onChange
}) => {
  const handleCardClick = useCallback(() => {
    onChange(name)
  }, [name, onChange])
  const formattedCost = useMemo(() => {
    return formatNumber(cost, { includeDecimalPart: cost === 0 })
  }, [cost])

  return (
    <PaperContainer elevation={3} onClick={handleCardClick}>
      <Grid container flexDirection='column'>
        <CardHeader isChosen={checked} item container>
          <Grid item container justifyContent='space-between' mb='12px'>
            <Grid item>
              <Typography variant='h6'>{name}</Typography>
            </Grid>
            <Grid item>
              <Radio
                checked={checked}
                color='default'
                sx={{
                  margin: 1,
                  color: COLORS.text_primary,
                  padding: 0
                }}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant='main' fontStyle='italic'>
              {label}
            </Typography>
          </Grid>
        </CardHeader>
        <Grid item container p='28px 24px 24px' flexDirection='column'>
          <Grid item>
            <Typography variant='title1'>{dealsPerMonth}</Typography>
          </Grid>
          <Grid item>
            <Typography variant='main' fontStyle='italic'>
              per month
            </Typography>
          </Grid>
        </Grid>
        <Grid item p='28px 24px 24px'>
          <Typography variant='h6'>${formattedCost}</Typography>
        </Grid>
      </Grid>
    </PaperContainer>
  )
}
