export enum SIDE_BAR {
  CONTACT_SUPPORT = 'CONTACT_SUPPORT',
  NONE = 'NONE'
}

interface ISupportSideBarContent {
  email: string
  reason: string
}

export interface ISideBarContent {
  [SIDE_BAR.CONTACT_SUPPORT]: ISupportSideBarContent
  [SIDE_BAR.NONE]: Record<string, unknown>
}

type SideBarContentType = { email: string; reason: string } & Record<
  string,
  unknown
>

export interface ISideBarPayload {
  sideBarType: SIDE_BAR
  header: string
  content: SideBarContentType
}
