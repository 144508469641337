import { ThemeProvider } from '@mui/material/styles'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { StrictMode } from 'react'
import { createRoot, hydrateRoot } from 'react-dom/client'
import TagManager from 'react-gtm-module'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { config } from './constants/config'
import { ApplicationRouter } from './layout/ApplicationRouter/ApplicationRouter'
import { ApplicationWrapper } from './layout/ApplicationWrapper'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { store } from './store/store'
import './styles/resets.css'
import { theme } from './theme'

const tagManagerArgs = {
  gtmId: config.GTM_ID
}

TagManager.initialize(tagManagerArgs);

const App = () =>
  <StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <GoogleOAuthProvider clientId={config.GOOGLE_CLIENT_ID}>
            <ApplicationWrapper>
              <ApplicationRouter />
            </ApplicationWrapper>
          </GoogleOAuthProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </StrictMode>

const rootElement = document.getElementById("root") as HTMLElement;

if (rootElement.hasChildNodes()) {
  hydrateRoot(rootElement, <App />);
} else {
  createRoot(rootElement).render(<App />);
}

serviceWorkerRegistration.register()
