export const trimZeroes =
  (startWith?: string) =>
  (v: string): string => {
    const [int, decimal] = v.replace(startWith || '', '').split('.')

    const zeroes = int.match(/^([0,]+)(\d)/)

    if (zeroes) {
      const zeroesEnd = zeroes[1].length
      const valueWithoutZeroes = int.slice(zeroesEnd)
      const result = `${startWith || ''}${valueWithoutZeroes}${
        decimal ? `.${decimal}` : ''
      }`

      return result
    }

    return v
  }
