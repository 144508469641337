import { ISubscriptionOption } from './SubscriptionMicroform.types'

export enum SUBSCRIPTION_FIELD_NAMES {
  SUBSCRIPTION = 'subscription'
}

// TODO: In the future Anatoly Grishanovich will change this options to dynamic =)
export const subscriptionOptions: ISubscriptionOption[] = [
  {
    name: 'Free',
    label: 'Buy, Sell or Trade In your Car',
    dealsPerMonth: '1 Deal',
    cost: 0
  },
  {
    name: 'Small',
    label: 'Buy, Sell or Trade In your Car',
    dealsPerMonth: '10 Deals',
    cost: 999
  },
  {
    name: 'Medium',
    label: 'Buy, Sell or Trade In your Car',
    dealsPerMonth: '50 Deals',
    cost: 1999
  },
  {
    name: 'Large',
    label: 'Buy, Sell or Trade In your Car',
    dealsPerMonth: 'Unlimited Deals',
    cost: 3999
  }
]
