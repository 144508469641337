import { useCallback, useEffect } from 'react'
import {
  useApiContext,
  useAppDispatch,
  useAppSelector,
  useMakeService,
  useUserInfo
} from '../../../utils/hooks'
import { selectChatList, selectHasUnread } from '../model/selectors'
import { actionsMiniChat } from '../model/actions'
import { ResponseStatusMap } from '../../../types/status'
import { formatReadChats } from '../../../api/chat/types/readChats'

export const useMiniChatReader = (initialGetting: boolean = true) => {
  const dispatch = useAppDispatch()
  const chatList = useAppSelector(selectChatList)
  const hasUnread = useAppSelector(selectHasUnread)

  const { chatApi } = useApiContext()
  const { id: userId } = useUserInfo()

  const readChatsService = useCallback(async () => {
    const response = await chatApi.readChats({
      user: userId
    })

    if (response.status === ResponseStatusMap.Success) {
      const { payload } = response
      const formattedPayload = formatReadChats(payload, userId)
      dispatch(actionsMiniChat.setChatList(formattedPayload))

      return response
    }

    return response
  }, [userId, dispatch])

  const [readChats] = useMakeService(
    readChatsService,
    // Discussable
    { includeCheckAccess: true, withStatusNotification: [1, 0] }
  )

  const getChatList = useCallback(async () => {
    await readChats()
  }, [readChats])

  useEffect(() => {
    if (initialGetting) {
      getChatList()
    }
  }, [])

  return {
    chatList,
    hasUnread,
    getChatList
  }
}
