import { FC } from 'react'
import { Grid, Typography } from '@mui/material'
import { DetailsItem } from '../../../DetailsItem'
import { Divider } from '../../../../../../../../atoms/Divider'
import { Show } from '../../../../../../../../atoms/JSXExtensions'
import { NormalizedContractType } from '../../../../../../../../api/carSnoopApi/transactions/types'

export interface ViewModeProps {
  buyerDetails: NormalizedContractType['buyerDetails']
  coBuyerDetails: NormalizedContractType['additionalBuyerDetails']
}

export const ViewMode: FC<ViewModeProps> = (props) => {
  const { buyerDetails, coBuyerDetails } = props

  const { legalName, legalStreet, legalZipCode, legalCity, legalState } =
    buyerDetails

  return (
    <Grid container item spacing={4}>
      <Grid container item spacing='20px' flexDirection='column'>
        <DetailsItem title='Legal Name' value={legalName} />
        <DetailsItem title='Legal Address' value={legalStreet} />
        <DetailsItem title='Zip Code' value={legalZipCode} />
        <DetailsItem
          title='City, state'
          value={`${legalCity}, ${legalState}`}
          withoutDivider
        />
      </Grid>
      <Show when={coBuyerDetails}>
        {(coBuyer) => {
          const {
            legalName: coBuyerLegalName,
            legalStreet: cobuyerLegalAddress,
            legalZipCode: coBuyerZipCode,
            legalCity: coBuyerCity,
            legalState: coBuyerState
          } = coBuyer

          return (
            <>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item>
                <Typography variant='badge' fontSize={18}>
                  Co-Buyer’s details
                </Typography>
              </Grid>
              <Grid container item spacing='20px' flexDirection='column'>
                <DetailsItem title='Legal Name' value={coBuyerLegalName} />
                <DetailsItem
                  title='Legal Address'
                  value={cobuyerLegalAddress}
                />
                <DetailsItem title='Zip Code' value={coBuyerZipCode} />
                <DetailsItem
                  title='City, state'
                  value={`${coBuyerCity}, ${coBuyerState}`}
                  withoutDivider
                />
              </Grid>
            </>
          )
        }}
      </Show>
    </Grid>
  )
}
