import { FC } from 'react'
import { useField } from 'formik'
import { RadioGroup, RadioGroupProps } from './RadioGroup'

export const RadioGroupField: FC<Omit<RadioGroupProps, 'value'>> = ({
  name,
  ...props
}) => {
  const [field] = useField({ name, type: 'radio' })

  return <RadioGroup {...props} {...field} name={name} />
}
