import { Grid, Typography } from '@mui/material'
import { FC, useMemo } from 'react'
import { Show } from '../../../../atoms/JSXExtensions'
import { AddShipmentDetailsParamsAddress } from '../../../../api/carSnoopApi/shipments/types'

interface IAddressInfo {
  address: AddShipmentDetailsParamsAddress
  maxWidth?: string
}

export const AddressInfo: FC<IAddressInfo> = ({
  maxWidth = '235px',
  address
}) => {
  const { addressName, street, city, state, zipCode } = address

  const addressTitle = useMemo(() => {
    return `${street ?? ''} ${city}, ${state} ${zipCode}`.trim()
  }, [street, city, state, zipCode])

  return (
    <Grid
      maxWidth={maxWidth}
      item
      container
      flexDirection='column'
      width='auto'
      mt='14px'
      ml='40px'
    >
      <Show when={!!addressName}>
        <Typography variant='main'>{addressName}</Typography>
      </Show>
      <Typography variant='main'>{addressTitle}</Typography>
    </Grid>
  )
}
