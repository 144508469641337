import { Box, Grid, Typography } from '@mui/material'
import { ColorOption, RenderOptionFunction } from '../../types/Autoselect'
import { Checkbox, CheckboxChecked } from '../../assets/icons/Checkbox'
import { Condition } from '../Condition'
import {
  AutoselectColorOptionContainer,
  SwatchContainer
} from './AutoselectColorOption.styles'

export const AutoselectColorOption: RenderOptionFunction<ColorOption> = (
  props,
  option,
  state
): JSX.Element => {
  const { name, swatch, disableCheckbox } = option
  const { selected } = state

  return (
    <li {...props}>
      <AutoselectColorOptionContainer container selected={selected}>
        <Grid item container justifyContent='space-between' alignItems='center'>
          <Grid
            item
            container
            xs={swatch ? 10 : 12}
            spacing={1}
            alignItems='center'
            flexWrap='nowrap'
          >
            <Condition
              condition={!disableCheckbox}
              trueContent={
                <Grid item alignItems='center'>
                  <Box height='24px'>
                    {selected ? <CheckboxChecked /> : <Checkbox />}
                  </Box>
                </Grid>
              }
            />

            <Grid item>
              <Grid item container alignItems='center'>
                <Typography variant='subtitle1'>{name}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Condition
            condition={!!swatch}
            trueContent={
              <Grid
                item
                container
                xs={2}
                justifyContent='flex-end'
                alignItems='center'
              >
                <SwatchContainer swatch={swatch} />
              </Grid>
            }
          />
        </Grid>
      </AutoselectColorOptionContainer>
    </li>
  )
}
