import { Grid, Typography } from '@mui/material'

const HeaderItem = ({ title }: { title: string }) => {
  return (
    <Typography variant='details' textTransform='uppercase' textAlign='center'>
      {title}
    </Typography>
  )
}

export const DocumentsTableHeader = ({
  viewType
}: {
  viewType: 'buyer' | 'seller'
}) => {
  return (
    <Grid container alignItems='center' columns={18} px={2}>
      <Grid item xs={6}>
        <HeaderItem title='Name of document' />
      </Grid>
      <Grid item xs={4}>
        <HeaderItem title='Date created' />
      </Grid>
      <Grid item xs={3}>
        <HeaderItem title='Format' />
      </Grid>
      <Grid item xs={5}>
        <HeaderItem title={`${viewType}’s notes`} />
      </Grid>
    </Grid>
  )
}
