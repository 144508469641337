import { TEXT_MESSAGE_TYPE } from '../../../../constants/applicationConstants'
import { ChatListItemType } from '../../../../features/MiniChat/model/types'

export const chatListSet: ChatListItemType[] = [
  {
    transactionId: '9f339359-2340-4666-92fa-d57773758a24',
    transactionStatus: 'Under Contract',
    unreadCount: 1,
    vehicle: {
      make: 'Honda',
      model: 'Civic',
      year: 2019,
      trim: 'EX',
      thumbnail:
        'https://www.honda.co.uk/content/dam/central/portal/nav1-16x9.jpg/_jcr_content/renditions/c2_r.jpg'
    },
    lastMessage: {
      transactionId: '9f339359-2340-4666-92fa-d57773758a24',
      id: '1',
      senderName: 'Seller',
      isSender: false,
      type: TEXT_MESSAGE_TYPE,
      hasRead: false,
      timestamp: new Date().toISOString(),
      message: 'Hello, how are you?'
    }
  },
  {
    transactionId: '4a7ff5ab-a48e-4221-b2d1-d89393202c84',
    transactionStatus: 'Offer Cancelled',
    unreadCount: 13,
    vehicle: {
      make: 'Lamborghini',
      model: 'Aventador',
      year: 2019,
      trim: 'S'
    },
    lastMessage: {
      transactionId: '4a7ff5ab-a48e-4221-b2d1-d89393202c84',
      id: '3',
      message: '',
      senderName: 'Seller',
      isSender: false,
      type: 'image/png',
      hasRead: false,
      timestamp: new Date().toISOString(),
      contentId: '1111-2222-3333-4444',
      contentName: 'googlelogo_color_272x92dp.png',
      contentPreviewURL:
        'https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png'
    }
  }
]
