import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Button, Grid, Typography } from '@mui/material'
import { Form, Formik, FormikProps } from 'formik'
import { FC, memo, useCallback } from 'react'
import { AttentionIcon } from '../../../../assets/icons/Attention'
import { StyledToggleField } from '../../../../atoms/Toggle'
import { Modal } from '../../../../molecules/Modal'
import { useModal } from '../../../../utils/hooks'
import { noop } from '../../../../utils/noop'
import { AddNewUserCard } from './AddNewUserCard'
import { MicroForm } from './Form'
import {
  CobuyerFieldsValues,
  FIELD_NAMES,
  TOGGLE_OPTIONS,
  initialValues
} from './constants'
import { validationSchema } from './validationSchema'

export type FormRefTargetType = FormikProps<CobuyerFieldsValues>

export interface CobuyerMicroformProps {
  defaultValues?: CobuyerFieldsValues
  formRef: (node: FormRefTargetType) => void
}

export const CobuyerMicroform: FC<CobuyerMicroformProps> = memo((props) => {
  const { formRef, defaultValues } = props
  const { isOpen, setOpen } = useModal()

  const handleAddUserClick = useCallback(() => {
    setOpen(true)
  }, [setOpen])

  const handleCloseClick = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  return (
    <>
      <Modal open={isOpen} sx={{ maxWidth: '548px' }}>
        <AddNewUserCard onClose={handleCloseClick} />
      </Modal>
      <Formik<CobuyerFieldsValues>
        initialValues={defaultValues || initialValues}
        onSubmit={noop}
        validateOnChange={false}
        innerRef={formRef}
        validationSchema={validationSchema}
      >
        {(formik) => {
          const isFormDisabled = formik.values[FIELD_NAMES.ADD_COBUYER_TOGGLE]

          return (
            <Form>
              <Grid container flexDirection='column' spacing={2}>
                {/* Controls */}
                <Grid
                  container
                  item
                  alignItems='center'
                  spacing={4}
                  justifyContent='space-between'
                >
                  <Grid item>
                    <Typography variant='label1'>
                      Would like to add Co-Buyers?
                    </Typography>
                  </Grid>
                  <Grid item>
                    <StyledToggleField
                      name={FIELD_NAMES.ADD_COBUYER_TOGGLE}
                      leftLabel={TOGGLE_OPTIONS.YES}
                      rightLabel={TOGGLE_OPTIONS.NO}
                      leftOptionAlign='18%'
                      rightOptionAlign='20%'
                    />
                  </Grid>
                </Grid>
                {/* /Controls */}
                {/* Description */}
                <Grid item>
                  <Grid item>
                    <Typography variant='subtitle2'>
                      Co-Buyer will be included in your contract and will appear
                      on the vehicle title
                    </Typography>
                  </Grid>
                  <Grid container item alignItems='center' spacing={1}>
                    <Grid item>
                      <AttentionIcon />
                    </Grid>
                    <Grid item>
                      <Typography variant='subtitle2'>
                        If Co-Buyer is not on your profile yet, you may add them
                        here
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {/* /Description */}
                {/* Form header */}
                <Grid container item justifyContent='space-between'>
                  <Grid item>
                    <Typography variant='main'>Co-Buyer</Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant='text'
                      disableElevation
                      startIcon={<AddCircleOutlineIcon />}
                      sx={{
                        whiteSpace: 'nowrap',
                        textTransform: 'none',
                        fontWeight: '400'
                      }}
                      onClick={handleAddUserClick}
                      disabled={isFormDisabled}
                    >
                      Add New User
                    </Button>
                  </Grid>
                </Grid>
                {/* /Form header */}
                {/* Form */}
                <MicroForm />
                {/* /Form */}
              </Grid>
            </Form>
          )
        }}
      </Formik>
    </>
  )
})
