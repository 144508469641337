import { CircularProgress, styled } from '@mui/material'
import { Box, SxProps } from '@mui/system'
import { FC } from 'react'
import { COLORS } from '../../types/colors'

export const StyledSpinner = styled(CircularProgress)({
  '&.MuiCircularProgress-root': {
    color: COLORS.active_yellow
  }
})

export interface SpinnerProps {
  size?: number
  sx?: SxProps
}

export const Spinner: FC<SpinnerProps> = ({ size = 24, sx = {} }) => (
  // Box is needed to prevent strange spinner shaking
  <Box
    sx={{
      alignItems: 'center',
      display: 'flex',
      ...sx
    }}
  >
    <StyledSpinner size={size} />
  </Box>
)
