import { FC, useCallback, useMemo } from 'react'
import { Formik, Form } from 'formik'
import { Grid, Typography } from '@mui/material'
import { useModal } from '../../utils/hooks'
import { DocumentUploader } from '../../molecules/ImagesCarousel/components/Drop/DocumentUploader'
import {
  ConfirmationModal,
  DiscardConfirmationModal
} from '../../molecules/Modal'
import { NotesField } from '../../atoms/NotesField'
import { AgreeButton, CancelButton } from '../../atoms/Button'
import { StopPropagationContainer } from '../../atoms/StopPropagationContainer'
import { validationSchema } from './validationSchema'
import { UploadDocumentFieldsTypes, UploadDocumentFormType } from './types'
import { useDocumentUpload } from './useDocumentUpload'
import { Loader } from '../../atoms/Loader'
import { Show } from '../../atoms/JSXExtensions'
import { OrNull } from '../../types/OrNull'
import { RequiredLabel } from '../../atoms/RequiredText'

interface UploadNewDocumentModalProps {
  name: string
  isOpen: boolean
  id?: string
  type?: string
  notes?: OrNull<string>
  createdOn?: OrNull<Date>
  isLoading?: boolean
  setOpen: (value: boolean) => void
  onCancel: () => void
  onSubmit: (data: UploadDocumentFormType) => Promise<void>
  preventDragEvents?: boolean
}

const desktopModalSizes = {
  minHeight: 400,
  maxHeight: '90vh',
  overflow: 'hidden',
  width: 750,
  mb: '24px'
}

export const UploadNewDocumentModal: FC<UploadNewDocumentModalProps> = (
  props
) => {
  const {
    name,
    isOpen,
    id = '',
    type = '',
    createdOn = null,
    notes = '',
    isLoading = false,
    setOpen,
    onCancel,
    onSubmit,
    preventDragEvents = true
  } = props

  const {
    document,
    progress,
    setProgress,
    handleUpload,
    handleBeforeUpload,
    handleDiscardUpload,
    errorMessage
  } = useDocumentUpload({ id, type, createdOn, isOpen })

  const handleDiscardSubmit = useCallback(() => {
    handleDiscardUpload()
    onCancel()
  }, [handleDiscardUpload, onCancel])

  const discardUploadingModal = useModal(handleDiscardSubmit)

  const onSubmitClick = useCallback(
    (values: UploadDocumentFieldsTypes) => {
      onSubmit({ notes: values.notes, file: document })
    },
    [document, onSubmit]
  )

  const onOpenDiscard = useCallback(() => {
    discardUploadingModal.setOpen(true)
  }, [discardUploadingModal])

  const headerText = useMemo(
    () => (id && type ? 'Edit Document' : 'Upload Document'),
    [id, type]
  )

  const hasInitialContent = useMemo(
    () => document?.content === id,
    [document?.content, id]
  )

  return (
    <StopPropagationContainer preventDragEvents={preventDragEvents}>
      <ConfirmationModal
        header={
          <Grid item>
            <Typography variant='h6'>{headerText}</Typography>
          </Grid>
        }
        isOpen={isOpen}
        setIsOpen={setOpen}
        cancel={{
          text: '',
          callback: onOpenDiscard,
          closeModal: false
        }}
      >
        <Show
          when={!isLoading}
          fallback={
            <Grid container justifyContent='center'>
              <Loader />
            </Grid>
          }
        >
          <Grid container alignItems='center' p={4} sx={desktopModalSizes}>
            <Formik<UploadDocumentFieldsTypes>
              initialValues={{
                notes: notes || ''
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmitClick}
              validateOnMount
              validateOnChange={false}
            >
              {({ isValid, dirty }) => {
                const isSubmitDisabled =
                  !isValid ||
                  !document ||
                  !!errorMessage ||
                  (!dirty && hasInitialContent)

                return (
                  <Form style={{ width: '100%' }}>
                    <Grid container item flexDirection='column' rowSpacing={3}>
                      <Grid item container xs={12} spacing={2}>
                        <Grid item xs={12}>
                          <RequiredLabel marginTop={0} />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant='main'>
                            Accepted file formats: .pdf, .jpeg, .png, .heic,
                            .heif <Typography variant='error'>*</Typography>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <DocumentUploader
                            name={name}
                            document={document}
                            onBeforeUpload={handleBeforeUpload}
                            onFileDelete={handleDiscardUpload}
                            onFileUpload={handleUpload}
                            error={errorMessage}
                            progress={progress}
                            setProgress={setProgress}
                          />
                        </Grid>
                      </Grid>
                      <Grid item container xs={12} spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant='emphasized'>Notes</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <NotesField
                            name='notes'
                            label=''
                            placeholder='You may leave any additional information here.'
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        justifyContent='flex-end'
                        spacing={2}
                      >
                        <Grid item>
                          <CancelButton onClick={onOpenDiscard}>
                            Cancel
                          </CancelButton>
                        </Grid>
                        <Grid item>
                          <AgreeButton
                            type='submit'
                            disabled={isSubmitDisabled}
                          >
                            Submit
                          </AgreeButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Form>
                )
              }}
            </Formik>
            <DiscardConfirmationModal {...discardUploadingModal} />
          </Grid>
        </Show>
      </ConfirmationModal>
    </StopPropagationContainer>
  )
}
