import { useCallback } from 'react'
import { Grid } from '@mui/material'
import { AgreeButton, CancelButton } from '../../../../../atoms/Button'
import { FormOrchestratorStep } from '../../../../../layout/FormOrchestrator/types'

export const PreviewStep: FormOrchestratorStep = (props) => {
  const { ctx } = props

  const handleSubmit = useCallback(() => {
    ctx.nextStep()
  }, [])

  const handleBack = useCallback(() => {
    ctx.prevStep()
  }, [])

  return (
    <Grid container item spacing='12px'>
      {Object.keys(ctx.values).map((field, i) => {
        return (
          <Grid container item xs={12} key={i}>
            <Grid item xs={6}>
              {field}
            </Grid>
            <Grid item xs={6}>
              {ctx.values[field].toString()}
            </Grid>
          </Grid>
        )
      })}

      <Grid item xs={6}>
        <CancelButton onClick={handleBack}>Back</CancelButton>
      </Grid>

      <Grid item xs={6}>
        <AgreeButton onClick={handleSubmit}>SUBMIT</AgreeButton>
      </Grid>
    </Grid>
  )
}
