import { FC, Dispatch, SetStateAction } from 'react'
import { Grid, Typography } from '@mui/material'
import { ConfirmationModal } from '../../molecules/Modal'
import { EnvelopeIcon } from '../../assets/icons/EnvelopeIcon'
import { SUPPORT_EMAIL } from '../../constants/applicationConstants'
import {
  SubscriptionModalContainer,
  SubscriptionModalContentContainer,
  SubscriptionTypography,
  IconContainer,
  SupportLink,
  confirmationModalStyle,
  submitButtonStyle
} from './SubscriptionInfoModal.styles'

interface SubscriptionInfoModalProps {
  isOpen: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export const SubscriptionInfoModal: FC<SubscriptionInfoModalProps> = ({
  isOpen,
  setOpen
}) => {
  return (
    <ConfirmationModal
      isOpen={isOpen}
      setIsOpen={setOpen}
      sx={confirmationModalStyle}
      header={
        <Grid item>
          <Typography variant='h6'>Subscription</Typography>
        </Grid>
      }
      submit={{
        text: 'Got it',
        closeModal: true,
        sx: submitButtonStyle
      }}
      buttonsPosition='center'
    >
      <SubscriptionModalContainer container>
        <SubscriptionModalContentContainer container rowSpacing={4}>
          <IconContainer item>
            <EnvelopeIcon width='184' height='103' />
          </IconContainer>
          <SubscriptionTypography variant='main'>
            {`Regarding pricing, please, contact\n`}
            <SupportLink href={`mailto:${SUPPORT_EMAIL}`}>
              {SUPPORT_EMAIL}
            </SupportLink>
          </SubscriptionTypography>
        </SubscriptionModalContentContainer>
      </SubscriptionModalContainer>
    </ConfirmationModal>
  )
}
