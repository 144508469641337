import { Grid, Typography } from '@mui/material'
import { FC, useCallback } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { UpdateContractParams } from '../../../../api/carSnoopApi/contracts/types'
import { NormalizedTransactionType } from '../../../../api/carSnoopApi/transactions/types'
import { Show } from '../../../../atoms/JSXExtensions'
import { Loader } from '../../../../atoms/Loader'
import { INTERNAL_SHIPMENT_TYPE } from '../../../../constants/applicationConstants'
import { routes } from '../../../../routes'
import { OrNull } from '../../../../types/OrNull'
import { ResponseStatusMap } from '../../../../types/status'
import {
  useApiContext,
  useDeviceInfo,
  useMakeService
} from '../../../../utils/hooks'
import { useContractMessage } from '../../hooks/useContractMessage'
import { useContractPageInfo } from '../../hooks/useContractPageInfo'
import {
  ContractViewStates,
  useContractStateDecoder
} from '../../hooks/useContractStateDecoder'
import { PaymentsWidget } from './PaymentsWidget'
import { BuyerDetails } from './components/BuyerDetails'
import { BuyerFormFieldsTypes } from './components/BuyerDetails/components/EditMode/types'
import { DisplayPaymentMethod } from './components/DisplayPaymentMethod'
import { OfferDetails } from './components/OfferDetails'
import { SellerDetails } from './components/SellerDetails'
import { SellerFormFieldsTypes } from './components/SellerDetails/components/EditMode/types'
import {
  extractUpdateBuyerContractParams,
  extractUpdateSellerContractParams
} from './utils'

export interface ContractInformationProps {
  transaction: OrNull<NormalizedTransactionType>
  isLoading: boolean
}

export const ContractInformation: FC<ContractInformationProps> = ({
  transaction,
  isLoading
}) => {
  const navigate = useNavigate()
  const { contractViewState } = useContractStateDecoder(transaction)
  const { carSnoopApi } = useApiContext()
  const { externalCarSnoopApi } = useApiContext()
  const { isTablet } = useDeviceInfo()
  const {
    readTransaction,
    transactionID,
    shouldShowPaymentMethod,
    isContractEditable,
    shipmentCreationIsSuccessful
  } = useContractPageInfo()
  const [updateContract] = useMakeService(
    async (params: UpdateContractParams) => {
      const response = await carSnoopApi.contracts.updateContract(params)
      if (response.status === ResponseStatusMap.Success) {
        readTransaction({ id: transactionID })
      }

      return response
    },
    { withStatusNotification: true, includeCheckAccess: true }
  )

  const contractMessage = useContractMessage(
    contractViewState,
    shipmentCreationIsSuccessful
  )

  const editSellerDetails = useCallback(
    (data: SellerFormFieldsTypes) => {
      if (transaction?.contract) {
        const params: UpdateContractParams = extractUpdateSellerContractParams(
          transaction?.contract,
          data
        )
        updateContract(params)
      }
    },
    [updateContract, transaction?.contract]
  )

  const editBuyerDetails = useCallback(
    (data: BuyerFormFieldsTypes) => {
      if (transaction?.contract) {
        const params: UpdateContractParams = extractUpdateBuyerContractParams(
          transaction?.contract,
          data
        )
        updateContract(params)
      }
    },
    [updateContract, transaction?.contract]
  )

  const [declinePayment, declinePaymentObservable] = useMakeService(
    async () => {
      const res = await carSnoopApi.payments.declinePayment({
        id: transactionID
      })
      if (res.status === ResponseStatusMap.Success) {
        readTransaction({ id: transactionID })
      }

      return res
    },
    { includeCheckAccess: true, withStatusNotification: true }
  )

  const successNavigate = useCallback(() => {
    const path = generatePath(routes.contracts.paymentConfirmed, {
      transactionId: transactionID
    })
    navigate(path)
  }, [transactionID, navigate])

  const [confirmTransactionPayment, confirmTransactionPaymentObservable] =
    useMakeService(
      async () => {
        const isShipmentByService =
          transaction?.contract?.shipmentType ===
          INTERNAL_SHIPMENT_TYPE.shipping

        const confirmPaymentRes =
          await externalCarSnoopApi.payment.confirmPayment({
            id: transactionID
          })

        // confirmPayment success
        if (confirmPaymentRes.status === ResponseStatusMap.Success) {
          // Should call createShipment
          if (isShipmentByService) {
            // createShipment result doesn't matter because we show the shipment creation error on the folowing "pages/states/views"
            const createShipmentRes =
              await externalCarSnoopApi.shipments.createShipment({
                transactionId: transactionID
              })

            if (createShipmentRes.status === ResponseStatusMap.Success) {
              successNavigate()
            }

            if (createShipmentRes.status === ResponseStatusMap.Error) {
              readTransaction({ id: transactionID })
            }

            return confirmPaymentRes
          }
          successNavigate()
        }

        return confirmPaymentRes
      },
      { includeCheckAccess: true, withStatusNotification: true }
    )

  const [completeDeal] = useMakeService(
    async () => {
      const res = await carSnoopApi.shipments.completeShipment({
        id: transactionID
      })
      if (res.status === ResponseStatusMap.Success) {
        readTransaction({ id: transactionID })
      }

      return res
    },
    { includeCheckAccess: true, withStatusNotification: true }
  )

  return (
    <Show
      when={!declinePaymentObservable.isLoading && !isLoading}
      fallback={
        <Grid container justifyContent='center'>
          <Loader />
        </Grid>
      }
    >
      <>
        {/* Message */}
        <Show when={contractMessage}>
          {(message) => <Typography variant='label1'>{message}</Typography>}
        </Show>
        <Grid
          container
          spacing={3}
          mt={0}
          flexDirection={isTablet ? 'column-reverse' : 'row'}
        >
          {/* LeftColumn */}
          <Grid
            item
            container
            flexDirection='column'
            xs={12}
            lg={5}
            spacing={3}
          >
            {/* DisplayPaymentMethod */}
            <Show when={isTablet && shouldShowPaymentMethod}>
              <Grid item>
                <DisplayPaymentMethod
                  method={transaction?.contract?.paymentMethod || ''}
                />
              </Grid>
            </Show>

            {/* /BuyerDetails */}
            <Show when={transaction?.contract}>
              {(contract) => (
                <Grid item>
                  <BuyerDetails
                    title='Buyer’s details'
                    allowEdit={isContractEditable}
                    contract={contract}
                    onSaveClick={editBuyerDetails}
                  />
                </Grid>
              )}
            </Show>

            {/* SellerDetails */}
            <Show when={transaction?.contract}>
              {(contract) => (
                <Grid item>
                  <SellerDetails
                    title='Seller’s details'
                    allowEdit={isContractEditable}
                    contract={contract}
                    onSaveClick={editSellerDetails}
                  />
                </Grid>
              )}
            </Show>
          </Grid>

          {/* RightColumn */}
          <Grid
            item
            container
            flexDirection='column'
            xs={12}
            lg={7}
            spacing={3}
          >
            {/* DisplayPaymentMethod */}
            <Show when={!isTablet && shouldShowPaymentMethod}>
              <Grid item>
                <DisplayPaymentMethod
                  method={transaction?.contract?.paymentMethod || ''}
                />
              </Grid>
            </Show>

            <Show
              when={contractViewState !== ContractViewStates.UNDER_CONTRACT}
            >
              <PaymentsWidget
                transaction={transaction}
                declinePayment={declinePayment}
                confirmPayment={confirmTransactionPayment}
                isLoading={confirmTransactionPaymentObservable.isLoading}
                completeDeal={completeDeal}
              />
            </Show>

            <Show when={transaction?.offer}>
              {(offer) => (
                <Grid item>
                  <OfferDetails
                    offer={offer}
                    includeTradeIn={!!transaction?.tradeInVehicle}
                  />
                </Grid>
              )}
            </Show>
          </Grid>
        </Grid>
      </>
    </Show>
  )
}
