import { Grid, styled } from '@mui/material'

export const ChatsContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  flexWrap: 'nowrap',
  overflowX: 'hidden',
  overflowY: 'auto',
  paddingBottom: '8px',
  '&:hover': {
    cursor: 'pointer'
  }
}))
