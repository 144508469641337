import { DriverLicenseInformationCard } from '../../../PersonalDetails/DriverLicenseInformationCard'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const EmptyDriverLicenseInformationCardExample = () => {
  return (
    <ComponentContainer>
      <ComponentWithPaperBackground
        title='Not in features folder (in routes/PersonalDetails)'
        minWidth='500px'
      >
        <DriverLicenseInformationCard />
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}

export const FilledDriverLicenseInformationCardExample = () => {
  return (
    <ComponentContainer>
      <ComponentWithPaperBackground
        title='Not in features folder (in routes/PersonalDetails)'
        minWidth='500px'
      >
        <DriverLicenseInformationCard
          fullName='Mary Cook'
          licenseNumber='123456789'
          expirationDate='2024-09-04'
          state='New York'
        />
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
