import { Grid } from '@mui/material'
import { CurrentSubscription } from '../../../SubscriptionPage/components'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

const freeSubscription = {
  name: 'Free',
  maxDeals: 1,
  price: 0,
  completedDeals: 0
} as const

const luxurySubscription = {
  name: 'Large',
  maxDeals: 500,
  price: 5000,
  completedDeals: 123
} as const

const luxurySubscriptionOver = {
  name: 'Large',
  maxDeals: 500,
  price: 5000,
  completedDeals: 0
} as const

export const CurrentSubscriptionExample = () => {
  return (
    <ComponentContainer>
      <ComponentWithPaperBackground title='Not in molecules folder'>
        <Grid container item spacing={5}>
          <Grid item>
            <CurrentSubscription isDefault subscription={freeSubscription} />
          </Grid>
          <Grid item>
            <CurrentSubscription
              isDefault={false}
              subscription={luxurySubscription}
            />
          </Grid>
          <Grid item>
            <CurrentSubscription
              isDefault={false}
              disabled
              subscription={luxurySubscriptionOver}
            />
          </Grid>
        </Grid>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
