import { FC, useCallback, useEffect } from 'react'
import { ResponseStatusMap } from '../../types/status'
import { useMakeService, useApiContext } from '../../utils/hooks'
import { ViewReport } from './ViewReport'
import { LoadingText } from './LoadingText'
import { LoadingError } from './LoadingError'
import { SwitchComponent } from '../JSXExtensions'

interface CarFaxInfoProps {
  vin: string
}

export const CarFaxInfo: FC<CarFaxInfoProps> = ({ vin }) => {
  const { externalCarSnoopApi } = useApiContext()

  const [readCarfaxService, readCarfaxObservable] = useMakeService(
    async (id: string) => {
      const response = await externalCarSnoopApi.vehicles.readCarfaxLink(id)

      if (response.status === ResponseStatusMap.Success) {
        return response
      }

      return {
        message: response.message,
        status: ResponseStatusMap.Error,
        url: ''
      }
    }
  )

  const handleCarfaxRequest = useCallback(() => {
    readCarfaxService(vin)
  }, [vin])

  useEffect(() => {
    handleCarfaxRequest()
  }, [handleCarfaxRequest])

  return (
    <SwitchComponent
      case={readCarfaxObservable.status}
      default={<ViewReport reportURL={readCarfaxObservable.payload.url} />}
    >
      {{
        pending: <LoadingText />,
        failed: (
          <LoadingError
            errorMessage={readCarfaxObservable.payload.message}
            handleCarfaxRequest={handleCarfaxRequest}
          />
        )
      }}
    </SwitchComponent>
  )
}
