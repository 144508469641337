import { FC } from 'react'
import { Formik, FormikProps, FormikValues } from 'formik'
import { Grid, Typography } from '@mui/material'
import { FormContainer } from '../../../layout/FormContainer'
import { ForgotPasswordText } from '../constants'
import { TextInputField } from '../../../atoms/TextInputField'
import { ActionBlock } from '../../../atoms/ActionBlock'
import { validationSchema } from './validationSchema'
import { isValidForm } from '../../../utils/isValidForm'
import { RequestStatus } from '../../../types/status'
import { ScreenContainer } from '../../../layout/ScreenContainer'
import { ForgotPasswordForm } from './ForgotPasportContainer.styles'
import { RequiredLabel } from '../../../atoms/RequiredText'

type ForgotPasswordContainerProps = {
  isLoading: boolean
  email: string
  status: RequestStatus
  message?: string
  onSubmit: (form: FormikValues) => Promise<unknown>
  onExit: () => void
}

export const ForgotPasswordContainer: FC<ForgotPasswordContainerProps> = ({
  isLoading,
  email,
  status,
  message,
  onSubmit,
  onExit
}) => {
  const initialValues: FormikValues = { email }

  const validateEmail = (value: string): string | undefined => {
    if (status === 'failed' && value === email) {
      return message
    }
  }

  return (
    <FormContainer title={ForgotPasswordText.title} minHeight='410px' mt={5}>
      <ScreenContainer isLoading={isLoading}>
        <Grid item>
          <Typography variant='subtitle2'>
            {ForgotPasswordText.description}
          </Typography>
        </Grid>
        <Grid item>
          <RequiredLabel marginTop={24} />
        </Grid>
        <Grid container item flexDirection='column' flexGrow={1} marginTop={1}>
          <Formik
            initialValues={initialValues}
            initialErrors={{ email: message }}
            initialTouched={{ email: true }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnMount
          >
            {(formik: FormikProps<FormikValues>) => {
              return (
                <ForgotPasswordForm>
                  <TextInputField
                    name='email'
                    label='Email'
                    placeholder='Email'
                    isValid={!(formik.touched?.email && formik.errors.email)}
                    validate={validateEmail}
                  />
                  <ActionBlock
                    schema={[
                      {
                        label: 'cancel',
                        type: 'secondary',
                        onClick: onExit
                      },
                      {
                        label: 'Submit',
                        type: 'primary',
                        disabled: !isValidForm(formik, {
                          requiredFields: ['email']
                        }),
                        props: {
                          type: 'submit'
                        }
                      }
                    ]}
                  />
                </ForgotPasswordForm>
              )
            }}
          </Formik>
        </Grid>
      </ScreenContainer>
    </FormContainer>
  )
}
