import { useCallback, useEffect, useRef, useState } from 'react'
import { getLinesCount } from '../getLinesCount'

export type UseCountLinesHook = (text: string) => [number, React.MutableRefObject<HTMLElement | undefined>]

export const useCountLines: UseCountLinesHook = (text: string) => {
  const targetRef = useRef<HTMLElement>()
  const [numberOfLines, setNumberOfLines] = useState<number>(0)

  const countLines = useCallback(() => {
    if (!targetRef.current) return

    const linesCount = getLinesCount(targetRef.current)
    setNumberOfLines(linesCount)
  }, [targetRef.current, setNumberOfLines, getLinesCount])

  useEffect(() => {
    countLines()

    window.addEventListener('resize', countLines)

    return () => {
      window.removeEventListener('resize', countLines)
    }
  }, [])

  useEffect(() => {
    countLines()
  }, [text])

  return [numberOfLines, targetRef]
}
