import { Grid } from '@mui/material'
import { FC, useCallback, useMemo, useState } from 'react'
import { generatePath } from 'react-router-dom'
import { CompleteShipmentReturnType } from '../../../../../../api/carSnoopApi/shipments/types'
import {
  NormalizedContractType,
  NormalizedOfferType,
  NormalizedTransactionType
} from '../../../../../../api/carSnoopApi/transactions/types'
import { Condition } from '../../../../../../atoms/Condition'
import { Show } from '../../../../../../atoms/JSXExtensions'
import { Loader } from '../../../../../../atoms/Loader'
import {
  FLOW_PARAM,
  FLOW_SEARCH_PARAM,
  ShipmentTypes
} from '../../../../../../constants/applicationConstants'
import { PaymentRedirect } from '../../../../../../features/PaymentRedirect'
import { routes } from '../../../../../../routes'
import { ResponseStatusMap } from '../../../../../../types/status'
import { useMakeService } from '../../../../../../utils/hooks'
import { useContractPageInfo } from '../../../../hooks/useContractPageInfo'
import { useStripePaymentRedirect } from '../../../../hooks/usePaymentRedirect/useStripePaymentRedirect'
import { DeliveryInfo } from '../../components/DeliveryInfo'
import { DetailsContainer } from '../../components/DetailsContainer'
import { NonTrackableView } from '../../components/Shipment/NonTrackableView'
import { TrackableView } from '../../components/Shipment/TrackableView'
import { isDeliveryLocal } from '../../utils'
import { ChooseShipmentTypeStep } from '../components/ChooseShipmentTypeStep'
import { NON_TRACKABLE_PROPS_BUYER } from '../constants'
import { useAddShipmentDetails } from '../hooks/useAddShipmentDetails'
import { useShipmentInfo } from '../hooks/useShipmentInfo'

type BuyerNonStripeShipmentProps = {
  transactionId: string
  completeDeal: () => CompleteShipmentReturnType
  offer: NormalizedOfferType
  contract: NormalizedContractType
  vehicle: NormalizedTransactionType['vehicle']
  tradeIn: NormalizedTransactionType['tradeIn']
  trackingNumber: NormalizedTransactionType['trackingNumber']
  payments: NormalizedTransactionType['payments']
}

export const BuyerNonStripeShipment: FC<BuyerNonStripeShipmentProps> = ({
  completeDeal,
  ...props
}) => {
  const {
    vehicle,
    tradeIn,
    transactionId,
    contract,
    payments,
    trackingNumber
  } = props

  const deliveryIsLocal = isDeliveryLocal(contract)

  const isShipmentPaid = useMemo(
    () => !!payments?.some((p) => p.type === 'Shipping' && p.status === 'Paid'),
    [payments]
  )

  const shipmentCreationError =
    //  Delivery by service was selected
    !deliveryIsLocal &&
    // and the user has paid for the shipment
    isShipmentPaid &&
    // but shipment wasn't created
    !trackingNumber

  // Amazing! \^.^/
  const [currentStep, setStep] = useState(Number(deliveryIsLocal))

  const {
    shipmentType,
    shipmentInfo,
    onShipmentTypeChange,
    setPickup,
    setDropOff,
    isLoading,
    isDisabled,
    addressesAreValid
  } = useShipmentInfo(props)

  const { sendShipmentInfoObservable, sendShipmentInfo } =
    useAddShipmentDetails()

  const generateSuccessUrl = useCallback((): string => {
    const baseUrl = window.location.origin
    const successUrl = new URL('/', baseUrl)

    successUrl.pathname = generatePath(routes.contracts.paymentSuccess, {
      transactionId
    })

    successUrl.search = `?${FLOW_SEARCH_PARAM}=${FLOW_PARAM.nonStripe}`

    return successUrl.href
  }, [transactionId])

  const {
    getPaymentRedirectUrl,
    isPaymentOpen,
    redirectToPayment,
    handleRedirectFail
  } = useStripePaymentRedirect(generateSuccessUrl, transactionId)

  const { readTransaction, transactionID } = useContractPageInfo()

  const buttonLabel = useMemo(
    () =>
      shipmentType === ShipmentTypes.local ? 'Continue' : 'Pay for shipment',
    [shipmentType]
  )

  const getRedirectUrl = useCallback(() => {
    //  Actually if we call this method we really have a shipmentInfo, but TS isn't so smart as i am
    if (!shipmentInfo) {
      // If it happens handleRedirectFail will catch it
      throw new Error("Shipment info isn't presented. Check the logic")
    }

    const itemsForPaying = [
      {
        amount: shipmentInfo.vehicle.cost,
        productName: 'Shipping',
        productDescription: 'Cost to Ship Vehicle'
      }
    ]

    return getPaymentRedirectUrl(itemsForPaying)
  }, [getPaymentRedirectUrl, shipmentInfo])

  const [handleCompleteDealClick] = useMakeService(
    async () => {
      if (shipmentType === 'Local delivery') {
        const res = await sendShipmentInfo({
          shipmentType,
          transactionId
        })

        if (res.status === ResponseStatusMap.Success) {
          return completeDeal()
        }

        return res
      }

      return completeDeal()
    },
    {
      withStatusNotification: [1]
    }
  )

  const [handleContinueClick] = useMakeService(
    async () => {
      if (shipmentType === 'Shipment') {
        if (shipmentInfo) {
          const res = await sendShipmentInfo({
            shipmentType,
            transactionId,
            shipmentInfo
          })

          if (res.status === ResponseStatusMap.Success) {
            redirectToPayment()
          }

          return res
        }
      } else {
        const res = await sendShipmentInfo({
          shipmentType,
          transactionId
        })

        if (res.status === ResponseStatusMap.Success) {
          readTransaction({ id: transactionID })
          setStep(1)
        }

        return res
      }
    },
    {
      withStatusNotification: [1]
    }
  )

  return (
    <DetailsContainer title='shipment'>
      <Condition
        condition={isShipmentPaid}
        trueContent={
          <TrackableView
            {...props}
            completeDeal={handleCompleteDealClick}
            shipmentCreationError={shipmentCreationError}
          />
        }
        falseContent={
          <>
            <Show when={currentStep === 0}>
              <ChooseShipmentTypeStep
                shipmentType={shipmentType}
                onShipmentTypeChange={onShipmentTypeChange}
                onContinueClick={handleContinueClick}
                buttonLabel={buttonLabel}
                disableContinue={
                  isDisabled || sendShipmentInfoObservable.isLoading
                }
                deliveryAdditionalInfo={
                  <Show when={shipmentType === ShipmentTypes.shipping}>
                    <Show
                      when={!isLoading}
                      fallback={
                        <Grid container justifyContent='center'>
                          <Loader small />
                        </Grid>
                      }
                    >
                      <DeliveryInfo
                        vehicle={vehicle}
                        tradeIn={tradeIn}
                        shipmentInfo={shipmentInfo}
                        setPickup={setPickup}
                        setDropOff={setDropOff}
                        isNotAvailable={isDisabled}
                        hasAddressError={!addressesAreValid}
                      />
                    </Show>
                  </Show>
                }
              />
            </Show>
            <Show when={currentStep === 1}>
              <NonTrackableView
                {...NON_TRACKABLE_PROPS_BUYER}
                completeDeal={handleCompleteDealClick}
              />
            </Show>
          </>
        }
      />
      <PaymentRedirect
        open={isPaymentOpen}
        onRedirectFail={handleRedirectFail}
        getPaymentRedirectUrl={getRedirectUrl}
      />
    </DetailsContainer>
  )
}
