import { styled } from '@mui/system'
import { COLORS } from '../../types/colors'

export const CheckboxContainer = styled('div')`
  display: flex;
  padding: 10px;
  background-color: ${COLORS.secondary_background};
`

export const CheckboxLabel = styled('label')`
  display: block;
  height: fit-content;

  & > span {
    align-self: self-start;
    padding: 0;
    margin-top: -5px;
  }

  & > span > svg {
    font-size: 20px;
  }
`
