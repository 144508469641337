import { useApiContext, useMakeService } from '../../../utils/hooks'
import { SubmitRequestFeeCheckoutType } from '../../../api/externalCarSnoopApi/requests/types'

export const useSubmitRequestFeeCheckout = () => {
  const { externalCarSnoopApi } = useApiContext()

  const [submitRequestFeeCheckout, submitRequestFeeCheckoutObservable] =
    useMakeService(
      async (params: SubmitRequestFeeCheckoutType.Params) => {
        const response =
          await externalCarSnoopApi.requests.submitRequestFeeCheckout(params)

        return response
      },
      { includeCheckAccess: true }
    )

  return {
    submitRequestFeeCheckoutObservable,
    submitRequestFeeCheckout
  }
}
