import { DashboardItem } from '../../../../atoms/DashboardItem'
import { StatisticButton } from '../../../../atoms/StatisticButton'
import { StatisticCardSchema } from '../../../../types/statisticCard'
import { StatisticButtonGrid } from './StatisticCard.styles'

export const StatisticCard = ({ schema }: { schema: StatisticCardSchema }) => {
  return (
    <DashboardItem sx={{ padding: 0 }}>
      <StatisticButtonGrid container>
        <StatisticButton schema={schema} />
      </StatisticButtonGrid>
    </DashboardItem>
  )
}
