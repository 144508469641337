import { Grid, Typography } from '@mui/material'
import { FC, useMemo } from 'react'

export interface DetailsItemProps {
  title: string
  value: string | JSX.Element
}

export const LabelWithCustomValue: FC<DetailsItemProps> = (props) => {
  const { title, value } = props
  const valueComponent = useMemo(() => {
    if (typeof value === 'string') {
      return (
        <Grid item>
          <Typography variant='label1'>{value}</Typography>
        </Grid>
      )
    }

    return value
  }, [value])

  return (
    <Grid container item justifyContent='space-between' alignItems='center'>
      <Grid item>
        <Typography variant='subtitle2' textTransform='uppercase'>
          {title}
        </Typography>
      </Grid>
      {valueComponent}
    </Grid>
  )
}
