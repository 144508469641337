import { Box, Typography } from '@mui/material'
import { FC } from 'react'
import { BORDERS, COLORS } from '../../types/colors'
import { Condition } from '../Condition'

interface DividerProps {
  text?: string
}

export const Divider: FC<DividerProps> = ({ text = '' }) => {
  return (
    <Box
      sx={{
        borderTop: BORDERS.SM_GRAY,
        width: '100%',
        backgroundColor: COLORS.secondary_background,
        py: text ? '3px' : 0
      }}
    >
      <Condition
        condition={!!text}
        trueContent={
          <Typography variant='main' sx={{ fontWeight: 500 }}>
            {text}
          </Typography>
        }
      />
    </Box>
  )
}
