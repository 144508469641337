import { Grid, Typography } from '@mui/material'
import { FC, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { NoInternet } from '../../assets/imgs/NoInternet'
import { ActionBlock } from '../../atoms/ActionBlock'

export interface NoInternetScreenProps {
  onRetry?: () => void
}

export const NoInternetScreen: FC<NoInternetScreenProps> = ({ onRetry }) => {
  const navigate = useNavigate()

  const retryHandler = useCallback(() => {
    if (onRetry) {
      onRetry()
      return
    }

    navigate(0)
  }, [onRetry, navigate])

  return (
    <Grid container justifyContent='center' pt={6}>
      <Grid container item xs={12} justifyContent='center'>
        <NoInternet />
      </Grid>
      <Grid container item xs={12} justifyContent='center'>
        <Typography fontSize='20px' fontWeight={500}>
          No Internet connection.
        </Typography>
      </Grid>
      <Grid item xs={3} justifyContent='center'>
        <ActionBlock
          schema={[
            {
              type: 'primary',
              label: 'Retry',
              onClick: retryHandler
            }
          ]}
        />
      </Grid>
    </Grid>
  )
}
