import { FC, useCallback } from 'react'
import { Grid, Typography } from '@mui/material'
import { FormContainer } from '../../../layout/FormContainer'
import { SuccessIconCircle } from '../../../assets/icons/SuccessIconCircle'
import { LinkButton } from '../../../atoms/LinkButton'
import { ResentLinkConstants } from './constants'
import { ScreenContainer } from '../../../layout/ScreenContainer'

type ResentLinkContainerProps = {
  isLoading: boolean
  email: string
  resendLinkService: (email: string) => Promise<unknown>
}

export const ResentLinkContainer: FC<ResentLinkContainerProps> = ({
  isLoading,
  email,
  resendLinkService
}) => {
  const resendMessage = useCallback(() => {
    resendLinkService(email)
  }, [email, resendLinkService])

  return (
    <FormContainer title={ResentLinkConstants.title} minHeight='390px' mt={5}>
      <ScreenContainer isLoading={isLoading} wrapperProps={{ paddingX: 2 }}>
        <Grid item textAlign='center'>
          <SuccessIconCircle />
        </Grid>
        <Grid item mt={3}>
          <Typography variant='body1' textAlign='center'>
            {ResentLinkConstants.firstParagraph}
          </Typography>
        </Grid>
        <Grid item mt={5}>
          <Typography variant='body1' textAlign='center'>
            {ResentLinkConstants.secondParagraph}
          </Typography>
        </Grid>
        <Grid item mt={3}>
          <Typography variant='subtitle2' textAlign='center'>
            {ResentLinkConstants.bottomText}{' '}
            <LinkButton
              label={ResentLinkConstants.textLink}
              onClick={resendMessage}
            />
          </Typography>
        </Grid>
      </ScreenContainer>
    </FormContainer>
  )
}
