import { FC } from 'react'
import { SwitchBaseProps } from '@mui/material/internal/SwitchBase'
import Radio from '@mui/material/Radio'
import { COLORS } from '../../types/colors'

interface CustomRadioProps {
  checked: boolean
  onChange: SwitchBaseProps['onChange']
}

export const CustomRadio: FC<CustomRadioProps> = ({ checked, onChange }) => {
  return (
    <Radio
      checked={checked}
      onChange={onChange}
      color='default'
      sx={{
        margin: 1,
        backgroundColor: COLORS.white,
        padding: 0,
        '&:hover': {
          backgroundColor: COLORS.white
        }
      }}
    />
  )
}
