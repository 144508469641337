import { Grid } from '@mui/material'
import { DisplayStandardEquipment } from '../../../VehicleDetails/components/VehicleDetails/StandardEquipment'
import { standardEquipmentMock } from './mocks'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

const lessThanMaxCountEquipment = {
  dimensions: standardEquipmentMock.dimensions,
  exterior: standardEquipmentMock.exterior
}

export const DisplayStandardEquipmentExample = () => {
  return (
    <ComponentContainer>
      <ComponentWithPaperBackground title='Not in features folder'>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DisplayStandardEquipment equipment={null} />
          </Grid>
          <Grid item xs={12}>
            <DisplayStandardEquipment equipment={lessThanMaxCountEquipment} />
          </Grid>
          <Grid item xs={12}>
            <DisplayStandardEquipment equipment={standardEquipmentMock} />
          </Grid>
        </Grid>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
