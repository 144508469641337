import { Box, SxProps, Tab, Tabs, tabsClasses } from '@mui/material'
import { FC, ReactNode, SyntheticEvent } from 'react'
import { COLORS } from '../../types/colors'
import { For, Show } from '../JSXExtensions'

type TabContainerProps = {
  tabNames: string[] | readonly string[]
  tabContents: ReactNode[]
  currentTabID: number
  onChange: (event: SyntheticEvent, newValue: number) => void
  trailingContent?: JSX.Element
  tabContentsSx?: SxProps
}

export const TabContainer: FC<TabContainerProps> = ({
  tabNames,
  tabContents,
  currentTabID,
  onChange,
  trailingContent,
  tabContentsSx
}) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Box>
        <Tabs
          variant='scrollable'
          value={currentTabID}
          onChange={onChange}
          aria-label='tabs'
          textColor='inherit'
          sx={{
            '& .MuiTabs-scroller': {
              borderBottom: 2,
              borderColor: COLORS.border_gray
            },
            [`& .${tabsClasses.scrollButtons}`]: {
              '&.Mui-disabled': {
                opacity: 0.3
              }
            }
          }}
          TabIndicatorProps={{
            sx: {
              borderColor: COLORS.border_gray,
              backgroundColor: '#FACC34'
            }
          }}
        >
          {tabNames.map((tab, index) => (
            <Tab
              key={index}
              label={tab}
              id={`${tab}-${index}`}
              aria-controls={`${tab}-${index}`}
            />
          ))}
          {trailingContent}
        </Tabs>
      </Box>
      <For of={tabContents}>
        {(panel, index) => (
          <Box
            key={index}
            role='tabpanel'
            hidden={currentTabID !== index}
            id={`${tabNames[index]}-${index}`}
            aria-labelledby={`${tabNames[index]}-${index}`}
          >
            <Show when={currentTabID === index}>
              <Box sx={{ paddingY: 3, ...tabContentsSx }}>{panel}</Box>
            </Show>
          </Box>
        )}
      </For>
    </Box>
  )
}
