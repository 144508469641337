import { useCallback, useState } from 'react'
import { objectKeys } from '../objectKeys'

// TODO: specify form values type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ComputedFormValues = Record<string, any>

export type CombinedStorage<Key extends string, FormValues> = {
  values: Record<Key, FormValues> | ComputedFormValues
  updateValues: (name: Key, values: FormValues) => void
  errors: Partial<Record<Key, Record<keyof FormValues, string>>>
  updateErrors: (
    name: Key,
    errors: Partial<Record<keyof FormValues, string>>
  ) => void
}

export const useCombinedFormValues = <T extends ComputedFormValues>(
  formObjects: T
) => {
  type FormName = keyof T
  type FormValues = T[FormName]

  const [values, setValues] = useState(
    objectKeys(formObjects).reduce((acc, name) => {
      const formValues = formObjects[name]

      return { ...acc, [name]: formValues }
    }, {} as Record<FormName, ComputedFormValues>)
  )

  const [errors, setErrors] = useState<
    Partial<Record<FormName, Record<string, string>>>
  >({})

  const updateValues = useCallback(
    (name: FormName, newValues: FormValues) => {
      setValues((prevStore) => ({
        ...prevStore,
        [name]: { ...prevStore[name], ...newValues }
      }))
    },
    [setValues]
  )

  const updateErrors = useCallback(
    (name: FormName, newErrors: Partial<Record<string, string>>) => {
      setErrors((prevStore) => {
        const prevErrors = prevStore[name] || {}

        return {
          ...prevStore,
          [name]: { ...prevErrors, ...newErrors }
        }
      })
    },
    [setErrors]
  )

  return { values, updateValues, errors, updateErrors }
}
