import { Box, Typography } from '@mui/material'
import { FC, useCallback, useMemo } from 'react'

import { LocationIcon } from '../../../../assets/icons/LocationIcon'
import { OfficeIcon } from '../../../../assets/icons/OfficeIcon'
import { Avatar } from '../../../../atoms/Avatar'
import { DashboardItem } from '../../../../atoms/DashboardItem'
import { EllipsisText } from '../../../../atoms/EllipsisText'
import { SettingButton } from '../../../../atoms/SettingButton'
import { routes } from '../../../../routes'
import { useNavigate, useUserInfo } from '../../../../utils/hooks'
import { returnNoCachedSrc } from '../../../../utils/returnNoCachedSrc'
import {
  ImgBlock,
  LocationContainer,
  NameContainer,
  PersonalDetails,
  SettingButtonContainer,
  UserInfoCardContainer
} from './UserCard.styles'

export const UserInfoCard: FC = () => {
  const navigate = useNavigate()
  const {
    addresses,
    avatar,
    dealershipName,
    firstName,
    isDealer,
    lastName,
  } = useUserInfo()

  const handleSettingsClick = useCallback(() => {
    navigate(routes.personalSettings)
  }, [navigate])

  const fullName = useMemo(
    () => `${firstName} ${lastName}`,
    [firstName, lastName]
  )

  const locationIcon = useMemo(
    () => (isDealer ? <OfficeIcon /> : <LocationIcon />),
    [isDealer]
  )

  const locationInfo = useMemo(() => {
    if (isDealer) {
      return dealershipName
    }
    if (addresses.length) {
      const { state, city, zipCode } = addresses[0]
      return `${city}, ${state} ${zipCode}`
    }
    return ''
  }, [isDealer, addresses])

  return (
    <DashboardItem>
      <UserInfoCardContainer>
        <ImgBlock>
          <Avatar
            avatar={returnNoCachedSrc(avatar)}
            badge={
              <SettingButtonContainer onClick={handleSettingsClick}>
                <SettingButton />
              </SettingButtonContainer>
            }
          />
        </ImgBlock>
        <PersonalDetails>
          <NameContainer>
            <EllipsisText tooltipText={fullName}>{fullName}</EllipsisText>
          </NameContainer>
          <LocationContainer>
            <Box width='25px' mr='10px'>
              {locationIcon}
            </Box>
            <Typography variant='main' sx={{ overflow: 'hidden' }}>
              <EllipsisText tooltipText={locationInfo}>
                {locationInfo}
              </EllipsisText>
            </Typography>
          </LocationContainer>
        </PersonalDetails>
      </UserInfoCardContainer>
    </DashboardItem>
  )
}
