import { FC, Suspense, lazy } from 'react'

const LazyIcon = lazy(() => import('./Icon'))

export const DefaultVehicle: FC<Parameters<typeof LazyIcon>[0]> = (props) => {
  return (
    <Suspense>
      <LazyIcon {...props} />
    </Suspense>
  )
}
