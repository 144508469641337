import { ApiOptions } from '../types'
import { Utils } from '../utils'
import { noop } from '../../utils'
import { config } from '../../constants/config'
import { ResponseStatusMap } from '../../types/status'
import {
  ErrorResponse,
  MessageResponse,
  SuccessResponse
} from '../private.types'
import {
  RawCreateMessageResponse,
  NormalizedCreateMessageResponse,
  formatCreateMessageResponse
} from './types/createMessage'

import {
  RawReadChatMessagesResponse,
  NormalizedReadChatMessagesResponse,
  normalizeReadChatMessages
} from './types/readChatMessages'

import {
  RawReadChatsResponse,
  NormalizedReadChatsResponse,
  normalizeReadChats
} from './types/readChats'

import {
  RawUpdateChatsHasReadResponse,
  NormalizedUpdateChatsHasReadResponse
} from './types/updateChatsHasRead'

export class ChatApi {
  readonly utils: Utils
  protected readonly operations = {
    createChatMessage: 'createChatMessage',
    readChatMessages: 'readChatMessages',
    readChats: 'readChats',
    updateChatsHasRead: 'updateChatsHasRead'
  }

  constructor(options: ApiOptions = {}) {
    const {
      endpoint = config.CHATS_API_ENDPOINT,
      token = config.AWS_API_KEY,
      session,
      logoutCallback = noop
    } = options

    this.utils = new Utils({
      endpoint,
      token,
      session,
      logoutCallback
    })
  }

  async createChatMessage({
    transactionId,
    message = '',
    file
  }: {
    transactionId: string
    message?: string
    file?: File
  }): Promise<
    | SuccessResponse<{ payload: NormalizedCreateMessageResponse }>
    | ErrorResponse<MessageResponse>
  > {
    const record = {
      transaction_id: transactionId,
      message,
      ...(file && { file_name: file.name, content_type: file.type })
    }

    try {
      const response = await this.utils.makeJSONRequest<
        RawCreateMessageResponse,
        ErrorResponse<MessageResponse>
      >(this.operations.createChatMessage, record)

      if (response.type === ResponseStatusMap.Success) {
        return {
          status: ResponseStatusMap.Success,
          payload: formatCreateMessageResponse(response.payload)
        }
      }

      return {
        status: ResponseStatusMap.Error,
        message: response.payload.message
      }
    } catch {
      return {
        status: ResponseStatusMap.Error,
        message: 'Something went wrong'
      }
    }
  }

  async uploadFile({
    url,
    file
  }: {
    url: string
    file: File
  }): Promise<
    SuccessResponse<MessageResponse> | ErrorResponse<MessageResponse>
  > {
    try {
      const response = await this.utils.sendFileRequest({ url, file })

      return {
        status: response.type,
        message: response.payload.message
      }
    } catch {
      return {
        status: ResponseStatusMap.Error,
        message: 'Something went wrong'
      }
    }
  }

  async readChatMessages({
    user,
    transactionId
  }: {
    user: string
    transactionId: string
  }): Promise<
    | SuccessResponse<{ payload: NormalizedReadChatMessagesResponse }>
    | ErrorResponse<MessageResponse>
  > {
    const dataToSend = {
      transaction_id: transactionId,
      user
    }

    const response = await this.utils.makeJSONRequest<
      RawReadChatMessagesResponse,
      ErrorResponse<MessageResponse>
    >(this.operations.readChatMessages, dataToSend)

    if (response.type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        payload: normalizeReadChatMessages(response.payload)
      }
    }

    return {
      status: ResponseStatusMap.Error,
      message: response.payload.message
    }
  }

  async readChats(params: {
    user: string
  }): Promise<
    | SuccessResponse<{ payload: NormalizedReadChatsResponse }>
    | ErrorResponse<MessageResponse>
  > {
    const response = await this.utils.makeJSONRequest<
      RawReadChatsResponse,
      ErrorResponse<MessageResponse>
    >(this.operations.readChats, params)

    if (response.type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        payload: normalizeReadChats(response.payload)
      }
    }

    return {
      status: ResponseStatusMap.Error,
      message: response.payload.message
    }
  }

  async updateChatsHasRead(params: {
    ids: string[]
    hasRead: boolean
  }): Promise<
    | SuccessResponse<{ payload: NormalizedUpdateChatsHasReadResponse }>
    | ErrorResponse<MessageResponse>
  > {
    const dataTosend = {
      ids: params.ids,
      has_read: params.hasRead
    }

    const response = await this.utils.makeJSONRequest<
      RawUpdateChatsHasReadResponse,
      ErrorResponse<MessageResponse>
    >(this.operations.updateChatsHasRead, dataTosend)

    if (response.type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        payload: response.payload
      }
    }

    return {
      status: ResponseStatusMap.Error,
      message: response.payload.message
    }
  }
}
