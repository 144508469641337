import { Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { NoVehiclesImage } from '../../assets/imgs/NoVehicles'

interface DefaultEmptyStateProps {
  image?: JSX.Element
  imageHeight?: string
  imageWidth?: string
  text?: string
}

export const DefaultEmptyState: FC<DefaultEmptyStateProps> = ({
  imageHeight = '100%',
  imageWidth = '100%',
  text = 'There are no vehicles yet.',
  image = <NoVehiclesImage height={imageHeight} width={imageWidth} />
}) => {
  return (
    <Grid
      container
      alignContent='start'
      gap={2}
      justifyContent='center'
    >
      <Grid item maxHeight='50%' textAlign='center' xs={12}>
        {image}
      </Grid>
      <Grid item xs={12}>
        <Typography component='h3' variant='h6' sx={{ textAlign: 'center' }}>
          {text}
        </Typography>
      </Grid>
    </Grid>
  )
}
