import { styled } from '@mui/system'

export const LocationContainer = styled('div')`
  display: flex;

  & > div {
    width: 100%;
    margin-right: 6px;
  }

  & > div:last-child {
    margin-right: 0;
  }
`
