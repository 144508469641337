import { FC, useLayoutEffect, useCallback, useMemo } from 'react'
import { useParams, useNavigate, generatePath } from 'react-router-dom'
import { contractInProgressStatuses } from '../Contract/hooks/useContractPageInfo'
import { useReadTransaction } from '../../utils/hooks'
import { routes } from '../../routes'
import { Show } from '../../atoms/JSXExtensions'
import { PageContainer } from '../../layout/PageContainer'
import { OfferDetailsPage } from '.'

export const OfferPageRouteResolver: FC = () => {
  const { transactionId = '' } = useParams()
  const navigate = useNavigate()
  const { isLoading, data: transaction } = useReadTransaction({ transactionId })

  const navigateToDeal = useCallback(() => {
    const path = generatePath(routes.contracts.transaction, {
      transactionId
    })

    navigate(path)
  }, [transactionId])

  const isContractInProgressDeal = useMemo(
    () =>
      transaction?.status &&
      contractInProgressStatuses.includes(transaction.status),
    [transaction?.status]
  )

  useLayoutEffect(() => {
    if (isContractInProgressDeal) {
      navigateToDeal()
    }
  }, [isContractInProgressDeal, navigateToDeal])

  return (
    <PageContainer isLoading={isLoading}>
      <Show when={transaction}>
        {(deal) => <OfferDetailsPage transaction={deal} />}
      </Show>
    </PageContainer>
  )
}
