import { DependentField } from '../../../../atoms/DependentField'
import {
  MaskedInput,
  MaskedInputFieldProps
} from '../../../../atoms/MaskedInput'
import { BUYER_FIELDS_NAMES, BuyerFieldsTypes } from './types'

export const DependentZipCodeField = DependentField<
  MaskedInputFieldProps,
  BuyerFieldsTypes
>(MaskedInput, {
  deps: [BUYER_FIELDS_NAMES.ZIP_CODE]
})
