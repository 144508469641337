import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CloseIcon from '@mui/icons-material/Close'
import { Grid, Link, Typography } from '@mui/material'
import { FC, MouseEventHandler, ReactElement, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { RedButton } from '../../../../../../atoms/Button'
import { DisplayColors } from '../../../../../../atoms/DisplayColors'
import { EllipsisText } from '../../../../../../atoms/EllipsisText'
import { Show } from '../../../../../../atoms/JSXExtensions'

type RequestHeaderLinkProps = {
  navigateToNewTab: boolean
  navigateTo: string
  children: ReactElement
}

export const RequestHeaderLink: FC<RequestHeaderLinkProps> = (props) => {
  const { navigateToNewTab, navigateTo, children } = props
  return navigateTo ? (
    <Link
      to={navigateTo}
      component={NavLink}
      target={navigateToNewTab ? '_blank' : '_self'}
      underline='none'
      color='inherit'
      width='100%'
      height='100%'
    >
      {children}
    </Link>
  ) : (
    children
  )
}

type RequestHeaderDesktopLayoutProps = {
  cancelText?: string
  disableCancel?: boolean
  expirationDate: string
  exteriorColors: string[]
  interiorColors: string[]
  navigateTo: string
  navigateToNewTab: boolean
  onCancelRequest: MouseEventHandler<HTMLButtonElement>
  title: string
  tradeIn: string
  years: string
}

export const RequestHeaderDesktopLayout: FC<RequestHeaderDesktopLayoutProps> = ({
  cancelText = 'Cancel Request',
  disableCancel = false,
  expirationDate,
  exteriorColors,
  interiorColors,
  navigateTo,
  navigateToNewTab,
  onCancelRequest,
  title,
  tradeIn,
  years,
}) => {
  const tradeInStyles = useMemo(() => {
    if (tradeIn !== 'Not included') {
      return {}
    }

    return {
      fontStyle: 'italic'
    }
  }, [tradeIn])

  return (
    <Grid container alignItems='center'>
      <Grid item xs={10.5}>
        <RequestHeaderLink
          navigateToNewTab={navigateToNewTab}
          navigateTo={navigateTo}
        >
          <Grid container item wrap='nowrap' alignItems='center' spacing={1}>
            <Grid item xs={2.5}>
              <Typography variant='emphasized'>
                <EllipsisText tooltipText={title}>{title}</EllipsisText>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant='main'>{years}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant='main'>
                <DisplayColors colors={exteriorColors} />/{' '}
                <DisplayColors colors={interiorColors} />
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant='main' sx={tradeInStyles}>
                <EllipsisText tooltipText={tradeIn}>{tradeIn}</EllipsisText>
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={2.5}
              spacing={1}
              alignItems='center'
              wrap='nowrap'
            >
              <Grid item mt={1}>
                <AccessTimeIcon />
              </Grid>
              <Grid item>
                <Typography variant='main'>{expirationDate}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </RequestHeaderLink>
      </Grid>
      <Show when={!disableCancel}>
        <Grid container item xs={1.5}>
          <RedButton onClick={onCancelRequest} startIcon={<CloseIcon />}>
            {cancelText}
          </RedButton>
        </Grid>
      </Show>
    </Grid>
  )
}
