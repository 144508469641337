import { Grid, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { useCallback } from 'react'
import { PaymentApi } from '../../../../api/externalCarSnoopApi/payment/types'
import { AgreeButton } from '../../../../atoms/Button'
import { Show } from '../../../../atoms/JSXExtensions'
import { Loader } from '../../../../atoms/Loader'
import { TextInputFieldNew } from '../../../../atoms/TextInputFieldNew'
import { PaymentRedirect } from '../../../../features/PaymentRedirect'
import { useRestoreData } from '../../../../utils/hooks'
import { useStripePaymentRedirect } from '../../../Contract/hooks/usePaymentRedirect/useStripePaymentRedirect'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

interface FormValues {
  test: string
}

const mockedTransactionId: PaymentApi.CreatePaymentCheckout.Params['transactionId'] =
  '00db8ed2-8117-49ab-807d-9b9d1db3a4f1'

export const PaymentRedirectExample = () => {
  const {
    getPaymentRedirectUrl,
    isPaymentOpen,
    redirectToPayment,
    handleRedirectFail
  } = useStripePaymentRedirect(
    () => 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
    mockedTransactionId
  )

  const { restoredData, isRestoring, storeData } =
    useRestoreData<FormValues>('testForm')

  const getRedirectUrl = useCallback(() => {
    const itemsForPaying = [
      {
        amount: 500.0,
        productName: 'Deposit',
        productDescription: 'Initial Deposit to Hold the Vehicle'
      }
    ]

    return getPaymentRedirectUrl(itemsForPaying)
  }, [getPaymentRedirectUrl])

  const handleSubmit = useCallback(
    (values: FormValues) => {
      storeData(values)
      redirectToPayment()
    },
    [redirectToPayment]
  )

  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <Grid container spacing={2}>
          <Show when={!isRestoring} fallback={<Loader />}>
            <>
              <Grid item>
                <Formik<FormValues>
                  initialValues={{
                    test: restoredData?.test || 'some initial value'
                  }}
                  onSubmit={handleSubmit}
                  validateOnChange={false}
                >
                  {() => (
                    <Form>
                      <Grid
                        container
                        item
                        columnSpacing={2}
                        alignItems='center'
                        flexWrap='nowrap'
                      >
                        <Grid item>
                          <TextInputFieldNew name='test' label='test' />
                        </Grid>
                      </Grid>
                      <Grid item>
                        <AgreeButton type='submit'>PAY!</AgreeButton>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Grid>
              <Grid item>
                <Typography variant='h6'>Go to pay</Typography>
              </Grid>
              <PaymentRedirect
                open={isPaymentOpen}
                onRedirectFail={handleRedirectFail}
                getPaymentRedirectUrl={getRedirectUrl}
              />
            </>
          </Show>
        </Grid>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
