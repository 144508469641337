import { Grid } from '@mui/material'
import { FC } from 'react'
import { Condition } from '../../atoms/Condition'
import { PageContainer } from '../../layout/PageContainer'
import { useUserInfo } from '../../utils/hooks'
import { BuyerHomePage } from './Buyer'
import { Dashboard } from './Dashboard'
import { useUserDashboardStatistic } from './Dashboard/StatisticCard/hooks/useUserDashboardStatistic'
import { DealerHomePage } from './Dealer'

export const MainPage: FC = () => {
  const { isDealer } = useUserInfo()
  const { getAccountStatistics, schema } = useUserDashboardStatistic()

  return (
    <PageContainer>
      <Grid container spacing={2}>
        <Dashboard schema={schema} />
        <Condition
          condition={isDealer}
          trueContent={
            <DealerHomePage getAccountStatistics={getAccountStatistics} />
          }
          falseContent={<BuyerHomePage />}
        />
      </Grid>
    </PageContainer>
  )
}
