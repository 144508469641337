import { Box, Grid, styled } from '@mui/material'
import { COLORS } from '../../types/colors'

export const AutoselectColorOptionContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'selected'
}) <{ selected: boolean }>`
  align-items: center;
  padding: 12px 22px 12px 12px;
  background-color: ${({ selected }) =>
    selected ? COLORS.hover_background_light_yellow : ''};

  &:hover {
    background-color: ${({ selected }) =>
    selected
      ? COLORS.hover_background_light_yellow
      : COLORS.secondary_background};
  }
`

export const SwatchContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'swatch'
}) <{ swatch?: string }>`
  width: 24px;
  height: 24px;
  background-color: ${({ swatch }) => swatch};
  border-radius: 3px;
`
