import { ReferenceModel } from '../../../../types/ReferenceModel'

export const getOptionsBetween = <T extends ReferenceModel>(
  from: T,
  to: T,
  options: T[],
  compareFrom: (option: T, value: T) => boolean,
  compareTo: (option: T, value: T) => boolean
): T[] => {
  return options.filter((option) => {
    return compareFrom(option, from) && compareTo(option, to)
  })
}
