import { Grid, Typography } from '@mui/material'
import { FC, useMemo } from 'react'

import { Show } from '../../../../../../atoms/JSXExtensions'
import { BORDERS } from '../../../../../../types/colors'

export interface DetailsItemProps {
  title: string
  value: string
  withoutDivider?: boolean
  renderValue?: (value: DetailsItemProps['value']) => JSX.Element
  fallbackText?: string
}

export const DetailsItem: FC<DetailsItemProps> = (props) => {
  const { title, value, fallbackText = '', withoutDivider, renderValue } = props

  const hasError = !value

  const valueTypographyProps: {
    props: Parameters<typeof Typography>[0]
    children: string
  } = useMemo(() => {
    return {
      children: hasError ? fallbackText : value,
      props: {
        variant: hasError ? 'details' : 'label1',
        fontStyle: hasError ? 'italic' : 'inherit'
      }
    }
  }, [value, fallbackText, hasError])

  return (
    <Grid item>
      <Grid
        container
        item
        justifyContent='space-between'
        alignItems='flex-end'
        sx={{
          pb: (!withoutDivider && '20px') || '',
          borderBottom: (!withoutDivider && BORDERS.SM_GRAY) || ''
        }}
      >
        <Grid item>
          <Typography variant='subtitle2' textTransform='uppercase'>
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <Show
            when={renderValue}
            fallback={
              <Typography
                {...valueTypographyProps.props}
                sx={{ wordBreak: 'break-word' }}
              >
                {valueTypographyProps.children}
              </Typography>
            }
          >
            {(render) => render(value)}
          </Show>
        </Grid>
      </Grid>
    </Grid>
  )
}
