import { SSNInformationCard } from '../../../PersonalDetails/SSNInformationCard'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const EmptySSNInformationCardExample = () => {
  return (
    <ComponentContainer>
      <ComponentWithPaperBackground title='Not in molecules folder (in routes/PersonalDetails)'>
        <SSNInformationCard />
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}

export const FilledSSNInformationCardExample = () => {
  return (
    <ComponentContainer>
      <ComponentWithPaperBackground title='Not in molecules folder (in routes/PersonalDetails)'>
        <SSNInformationCard ssn='1111113345' />
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
