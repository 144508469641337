import { SxProps } from '@mui/material'
import { BORDERS, SHADOWS } from '../../../../types/colors'

export const currentSubscriptionStyles: SxProps = {
  width: '300px',
  height: '150px',
  p: 2,
  border: BORDERS.SM_GRAY,
  borderRadius: '11px',
  boxShadow: SHADOWS.hover,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
}
