import { sortArrPropAlphabetically } from './sortArrPropAlphabetically'

export const groupArrPropAlphabetically = <T>(
  arr: T[] = [],
  comparators: ((_: T) => boolean)[],
  sortByPropName: keyof T
): T[] => {
  if (arr.length < 2) {
    return arr
  }

  const groupedArray: T[][] = Array.from(
    { length: comparators.length },
    () => []
  )

  arr.forEach((item) => {
    comparators.forEach((comparator, index) => {
      if (comparator(item)) {
        groupedArray[index].push(item)
      }
    })
  })

  const sortedArray: T[][] = groupedArray.map((subArray) =>
    sortArrPropAlphabetically(subArray, sortByPropName)
  )

  return sortedArray.reduce((acc, item) => acc.concat(item), [])
}
