import { useCallback } from 'react'
import { useApiContext } from './useApiContext'
import { useMakeService } from './useMakeService'

export const useCreateShipment = () => {
  const { externalCarSnoopApi } = useApiContext()

  const createShipment = useCallback(async (id: string) => {
    const createShipmentResult =
      await externalCarSnoopApi.shipments.createShipment({
        transactionId: id
      })

    return createShipmentResult
  }, [])

  const [createShipmentService, createShipmentServiceObservable] =
    useMakeService(createShipment)

  return { createShipmentServiceObservable, createShipmentService }
}
