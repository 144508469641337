import { FC, useMemo } from 'react'
import { VehicleDetails } from './components/VehicleDetails'
import { VehicleComponent } from '../../../../../../atoms/VehicleComponent'
import { useDealsRoute } from '../../../../../../utils/hooks'
import { DEAL_FLOW } from '../../../../../../types/enums/dealFlow'
import { formatPrice } from '../../../../../../utils'
import { ReadRequestsResponse } from '../../../../../../api/carSnoopApi/requests/types'
import { AttentionIcon } from '../../../../../../atoms/AttentionIcon'
import { VEHICLE_DOESNT_HAVE_VIN_WARNING } from '../../../../../../constants/messages'

export type DealItemProps =
  ReadRequestsResponse.Normilized.ReadRequestsResponse['transactions'][number]

export const DealVehicleItem: FC<DealItemProps> = (props) => {
  const { vehicle, id } = props
  const {
    make,
    model,
    retailValue,
    shippingCost,
    mileage,
    year,
    trim,
    documentContent = '',
    vin
  } = vehicle

  const imageSource = documentContent === 'None' ? '' : documentContent

  const { getPathToDeal } = useDealsRoute()
  const badges = useMemo(() => {
    const hasVin = !!vin

    const defaultBadges = [
      {
        position: 'bottom-left',
        label: retailValue ? formatPrice(retailValue) : 'Contact CarSnoop'
      }
    ]

    return hasVin
      ? defaultBadges
      : [
          ...defaultBadges,
          {
            position: 'bottom-right',
            label: 'vinAttention',
            component: <AttentionIcon text={VEHICLE_DOESNT_HAVE_VIN_WARNING} />
          }
        ]
  }, [retailValue, vin])

  const details = useMemo(() => {
    return <VehicleDetails mileage={mileage} vin={vin} />
  }, [mileage, shippingCost])

  const navigationURL = useMemo(() => {
    return getPathToDeal({ transactionId: id, flow: DEAL_FLOW.BUY })
  }, [id, getPathToDeal])

  return (
    <VehicleComponent
      disabled={false}
      id={id}
      year={year}
      make={make}
      model={model}
      trim={trim}
      imageSource={imageSource}
      details={details}
      badges={badges}
      navigateToNewTab
      navigationURL={navigationURL}
    />
  )
}
