import { Avatar, Grid, Typography } from '@mui/material'
import { FC, PropsWithChildren } from 'react'
import { UnknownUserIcon } from '../../../../assets/icons/UnknownUser'
import { Show } from '../../../../atoms/JSXExtensions'
import { COLORS } from '../../../../types/colors'
import { useMessageSendTime } from '../../../../utils/hooks/chat'
import { MessageContainer } from './ChatMessageContainer.styles'
import { IChatMessageContainerProps } from './ChatMessageContainer.types'

export const ChatMessageContainer: FC<
  PropsWithChildren<IChatMessageContainerProps>
> = ({
  id,
  avatar = '',
  senderName,
  isSender,
  timestamp,
  isMinimized = false,
  children
}) => {
    const sendTime = useMessageSendTime(timestamp)

    return (
      <Grid
        container
        flexWrap='nowrap'
        spacing='12px'
        flexDirection={isSender ? 'row-reverse' : 'row'}
        // TODO: Use Intersection Observer as effect in [chat->controller->updater] to set status of message as read.
        data-message-id={id}
      >
        <Grid item>
          <Avatar
            sx={{
              width: isMinimized ? 40 : 56,
              height: isMinimized ? 40 : 56
            }}
            src={avatar}
          >
            <UnknownUserIcon />
          </Avatar>
        </Grid>
        <Grid item container flexDirection='column'>
          <Grid
            item
            container
            mb='6px'
            spacing='6px'
            flexDirection={isSender ? 'row-reverse' : 'row'}
          >
            <Grid item display='flex'>
              <Typography variant='label1'>{senderName}</Typography>
            </Grid>
            <Show when={isMinimized}>
              <Grid item>
                <Typography variant='subtitle1_grey' noWrap>
                  {sendTime}
                </Typography>
              </Grid>
            </Show>
          </Grid>
          <Grid
            item
            container
            alignItems='center'
            flexWrap='nowrap'
            flexDirection={isSender ? 'row-reverse' : 'row'}
          >
            <Grid item mr={isSender ? '' : '30px'} ml={isSender ? '30px' : ''}>
              <MessageContainer
                backgroundColor={
                  isSender
                    ? COLORS.hover_background_blue
                    : COLORS.secondary_background
                }
              >
                {children}
              </MessageContainer>
            </Grid>
            <Show when={!isMinimized}>
              <Grid item>
                <Typography variant='subtitle1_grey' noWrap>
                  {sendTime}
                </Typography>
              </Grid>
            </Show>
          </Grid>
        </Grid>
      </Grid>
    )
  }
