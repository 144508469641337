import { RawReadChatsResponse } from './raw'
import { NormalizedReadChatsResponse } from './normalized'
import { ChatListItemType } from '../../../../features/MiniChat/model/types'
import { TEXT_MESSAGE_TYPE } from '../../../../constants/applicationConstants'

export const normalizeReadChats = ({
  data: { chats }
}: RawReadChatsResponse): NormalizedReadChatsResponse => {
  return chats.map((chat) => {
    const {
      unread,
      inventory,
      transaction_id: transactionId,
      transaction_status: transactionStatus,
      last_message: m
    } = chat

    if (m.media_id !== null) {
      return {
        unread,
        inventory,
        transactionId,
        transactionStatus,
        lastMessage: {
          id: m.id,
          fromUser: m.from_user,
          toUser: m.to_user,
          message: m.message,
          sentOn: m.sent_on,
          hasRead: m.has_read,
          mediaId: m.media_id,
          contentType: m.content_type,
          fileName: m.file_name,
          mediaUrl: m.media_id
        }
      }
    }
    return {
      unread,
      inventory,
      transactionId,
      transactionStatus,
      lastMessage: {
        id: m.id,
        fromUser: m.from_user,
        toUser: m.to_user,
        message: m.message,
        sentOn: m.sent_on,
        hasRead: m.has_read,
        mediaId: m.media_id,
        contentType: m.content_type,
        fileName: m.file_name,
        mediaUrl: m.media_id
      }
    }
  })
}

export const formatReadChats = (
  chats: NormalizedReadChatsResponse,
  currentUserId: string
): ChatListItemType[] => {
  return chats.map<ChatListItemType>((chat) => {
    const { transactionId, transactionStatus, unread, inventory, lastMessage } =
      chat

    const isSender = currentUserId === lastMessage.fromUser

    if (lastMessage.mediaId !== null) {
      const {
        id,
        fromUser,
        message,
        sentOn,
        hasRead,
        mediaId,
        contentType,
        fileName,
        mediaUrl
      } = lastMessage

      return {
        transactionId,
        transactionStatus,
        unreadCount: unread,
        vehicle: inventory,
        lastMessage: {
          id,
          transactionId,
          senderName: fromUser,
          isSender,
          hasRead,
          timestamp: sentOn,
          message,
          type: contentType,
          contentId: mediaId,
          contentName: fileName,
          contentPreviewURL: mediaUrl
        }
      }
    }
    const { id, fromUser, message, sentOn, hasRead } = lastMessage

    return {
      transactionId,
      transactionStatus,
      unreadCount: unread,
      vehicle: inventory,
      lastMessage: {
        id,
        transactionId,
        senderName: fromUser,
        isSender,
        hasRead,
        timestamp: sentOn,
        message,
        type: TEXT_MESSAGE_TYPE
      }
    }
  })
}
