import { VehicleImage } from '../../api/carSnoopApi/vehicles'

export const integrateServiceImageBlock = (
  array: VehicleImage[],
  step: number,
  allowEdit: boolean
): VehicleImage[] => {
  if (!array.length) {
    return []
  }

  if (allowEdit) {
    return [
      ...array,
      {
        id: `new`,
        url: '',
        type: ''
      }
    ]
  }

  const occupiedSlotsOnLastStep = array.length % step && allowEdit

  if (!occupiedSlotsOnLastStep) return array

  return [
    ...array,
    ...Array.from({ length: step - occupiedSlotsOnLastStep }, (_, index) => ({
      id: `empty-${index}`,
      url: '',
      type: ''
    }))
  ]
}
