export const returnStringOrPropByName = <T extends object>(
  value: T | string | null,
  propName: keyof T
): string => {
  if (typeof value === 'string') {
    return value
  }

  return value ? String(value[propName]) : ''
}
