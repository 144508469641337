import { FC, useCallback, useMemo } from 'react'
import { Form, Formik } from 'formik'
import { Grid, Typography } from '@mui/material'

import { TextInputFieldNew } from '../../../../../atoms/TextInputFieldNew'
import { useCheckZipCode } from '../../../../../utils/hooks/useCheckZipCode'
import { DependentFieldHocEffect } from '../../../../../atoms/DependentField'
import { MaskedInputFieldProps } from '../../../../../atoms/MaskedInput'
import { ResponseStatusMap } from '../../../../../types/status'
import { zipCodeMask } from '../../../../../constants/regexp'

import { DEFAULT_VALUES } from './constants'
import { getValidationSchema } from './validationSchema'
import { FormFields, FIELDS_NAMES, FIELDS_ERRORS, FIELDS_LABELS } from './types'
import { DependentZipCodeField } from './DependentField'
import { PhoneInputFieldNew } from '../../../../../atoms/PhoneInputField'
import { NotesField } from '../../../../../atoms/NotesField'
import { Show } from '../../../../../atoms/JSXExtensions'
import { noop } from '../../../../../utils'
import { isRequiredFormField } from '../../../../../utils/validators/isRequiredFormField'
import { RequiredLabel } from '../../../../../atoms/RequiredText'

export interface AddressEditFormProps {
  addressType: 'Pickup' | 'Drop-Off'
  initialValues?: Partial<FormFields>
  setIsValid: (isValid: boolean) => void
  setFormValues: (values: FormFields) => void
}

export const AddressEditForm: FC<AddressEditFormProps> = (props) => {
  const { addressType, initialValues, setIsValid, setFormValues } = props
  const { checkZipCode, checkZipCodeObservable } = useCheckZipCode()

  const zipCodeFieldEffect: DependentFieldHocEffect<
    MaskedInputFieldProps,
    FormFields
  > = useCallback(async (_, ctx) => {
    const { values, setFieldValue, validateForm, setFieldError } = ctx
    const { zipCode: zipCodeValue } = values
    setFieldValue(FIELDS_NAMES.CITY, '')
    setFieldValue(FIELDS_NAMES.STATE, '')

    if (zipCodeValue) {
      const response = await checkZipCode(zipCodeValue)

      if (response.status === ResponseStatusMap.Success) {
        setFieldValue(FIELDS_NAMES.CITY, response.city)
        setFieldValue(FIELDS_NAMES.STATE, response.state)
      } else {
        validateForm().then(() => {
          setFieldError(FIELDS_NAMES.ZIP_CODE, FIELDS_ERRORS.ZIP_CODE)
        })
      }
    }
  }, [])

  const notesLabel = `${addressType} Notes`
  const validationSchema = useMemo(
    () => getValidationSchema(!!initialValues?.addressName),
    [initialValues?.addressName]
  )
  const isRequiredField = isRequiredFormField(validationSchema)

  return (
    <Formik<FormFields>
      initialValues={{ ...DEFAULT_VALUES, ...initialValues }}
      validationSchema={validationSchema}
      onSubmit={noop}
      validateOnMount
      validateOnChange={false}
    >
      {(formik) => {
        setIsValid(
          formik.isValid && formik.dirty && !checkZipCodeObservable.isLoading
        )
        setFormValues(formik.values)

        return (
          <Form style={{ width: '100%' }}>
            {/* Form */}
            <Grid
              container
              item
              flexDirection='column'
              wrap='nowrap'
              spacing={2}
              sx={{
                // Hardcoded according the design
                maxHeight: '616px',
                p: 3,
                overflowY: 'scroll'
              }}
            >
              <Grid item>
                <RequiredLabel marginTop={0} />
              </Grid>
              <Show when={!!initialValues?.addressName}>
                <Grid item>
                  <TextInputFieldNew
                    name={FIELDS_NAMES.ADDRESS_NAME}
                    label={FIELDS_LABELS.ADDRESS_NAME}
                    required={isRequiredField}
                  />
                </Grid>
              </Show>

              <Grid item>
                <TextInputFieldNew
                  name={FIELDS_NAMES.STREET}
                  label={FIELDS_LABELS.STREET}
                  required={isRequiredField}
                />
              </Grid>

              <Grid item>
                <DependentZipCodeField
                  name={FIELDS_NAMES.ZIP_CODE}
                  label={FIELDS_LABELS.ZIP_CODE}
                  onDoneEffect={zipCodeFieldEffect}
                  mask={zipCodeMask}
                  required={isRequiredField}
                />
              </Grid>

              <Grid container item spacing={2}>
                <Grid item xs={6}>
                  <TextInputFieldNew
                    name={FIELDS_NAMES.CITY}
                    label={FIELDS_LABELS.CITY}
                    disabled
                    isLoading={checkZipCodeObservable.isLoading}
                    required={isRequiredField}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInputFieldNew
                    name={FIELDS_NAMES.STATE}
                    label={FIELDS_LABELS.STATE}
                    disabled
                    isLoading={checkZipCodeObservable.isLoading}
                    required={isRequiredField}
                  />
                </Grid>
              </Grid>

              <Grid item>
                <TextInputFieldNew
                  name={FIELDS_NAMES.CONTACT_NAME}
                  label={FIELDS_LABELS.CONTACT_NAME}
                  required={isRequiredField}
                />
              </Grid>

              <Grid item>
                <PhoneInputFieldNew
                  name={FIELDS_NAMES.CONTACT_NUMBER}
                  label={FIELDS_LABELS.CONTACT_NUMBER}
                  required={isRequiredField}
                />
              </Grid>

              <Grid item>
                <TextInputFieldNew
                  name={FIELDS_NAMES.CONTACT_EMAIL}
                  label={FIELDS_LABELS.CONTACT_EMAIL}
                  required={isRequiredField}
                />
              </Grid>

              <Grid item>
                <Typography variant='emphasized'>{notesLabel}</Typography>
              </Grid>

              <Grid item>
                <NotesField
                  name={FIELDS_NAMES.NOTES}
                  label={FIELDS_LABELS.NOTES}
                  required={isRequiredField}
                />
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}
