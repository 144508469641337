import { Grid } from '@mui/material'
import { FC } from 'react'
import {
  DisplayTradeInDetails,
  TradeInDetailsProps
} from '../../../features/DisplayTradeInDetails'
import {
  ReviewRequest,
  ReviewRequestProps
} from './components/ReviewRequest/ReviewRequest'
import {
  SelectedCars,
  SelectedCarsProps
} from './components/SelectedCars/SelectedCars'

export type BuildCarPreviewScreenProps = ReviewRequestProps &
  TradeInDetailsProps &
  SelectedCarsProps

export const BuildCarPreviewScreen: FC<BuildCarPreviewScreenProps> = ({
  notes,
  requestedVehicle,
  selectedCars,
  tradeinVehicle
}) => {
  return (
    <Grid container gap={4} mb={10}>
      <ReviewRequest requestedVehicle={requestedVehicle} notes={notes} />
      <DisplayTradeInDetails tradeinVehicle={tradeinVehicle} />
      <SelectedCars selectedCars={selectedCars} />
    </Grid>
  )
}
