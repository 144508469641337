import { FC } from 'react'
import { BGCOLORS, COLORS } from '../../types/colors'
import { FormStep, FormStepperContainer } from './FormStepper.styles'

type FormStepperProps = {
  count: number
  target: number
}

export const FormStepper: FC<FormStepperProps> = ({ count, target }) => {
  return (
    <FormStepperContainer>
      {Array.from({ length: count }, (_, index) => {
        return (
          <FormStep
            key={index}
            stepColor={
              index <= target
                ? COLORS.main_yellow
                : BGCOLORS.gray
            }
          />
        )
      })}
    </FormStepperContainer>
  )
}
