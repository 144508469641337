import { useCallback, useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ResponseStatusMap } from '../../../types/status'
import { useApiContext } from '../useApiContext'
import { useMakeService } from '../useMakeService'
import { useRequestToast } from '../useToast'
import { useUpdateAccountSubscription } from '../useUpdateAccountSubscription'
import { useUserInfo } from '../useUserInfo'
import { getReturnUrl } from './utils'
import { useHandleRedirectNeeded } from './useHandleRedirectNeeded'
import {
  AUTOUPDATE_QUERY_PARAM,
  AUTOUPDATE_QUERY_SUBSCRIPTION_TYPE_PARAM
} from './constants'
import { useCheckStripe } from './useCheckStripe'

interface UseUpdateSubscriptionOptions {
  autoUpdate: boolean
  isSubscriptionPage?: boolean
}

export const useUpdateSubscriptionService = (
  options: UseUpdateSubscriptionOptions = {
    autoUpdate: true,
    isSubscriptionPage: false
  }
) => {
  const [params, setParams] = useSearchParams()
  const { accountID } = useUserInfo()

  const { handleRedirect, redirectUrl } = useHandleRedirectNeeded()
  const { checkStripeObservable, checkStripeService } = useCheckStripe()

  const shouldAutoUpdate = params.has(AUTOUPDATE_QUERY_PARAM)
  const autoUpdateSubType = params.get(AUTOUPDATE_QUERY_SUBSCRIPTION_TYPE_PARAM)

  const { updateAccountSubscription, updateAccountSubscriptionObservable } =
    useUpdateAccountSubscription()

  const { carSnoopApi } = useApiContext()

  const { openRequestToast } = useRequestToast()

  const showErrorToast = useCallback(() => {
    openRequestToast({ status: 'failed' })
  }, [openRequestToast])

  const updateSubscription = useCallback(
    async ({
      accId,
      subscriptionType
    }: {
      accId: string
      subscriptionType: string
    }) => {
      const updateResult = await carSnoopApi.subscription.updateSubscription({
        accountId: accId,
        type: subscriptionType,
        returnUrl: getReturnUrl(subscriptionType, options.isSubscriptionPage)
      })

      const isSuccess = updateResult.status === ResponseStatusMap.Success
      const isError = updateResult.status === ResponseStatusMap.Error

      if (isSuccess) {
        updateAccountSubscription()

        return updateResult
      }

      if (isError) {
        if (updateResult.stripeSession) {
          handleRedirect(updateResult.stripeSession)

          return updateResult
        }
      }

      return updateResult
    },
    [updateAccountSubscription, openRequestToast]
  )

  const [updateSubscriptionService, updateSubscriptionObservable] =
    useMakeService(updateSubscription, { includeCheckAccess: true })

  const autoUpdateSubscription = useCallback(
    async (accId: string, subscriptionType: string) => {
      const stripeCheckResult = await checkStripeService()

      const isStripeCheckSuccess =
        stripeCheckResult.status === ResponseStatusMap.Success

      if (isStripeCheckSuccess) {
        // looks clear
        if (stripeCheckResult.result === true) {
          updateSubscriptionService({
            accId,
            subscriptionType
          })
        }
      } else {
        showErrorToast()
      }
    },
    [updateSubscription, showErrorToast, checkStripeService]
  )

  useEffect(() => {
    if (
      shouldAutoUpdate &&
      autoUpdateSubType &&
      accountID &&
      options.autoUpdate
    ) {
      // To avoid double calls it resets search params
      setParams('')
      autoUpdateSubscription(accountID, autoUpdateSubType)
    }
  }, [accountID, options.autoUpdate])

  useEffect(() => {
    if (updateSubscriptionObservable.status === 'failed' && redirectUrl) {
      // To avoid showing error toast on redirect
      return
    }
    openRequestToast({ status: updateSubscriptionObservable.status })
  }, [updateSubscriptionObservable.status, redirectUrl])

  const isLoading = useMemo(() => {
    return (
      updateSubscriptionObservable.isLoading ||
      updateAccountSubscriptionObservable.isLoading ||
      checkStripeObservable.isLoading
    )
  }, [
    updateSubscriptionObservable,
    updateAccountSubscriptionObservable,
    checkStripeObservable
  ])

  return {
    updateSubscriptionService,
    redirectUrl,
    isLoading
  }
}
