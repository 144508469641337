import { BuildCarPreviewScreenProps } from '../../../../forms/screens/BuildCarPreviewScreen/BuildCarPreviewScreen'

export const requestedVehicle: BuildCarPreviewScreenProps['requestedVehicle'] =
  {
    make: 'BMW',
    model: 'X6',
    years: ['2010', '2020'],
    trim: 'The best',
    exteriorColors: [
      {
        id: 'Black',
        name: 'Black'
      },
      {
        id: 'White',
        name: 'White'
      }
    ],
    interiorColors: [
      {
        id: 'Red',
        name: 'Red'
      },
      {
        id: 'Pink',
        name: 'Pink'
      }
    ]
  }

export const tradeinVehicle: BuildCarPreviewScreenProps['tradeinVehicle'] = {
  id: '001',
  year: 2020,
  make: 'BMW',
  model: 'X6',
  trim: 'Shvabra',
  mileage: 10203,
  tradeInValue: 28000,
  status: 'For Sale',
  stockNumber: '123',
  vin: '12321432',
  retailValue: 30000,
  documentContent:
    'https://patch.com/img/cdn/users/102060/2011/04/raw/23a8eb2c83a72c56efb063a27a2e1ae4.jpg',
  documentID: '',
  addressID: '',
  type: 'png',
  requests: 0
}

export const selectedCars: BuildCarPreviewScreenProps['selectedCars'] = [
  {
    id: '001',
    year: 2020,
    make: 'BMW',
    model: 'X6',
    trim: 'Shvabra',
    mileage: 10203,
    distance: '10 mi',
    shippingCost: 300,
    retailValue: 2000,
    vin: '000',
    zipCode: '12345'
  },
  {
    id: '001',
    year: 2020,
    make: 'BMW',
    model: 'X6',
    trim: 'Shvabra',
    mileage: 10203,
    distance: '10 mi',
    shippingCost: 300,
    image: 'https://autojosh.com/wp-content/uploads/2017/03/askideas-4.jpg',
    retailValue: 2000,
    vin: '000',
    zipCode: '12345'
  },
  {
    id: '001',
    year: 2020,
    make: 'BMW',
    model: 'X6',
    trim: 'Shvabra',
    mileage: 10203,
    distance: '10 mi',
    shippingCost: 300,
    image: 'https://autojosh.com/wp-content/uploads/2017/03/askideas-4.jpg',
    retailValue: 2000,
    vin: '000',
    zipCode: '12345'
  },
  {
    id: '001',
    year: 2020,
    make: 'BMW',
    model: 'X6',
    trim: 'Shvabra',
    mileage: 10203,
    distance: '10 mi',
    shippingCost: 300,
    retailValue: 2000,
    vin: '000',
    zipCode: '12345'
  }
]
