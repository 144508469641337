import { actionsUserInfo } from '../../../../../../store/features/userInfo'
import { ResponseStatusMap } from '../../../../../../types/status'
import {
  useAppDispatch,
  useMakeService,
  useUserInfo,
  useApiContext
} from '../../../../../../utils/hooks'

export const useDeleteAddress = (onDeleted?: () => void) => {
  const dispatch = useAppDispatch()
  const { accountID } = useUserInfo()
  const { carSnoopApi } = useApiContext()

  const [deleteAddress, deleteAddressObservable] = useMakeService(
    async (id: string) => {
      const response = await carSnoopApi.addresses.deleteAddress(id)

      if (response.status === ResponseStatusMap.Success) {
        const accInfo = await carSnoopApi.accounts.readAccount(accountID)

        if (accInfo.status === ResponseStatusMap.Success) {
          const { addresses } = accInfo
          dispatch(actionsUserInfo.setAddresses({ addresses }))
          onDeleted && onDeleted()
        }

        return response
      }
      return { status: ResponseStatusMap.Error, message: response.message }
    },
    { includeCheckAccess: true, withStatusNotification: [0, 1] }
  )

  return [deleteAddress, deleteAddressObservable] as [
    typeof deleteAddress,
    typeof deleteAddressObservable
  ]
}
