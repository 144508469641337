import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISideBarPayload, SIDE_BAR, ISideBarContent } from './SideBar.types'

interface ISideBarState {
  isOpen: boolean
  sideBarType: SIDE_BAR
  header: string
  content: ISideBarContent
}

export const initialState: ISideBarState = {
  isOpen: false,
  header: '',
  sideBarType: SIDE_BAR.NONE,
  content: {
    [SIDE_BAR.CONTACT_SUPPORT]: {
      email: '',
      reason: ''
    },
    [SIDE_BAR.NONE]: { title: '' }
  }
}

export const sideBarSlice = createSlice({
  name: 'sideBar',
  initialState,
  reducers: {
    openSideBar: (
      state: ISideBarState,
      action: PayloadAction<ISideBarPayload>
    ) => {
      state.isOpen = true
      state.sideBarType = action.payload.sideBarType
      state.header = action.payload.header
      state.content[action.payload.sideBarType] = action.payload.content
    },
    closeSideBar: (state) => {
      state.isOpen = false
      state.sideBarType = SIDE_BAR.NONE
    }
  }
})
