import { FC, useCallback } from 'react'
import { SearchInventoryResponseItem } from '../../../api/carSnoopApi/vehicles'
import { NoMatchesImage } from '../../../assets/imgs/NoMatches/intex'
import { EmptyState, ItemList } from '../../../atoms/ItemList'
import {
  CheckboxVehicleComponent,
  CheckboxVehicleComponentProps
} from '../../../molecules/CheckboxVehicleComponent'

interface DisplayVehiclesListProps {
  vehiclesList: SearchInventoryResponseItem[]
  checkedList: string[]
  onCheckChange: CheckboxVehicleComponentProps['onChange']
}

const maxVehicles = 15

export const DisplayVehiclesList: FC<DisplayVehiclesListProps> = ({
  vehiclesList,
  checkedList,
  onCheckChange
}) => {
  const isChecked = useCallback(
    (id: string) => checkedList.includes(id),
    [checkedList]
  )

  const isDisabled = useCallback(
    (id: string) => checkedList.length >= maxVehicles && !isChecked(id),
    [checkedList, isChecked]
  )

  return (
    <ItemList
      dataList={vehiclesList}
      EmptyStateComponent={
        <EmptyState
          title='No matches were found. Change the car details and try again!'
          image={<NoMatchesImage />}
        />
      }
      itemProps={{
        checked: isChecked,
        disabled: isDisabled,
        onChange: onCheckChange
      }}
      ItemComponent={CheckboxVehicleComponent}
    />
  )
}
