import { Grid } from '@mui/material'
import { useState } from 'react'
import { Condition } from '../../../../atoms/Condition'
import { FindCarFormStepper } from '../../../../atoms/FindCarFormStepper'
import { Toggle } from '../../../../atoms/Toggle'
import { FormOrchestrator } from '../../../../layout/FormOrchestrator'
import { useFormOrchestrator } from '../../../../layout/FormOrchestrator/hooks/useFormOrchestrator'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'
import {
  FinalStep,
  FirstVariant,
  PreviewStep,
  SecondVariant
} from './TestForms'

export const FormOrchestratorExample = () => {
  const [variant, setVariant] = useState<'First' | 'Second'>('First')
  const ctx = useFormOrchestrator()

  const toggleVariant = () => {
    ctx.clearValues()
    if (variant === 'First') {
      setVariant('Second')
    } else {
      setVariant('First')
    }
  }

  const isFirstVariant = variant === 'First'
  const FirstStep = isFirstVariant ? FirstVariant : SecondVariant

  return (
    <ComponentContainer>
      <ComponentWithPaperBackground title='Not in features folder (in layout)'>
        <Grid container spacing={10}>
          <Condition
            condition={ctx.step === 0}
            trueContent={
              <Grid item xs={12}>
                <Toggle
                  checked={variant === 'Second'}
                  onChange={toggleVariant}
                  leftLabel='First'
                  rightLabel='Second'
                />
              </Grid>
            }
          />
          <Grid item xs={12}>
            <FindCarFormStepper
              steps={[
                { label: 'Form' },
                { label: 'Preview' },
                { label: 'Success' }
              ]}
              currentStep={ctx.step}
            />
          </Grid>
          <FormOrchestrator
            ctx={ctx}
            steps={[FirstStep, PreviewStep, FinalStep]}
          />
        </Grid>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
