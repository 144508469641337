import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ResponseStatus } from '../../../types/status'

interface SnackbarState {
  isOpen: boolean
  message: string
  status: ResponseStatus
}

export const initialState: SnackbarState = {
  isOpen: false,
  message: '',
  status: 'error'
}

export const snackbarSlice = createSlice({
  name: 'units/snackbar',
  initialState,
  reducers: {
    openSnackbar: (
      state,
      action: PayloadAction<Omit<SnackbarState, 'isOpen'>>
    ) => {
      state.isOpen = true
      state.message = action.payload.message
      state.status = action.payload.status
    },
    closeSnackbar: (state) => {
      state.isOpen = false
    }
  }
})
