import { Grid } from '@mui/material'
import { FC, useCallback, useMemo, useState } from 'react'
import { generatePath } from 'react-router-dom'
import { FindCarFormStepper } from '../../../../../../atoms/FindCarFormStepper'
import { Show } from '../../../../../../atoms/JSXExtensions'
import { Loader } from '../../../../../../atoms/Loader'
import {
  FLOW_PARAM,
  FLOW_SEARCH_PARAM,
  ShipmentTypes
} from '../../../../../../constants/applicationConstants'
import { PaymentRedirect } from '../../../../../../features/PaymentRedirect'
import { routes } from '../../../../../../routes'
import { ResponseStatusMap } from '../../../../../../types/status'
import { formatNumber } from '../../../../../../utils'
import { useMakeService } from '../../../../../../utils/hooks'
import { useStripePaymentRedirect } from '../../../../hooks/usePaymentRedirect/useStripePaymentRedirect'
import { DeliveryInfo } from '../../components/DeliveryInfo'
import { DetailsContainer } from '../../components/DetailsContainer'
import { arePaymentsPaid } from '../../utils'
import { ChooseShipmentTypeStep } from '../components/ChooseShipmentTypeStep'
import { useAddShipmentDetails } from '../hooks/useAddShipmentDetails'
import { useShipmentInfo } from '../hooks/useShipmentInfo'
import { FinalShippingStep } from './FinalAwaitingPaymentStep'
import { shipmentSteps } from './state.constants'
import { BuyerStripeAwaitingPaymentProps } from './types'

export const BuyerStripeAwaitingPayment: FC<BuyerStripeAwaitingPaymentProps> = (
  props
) => {
  const { transactionId, contract, vehicle, tradeIn, offer, payments } = props

  const {
    shipmentType,
    shipmentInfo,
    onShipmentTypeChange,
    setPickup,
    setDropOff,
    isLoading,
    isDisabled,
    addressesAreValid
  } = useShipmentInfo(props)

  const isPaid = useMemo(() => arePaymentsPaid(payments), [payments])

  // Amazing
  const [currentStep, setStep] = useState(Number(isPaid))

  const generateSuccessUrl = useCallback((): string => {
    const baseUrl = window.location.origin
    const successUrl = new URL('/', baseUrl)

    successUrl.pathname = generatePath(routes.contracts.paymentSuccess, {
      transactionId
    })

    successUrl.search = `?${FLOW_SEARCH_PARAM}=${FLOW_PARAM.stripe}`

    return successUrl.href
  }, [transactionId])

  const {
    getPaymentRedirectUrl,
    isPaymentOpen,
    redirectToPayment,
    handleRedirectFail
  } = useStripePaymentRedirect(generateSuccessUrl, transactionId)

  const { sendShipmentInfoObservable, sendShipmentInfo } =
    useAddShipmentDetails()

  const handleNextStepClick = useCallback(() => {
    setStep((step) => step + 1)
  }, [setStep])

  const handlePrevStepClick = useCallback(() => {
    setStep((step) => step - 1)
  }, [setStep])

  const getRedirectUrl = useCallback(() => {
    const itemsForPaying = [
      {
        amount: Number(
          formatNumber(offer.totalPrice, {
            includeDecimalPart: true,
            splitter: ''
          })
        ),
        productName: 'Balance',
        productDescription: 'Remaining Balance to Seller'
      }
    ]

    if (shipmentInfo) {
      itemsForPaying.push({
        amount: shipmentInfo.vehicle.cost,
        productName: 'Shipping',
        productDescription: 'Cost to Ship Vehicle'
      })
    }

    return getPaymentRedirectUrl(itemsForPaying)
  }, [getPaymentRedirectUrl, offer, shipmentInfo])

  const [checkOutService] = useMakeService(
    async () => {
      let res: Awaited<ReturnType<typeof sendShipmentInfo>>

      switch (shipmentType) {
        case ShipmentTypes.local: {
          res = await sendShipmentInfo({
            shipmentType,
            transactionId
          })
          break
        }
        case 'Shipment': {
          if (!shipmentInfo) {
            throw new Error("Shipment info isn't presented. Check the logic")
          }

          res = await sendShipmentInfo({
            shipmentType,
            transactionId,
            shipmentInfo
          })
          break
        }
        default:
          throw new Error('Unexpected shipmentType. Check it bro')
      }

      if (res.status === ResponseStatusMap.Success) {
        redirectToPayment()
      }

      return res
    },
    {
      withStatusNotification: [1]
    }
  )

  const onCheckOutClick = useCallback(async () => {
    await checkOutService()
  }, [checkOutService])

  const totalPrice = useMemo(() => {
    if (!shipmentInfo) {
      return offer.totalPrice
    }

    return offer.totalPrice + shipmentInfo.vehicle.cost
  }, [offer.totalPrice, vehicle.shippingCost, shipmentInfo])

  return (
    <DetailsContainer>
      <FindCarFormStepper
        steps={shipmentSteps}
        currentStep={currentStep}
        showAllSteps
      />
      <Show when={currentStep === 0}>
        <ChooseShipmentTypeStep
          mt='36px'
          shipmentType={shipmentType}
          onShipmentTypeChange={onShipmentTypeChange}
          onContinueClick={handleNextStepClick}
          buttonLabel='Continue'
          disableContinue={isDisabled}
          deliveryAdditionalInfo={
            <Show when={shipmentType === ShipmentTypes.shipping}>
              <Show
                when={!isLoading}
                fallback={
                  <Grid container justifyContent='center'>
                    <Loader small />
                  </Grid>
                }
              >
                <DeliveryInfo
                  vehicle={vehicle}
                  tradeIn={tradeIn}
                  shipmentInfo={shipmentInfo}
                  setPickup={setPickup}
                  setDropOff={setDropOff}
                  isNotAvailable={isDisabled}
                  hasAddressError={!addressesAreValid}
                />
              </Show>
            </Show>
          }
        />
      </Show>
      <Show when={currentStep === 1}>
        <Show
          when={!isLoading}
          fallback={
            <Grid container justifyContent='center' sx={{ py: 5 }}>
              <Loader small />
            </Grid>
          }
        >
          <FinalShippingStep
            disabled={sendShipmentInfoObservable.isLoading}
            totalPrice={totalPrice}
            paymentMethod={contract.paymentMethod}
            isPaid={isPaid}
            onBackClick={handlePrevStepClick}
            onCheckOutClick={onCheckOutClick}
          />
        </Show>
      </Show>
      <PaymentRedirect
        open={isPaymentOpen}
        onRedirectFail={handleRedirectFail}
        getPaymentRedirectUrl={getRedirectUrl}
      />
    </DetailsContainer>
  )
}
