import { CardMedia, Grid, Link } from '@mui/material'
import { FC, useMemo } from 'react'
import { NavLink, generatePath } from 'react-router-dom'
import { ReadInventoryItemStatusesMap } from '../../api/carSnoopApi/accounts/types'
import {
  CompletedTransactionStatuses,
  VehicleStatusTypes
} from '../../api/carSnoopApi/statusTypes'
import { DefaultVehicle } from '../../assets/imgs/DefaultVehicle'
import { Condition } from '../../atoms/Condition'
import { EllipsisText } from '../../atoms/EllipsisText'
import { routes } from '../../routes'
import { formatNumber } from '../../utils'
import { useBackPath, useVehicleDetailsRoute } from '../../utils/hooks'
import { useStatusPathResolver } from '../../utils/hooks/useStatusPathResolver'
import { VehicleBadge } from './VehicleBadge'
import {
  DividerStyles,
  ImageContainer,
  Info,
  InfoTitle,
  NumberContainer,
  NumberIcon,
  StatusContainer,
  TitleContainer,
  VehicleItemContainer
} from './VehicleItem.styles'

export interface VehicleItemProps {
  id: string
  title: string
  stockNumber: string
  mileage: number
  vin?: string
  status?: VehicleStatusTypes
  imageSource?: string
  transactionID?: string
  allowDealsRedirect?: boolean
}

export const VehicleItem: FC<VehicleItemProps> = (props) => {
  const {
    title,
    id,
    stockNumber,
    mileage,
    vin,
    imageSource,
    status,
    transactionID,
    allowDealsRedirect = false
  } = props
  const { getPathToVehicle } = useVehicleDetailsRoute()
  const { getPathByStatus } = useStatusPathResolver(status)
  const { currentLocation } = useBackPath()

  const statusBadge = (
    <Condition
      condition={!!status}
      trueContent={
        <StatusContainer>
          <VehicleBadge status={status} />
        </StatusContainer>
      }
    />
  )

  const formattedMileage = useMemo(
    () => formatNumber(mileage),
    [formatNumber, mileage]
  )

  const vinCode = useMemo(() => vin || '-', [vin])

  const carItemPath = useMemo(() => {
    const pagesMap: Partial<
      Record<VehicleStatusTypes | CompletedTransactionStatuses, string>
    > = {
      [ReadInventoryItemStatusesMap.Requested]: allowDealsRedirect
        ? generatePath(routes.deals.requests, { vehicleID: id })
        : getPathToVehicle(id),
      [ReadInventoryItemStatusesMap.Offered]:
        allowDealsRedirect && transactionID
          ? getPathByStatus({
            transactionId: transactionID
          })
          : getPathToVehicle(id),
      [ReadInventoryItemStatusesMap['Under Contract']]:
        allowDealsRedirect && transactionID
          ? getPathByStatus({
            transactionId: transactionID
          })
          : getPathToVehicle(id)
    }

    return (!!status && pagesMap[status]) || getPathToVehicle(id)
  }, [
    status,
    id,
    transactionID,
    getPathByStatus,
    getPathToVehicle,
    allowDealsRedirect
  ])

  return (
    <VehicleItemContainer>
      <Grid container spacing='10px' height='100%'>
        <Grid item xs={12} height='calc(100% - 70px)' overflow='hidden'>
          <Link
            underline='none'
            component={NavLink}
            to={carItemPath}
            state={{ backPath: currentLocation }}
          >
            <Condition
              condition={!!imageSource}
              trueContent={
                <ImageContainer item xs={16}>
                  {statusBadge}
                  <CardMedia
                    component='img'
                    src={imageSource}
                    alt='Vehicle'
                    sx={{
                      maxHeight: {
                        xs: 150,
                        xl: 200
                      }
                    }}
                  />
                </ImageContainer>
              }
              falseContent={
                <ImageContainer
                  container
                  item
                  xs={16}
                  isDefault={!imageSource}
                  justifyContent='center'
                  alignItems='center'
                  height='100%'
                >
                  {statusBadge}
                  <DefaultVehicle />
                </ImageContainer>
              }
            />
          </Link>
        </Grid>
        <Grid container item xs={12} height='80px'>
          <Grid container item xs={12} spacing='4px'>
            <Grid item xs={8}>
              <TitleContainer>
                <EllipsisText tooltipText={title}>{title}</EllipsisText>
              </TitleContainer>
            </Grid>
            <Condition
              condition={!!stockNumber}
              trueContent={
                <Grid container item xs={4}>
                  <Grid
                    container
                    item
                    wrap='nowrap'
                    xs={12}
                    justifyContent='flex-end'
                    alignItems='center'
                  >
                    <NumberIcon>№</NumberIcon>
                    <NumberContainer>
                      <EllipsisText tooltipText={stockNumber}>
                        {stockNumber}
                      </EllipsisText>
                    </NumberContainer>
                  </Grid>
                </Grid>
              }
            />
          </Grid>
          <Grid container item xs={12} sx={{ fontSize: '12px' }}>
            <Grid container item>
              <DividerStyles />
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <InfoTitle>MILEAGE</InfoTitle>
              </Grid>
              <Grid
                container
                item
                xs={6}
                justifyContent='flex-end'
                alignItems='center'
              >
                <InfoTitle>VIN</InfoTitle>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={4}>
                <Info>
                  <EllipsisText tooltipText={formattedMileage}>
                    {formattedMileage}
                  </EllipsisText>
                </Info>
              </Grid>
              <Grid
                container
                item
                xs={8}
                justifyContent='flex-end'
                alignItems='center'
              >
                <Info>
                  <EllipsisText tooltipText={vinCode}>{vinCode}</EllipsisText>
                </Info>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </VehicleItemContainer>
  )
}
