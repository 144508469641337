import { Grid, LinearProgress, Typography } from '@mui/material'
import { FC } from 'react'
import { COLORS } from '../../types/colors'

interface ProgressBarsProps {
  progress: number
  label?: string
  progressColor?: string
}

export const ProgressBar: FC<ProgressBarsProps> = ({
  progress,
  label = '',
  progressColor = COLORS.text_primary
}) => {
  return (
    <Grid container>
      <Grid item container justifyContent='space-between' wrap='nowrap'>
        <Grid item>
          <Typography variant='main'>{label}</Typography>
        </Grid>
        <Grid item>
          <Typography variant='main' color={progressColor}>
            {progress}%
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <LinearProgress
          variant='determinate'
          value={progress}
          sx={{
            backgroundColor: '#FFFBCE',
            height: '8px',
            borderRadius: '4px',
            '& .MuiLinearProgress-bar': {
              backgroundColor: COLORS.main_yellow
            }
          }}
        />
      </Grid>
    </Grid>
  )
}
