import { AutoselectInputField } from '../../../atoms/AutoselectInputField'
import { DependentField } from '../../../atoms/DependentField'

import { ColorOption, Option } from '../../../types/Autoselect'
import { FIND_CAR_FIELD_NAMES } from '../../../types/enums/findCar'
import { BasicInfo } from '../../../types/forms/findCar'

export type ModelFieldProps = Parameters<typeof AutoselectInputField<Option>>[0]
export type YearFromFieldProps = ModelFieldProps
export type YearToFieldProps = ModelFieldProps
export type TrimFieldProps = ModelFieldProps
export type ExteriorFieldProps = Parameters<
  typeof AutoselectInputField<ColorOption>
>[0]
export type InteriorFieldProps = ExteriorFieldProps

export const DependentModelField = DependentField<ModelFieldProps, BasicInfo>(
  AutoselectInputField<Option>,
  {
    modifyProps: (props, { make }) =>
      make ? props : { ...props, disabled: true },
    deps: [FIND_CAR_FIELD_NAMES.MAKE]
  }
)

export const DependentYearFromField = DependentField<
  YearFromFieldProps,
  BasicInfo
>(AutoselectInputField<Option>, {
  modifyProps: (props, { model, yearTo }) => {
    const { options } = props
    if (!model) {
      return { ...props, disabled: true }
    }
    if (yearTo) {
      const filteredOptions = options.filter((option) => option.id <= yearTo.id)
      return { ...props, options: filteredOptions }
    }
    return props
  },
  deps: [FIND_CAR_FIELD_NAMES.MODEL, FIND_CAR_FIELD_NAMES.YEAR_TO]
})

export const DependentYearToField = DependentField<
  YearToFieldProps,
  BasicInfo
>(AutoselectInputField<Option>,
  {
    modifyProps: (props, { model, yearFrom }) => {
      const { options } = props
      if (!model) {
        return { ...props, disabled: true }
      }
      if (yearFrom) {
        const filteredOptions = options.filter(
          (option) => option.id >= yearFrom.id
        )
        return { ...props, options: filteredOptions }
      }
      return props
    },
    deps: [FIND_CAR_FIELD_NAMES.MODEL, FIND_CAR_FIELD_NAMES.YEAR_FROM]
  }
)

export const DependentTrimField = DependentField<TrimFieldProps, BasicInfo>(
  AutoselectInputField<Option>,
  {
    modifyProps: (props, { yearTo, yearFrom }) => {
      if (!yearFrom && !yearTo) {
        return { ...props, disabled: true }
      }
      return props
    },
    deps: [FIND_CAR_FIELD_NAMES.YEAR_FROM, FIND_CAR_FIELD_NAMES.YEAR_TO]
  }
)

export const DependentExteriorField = DependentField<
  ExteriorFieldProps,
  BasicInfo
>(AutoselectInputField<ColorOption>, {
  modifyProps: (props, { yearFrom, yearTo }) => {
    if (!yearFrom && !yearTo) {
      return { ...props, disabled: true }
    }
    return props
  },
  deps: [FIND_CAR_FIELD_NAMES.YEAR_FROM, FIND_CAR_FIELD_NAMES.YEAR_TO]
})

export const DependentInteriorField = DependentField<
  InteriorFieldProps,
  BasicInfo
>(AutoselectInputField<ColorOption>, {
  modifyProps: (props, { yearFrom, yearTo }) => {
    if (!yearFrom && !yearTo) {
      return { ...props, disabled: true }
    }
    return props
  },

  deps: [FIND_CAR_FIELD_NAMES.YEAR_FROM, FIND_CAR_FIELD_NAMES.YEAR_TO]
})
