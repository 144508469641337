import { RootState } from '../../types'
import { RegisterStep } from '../../../types/enums/RegisterStep'

export const selectRegistrationStep = (state: RootState) =>
  state.registrationForm.step
export const selectOnboardingStep = (state: RootState) =>
  state.registrationForm.onboardStep

export const selectPersonalDetailsScreenData = (state: RootState) => {
  return state.registrationForm[RegisterStep.stepOne]
}

export const selectContactDetailsScreenData = (state: RootState) => {
  return state.registrationForm[RegisterStep.stepTwo]
}

export const selectVerificationCodeScreenData = (state: RootState) =>
  state.registrationForm[RegisterStep.stepThree]

export const selectConfirmPasswordScreenData = (state: RootState) =>
  state.registrationForm[RegisterStep.stepFour]

export const selectRegistrationScreenLength = (state: RootState) =>
  state.registrationForm.length

export const selectNetworkLocation = (state: RootState) =>
  state.registrationForm.network.location

export const selectIsExitState = (state: RootState) => {
  return state.registrationForm.step === -1
}
