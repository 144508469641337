import { Typography } from '@mui/material'
import { BlueButton } from '../Button'

export const ViewReport = ({ reportURL }: { reportURL: string }) => {
  return (
    <a href={reportURL} target='_blank' rel='noreferrer'>
      <BlueButton>
        <Typography variant='main' sx={{ textDecoration: 'underline' }}>
          View Report
        </Typography>
      </BlueButton>
    </a>
  )
}
