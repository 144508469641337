import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { pageNavigation } from '../../thunks/pageNavigation'

interface LoginFormState {
  isBlocked: boolean
  blockedUntil: number
  errorMessage: string
}

export const initialState: LoginFormState = {
  isBlocked: false,
  blockedUntil: 0,
  errorMessage: ''
}

export const loginFormSlice = createSlice({
  name: 'loginForm',
  initialState,
  reducers: {
    setLoginFormState: (store, action: PayloadAction<LoginFormState>) => {
      store.isBlocked = action.payload.isBlocked
      store.errorMessage = action.payload.errorMessage
      store.blockedUntil = action.payload.blockedUntil
    }
  },
  extraReducers: (builder) => {
    builder.addCase(pageNavigation.fulfilled, () => {
      return initialState
    })
  }
})
