import { FC, useMemo } from 'react'
import { Grid, Typography } from '@mui/material'
import { formatNumber, formatPrice } from '../../utils'
import { VehicleComponentDetailsItem } from '../VehicleComponentDetailsItem'
import { Show } from '../JSXExtensions'
import { useUserInfo } from '../../utils/hooks'

interface TradeInVehicleDetailsProps {
  mileage: number
  tradeInValue: number
  stockNumber?: string
  showStockNumber?: boolean
}

export const TradeInVehicleDetails: FC<TradeInVehicleDetailsProps> = ({
  mileage,
  tradeInValue,
  stockNumber = '',
  showStockNumber = true
}) => {
  const { isDealer } = useUserInfo()

  const formattedData = useMemo(
    () => ({
      mileage: formatNumber(mileage),
      tradeInValue: formatPrice(tradeInValue)
    }),
    [mileage, tradeInValue]
  )

  return (
    <Grid container spacing={1}>
      <Show when={showStockNumber && isDealer}>
        <VehicleComponentDetailsItem
          title='stock number:'
          value={stockNumber}
        />
      </Show>
      <Grid item container spacing={0.5}>
        <Grid item>
          <Typography
            variant='body2'
            color='textSecondary'
            textTransform='uppercase'
          >
            mileage:
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='body2'>{formattedData.mileage}</Typography>
        </Grid>
      </Grid>
      <VehicleComponentDetailsItem
        title='Trade in value:'
        value={formattedData.tradeInValue}
      />
    </Grid>
  )
}
