import { styled } from '@mui/material'
import { COLORS } from '../../../../types/colors'

export const AddressMark = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isOutline'
})<{ isOutline?: boolean }>(({ isOutline = false }) => ({
  background: isOutline ? '' : COLORS.main_yellow,
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  ...(isOutline ? { border: `2px solid ${COLORS.main_yellow}` } : {})
}))
