import { Grid, Typography } from '@mui/material'
import { FC, useCallback, useEffect, useState } from 'react'
import { Condition } from '../../../../../atoms/Condition'
import { PersonalSettingsItem } from '../../../../../atoms/PersonalSettingsItem'
import { ConfirmationModal } from '../../../../../molecules/Modal'
import type { Address } from '../../../../../store/types'
import { COLORS } from '../../../../../types/colors'
import { useRequestToast } from '../../../../../utils/hooks'
import { MODES, ModeType } from '../../constants'
import { EditMode } from './components/EditMode'
import { ViewMode } from './components/ViewMode'
import { useDeleteAddress } from './hooks/useDeleteAddress'
import { useUpdateAddress } from './hooks/useUpdateAddress'

export interface AddressCardProps {
  mode: ModeType
  address: Address
  isUserAdmin?: boolean
  canBeDeleted?: boolean
  onEditClick: (address: Address) => void
  onCancelClick: () => void
}

export const AddressCard: FC<AddressCardProps> = (props) => {
  const { mode, onCancelClick, ...otherProps } = props
  const { openRequestToast } = useRequestToast()
  const [isValidateAddressError, setIsValidateAddressError] = useState(false)
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    useState<boolean>(false)

  const onAddressValidationError = () => {
    setIsValidateAddressError(true)
  }
  const [handleUpdateAddressSaveClick, updateAddressObservable] =
    useUpdateAddress(
      otherProps.address,
      onCancelClick,
      onAddressValidationError
    )

  const [deleteAddress, deleteAddressObservable] =
    useDeleteAddress(onCancelClick)

  const handleDeleteClick = useCallback(() => {
    setDeleteConfirmationOpen(true)
  }, [setDeleteConfirmationOpen])

  const handleDeleteConfirm = useCallback(() => {
    deleteAddress(otherProps.address.id)
  }, [setDeleteConfirmationOpen])

  const isEditMode = mode === MODES.EDIT
  const isCreateMode = mode === MODES.CREATE

  const isLoading =
    updateAddressObservable.isLoading || deleteAddressObservable.isLoading

  useEffect(() => {
    setIsValidateAddressError(false)
  }, [mode])

  useEffect(() => {
    openRequestToast({
      status: deleteAddressObservable.status,
      errorMessage: deleteAddressObservable.payload.message
    })
  }, [deleteAddressObservable.status])

  return (
    <PersonalSettingsItem
      isLoading={isLoading}
      isEditMode={isEditMode || isCreateMode}
    >
      <ConfirmationModal
        header={
          <Typography variant='h6' color={COLORS.text_red}>
            Delete
          </Typography>
        }
        isOpen={deleteConfirmationOpen}
        setIsOpen={setDeleteConfirmationOpen}
        cancel={{
          text: 'cancel'
        }}
        submit={{
          text: 'delete',
          callback: handleDeleteConfirm
        }}
      >
        <Grid
          container
          rowSpacing={3}
          flexDirection='column'
          sx={{ pt: 4, pb: 2, px: 8 }}
        >
          <Grid item textAlign='center'>
            <Typography variant='main'>
              Are you sure you would like to delete this address?
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='subtitle2' textAlign='center'>
              Deleted address cannot be recovered
            </Typography>
          </Grid>
        </Grid>
      </ConfirmationModal>
      <Condition
        condition={isEditMode}
        trueContent={
          <EditMode
            mode={MODES.EDIT}
            {...otherProps}
            onSave={handleUpdateAddressSaveClick}
            onCancel={onCancelClick}
            onDelete={handleDeleteClick}
            updateAddressError={isValidateAddressError}
          />
        }
        falseContent={
          <Condition
            condition={isCreateMode}
            trueContent={
              <EditMode
                mode={MODES.CREATE}
                {...otherProps}
                onSave={handleUpdateAddressSaveClick}
                onCancel={onCancelClick}
                updateAddressError={isValidateAddressError}
              />
            }
            falseContent={<ViewMode {...otherProps} />}
          />
        }
      />
    </PersonalSettingsItem>
  )
}
