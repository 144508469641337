import { Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { ActionBlock } from '../../../../../../atoms/ActionBlock'
import { Show } from '../../../../../../atoms/JSXExtensions'
import { CommonConfirmationModal } from '../../../../../../molecules/Modal/CommonConfirmationModal'
import { useModal, useUserInfo } from '../../../../../../utils/hooks'
import { completeDealHint, completeDealMessage } from './constants'

interface NonTrackableViewProps {
  title: string
  description: string
  completeDeal?: () => void
}

export const NonTrackableView: FC<NonTrackableViewProps> = ({
  title,
  description,
  completeDeal
}) => {
  const completeDealModal = useModal(completeDeal)
  const { isAdmin } = useUserInfo()
  return (
    <>
      <Grid item>
        <Typography variant='label1'>{title}</Typography>
      </Grid>
      <Grid item mt='12px'>
        <Typography variant='subtitle2'>{description}</Typography>
      </Grid>
      <Show when={completeDeal}>
        <Grid item mt={4} width='100%'>
          <ActionBlock
            schema={[
              {
                label: 'Complete the Deal',
                type: 'primary',
                onClick: completeDealModal.onOpen,
                disabled: !isAdmin
              }
            ]}
          />
        </Grid>
      </Show>
      <CommonConfirmationModal
        {...completeDealModal}
        message={completeDealMessage}
        hint={completeDealHint}
      />
    </>
  )
}
