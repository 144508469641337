import Grid from '@mui/material/Grid'
import { FC } from 'react'
import { StatisticCardSchema } from '../../../types/statisticCard'
import { StatisticCard } from './StatisticCard'
import { UserConnections } from './UserConnections'
import { UserInfoCard } from './UserInfoCard'

interface DashboardProps {
  schema: StatisticCardSchema
}

export const Dashboard: FC<DashboardProps> = ({ schema }) => {
  return (
    <Grid container item spacing={2} xs={12}>
      <Grid item xs={12} md={6} xl={3}>
        <UserInfoCard />
      </Grid>
      <Grid item xs={12} md={6} xl={3}>
        <UserConnections />
      </Grid>
      <Grid item xs={12} md={12} xl={6}>
        <StatisticCard schema={schema} />
      </Grid>
    </Grid>
  )
}
