import { FC } from 'react'
import { Grid, Typography } from '@mui/material'

export type ResultScreenBodyProps = {
  subtitle: string
  icon: JSX.Element
  description?: string
}

export const ResultScreenBody: FC<ResultScreenBodyProps> = (props) => {
  const { subtitle, icon, description } = props

  return (
    <Grid container>
      <Grid container item xs={12} justifyContent='center'>
        {icon}
      </Grid>
      <Grid container item xs={12} justifyContent='center' mt={4}>
        <Typography variant='subtitle1' textAlign='center'>
          {subtitle}
        </Typography>
      </Grid>
      <Grid container item xs={12} justifyContent='center' mt={3}>
        <Typography
          variant='subtitle2'
          textAlign='center'
          whiteSpace='pre-line'
        >
          {description}
        </Typography>
      </Grid>
    </Grid>
  )
}
