import { FC, useState, useCallback, useLayoutEffect } from 'react'
import { CardMedia, Box, Grid } from '@mui/material'
import { Condition } from '../../../../atoms/Condition'
import { Modal } from '../../../Modal'
import { BORDERS, COLORS } from '../../../../types/colors'
import { ImagesCounterLabel } from '../ImagesCounterLabel'
import { VehicleImage } from '../../../../api/carSnoopApi/vehicles'
import { CarouselButton } from '../CarouselButton/CarouselButton'
import { returnNoCachedSrc } from '../../../../utils/returnNoCachedSrc'

export interface ImagesCarouselProps {
  activeImageIndex: number
  images: VehicleImage[]
  isOpen: boolean
  onClose: () => void
}

export const FullScreenImagePreview: FC<ImagesCarouselProps> = ({
  images,
  activeImageIndex,
  isOpen,
  onClose
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(activeImageIndex)

  const handleRightClick = useCallback(() => {
    if (currentImageIndex < images.length - 1) {
      setCurrentImageIndex((i) => i + 1)
    }
  }, [currentImageIndex, setCurrentImageIndex])

  const handleLeftClick = useCallback(() => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex((i) => i - 1)
    }
  }, [currentImageIndex, setCurrentImageIndex])

  useLayoutEffect(() => {
    setCurrentImageIndex(activeImageIndex)
  }, [activeImageIndex])

  return (
    <Condition
      condition={!!images[currentImageIndex]}
      trueContent={
        <Modal open={isOpen} onClose={onClose}>
          <Grid
            item
            container
            sx={{
              pl: 3,
              pr: 6,
              pt: 4,
              pb: 10,
              backgroundColor: COLORS.white,
              mt: 2
            }}
            spacing={3}
            flexDirection='column'
            alignItems='center'
          >
            <Box>
              <ImagesCounterLabel
                current={currentImageIndex + 1}
                total={images.length}
              />
            </Box>
            <Grid
              container
              item
              sx={{
                position: 'relative',
                width: '70vw',
                height: '70vh'
              }}
            >
              <Grid
                container
                justifyContent='space-between'
                sx={{
                  position: 'absolute',
                  alignSelf: 'center',
                  width: '100%',
                  pr: 2
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                    right: '24px'
                  }}
                >
                  <CarouselButton
                    onClick={handleLeftClick}
                    disabled={!currentImageIndex}
                  />
                </Box>
                <Box
                  sx={{
                    transform: 'rotateZ(180deg)',
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                    left: '24px'
                  }}
                >
                  <CarouselButton
                    onClick={handleRightClick}
                    disabled={currentImageIndex >= images.length - 1}
                  />
                </Box>
              </Grid>
              <CardMedia
                component='img'
                src={`${returnNoCachedSrc(images[currentImageIndex]?.url)}`}
                alt='Vehicle'
                height='100%'
                sx={{
                  objectFit: 'contain',
                  border: BORDERS.EMPTY_IMAGE_MAIN
                }}
              />
            </Grid>
          </Grid>
        </Modal>
      }
    />
  )
}
