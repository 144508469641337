import { FC } from 'react'
import { Grid } from '@mui/material'
import { CancelButton, AgreeButton } from '../../../../../atoms/Button'

interface AddVehiclePreviewControlsProps {
  onSaveClick: () => void
  onEditClick: () => void
}

export const AddVehiclePreviewControls: FC<AddVehiclePreviewControlsProps> = ({
  onSaveClick,
  onEditClick
}) => {
  return (
    <Grid container item spacing={2}>
      <Grid item>
        <CancelButton onClick={onEditClick}>EDIT</CancelButton>
      </Grid>

      <Grid item>
        <AgreeButton onClick={onSaveClick}>SAVE</AgreeButton>
      </Grid>
    </Grid>
  )
}
