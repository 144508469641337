import { Button } from '@mui/material'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { NotesField } from '../../../../atoms/NotesField'
import { latinWithSpecial } from '../../../../utils/validators'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const NotesFieldExample = () => {
  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <Formik
          initialValues={{
            notes: '',
            notes_2: ''
          }}
          validationSchema={Yup.object({
            notes: Yup.string().test(
              'is-latin-or-special',
              'Enter a valid email address.',
              latinWithSpecial(['\n', ' '])
            ),
            notes_2: Yup.string().test(
              'is-latin-or-special',
              'Enter a valid email address.',
              latinWithSpecial(['\n', ' '])
            )
          })}
          onSubmit={console.log}
          validateOnChange={false}
        >
          <Form
            style={{
              width: '512px'
            }}
          >
            <NotesField name='notes' label='Notes' />
            <NotesField name='notes_2' label='Notes 2' maxChars={5} />
            <Button type='submit'>Submit</Button>
          </Form>
        </Formik>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
