import { useEffect } from 'react'
import { useFormikContext } from 'formik'

type AutoFormValidatorProps = {
  dependencies: unknown[]
}

export const AutoFormValidator = ({ dependencies }: AutoFormValidatorProps) => {
  const { validateForm } = useFormikContext()

  useEffect(() => {
    validateForm()
  }, [...dependencies])

  return null
}
