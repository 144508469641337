export const filterMultiSelectorValues = <T>(
  values: T[],
  comparator: (_: T) => boolean
): T[] => {
  const valuesContainsExactValue = values.some((option) => comparator(option))
  const lastItem = values[values.length - 1]

  if (!valuesContainsExactValue || values.length === 1) {
    return values
  }

  const [matched, unmatched] = values.reduce<[T[], T[]]>(
    (acc, item) => {
      if (comparator(item)) {
        acc[0].push(item)
      } else {
        acc[1].push(item)
      }

      return acc
    },
    [[], []]
  )

  if (comparator(lastItem)) {
    return matched
  }

  return unmatched
}
