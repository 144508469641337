import { FC } from 'react'
import { Grid } from '@mui/material'
import { InProgressDealCard } from '../InProgressDealCard'
import { For, Show } from '../../../../atoms/JSXExtensions'
import { EmptyState } from '../../../../atoms/ItemList'
import { NoMatchesImage } from '../../../../assets/imgs/NoMatches/intex'
import { Condition } from '../../../../atoms/Condition'
import { NormalizedDealsListType } from '../../../../store/types'

export interface InProgressDealsListProps {
  deals: NormalizedDealsListType
  noDealsText: string
}

export const InProgressDealsList: FC<InProgressDealsListProps> = ({
  deals,
  noDealsText
}) => {
  return (
    <Show when={deals}>
      {(inProgressDeals) => (
        <Condition
          condition={!!inProgressDeals.length}
          trueContent={
            <Grid container item spacing={2}>
              <For of={inProgressDeals}>
                {(deal) => {
                  return (
                    <Grid item xs={6} lg={4}>
                      <InProgressDealCard deal={deal} />
                    </Grid>
                  )
                }}
              </For>
            </Grid>
          }
          falseContent={
            <EmptyState title={noDealsText} image={<NoMatchesImage />} />
          }
        />
      )}
    </Show>
  )
}
