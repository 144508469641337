import {
  LicensePlateFieldSet,
  LicensePlateFieldSetValues
} from '../../../AddVehicle/screens/EditNewVehicleDetails/Controls'
import { noop } from '../../../../utils/noop'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const LicencePlateFieldSetExample = () => {
  const handleVinConfirm = (values: LicensePlateFieldSetValues) => {
    console.log(values)
  }

  return (
    <ComponentContainer fullWidth>
      <ComponentWithPaperBackground title='Not in molecules folder'>
        <LicensePlateFieldSet
          onSubmit={handleVinConfirm}
          storage={{
            values: {
              'License Plate': {
                licensePlate: '',
                state: null
              }
            },
            errors: {
              'License Plate': {
                licensePlate: '',
                state: ''
              }
            },
            updateValues: noop,
            updateErrors: noop
          }}
        />
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
