import { format } from 'date-fns'
import { REQUEST_WRONG_ERROR_MESSAGE } from '../../../constants/messages'
import type { Address } from '../../../store/types'
import { OrNull } from '../../../types/OrNull'
import { ResponseStatusMap } from '../../../types/status'
import { addCountryCode } from '../../../utils/formatters/addCountryCode'
import { parseRelativePhoneNumber } from '../../../utils/formatters/parseRelativePhoneNumber'
import {
  ErrorResponse,
  RequestRecord,
  SuccessResponse
} from '../../private.types'
import { Utils } from '../../utils'
import {
  DriverLicenseFileType,
  NormalizedCheckUserErrorResponse,
  NormalizedCheckUserResponse,
  RawCheckUserErrorResponse,
  RawCheckUserResponse
} from './types'

export class Users {
  protected readonly utils: Utils
  protected readonly operations = {
    authenticateUser: 'authenticateUser',
    checkUser: 'checkUser',
    deleteUserRequest: 'deleteUserRequest',
    deleteUserVerifyToken: 'deleteUserVerifyToken',
    registerUser: 'registerUser',
    forgotPassword: 'forgotPassword',
    resetPassword: 'resetPassword',
    activateUser: 'activateUser',
    resendCode: 'resendCode',
    verifyEmail: 'verifyEmail',
    readUser: 'readUser',
    verifyForgotPasswordToken: 'verifyForgotPasswordToken',
    makeUserAdmin: 'makeUserAdmin',
    removeUserAdmin: 'removeUserAdmin',
    deleteUser: 'deleteUser',
    updateUser: 'updateUser',
    uploadAvatar: 'uploadAvatar'
  }

  constructor(utils: Utils) {
    this.utils = utils
  }

  async authenticateUser({
    id,
    password,
    idp
  }: {
    id: string
    password: string
    idp?: 'Google' | 'LinkedIn'
  }): Promise<
    | SuccessResponse<{
      sessionID: string
      id: string
      message: string
      isBlocked: boolean
      blockedUntil: number
      isExternal: boolean
    }>
    | ErrorResponse<{
      id: string
      message: string
      isBlocked: boolean
      blockedUntil: number
      isExternal: boolean
    }>
  > {
    type SuccessResponse = {
      id: string
      session_id: string
      message: string
    }

    type ErrorResponse = {
      id: string
      message: string
      is_blocked?: boolean
      locked_until?: number
    }

    let dataToSend: RequestRecord

    switch (idp) {
      case 'LinkedIn':
      case 'Google': {
        dataToSend = {
          id,
          idp,
          password: null,
          token: password
        }
        break
      }
      default: {
        dataToSend = { id, password }
      }
    }

    const { type, payload } = await this.utils.makeJSONRequest<
      SuccessResponse,
      ErrorResponse
    >(this.operations.authenticateUser, dataToSend)

    if (type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        id: payload.id,
        sessionID: payload.session_id,
        message: payload.message,
        isBlocked: false,
        blockedUntil: 0,
        isExternal: !!idp
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: payload.id,
      message: !idp ? payload.message : '',
      blockedUntil: payload?.locked_until || 0,
      isBlocked: payload?.is_blocked || false,
      isExternal: !!idp
    }
  }

  async checkUser(
    email: string
  ): Promise<
    | SuccessResponse<NormalizedCheckUserResponse>
    | ErrorResponse<NormalizedCheckUserErrorResponse>
  > {
    const response = await this.utils.makeJSONRequest<
      RawCheckUserResponse,
      ErrorResponse<RawCheckUserErrorResponse>
    >(this.operations.checkUser, { email })

    if (response.type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        id: response.payload.id,
        idp: response.payload.idp,
        accountID: response.payload.account_id,
        message: response.payload.message
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: response.payload.id,
      message: response.payload.message,
      reason: response.payload.reason
    }
  }

  async deleteUserRequest(
    id: string
  ): Promise<
    | SuccessResponse<{ id: string; message: string }>
    | ErrorResponse<{ id?: string; message: string }>
  > {
    const response = await this.utils.makePostRequest(
      this.operations.deleteUserRequest,
      { id }
    )

    if (response.status >= 500) {
      return {
        status: 'error',
        message: REQUEST_WRONG_ERROR_MESSAGE
      }
    }

    const responseData = await response.json()

    if (response.status >= 300) {
      return {
        status: 'error',
        id: responseData.id,
        message: responseData.message
      }
    }

    return {
      status: 'success',
      id: responseData.id,
      message: responseData.message
    }
  }

  async deleteUserVerifyToken(data: {
    id: string
    token?: string
  }): Promise<
    | SuccessResponse<{ id: string; message: string }>
    | ErrorResponse<{ id?: string; message: string }>
  > {
    type SuccessResponse = {
      id: string
      message: string
    }

    type ErrorResponse = {
      id: string
      message: string
    }

    const { type, payload } = await this.utils.makeJSONRequest<
      SuccessResponse,
      ErrorResponse
    >(this.operations.deleteUserVerifyToken, data)

    if (type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        id: payload.id,
        message: payload.message
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: payload.id,
      message: payload.message
    }
  }

  async registerUser(data: {
    account_id?: string
    email: string
    first_name: string
    last_name: string
    phone: string
    birth_date: string
    zip_code: string
    idp: string
    device_id?: string
    token?: string
  }): Promise<
    | SuccessResponse<{
      accountID: string
      id: string
      message: string
      isExists: boolean
      isNotVerified: boolean
      isBlocked: boolean
      blockedUntil: number
    }>
    | ErrorResponse<{
      accountID?: string
      id: string
      message: string
      isExists: boolean
      isNotVerified: boolean
      isBlocked: boolean
      blockedUntil: number
    }>
  > {
    type RegisterUserResponse = {
      account_id?: string
      id: string
      idp: 'CarSnoop'
      message: string
    }

    const { type, payload } = await this.utils.makeJSONRequest<
      RegisterUserResponse,
      {
        id: string
        account_id?: string
        message: string
        locked_until?: number
        is_blocked?: boolean
      }
    >(this.operations.registerUser, {
      ...data,
      phone: addCountryCode(data.phone)
    })

    if (type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        accountID: payload.account_id as string,
        id: payload.id,
        message: payload.message,
        isExists: false,
        isNotVerified: false,
        isBlocked: false,
        blockedUntil: 0
      }
    }

    const isExists = payload.message.includes('email already exists')
    const isNotVerified = payload.message.includes(
      'email has not been verified'
    )

    if (isExists) {
      return {
        status: ResponseStatusMap.Error,
        id: payload.id,
        accountID: payload.account_id,
        message: payload.message,
        isExists,
        isNotVerified,
        isBlocked: payload.is_blocked || false,
        blockedUntil: payload.locked_until || 0
      }
    }

    if (isNotVerified) {
      return {
        status: ResponseStatusMap.Error,
        id: payload.id,
        message: 'Email has not been verified.',
        isExists,
        isNotVerified,
        isBlocked: payload.is_blocked || false,
        blockedUntil: payload.locked_until || 0
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: payload.id,
      message: payload.message,
      accountID: payload.account_id,
      isExists,
      isNotVerified,
      isBlocked: payload.is_blocked || false,
      blockedUntil: payload.locked_until || 0
    }
  }

  async resendCode(id: string): Promise<
    | SuccessResponse<{
      id: string
      message: string
      blockedUntil: number
      isBlocked: boolean
    }>
    | ErrorResponse<{
      id?: string
      message: string
      blockedUntil: number
      isBlocked: boolean
    }>
  > {
    const response = await this.utils.makePostRequest(
      this.operations.resendCode,
      { id }
    )

    if (response.status >= 500) {
      return {
        status: 'error',
        message: REQUEST_WRONG_ERROR_MESSAGE,
        isBlocked: false,
        blockedUntil: 0
      }
    }

    const responseData = await response.json()

    if (response.status >= 300) {
      return {
        status: 'error',
        id: responseData.id,
        message: responseData.message,
        isBlocked: responseData.is_blocked || false,
        blockedUntil: responseData.locked_until || 0
      }
    }

    return {
      status: 'success',
      id: responseData.id,
      message: responseData.message,
      isBlocked: responseData.is_blocked || false,
      blockedUntil: responseData.locked_until || 0
    }
  }

  async verifyEmail(options: {
    id: string
    verification_code: string
  }): Promise<
    | ErrorResponse<{
      id?: string
      message: string
      isBlocked: boolean
      blockedUntil: number
    }>
    | SuccessResponse<{
      id: string
      message: string
      isBlocked: boolean
      blockedUntil: number
    }>
  > {
    const response = await this.utils.makePostRequest(
      this.operations.verifyEmail,
      options
    )

    if (response.status >= 500) {
      return {
        status: 'error',
        message: REQUEST_WRONG_ERROR_MESSAGE,
        isBlocked: false,
        blockedUntil: 0
      }
    }

    const responseData = await response.json()

    if (response.status >= 300) {
      return {
        status: 'error',
        id: responseData.id,
        message: responseData.message,
        isBlocked: responseData.is_blocked,
        blockedUntil: responseData.locked_until
      }
    }

    return {
      status: 'success',
      id: responseData.id,
      message: responseData.message,
      isBlocked: false,
      blockedUntil: 0
    }
  }

  async resetPassword(data: {
    id: string
    password: string
    token?: string
  }): Promise<
    | SuccessResponse<{ id: string; message: string }>
    | ErrorResponse<{ id?: string; message: string }>
  > {
    const response = await this.utils.makePostRequest(
      this.operations.resetPassword,
      data
    )

    if (response.status >= 500) {
      return {
        status: 'error',
        message: REQUEST_WRONG_ERROR_MESSAGE
      }
    }

    const responseData = await response.json()

    if (response.status >= 300) {
      return {
        status: 'error',
        id: responseData.id,
        message: responseData.message
      }
    }

    return {
      status: 'success',
      id: responseData.id,
      message: responseData.message
    }
  }

  async forgotPassword(
    id: string
  ): Promise<
    | SuccessResponse<{ id: string; message: string }>
    | ErrorResponse<{ id?: string; message: string }>
  > {
    const response = await this.utils.makePostRequest(
      this.operations.forgotPassword,
      { id }
    )

    if (response.status >= 500) {
      return {
        status: 'error',
        message: REQUEST_WRONG_ERROR_MESSAGE
      }
    }

    const responseData = await response.json()

    if (response.status >= 300) {
      return {
        status: 'error',
        id: responseData.id,
        message: responseData.message
      }
    }

    return {
      status: 'success',
      id: responseData.id,
      message: responseData.message
    }
  }

  async readUser(id: string): Promise<
    | SuccessResponse<{
      id: string
      firstName: string
      lastName: string
      fullName: string
      accountID: string
      driversLicense: string
      driversLicenseState: string
      driversLicenseExpiration: string
      driverLicenseFile: DriverLicenseFileType
      taxID: string
      birthDate: string
      phone: string
      avatar: string
      isAdmin: boolean
    }>
    | ErrorResponse<{ id: string; message: string }>
  > {
    type SuccessResponse = {
      id: string
      first_name: string
      last_name: string
      full_name: string
      account_id: string
      drivers_license: string | null
      drivers_license_expiration: string | null
      drivers_license_state: string | null
      driver_license_file: OrNull<{
        id: string
        created_on: string
        type: string
        notes: string
        link: OrNull<string>
      }>
      tax_id: string | null
      birth_date: string
      phone: string
      avatar: string
      is_admin: boolean
    }

    const response = await this.utils.makeJSONRequest<
      SuccessResponse,
      { id: string; message: string }
    >(this.operations.readUser, {
      id
    })

    if (response.type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        id: response.payload.id,
        firstName: response.payload.first_name,
        lastName: response.payload.last_name,
        fullName: response.payload.full_name,
        accountID: response.payload.account_id,
        driversLicense: response.payload.drivers_license || '',
        driversLicenseState: response.payload.drivers_license_state || '',
        driversLicenseExpiration:
          response.payload.drivers_license_expiration || '',
        driverLicenseFile: response.payload.driver_license_file && {
          ...response.payload.driver_license_file,
          createdOn: response.payload.driver_license_file.created_on
        },
        taxID: response.payload.tax_id || '',
        birthDate: response.payload.birth_date,
        phone: parseRelativePhoneNumber(response.payload.phone),
        avatar: response.payload.avatar,
        isAdmin: response.payload.is_admin
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: response.payload.id,
      message: response.payload.message
    }
  }

  async verifyForgotPasswordToken(data: {
    id: string
    token?: string
  }): Promise<
    | SuccessResponse<{ id: string; message: string }>
    | ErrorResponse<{ id?: string; message: string }>
  > {
    type SuccessResponse = {
      id: string
      message: string
    }

    type ErrorResponse = {
      id: string
      message: string
    }

    const { type, payload } = await this.utils.makeJSONRequest<
      SuccessResponse,
      ErrorResponse
    >(this.operations.verifyForgotPasswordToken, data)

    if (type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        id: payload.id,
        message: payload.message
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: payload.id,
      message: payload.message
    }
  }

  async makeUserAdmin({
    id
  }: {
    id: string
  }): Promise<
    | SuccessResponse<{ id: string; message: string }>
    | ErrorResponse<{ id: string; message: string }>
  > {
    type RequestResponse = {
      id: string
      message: string
    }

    const { type, payload } = await this.utils.makeJSONRequest<
      RequestResponse,
      RequestResponse
    >(this.operations.makeUserAdmin, {
      id
    })

    if (type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        id: payload.id,
        message: payload.message
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: payload.id,
      message: payload.message
    }
  }

  async removeUserAdmin({
    id
  }: {
    id: string
  }): Promise<
    | SuccessResponse<{ id: string; message: string }>
    | ErrorResponse<{ id: string; message: string }>
  > {
    type RequestResponse = {
      id: string
      message: string
    }

    const { type, payload } = await this.utils.makeJSONRequest<
      RequestResponse,
      RequestResponse
    >(this.operations.removeUserAdmin, {
      id
    })

    if (type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        id: payload.id,
        message: payload.message
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: payload.id,
      message: payload.message
    }
  }

  async deleteUser({
    id
  }: {
    id: string
  }): Promise<
    | SuccessResponse<{ id: string; message: string }>
    | ErrorResponse<{ id: string; message: string }>
  > {
    type RequestResponse = {
      id: string
      message: string
    }

    const { type, payload } = await this.utils.makeJSONRequest<
      RequestResponse,
      RequestResponse
    >(this.operations.deleteUser, {
      id
    })

    if (type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        id: payload.id,
        message: payload.message
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: payload.id,
      message: payload.message
    }
  }

  async updateUser(data: {
    id: string
    firstName: string
    lastName: string
    phone: string
    dateOfBirth: OrNull<Date>
    taxId?: string
    changedBy?: string
    driversLicense?: string
    driversLicenseState?: string
    driversLicenseExpiration?: string
    credit?: []
    employment?: []
    residence?: []
    addresses?: Address[]
    deviceId?: string
  }): Promise<
    | SuccessResponse<{ id: string; message: string }>
    | ErrorResponse<{ id: string; message: string }>
  > {
    type RequestResponse = {
      id: string
      message: string
    }

    const {
      id,
      firstName,
      lastName,
      phone,
      dateOfBirth,
      taxId = '',
      addresses = [],
      changedBy = id,
      driversLicense = '',
      driversLicenseState = '',
      driversLicenseExpiration = '',
      credit = [],
      employment = [],
      residence = []
    } = data

    const dataToSend = {
      id,
      first_name: firstName,
      last_name: lastName,
      phone: addCountryCode(phone),
      birth_date: format(dateOfBirth as Date, 'yyyy-MM-dd'),
      tax_id: taxId,
      addresses: addresses.map(({ zipCode, ...other }) => ({
        zip_code: zipCode,
        ...other
      })),
      changed_by: changedBy,
      drivers_license: driversLicense,
      drivers_license_state: driversLicenseState,
      drivers_license_expiration: driversLicenseExpiration,
      credit,
      employment,
      residence
    }

    const { type, payload } = await this.utils.makeJSONRequest<
      RequestResponse,
      RequestResponse
    >(this.operations.updateUser, dataToSend)

    if (type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        id: payload.id,
        message: payload.message
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: payload.id,
      message: payload.message
    }
  }

  async uploadAvatar(data: {
    id: string
    changedBy?: string
    avatar: string
    avatarFileType: string
  }): Promise<
    | SuccessResponse<{ id: string; message: string }>
    | ErrorResponse<{ id: string; message: string }>
  > {
    type RequestResponse = {
      id: string
      message: string
    }

    const { id, changedBy = id, avatar, avatarFileType } = data

    const dataToSend = {
      id,
      changed_by: changedBy,
      avatar_file_type: avatarFileType,
      avatar
    }

    const { type, payload } = await this.utils.makeJSONRequest<
      RequestResponse,
      RequestResponse
    >(this.operations.uploadAvatar, dataToSend)

    if (type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        id: payload.id,
        message: payload.message
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: payload.id,
      message: payload.message
    }
  }
}
