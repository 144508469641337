import { DependentField } from '../../../../../../../../atoms/DependentField'
import {
  MaskedInput,
  MaskedInputFieldProps
} from '../../../../../../../../atoms/MaskedInput'
import { SELLER_FIELDS_NAMES, SellerFormFieldsTypes } from './types'

export type DependentZipCodeFieldProps = MaskedInputFieldProps

export const DependentZipCodeField = DependentField<
  MaskedInputFieldProps,
  SellerFormFieldsTypes
>(MaskedInput, {
  deps: [SELLER_FIELDS_NAMES.ZIP_CODE]
})
