import { FC, useCallback } from 'react'
import { Formik } from 'formik'
import { Box } from '@mui/material'

import { ScreenContainer } from '../../../layout/ScreenContainer'
import { TextInputField } from '../../../atoms/TextInputField'
import { PhoneInputField } from '../../../atoms/PhoneInputField'
import { ActionBlock } from '../../../atoms/ActionBlock'

import { isValidForm } from '../../../utils/isValidForm'
import { useAppSelector } from '../../../utils/hooks'
import { selectNetworkLocation } from '../../../store/units/registrationForm/selectors'
import { ContactDetails } from '../../../types/forms/registration'
import { RequiredLabel } from '../../../atoms/RequiredText'

import { Location } from './Location'
import { validationSchema } from './validationSchema'
import { phoneNumberMaskPattern } from '../../../constants/regexp'
import { phoneNumberMask } from '../../../constants/applicationConstants'
import { ContactDetailsForm } from './ContactDetailsScreen.styles'

export type ContactDetailsScreenScreenProps = {
  isLoading?: boolean
  initialValues: ContactDetails
  staticValues?: Partial<ContactDetails>
  initialErrors?: Partial<ContactDetails>
  onNextBtnClicked: (data: ContactDetails) => void
  onBackBtnClicked: () => void
}

export const ContactDetailsScreen: FC<ContactDetailsScreenScreenProps> = ({
  isLoading,
  initialValues,
  initialErrors,
  staticValues = {},
  onNextBtnClicked,
  onBackBtnClicked
}) => {
  const userLocation = useAppSelector(selectNetworkLocation)
  const initialFormValues = {
    ...initialValues,
    ...staticValues
  }

  const validateEmail = useCallback(
    (value: string) => {
      if (value === initialFormValues.email && initialErrors?.email) {
        return initialErrors.email
      }
    },
    [initialFormValues, initialErrors]
  )

  return (
    <ScreenContainer isLoading={isLoading}>
      <Box sx={{ mb: 2 }}>
        <RequiredLabel />
      </Box>
      <Formik
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        initialErrors={initialErrors}
        initialTouched={{ email: true }}
        onSubmit={onNextBtnClicked}
        validateOnMount
      >
        {(formik) => {
          return (
            <ContactDetailsForm>
              <TextInputField
                label='Email'
                placeholder='Email'
                name='email'
                disabled={'email' in staticValues}
                isValid={!(formik.touched?.email && formik.errors.email)}
                validate={validateEmail}
              />
              <PhoneInputField
                label='Phone number'
                name='phone'
                disabled={'phone' in staticValues}
                mask={phoneNumberMaskPattern}
                placeholder={phoneNumberMask}
                isValid={!(formik.touched?.phone && formik.errors.phone)}
                autoComplete='no'
              />
              <Location
                state={{
                  value: formik.values.state.id,
                  name: formik.values.state.name,
                  options: [formik.values.state],
                  status: userLocation.status
                }}
                city={{
                  value: formik.values.city.id,
                  name: formik.values.city.name,
                  options: [formik.values.city],
                  status: userLocation.status
                }}
              />
              <ActionBlock
                schema={[
                  {
                    label: 'Back',
                    type: 'secondary',
                    onClick: onBackBtnClicked
                  },
                  {
                    label: 'Next',
                    type: 'primary',
                    disabled: !isValidForm<ContactDetails>(formik, {
                      requiredFields: ['email', 'phone']
                    }),
                    props: {
                      type: 'submit'
                    }
                  }
                ]}
              />
            </ContactDetailsForm>
          )
        }}
      </Formik>
    </ScreenContainer>
  )
}
