import { useMemo, useCallback } from 'react'
import { NormalizedTransactionType } from '../../../api/carSnoopApi/transactions/types/transaction/normalized'
import { useDealsPageSlice } from '.'
import { useUserInfo } from '../../../utils/hooks'
import { DealsActiveTabEnum } from '../../../store/types'
import { completedDealStatuses } from '../../../store/pages/Deals'

export const useReturnToDealsPage = (
  status: NormalizedTransactionType['status'] = 'Requested',
  sellerID: NormalizedTransactionType['sellerID']
) => {
  const { accountID } = useUserInfo()
  const { returnToDealsPage } = useDealsPageSlice()

  const isCurrentUserASeller = useMemo(
    () => sellerID === accountID,
    [sellerID, accountID]
  )

  const tabNumber = useMemo(
    () =>
      completedDealStatuses.includes(status)
        ? DealsActiveTabEnum.Completed
        : DealsActiveTabEnum['In Progress'],
    [status]
  )

  const backToDealsPage = useCallback(() => {
    returnToDealsPage({
      initialSellingView: isCurrentUserASeller,
      tabNumber
    })
  }, [tabNumber, isCurrentUserASeller])

  return backToDealsPage
}
