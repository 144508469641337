import { FC, useMemo, useCallback } from 'react'
import { Grid, Typography } from '@mui/material'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { TwoDudes } from '../../assets/icons/TwoDudes'
import { PageContainer } from '../../layout/PageContainer'
import { routes } from '../../routes'
import { OperationResultScreen } from '../../molecules/OperationResultScreen'
import { OperationResultInfoType } from '../../molecules/OperationResultScreen/types'
import { buttonText, headerText, mainText } from './constants'

export const PaymentConfirmation: FC = () => {
  const navigate = useNavigate()
  const { transactionId = '' } = useParams()
  const navigateToDeal = useCallback(() => {
    const path = generatePath(routes.contracts.transaction, {
      transactionId
    })
    navigate(path)
  }, [transactionId])

  const operationResultInfo: OperationResultInfoType = useMemo(
    () => ({
      header: (
        <Grid item>
          <Typography variant='h6'>{headerText}</Typography>
        </Grid>
      ),
      mainText: (
        <Typography
          variant='subtitle2'
          textAlign='center'
          whiteSpace='pre-wrap'
        >
          {mainText}
        </Typography>
      ),
      onClick: navigateToDeal,
      buttonText,
      icon: <TwoDudes />
    }),
    [navigateToDeal]
  )
  return (
    <PageContainer>
      <OperationResultScreen
        successOperationInfo={operationResultInfo}
        sx={{ height: '100vh', minHeight: '650px' }}
      />
    </PageContainer>
  )
}
