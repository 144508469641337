import { FC, PropsWithChildren, Children } from 'react'
import { EmptyRequest } from '../../assets/imgs/EmptyRequest'
import { Condition } from '../Condition'
import { Loader } from '../Loader'
import { LOADING_TEXT } from './constants'
import {
  ContentContainer,
  FindCarResultContainer,
  ImgContainer,
  ResultTitle
} from './FindCarResult.styles'

export interface FindCarResultProps {
  isLoading?: boolean
}

export const FindCarResult: FC<PropsWithChildren<FindCarResultProps>> = (
  props
) => {
  const { children, isLoading } = props

  const hasChildren = !!Children.count(children)

  return (
    <FindCarResultContainer>
      <Condition
        condition={hasChildren}
        trueContent={<>{Children.toArray(children)}</>}
        falseContent={
          <>
            {isLoading && <ResultTitle>{LOADING_TEXT}</ResultTitle>}
            <ContentContainer>
              <Condition
                condition={!!isLoading}
                trueContent={<Loader />}
                falseContent={
                  <ImgContainer>
                    <EmptyRequest />
                  </ImgContainer>
                }
              />
            </ContentContainer>
          </>
        }
      />
    </FindCarResultContainer>
  )
}
