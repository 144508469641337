import { useCallback } from 'react'
import {
  selectDealerHomepageState,
  actionsDealerHomepage
} from '../../../store/pages/dealerMain'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'

export const useDealerHomepageSlice = () => {
  const dispatch = useAppDispatch()
  const { currentTabID, userVehicles } = useAppSelector(
    selectDealerHomepageState
  )

  const setCurrentTabID = useCallback(
    (tabID: number) => {
      dispatch(actionsDealerHomepage.setCurrentTabID(tabID))
    },
    [dispatch, actionsDealerHomepage.setCurrentTabID]
  )

  const setUserVehiclesList = useCallback(
    (list: Parameters<typeof actionsDealerHomepage.setUserVehiclesList>[0]) => {
      dispatch(actionsDealerHomepage.setUserVehiclesList(list))
    },
    [dispatch, actionsDealerHomepage.setCurrentTabID]
  )

  return {
    currentTabID,
    userVehicles,
    setCurrentTabID,
    setUserVehiclesList
  }
}
