type Props = {
  condition: boolean
  trueContent: JSX.Element
  falseContent?: JSX.Element | null
}

export const Condition = ({
  condition,
  trueContent,
  falseContent = null
}: Props): JSX.Element | null => {
  if (condition) {
    return trueContent
  }

  return falseContent
}
