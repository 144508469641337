import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Box, Button, Grid, Typography } from '@mui/material'
import {
    FieldArray,
    FieldArrayRenderProps,
    Form,
    Formik,
    FormikHelpers,
    FormikProps
} from 'formik'
import { FC, MouseEventHandler, useCallback } from 'react'

import { DeleteIcon } from '../../../../assets/icons/Delete'
import SuccessIcon from '../../../../assets/icons/SuccessIcon'
import { AgreeButton, CancelButton } from '../../../../atoms/Button'
import { Card } from '../../../../atoms/Card'
import { Condition } from '../../../../atoms/Condition'
import { Loader } from '../../../../atoms/Loader'
import { RequiredLabel } from '../../../../atoms/RequiredText'
import { TextInputFieldNew } from '../../../../atoms/TextInputFieldNew'
import { COLORS } from '../../../../types/colors'
import { ResultScreenBody } from '../../../ResultScreen/ResultScreenBody'
import { MAX_EMAILS } from './constants'
import { useCheckIfEmailExist, validateEmails } from './helpers'
import { FIELDS, MyTeamAddUsersFormValues } from './types'
import { validationSchema } from './validationSchema'

export const MyTeamAddUsersHeader: FC = () => {
  return (
    <Grid container item xs={10} spacing={1} justifyContent='flex-start'>
      <Grid item>
        <Typography variant='h6'>Add New User</Typography>
      </Grid>
    </Grid>
  )
}

export interface MyTeamAddUsersBodyProps {
  onSendClick: (values: MyTeamAddUsersFormValues) => void
  onCancelClick: MouseEventHandler
}

export const MyTeamAddUsersBody: FC<MyTeamAddUsersBodyProps> = (props) => {
  const { onSendClick, onCancelClick } = props
  const checkIfEmailExist = useCheckIfEmailExist()

  const handleAddNewUserClick = useCallback(
    (
        helpers: FieldArrayRenderProps,
        formik: FormikProps<MyTeamAddUsersFormValues>
      ) =>
      async () => {
        const {
          values: { emails }
        } = formik

        const formikErrors = await formik.validateForm()

        if (!(FIELDS.EMAILS in formikErrors)) {
          const emailExistError = await validateEmails(
            emails,
            checkIfEmailExist
          )

          if (emailExistError) {
            formik.setErrors({ emails: emailExistError })
            return
          }

          helpers.insert(emails.length, '')
        }
      },
    []
  )

  const handleDeleteClick = useCallback(
    (helpers: FieldArrayRenderProps, index: number) => () => {
      helpers.remove(index)
    },
    []
  )

  const handleSubmit = useCallback(
    async (
      values: MyTeamAddUsersFormValues,
      helpers: FormikHelpers<MyTeamAddUsersFormValues>
    ) => {
      const { emails } = values
      const emailExistError = await validateEmails(emails, checkIfEmailExist)

      if (emailExistError) {
        helpers.setErrors({ emails: emailExistError })
        return
      }

      onSendClick(values)
    },
    [onSendClick]
  )

  return (
    <Grid container sx={{ ml: '10px' }}>
      <Grid item container spacing='10px'>
        <Grid container sx={{ padding: '34px 32px 0px' }}>
          <Typography>
            Please, enter the email to add new user. We will send them the email
            with an invitation.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Formik<MyTeamAddUsersFormValues>
            initialValues={{ emails: [''] }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {(formik) => {
              const { emails } = formik.values
              return (
                <Form>
                  <Grid container spacing='10px'>
                    <Grid
                      container
                      spacing='10px'
                      sx={{ padding: '10px 32px 0' }}
                    >
                      <Grid item>
                        <RequiredLabel marginTop={0} />
                      </Grid>
                      <FieldArray
                        name={FIELDS.EMAILS}
                        validateOnChange
                        render={(helpers) => {
                          return (
                            <>
                              {emails.map((_email, index) => {
                                const isFirstField = index === 0
                                return (
                                  <Grid
                                    container
                                    item
                                    xs={12}
                                    key={`${FIELDS.EMAILS}.${index}`}
                                  >
                                    <Grid item xs={isFirstField ? 12 : 11}>
                                      <TextInputFieldNew
                                        name={`${FIELDS.EMAILS}.${index}`}
                                        label='New User Email'
                                        onBlur={() => {
                                          if (emails[index]) {
                                            formik.validateForm()
                                          }
                                        }}
                                        required
                                      />
                                    </Grid>
                                    <Condition
                                      condition={!isFirstField}
                                      trueContent={
                                        <Grid
                                          container
                                          item
                                          xs={1}
                                          justifyContent='center'
                                        >
                                          <Button
                                            disableElevation
                                            variant='text'
                                            sx={{ minWidth: 0, padding: 0 }}
                                            onClick={handleDeleteClick(
                                              helpers,
                                              index
                                            )}
                                          >
                                            <DeleteIcon />
                                          </Button>
                                        </Grid>
                                      }
                                    />
                                  </Grid>
                                )
                              })}
                              <Condition
                                condition={emails.length < MAX_EMAILS}
                                trueContent={
                                  <Grid
                                    container
                                    item
                                    xs={12}
                                    justifyContent='flex-end'
                                  >
                                    <Button
                                      variant='text'
                                      disableElevation
                                      startIcon={<AddCircleOutlineIcon />}
                                      sx={{
                                        textTransform: 'none',
                                        fontWeight: '400'
                                      }}
                                      onClick={handleAddNewUserClick(
                                        helpers,
                                        formik
                                      )}
                                    >
                                      Add User
                                    </Button>
                                  </Grid>
                                }
                                falseContent={<Grid item />}
                              />
                            </>
                          )
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      borderBottom: `1px solid ${COLORS.border_gray}`
                    }}
                  />
                  <Grid
                    container
                    item
                    xs={12}
                    justifyContent='flex-end'
                    spacing={2}
                    sx={{ padding: '24px' }}
                  >
                    <Grid item>
                      <CancelButton onClick={onCancelClick}>
                        Cancel
                      </CancelButton>
                    </Grid>
                    <Grid item>
                      <AgreeButton
                        type='submit'
                        // formik is immutable thing so we can't use useCallback here ¯\_(ツ)_/¯
                        disabled={(() => {
                          const formikErrors = formik.errors[FIELDS.EMAILS]
                          const hasErrors = Array.isArray(formikErrors)
                            ? formikErrors.some(Boolean)
                            : false

                          const hasEmptyField = formik.values[
                            FIELDS.EMAILS
                          ].some((v) => !v)

                          return hasErrors || hasEmptyField
                        })()}
                      >
                        Send
                      </AgreeButton>
                    </Grid>
                  </Grid>
                </Form>
              )
            }}
          </Formik>
        </Grid>
      </Grid>
    </Grid>
  )
}

export interface MyTeamAddUsersViewProps {
  isLoading: boolean
  isSuccess: boolean
  onCloseClick: MouseEventHandler
  onCancelClick: MouseEventHandler
  onSendClick: (values: MyTeamAddUsersFormValues) => void
}

export const MyTeamAddUsersView: FC<MyTeamAddUsersViewProps> = (props) => {
  const { isLoading, isSuccess, onCloseClick, onCancelClick, onSendClick } =
    props
  return (
    <Card onCloseClick={onCloseClick} header={<MyTeamAddUsersHeader />}>
      <Condition
        condition={isLoading}
        trueContent={
          <Grid container>
            <Loader />
          </Grid>
        }
        falseContent={
          <Condition
            condition={isSuccess}
            trueContent={
              <Box sx={{ p: '40px 0 60px', m: '0 auto' }}>
                <ResultScreenBody
                  icon={<SuccessIcon />}
                  subtitle='The invitation link has been sent successfully!'
                  description='We have sent the email(s) with further instructions.'
                />
              </Box>
            }
            falseContent={
              <MyTeamAddUsersBody
                onCancelClick={onCancelClick}
                onSendClick={onSendClick}
              />
            }
          />
        }
      />
    </Card>
  )
}
