import { RefObject } from 'react'
import { FormikProps, FormikValues } from 'formik'
import { FIND_CAR_FIELD_NAMES } from '../../../types/enums/findCar'
import { Option } from '../../../types/Autoselect'
import { OrNull } from '../../../types/OrNull'
import { ReferenceModel } from '../../../types/ReferenceModel'
import { NormalizedReadInventoryItem } from '../../../api/carSnoopApi/accounts/types'

export interface FiltersFormValues extends FormikValues {
  [FIND_CAR_FIELD_NAMES.MAKE]: null | Option
  [FIND_CAR_FIELD_NAMES.MODEL]: null | Option
  [FIND_CAR_FIELD_NAMES.YEAR_FROM]: null | Option
  [FIND_CAR_FIELD_NAMES.YEAR_TO]: null | Option
}

export interface FilterComponentProps<V extends NormalizedReadInventoryItem> {
  initialValues: FiltersFormValues
  displayVehicleList: V[]
  defaultDisplayVehicleList: V[]
  applyFilters: (vehiclesList: V[], fomValues: FiltersFormValues) => void
  resetFilters: () => void
  formRef: RefObject<FormikProps<FiltersFormValues>>
  isClearDisabled: boolean
}

export enum FILTER_CAR_FIELD_LABELS {
  MAKE = 'Make',
  MODEL = 'Model',
  YEAR_FROM = 'Min Year',
  YEAR_TO = 'Max Year'
}

export interface FilterFieldSetInfo {
  [FIND_CAR_FIELD_NAMES.MAKE]: OrNull<ReferenceModel>
  [FIND_CAR_FIELD_NAMES.MODEL]: OrNull<ReferenceModel>
  [FIND_CAR_FIELD_NAMES.YEAR_FROM]: OrNull<ReferenceModel>
  [FIND_CAR_FIELD_NAMES.YEAR_TO]: OrNull<ReferenceModel>
}
