import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useMediaQuery, useTheme } from '@mui/material'
import { format } from 'date-fns'
import { FC, MouseEventHandler, useCallback, useMemo } from 'react'
import { ReadRequestsResponse } from '../../../../../../api/carSnoopApi/requests/types'
import { ReadVehicleResponse } from '../../../../../../api/carSnoopApi/vehicles'
import { AccordionSummary } from '../../../../../../atoms/AccordionSummary'
import { Show } from '../../../../../../atoms/JSXExtensions'
import { CommonConfirmationModal } from '../../../../../../molecules/Modal/CommonConfirmationModal'
import { useModal } from '../../../../../../utils/hooks'
import { RequestHeaderDesktopLayout } from './RequestHeaderDesktopLayout'
import { RequestHeaderTabletLayout } from './RequestHeaderTabletLayout'

export interface RequestHeaderProps {
  request:
  | ReadRequestsResponse.Normilized.ReadRequestsResponse
  | ReadVehicleResponse['requests'][number]
  standalone?: boolean
  onClick?: MouseEventHandler
  getTradeinValue: () => {
    tablet: string[]
    desktop: string
  }
  isBuyingFlow?: boolean
  onCancelClick?: (id: string) => void
  disableCancel?: boolean
  navigateTo?: string
  navigateToNewTab?: boolean
}

export const RequestHeader: FC<RequestHeaderProps> = ({
  request,
  standalone = false,
  isBuyingFlow = false,
  disableCancel = false,
  getTradeinValue,
  onClick,
  onCancelClick,
  navigateTo = '',
  navigateToNewTab = false
}) => {
  const theme = useTheme();
  const isBreakpointDownLg = useMediaQuery(theme.breakpoints.down('lg'));
  const {
    id,
    make,
    model,
    trim,
    year: years,
    exteriorColor,
    interiorColor,
    expiresOn
  } = request

  const title = useMemo(() => {
    return [make, model, trim].filter(Boolean).join(' ')
  }, [make, model, trim])
  const accordionStyles = useMemo(
    () => (isBreakpointDownLg ? { height: '90px' } : { height: '58px' }),
    [isBreakpointDownLg]
  )
  const displayYears = useMemo(() => {
    const splittedYears = years.split(',')
    if (splittedYears.length === 1) {
      return splittedYears[0]
    }

    return `${splittedYears[0]} - ${splittedYears[splittedYears.length - 1]}`
  }, [years])

  const displayExpirationDate = useMemo(() => {
    return format(expiresOn, 'MM / dd / yyyy')
  }, [expiresOn])

  const handleCancelRequestConfirm = useCallback(() => {
    onCancelClick && onCancelClick(id)
  }, [id, onCancelClick])

  const { cancelText, modalMessage, modalHint } = useMemo(
    () =>
      isBuyingFlow
        ? {
          cancelText: 'Cancel Request',
          modalMessage: 'Are you sure you would like to cancel request?',
          modalHint: 'Canceled requests cannot be recovered'
        }
        : {
          cancelText: 'Decline Request',
          modalMessage: 'Are you sure you would like to decline the request?',
          modalHint: 'Declined request can not be recovered'
        },
    [isBuyingFlow]
  )

  const cancelRequestConfirmationModal = useModal(handleCancelRequestConfirm)

  const tradeinViews = useMemo(() => {
    return getTradeinValue()
  }, [getTradeinValue])

  return (
    <>
      <CommonConfirmationModal
        {...cancelRequestConfirmationModal}
        message={modalMessage}
        hint={modalHint}
      />
      <AccordionSummary
        expandIcon={!standalone && <ExpandMoreIcon />}
        aria-controls={`panel${id}-content`}
        id={`panel${id}-header`}
        onClick={onClick}
        sx={accordionStyles}
      >
        <Show
          when={isBreakpointDownLg}
          fallback={
            <RequestHeaderDesktopLayout
              title={title}
              years={displayYears}
              exteriorColors={exteriorColor}
              interiorColors={interiorColor}
              tradeIn={tradeinViews.desktop}
              expirationDate={displayExpirationDate}
              disableCancel={disableCancel}
              onCancelRequest={cancelRequestConfirmationModal.onOpen}
              cancelText={cancelText}
              navigateTo={navigateTo}
              navigateToNewTab={navigateToNewTab}
            />
          }
        >
          <RequestHeaderTabletLayout
            title={title}
            years={displayYears}
            exteriorColors={exteriorColor}
            interiorColors={interiorColor}
            tradeIn={tradeinViews.tablet}
            expirationDate={displayExpirationDate}
            disableCancel={disableCancel}
            onCancelRequest={cancelRequestConfirmationModal.onOpen}
            navigateTo={navigateTo}
            navigateToNewTab={navigateToNewTab}
          />
        </Show>
      </AccordionSummary>
    </>
  )
}
