import { FC, PropsWithChildren, useLayoutEffect, useRef, useState } from 'react'
import { Tooltip } from '../Tooltip'
import { EllipsisTextSpan } from './EllipsisText.styles'

export type EllipsisTextProps = {
  tooltipText?: string
  forcedTooltip?: boolean
  ellipsisRows?: number
} & PropsWithChildren

export const EllipsisText: FC<EllipsisTextProps> = (props) => {
  const { tooltipText, forcedTooltip, ellipsisRows = 1, children } = props

  const ref = useRef<HTMLSpanElement>(null)
  const [isEllipsed, setIsEllipsed] = useState<boolean>(false)

  useLayoutEffect(() => {
    const offsetWidth = ref.current?.offsetWidth || 0
    const scrollWidth = ref.current?.scrollWidth || 0
    const offsetHeight = ref.current?.offsetHeight || 0
    const scrollHeight = ref.current?.scrollHeight || 0
    const ellipsed = offsetWidth < scrollWidth || offsetHeight < scrollHeight

    setIsEllipsed(ellipsed)
  })
  return (
    <Tooltip
      title={tooltipText || ''}
      disableHoverListener={forcedTooltip ? false : !isEllipsed}
    >
      <EllipsisTextSpan ellipsisRows={ellipsisRows} ref={ref}>
        {children}
      </EllipsisTextSpan>
    </Tooltip>
  )
}
