import { Grid, Typography } from '@mui/material'
import { Form, Formik, FormikConfig, FormikProps, FormikValues } from 'formik'
import { FC, PropsWithChildren } from 'react'
import { DeleteIcon } from '../../assets/icons/Delete'
import { AgreeButton, CancelButton, RedButton } from '../Button'
import { Condition } from '../Condition'
import { RequiredLabel } from '../RequiredText'

export interface EditPersonalInformationContainerProps {
  title?: string
  initialValues: FormikValues
  disableAutocomplete?: boolean
  deleteAction?: {
    label: string
    onClick: () => void | Promise<void>
  }
  onSubmit: (values: FormikValues) => void | Promise<void>
  onCancel: () => void | Promise<void>
  validationSchema: FormikConfig<unknown>['validationSchema']
  isSubmitDisabled: (formik: FormikProps<FormikValues>) => boolean
}

export const EditPersonalInformationContainer: FC<
  PropsWithChildren<EditPersonalInformationContainerProps>
> = ({
  title,
  initialValues,
  disableAutocomplete,
  deleteAction,
  onSubmit,
  onCancel,
  validationSchema,
  isSubmitDisabled,
  children
}) => {
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnChange={false}
        autoComplete={disableAutocomplete ? 'off' : 'on'}
      >
        {(formik) => {
          return (
            <Form style={{ width: '100%' }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='label1'>{title}</Typography>
                  <RequiredLabel />
                </Grid>
                <Grid item xs={12}>
                  {children}
                </Grid>
                <Condition
                  condition={!!deleteAction}
                  trueContent={
                    <Grid item xs={12}>
                      <RedButton
                        startIcon={<DeleteIcon />}
                        onClick={deleteAction?.onClick}
                      >
                        <Typography variant='main'>
                          {deleteAction?.label}
                        </Typography>
                      </RedButton>
                    </Grid>
                  }
                />
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent='flex-end'
                  spacing={2}
                >
                  <Grid item>
                    <CancelButton onClick={onCancel}>Cancel</CancelButton>
                  </Grid>
                  <Grid item>
                    <AgreeButton
                      type='submit'
                      disabled={isSubmitDisabled(formik)}
                    >
                      Save
                    </AgreeButton>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )
        }}
      </Formik>
    )
  }
