import { createAsyncThunk } from '@reduxjs/toolkit'
import { carSnoopApi } from '../../../../api'
import {
  ReadCarByYearResponse,
  ReadCarModelParams
} from '../../../../api/carSnoopApi/vehicles'
import { ResponseStatusMap } from '../../../../types/status'

export const readCarByYear = createAsyncThunk(
  'readCarModel/byYear',
  async (
    params: ReadCarModelParams
  ): Promise<{ status: string; data: ReadCarByYearResponse }> => {
    const response = await carSnoopApi.vehicles.readCarModelYear(params)

    if (response.status === ResponseStatusMap.Success) {
      return {
        status: 'succeeded',
        data: response.data
      }
    }

    throw new Error()
  }
)

export const readVehicleTrimByYear = createAsyncThunk(
  'readVehicleTrim/byYear',
  async (
    params: ReadCarModelParams
  ): Promise<{ status: string; data: ReadCarByYearResponse }> => {
    const response = await carSnoopApi.vehicles.readCarModelYear(params)

    if (response.status === ResponseStatusMap.Success) {
      return {
        status: 'succeeded',
        data: response.data
      }
    }

    throw new Error()
  }
)
