import { FC, useCallback, useMemo, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { AddressEditForm } from './AddressEditForm'
import {
  ConfirmationModal,
  ConfirmationModalProps
} from '../../../../../molecules/Modal'
import { AddShipmentDetailsParamsAddress } from '../../../../../api/carSnoopApi/shipments/types'
import { parseNumber } from '../../../../../utils'

interface AddressEditModalProps {
  address: AddShipmentDetailsParamsAddress
  isOpen: ConfirmationModalProps['isOpen']
  setOpen: ConfirmationModalProps['setIsOpen']
  isDropOff: boolean
  onSubmit: (values: AddShipmentDetailsParamsAddress) => void | Promise<void>
}

export const AddressEditModal: FC<AddressEditModalProps> = ({
  isOpen,
  setOpen,
  onSubmit,
  isDropOff,
  address
}) => {
  const [isValid, setIsValid] = useState(false)
  const [formValues, setFormValues] =
    useState<AddShipmentDetailsParamsAddress>(address)

  const addressType = useMemo(() => {
    return isDropOff ? 'Drop-Off' : 'Pickup'
  }, [isDropOff])

  const editModalTitle = useMemo(() => {
    return isDropOff ? 'Edit Drop-off Address' : 'Edit Pickup Address'
  }, [isDropOff])

  const onEditSubmit = useCallback(() => {
    onSubmit({
      ...formValues,
      contactNumber: parseNumber(formValues.contactNumber).toString()
    })
  }, [formValues])

  return (
    <ConfirmationModal
      isOpen={isOpen}
      setIsOpen={setOpen}
      sx={{ minWidth: '300px' }}
      header={
        <Grid item>
          <Typography variant='h6'>{editModalTitle}</Typography>
        </Grid>
      }
      submit={{
        text: 'Save',
        disabled: !isValid,
        callback: onEditSubmit
      }}
      cancel={{
        text: 'Cancel'
      }}
    >
      <AddressEditForm
        initialValues={address}
        addressType={addressType}
        setIsValid={setIsValid}
        setFormValues={setFormValues}
      />
    </ConfirmationModal>
  )
}
