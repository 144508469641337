import * as Yup from 'yup'
import {
  countOfNumbers,
  latinWithSpecial,
  notOnlyZeroes
} from '../../../../utils/validators'
import { FIELD_NAMES, FIELD_ERRORS } from './constants'

const optionSchema = {
  name: Yup.string(),
  id: Yup.string()
}

const conditionalCobuyerValidator = (error: string) =>
  Yup.string().when([FIELD_NAMES.ADD_COBUYER_TOGGLE], {
    is: false,
    then: Yup.string()
      .required('')
      .trim()
      .test('is-latin-or-special', error, latinWithSpecial([' ']))
      .nullable(),
    otherwise: Yup.string()
  })

export const validationSchema = Yup.object({
  [FIELD_NAMES.ADD_COBUYER_TOGGLE]: Yup.boolean(),
  [FIELD_NAMES.COBUYER_EMAIL]: Yup.object(optionSchema).required(),
  [FIELD_NAMES.COBUYER_NAME]: conditionalCobuyerValidator(
    FIELD_ERRORS.COBUYER_NAME
  ),
  [FIELD_NAMES.COBUYER_ADDRESS]: conditionalCobuyerValidator(
    FIELD_ERRORS.COBUYER_ADDRESS
  ),
  [FIELD_NAMES.ZIP_CODE]: Yup.string().when([FIELD_NAMES.ADD_COBUYER_TOGGLE], {
    is: false,
    then: Yup.string()
      .required('')
      .trim()
      .test('length', FIELD_ERRORS.ZIP_CODE, countOfNumbers(5))
      .test('not-only-zeroes', FIELD_ERRORS.ZIP_CODE, notOnlyZeroes()),
    otherwise: Yup.string()
  }),
  [FIELD_NAMES.CITY]: Yup.string().required(''),
  [FIELD_NAMES.STATE]: Yup.string().required('')
})
