export const formatPhoneNumber = (
  value: string,
  mask: string = '(***) *** - ****'
): string => {
  const phone = value.startsWith('+') ? value.slice(2) : value

  return phone.split('').reduce((acc, char) => {
    return acc.replace('*', char)
  }, mask)
}
