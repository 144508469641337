import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Button, Grid, Typography } from '@mui/material'
import {
    Dispatch,
    FC,
    MouseEventHandler,
    SetStateAction,
    useCallback,
    useState
} from 'react'

import { NoMembers } from '../../../../assets/imgs/NoMembers'
import { AgreeButton, CancelButton } from '../../../../atoms/Button'
import { Condition } from '../../../../atoms/Condition'
import { COLORS, SHADOWS } from '../../../../types/colors'
import { UserStatusMap } from '../../../../types/status'
import { AccountMember, Member } from '../../../AccountMember'
import { ResultScreen } from '../../../ResultScreen'

export type MyTeamListHeaderProps = {
  onAddNewUserClick?: () => void
  isAdmin?: boolean
}

export type MyTeamListBodyProps = {
  members: Member[]
  setMembers: Dispatch<SetStateAction<Member[]>>
  onCloseClick: MouseEventHandler
  onSaveClick: () => Promise<unknown> | void
  isAdmin?: boolean
}

export const MyTeamListHeader: FC<MyTeamListHeaderProps> = (props) => {
  const { isAdmin, onAddNewUserClick } = props

  return (
    <Grid container item xs={10} spacing={1} justifyContent='flex-start'>
      <Grid item>
        <Typography variant='h6'>My Team</Typography>
      </Grid>
      <Condition
        condition={!!isAdmin}
        trueContent={
          <Grid item xs={6} justifyContent='flex-start'>
            <Button
              variant='text'
              disableElevation
              startIcon={<AddCircleOutlineIcon />}
              sx={{ whiteSpace: 'nowrap' }}
              onClick={onAddNewUserClick}
            >
              Add New User
            </Button>
          </Grid>
        }
      />
    </Grid>
  )
}

export const MyTeamListBody: FC<MyTeamListBodyProps> = (props) => {
  const { members, setMembers, isAdmin, onCloseClick, onSaveClick } = props
  const [isDeleteTriggered, setDeleteTriggered] = useState(false)

  const onUserTypeChange = useCallback(
    (options: { id: string; setAdminRole: boolean }) => {
      setMembers((currentMembers) =>
        currentMembers.map((member) => {
          if (member.id === options.id) {
            return {
              ...member,
              isAdmin: options.setAdminRole,
              status: options.setAdminRole
                ? UserStatusMap.Admin
                : UserStatusMap.User
            }
          }

          return member
        })
      )
    },
    [setMembers]
  )

  const onDeleteClick = useCallback(
    (id: string) => {
      setMembers((currentMembers) =>
        currentMembers.filter((user) => user.id !== id)
      )
      setDeleteTriggered(true)
    },
    [setMembers]
  )
  const buttonBlock = (
    <Grid
      container
      item
      xs={12}
      justifyContent='flex-end'
      spacing={2}
      sx={{ padding: '0 24px 24px 0' }}
    >
      <Grid item>
        <CancelButton onClick={onCloseClick}>Cancel</CancelButton>
      </Grid>
      <Grid item>
        <AgreeButton onClick={onSaveClick}>Save</AgreeButton>
      </Grid>
    </Grid>
  )

  return (
    <Condition
      condition={!!members.length}
      trueContent={
        <>
          <Grid
            container
            p='18px 24px 8px'
            sx={{
              backgroundColor: COLORS.white,
              zIndex: 3,
              borderBottom: `1px solid ${COLORS.border_gray}`,
              boxShadow: SHADOWS.modal
            }}
          >
            <Grid item xs={6}>
              <Typography color={COLORS.text_secondary} fontSize={12}>
                USER
              </Typography>
            </Grid>
            <Grid container item xs={6} justifyContent='flex-end'>
              <Typography color={COLORS.text_secondary} fontSize={12}>
                ADMIN PERMISSIONS
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing='24px'>
            <Grid
              item
              xs={12}
              sx={{
                maxHeight: '320px',
                overflowY: 'scroll',
                borderBottom: `1px solid ${COLORS.border_gray}`,
                boxShadow: SHADOWS.modal
              }}
            >
              {members.map((m) => (
                <AccountMember
                  key={m.id}
                  member={m}
                  isAdminView={isAdmin}
                  onDeleteClick={onDeleteClick}
                  onUserTypeChange={onUserTypeChange}
                />
              ))}
            </Grid>

            <Condition
              condition={!!isAdmin}
              trueContent={buttonBlock}
              falseContent={<Grid container pb={2} />}
            />
          </Grid>
        </>
      }
      falseContent={
        <Grid item xs={12} sx={{ pt: 6, pb: 8 }}>
          <ResultScreen
            inlined
            title=''
            subtitle='There are no other team members yet.'
            icon={<NoMembers />}
          />
          <Condition
            condition={!!isAdmin && !!isDeleteTriggered}
            trueContent={buttonBlock}
          />
        </Grid>
      }
    />
  )
}
