import { Grid, Typography } from '@mui/material'
import { FC, PropsWithChildren } from 'react'
import { COLORS } from '../../types/colors'
import { ConfirmationModal } from '../Modal'
import * as AccountMemberConstants from './constants'

interface ConfirmationsProps {
  fullName: string
  changeMap: {
    isSetToAdmin: boolean
    isOpen: boolean
    setOpen: (open: boolean) => void
    onSubmit: () => void | Promise<void>
  }
  deleteMap: {
    isOpen: boolean
    setOpen: (open: boolean) => void
    onSubmit: () => void | Promise<void>
  }
}

export const MemberDialogConfirmations: FC<
  PropsWithChildren<ConfirmationsProps>
> = ({ fullName, changeMap, deleteMap }) => {
  return (
    <>
      <ConfirmationModal
        isOpen={changeMap.isOpen}
        setIsOpen={changeMap.setOpen}
        cancel={{
          text: 'cancel'
        }}
        submit={{
          text: 'confirm',
          callback: changeMap.onSubmit
        }}
      >
        <Grid container justifyContent='center' sx={{ pt: 4, pb: 2, px: 8 }}>
          <Typography variant='body1' textAlign='center'>
            {changeMap.isSetToAdmin
              ? AccountMemberConstants.toAdmin(fullName)
              : AccountMemberConstants.fromAdmin(fullName)}
          </Typography>
        </Grid>
      </ConfirmationModal>
      <ConfirmationModal
        isOpen={deleteMap.isOpen}
        setIsOpen={deleteMap.setOpen}
        header={
          <Typography variant='h6' color={COLORS.text_red}>
            Delete
          </Typography>
        }
        cancel={{
          text: 'Cancel'
        }}
        submit={{
          text: 'Delete',
          callback: deleteMap.onSubmit
        }}
      >
        <Grid
          container
          rowSpacing={3}
          flexDirection='column'
          sx={{ pt: 4, pb: 2, px: 8 }}
        >
          <Grid item>
            <Typography variant='body1' textAlign='center'>
              {AccountMemberConstants.deleteUserConfirmationText.message}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='subtitle2' textAlign='center'>
              {AccountMemberConstants.deleteUserConfirmationText.hint}
            </Typography>
          </Grid>
        </Grid>
      </ConfirmationModal>
    </>
  )
}
