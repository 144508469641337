import * as Yup from 'yup'
import {
  countOfNumbers,
  notOnlyZeroes,
  latinWithSpecial
} from '../../../../../../../../utils/validators'
import { BUYER_FIELDS_ERRORS, BUYER_FIELDS_NAMES } from './types'

const optionalNameValidationPattern = Yup.string()
  .trim()
  .test(
    'is-latin-or-special',
    BUYER_FIELDS_ERRORS.NAME,
    latinWithSpecial([' '])
  )

const nameValidationPattern = optionalNameValidationPattern.required('')

const optionalAddressValidationPattern = Yup.string()
  .trim()
  .test(
    'is-latin-or-special',
    BUYER_FIELDS_ERRORS.ADDRESS,
    latinWithSpecial([' '])
  )
  .nullable()

const addressValidationPattern = optionalAddressValidationPattern.required('')

const optionalZipCodeValidationPattern = Yup.string()
  .trim()
  .test('length', BUYER_FIELDS_ERRORS.ZIP_CODE, countOfNumbers(5))
  .test('not-only-zeroes', BUYER_FIELDS_ERRORS.ZIP_CODE, notOnlyZeroes())

const zipCodeValidationPattern = optionalZipCodeValidationPattern.required('')

const optionalCityValidationPattern = Yup.string()

const cityValidationPattern = optionalCityValidationPattern.required('')

const optionalStateValidationPattern = Yup.string()

const stateValidationPattern = optionalStateValidationPattern.required('')

export const getValidationSchema = (includeCoBuyer: boolean) =>
  Yup.object({
    [BUYER_FIELDS_NAMES.NAME]: nameValidationPattern,
    [BUYER_FIELDS_NAMES.ADDRESS]: addressValidationPattern,
    [BUYER_FIELDS_NAMES.ZIP_CODE]: zipCodeValidationPattern,
    [BUYER_FIELDS_NAMES.CITY]: cityValidationPattern,
    [BUYER_FIELDS_NAMES.STATE]: stateValidationPattern,
    [BUYER_FIELDS_NAMES.CO_BUYER_NAME]: includeCoBuyer
      ? nameValidationPattern
      : optionalNameValidationPattern,
    [BUYER_FIELDS_NAMES.CO_BUYER_ADDRESS]: includeCoBuyer
      ? addressValidationPattern
      : optionalAddressValidationPattern,
    [BUYER_FIELDS_NAMES.CO_BUYER_ZIP_CODE]: includeCoBuyer
      ? zipCodeValidationPattern
      : optionalZipCodeValidationPattern,
    [BUYER_FIELDS_NAMES.CO_BUYER_CITY]: includeCoBuyer
      ? cityValidationPattern
      : optionalCityValidationPattern,
    [BUYER_FIELDS_NAMES.CO_BUYER_STATE]: includeCoBuyer
      ? stateValidationPattern
      : optionalStateValidationPattern
  })
