import { CollapsibleNotes } from '../../../../atoms/CollapsibleNotes'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const EmptySellerNotesExample = () => {
  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <CollapsibleNotes> </CollapsibleNotes>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}

export const FilledSellerNotesExample = () => {
  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <CollapsibleNotes>
          Honda Odyssey EX now available. This particular model comes with Up to
          60.1 MPG, 16 inch alloy wheels, SEAT logo boot release, Electric
          headlight adjustment, Tinted glass, Leather steering wheel and gear
          knob and more! Buying your next car has never been easier! Our sites
          across Scotland Electric headlight adjustment, Tinted glass, Leather
          steering wheel and gear knob and more! Buying your next car has never
          been easier! Our sites across Scotland Honda Odyssey EX now available.
        </CollapsibleNotes>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
