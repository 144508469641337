import { ApiOptions } from './types'
import { MessageResponse, RequestRecord } from './private.types'
import { ResponseStatusMap } from '../types/status'

type SuccessResponse<T> = {
  type: 'success'
  payload: T
  status: number
}

type ErrorResponse<Q> = {
  type: 'error'
  payload: Q
  status: number
}

export class Utils {
  private readonly endpoint: string
  private readonly token: string
  private session: string | undefined
  private readonly logoutCallback: () => void

  constructor(
    options: Required<Omit<ApiOptions, 'session'>> & { session?: string }
  ) {
    this.endpoint = options.endpoint
    this.token = options.token
    this.session = options.session
    this.logoutCallback = options.logoutCallback
  }

  // --- Utils ---

  setSession(session: string) {
    this.session = session
  }

  async makePostRequest(
    operation: string,
    record: RequestRecord = {}
  ): Promise<Response> {
    const headers = {
      'Content-Type': 'application/json',
      'x-api-key': this.token,
      ...(this.session && { 'x-session-id': this.session })
    }

    const body = JSON.stringify({ operation, record })

    const response = await fetch(this.endpoint, {
      method: 'POST',
      headers,
      body
    })

    if (response.status === 401) {
      this.session = undefined
      this.logoutCallback()
    }

    return response
  }

  async makeJSONRequest<T, Q extends { id?: string; message: string }>(
    operation: string,
    record: RequestRecord = {}
  ): Promise<SuccessResponse<T> | ErrorResponse<Q>> {
    const response = await this.makePostRequest(operation, record)
    const { status } = response
    const payload = await response.json()

    if (status >= 500) {
      return {
        status,
        type: 'error',
        payload: {
          id: '',
          message: payload.message
        } as unknown as Q
      }
    }

    if (status >= 300) {
      return {
        status,
        type: 'error',
        payload
      }
    }

    return {
      status,
      type: 'success',
      payload
    }
  }

  async sendFileRequest(options: {
    url: string
    file: File
  }): Promise<
    SuccessResponse<MessageResponse> | ErrorResponse<MessageResponse>
  > {
    const { url, file } = options

    const response = await fetch(url, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type,
        'x-api-key': this.token,
        ...(this.session && { 'x-session-id': this.session })
      }
    })

    if (response.status - 300 >= 0) {
      return {
        type: ResponseStatusMap.Error,
        status: response.status,
        payload: {
          message: 'File upload failed!'
        }
      }
    }

    return {
      type: 'success',
      status: response.status,
      payload: {
        message: 'File uploaded successfully!'
      }
    }
  }
}
