import { styled } from '@mui/material'
import { COLORS } from '../../types/colors'

export const InputsContainer = styled('div')`
  display: grid;
  grid-template-columns: 2fr 1fr 136px 24px;
  grid-template-areas: 'description price amount button';
  align-items: start;
  height: 78px;
  gap: 8px;
`

export const DescriptionField = styled('div')`
  grid-area: description;
`

export const PriceField = styled('div')`
  grid-area: price;
`

export const AmountField = styled('div')`
  grid-area: amount;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${COLORS.border_gray};
  border-radius: 3px;
  height: 60px;
`

export const ButtonField = styled('div')`
  margin-top: 14px;
  grid-area: button;
  &:hover {
    background-color: rgba(25, 118, 210, 0.04);
    cursor: pointer;
  }
`
