import { Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { InformationLabel } from '../../../../../../atoms/InformationLabel'
import { Show } from '../../../../../../atoms/JSXExtensions'
import { LabelWithCustomValue } from '../../../../../../atoms/LabelWithCustomValue'
import { formatPrice } from '../../../../../../utils'
import { DetailsContainer } from '../../components/DetailsContainer'

interface PaymentDetailsProps {
  totalPrice: number
  paymentMethod: string
  showTooltip?: boolean
}

export const PaymentDetails: FC<PaymentDetailsProps> = ({
  totalPrice,
  paymentMethod,
  showTooltip = false
}) => {
  return (
    <DetailsContainer title='Payment details'>
      <Grid item>
        <LabelWithCustomValue
          title='Total price'
          value={
            <Typography variant='title1'>
              {formatPrice(totalPrice, { includeDecimalPart: true })}
            </Typography>
          }
        />
      </Grid>
      <Grid item mt='46px'>
        <LabelWithCustomValue
          title='Payment method'
          value={<Typography variant='label1'>{paymentMethod}</Typography>}
        />
      </Grid>
      <Show when={showTooltip}>
        <Grid item mt='46px'>
          <InformationLabel labelText='When the Seller confirms the payment, we`ll be able to ship the vehicle.' />
        </Grid>
      </Show>
    </DetailsContainer>
  )
}
