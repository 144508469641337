import { useApiContext, useMakeService } from '../../../utils/hooks'

export const useDeleteImage = (
  vehicleId: string,
  onLoadCallback?: () => void
) => {
  const { carSnoopApi } = useApiContext()

  const [deleteVehiclePhoto, deleteVehiclePhotoObservable] = useMakeService(
    async ({ id, type }: { id: string; type: string }) => {
      const res = await carSnoopApi.vehicles.deleteVehiclePhoto({
        vehicleId,
        id,
        type
      })

      onLoadCallback?.()
      return res
    },
    {
      withStatusNotification: true
    }
  )

  return [deleteVehiclePhotoObservable, deleteVehiclePhoto] as [
    typeof deleteVehiclePhotoObservable,
    typeof deleteVehiclePhoto
  ]
}
