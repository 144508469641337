import { Grid } from '@mui/material'
import { FC } from 'react'
import { DefaultEmptyState } from '../../../../atoms/DefaultEmptyState'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const DefaultEmptyStateExample: FC = () => {
  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <Grid container flexDirection='column'>
          <Grid item>
            <DefaultEmptyState text='Custom text' />
          </Grid>
          <Grid item>
            <DefaultEmptyState
              imageHeight='170'
              imageWidth='280'
              text='Custom size'
            />
          </Grid>
        </Grid>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
