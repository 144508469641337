import { FC } from 'react'
import { Grid } from '@mui/material'
import { ErrorScreen } from '../../../../molecules/ErrorScreen'
import { EMAIL_NOT_VERIFIED_ERROR } from '../constants'
import { ActionBlock } from '../../../../atoms/ActionBlock'

export interface ContactDetailsErrorScreenProps {
  onBackClick: () => void
  onResendClick: () => void
}

export const NotVerifiedScreenScreen: FC<ContactDetailsErrorScreenProps> = (
  props
) => {
  const { onBackClick, onResendClick } = props
  return (
    <ErrorScreen
      title={EMAIL_NOT_VERIFIED_ERROR.TITLE}
      subtitle={EMAIL_NOT_VERIFIED_ERROR.SUBTITLE}
      description={EMAIL_NOT_VERIFIED_ERROR.DESCRIPTION}
      inlined
    >
      <Grid item container sx={{ pt: 4 }}>
        <Grid item container justifyContent='space-between'>
          <ActionBlock
            schema={[
              {
                label: 'Cancel',
                type: 'secondary',
                onClick: onBackClick
              },
              {
                label: 'Resend',
                type: 'primary',
                onClick: onResendClick
              }
            ]}
          />
        </Grid>
      </Grid>
    </ErrorScreen>
  )
}
