import { FC, useCallback } from 'react'
import { Grid, Typography } from '@mui/material'
import { Formik, Form } from 'formik'
import { TextInputFieldNew } from '../../../../../../../../atoms/TextInputFieldNew'
import { Show } from '../../../../../../../../atoms/JSXExtensions'
import { CancelButton, AgreeButton } from '../../../../../../../../atoms/Button'
import {
  DependentBuyerZipCodeField,
  DependentCoBuyerZipCodeField,
  DependentZipCodeFieldProps
} from './dependentFields'
import {
  BuyerFormFieldsTypes,
  BUYER_FIELDS_NAMES,
  BUYER_FIELDS_LABELS,
  BUYER_FIELDS_ERRORS
} from './types'
import { useCheckZipCode } from '../../../../../../../../utils/hooks/useCheckZipCode'
import { COLORS } from '../../../../../../../../types/colors'
import { zipCodeMask } from '../../../../../../../../constants/regexp'
import { getValidationSchema } from './validationSchema'
import { ResponseStatusMap } from '../../../../../../../../types/status'
import { DependentFieldHocEffect } from '../../../../../../../../atoms/DependentField'
import { NormalizedContractType } from '../../../../../../../../api/carSnoopApi/transactions/types'
import { isRequiredFormField } from '../../../../../../../../utils/validators/isRequiredFormField'
import { RequiredLabel } from '../../../../../../../../atoms/RequiredText'

export interface EditModeProps {
  buyerDetails: NormalizedContractType['buyerDetails']
  coBuyerDetails: NormalizedContractType['additionalBuyerDetails']
  onCancelClick: () => void
  onSaveClick: (data: BuyerFormFieldsTypes) => void
}

export const EditMode: FC<EditModeProps> = (props) => {
  const { buyerDetails, coBuyerDetails, onCancelClick, onSaveClick } = props

  const {
    legalName: buyerLegalName,
    legalStreet: buyerLegalAddress,
    legalZipCode: buyerZipCode,
    legalCity: buyerCity,
    legalState: buyerState
  } = buyerDetails

  const { checkZipCode, checkZipCodeObservable } = useCheckZipCode()
  const {
    checkZipCode: checkCoBuyerZipCode,
    checkZipCodeObservable: checkCoBuyerZipCodeObservable
  } = useCheckZipCode()

  const validationSchema = getValidationSchema(!!coBuyerDetails)
  const isRequiredField = isRequiredFormField(validationSchema)

  const buyerZipCodeFieldEffect: DependentFieldHocEffect<
    DependentZipCodeFieldProps,
    BuyerFormFieldsTypes
  > = useCallback(async (_, ctx) => {
    const {
      values: formValues,
      setFieldValue,
      validateForm,
      setFieldError,
      setValues
    } = ctx
    const { buyerZipCode: zipCode } = formValues
    setFieldValue(BUYER_FIELDS_NAMES.CITY, '')
    setFieldValue(BUYER_FIELDS_NAMES.STATE, '')

    if (zipCode) {
      const response = await checkZipCode(zipCode)

      if (response.status === ResponseStatusMap.Success) {
        setValues(
          {
            ...formValues,
            [BUYER_FIELDS_NAMES.CITY]: response.city,
            [BUYER_FIELDS_NAMES.STATE]: response.state
          },
          true
        )
      } else {
        validateForm().then(() => {
          setFieldError(
            BUYER_FIELDS_NAMES.ZIP_CODE,
            BUYER_FIELDS_ERRORS.ZIP_CODE
          )
        })
      }
    }
  }, [])

  const coBuyerZipCodeFieldEffect: DependentFieldHocEffect<
    DependentZipCodeFieldProps,
    BuyerFormFieldsTypes
  > = useCallback(async (_, ctx) => {
    const {
      values: formValues,
      setFieldValue,
      validateForm,
      setFieldError,
      setValues
    } = ctx
    const { coBuyerZipCode: zipCode } = formValues
    setFieldValue(BUYER_FIELDS_NAMES.CO_BUYER_CITY, '')
    setFieldValue(BUYER_FIELDS_NAMES.CO_BUYER_STATE, '')

    if (zipCode) {
      const response = await checkCoBuyerZipCode(zipCode)

      if (response.status === ResponseStatusMap.Success) {
        setValues(
          {
            ...formValues,
            [BUYER_FIELDS_NAMES.CO_BUYER_CITY]: response.city,
            [BUYER_FIELDS_NAMES.CO_BUYER_STATE]: response.state
          },
          true
        )
      } else {
        validateForm().then(() => {
          setFieldError(
            BUYER_FIELDS_NAMES.CO_BUYER_ZIP_CODE,
            BUYER_FIELDS_ERRORS.ZIP_CODE
          )
        })
      }
    }
  }, [])

  return (
    <Grid item>
      <Grid item mb={2}>
        <RequiredLabel marginTop={0} />
      </Grid>
      <Formik<BuyerFormFieldsTypes>
        onSubmit={onSaveClick}
        initialValues={{
          buyerLegalName,
          buyerLegalAddress,
          buyerZipCode,
          buyerCity,
          buyerState,
          coBuyerLegalName: coBuyerDetails?.legalName || '',
          coBuyerLegalAddress: coBuyerDetails?.legalStreet || '',
          coBuyerZipCode: coBuyerDetails?.legalZipCode || '',
          coBuyerCity: coBuyerDetails?.legalCity || '',
          coBuyerState: coBuyerDetails?.legalState || ''
        }}
        validateOnChange={false}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Form>
            <Grid container item xs={12} flexDirection='column' spacing={2}>
              <Grid item>
                <TextInputFieldNew
                  name={BUYER_FIELDS_NAMES.NAME}
                  label={BUYER_FIELDS_LABELS.NAME}
                  backgroundColor={COLORS.white}
                  required={isRequiredField}
                />
              </Grid>
              <Grid item>
                <TextInputFieldNew
                  name={BUYER_FIELDS_NAMES.ADDRESS}
                  label={BUYER_FIELDS_LABELS.ADDRESS}
                  backgroundColor={COLORS.white}
                  required={isRequiredField}
                />
              </Grid>
              <Grid container item justifyContent='space-between' spacing={2}>
                <Grid item xs={5}>
                  <DependentBuyerZipCodeField
                    name={BUYER_FIELDS_NAMES.ZIP_CODE}
                    label={BUYER_FIELDS_LABELS.ZIP_CODE}
                    mask={zipCodeMask}
                    onDoneEffect={buyerZipCodeFieldEffect}
                    backgroundColor={COLORS.white}
                    required={isRequiredField}
                  />
                </Grid>
                <Grid item xs={3.5}>
                  <TextInputFieldNew
                    name={BUYER_FIELDS_NAMES.CITY}
                    label={BUYER_FIELDS_LABELS.CITY}
                    disabled
                    isLoading={checkZipCodeObservable.isLoading}
                    required={isRequiredField}
                  />
                </Grid>
                <Grid item xs={3.5}>
                  <TextInputFieldNew
                    name={BUYER_FIELDS_NAMES.STATE}
                    label={BUYER_FIELDS_LABELS.STATE}
                    disabled
                    isLoading={checkZipCodeObservable.isLoading}
                    required={isRequiredField}
                  />
                </Grid>
              </Grid>
              <Show when={coBuyerDetails}>
                <>
                  <Grid item my={2}>
                    <Typography variant='badge' fontSize={18}>
                      Co-Buyer’s details
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextInputFieldNew
                      name={BUYER_FIELDS_NAMES.CO_BUYER_NAME}
                      label={BUYER_FIELDS_LABELS.NAME}
                      backgroundColor={COLORS.white}
                      required={isRequiredField}
                    />
                  </Grid>
                  <Grid item>
                    <TextInputFieldNew
                      name={BUYER_FIELDS_NAMES.CO_BUYER_ADDRESS}
                      label={BUYER_FIELDS_LABELS.ADDRESS}
                      backgroundColor={COLORS.white}
                      required={isRequiredField}
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    justifyContent='space-between'
                    spacing={2}
                  >
                    <Grid item xs={5}>
                      <DependentCoBuyerZipCodeField
                        name={BUYER_FIELDS_NAMES.CO_BUYER_ZIP_CODE}
                        label={BUYER_FIELDS_LABELS.ZIP_CODE}
                        mask={zipCodeMask}
                        onDoneEffect={coBuyerZipCodeFieldEffect}
                        backgroundColor={COLORS.white}
                        required={isRequiredField}
                      />
                    </Grid>
                    <Grid item xs={3.5}>
                      <TextInputFieldNew
                        name={BUYER_FIELDS_NAMES.CO_BUYER_CITY}
                        label={BUYER_FIELDS_LABELS.CITY}
                        disabled
                        isLoading={checkCoBuyerZipCodeObservable.isLoading}
                        required={isRequiredField}
                      />
                    </Grid>
                    <Grid item xs={3.5}>
                      <TextInputFieldNew
                        name={BUYER_FIELDS_NAMES.CO_BUYER_STATE}
                        label={BUYER_FIELDS_LABELS.STATE}
                        disabled
                        isLoading={checkCoBuyerZipCodeObservable.isLoading}
                        required={isRequiredField}
                      />
                    </Grid>
                  </Grid>
                </>
              </Show>
              <Grid
                container
                item
                xs={12}
                justifyContent='space-between'
                mt={2}
              >
                <Grid item>
                  <CancelButton
                    onClick={onCancelClick}
                    sx={{ background: COLORS.white }}
                  >
                    Cancel
                  </CancelButton>
                </Grid>
                <Grid item>
                  <AgreeButton
                    type='submit'
                    disabled={
                      !formik.dirty ||
                      !formik.isValid ||
                      checkZipCodeObservable.isLoading ||
                      checkCoBuyerZipCodeObservable.isLoading
                    }
                  >
                    Save
                  </AgreeButton>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  )
}
