import { GetSynchronizedDataTypes } from '../types'

export const synchronizedDataNormalizer = (
  rawResponse: GetSynchronizedDataTypes.ApiSuccessRawResponse
): GetSynchronizedDataTypes.ApiSuccessNormalizedResponse => {
  const { subscription } = rawResponse

  const normalized: GetSynchronizedDataTypes.ApiSuccessNormalizedResponse = {
    subscription: {
      name: subscription.name,
      completedDeals: subscription.completed_deals,
      maxDeals: subscription.max_deals,
      price: subscription.price
    }
  }

  return normalized
}
