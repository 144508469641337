import { ERROR_REASONS } from '../../../types/enums/network'
import { ResponseStatusMap } from '../../../types/status'
import { Utils } from '../../utils'
import { PaymentApi } from './types'

export class Payment {
  protected readonly utils: Utils
  protected readonly operations = {
    createPaymentCheckout: 'createPaymentCheckout',
    confirmPayment: 'confirmPayment'
  }

  constructor(utils: Utils) {
    this.utils = utils
  }

  async createPaymentCheckout(
    params: PaymentApi.CreatePaymentCheckout.Params
  ): Promise<PaymentApi.CreatePaymentCheckout.MethodResponse> {
    type SuccessResponse = PaymentApi.CreatePaymentCheckout.ApiSuccessResponse
    type ErrorResponse = PaymentApi.CreatePaymentCheckout.ApiErrorResponse

    const { transactionId, successUrl, cancelUrl, items } = params

    const dataToSend = {
      transaction_id: transactionId,
      success_url: successUrl,
      cancel_url: cancelUrl,
      items: items.map((i) => ({
        amount: i.amount,
        product_name: i.productName,
        product_description: i.productDescription
      }))
    }

    const { type, payload } = await this.utils.makeJSONRequest<
      SuccessResponse,
      ErrorResponse
    >(this.operations.createPaymentCheckout, dataToSend)

    if (type === ResponseStatusMap.Success) {
      const { payment_url: paymentUrl, session_id: sessionId } = payload
      return {
        status: ResponseStatusMap.Success,
        paymentUrl,
        sessionId
      }
    }

    const { id, message } = payload
    return {
      status: ResponseStatusMap.Error,
      id,
      message,
      isSubscriptionError: payload.reason === ERROR_REASONS.INVALID_SUBSCRIPTION
    }
  }

  async confirmPayment({
    id,
    paymentReference = ''
  }: PaymentApi.ConfirmPayment.Params): Promise<PaymentApi.ConfirmPayment.MethodResponse> {
    type SuccessResponse = PaymentApi.ConfirmPayment.ApiSuccessResponse
    type ErrorResponse = PaymentApi.ConfirmPayment.ApiErrorResponse
    try {
      const { type, payload } = await this.utils.makeJSONRequest<
        SuccessResponse,
        ErrorResponse
      >(this.operations.confirmPayment, {
        transaction_id: id,
        session_id: paymentReference
      })

      if (type === ResponseStatusMap.Success) {
        return {
          status: ResponseStatusMap.Success,
          paymentStatus: payload.status,
          transactionID: payload.transaction_id
        }
      }

      return {
        status: ResponseStatusMap.Error,
        message: payload.message
      }
    } catch (e) {
      return {
        status: ResponseStatusMap.Error,
        message: 'Something went wrong'
      }
    }
  }
}
