import { Button, Grid } from '@mui/material'
import { VideoPlayer } from '../../../../atoms/VideoPlayer'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

const TEST_VIDEO_LINK =
  'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'

export const VideoPlayerExample = () => {
  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <Grid container>
          <Grid item>
            <VideoPlayer src={TEST_VIDEO_LINK}>
              <Button>Open player</Button>
            </VideoPlayer>
          </Grid>
        </Grid>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
