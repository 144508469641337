import type { Option } from '../../../../types/Autoselect'
import { stringToReferenceModel } from '../../../../utils/autoselect/stringToReferenceModel'
import type { ReadModelsResponse } from '../thunks'

export const modelsResponseToOption = (
  response: ReadModelsResponse
): Option[] => {
  const { data } = response
  return data.map((maker) => stringToReferenceModel(maker.model))
}
