import { ListItemIcon } from '@mui/material';
import { NestedMenuItem } from 'mui-nested-menu';
import { FC, PropsWithChildren } from 'react';
import { MenuArrowIcon } from '../../../../../../assets/icons/MenuArrowIcon';
import { COLORS } from '../../../../../../types/colors';
import {
  leftIconStyle,
  menuItemStyle,
  menuPaperStyle
} from './AdditionalMenuNestedItem.styles';

interface AdditionalMenuNestedItemProps {
  label: string
  icon: JSX.Element
  isOpen: boolean
}

export const AdditionalMenuNestedItem: FC<
  PropsWithChildren<AdditionalMenuNestedItemProps>
> = ({ isOpen, label, icon, children }) => {
  return (
    <NestedMenuItem
      label={label}
      rightIcon={<ListItemIcon>{icon}</ListItemIcon>}
      leftIcon={
        <ListItemIcon sx={leftIconStyle}>
          <MenuArrowIcon color={COLORS.text_primary} />
        </ListItemIcon>
      }
      parentMenuOpen={isOpen}
      sx={menuItemStyle}
      MenuProps={{
        anchorOrigin: { horizontal: 'left', vertical: 'top' },
        transformOrigin: { vertical: 42, horizontal: 220 },
        transitionDuration: 10,
        PaperProps: {
          elevation: 0,
          sx: menuPaperStyle
        }
      }}
    >
      {children}
    </NestedMenuItem>
  )
}
