import { Grid } from '@mui/material'
import { FC } from 'react'
import { VehicleDetails } from '../VehicleDetails/components/VehicleDetails'

export const PublicVehicleDetailsPage: FC = () => {
  return (
    <Grid justifyContent='center' padding={3}>
      <VehicleDetails />
    </Grid>
  )
}
