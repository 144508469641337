import { Grid } from '@mui/material'
import { FieldArray } from 'formik'
import { FC } from 'react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { BlueButton } from '../../atoms/Button'
import { AddOn } from './AddOn'
import { DEFAULT_ADDON_VALUE } from './AddOn.constants'
import { IAddOnFormValues } from './AddOn.types'
import { AutoFormValidator } from '../../atoms/AutoFormValidator'

interface IAddOnsArray {
  name: string
  values: IAddOnFormValues[]
  onBlur?: () => void
}

export const AddOnsArray: FC<IAddOnsArray> = ({ values, onBlur, name }) => {
  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <>
          <AutoFormValidator dependencies={[values.length]} />
          {values.map((addOnValues: IAddOnFormValues, index: number) => (
            <AddOn
              fieldGroupName={name}
              index={index}
              onRemoveAddOn={arrayHelpers.remove}
              key={addOnValues.id}
              onBlur={onBlur}
            />
          ))}
          <Grid item marginTop={1}>
            <BlueButton
              onClick={() => {
                arrayHelpers.push({
                  ...DEFAULT_ADDON_VALUE,
                  id: values.length
                })
              }}
              startIcon={<AddCircleOutlineIcon />}
            >
              Add-ons
            </BlueButton>
          </Grid>
        </>
      )}
    />
  )
}
