import { Grid, Typography } from '@mui/material'
import { FC, useCallback, useMemo } from 'react'
import { OfficeIcon } from '../../../../../../../assets/icons/OfficeIcon'
import { PenIcon } from '../../../../../../../assets/icons/Pen'
import { BlueButton } from '../../../../../../../atoms/Button'
import { Condition } from '../../../../../../../atoms/Condition'
import { EllipsisText } from '../../../../../../../atoms/EllipsisText'
import { IconText } from '../../../../../../../atoms/IconText'
import type { Address } from '../../../../../../../store/types'
import { COLORS } from '../../../../../../../types/colors'
import { returnBuyerAddressString } from '../../../../../../../utils/formatters/returnBuyerAddressString'

export interface ViewModeProps {
  address: Address
  isUserAdmin?: boolean
  onEditClick: (address: Address) => void
}

export const ViewMode: FC<ViewModeProps> = (props) => {
  const { address, isUserAdmin, onEditClick } = props
  const { id, nickname, city, state, street, zipCode } = address

  const handleEditClick = useCallback(() => {
    onEditClick(address)
  }, [onEditClick, id])

  const addressString = useMemo(
    () => returnBuyerAddressString({ city, state, street, zipCode }),
    [city, state, street, zipCode]
  )

  return (
    <Grid container item spacing={3}>
      <Grid
        container
        item
        xs={12}
        justifyContent='space-between'
        alignItems='center'
        wrap='nowrap'
      >
        <Grid item xs={isUserAdmin ? 8 : 12} sx={{ mr: 2 }}>
          <IconText
            flexWrap='nowrap'
            icon={<OfficeIcon fill={COLORS.active_yellow} />}
          >
            <Typography variant='label1'>
              <EllipsisText tooltipText={nickname}>{nickname}</EllipsisText>
            </Typography>
          </IconText>
        </Grid>
        <Condition
          condition={!!isUserAdmin}
          trueContent={
            <BlueButton onClick={handleEditClick}>
              <PenIcon />
            </BlueButton>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='main'>{addressString}</Typography>
      </Grid>
    </Grid>
  )
}
