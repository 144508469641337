import { Button } from '@mui/material'
import { useState } from 'react'
import { Modal } from '../../../../molecules/Modal'
import { Member } from '../../../../molecules/AccountMember'
import { MyTeamCard } from '../../../../molecules/MyTeamCard'
import { useMyTeamCard } from '../../../../molecules/MyTeamCard/hooks'
import { MY_TEAM_MODE } from '../../../../molecules/MyTeamCard/types'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'
import { member } from '../AccountMemberExample'

export const mockedMembers: Member[] = [
  { ...member, status: 'Admin', id: '1@mail.com' },
  member,
  { ...member, status: 'Pending', id: '2@mail.com' }
]

export const MyTeamCardModalExample = () => {
  const [open, setOpen] = useState(false)
  const [members, setMembers] = useState(mockedMembers)
  const { mode, setMode } = useMyTeamCard()

  const handleSaveClick = () => {
    // eslint-disable-next-line
    console.log('>>>', 'SAVE')
  }

  const handleSwitchModeClick = () => {
    if (mode === MY_TEAM_MODE.LIST) {
      setMode(MY_TEAM_MODE.ADDING)
    } else {
      setMode(MY_TEAM_MODE.LIST)
    }
  }
  const handleAddNewUserClick = () => {
    setMode(MY_TEAM_MODE.ADDING)
  }

  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <Modal open={open}>
          <MyTeamCard
            setMode={setMode}
            mode={mode}
            members={members}
            setMembers={setMembers}
            isAdmin
            onAddNewUserClick={handleAddNewUserClick}
            onCloseClick={() => setOpen(false)}
            onSaveClick={handleSaveClick}
          />
        </Modal>
        <Button onClick={() => setOpen(true)}>Open modal</Button>
        <Button onClick={handleSwitchModeClick}>Switch mode</Button>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
