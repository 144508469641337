import { Grid } from '@mui/material'
import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef
} from 'react'
import { Divider } from '../../../../atoms/Divider'
import { Controls } from './components/Controls'
import { UploadFileComponent } from '../../../../molecules/UploadFileComponent'
import { CompressImageSuccessResponse } from '../../../../utils/compressImages'

interface ChatContainerProps {
  onSend: (value: string) => Promise<unknown>
  onFileSend: (file: CompressImageSuccessResponse) => void
  isUploadInProgress: boolean
  chatId: string
  height?: string
}

export const ChatContainer: FC<PropsWithChildren<ChatContainerProps>> = ({
  onSend,
  children,
  height = '100%',
  onFileSend,
  isUploadInProgress,
  chatId
}) => {
  const fileInputRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const scrollPosition = useRef(0)

  const handleFileSend = useCallback(() => {
    fileInputRef.current?.click()
  }, [])

  const scrolltoBottom = useCallback(() => {
    if (!containerRef.current) {
      return
    }
    const container = containerRef.current
    container.scrollTo(0, container.scrollHeight)
  }, [containerRef])

  const handleMessageSend = useCallback(
    async (params: Parameters<typeof onSend>[0]) => {
      await onSend(params)
      scrolltoBottom()
    },
    [scrolltoBottom]
  )

  useEffect(() => {
    if (!containerRef.current) {
      return
    }
    const onScroll = (e: Event) => {
      const target = e.target as HTMLElement
      const { scrollHeight, scrollTop, offsetHeight } = target
      scrollPosition.current = scrollHeight - scrollTop - offsetHeight
    }

    containerRef.current.addEventListener('scroll', onScroll)

    return () => {
      if (!containerRef.current) {
        return
      }
      containerRef.current.removeEventListener('scroll', onScroll)
    }
  }, [])

  useLayoutEffect(() => {
    if (containerRef.current) {
      const isScrolledBottom = scrollPosition.current === 0

      isScrolledBottom && scrolltoBottom()
    }
  }, [containerRef.current?.scrollHeight])

  return (
    <Grid
      container
      flexDirection='column'
      spacing={2}
      wrap='nowrap'
      height={height}
    >
      <Grid
        item
        sx={{
          overflowY: 'auto',
          '-webkit-overflow-scrolling': 'touch',
          height: '100%'
        }}
        flexGrow={1}
        ref={containerRef}
      >
        {children}
      </Grid>
      <Grid container item>
        <Grid item xs={12} justifySelf='flex-end'>
          <Divider />
        </Grid>
        <Grid item xs={12} my={2}>
          <Controls
            onSendClick={handleMessageSend}
            onSendFileClick={handleFileSend}
          />
          <UploadFileComponent
            fileInputRef={fileInputRef}
            onFileUpload={onFileSend}
            isUploadInProgress={isUploadInProgress}
            uploadDocumentId={chatId}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
