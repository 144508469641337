import { Grid, Typography } from '@mui/material'
import { Option, RenderOptionFunction } from '../../types/Autoselect'
import { AutoselectOptionContainer } from './AutoselectOption.styles'

export const AutoselectOption: RenderOptionFunction<Option> = (
  props,
  option,
  state
): JSX.Element => {
  const { selected } = state
  const { name } = option

  return (
    <li {...props}>
      <AutoselectOptionContainer
        container
        alignItems='center'
        justifyContent='space-between'
        selected={selected}
      >
        <Grid item>
          <Grid item container alignItems='center'>
            <Typography variant='subtitle1'>{name}</Typography>
          </Grid>
        </Grid>
      </AutoselectOptionContainer>
    </li>
  )
}
