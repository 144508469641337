import ErrorIcon from '@mui/icons-material/Error'
import { Grid, Link, Typography } from '@mui/material'
import { FC } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { FormContainer } from '../../../layout/FormContainer'
import { ScreenContainer } from '../../../layout/ScreenContainer'
import { ConfirmationErrorConstants } from './constants'

export const ConfirmationErrorContainer: FC = () => {
  return (
    <FormContainer title={ConfirmationErrorConstants.title} minHeight='unset' mt={5}>
      <ScreenContainer isLoading={false} wrapperProps={{ paddingX: 2 }}>
        <Grid item textAlign='center'>
          <ErrorIcon color='error' fontSize='large' />
        </Grid>
        <Grid item mt={3}>
          <Typography variant='body1' textAlign='center'>
            {ConfirmationErrorConstants.firstParagraph}
          </Typography>
        </Grid>
        <Grid item mt={3}>
          <Typography variant='body1' textAlign='center'>
            {ConfirmationErrorConstants.secondParagraph}
          </Typography>
        </Grid>
        <Grid item mt={3}>
          <Typography variant='body1' textAlign='center'>
            <Link underline='none' component={RouterLink} to={'/delete-user'} reloadDocument>
              {ConfirmationErrorConstants.link}
            </Link>
          </Typography>
        </Grid>
      </ScreenContainer>
    </FormContainer>
  )
}
