import {
  AUTOUPDATE_QUERY_PARAM,
  AUTOUPDATE_QUERY_SUBSCRIPTION_TYPE_PARAM,
  AUTOUPDATE_QUERY_SUBSCRIPTION_PAGE_MARK
} from './constants'

export const getReturnUrl = (
  subType: string,
  isSubscriptionPage: boolean = false
) => {
  const { origin } = window.location
  const { pathname } = window.location
  let url = `${origin}${pathname}?${AUTOUPDATE_QUERY_PARAM}&${AUTOUPDATE_QUERY_SUBSCRIPTION_TYPE_PARAM}=${subType}`

  if (isSubscriptionPage) {
    url += `&${AUTOUPDATE_QUERY_SUBSCRIPTION_PAGE_MARK}`
  }
  return url
}
