import { FC, useMemo } from 'react'
import { generatePath } from 'react-router-dom'
import { VehicleComponent } from '../../../../../../atoms/VehicleComponent'
import { routes } from '../../../../../../routes'
import { BADGE_COLORS } from '../../../../../../types/colors'
import { RequestedVehicle } from '../../../useRequestedVehicles'
import { Details } from './Details'

export const SellingVehicleItem: FC<RequestedVehicle> = ({
  requests,
  documentContent,
  ...vehicle
}) => {
  const navigationURL = useMemo(() => {
    return generatePath(routes.deals.requests, { vehicleID: vehicle.id })
  }, [vehicle.id])

  const badges = useMemo(() => {
    return [
      {
        position: 'top-left',
        label: vehicle.status,
        ...BADGE_COLORS.rose
      }
    ]
  }, [vehicle.retailValue])

  return (
    <VehicleComponent
      {...vehicle}
      imageSource={documentContent}
      badges={badges}
      details={<Details {...vehicle} requestsCount={requests} />}
      navigationURL={navigationURL}
    />
  )
}
