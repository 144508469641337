import { FC } from 'react'
import { FormOrchestratorCtx, FormOrchestratorStep } from './types'

interface FormOrchestratorProps {
  ctx: FormOrchestratorCtx
  steps: FormOrchestratorStep[]
}

export const FormOrchestrator: FC<FormOrchestratorProps> = (props) => {
  const { ctx, steps } = props

  const StepToRender = steps[ctx.step]
  return <StepToRender ctx={ctx} />
}
