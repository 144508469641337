import { Grid } from '@mui/material'
import {
  OfferDetailsWidget,
  OfferDetailsWidgetProps
} from '../../../../features/OfferDetailsWidget'
import { NormalizedOfferType } from '../../../../api/carSnoopApi/transactions/types'

const offer: Omit<OfferDetailsWidgetProps, 'actions'> = {
  totalPrice: 1000,
  vehiclePrice: 2000,
  salesTax: 3000,
  addons: [
    { description: 'Insurance', price: '1000', quantity: 1, id: 0 },
    { description: 'Delivery', price: '2000', quantity: 1, id: 1 }
  ],
  tradeinValue: 3000,
  notes:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nisl vel ultricies aliquam, nisl nisl aliquet nisl, eget aliquet nisl nisl sit amet nisl. Sed euismod, nisl vel ultricies aliquam, nisl nisl aliquet nisl, eget aliquet nisl nisl sit amet nisl.',
  previousOffer: {
    totalPrice: 0,
    vehiclePrice: 0,
    salesTax: 0,
    addons: null,
    tradeinValue: undefined,
    notes: '123'
  } as unknown as NormalizedOfferType
}

export const OfferDetailsWidgetExample = () => {
  const sellerActions = {
    onEditClick: () => {
      console.log('edit clicked')
    },
    onCancelClick: () => {
      console.log('cancel clicked')
    }
  }

  const buyerActions = {
    onAcceptClick: () => {
      console.log('buyerActions accept clicked')
    },
    onDeclineClick: () => {
      console.log('buyerActions decline clicked')
    }
  }

  return (
    <Grid container flexDirection='column' rowSpacing={3}>
      <Grid item>
        <OfferDetailsWidget {...offer} actions={buyerActions} />
      </Grid>
      <Grid item>
        <OfferDetailsWidget {...offer} actions={sellerActions} />
      </Grid>
    </Grid>
  )
}
