import { FC, useCallback, useState } from 'react'
import { Condition } from '../../../atoms/Condition'
import { VerificationCodeScreen } from './VerificationCodeScreen'
import { SuccessVerification } from './SuccessVerification'
import {
  VerificationCode,
  VerificationCodeStep
} from '../../../types/forms/registration'
import {
  useAppDispatch,
  useMakeService,
  useUserInfo
} from '../../../utils/hooks'
import { VerificationCodeScreenActions } from './constants'
import { carSnoopApi } from '../../../api'
import { ResponseStatusMap } from '../../../types/status'
import {
  actionsUserInfo,
  UserInfoStatusMap
} from '../../../store/features/userInfo'
import { NotVerifiedScreenScreen } from './NotVerifiedScreen/NotVerifiedScreen'

type VerificationCodeContainerProps = {
  initialValues: VerificationCodeStep
  actions: VerificationCodeScreenActions
  dependencies: {
    email: string
  }
  onBackBtnClick: () => void
}

export const VerificationCodeContainer: FC<VerificationCodeContainerProps> = ({
  actions,
  initialValues,
  dependencies,
  onBackBtnClick
}) => {
  const dispatch = useAppDispatch()
  const userInfo = useUserInfo()

  const [isSubmitted, submitScreen] = useState(!!initialValues.verificationCode)

  const [verifyCodeService, verificationCodeState] = useMakeService(
    async (options: VerificationCode) => {
      const response = await carSnoopApi.users.verifyEmail({
        id: dependencies.email,
        verification_code: options.verificationCode
      })

      if (response.status === ResponseStatusMap.Success) {
        dispatch(actions.setVerificationCode(options))
        dispatch(actions.incrementOnboardStep())
        submitScreen(true)
      }

      if (response.isBlocked) {
        dispatch(
          actions.setIsVerificationBlocked({
            isVerificationBlocked: response.isBlocked,
            blockedUntil: response.blockedUntil
          })
        )
      }

      return {
        status: response.status,
        error: response.message
      }
    }
  )

  const onBackBtnClicked = () => {
    dispatch(actions.decrementScreenStep())
    dispatch(actions.decrementOnboardStep())
    dispatch(
      actionsUserInfo.setUserStatus({
        status: UserInfoStatusMap.Unauthenticated
      })
    )
    onBackBtnClick()
  }

  const handleResendClick = useCallback(async () => {
    const response = await carSnoopApi.users.resendCode(dependencies.email)
    if (response.status === ResponseStatusMap.Success) {
      dispatch(
        actionsUserInfo.setUserStatus({
          status: UserInfoStatusMap.Unauthenticated
        })
      )
    }

    dispatch(
      actions.setIsVerificationBlocked({
        isVerificationBlocked: response.isBlocked,
        blockedUntil: response.blockedUntil
      })
    )
  }, [dependencies.email])

  return (
    <Condition
      condition={isSubmitted}
      trueContent={<SuccessVerification actions={actions} />}
      falseContent={
        <Condition
          condition={
            userInfo.status === UserInfoStatusMap.Preregistered &&
            !initialValues.isVerificationBlocked
          }
          trueContent={
            <NotVerifiedScreenScreen
              onResendClick={handleResendClick}
              onBackClick={onBackBtnClicked}
            />
          }
          falseContent={
            <VerificationCodeScreen
              isLoading={verificationCodeState.isLoading}
              onNextBtnClicked={verifyCodeService}
              onBackBtnClicked={onBackBtnClicked}
              dependencies={dependencies}
              initialValues={initialValues}
              initialErrors={{
                verificationCode: verificationCodeState.payload.error
              }}
            />
          }
        />
      }
    />
  )
}
