import { Grid } from '@mui/material'
import { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react'
import { NormalizedReadInventoryItem } from '../../../../../api/carSnoopApi/accounts/types'
import { Condition } from '../../../../../atoms/Condition'
import { DefaultEmptyState } from '../../../../../atoms/DefaultEmptyState'
import { For } from '../../../../../atoms/JSXExtensions'
import { RadioVehicleComponent } from '../../../../../atoms/RadioVehicleComponent'
import { OrNull } from '../../../../../types/OrNull'

interface TradeInCarsListProps {
  cars: NormalizedReadInventoryItem[]
  checkedTradeIn: OrNull<NormalizedReadInventoryItem>
  setCheckedTradeIn: Dispatch<
    SetStateAction<OrNull<NormalizedReadInventoryItem>>
  >
}

export const TradeInCarsList: FC<TradeInCarsListProps> = ({
  cars,
  checkedTradeIn,
  setCheckedTradeIn
}) => {
  const isShowControl = useMemo(() => cars.length > 1, [cars.length])

  const onCheckRadio = useCallback(
    (id: string) => {
      const checkedCar = cars.find((car) => car.id === id)

      if (checkedCar) {
        setCheckedTradeIn(checkedCar)
      }
    },
    [setCheckedTradeIn]
  )

  return (
    <Condition
      condition={!!cars.length}
      trueContent={
        <Grid container gap={2} mb={7}>
          <For of={cars}>
            {(vehicle) => (
              <Grid item xs={12} md={6} lg={4} key={vehicle.id}>
                <RadioVehicleComponent
                  id={vehicle.id}
                  showControl={isShowControl}
                  imageSource={vehicle.documentContent}
                  year={vehicle.year}
                  make={vehicle.make}
                  model={vehicle.model}
                  trim={vehicle.trim}
                  mileage={vehicle.mileage}
                  tradeInPrice={vehicle.tradeInValue || 0}
                  checked={vehicle.id === checkedTradeIn?.id}
                  onChange={onCheckRadio}
                  stockNumber={vehicle.stockNumber}
                />
              </Grid>
            )}
          </For>
        </Grid>
      }
      falseContent={<DefaultEmptyState text='Your trade-in list is empty.' />}
    />
  )
}
