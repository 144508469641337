import { useCallback } from 'react'
import { Form, Formik, FormikValues } from 'formik'
import * as Yup from 'yup'
import { Grid } from '@mui/material'
import { AgreeButton } from '../../../../../atoms/Button'
import { TextInputFieldNew } from '../../../../../atoms/TextInputFieldNew'
import { FormOrchestratorStep } from '../../../../../layout/FormOrchestrator/types'

export const SecondVariant: FormOrchestratorStep = (props) => {
  const { ctx } = props
  const { values } = ctx

  const handleSubmit = useCallback(async (formValues: FormikValues) => {
    ctx.setValues(formValues)
    ctx.nextStep()
  }, [])

  return (
    <Grid container item xs={12}>
      <Formik
        initialValues={{
          form_2_field_1: '',
          form_2_field_2: '',
          ...values
        }}
        validationSchema={Yup.object({
          form_2_field_1: Yup.string().required(''),
          form_2_field_2: Yup.string().required('')
        })}
        onSubmit={handleSubmit}
        validateOnMount
      >
        {(formik) => {
          return (
            <Form>
              <Grid container spacing='12px'>
                <Grid item xs={6}>
                  <TextInputFieldNew
                    name='form_2_field_1'
                    label='form_2_field_1'
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextInputFieldNew
                    name='form_2_field_2'
                    label='form_2_field_2'
                  />
                </Grid>

                <Grid item xs={6}>
                  <AgreeButton type='submit' disabled={!formik.isValid}>
                    SUBMIT
                  </AgreeButton>
                </Grid>
              </Grid>
            </Form>
          )
        }}
      </Formik>
    </Grid>
  )
}
