import { Box, Grid } from '@mui/material'
import { FC } from 'react'
import { LinkButton } from '../../../atoms/LinkButton'
import { ErrorScreen } from '../../../molecules/ErrorScreen'
import { ResetLinkExpiredConstants } from './constants'

type ResetLinkExpiredContainerProps = {
  isLoading?: boolean
  email: string
  resendLinkService: (email: string) => Promise<unknown>
}

export const ResetLinkExpiredContainer: FC<ResetLinkExpiredContainerProps> = ({
  isLoading,
  email,
  resendLinkService
}) => {
  const resendMessage = () => {
    resendLinkService(email)
  }
  return (
    <Box>
      <ErrorScreen
        title={ResetLinkExpiredConstants.title}
        subtitle={ResetLinkExpiredConstants.firstParagraph}
        description={ResetLinkExpiredConstants.bottomText}
        isLoading={isLoading}
        excludeLoadingTitle
      >
        <Grid container justifyContent='center' mt={1} alignSelf='flex-start'>
          <LinkButton
            label={ResetLinkExpiredConstants.textLink}
            onClick={resendMessage}
          />
        </Grid>
      </ErrorScreen>
    </Box>
  )
}
