import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { MouseEvent, useState } from 'react';
import { COLORS } from '../../../../types/colors';
import { useAppLinks } from '../../../../utils/hooks';
import { CustomNavLink } from '../CustomNavLink';

export const AppMenu = () => {
  const appLinks = useAppLinks()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onOpenClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseClick = () => {
    setAnchorEl(null);
  };

  const menuItems = appLinks.map(({ label, route }) => {
    if (route.startsWith('http')) {
      return (
        <MenuItem key={label} onClick={onCloseClick} sx={{ ":hover": { backgroundColor: `${COLORS.hover_background_gray} !important` } }}>
          <Typography
            component="a"
            href={route}
            lineHeight={1}
            sx={{ textDecoration: 'none', whiteSpace: 'nowrap' }}
            target="_blank"
            textAlign="center"
            variant='overline'
          >
            {label}
          </Typography>
        </MenuItem>
      )
    }
    return (
      <MenuItem key={label} onClick={onCloseClick} sx={{ ":hover": { backgroundColor: `${COLORS.hover_background_gray} !important` } }}>
        <Typography
          component={CustomNavLink}
          lineHeight={1}
          sx={{ textDecoration: 'none', whiteSpace: 'nowrap' }}
          textAlign="center"
          to={route}
          variant='overline'
        >
          {label}
        </Typography>
      </MenuItem>
    )
  })

  return (
    <>
      <IconButton
        size="large"
        aria-label="app menu"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={onOpenClick}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        MenuListProps={{ sx: { backgroundColor: COLORS.main_black } }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorEl)}
        onClose={onCloseClick}
        sx={{
          display: { xs: 'block', md: 'none' },
        }}
      >
        {menuItems}
      </Menu>
    </>
  )
}
