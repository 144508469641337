import { FC } from 'react'
import { ResetPasswordSuccessScreen } from '../../../ResetPassword/ResetPasswordSuccessScreen'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const ResetPasswordSuccessScreenExample: FC = () => {
  return (
    <ComponentContainer fullWidth>
      <ComponentWithPaperBackground title='Not in molecules folder (in routes/ResetPassword)'>
        <ResetPasswordSuccessScreen />
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
