import * as Yup from 'yup'
import { latinWithSpecial, vinNumber } from '../../../../../../utils/validators'

const ERROR =
  'Enter a valid VIN (17 characters: numbers and letters only except I, O, and Q)'

export const validationPattern = Yup.string()
  .required('')
  .test('is-latin-or-special', ERROR, latinWithSpecial())
  .test('vinNumber', ERROR, vinNumber)

export const validationSchema = Yup.object().shape({
  vin: validationPattern
})
