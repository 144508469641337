import { Grid, styled } from '@mui/material'

export const ModalContainer = styled(Grid)`
  padding: 40px 0 16px 0;
  width: 667px;
  justify-content: center;
`

export const ModalInnerContainer = styled(Grid)`
  max-width: 400px;
  text-align: center;
`
