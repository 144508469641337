import * as Yup from 'yup'
import { phoneNumberLength } from '../../../../../constants/applicationConstants'
import {
  countOfNumbers,
  isValidEmail,
  latinWithSpecial,
  notOnlySpaces,
  notOnlyZeroes
} from '../../../../../utils/validators'
import { FIELDS_NAMES, FIELDS_ERRORS } from './types'

const addressNamePattern = Yup.string()
  .trim()
  .test(
    'is-latin-or-special',
    FIELDS_ERRORS.ADDRESS_NAME,
    latinWithSpecial([' '])
  )
  .test('not-only-spaces', FIELDS_ERRORS.ADDRESS_NAME, notOnlySpaces())
  .nullable()

const addressNamePatternRequired = addressNamePattern.required('')

export const getValidationSchema = (isDealer: boolean) => {
  return Yup.object({
    [FIELDS_NAMES.ADDRESS_NAME]: isDealer
      ? addressNamePatternRequired
      : addressNamePattern,
    [FIELDS_NAMES.STREET]: Yup.string()
      .required('')
      .trim()
      .test(
        'is-latin-or-special',
        FIELDS_ERRORS.STREET,
        latinWithSpecial([' '])
      )
      .test('not-only-spaces', FIELDS_ERRORS.STREET, notOnlySpaces())
      .nullable(),
    [FIELDS_NAMES.ZIP_CODE]: Yup.string()
      .required('')
      .trim()
      .test('length', FIELDS_ERRORS.ZIP_CODE, countOfNumbers(5))
      .test('not-only-zeroes', FIELDS_ERRORS.ZIP_CODE, notOnlyZeroes()),
    [FIELDS_NAMES.CONTACT_NAME]: Yup.string()
      .required('')
      .trim()
      .test(
        'is-latin-or-special',
        FIELDS_ERRORS.CONTACT_NAME,
        latinWithSpecial([' '])
      )
      .test('not-only-spaces', FIELDS_ERRORS.CONTACT_NAME, notOnlySpaces()),
    [FIELDS_NAMES.CONTACT_NUMBER]: Yup.string()
      .required('')
      .trim()
      .test(
        'length',
        FIELDS_ERRORS.CONTACT_NUMBER,
        countOfNumbers(phoneNumberLength)
      ),
    [FIELDS_NAMES.CONTACT_EMAIL]: Yup.string()
      .required('')
      .test('is-email', FIELDS_ERRORS.CONTACT_EMAIL, isValidEmail())
      .test(
        'is-latin-or-special',
        FIELDS_ERRORS.CONTACT_EMAIL,
        latinWithSpecial()
      ),
    [FIELDS_NAMES.NOTES]: Yup.string().test(
      'is-latin-or-special',
      FIELDS_ERRORS.NOTES,
      latinWithSpecial(['\n', ' ', '€', '£'])
    ),
    [FIELDS_NAMES.CITY]: Yup.string().required(''),
    [FIELDS_NAMES.STATE]: Yup.string().required('')
  })
}
