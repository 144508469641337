export const supportedTypesMap = {
  audio: ['mp3', 'wav', 'mpeg'],
  video: ['mp4', 'webm'],
  image: ['gif', 'jpg', 'jpeg', 'png', 'svg+xml'],
  application: ['pdf']
}

export const useFileType = (mimeType: string) => {
  const [type, subtype] = mimeType.split('/') as [
    keyof typeof supportedTypesMap,
    string
  ]

  return {
    type,
    subtype,
    isSupported: !!supportedTypesMap[type]?.includes(subtype)
  }
}
