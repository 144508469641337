import { useMemo } from "react"
import { UserInfoStatusMap } from "../../store/features/userInfo"
import { useDeviceInfo } from "./useDeviceInfo"
import { useUserInfo } from "./useUserInfo"

export const useUserAuthStatus = () => {
  const { isOnLine } = useDeviceInfo()
  const { status } = useUserInfo()

  const isUserAuthenticated = useMemo(
    () => isOnLine && status === UserInfoStatusMap.Authenticated,
    [isOnLine, status]
  )

  return isUserAuthenticated
}
