import { useCallback } from 'react'
import { GetSynchronizedDataTypes } from '../../../api/carSnoopApi/duty/types'
import { actionsUserInfo } from '../../../store/features/userInfo'
import { ResponseStatusMap } from '../../../types/status'
import { useApiContext, useAppDispatch, useMakeService } from '../../hooks'

export const useSynchronizer = () => {
  const dispatch = useAppDispatch()
  const { carSnoopApi } = useApiContext()

  const getSynchronizedData = useCallback(async () => {
    const synchronizedData = await carSnoopApi.duty.getSynchronizedData()

    return synchronizedData
  }, [carSnoopApi])

  const handleSuccessSynchronize = useCallback(
    async (response: GetSynchronizedDataTypes.ServiceSuccessResponse) => {
      const { subscription } = response

      dispatch(
        actionsUserInfo.setUserSubscription({
          subscription
        })
      )
    },
    [dispatch]
  )

  const synchronizeData = useCallback(async () => {
    const synchronizedData = await getSynchronizedData()

    const isSuccess = synchronizedData.status === ResponseStatusMap.Success

    if (isSuccess) {
      return handleSuccessSynchronize(synchronizedData)
    }

    return synchronizedData
  }, [getSynchronizedData, handleSuccessSynchronize])

  const [synchronizeDataService, synchronizeDataObservable] =
    useMakeService(synchronizeData)

  return {
    synchronize: synchronizeDataService,
    isSynchronizing:
      !synchronizeDataObservable.isError && !synchronizeDataObservable.isSuccess
  }
}
