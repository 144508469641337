import { FC } from 'react'
import { StyledRequiredLabel } from './RequiredLabel.styles'

export interface RequiredLabelProps {
  marginTop?: number
}

export const RequiredLabel: FC<RequiredLabelProps> = ({ marginTop = 10 }) => {
  return (
    <StyledRequiredLabel marginTop={marginTop}>
      indicates required field
    </StyledRequiredLabel>
  )
}
