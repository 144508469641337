import { Grid } from '@mui/material'
import { EnvelopeIcon } from '../../../../assets/icons/EnvelopeIcon'
import { SupportForm } from '../../../../features/Support'
import {
  buttonText,
  SUPPORT_FINAL_TEXT_HEADER,
  SUPPORT_FINAL_TEXT_MESSAGE
} from '../../../../features/Support/constants'
import { SupportFormMainText } from '../../../../features/Support/components/SupportFormMainText'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

const email = 'JohnDoe@gmail.com'

const prefilledReason = 'Delete account'

const successSupportMessageText = (
  <SupportFormMainText
    primaryText={SUPPORT_FINAL_TEXT_HEADER}
    secondaryText={SUPPORT_FINAL_TEXT_MESSAGE}
  />
)

export const SupportFormExample = () => {
  return (
    <ComponentContainer>
      <ComponentWithPaperBackground title='Support form'>
        {/* hardcoded according to design for demonstration */}
        <Grid container height='1000px' width='700px'>
          <SupportForm
            email={email}
            prefilledReason={prefilledReason}
            buttonText={buttonText}
            mainText={successSupportMessageText}
            icon={<EnvelopeIcon />}
          />
        </Grid>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
