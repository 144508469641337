import { Box } from '@mui/material'
import { FC } from 'react'
import { DefaultVehicle } from '../../assets/imgs/DefaultVehicle'
import { OrNull } from '../../types/OrNull'
import { COLORS } from '../../types/colors'
import { formatPrice } from '../../utils'
import { Badge } from '../Badge'
import { Show } from '../JSXExtensions'

interface ImageWithBadgeProps {
  imageURL: OrNull<string>
  badgeValue: number
}

export const ImageWithBadge: FC<ImageWithBadgeProps> = ({
  imageURL,
  badgeValue
}) => {
  return (
    <Box
      sx={{
        aspectRatio: '16/9',
        background: imageURL
          ? `no-repeat center/cover url(${imageURL})`
          : `no-repeat center/cover ${COLORS.border_gray}`,
        borderRadius: '3px',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          left: '24px',
          bottom: '24px',
          minWidth: '140px'
        }}
      >
        <Badge
          text={formatPrice(badgeValue, { includeDecimalPart: true })}
          background={COLORS.hover_background_blue}
          color={COLORS.main_black}
          textSx={{
            fontSize: '18px'
          }}
          border='none'
          sx={{ height: 'auto', py: 0.3 }}
        />
      </Box>

      <Show when={!imageURL}>
        <Box
          sx={{
            p: 2,
            height: '100%',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <DefaultVehicle />
        </Box>
      </Show>
    </Box>
  )
}
