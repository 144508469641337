import { useState, useCallback } from 'react'

export const useHandleRedirectNeeded = () => {
  const [redirectUrl, setRedirectUrl] = useState('')

  const handleRedirect = useCallback(
    (url: string) => {
      setRedirectUrl(url)
    },
    [setRedirectUrl]
  )

  return { handleRedirect, redirectUrl }
}
