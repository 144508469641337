import { DependentField } from '../../atoms/DependentField'
import { AutoselectInputField } from '../../atoms/AutoselectInputField'

import { FIND_CAR_FIELD_NAMES } from '../../types/enums/findCar'
import { Option, ColorOption } from '../../types/Autoselect'
import { BasicInfo } from '../../types/forms/findCar'
import { NumericField, NumericFieldProps } from '../../atoms/NumericField'
import {
  EditVehicleFormFieldsTypes,
  EDIT_VEHICLE_FIELD_NAMES
} from './constants'

export type ModelFieldProps = Parameters<typeof AutoselectInputField<Option>>[0]
export type YearFromFieldProps = ModelFieldProps
export type YearToFieldProps = ModelFieldProps
export type TrimFieldProps = ModelFieldProps
export type ExteriorFieldProps = Parameters<
  typeof AutoselectInputField<ColorOption>
>[0]
export type InteriorFieldProps = ExteriorFieldProps

export const DependentModelField = DependentField<ModelFieldProps, BasicInfo>(
  AutoselectInputField<Option>,
  {
    modifyProps: (props, { make }) =>
      make ? props : { ...props, disabled: true },
    deps: [FIND_CAR_FIELD_NAMES.MAKE]
  }
)

export const DependentYearFromField = DependentField<
  YearFromFieldProps,
  BasicInfo
>(AutoselectInputField<Option>, {
  modifyProps: (props, { model }) =>
    model ? props : { ...props, disabled: true },
  deps: [FIND_CAR_FIELD_NAMES.MODEL]
})

export const DependentYearToField = DependentField<YearToFieldProps, BasicInfo>(
  AutoselectInputField<Option>,
  {
    modifyProps: (props, { yearFrom }) => {
      const { options } = props
      if (yearFrom) {
        const filteredOptions = options.filter(
          (option) => option.id >= yearFrom.id
        )
        return { ...props, options: filteredOptions }
      }
      return { ...props, disabled: true }
    },
    deps: [FIND_CAR_FIELD_NAMES.YEAR_FROM]
  }
)

export const DependentTrimField = DependentField<TrimFieldProps, BasicInfo>(
  AutoselectInputField<Option>,
  {
    modifyProps: (props, { yearTo }) =>
      yearTo ? props : { ...props, disabled: true },
    deps: [FIND_CAR_FIELD_NAMES.YEAR_TO]
  }
)

export const DependentExteriorField = DependentField<
  ExteriorFieldProps,
  BasicInfo
>(AutoselectInputField<ColorOption>, {
  modifyProps: (props, { yearTo }) =>
    yearTo ? props : { ...props, disabled: true },
  deps: [FIND_CAR_FIELD_NAMES.YEAR_TO]
})

export const DependentInteriorField = DependentField<
  InteriorFieldProps,
  BasicInfo
>(AutoselectInputField<ColorOption>, {
  modifyProps: (props, { yearTo }) =>
    yearTo ? props : { ...props, disabled: true },

  deps: [FIND_CAR_FIELD_NAMES.YEAR_TO]
})

export const DependentRetailValueField = DependentField<
  NumericFieldProps,
  EditVehicleFormFieldsTypes
>(NumericField, {
  deps: [
    EDIT_VEHICLE_FIELD_NAMES.CURRENT_MILEAGE,
    EDIT_VEHICLE_FIELD_NAMES.CONDITION
  ]
})
