import { createContext, useContext, Dispatch, SetStateAction } from 'react'
import { noop } from '../../../../utils'

export const ShareErrorContext = createContext<{
  hasError: boolean
  setHasError: Dispatch<SetStateAction<boolean>>
}>({
  hasError: false,
  setHasError: noop
})

export const useShareErrorContext = () => {
  const { hasError, setHasError } = useContext(ShareErrorContext)

  return { hasError, setHasError }
}
