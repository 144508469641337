import { Button } from '@mui/material'
import { useSubscriptionModal } from '../../../../utils/hooks'
import { SubscriptionModal } from '../../../../features/SubscriptionModal'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const SubscriptionModalExample = () => {
  const { openSubscriptionModal } = useSubscriptionModal()

  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <Button onClick={openSubscriptionModal}>Open</Button>
        <SubscriptionModal />
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
