import { Grid, Typography } from '@mui/material'
import { FindCarForm } from '../../../forms/FindCar'

export const BuyerHomePage = () => {
  return (
    <Grid container item spacing={2}>
      <Grid item xs={12}>
        <Typography component='h2' variant='h6'>
          Find Your Perfect Vehicle
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FindCarForm />
      </Grid>
    </Grid>
  )
}
