import { FC, ReactNode } from 'react'
import { Grid } from '@mui/material'

type OperationResultProps = {
  header: ReactNode
  mainText: ReactNode
  actionBlock: ReactNode
  icon: ReactNode
}
export const OperationResult: FC<OperationResultProps> = ({
  header,
  icon,
  mainText,
  actionBlock
}) => {
  return (
    <>
      <Grid item>{header}</Grid>
      <Grid item>{icon}</Grid>
      <Grid item>{mainText}</Grid>
      <Grid item width='400px' maxHeight='75px'>
        {actionBlock}
      </Grid>
    </>
  )
}
