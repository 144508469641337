import { FC, Dispatch, SetStateAction } from 'react'
import { Grid, Typography } from '@mui/material'
import { ConfirmationModal } from './ConfirmationModal'
import { Condition } from '../../atoms/Condition'

export interface DealConfirmationProps {
  message: string
  hint?: string
  isOpen: boolean
  cancelText?: string
  confirmText?: string
  setOpen: Dispatch<SetStateAction<boolean>>
  onSubmit: () => void | Promise<unknown>
  header?: string
  isSubmitDisabled?: boolean
  messageColor?: string
}

export const CommonConfirmationModal: FC<DealConfirmationProps> = ({
  message,
  hint,
  isOpen,
  cancelText = 'Close',
  confirmText = 'Confirm',
  setOpen,
  onSubmit,
  header = 'Confirmation',
  isSubmitDisabled = false,
  messageColor = ''
}) => {
  return (
    <ConfirmationModal
      isOpen={isOpen}
      setIsOpen={setOpen}
      cancel={{
        text: cancelText
      }}
      submit={{
        text: confirmText,
        callback: onSubmit,
        disabled: isSubmitDisabled
      }}
      header={<Typography variant='h6'>{header}</Typography>}
    >
      <Grid
        container
        rowSpacing={3}
        flexDirection='column'
        sx={{ pt: 4, pb: 2, px: 8 }}
      >
        <Grid item container justifyContent='center'>
          <Typography variant='main' textAlign='center' color={messageColor}>
            {message}
          </Typography>
        </Grid>
        <Condition
          condition={!!hint}
          trueContent={
            <Grid item>
              <Typography
                variant='subtitle2'
                textAlign='center'
                whiteSpace='pre'
              >
                {hint}
              </Typography>
            </Grid>
          }
        />
      </Grid>
    </ConfirmationModal>
  )
}
