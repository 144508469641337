import { ActionBlock } from '../../../../atoms/ActionBlock'
import { ErrorScreen } from '../../../../molecules/ErrorScreen'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const ErrorScreenExample = () => {
  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <ErrorScreen
          title='Everything is broken!'
          subtitle="Our developers aren't so good"
          description='Who will be fired?'
        >
          <ActionBlock
            schema={[
              {
                label: 'Anatolii',
                type: 'secondary'
              },
              {
                label: 'Ivan',
                type: 'primary'
              }
            ]}
          />
        </ErrorScreen>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
