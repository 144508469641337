import { Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { AttentionIcon } from '../../assets/icons/Attention'

interface IInformationLabel {
  labelText: string
}

export const InformationLabel: FC<IInformationLabel> = ({ labelText }) => {
  return (
    <Grid container display='flex' alignItems='center'>
      <Grid item height='24px' mr='16px'>
        <AttentionIcon />
      </Grid>
      <Grid item>
        <Typography variant='subtitle2'>{labelText}</Typography>
      </Grid>
    </Grid>
  )
}
