import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Grid, Typography } from '@mui/material'
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useMemo
} from 'react'
import { Document } from '../../../assets/icons/Document'
import { PenIcon } from '../../../assets/icons/Pen'
import { BlueButton } from '../../../atoms/Button'
import { Condition } from '../../../atoms/Condition'
import { IconText } from '../../../atoms/IconText'
import { applyMask } from '../../../utils'

interface EmptyStateProps {
  setEditCallback: () => void
}

interface FullStateProps {
  ssn: string
  setEditCallback: () => void
}

const EmptyState: FC<PropsWithChildren<EmptyStateProps>> = ({
  setEditCallback
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='label1'>SSN</Typography>
      </Grid>
      <Grid item xs={12}>
        <BlueButton
          startIcon={<AddCircleOutlineIcon />}
          onClick={setEditCallback}
        >
          Add SSN
        </BlueButton>
      </Grid>
    </Grid>
  )
}

const FullyState: FC<PropsWithChildren<FullStateProps>> = ({
  ssn,
  setEditCallback
}) => {
  const hiddenSSN = useMemo(() => {
    const maskedSSN = applyMask(ssn, '### - ## - ####')

    return maskedSSN.replace(/\d(?=.{4})/g, '*')
  }, [ssn])

  return (
    <Grid item xs={12} container spacing={2}>
      <Grid
        item
        container
        justifyContent='space-between'
        alignItems='center'
        wrap='nowrap'
        xs={12}
      >
        <Typography variant='label1'>SSN</Typography>
        <BlueButton onClick={setEditCallback}>
          <PenIcon />
        </BlueButton>
      </Grid>
      <Grid item xs={12}>
        <IconText icon={<Document />} alignItems='center'>
          {hiddenSSN}
        </IconText>
      </Grid>
    </Grid>
  )
}

export const DisplaySSNInformation: FC<
  PropsWithChildren<{
    ssn?: string | null
    setEditMode: Dispatch<SetStateAction<boolean>>
  }>
> = ({ ssn, setEditMode }) => {
  const setEditCallback = useCallback(() => {
    setEditMode(true)
  }, [setEditMode])

  return (
    <Condition
      condition={!!ssn}
      trueContent={
        <FullyState ssn={ssn as string} setEditCallback={setEditCallback} />
      }
      falseContent={<EmptyState setEditCallback={setEditCallback} />}
    />
  )
}
