import { MouseEventHandler, useCallback } from 'react'
import { ResponseStatusMap } from '../../../types/status'
import { useApiContext } from '..'
import { createDownloadLink } from './createDownloadLink'

export const useFiles = () => {
  const { carSnoopApi } = useApiContext()

  const getMediaLink = useCallback(async (id: string) => {
    const response = await carSnoopApi.documents.getDocumentUrl({
      documentId: id
    })

    if (response.status === ResponseStatusMap.Success) {
      return response
    }

    return { url: '' }
  }, [])

  const getFileById = useCallback(
    async (fileId: string, fileName: string) => {
      const { url } = await getMediaLink(fileId)

      createDownloadLink(url || '', fileName)
    },
    [getMediaLink]
  )

  const getFileByLink: (url: string, fileName: string) => MouseEventHandler =
    useCallback((url, fileName) => {
      return (event) => {
        event.stopPropagation()

        createDownloadLink(url, fileName)
      }
    }, [])

  return {
    getFileByLink,
    getFileById,
    getMediaLink
  }
}
