import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
  MouseEvent
} from 'react'
import { FieldValidator, useField } from 'formik'
import MaskedInputComponent, { MaskedInputProps } from 'react-text-mask'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { InputField } from '../InputField/InputField'
import { Condition } from '../Condition'
import { identity } from '../../utils/noop'
import { getIsFieldRequired } from '../../utils/validators/getIsFieldRequired'

export interface MaskedInputFieldProps {
  name: string
  label: string
  mask: MaskedInputProps['mask']
  hiddable?: boolean
  placeholder?: string
  disabled?: boolean
  required?: boolean | ((name: string) => boolean)
  validate?: FieldValidator
  onBlur?: () => void
  onFocus?: () => void
  modifyValue?: (value: string) => string
  showMask?: boolean
  guide?: boolean
  autoComplete?: string
  textColor?: string
  backgroundColor?: string
}

export const MaskedInput: FC<MaskedInputFieldProps> = (props): JSX.Element => {
  const {
    name,
    label,
    placeholder,
    disabled,
    required = false,
    validate,
    mask,
    hiddable,
    onBlur: customOnBlur,
    onFocus: customOnFocus,
    modifyValue = identity,
    autoComplete = 'none',
    showMask = false,
    guide = true,
    textColor,
    backgroundColor
  } = props
  const [field, meta, helpers] = useField<string>({ name, validate })

  const { value, onChange, onBlur } = field
  const { error } = meta

  const isRequiredField = getIsFieldRequired(required, name)

  const handleFocus = useCallback(() => {
    helpers.setError('')
    customOnFocus && customOnFocus()
  }, [helpers])

  const [showValue, setShowValue] = useState(!hiddable)

  const handleMouseDown = useCallback((event: MouseEvent) => {
    event.preventDefault()
  }, [])

  useEffect(() => {
    helpers.setTouched(true)
  }, [mask])

  const handleClickShowValue = useCallback(() => {
    setShowValue(!showValue)
  }, [showValue])

  const inputIcon = useMemo(() => {
    return showValue ? <Visibility /> : <VisibilityOff />
  }, [error, showValue])

  const maskForSeveralSymbols: MaskedInputProps['mask'] = useMemo(() => {
    if (Array.isArray(mask) && typeof mask[0] === 'string') {
      return [...mask[0].split(''), ...mask.slice(1)]
    }

    return mask
  }, [mask])

  return (
    <MaskedInputComponent
      type={showValue ? 'text' : 'password'}
      mask={showValue && maskForSeveralSymbols}
      placeholder={placeholder}
      showMask={showMask}
      guide={guide}
      autoComplete={autoComplete}
      pipe={modifyValue}
      render={(ref, internalProps) => {
        return (
          <InputField
            {...internalProps}
            textColor={textColor}
            backgroundColor={backgroundColor}
            inputRef={ref}
            name={name}
            value={value}
            label={label}
            placeholder={placeholder}
            disabled={disabled}
            required={isRequiredField}
            helperText={error}
            error={!!error}
            onChange={(e) => {
              internalProps.onChange(e)
              onChange(e)
            }}
            onBlur={(e) => {
              internalProps.onBlur(e)
              onBlur(e)
              customOnBlur && customOnBlur()
            }}
            onFocus={handleFocus}
            endAdornment={
              <Condition
                condition={!!hiddable}
                trueContent={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowValue}
                      onMouseDown={handleMouseDown}
                      edge='end'
                    >
                      {inputIcon}
                    </IconButton>
                  </InputAdornment>
                }
              />
            }
          />
        )
      }}
    />
  )
}
