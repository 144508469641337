import { Grid, GridProps, Typography } from '@mui/material'
import { FC, ReactNode } from 'react'
import { AgreeButton } from '../Button'
import { Show } from '../JSXExtensions'

interface DefaultEmptyStateProps {
  title: string
  image: ReactNode
  sx?: GridProps['sx']
  onAcceptButtonClick?: () => void
  acceptButtonText?: string
}

export const EmptyState: FC<DefaultEmptyStateProps> = ({
  title,
  image,
  sx,
  onAcceptButtonClick,
  acceptButtonText
}) => {
  return (
    <Grid
      container
      justifyContent='center'
      alignContent='center'
      flexDirection='column'
      gap={2}
      margin='auto'
      flexGrow={1}
      sx={sx}
    >
      <Grid item textAlign='center'>
        {image}
      </Grid>
      <Grid item>
        <Typography variant='h6' sx={{ textAlign: 'center' }}>
          {title}
        </Typography>
      </Grid>
      <Show when={acceptButtonText && onAcceptButtonClick}>
        <Grid item display='flex' justifyContent='center'>
          <AgreeButton
            sx={{ maxWidth: '480px', width: '100%' }}
            fullWidth
            onClick={onAcceptButtonClick}
          >
            {acceptButtonText}
          </AgreeButton>
        </Grid>
      </Show>
    </Grid>
  )
}
