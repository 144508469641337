import { Utils } from '../../utils'
import { ErrorResponse, SuccessResponse } from '../../private.types'
import { ResponseStatusMap } from '../../../types/status'
import { ContactSupportParams } from './types'

export class Support {
  protected readonly utils: Utils
  protected readonly operations = {
    contactSupport: 'contactSupport'
  }

  constructor(utils: Utils) {
    this.utils = utils
  }

  async contactSupport(
    data: ContactSupportParams
  ): Promise<
    SuccessResponse<{ message: string }> | ErrorResponse<{ message: string }>
  > {
    type ContactSupportResponse = {
      message: string
    }

    const { type, payload } = await this.utils.makeJSONRequest<
      ContactSupportResponse,
      ContactSupportResponse
    >(this.operations.contactSupport, data)

    if (type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        message: payload.message
      }
    }

    return {
      status: ResponseStatusMap.Error,
      message: payload.message
    }
  }
}
