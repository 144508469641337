import { Grid, Typography } from '@mui/material'
import { useFormikContext } from 'formik'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { DependentFieldHocEffect } from '../../../../atoms/DependentField'
import { TextInputFieldNew } from '../../../../atoms/TextInputFieldNew'
import { zipCodeMask } from '../../../../constants/regexp'
import { RenderOptionFunction } from '../../../../types/Autoselect'
import { COLORS } from '../../../../types/colors'
import { ResponseStatusMap, UserStatusMap } from '../../../../types/status'
import { getOptionLabel } from '../../../../utils/autoselect/getOptionLabel'
import { isOptionEqualToValue } from '../../../../utils/autoselect/isOptionEqualToValue'
import { stringToReferenceModel } from '../../../../utils/autoselect/stringToReferenceModel'
import { useUserInfo } from '../../../../utils/hooks'
import { useCheckZipCode } from '../../../../utils/hooks/useCheckZipCode'
import { isRequiredFormField } from '../../../../utils/validators/isRequiredFormField'
import {
    CobuyerFieldsValues,
    FIELD_ERRORS,
    FIELD_LABELS,
    FIELD_NAMES
} from './constants'
import {
    CobuyerEmailFieldProps,
    CobuyerOptionsType,
    DependentCobuyerEmailField,
    DependentZipCodeField,
    DependentZipCodeFieldProps
} from './dependentFields'
import { validationSchema } from './validationSchema'

const RenderSelectFieldOption: RenderOptionFunction<CobuyerOptionsType> = (
  props,
  option,
  state
) => {
  const { status, name } = option
  const { selected } = state
  const statusToDisplay = (status === UserStatusMap.Pending && status) || ''

  return (
    <li {...props}>
      <Grid
        container
        alignItems='center'
        justifyContent='space-between'
        sx={{
          p: '12px 16px',
          backgroundColor: selected
            ? COLORS.hover_background_light_yellow
            : COLORS.white,
          '&:hover': {
            backgroundColor: selected
              ? COLORS.hover_background_light_yellow
              : COLORS.secondary_background
          }
        }}
      >
        <Grid item container justifyContent='space-between'>
          <Grid item>{name}</Grid>
          <Grid item>
            <Typography variant='subtitle2' fontStyle='italic'>
              {statusToDisplay}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </li>
  )
}

const NoOptionsText = () => {
  return (
    // variant='main' can't override MUI default color and styles
    <Typography variant='body1' textAlign='center' fontSize='16px'>
      No options
    </Typography>
  )
}

export const MicroForm: FC = () => {
  const {
    values,
    setErrors,
    validateForm: validateOnToggle
  } = useFormikContext<CobuyerFieldsValues>()

  const { [FIELD_NAMES.ADD_COBUYER_TOGGLE]: isDisabled } = values
  useEffect(() => {
    if (isDisabled) {
      setErrors({})
    } else {
      validateOnToggle()
    }
  }, [isDisabled])

  const { users, id: userEmail, addresses } = useUserInfo()
  const { street, zipCode, city, state } = addresses[0]
  const cobuyersOptions = useMemo(() => {
    return users.reduce((acc: CobuyerOptionsType[], u) => {
      if (u.id !== userEmail) {
        return [...acc, { ...stringToReferenceModel(u.id), status: u.status }]
      }

      return acc
    }, [])
  }, [users, userEmail])

  const isRequiredField = isRequiredFormField(validationSchema)

  const { checkZipCode, checkZipCodeObservable } = useCheckZipCode()
  const zipCodeFieldEffect: DependentFieldHocEffect<
    DependentZipCodeFieldProps,
    CobuyerFieldsValues
  > = useCallback(async (_, ctx) => {
    const {
      values: formValues,
      setFieldValue,
      validateForm,
      setFieldError,
      setValues
    } = ctx
    const { zipCode: zipCodeValue } = formValues
    setFieldValue(FIELD_NAMES.CITY, '')
    setFieldValue(FIELD_NAMES.STATE, '')

    if (zipCodeValue) {
      const response = await checkZipCode(zipCodeValue)

      if (response.status === ResponseStatusMap.Success) {
        setValues(
          {
            ...formValues,
            [FIELD_NAMES.CITY]: response.city,
            [FIELD_NAMES.STATE]: response.state
          },
          true
        )
      } else {
        validateForm().then(() => {
          setFieldError(FIELD_NAMES.ZIP_CODE, FIELD_ERRORS.ZIP_CODE)
        })
      }
    }
  }, [])

  const cobuyerEmailFieldEffect: DependentFieldHocEffect<
    CobuyerEmailFieldProps,
    CobuyerFieldsValues
  > = useCallback(
    (_, ctx) => {
      const { values: formValues, setValues } = ctx
      const { cobuyerEmail } = formValues
      if (cobuyerEmail?.id) {
        const cobuyer = users.find((user) => user.id === cobuyerEmail?.id)
        setValues(
          {
            ...formValues,
            [FIELD_NAMES.COBUYER_NAME]: cobuyer?.fullName || '',
            [FIELD_NAMES.COBUYER_ADDRESS]: street || '',
            [FIELD_NAMES.ZIP_CODE]: zipCode,
            [FIELD_NAMES.CITY]: city,
            [FIELD_NAMES.STATE]: state
          },
          true
        )
      }
    },
    [street, zipCode, city, state]
  )
  return (
    <Grid container item flexDirection='column' spacing={2}>
      <Grid item>
        <DependentCobuyerEmailField
          name={FIELD_NAMES.COBUYER_EMAIL}
          label={FIELD_LABELS.COBUYER_EMAIL}
          options={cobuyersOptions}
          disabled={isDisabled}
          renderOption={RenderSelectFieldOption}
          onChangeEffect={cobuyerEmailFieldEffect}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={isOptionEqualToValue}
          noOptionText={<NoOptionsText />}
          required={isRequiredField}
        />
      </Grid>
      <Grid item>
        <TextInputFieldNew
          name={FIELD_NAMES.COBUYER_NAME}
          label={FIELD_LABELS.COBUYER_NAME}
          disabled={isDisabled}
          required
        />
      </Grid>
      <Grid item>
        <TextInputFieldNew
          name={FIELD_NAMES.COBUYER_ADDRESS}
          label={FIELD_LABELS.COBUYER_ADDRESS}
          disabled={isDisabled}
          required
        />
      </Grid>
      <Grid container item justifyContent='space-between' spacing={2}>
        <Grid item xs={6}>
          <DependentZipCodeField
            name={FIELD_NAMES.ZIP_CODE}
            label={FIELD_LABELS.ZIP_CODE}
            mask={zipCodeMask}
            disabled={isDisabled}
            onDoneEffect={zipCodeFieldEffect}
            required
          />
        </Grid>
        <Grid
          container
          item
          spacing={2}
          justifyContent='flex-end'
          xs={6}
          wrap='nowrap'
        >
          <Grid item>
            <TextInputFieldNew
              name={FIELD_NAMES.CITY}
              label={FIELD_LABELS.CITY}
              disabled
              isLoading={checkZipCodeObservable.isLoading}
              required={isRequiredField}
            />
          </Grid>
          <Grid item>
            <TextInputFieldNew
              name={FIELD_NAMES.STATE}
              label={FIELD_LABELS.STATE}
              disabled
              isLoading={checkZipCodeObservable.isLoading}
              required={isRequiredField}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
