import { FC, ReactNode, useCallback } from 'react'
import { Grid, Typography } from '@mui/material'
import { CustomRadio } from '../RadioVehicleComponent/CustomRadio'
import { BORDERS } from '../../types/colors'
import { noop } from '../../utils/noop'

export interface RadioActionButtonProps {
  name: string
  label?: string
  image: ReactNode
  checked: boolean
  onChange: (value: RadioActionButtonProps['name']) => void
}

export const RadioActionButton: FC<RadioActionButtonProps> = ({
  name,
  image,
  checked,
  label,
  onChange
}) => {
  const onClick = useCallback(() => {
    onChange(name)
  }, [onChange])

  return (
    <Grid
      container
      item
      onClick={onClick}
      sx={{
        p: 1,
        borderRadius: '12px',
        height: '100%',
        cursor: 'pointer',
        border: checked ? BORDERS.SELECTED_PAYMENT : BORDERS.UNACTIVE_PAYMENT,
        '&:hover': {
          border: BORDERS.HOVERED_PAYMENT
        }
      }}
    >
      <Grid
        item
        container
        xs={12}
        justifyContent='space-between'
        alignItems='center'
        pr={1}
      >
        <Grid item>
          <CustomRadio checked={checked} onChange={noop} />
        </Grid>
        <Grid item>
          <Typography variant='main'>{label || name}</Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} mt={3} justifyContent='flex-start'>
        <Grid item pl={1}>
          {image}
        </Grid>
      </Grid>
    </Grid>
  )
}
