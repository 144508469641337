import { Switch, SwitchProps } from '@mui/material'
import { FC, useMemo } from 'react'
import { COLORS } from '../../types/colors'

interface GetStylesParams {
  leftLabel: string
  rightLabel: string
  leftOptionAlign?: string
  rightOptionAlign?: string
}

export const getStyles = (params: GetStylesParams) => {
  const {
    leftLabel,
    rightLabel,
    leftOptionAlign = '15%',
    rightOptionAlign = '15%'
  } = params
  const maxLabelLength = Math.max(leftLabel.length, rightLabel.length)
  const labelWidth = maxLabelLength * 8 + 16

  return {
    '&.MuiSwitch-root': {
      width: labelWidth * 2 + 45,
      height: '55px'
    },
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 10,
      pointerEvents: 'none'
    },
    '&:before': {
      content: `"${leftLabel}"`,
      fontWeight: '400',
      fontSize: '16px',
      left: leftOptionAlign
    },
    ':after': {
      color: COLORS.text_primary,
      content: `"${rightLabel}"`,
      right: rightOptionAlign
    },
    '& .MuiSwitch-switchBase': {
      top: 2,
      left: -6,
      ':hover': {
        backgroundColor: 'transparent',
        opacity: 1
      }
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: `translateX(${labelWidth + 13}px)`,
      ':hover': {
        backgroundColor: 'transparent',
        opacity: 1
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: COLORS.secondary_background
      }
    },
    '& .MuiSwitch-switchBase .MuiSwitch-thumb': {
      height: '32px',
      width: labelWidth + 24,
      boxShadow: 'none',
      backgroundColor: COLORS.main_yellow,
      borderRadius: '20%/50%'
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      height: '32px',
      borderRadius: '10%/50%',
      backgroundColor: COLORS.secondary_background
    }
  }
}

export type ToggleProps = {
  checked: boolean
  onChange: SwitchProps['onChange']
} & SwitchProps &
  Parameters<typeof getStyles>[0]

export const Toggle: FC<ToggleProps> = ({
  checked,
  onChange,
  leftLabel,
  rightLabel
}) => {
  const sx = useMemo(
    () => getStyles({ leftLabel, rightLabel }),
    [leftLabel, rightLabel]
  )

  return <Switch checked={checked} onChange={onChange} disableRipple sx={sx} />
}
