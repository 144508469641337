import { styled } from '@mui/material'
import { COLORS } from '../../types/colors'

export const PasswordInputFieldContainer = styled('div')`
  display: flex;
  flex-direction: column;

  & input {
    padding-left: 22px;
    font-size: 16px;
    opacity: 1;
  }

  & input::placeholder {
    color: ${COLORS.text_secondary};
    opacity: 1;
  }

  ::-ms-reveal {
    display: none;
  }
`

export const ErrorMessageContainer = styled('div')`
  font-size: 14px;
  color: ${COLORS.error_message_red};
`
