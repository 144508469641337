import { useEffect, useState } from 'react'
import { objectKeys } from '../../../../../utils/objectKeys'
import { WalkableStandardEquipment } from './types'
import { StandardEquipment } from '../../../../../api/carSnoopApi/vehicles'
import { OrNull } from '../../../../../types/OrNull'

const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const formatStandardEquipment = (equipment: StandardEquipment) => {
  const categories = objectKeys(equipment)
    .map((category) => {
      const cleanCategory = category.toString().replace(/_/g, ' ')

      return {
        key: category,
        title: cleanCategory.split(' ').map(capitalize).join(' ')
      }
    })
    .sort((a, b) => a.title.localeCompare(b.title))

  return categories.reduce<WalkableStandardEquipment>(
    (acc, { key, title }) => {
      acc[title] = equipment[key]
        .map((item) => {
          const [label] = objectKeys(item)
          const value = item[label]

          return { label, value }
        })
        .sort((a, b) => a.label.localeCompare(b.label))

      return acc
    },
    { _flow: categories.map(({ title }) => title) } as WalkableStandardEquipment
  )
}

const sliceStandardEquipment = (
  equipment: WalkableStandardEquipment,
  maxCount: number
) => {
  const { _flow, ...rest } = equipment

  let count = 0

  return _flow.reduce<WalkableStandardEquipment>(
    (acc, item) => {
      const items = rest[item]
      const allowedItems = maxCount - count

      if (!allowedItems) {
        return acc
      }

      if (allowedItems > items.length) {
        count += items.length
        acc[item] = items
      } else {
        count += allowedItems
        acc[item] = items.slice(0, allowedItems)
      }

      acc._flow.push(item)

      return acc
    },
    { _flow: [] } as WalkableStandardEquipment
  )
}

export const useFormatStandardEquipment = (
  equipment: OrNull<StandardEquipment>,
  maxRows: number
) => {
  const [isExpandable, setIsExpandable] = useState(false)
  const [formattedEquipment, setFormattedEquipment] =
    useState<WalkableStandardEquipment>({ _flow: [] })
  const [slicedEquipment, setSlicedEquipment] =
    useState<WalkableStandardEquipment>({ _flow: [] })

  useEffect(() => {
    if (equipment) {
      const formatted = formatStandardEquipment(equipment)
      const sliced = sliceStandardEquipment(formatted, maxRows)

      const formattedRows = formatted._flow.reduce(
        (acc, item) => acc + formatted[item].length,
        0
      )

      const slicedRows = sliced._flow.reduce(
        (acc, item) => acc + sliced[item].length,
        0
      )

      setIsExpandable(formattedRows > slicedRows)
      setFormattedEquipment(formatted)
      setSlicedEquipment(sliced)
    } else {
      setIsExpandable(false)
      setFormattedEquipment({ _flow: [] })
      setSlicedEquipment({ _flow: [] })
    }
  }, [equipment, maxRows])

  return { isExpandable, formattedEquipment, slicedEquipment }
}
