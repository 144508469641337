import { useCallback, useEffect, useMemo, useRef } from 'react'
import { FormikProps } from 'formik'
import { SelectStatusProps } from './types'

enum FIELD_NAMES {
  status = 'status'
}

export const useInventorySelectStatus = <T extends string, V>({
  selectOptions,
  applySelectVehiclesList,
  vehiclesListMap,
  disabled,
  initialStatus,
  vehiclesList
}: SelectStatusProps<T, V>) => {
  const selectFormRef = useRef<FormikProps<{ status: T }>>(null)

  const onStatusChange = useCallback(
    ({ status }: { status: T }) => {
      if (status !== initialStatus) {
        applySelectVehiclesList(vehiclesListMap[status], status)
      }
    },
    [applySelectVehiclesList, vehiclesListMap, initialStatus]
  )

  const statusOptions = useMemo(
    () =>
      selectOptions.map((statusName) => {
        return {
          name: `${statusName} (${vehiclesListMap[statusName]?.length || 0})`,
          id: statusName
        }
      }),
    [vehiclesListMap, selectOptions]
  )
  const currentStatus = useMemo(
    () => initialStatus || statusOptions[0].id,
    [initialStatus, statusOptions[0].id]
  )

  const initialValues = {
    [FIELD_NAMES.status]: currentStatus
  }

  useEffect(() => {
    if (!disabled) {
      selectFormRef.current?.setFieldValue(FIELD_NAMES.status, currentStatus)
    }
  }, [disabled, currentStatus])

  useEffect(() => {
    if (vehiclesList) {
      applySelectVehiclesList(vehiclesListMap[currentStatus], currentStatus)
    }
  }, [currentStatus, vehiclesListMap, vehiclesList])

  return {
    initialValues,
    onStatusChange,
    FIELD_NAMES,
    options: statusOptions,
    selectFormRef
  }
}
