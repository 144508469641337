import { Grid } from '@mui/material'
import { Formik } from 'formik'
import { FC, useCallback } from 'react'
import { TradeInField } from '../../../../molecules/TradeInField'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const TradeInExample: FC = () => {
  const removeTradeIn = useCallback(async () => {
    // eslint-disable-next-line no-console
    console.log('>>>', 'remove trade in')
  }, [])

  const submitForm = useCallback(async () => {
    // eslint-disable-next-line no-console
    console.log('submit')
  }, [])

  return (
    <ComponentContainer fullWidth>
      <ComponentWithPaperBackground>
        <Grid container flexDirection='column'>
          <Grid item>
            <Formik initialValues={{ tradeIn: '' }} onSubmit={submitForm}>
              <TradeInField
                name='tradeIn'
                onRemoveTradeInConfirm={removeTradeIn}
              />
            </Formik>
          </Grid>
        </Grid>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
