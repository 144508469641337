import { Grid, Typography } from '@mui/material'
import { useCallback, useMemo } from 'react'
import { CollapsibleNotes } from '../../../../atoms/CollapsibleNotes'
import { Condition } from '../../../../atoms/Condition'
import { Loader } from '../../../../atoms/Loader'
import { FormOrchestratorStep } from '../../../../layout/FormOrchestrator/types'
import { DiscardConfirmationModal } from '../../../../molecules/Modal'
import { ResponseStatusMap } from '../../../../types/status'
import { orEmptyString } from '../../../../utils'
import { formattedStringToNumber } from '../../../../utils/formatters/formattedStringToNumber'
import {
  useApiContext,
  useDeviceInfo,
  useMakeService,
  useUserInfo
} from '../../../../utils/hooks'
import { returnStringOrPropByName } from '../../../../utils/returnStringOrPropByName'
import {
  DesktopVehicleDetails,
  TabletVehicleDetails
} from '../../../VehicleDetails/components/VehicleDetails/Layouts'
import { DisplayStandardEquipment } from '../../../VehicleDetails/components/VehicleDetails/StandardEquipment'
import { VehicleDetailsMainInfo } from '../../../VehicleDetails/components/VehicleDetailsMainInfo'
import { VehicleDetailsOverview } from '../../../VehicleDetails/components/VehicleDetailsOverview'
import { useAddVehicleDiscardModal, useAddVehicleFormSlice } from '../hooks'
import { AddVehiclePreviewControls } from './Controls/AddVehiclePreviewControls'
import { AddVehiclePreviewPageCtxvalues } from './types'

export const AddVehiclePreviewPage: FormOrchestratorStep<
  AddVehiclePreviewPageCtxvalues
> = (props) => {
  const { ctx } = props
  const {
    year,
    make,
    model,
    trim,
    vin,
    notes,
    currentMileage,
    condition,
    retailValue,
    tradeinValue,
    address,
    stockNumber,
    interiorColor,
    exteriorColor,
    fuelType,
    engineDescription,
    transmission,
    mpg,
    style,
    drivetrain,
    formLicensePlate,
    formLicensePlateState,
    howMuchIsOwed,
    standardEquipment
  } = ctx.values

  const vehicleProps = {
    interiorColor: returnStringOrPropByName(interiorColor, 'name'),
    exteriorColor: returnStringOrPropByName(exteriorColor, 'name'),
    fuelType,
    engineDescription,
    transmission,
    mpg,
    style,
    drivetrain
  }

  const { isDealer, accountID } = useUserInfo()
  const { isTablet } = useDeviceInfo()
  const { carSnoopApi } = useApiContext()
  const { setNewVehicleId } = useAddVehicleFormSlice()
  const {
    isDiscardModalOpen,
    isNavigateConfirmationOpen,
    setDiscardModalOpen,
    setIsNavigateConfirmationOpen,
    handleDiscard,
    handleNavigationDiscard
  } = useAddVehicleDiscardModal()

  const [createVehicle, createVehicleObservable] = useMakeService(
    async () => {
      const { values } = ctx

      const response = await carSnoopApi.vehicles.createVehicle({
        accountId: accountID,
        ...values,
        addressId: address.id,
        mileage: formattedStringToNumber(currentMileage),
        licensePlate: orEmptyString(formLicensePlate),
        licensePlateState: orEmptyString(formLicensePlateState?.id),
        amountOwed: formattedStringToNumber(howMuchIsOwed),
        year: formattedStringToNumber(year),
        trim: orEmptyString(values.trim),
        retailValue: formattedStringToNumber(retailValue),
        tradeinValue: formattedStringToNumber(tradeinValue),
        condition: condition.id,
        interiorColor: returnStringOrPropByName(interiorColor, 'id'),
        exteriorColor: returnStringOrPropByName(exteriorColor, 'id'),
        drivetrain: orEmptyString(drivetrain),
        engineDescription: orEmptyString(engineDescription),
        fuelType: orEmptyString(fuelType),
        mpg: orEmptyString(mpg),
        style: orEmptyString(style),
        transmission: orEmptyString(transmission),
        standardEquipment
      })

      if (response.status === ResponseStatusMap.Success) {
        const newVehicleId = response.id
        setNewVehicleId(newVehicleId)
        ctx.nextStep()
        return {
          status: response.status,
          id: response.id,
          message: response.message
        }
      }

      return response
    },
    {
      withStatusNotification: true
    }
  )

  const handleSave = useCallback(() => {
    createVehicle()
  }, [])

  const handleEdit = useCallback(() => {
    ctx.prevStep()
  }, [])

  const carName = useMemo(
    () => (make ? `${year} ${make} ${model} ${trim || ''}` : ''),
    [year, make, model, trim]
  )

  const vehicleDetailsComponent = useMemo(
    () => <VehicleDetailsOverview fullView={!!vin} {...vehicleProps} />,
    [vin, vehicleProps]
  )

  const sellerNotesComponent = useMemo(
    () => <CollapsibleNotes initialExpanded>{notes}</CollapsibleNotes>,
    [notes]
  )

  const vehicleInfoComponent = useMemo(
    () => (
      <VehicleDetailsMainInfo
        vin={vin}
        mileage={formattedStringToNumber(currentMileage)}
        condition={condition.id}
        retailValue={formattedStringToNumber(retailValue)}
        tradeinValue={formattedStringToNumber(tradeinValue)}
        address={address.rawValue}
        isDeleteButtonEnabled={false}
        isDealer={isDealer}
        stockNumber={stockNumber}
        vehicleAccountID={accountID}
      />
    ),
    [
      vin,
      currentMileage,
      condition,
      retailValue,
      tradeinValue,
      address.rawValue,
      isDealer,
      stockNumber
    ]
  )

  const standardEquipmentComponent = useMemo(
    () => (
      <DisplayStandardEquipment equipment={standardEquipment} initialExpanded />
    ),
    [standardEquipment]
  )

  return (
    <Grid container spacing={2}>
      <DiscardConfirmationModal
        isOpen={isDiscardModalOpen}
        setOpen={setDiscardModalOpen}
        onSubmit={handleDiscard}
      />
      <DiscardConfirmationModal
        isOpen={isNavigateConfirmationOpen}
        setOpen={setIsNavigateConfirmationOpen}
        onSubmit={handleNavigationDiscard}
      />
      <Condition
        condition={createVehicleObservable.isLoading}
        trueContent={<Loader />}
        falseContent={
          <>
            <Grid container item xs={12} justifyContent='space-between' mb={2}>
              <Grid item>
                <Typography variant='h6'>{carName}</Typography>
              </Grid>
              <Grid item>
                <AddVehiclePreviewControls
                  onSaveClick={handleSave}
                  onEditClick={handleEdit}
                />
              </Grid>
            </Grid>

            <Grid container item>
              <Grid container item spacing={3}>
                <Condition
                  condition={isTablet}
                  trueContent={
                    <TabletVehicleDetails
                      imagesComponent={null}
                      standardEquipmentComponent={standardEquipmentComponent}
                      vehicleDetailsComponent={vehicleDetailsComponent}
                      sellerNotesComponent={sellerNotesComponent}
                      vehicleInfoComponent={vehicleInfoComponent}
                    />
                  }
                  falseContent={
                    <DesktopVehicleDetails
                      imagesComponent={null}
                      standardEquipmentComponent={standardEquipmentComponent}
                      vehicleDetailsComponent={vehicleDetailsComponent}
                      sellerNotesComponent={sellerNotesComponent}
                      vehicleInfoComponent={vehicleInfoComponent}
                    />
                  }
                />
              </Grid>
            </Grid>
          </>
        }
      />
    </Grid>
  )
}
