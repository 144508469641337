import { Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { SuccessIconCircle } from '../../../assets/icons/SuccessIconCircle'
import { FormContainer } from '../../../layout/FormContainer'
import { ScreenContainer } from '../../../layout/ScreenContainer'
import { ConfirmationSuccessConstants } from './constants'

export const ConfirmationSuccessContainer: FC = () => {
  return (
    <FormContainer title={ConfirmationSuccessConstants.title} minHeight='unset' mt={5}>
      <ScreenContainer isLoading={false} wrapperProps={{ paddingX: 2 }}>
        <Grid item textAlign='center'>
          <SuccessIconCircle />
        </Grid>
        <Grid item mt={3}>
          <Typography variant='body1' textAlign='center'>
            {ConfirmationSuccessConstants.firstParagraph}
          </Typography>
        </Grid>
      </ScreenContainer>
    </FormContainer>
  )
}
