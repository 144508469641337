import { FC } from 'react'
import { DetailsContainer } from '../DetailsContainer'
import { DetailsItem } from '../DetailsItem'

export interface PaymentMethodProps {
  method: string
}

export const DisplayPaymentMethod: FC<PaymentMethodProps> = (props) => {
  const { method } = props
  return (
    <DetailsContainer title='Final Payment Method'>
      <DetailsItem withoutDivider title='Payment method' value={method} />
    </DetailsContainer>
  )
}
