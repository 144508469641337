export const vehicles = [
  {
    id: '4558f77b-0803-4055-8faf-a0da4c1ac9eb',
    year: 2022,
    make: 'Acura',
    model: 'ILX',
    trim: '',
    vin: '19UDE2F70NA000633',
    stock_number: '1',
    mileage: 0.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    trade_in_price: 1000
  },
  {
    id: '0c072af6-dd8e-4812-bb1a-71773e4a655b',
    year: 2022,
    make: 'Acura',
    model: 'ILX',
    trim: '4D Sedan',
    vin: '',
    stock_number: '666-4',
    mileage: 0.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    trade_in_price: 10000
  },
  {
    id: '6c7b7b98-322e-44c1-b66f-e7861a962d2f',
    year: 2022,
    make: 'Acura',
    model: 'ILX',
    trim: '',
    vin: '19UDE2F70NA000632',
    stock_number: '1',
    mileage: 576.98888,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    trade_in_price: 2000
  },
  {
    id: '1a9b99a3-c6ad-4a52-82d6-01734291e2e2',
    year: 2022,
    make: 'Acura',
    model: 'ILX',
    trim: '4D Sdn w/Tech&A-SPEC',
    vin: '',
    stock_number: '666-6',
    mileage: 0.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    trade_in_price: 20000
  },
  {
    id: '13547606-e9dc-4adf-a71e-96e186941efc',
    year: 2022,
    make: 'Acura',
    model: 'ILX',
    trim: '4D Sdn w/Tech&A-SPEC',
    vin: '',
    stock_number: '1',
    mileage: 0.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    trade_in_price: 3000
  },
  {
    id: '1b71aca1-eec3-484b-bd52-01b98e4aa257',
    year: 2013,
    make: 'Volvo',
    model: 'XC90',
    trim: '4D SUV AWD 3.2',
    vin: '',
    stock_number: '',
    mileage: 6000.0,
    address_id: 'a7a49d0f-b519-4614-bd1c-d472b1b9121d',
    status: 'For Sale',
    trade_in_price: 4000
  },
  {
    id: '1b10b640-a396-4a81-87bb-7bf5beb15232',
    year: 2022,
    make: 'Acura',
    model: 'ILX',
    trim: '4D Sedan w/Prem Pkg',
    vin: '19UDE2F84NA004809',
    stock_number: '123456',
    mileage: 6666.66666,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    trade_in_price: 1000
  },
  {
    id: '89adae2f-2272-4996-8dfd-f82231029fe0',
    year: 2022,
    make: 'Acura',
    model: 'ILX',
    trim: '',
    vin: '19UDE2F70NA000632',
    stock_number: '1',
    mileage: 0.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    trade_in_price: 2000
  },
  {
    id: '8f162cff-0409-4e6c-8218-5582064693a4',
    year: 2022,
    make: 'Acura',
    model: 'ILX',
    trim: '4D Sdn w/Tech&A-SPEC',
    vin: '',
    stock_number: '666-6',
    mileage: 0.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    trade_in_price: 20000
  },
  {
    id: 'bbfadc86-2c8c-40c2-bb0d-575aec7132d6',
    year: 2022,
    make: 'Acura',
    model: 'ILX',
    trim: '4D Sdn w/Tech&A-SPEC',
    vin: '',
    stock_number: '1',
    mileage: 0.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    trade_in_price: 3000
  },
  {
    id: '243aeb27-1d65-4ba6-bbc3-a74a73c69339',
    year: 2022,
    make: 'Acura',
    model: 'ILX',
    trim: '4D Sedan w/Prem Pkg',
    vin: '19UDE2F84NA004809',
    stock_number: '',
    mileage: 9996660.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    trade_in_price: 1000
  },
  {
    id: '0e20078b-78f9-4041-9e8d-d75ecc1898ee',
    year: 2022,
    make: 'Acura',
    model: 'ILX',
    trim: 's',
    vin: '19UDE2F70NA000635',
    stock_number: '2',
    mileage: 1.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    trade_in_price: 2000
  },
  {
    id: 'b00b4e3c-bb1b-43d9-ba4b-34c750daceae',
    year: 2022,
    make: 'Acura',
    model: 'ILX',
    trim: '4D Sdn w/Tech&A-SPEC',
    vin: '',
    stock_number: '22',
    mileage: 0.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    trade_in_price: 20000
  },
  {
    id: 'e702f007-3e26-4355-b6da-7b2a08eda2ae',
    year: 2022,
    make: 'Acura',
    model: 'ILX',
    trim: '',
    vin: '19UDE2F70NA000634',
    stock_number: '1',
    mileage: 0.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    trade_in_price: 3000
  },
  {
    id: '5bdd5476-88c7-458e-85f8-f9f1bb6891fb',
    year: 2021,
    make: 'BMW',
    model: 'X7',
    trim: 'ALPINA XB7',
    vin: '5UXCX6C19M9G35088',
    stock_number: 'WF5088',
    mileage: 9249.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    trade_in_price: 0
  },
  {
    id: 'd112d9d1-47db-44d2-ac66-5945ed3ac420',
    year: 2022,
    make: 'Acura',
    model: 'ILX',
    trim: '4D Sdn w/Tech&A-SPEC',
    vin: '',
    stock_number: '666-1',
    mileage: 6661.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    trade_in_price: 20000
  },
  {
    id: '1a8ffc71-a53e-4ade-8b2d-dae067adbad0',
    year: 2022,
    make: 'Acura',
    model: 'ILX',
    trim: '',
    vin: '19UDE2F70NA000638',
    stock_number: '2',
    mileage: 2.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    trade_in_price: 3000
  },
  {
    id: 'd719f754-ab56-4393-9d99-74980917ac1e',
    year: 2021,
    make: 'Tesla',
    model: 'Model S',
    trim: 'Plaid',
    vin: '5YJSA1E63MF442013',
    stock_number: 'WF2013T',
    mileage: 5011.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    document_content:
      'https://carsnoop.s3.us-east-2.amazonaws.com/c053b6e5-64ae-4965-af5d-674ad90aeb9c.jpg',
    trade_in_price: 0
  },
  {
    id: '5d64d0ed-d164-43b3-b31e-1351721f7728',
    year: 2020,
    make: 'Bentley',
    model: 'Continental GT',
    trim: 'V8',
    vin: 'SCBCG2ZG9LC076133',
    stock_number: 'WF6133',
    mileage: 9174.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    document_content:
      'https://carsnoop.s3.us-east-2.amazonaws.com/54e4f861-b64e-479a-b35c-116b5f4277ce.jpg',
    trade_in_price: 1120
  },
  {
    id: '0e278888-6e2f-4aa5-9065-561fc602084a',
    year: 2022,
    make: 'Acura',
    model: 'ILX',
    trim: '',
    vin: '19UDE2F70NA000634',
    stock_number: '1',
    mileage: 0.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    trade_in_price: 30400
  },
  {
    id: 'f056116f-9939-4d19-9f5c-06436cd3e15c',
    year: 2022,
    make: 'Acura',
    model: 'ILX',
    trim: '4D Sdn w/Tech&A-SPEC',
    vin: '',
    stock_number: '666-2',
    mileage: 6662.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    trade_in_price: 23030
  },
  {
    id: 'd57eafd7-0351-4471-b4b0-bca03f421474',
    year: 2022,
    make: 'Acura',
    model: 'ILX',
    trim: '4D Sdn w/Tech&A-SPEC',
    vin: '',
    stock_number: '2',
    mileage: 0.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    trade_in_price: 1200
  },
  {
    id: '83c128a9-e7c1-470b-960b-f12326169f23',
    year: 2022,
    make: 'Acura',
    model: 'ILX',
    trim: '4D Sdn w/Tech&A-SPEC',
    vin: '',
    stock_number: '2',
    mileage: 1.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    trade_in_price: 1200
  },
  {
    id: '3f4c0b9b-728d-4664-9929-3a9f98bfe81b',
    year: 2022,
    make: 'Acura',
    model: 'ILX',
    trim: '',
    vin: '19UDE2F70NA000631',
    stock_number: '666-2',
    mileage: 6662.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    trade_in_price: 23030
  },
  {
    id: 'b9381e63-1231-440e-a642-e456410829a3',
    year: 2020,
    make: 'Land Rover',
    model: 'Range Rover Sport',
    trim: 'SVR',
    vin: 'SALWZ2RE9LA889964',
    stock_number: 'F9964',
    mileage: 30946.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    document_content:
      'https://carsnoop.s3.us-east-2.amazonaws.com/162b0cb0-e91c-4162-833f-75e69211319f.jpg',
    trade_in_price: 0
  },
  {
    id: 'b2cdbff8-2df6-46f0-b5fa-2a574a722444',
    year: 2021,
    make: 'Lamborghini',
    model: 'Urus',
    trim: '',
    vin: 'ZPBUA1ZL7MLA11446',
    stock_number: 'WF1446T',
    mileage: 8702.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    document_content:
      'https://carsnoop.s3.us-east-2.amazonaws.com/13576a13-bbdb-42be-802b-7d7fb0bf6aac.jpg',
    trade_in_price: 121837
  },
  {
    id: '0df2d1c4-37ff-4d00-bdbe-53394db5f32a',
    year: 2021,
    make: 'Lamborghini',
    model: 'Urus',
    trim: '',
    vin: 'ZPBUA1ZL1MLA14245',
    stock_number: 'WF4245T',
    mileage: 9022.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    document_content:
      'https://carsnoop.s3.us-east-2.amazonaws.com/afeed095-3f59-4364-a292-0d169711c997.jpg',
    trade_in_price: 123456789
  },
  {
    id: 'af4462b7-e2fd-4484-913f-561982eb9839',
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'S-Class',
    trim: 'Brabus',
    vin: 'W1K6G7GB4MA045888',
    stock_number: 'WF5888',
    mileage: 2044.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    document_content:
      'https://carsnoop.s3.us-east-2.amazonaws.com/d80f8599-da45-4f97-b762-9693e630486d.jpg',
    trade_in_price: 0
  },
  {
    id: 'ab3ac8a8-340b-438b-8bcd-d647be5f7a6b',
    year: 2022,
    make: 'Acura',
    model: 'ILX',
    trim: '4D Sdn w/Tech&A-SPEC',
    vin: '',
    stock_number: '2',
    mileage: 1.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    trade_in_price: 1200
  },
  {
    id: 'd92ce2d7-53f8-4f87-9f71-f66b97159fa3',
    year: 2022,
    make: 'Land Rover',
    model: 'Range Rover Sport',
    trim: 'SVR',
    vin: 'SALWZ2RE4NA230970',
    stock_number: 'F0970',
    mileage: 3438.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    trade_in_price: 98213
  },
  {
    id: '04fe2214-fb1f-4c57-a681-cdf4f935c901',
    year: 2022,
    make: 'Audi',
    model: 'RS 7',
    trim: '4.0T',
    vin: 'WUAPCBF20NN904292',
    stock_number: 'WF4292T',
    mileage: 6181.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    document_content:
      'https://carsnoop.s3.us-east-2.amazonaws.com/9f79a5b5-cc07-4fce-bfe2-1c1569aed388.jpg',
    trade_in_price: 763254
  },
  {
    id: '0ff7704c-0f90-4ec3-990e-87d88f9c0c7b',
    year: 2019,
    make: 'Land Rover',
    model: 'Range Rover Sport',
    trim: 'SVR',
    vin: 'SALWZ2SE6KA823915',
    stock_number: 'WF3915',
    mileage: 5698.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    document_content:
      'https://carsnoop.s3.us-east-2.amazonaws.com/031128a9-8d2c-46e6-9de6-c75f6517f15f.jpg',
    trade_in_price: 4836589
  },
  {
    id: '4da4eb3a-c447-4794-9f9a-df7e8dd30c46',
    year: 2019,
    make: 'Land Rover',
    model: 'Range Rover',
    trim: '5.0L V8 Supercharged Autobiography',
    vin: 'SALGV5RE0KA541180',
    stock_number: 'WF1180T',
    mileage: 22720.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    document_content:
      'https://carsnoop.s3.us-east-2.amazonaws.com/b1933bf8-6c6c-4f58-b29b-e092e3ce7d1c.jpg',
    trade_in_price: 29436456
  },
  {
    id: '139291a2-032e-46d8-b457-9413573f0f1b',
    year: 2022,
    make: 'Acura',
    model: 'ILX',
    trim: '4D Sdn w/Tech&A-SPEC',
    vin: '',
    stock_number: '2',
    mileage: 1.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    trade_in_price: 1200
  },
  {
    id: '13d15258-a73a-4c80-b554-2df172d5c707',
    year: 2022,
    make: 'Acura',
    model: 'ILX',
    trim: '',
    vin: '19UDE2F70NA000630',
    stock_number: '666-3',
    mileage: 66632.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    trade_in_price: 66632
  },
  {
    id: '2333c8b5-d864-4734-827d-d37d8c1fc737',
    year: 2020,
    make: 'Land Rover',
    model: 'Range Rover',
    trim: '5.0L V8 Supercharged Autobiography',
    vin: 'SALGV2SE8LA595286',
    stock_number: 'WF5286',
    mileage: 13605.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    document_content:
      'https://carsnoop.s3.us-east-2.amazonaws.com/98f54c9f-e2f5-4aec-9bad-921b7ac6577a.jpg',
    trade_in_price: 123456
  },
  {
    id: 'daf435ab-5ac6-4426-9a21-787bec2de2c3',
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'S-Class',
    trim: 'S 65 AMG',
    vin: 'WDDXJ7KB9JA034555',
    stock_number: 'WF3455C',
    mileage: 22494.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    document_content:
      'https://carsnoop.s3.us-east-2.amazonaws.com/cc12cd94-ef0c-4bfa-aaac-08d44ac80f86.jpg',
    trade_in_price: 123456
  },
  {
    id: '90020376-aecd-44fc-b112-261fd10df83e',
    year: 2019,
    make: 'Lamborghini',
    model: 'Urus',
    trim: 'Base',
    vin: 'ZPBUA1ZL9KLA05600',
    stock_number: 'WF5600',
    mileage: 961.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    document_content:
      'https://carsnoop.s3.us-east-2.amazonaws.com/1d7852aa-a2c7-49ea-b7d6-7a252c1d1239.jpg',
    trade_in_price: 6742
  },
  {
    id: '024298f1-50cf-4cd2-8949-1712d5480ec0',
    year: 2018,
    make: 'Porsche',
    model: '911',
    trim: 'GT3',
    vin: 'WP0AC2A9XJS176256',
    stock_number: 'F6256T',
    mileage: 7300.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    document_content:
      'https://carsnoop.s3.us-east-2.amazonaws.com/c0303ef2-11d8-4732-a289-b3bc7db7d608.jpg',
    trade_in_price: 89104
  },
  {
    id: '4230cd18-4abd-4dbd-8db5-efbf08ab30bf',
    year: 2022,
    make: 'Acura',
    model: 'ILX',
    trim: '',
    vin: '19UDE2F70NA000633',
    stock_number: '1',
    mileage: 0.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    trade_in_price: 1200
  },
  {
    id: '493e2ce6-2418-4713-a616-01459114de54',
    year: 2022,
    make: 'Acura',
    model: 'ILX',
    trim: '4D Sedan',
    vin: '',
    stock_number: '666-4',
    mileage: 0.0,
    address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    status: 'For Sale',
    trade_in_price: 1500
  }
]
