import { FC } from 'react'
import { LinkedInCallback } from 'react-linkedin-login-oauth2'
import { Navigate, Route, Routes } from 'react-router-dom'
import { CreateOfferSuccessScreen } from '../../forms/screens/CreateOfferSuccessScreen'
import { routes } from '../../routes'
import { AddVehiclePage } from '../../routes/AddVehicle'
import { ContractPage } from '../../routes/Contract'
import { CompletedContractPage } from '../../routes/ContractCompleted'
import { CreateAccountPage } from '../../routes/CreateAccount'
import {
  DealDetailsPage,
  DealsPage,
  SellingRequestsPage
} from '../../routes/Deals'
import { DeleteUser } from '../../routes/DeleteUser'
import { DepositResultPage } from '../../routes/DepositResult'
import { ComponentsPreviewPage } from '../../routes/Dev'
import { TypographyExample } from '../../routes/Dev/atoms/TypographyExample'
import { ExternalServiceRegistrationPage } from '../../routes/ExternalServiceRegistration'
import { FindCarPage } from '../../routes/FindCar/FindCarPage'
import { ForgotPassword } from '../../routes/ForgotPassword'
import { GaragePage } from '../../routes/Garage'
import { InventoryPage } from '../../routes/Inventory'
import { LogInPage } from '../../routes/LogInPage'
import { MainPage } from '../../routes/MainPage'
import { OfferPageRouteResolver } from '../../routes/OfferDetails/OfferPageRouteResolver'
import { OfferPage } from '../../routes/Offers'
import { PaymentConfirmation } from '../../routes/PaymentConfirmation'
import { PersonalDetailsPage } from '../../routes/PersonalDetails'
import { PublicVehicleDetailsPage } from '../../routes/PublicVehicleDetails'
import { RegistrationByEmailPage } from '../../routes/RegistrationByEmail'
import { ResetPassword } from '../../routes/ResetPassword'
import { SubscriptionPage } from '../../routes/SubscriptionPage'
import { SuccessfulDealPayment } from '../../routes/SuccessfulDealPayment'
import { VehicleDetailsPage } from '../../routes/VehicleDetails'
import { VehicleEditPage } from '../../routes/VehicleEdit'
import { returnSubscriptionFeatureStatus } from '../../utils/returnSubscriptionFeatureStatus'

export const ApplicationRouter: FC = () => {
  const { isSubscriptionFeatureEnabled } = returnSubscriptionFeatureStatus()

  return (
    <Routes>
      <Route path={routes.carSearch} element={<FindCarPage />} />
      <Route path={routes.contracts.completed} element={<CompletedContractPage />} />
      <Route path={routes.contracts.paymentConfirmed} element={<PaymentConfirmation />} />
      <Route path={routes.contracts.paymentSuccess} element={<SuccessfulDealPayment />} />
      <Route path={routes.contracts.transaction} element={<ContractPage />} />
      <Route path={routes.dashboard} element={<MainPage />} />
      <Route path={routes.deals.completed} element={<CreateOfferSuccessScreen />} />
      <Route path={routes.deals.details} element={<DealDetailsPage />} />
      <Route path={routes.deals.index} element={<DealsPage />} />
      <Route path={routes.deals.requests} element={<SellingRequestsPage />} />
      <Route path={routes.deleteUser} element={<DeleteUser />} />
      <Route path={routes.forgotPassword} element={<ForgotPassword />} />
      <Route path={routes.garage} element={<GaragePage />} />
      <Route path={routes.inventory} element={<InventoryPage />} />
      <Route path={routes.linkedin} element={<LinkedInCallback />} />
      <Route path={routes.login} element={<LogInPage />} />
      <Route path={routes.offers.details} element={<OfferPageRouteResolver />} />
      <Route path={routes.offers.payment} element={<OfferPage />} />
      <Route path={routes.offers.paymentResult} element={<DepositResultPage />} />
      <Route path={routes.personalSettings} element={<PersonalDetailsPage />} />
      <Route path={routes.registration.external} element={<ExternalServiceRegistrationPage />} />
      <Route path={routes.registration.invitation} element={<RegistrationByEmailPage />} />
      <Route path={routes.registration.manual} element={<CreateAccountPage />} />
      <Route path={routes.resetPassword} element={<ResetPassword />} />
      <Route path={routes.vehicles.add} element={<AddVehiclePage />} />
      <Route path={routes.vehicles.detail} element={<VehicleDetailsPage />} />
      <Route path={routes.vehicles.edit} element={<VehicleEditPage />} />
      <Route path={routes.vehicles.publicDetail} element={<PublicVehicleDetailsPage />} />
      {isSubscriptionFeatureEnabled && (
        <Route
          path={routes.subscription.index}
          element={<SubscriptionPage />}
        />
      )}
      {process.env.NODE_ENV === 'development' && (
        <>
          <Route
            path={routes.dev.index}
            element={<ComponentsPreviewPage />}
          />
          <Route
            path={routes.dev.typography}
            element={<TypographyExample />}
          />
        </>
      )}
      <Route path='*' element={<Navigate to={routes.dashboard} />} />
    </Routes>
  )
}
