import { FC } from 'react'
import { Field, FieldProps } from 'formik'
import { Checkbox } from '@mui/material'
import { CheckboxContainer, CheckboxLabel } from './TermsCheckbox.styles'

type TermsCheckboxProps = {
  name: string
  label?: string | JSX.Element
  hint: string | JSX.Element
  value?: boolean
}

export const TermsCheckbox: FC<TermsCheckboxProps> = ({
  name,
  label,
  value,
  hint
}) => {
  return (
    <CheckboxContainer>
      <CheckboxLabel>
        <Field type='checkbox' name={name} value={value}>
          {({ field }: FieldProps) => {
            return <Checkbox {...field} />
          }}
        </Field>
        {label}
      </CheckboxLabel>
      {hint}
    </CheckboxContainer>
  )
}
