import { FC, ReactNode } from 'react'
import { Grid } from '@mui/material'

export interface HelperTextProps {
  left?: ReactNode
  right?: ReactNode
}

export const HelperText: FC<HelperTextProps> = (props) => {
  const { left, right } = props

  return (
    <Grid container justifyContent='space-between'>
      <Grid item>{left}</Grid>
      <Grid item>{right}</Grid>
    </Grid>
  )
}
