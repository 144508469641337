import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { useAppLinks } from '../../../../utils/hooks'
import { CustomNavLink } from '../CustomNavLink'

export const AppButtons = () => {
  const appLinks = useAppLinks()

  const buttons = appLinks.map(({ label, route }) => {
    if (route.startsWith('http')) {
      return (
        <Button key={label} sx={{ whiteSpace: 'nowrap' }}>
          <Typography
            component="a"
            href={route}
            lineHeight={1}
            target="_blank"
            variant='overline'
          >
            {label}
          </Typography>
        </Button>

      )
    }
    return (
      <Button key={label} sx={{ whiteSpace: 'nowrap' }}>
        <Typography
          component={CustomNavLink}
          lineHeight={1}
          to={route}
          variant='overline'
        >
          {label}
        </Typography>
      </Button>
    )
  })

  return <>{buttons}</>
}
