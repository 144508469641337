import {
  FC,
  PropsWithChildren,
  useRef,
  useMemo,
  useCallback,
  ChangeEvent
} from 'react'
import imageCompression from 'browser-image-compression'
import {
  compressImages,
  CompressImageResponse,
  transformToCompressImageResponse,
  CompressImageSuccessResponse
} from '../../utils/compressImages'
import {
  MIN_IMG_SIZE_FOR_COMPRESSION_KB,
  MAX_PHOTO_SIZE_MB
} from '../../constants/applicationConstants'
import { getFileExtension } from '../../utils'

type CompressImageOptions = Parameters<typeof imageCompression>[1]

export type UploadDocumentCallbackPropsItem = CompressImageSuccessResponse

interface UploadDocumentProps {
  name: string
  multiple?: boolean
  additionalExtensions?: string[]
  additionalExtensionsMaxSize?: number
  initialCompression?: boolean
  acceptType?: string
  onChange: (files: CompressImageResponse) => void | Promise<void>
  onBeforeChange?: (files: File[]) => void | Promise<void>
  onCancel?: () => void
  minSizeForCompression?: number
}

export const UploadDocument: FC<
  PropsWithChildren<UploadDocumentProps & CompressImageOptions>
> = ({
  name,
  maxSizeMB = MAX_PHOTO_SIZE_MB,
  minSizeForCompression = MIN_IMG_SIZE_FOR_COMPRESSION_KB,
  acceptType = 'image/*',
  multiple = false,
  onBeforeChange,
  onChange,
  onProgress,
  onCancel,
  initialCompression = true,
  children,
  additionalExtensions = [],
  additionalExtensionsMaxSize
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null)

  const settings = useMemo(() => {
    return {
      maxSizeMB,
      onProgress,
      useWebWorker: true,
      additionalExtensions,
      additionalExtensionsMaxSize
    }
  }, [maxSizeMB, onProgress])

  const onFileChange = useCallback(
    async ({ target }: ChangeEvent<HTMLInputElement>) => {
      const files = Array.from(target.files || [])
      await onBeforeChange?.(files)

      if (files.length) {
        if (initialCompression) {
          const compressedFiles = await compressImages(
            files,
            settings,
            minSizeForCompression
          )

          onChange(compressedFiles)
        } else {
          const mappedFiles = await Promise.all(
            files.map((file) => {
              const fileExtension = getFileExtension(file.type, '/')

              return transformToCompressImageResponse(
                file,
                file,
                {
                  ...settings,
                  additionalExtensions,
                  additionalExtensionsMaxSize
                },
                fileExtension
              )
            })
          )

          onChange(mappedFiles)
        }
      } else {
        onCancel?.()
      }

      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
    },
    [onChange]
  )

  return (
    <label htmlFor={name}>
      <input
        ref={fileInputRef}
        accept={acceptType}
        id={name}
        multiple={!!multiple}
        type='file'
        onChange={onFileChange}
        style={{ display: 'none' }}
      />
      {children}
    </label>
  )
}
