import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { StandardEquipment } from '../../../../../api/carSnoopApi/vehicles'
import { For, Show } from '../../../../../atoms/JSXExtensions'
import { LinkButton } from '../../../../../atoms/LinkButton'
import { OrNull } from '../../../../../types/OrNull'
import { BGCOLORS, COLORS } from '../../../../../types/colors'
import { EmptyStandardEquipment } from './EmptyStandardEquipment'
import { WalkableStandardEquipment } from './types'
import { useFormatStandardEquipment } from './useFormatStandardEquipment'

interface DisplayStandardEquipmentProps {
  equipment: OrNull<StandardEquipment>
  initialExpanded?: boolean
}

const maxCount = 14

export const DisplayStandardEquipment: FC<DisplayStandardEquipmentProps> = ({
  equipment,
  initialExpanded = false
}) => {
  const theme = useTheme();
  const isBreakpointDownLg = useMediaQuery(theme.breakpoints.down('lg'));
  const [isExpanded, setExpanded] = useState(initialExpanded)
  const [standardEquipment, setStandardEquipment] =
    useState<WalkableStandardEquipment>({
      _flow: []
    })

  const { isExpandable, formattedEquipment, slicedEquipment } =
    useFormatStandardEquipment(equipment, maxCount)

  useEffect(() => {
    if (isExpanded || isBreakpointDownLg) {
      setStandardEquipment(formattedEquipment)
    } else {
      setStandardEquipment(slicedEquipment)
    }
  }, [formattedEquipment, slicedEquipment, isExpanded, isBreakpointDownLg])

  const handleExpand = useCallback(() => {
    setExpanded((prevState) => !prevState)
  }, [setExpanded])

  const expandedControl = useMemo(() => {
    const expandText = isExpanded ? 'View Less' : 'View All'

    return <LinkButton onClick={handleExpand} label={expandText} />
  }, [isExpanded, handleExpand])

  return (
    <Grid container
      sx={{
        backgroundColor: BGCOLORS.white,
        borderRadius: '10px',
        padding: { xs: 2, md: 3 }
      }}
    >
      <Grid item xs={12}>
        <Typography component='h2' marginBottom={{ xs: 2, md: 3 }} variant='h6'>
          Standard Equipment
        </Typography>
      </Grid>
      <Grid item
        sx={{
          '& > div:not(:last-of-type)': {
            borderBottomColor: COLORS.border_gray,
            borderBottomStyle: 'solid',
            borderBottomWidth: 1,
            marginBottom: theme.spacing(2)
          }
        }}
        xs={12}
      >
        <Show
          when={equipment}
          fallback={
            <Grid container>
              <EmptyStandardEquipment />
            </Grid>
          }
        >
          {() => {
            const equip = standardEquipment

            return (
              <For of={equip._flow}>
                {(equipCategoryKey, index) => {
                  const equipCategory = equip[equipCategoryKey]

                  return (
                    <Grid container item key={index}>
                      <Grid item xs={12}>
                        <Typography variant='label1'>
                          {equipCategoryKey}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <ul>
                          <For of={equipCategory}>
                            {({ label, value }) => {
                              return (
                                <li key={label}>
                                  <Typography variant='body1'>
                                    {label}: {value}
                                  </Typography>
                                </li>
                              )
                            }}
                          </For>
                        </ul>
                      </Grid>
                    </Grid>
                  )
                }}
              </For>
            )
          }}
        </Show>
      </Grid>
      <Show when={isExpandable && !isBreakpointDownLg}>
        <Grid item>{expandedControl}</Grid>
      </Show>
    </Grid>
  )
}
