import { Grid, Link, Typography } from '@mui/material'
import { FC } from 'react'
import { SomethingWentWrong } from '../../../../../assets/icons/SomethingWentWrong'
import { ActionBlock } from '../../../../../atoms/ActionBlock'
import { OperationResult } from '../../../../../atoms/OperationResult'
import { SUPPORT_EMAIL } from '../../../../../constants/applicationConstants'
import { COLORS } from '../../../../../types/colors'

interface FindCarResultErrorScreenProps {
  onBackClick: () => void
}

export const FindCarResultErrorScreen: FC<FindCarResultErrorScreenProps> = ({
  onBackClick
}) => {
  return (
    <Grid
      container
      alignItems='center'
      justifyContent='center'
    >
      <Grid
        container
        rowSpacing={4}
        sx={{
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          margin: '0 auto',
          minHeight: '400px',
          width: '400px',
        }}
      >
        <OperationResult
          header={<Typography component='h3' variant='h6'>Something went wrong</Typography>}
          icon={<SomethingWentWrong />}
          mainText={
            <Typography variant='body1' sx={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}>
              {`Something went wrong during request creation.\n Contact `}
              <Link
                color={COLORS.accent_blue}
                href={`mailto:${SUPPORT_EMAIL}`}
              >
                CarSnoop support
              </Link>
            </Typography>
          }
          actionBlock={
            <ActionBlock
              schema={[
                {
                  label: 'Back to Request',
                  type: 'primary',
                  onClick: onBackClick
                }
              ]}
            />
          }
        />
      </Grid>
    </Grid>
  )
}
