import { ReadShippingCostParams } from '../../../api/types'
import { actionsFindCarForm } from '../../../store/units/findCarForm'
import { ResponseStatusMap } from '../../../types/status'
import {
  useApiContext,
  useAppDispatch,
  useMakeService
} from '../../../utils/hooks'

export const useShippingDetails = (selected: boolean = false) => {
  const { externalCarSnoopApi } = useApiContext()
  const dispatch = useAppDispatch()

  const [readShippingDetails, readShippingDetailsObservable] = useMakeService(
    async (params: ReadShippingCostParams & { id: string }) => {
      const res = await externalCarSnoopApi.vehicles.readShippingCost(params)

      if (res.status === ResponseStatusMap.Success) {
        const dataToSend = {
          id: params.id,
          cost: res.cost
        }

        if (selected) {
          dispatch(
            actionsFindCarForm.modifySelectedVehiclesShippingCost(dataToSend)
          )
        } else {
          dispatch(
            actionsFindCarForm.modifyVehiclesListShippingCost(dataToSend)
          )
        }
      }

      return res
    }
  )

  return { readShippingDetailsObservable, readShippingDetails }
}
