import { createAsyncThunk } from '@reduxjs/toolkit'
import { carSnoopApi } from '../../../../api'
import type { ReadMakersResponse } from './types'

export const readMakers = createAsyncThunk(
  'readMakers',
  async (): Promise<ReadMakersResponse> => {
    const response = await carSnoopApi.vehicles.readMakes()

    if (response.status >= 200 && response.status < 300) {
      const data = await response.json()

      return {
        status: 'succeeded',
        data
      }
    }

    return {
      status: 'failed',
      data: []
    }
  }
)
