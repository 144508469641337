import { Grid, SxProps } from '@mui/material'
import { useFormikContext } from 'formik'
import { FC, useCallback } from 'react'
import { For } from '../../../../atoms/JSXExtensions'
import { ISubscriptionOption } from '../SubscriptionMicroform.types'
import { SubscriptionRadioButton } from './SubscriptionRadioButton'

interface ISubscriptionRadioGroupProps {
  name: string
  options: ISubscriptionOption[]
  containerSx?: SxProps
}

export const SubscriptionRadioGroup: FC<ISubscriptionRadioGroupProps> = ({
  options,
  name,
  containerSx = {}
}) => {
  const { setFieldValue, values } = useFormikContext<Record<string, string>>()

  const handleChange = useCallback(
    (value: string) => setFieldValue(name, value),
    [setFieldValue, name]
  )

  return (
    <Grid container spacing='24px' sx={containerSx}>
      <For<ISubscriptionOption> of={options}>
        {({ name: optionName, label, cost, dealsPerMonth }) => (
          <Grid item>
            <SubscriptionRadioButton
              checked={values[name] === optionName}
              onChange={handleChange}
              name={optionName}
              label={label}
              cost={cost}
              dealsPerMonth={dealsPerMonth}
            />
          </Grid>
        )}
      </For>
    </Grid>
  )
}
