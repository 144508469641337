export enum TABS {
  CONTRACT_INFORMATION = 'Contract Information',
  DOCUMENTS = 'Documents',
  CHAT = 'Chat'
}

export const completeContractMainText =
  'Would you like to complete the contract?'

export const completeContractText =
  'By completing the contract you confirm that \n the Purchase Agreement is signed, you have received your deposit \n payment, and all required documents have been provided.'

export const sellerDeclineDealMainText =
  'Are you sure you would like to decline the deal?'

export const sellerDeclineDealText = 'Declined deals cannot be recovered.'

export const buyerDeclineDealMainText =
  'Are you sure you would like to cancel the deal?'

export const buyerDeclineDealText = 'Canceled deals can not be recovered.'
