import { useCallback } from 'react'
import { useRequestToast } from '../../useToast'
import { RequestStatus, RequestStatusMap } from '../../../../types/status'
import { RequestNotificationSettings } from '../types'

export const useRequestNotification = ({
  withStatusNotification
}: {
  withStatusNotification: RequestNotificationSettings
}) => {
  const { openRequestToast } = useRequestToast()

  const triggerNotification = useCallback(
    (status: RequestStatus) => {
      const [errorNotificationSetting = 0, successNotificationSetting = 0] =
        Array.isArray(withStatusNotification)
          ? withStatusNotification
          : [withStatusNotification, withStatusNotification]

      if (errorNotificationSetting && status === RequestStatusMap.Failed) {
        openRequestToast({ status })
      }

      if (successNotificationSetting && status === RequestStatusMap.Succeeded) {
        openRequestToast({ status })
      }
    },
    [withStatusNotification]
  )

  return { triggerNotification }
}
