import { FC } from 'react'
import { ScreenContainer } from '../../../layout/ScreenContainer'
import { Loader } from '../../../atoms/Loader'

export const PreparingAccountScreen: FC = () => {
  return (
    <ScreenContainer>
      <Loader />
    </ScreenContainer>
  )
}
