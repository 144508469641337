import { FC } from 'react'
import { ErrorMessage, Field, FieldProps, FieldValidator } from 'formik'
import { Label } from '../Label'
import { Condition } from '../Condition'
import {
  InputErrorMessage,
  TextInputFieldContainer
} from './TextInputField.styles'

type TextInputFieldProps = {
  name: string
  label: string
  placeholder: string
  isValid?: boolean
  isErrorMessageRequired?: boolean
  onInput?: () => void
  autocomplete?: string
  disabled?: boolean
  validate?: FieldValidator
}

export const TextInputField: FC<TextInputFieldProps> = ({
  name,
  label,
  placeholder,
  isValid,
  isErrorMessageRequired = true,
  onInput,
  autocomplete,
  disabled = false,
  validate
}) => {
  return (
    <TextInputFieldContainer hasError={!isValid}>
      <Label name={name} label={label} />
      <Field
        id={name}
        name={name}
        placeholder={placeholder}
        validate={validate}
      >
        {(props: FieldProps) => {
          return (
            <input
              {...props.field}
              autoComplete={autocomplete}
              placeholder={placeholder}
              disabled={disabled}
              onFocus={() => {
                props.form.setTouched({
                  ...props.form.touched,
                  [props.field.name]: false
                })
              }}
              onInput={() => onInput && onInput()}
              type='text'
            />
          )
        }}
      </Field>
      <Condition
        condition={isErrorMessageRequired}
        trueContent={
          <InputErrorMessage>
            <ErrorMessage name={name} />
          </InputErrorMessage>
        }
      />
    </TextInputFieldContainer>
  )
}
