import { Grid, Typography } from '@mui/material'
import { ChangeEvent, useCallback, useMemo } from 'react'
import { RadioGroup, RadioGroupProps } from '../../../../../atoms/RadioGroup'
import { RequiredLabel } from '../../../../../atoms/RequiredText'
import { stringToReferenceModel } from '../../../../../utils/autoselect/stringToReferenceModel'

interface AddVehicleFormControlsProps<T extends string> {
  currentFlow: T
  flowList: T[] | readonly T[]
  onFlowChange: (nextFlow: T) => void
  ControlsComponentsMap: Record<T, JSX.Element | null>
}

export const AddVehicleFormControls = <T extends string>({
  currentFlow,
  flowList,
  onFlowChange,
  ControlsComponentsMap
}: AddVehicleFormControlsProps<T>) => {
  const options = useMemo(
    () => flowList.map(stringToReferenceModel),
    [flowList]
  )

  const onChange: RadioGroupProps['onChange'] = useCallback(
    (_: ChangeEvent<HTMLInputElement>, nextFlow: string) => {
      onFlowChange(nextFlow as T)
    },
    [onFlowChange]
  )

  return (
    <Grid container gap={2}>
      <Grid item xs={12}>
        <Typography variant='main' fontWeight='500'>
          Let’s Get Started!
        </Typography>
        <RequiredLabel />
      </Grid>
      <Grid item xs={12}>
        <RadioGroup
          row
          name='flow'
          options={options}
          value={currentFlow}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        {ControlsComponentsMap[currentFlow]}
      </Grid>
    </Grid>
  )
}
