import { Grid, Typography } from '@mui/material'
import { FieldValidator, useField, useFormikContext } from 'formik'
import { ChangeEvent, FC, FocusEvent, useCallback, useState } from 'react'
import { COLORS } from '../../types/colors'
import { getIsFieldRequired } from '../../utils/validators/getIsFieldRequired'
import { InputField } from '../InputField/InputField'
import { HelperText } from './components/HelperText'
import { MAX_CHARS } from './constants'

export interface NotesFieldProps {
  name: string
  label: string
  placeholder?: string
  disabled?: boolean
  isLoading?: boolean
  maxChars?: number
  backgroundColor?: string
  validate?: FieldValidator
  onBlur?: () => void
  rows?: {
    min: number
    max: number
  }
  required?: boolean | ((name: string) => boolean)
}

export const NotesField: FC<NotesFieldProps> = (props) => {
  const {
    name,
    label,
    placeholder,
    disabled,
    isLoading,
    maxChars = MAX_CHARS,
    backgroundColor,
    validate,
    onBlur: customOnBlur,
    rows = {
      min: 5,
      max: 5
    },
    required = false
  } = props
  const [field, meta, helpers] = useField<string>({ name, validate })
  const { value, onChange, onBlur } = field
  const { error } = meta
  const [isOverflow, setIsOverflow] = useState(false)
  const { validateField } = useFormikContext()
  const isRequiredField = getIsFieldRequired(required, name)

  const handleFocus = useCallback(() => {
    helpers.setError('')
  }, [helpers])

  const handleBlur = useCallback(
    (e: FocusEvent) => {
      setIsOverflow(false)
      onBlur(e)
      validateField(name)
      customOnBlur && customOnBlur()
    },
    [onBlur, customOnBlur]
  )

  const getValidLength = useCallback((v: string) => {
    return v.length
  }, [])

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value: innerValue } = e.target
      const validInnerValueLength = getValidLength(innerValue)

      if (validInnerValueLength > maxChars) {
        setIsOverflow(true)
        return
      }

      setIsOverflow(false)
      onChange(e)
    },
    [maxChars, setIsOverflow, setIsOverflow, onChange]
  )

  return (
    <Grid container spacing='4px' position='relative'>
      <Grid item xs={12}>
        <InputField
          multiline
          maxRows={rows.max}
          minRows={rows.min}
          name={name}
          value={value}
          label={label}
          placeholder={placeholder}
          disabled={disabled}
          isLoading={isLoading}
          // Warning: validateDOMNesting(...): <div> cannot appear as a descendant of <p>.
          // https://github.com/mui/material-ui/issues/33339
          // eslint-disable-next-line
          // @ts-ignore
          FormHelperTextProps={{ component: 'div' }}
          backgroundColor={backgroundColor}
          helperText={
            <HelperText
              left={error}
              right={
                <Typography
                  variant='subtitle2'
                  color={isOverflow ? COLORS.text_red : ''}
                >{`${getValidLength(value)}/${maxChars}`}</Typography>
              }
            />
          }
          error={!!error}
          borderError={isOverflow}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          required={isRequiredField}
        />
      </Grid>
    </Grid>
  )
}
