import { MenuItem } from '@mui/material';
import { styled } from '@mui/system';
import { BORDERS, COLORS } from '../../types/colors';

export const SelectElementContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isDisabled' && prop !== 'hasError'
}) <{ isDisabled: boolean; hasError: boolean }>`
  & div {
    height: 46px;
  }

  & div > div > div {
    padding-top: 12px;
    padding-bottom: 11px;
  }

  & div label {
    top: -5px;
    margin-left: 8px;
    color: ${COLORS.main_black};
  }

  & div label {
    opacity: ${({ isDisabled }) => (isDisabled ? '0.35' : '1')};
  }

  & fieldset,
  .Mui-focused fieldset {
    border: ${({ hasError }) => (hasError ? BORDERS.SM_ERROR : '')};
  }
`

export const SelectInfoBlock = styled(MenuItem)`
  height: 46px;
  justify-content: center;
  font-size: 14px;
  overflow: hidden;
`
