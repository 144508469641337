import { DependentField } from '../../../atoms/DependentField'
import { AutoselectInputField } from '../../../atoms/AutoselectInputField'
import { FIND_CAR_FIELD_NAMES } from '../../../types/enums/findCar'
import { Option } from '../../../types/Autoselect'
import { FilterFieldSetInfo } from './types'

export type ModelFieldProps = Parameters<typeof AutoselectInputField<Option>>[0]
export type YearFromFieldProps = ModelFieldProps
export type YearToFieldProps = ModelFieldProps

export const DependentModelField = DependentField<
  ModelFieldProps,
  FilterFieldSetInfo
>(AutoselectInputField<Option>, {
  modifyProps: (props, { make }) =>
    make ? props : { ...props, disabled: true },
  deps: [FIND_CAR_FIELD_NAMES.MAKE]
})

export const DependentYearFromField = DependentField<
  YearFromFieldProps,
  FilterFieldSetInfo
>(AutoselectInputField<Option>, {
  modifyProps: (props, { model }) =>
    model ? props : { ...props, disabled: false },
  deps: [FIND_CAR_FIELD_NAMES.MODEL, FIND_CAR_FIELD_NAMES.MAKE]
})

export const DependentYearToField = DependentField<
  YearToFieldProps,
  FilterFieldSetInfo
>(AutoselectInputField<Option>, {
  modifyProps: (props, { yearFrom }) => {
    const { options } = props
    if (yearFrom) {
      const filteredOptions = options.filter(
        (option) => option.id >= yearFrom.id
      )
      return { ...props, options: filteredOptions }
    }
    return { ...props, disabled: false }
  },
  deps: [FIND_CAR_FIELD_NAMES.YEAR_FROM, FIND_CAR_FIELD_NAMES.MAKE]
})
