import { useCallback, MouseEventHandler, forwardRef } from 'react'
import { Box } from '@mui/material'
import { NavLink, NavLinkProps } from 'react-router-dom'

export type LinkWithEffectProps = {
  effect: () => void
} & NavLinkProps

export const LinkWithEffect = forwardRef<
  HTMLAnchorElement,
  LinkWithEffectProps
>((props, ref) => {
  const { effect, ...linkProps } = props

  const handleClick = useCallback<MouseEventHandler>(() => {
    effect()
  }, [effect])

  return (
    <Box onClickCapture={handleClick} sx={{ display: 'inline-block' }}>
      <NavLink ref={ref} {...linkProps} />
    </Box>
  )
})
