import { FC, PropsWithChildren } from 'react'
import SuccessIcon from '../../assets/icons/SuccessIcon'
import { ResultScreen, ResultScreenProps } from '../ResultScreen'

export type SuccessScreenProps = Omit<ResultScreenProps, 'icon'> &
  PropsWithChildren

export const SuccessScreen: FC<SuccessScreenProps> = (props) => {
  return <ResultScreen {...props} icon={<SuccessIcon />} />
}
