import { useState, useCallback } from 'react'
import { Grid } from '@mui/material'
import { CheckboxVehicleComponent } from '../../../../molecules/CheckboxVehicleComponent'
import { For } from '../../../../atoms/JSXExtensions'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

const images = [
  'https://tesla-cdn.thron.com/delivery/public/image/tesla/2391415a-7273-4735-92b2-a37c84c26f04/bvlatuR/std/4096x2560/Homepage-Model-S-Desktop-RHD',
  'https://carsnoop.s3.us-east-2.amazonaws.com/afeed095-3f59-4364-a292-0d169711c997.jpg',
  'https://carsnoop.s3.us-east-2.amazonaws.com/c053b6e5-64ae-4965-af5d-674ad90aeb9c.jpg'
]

const vehicleData = {
  id: 'd719f754-ab56-4393-9d99-74980917ac1e',
  year: 2021,
  make: 'Tesla',
  model: 'Model S',
  trim: 'Plaid',
  vin: '5YJSA1E63MF442013',
  stock_number: 'WF2013T',
  mileage: 5011.0,
  address_id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
  status: 'For Sale',
  distance: '35 miles',
  shipping_value: 3000,
  retail_value: 100000,
  imageSource:
    'https://carsnoop.s3.us-east-2.amazonaws.com/c053b6e5-64ae-4965-af5d-674ad90aeb9c.jpg'
}

const vehicles = [
  {
    id: 'ea5e2579-9013-4e1c-801e-93e7302c9454',
    year: 2015,
    make: 'Lamborghini',
    model: 'Huracan',
    trim: 'LP610-4',
    vin: 'YHWUC1ZF9FLA02580',
    stockNumber: 'F2580T',
    mileage: 18101.0,
    city: 'Indianapolis',
    state: 'IN',
    zipCode: '46268',
    distance: '5.8 mi'
  },
  {
    id: 'fa5e2579-9013-4e1c-801e-93e7302c9454',
    year: 2015,
    make: 'Lamborghini',
    model: 'Huracan',
    trim: 'LP610-4',
    vin: 'XHWUC1ZF9FLA02580',
    stockNumber: 'F2580T',
    mileage: 18101.0,
    city: 'Indianapolis',
    state: 'IN',
    zipCode: '46268',
    distance: '5.8 mi'
  },
  {
    id: '1b71aca1-eec3-484b-bd52-01b98e4aa257',
    year: 2015,
    make: 'Lamborghini',
    model: 'Huracan',
    trim: 'LP610-4',
    vin: 'XHWUC1ZF9FLA02580',
    stockNumber: 'F2580T',
    mileage: 18101.0,
    city: 'Indianapolis',
    state: 'IN',
    zipCode: '46268',
    distance: '5.8 mi'
  },
  {
    id: '8d3493bf-8b2d-4a4e-9feb-4205e49bce92',
    year: 2015,
    make: 'Lamborghini',
    model: 'Huracan',
    trim: 'LP610-4',
    vin: 'XHWUC1ZF9FLA02580',
    stockNumber: 'F2580T',
    mileage: 18101.0,
    city: 'Indianapolis',
    state: 'IN',
    zipCode: '46268',
    distance: '5.8 mi'
  }
]

export const CheckboxVehicleComponentExample = () => {
  const maxVehicles = 3
  const [checkedList, setCheckedList] = useState<string[]>([])

  const onCheckChange = useCallback(
    (id: string, nextValue: boolean) => {
      if (nextValue) {
        setCheckedList([...checkedList, id])
      } else {
        setCheckedList(checkedList.filter((item) => item !== id))
      }
    },
    [checkedList]
  )

  return (
    <ComponentContainer fullWidth>
      <ComponentWithPaperBackground>
        <Grid container flexDirection='column' mt={3} pt={4} rowSpacing={3}>
          <Grid
            item
            container
            width='448px'
            rowSpacing={3}
            flexDirection='column'
          >
            <Grid item>
              <For of={vehicles}>
                {(vehicle, index) => (
                  <CheckboxVehicleComponent
                    key={vehicle.id}
                    disabled={
                      checkedList.length >= maxVehicles &&
                      !checkedList.includes(vehicle.id)
                    }
                    checked={checkedList.includes(vehicle.id)}
                    onChange={onCheckChange}
                    {...vehicle}
                    shippingCost={vehicleData.shipping_value * (index + 1)}
                    retailValue={vehicleData.retail_value * 5 * (index + 1)}
                    image={images[index]}
                  />
                )}
              </For>
            </Grid>
          </Grid>
        </Grid>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
