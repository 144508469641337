import { ResponseStatusMap } from '../../../types/status'
import { formatEmptyObjectToNull } from '../../../utils/formatters/formatEmptyObjectToNull'
import { ErrorResponse, SuccessResponse } from '../../private.types'
import { Utils } from '../../utils'
import { ReadInventoryItemStatusesMap } from '../accounts/types'
import type {
  CreateVehicleParams,
  ReadCarByYearResponse,
  ReadCarByYearTrimApiResponse,
  ReadCarByYearTrimResponse,
  ReadCarModelParams,
  ReadCarModelYearTrimParams,
  ReadFindCarFormTradeInParams,
  ReadModelsParams,
  ReadVehicleApiResponse,
  ReadVehicleResponse,
  ReadYearsParams,
  UpdateVehicleParams
} from './types'
import { SearchInventoryResponseItem } from './types'

export class Vehicles {
  protected readonly utils: Utils
  protected readonly operations = {
    createVehicle: 'createVehicle',
    createVehiclePhoto: 'createVehiclePhoto',
    deleteVehicle: 'deleteVehicle',
    deleteVehiclePhoto: 'deleteVehiclePhoto',
    readFindCarFormTradeIn: 'readFindCarFormTradeIn',
    readMakes: 'readMakes',
    readMakesFromNonDealerInventory: 'readMakesFromNonDealerInventory',
    readMakesFromVehicles: 'readMakesFromVehicles',
    readModels: 'readModels',
    readModelsFromNonDealerInventory: 'readModelsFromNonDealerInventory',
    readModelsFromVehicles: 'readModelsFromVehicles',
    readModelYear: 'readModelYear',
    readModelYearFromNonDealerInventory: 'readModelYearFromNonDealerInventory',
    readModelYearFromVehicles: 'readModelYearFromVehicles',
    readModelYearTrim: 'readModelYearTrim',
    readModelYearTrimFromVehicles: 'readModelYearTrimFromVehicles',
    readVehicle: 'readVehicle',
    readYears: 'readYears',
    readYearsFromNonDealerInventory: 'readYearsFromNonDealerInventory',
    readYearsFromVehicles: 'readYearsFromVehicles',
    replaceVehiclePhoto: 'replaceVehiclePhoto',
    searchInventory: 'searchInventory',
    updateVehicle: 'updateVehicle'
  }

  constructor(utils: Utils) {
    this.utils = utils
  }

  async readFindCarFormTradeIn(params: ReadFindCarFormTradeInParams): Promise<Response> {
    return this.utils.makePostRequest(this.operations.readFindCarFormTradeIn, { ...params })
  }

  async readMakes(): Promise<Response> {
    return this.utils.makePostRequest(this.operations.readMakes)
  }

  async readMakesFromNonDealerInventory(): Promise<Response> {
    return this.utils.makePostRequest(this.operations.readMakesFromNonDealerInventory)
  }

  async readMakesFromVehicles(): Promise<Response> {
    return this.utils.makePostRequest(this.operations.readMakesFromVehicles)
  }

  async readModels(params: ReadModelsParams): Promise<Response> {
    return this.utils.makePostRequest(this.operations.readModels, { ...params })
  }

  async readModelsFromNonDealerInventory(params: ReadModelsParams): Promise<Response> {
    return this.utils.makePostRequest(this.operations.readModelsFromNonDealerInventory, { ...params })
  }

  async readModelsFromVehicles(params: ReadModelsParams): Promise<Response> {
    return this.utils.makePostRequest(this.operations.readModelsFromVehicles, { ...params })
  }

  async readYears(params: ReadYearsParams): Promise<Response> {
    return this.utils.makePostRequest(this.operations.readYears, { ...params })
  }

  async readYearsFromNonDealerInventory(params: ReadYearsParams): Promise<Response> {
    return this.utils.makePostRequest(this.operations.readYearsFromNonDealerInventory, { ...params })
  }

  async readYearsFromVehicles(params: ReadYearsParams): Promise<Response> {
    return this.utils.makePostRequest(this.operations.readYearsFromVehicles, { ...params })
  }

  async readCarModelYear(params: ReadCarModelParams): Promise<
    | SuccessResponse<{ data: ReadCarByYearResponse }>
    | ErrorResponse<{
      id: string
      message: string
    }>
  > {
    type SuccessResponse = ReadCarByYearResponse

    type ErrorResponse = {
      id: string
      message: string
    }

    const response = await this.utils.makeJSONRequest<
      SuccessResponse,
      ErrorResponse
    >(this.operations.readModelYear, {
      ...params
    })

    if (response.type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,

        data: response.payload
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: response.payload.id,
      message: response.payload.message
    }
  }

  async readCarModelYearFromNonDealerInventory(params: ReadCarModelParams): Promise<
    | SuccessResponse<{ data: ReadCarByYearResponse }>
    | ErrorResponse<{
      id: string
      message: string
    }>
  > {
    type SuccessResponse = ReadCarByYearResponse

    type ErrorResponse = {
      id: string
      message: string
    }

    const response = await this.utils.makeJSONRequest<
      SuccessResponse,
      ErrorResponse
    >(this.operations.readModelYearFromNonDealerInventory, {
      ...params
    })

    if (response.type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,

        data: response.payload
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: response.payload.id,
      message: response.payload.message
    }
  }

  async readCarModelYearFromVehicles(params: ReadCarModelParams): Promise<
    | SuccessResponse<{ data: ReadCarByYearResponse }>
    | ErrorResponse<{
      id: string
      message: string
    }>
  > {
    type SuccessResponse = ReadCarByYearResponse

    type ErrorResponse = {
      id: string
      message: string
    }

    const response = await this.utils.makeJSONRequest<
      SuccessResponse,
      ErrorResponse
    >(this.operations.readModelYearFromVehicles, {
      ...params
    })

    if (response.type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,

        data: response.payload
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: response.payload.id,
      message: response.payload.message
    }
  }

  async readCarModelYearTrim(params: ReadCarModelYearTrimParams): Promise<
    | SuccessResponse<{ data: ReadCarByYearTrimResponse }>
    | ErrorResponse<{
      id: string
      message: string
    }>
  > {
    type SuccessResponse = ReadCarByYearTrimApiResponse

    type ErrorResponse = {
      id: string
      message: string
    }

    const { payload, type } = await this.utils.makeJSONRequest<
      SuccessResponse,
      ErrorResponse
    >(this.operations.readModelYearTrim, {
      ...params
    })

    if (type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        data: {
          id: payload.id,
          exteriorColors: payload.exterior_colors,
          interiorColors: payload.interior_colors,
          make: payload.make,
          model: payload.model,
          trim: payload.trim,
          year: payload.year,
          standardEquipment: payload.standard_equipment
        }
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: payload.id,
      message: payload.message
    }
  }

  async readCarModelYearTrimFromVehicles(params: ReadCarModelYearTrimParams): Promise<
    | SuccessResponse<{ data: ReadCarByYearTrimResponse }>
    | ErrorResponse<{
      id: string
      message: string
    }>
  > {
    type SuccessResponse = ReadCarByYearTrimApiResponse

    type ErrorResponse = {
      id: string
      message: string
    }

    const { payload, type } = await this.utils.makeJSONRequest<
      SuccessResponse,
      ErrorResponse
    >(this.operations.readModelYearTrimFromVehicles, {
      ...params
    })

    if (type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        data: {
          id: payload.id,
          exteriorColors: payload.exterior_colors,
          interiorColors: payload.interior_colors,
          make: payload.make,
          model: payload.model,
          trim: payload.trim,
          year: payload.year,
          standardEquipment: payload.standard_equipment
        }
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: payload.id,
      message: payload.message
    }
  }

  async readVehicle(id: string): Promise<
    | SuccessResponse<ReadVehicleResponse>
    | ErrorResponse<{
      id: string
      message: string
    }>
  > {
    type SuccessResponse = ReadVehicleApiResponse

    type ErrorResponse = {
      id: string
      message: string
    }

    const response = await this.utils.makeJSONRequest<
      SuccessResponse,
      ErrorResponse
    >(this.operations.readVehicle, { id })

    if (response.type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        id: response.payload.id,
        year: response.payload.year,
        make: response.payload.make,
        model: response.payload.model,
        trim: response.payload.trim,
        vin: response.payload.vin,
        stockNumber: response.payload.stock_number,
        mileage: response.payload.mileage,
        vehicleStatus: ReadInventoryItemStatusesMap[response.payload.status],
        createdOn: response.payload.created_on,
        createdBy: response.payload.created_by,
        changedOn: response.payload.changed_on,
        changedBy: response.payload.changed_by,
        condition: response.payload.condition,
        accountID: response.payload.account_id,
        addressID: response.payload.address_id,
        retailValue: response.payload.retail_value,
        tradeinValue: response.payload.tradein_value,
        notes: response.payload.notes,
        standardEquipment: formatEmptyObjectToNull(
          response.payload.standard_equipment
        ),
        fuelType: response.payload.fuel_type,
        engineDescription: response.payload.engine_description,
        transmission: response.payload.transmission,
        mpg: response.payload.mpg,
        style: response.payload.style,
        interiorColor: response.payload.interior_color,
        exteriorColor: response.payload.exterior_color,
        drivetrain: response.payload.drivetrain,
        licensePlate: response.payload.license_plate,
        licensePlateState: response.payload.license_plate_state,
        deleted: response.payload.deleted,
        city: response.payload.city,
        state: response.payload.state,
        zipCode: response.payload.zip_code,
        amountOwed: response.payload.amount_owed,
        images: response.payload.images.map(
          ({ id: imageId, type, document_content: documentContent }) => ({
            id: imageId,
            type,
            url: documentContent
          })
        ),
        carStatus: ReadInventoryItemStatusesMap[response.payload.status],
        requests: response.payload.requests.map(
          ({
            id: requestId,
            make,
            model,
            trim,
            year,
            exterior_color: exteriorColor,
            interior_color: interiorColor,
            expires_on: expiresOn,
            trade_in_vehicle: tradeinVehicleId,
            tradein_value: tradeinValue
          }) => {
            return {
              id: requestId,
              make,
              model,
              trim,
              year,
              exteriorColor: exteriorColor.split(','),
              interiorColor: interiorColor.split(','),
              tradeinVehicleId,
              tradeinValue,
              expiresOn: new Date(expiresOn)
            }
          }
        ),
        requestCount: response.payload.request_count
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: response.payload.id,
      message: response.payload.message
    }
  }

  async deleteVehicle(id: string): Promise<
    | SuccessResponse<{
      id: string
      message: string
    }>
    | ErrorResponse<{
      id: string
      message: string
    }>
  > {
    type SuccessResponse = {
      id: string
      message: string
    }

    type ErrorResponse = {
      id: string
      message: string
    }

    const response = await this.utils.makeJSONRequest<
      SuccessResponse,
      ErrorResponse
    >(this.operations.deleteVehicle, { id })

    if (response.type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        id: response.payload.id,
        message: response.payload.message
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: response.payload.id,
      message: response.payload.message
    }
  }

  async updateVehicle(
    data: UpdateVehicleParams
  ): Promise<
    | SuccessResponse<{ id: string; message: string }>
    | ErrorResponse<{ id: string; message: string }>
  > {
    type RequestResponse = {
      id: string
      message: string
    }

    const {
      id,
      addressId,
      make,
      model,
      year,
      trim,
      vin,
      mileage,
      condition,
      stockNumber,
      retailValue,
      tradeInValue,
      fuelType,
      engineDescription,
      transmission,
      mpg,
      style,
      exteriorColor,
      interiorColor,
      drivetrain,
      licensePlate,
      licensePlateState,
      notes,
      amountOwed
    } = data

    const dataToSend = {
      id,
      address_id: addressId,
      make,
      model,
      year,
      trim,
      vin,
      mileage,
      condition,
      stock_number: stockNumber,
      retail_value: retailValue,
      tradein_value: tradeInValue,
      fuel_type: fuelType,
      engine_description: engineDescription,
      transmission,
      mpg,
      style,
      exterior_color: exteriorColor,
      interior_color: interiorColor,
      drivetrain,
      license_plate: licensePlate,
      license_plate_state: licensePlateState,
      notes,
      amount_owed: amountOwed
    }

    const { type, payload } = await this.utils.makeJSONRequest<
      RequestResponse,
      RequestResponse
    >(this.operations.updateVehicle, dataToSend)

    if (type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        id: payload.id,
        message: payload.message
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: payload.id,
      message: payload.message
    }
  }

  async createVehiclePhoto(data: {
    vehicleId: string
    name: string
    type: string
    content: string
    thumbnail: boolean
  }): Promise<
    | SuccessResponse<{
      id: string
      message: string
    }>
    | ErrorResponse<{
      id: string
      message: string
    }>
  > {
    type SuccessResponse = {
      id: string
      message: string
    }

    type ErrorResponse = {
      id: string
      message: string
    }

    const { vehicleId, ...other } = data

    const dataToSend = {
      vehicle_id: vehicleId,
      ...other
    }

    const response = await this.utils.makeJSONRequest<
      SuccessResponse,
      ErrorResponse
    >(this.operations.createVehiclePhoto, dataToSend)

    if (response.type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        id: response.payload.id,
        message: response.payload.message
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: response.payload.id,
      message: response.payload.message
    }
  }

  async replaceVehiclePhoto(data: {
    id: string
    type: string
    content: string
    thumbnail: boolean
  }): Promise<
    | SuccessResponse<{
      id: string
      message: string
    }>
    | ErrorResponse<{
      id: string
      message: string
    }>
  > {
    type SuccessResponse = {
      id: string
      message: string
    }

    type ErrorResponse = {
      id: string
      message: string
    }

    const response = await this.utils.makeJSONRequest<
      SuccessResponse,
      ErrorResponse
    >(this.operations.replaceVehiclePhoto, data)

    if (response.type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        id: response.payload.id,
        message: response.payload.message
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: response.payload.id,
      message: response.payload.message
    }
  }

  async deleteVehiclePhoto(data: {
    vehicleId: string
    id: string
    type: string
  }): Promise<
    | SuccessResponse<{
      id: string
      message: string
    }>
    | ErrorResponse<{
      id: string
      message: string
    }>
  > {
    type SuccessResponse = {
      id: string
      message: string
    }

    type ErrorResponse = {
      id: string
      message: string
    }

    const { vehicleId, ...other } = data

    const dataToSend = {
      vehicle_id: vehicleId,
      ...other
    }

    const response = await this.utils.makeJSONRequest<
      SuccessResponse,
      ErrorResponse
    >(this.operations.deleteVehiclePhoto, dataToSend)

    if (response.type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        id: response.payload.id,
        message: response.payload.message
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: response.payload.id,
      message: response.payload.message
    }
  }

  async createVehicle(
    data: CreateVehicleParams
  ): Promise<
    | SuccessResponse<{ id: string; message: string }>
    | ErrorResponse<{ id: string; message: string }>
  > {
    type RequestResponse = {
      id: string
      message: string
    }

    const {
      accountId,
      addressId,
      make,
      model,
      year,
      trim,
      vin,
      mileage,
      condition,
      stockNumber,
      retailValue,
      tradeinValue,
      fuelType,
      engineDescription,
      transmission,
      mpg,
      style,
      exteriorColor,
      interiorColor,
      drivetrain,
      licensePlate,
      licensePlateState,
      notes,
      amountOwed,
      standardEquipment
    } = data

    const dataToSend = {
      account_id: accountId,
      address_id: addressId,
      make,
      model,
      year,
      trim,
      vin,
      mileage,
      condition,
      stock_number: stockNumber,
      retail_value: retailValue,
      tradein_value: tradeinValue,
      fuel_type: fuelType,
      engine_description: engineDescription,
      transmission,
      mpg,
      style,
      exterior_color: exteriorColor,
      interior_color: interiorColor,
      drivetrain,
      license_plate: licensePlate,
      license_plate_state: licensePlateState,
      notes,
      amount_owed: amountOwed,
      standard_equipment: standardEquipment
    }

    const { type, payload } = await this.utils.makeJSONRequest<
      RequestResponse,
      RequestResponse
    >(this.operations.createVehicle, dataToSend)

    if (type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        id: payload.id,
        message: payload.message
      }
    }

    return {
      status: ResponseStatusMap.Error,
      id: payload.id,
      message: payload.message
    }
  }

  async searchInventory(data: {
    deliverTo: string
    isDealer: boolean
    make: string
    model: string
    years: string[]
    trim: string
    exteriorColor: string | string[]
    interiorColor: string | string[]
  }): Promise<
    | SuccessResponse<{
      list: SearchInventoryResponseItem[]
    }>
    | ErrorResponse<{
      message: string
    }>
  > {
    type RequestResponse = {
      list: Array<{
        id: string
        year: number
        make: string
        model: string
        trim: string
        vin: string
        stock_number: string
        mileage: number
        city: string
        state: string
        zip_code: string
        distance: string
        account_id: string
        retail_value: number
        shipping_cost: number
        document_content?: string
      }>
    }

    type RequestErrorResponse = {
      message: string
    }

    const dataToSend = {
      delivery_zip_code: data.deliverTo,
      is_dealer: data.isDealer ? 'true' : 'false',
      make: data.make,
      model: data.model,
      years: data.years,
      trim: data.trim,
      exterior_color: data.exteriorColor,
      interior_color: data.interiorColor
    }

    const response = await this.utils.makeJSONRequest<
      RequestResponse,
      RequestErrorResponse
    >(this.operations.searchInventory, dataToSend)

    if (response.type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        list: response.payload.list.map((item) => ({
          id: item.id,
          year: item.year,
          make: item.make,
          model: item.model,
          trim: item.trim,
          vin: item.vin,
          stockNumber: item.stock_number,
          mileage: item.mileage,
          city: item.city,
          state: item.state,
          zipCode: item.zip_code,
          distance: item.distance,
          accountId: item.account_id,
          retailValue: item.retail_value,
          shippingCost: item.shipping_cost,
          image: item.document_content || ''
        }))
      }
    }

    return {
      status: ResponseStatusMap.Error,
      message: response.payload.message
    }
  }
}
