import { FC } from 'react'
import { Label } from '../../../../atoms/Label'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const LabelExample: FC = () => {
  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <Label name='text label' label='label text' />
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
