import { FC, PropsWithChildren, ReactNode } from 'react'
import { Grid, GridProps } from '@mui/material'

export type IconTextProps = {
  icon: ReactNode
  alignItems?: GridProps['alignItems']
  flexWrap?: GridProps['flexWrap']
} & PropsWithChildren

export const IconText: FC<IconTextProps> = (props) => {
  const { icon, children, alignItems = 'flex-start', flexWrap = 'wrap' } = props

  return (
    <Grid
      container
      item
      spacing='10px'
      alignItems={alignItems}
      flexWrap={flexWrap}
    >
      <Grid item>{icon}</Grid>
      <Grid item maxWidth='100%'>
        {children}
      </Grid>
    </Grid>
  )
}
