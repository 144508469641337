import { Grid, IconButton } from '@mui/material'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { FC, useCallback, useRef } from 'react'
import { ChatSendIcon } from '../../../../../../assets/icons/ChatSend'
import { PaperClipIcon } from '../../../../../../assets/icons/PaperClip'
import { Tooltip } from '../../../../../../atoms/Tooltip'
import { ChatInput } from '../ChatInput/ChatInput'
import { useSendListener } from '../hooks/useSendListener'
import { buttonStyles } from '../styles'
import { FieldsType, FIELD_NAMES } from './types'
import { validationSchema } from './validationSchema'

interface ControlsProps {
  onSendClick: (value: string) => void
  onSendFileClick: () => void
}

export const Controls: FC<ControlsProps> = (props) => {
  const { onSendClick, onSendFileClick } = props

  const formikRef = useRef<FormikProps<FieldsType>>(null)

  const handleSendByKeyPress = useCallback(async () => {
    const { current: formikReference } = formikRef
    if (formikReference) {
      await formikReference.submitForm()
      // To disable "Send" button
      formikReference.resetForm({ errors: { input: '' } })
    }
  }, [])

  const { downListener, upListener } = useSendListener(handleSendByKeyPress)

  const handleSend = useCallback(
    (values: FieldsType, formikHelpers: FormikHelpers<FieldsType>) => {
      const { input } = values
      onSendClick(input)
      formikHelpers.setFieldValue(FIELD_NAMES.INPUT, '')
    },
    [onSendClick]
  )

  return (
    <Formik<FieldsType>
      initialValues={{
        input: ''
      }}
      onSubmit={handleSend}
      validateOnMount
      validateOnChange
      validationSchema={validationSchema}
      innerRef={formikRef}
    >
      {(formik) => {
        return (
          <Form onKeyDownCapture={downListener} onKeyUpCapture={upListener}>
            <Grid container item>
              <Grid item container xs='auto'>
                <Tooltip title='Attach files'>
                  <IconButton sx={buttonStyles} onClick={onSendFileClick}>
                    <PaperClipIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid
                item
                xs='auto'
                // To override without many classes nesting
                sx={{ flexGrow: '1 !important' }}
                container
                alignItems='center'
              >
                <ChatInput
                  name={FIELD_NAMES.INPUT}
                  placeholder='Write your message...'
                />
              </Grid>
              <Grid item container xs='auto'>
                <IconButton
                  sx={buttonStyles}
                  type='submit'
                  disabled={!formik.isValid}
                >
                  <ChatSendIcon active={formik.isValid} />
                </IconButton>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}
