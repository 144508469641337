import * as Yup from 'yup'
import { FIND_CAR_FIELD_NAMES } from '../../../types/enums/findCar'
import { latinWithSpecial } from '../../../utils/validators'

const optionScheme = {
  name: Yup.string(),
  id: Yup.string()
}

export const validationSchema = Yup.object({
  [FIND_CAR_FIELD_NAMES.MAKE]: Yup.object(optionScheme).required(),
  [FIND_CAR_FIELD_NAMES.MODEL]: Yup.object(optionScheme).required(),
  [FIND_CAR_FIELD_NAMES.YEAR_TO]: Yup.object(optionScheme).nullable(),
  [FIND_CAR_FIELD_NAMES.YEAR_FROM]: Yup.object(optionScheme).nullable(),
  [FIND_CAR_FIELD_NAMES.TRIM]: Yup.object(optionScheme).nullable(),
  [FIND_CAR_FIELD_NAMES.EXTERIOR_COLOR]: Yup.array()
    .of(Yup.object(optionScheme))
    .min(1),
  [FIND_CAR_FIELD_NAMES.INTERIOR_COLOR]: Yup.array()
    .of(Yup.object(optionScheme))
    .min(1),
  [FIND_CAR_FIELD_NAMES.DELIVER_TO]: Yup.object(optionScheme).required(),
  [FIND_CAR_FIELD_NAMES.NOTES]: Yup.string().test(
    'is-latin-or-special',
    'Enter a valid data format.',
    latinWithSpecial(['\n', ' ', '€', '£'])
  )
})
