import { Grid } from '@mui/material'
import { FC, ReactNode } from 'react'
import { Condition } from '../../../../atoms/Condition'

interface VehicleDetailsLayoutProps {
  imagesComponent: ReactNode
  sellerNotesComponent: ReactNode
  standardEquipmentComponent: ReactNode
  vehicleDetailsComponent: ReactNode
  vehicleInfoComponent: ReactNode
}

export const DesktopVehicleDetails: FC<VehicleDetailsLayoutProps> = ({
  imagesComponent,
  sellerNotesComponent,
  standardEquipmentComponent,
  vehicleDetailsComponent,
  vehicleInfoComponent,
}) => {
  return (
    <>
      {/* Left Column */}
      <Grid container item lg={7} alignSelf='flex-start' spacing={4}>
        <Condition
          condition={!!imagesComponent}
          trueContent={
            <Grid item xs={12}>
              {imagesComponent}
            </Grid>
          }
        />
        <Grid item xs={12}>
          {vehicleDetailsComponent}
        </Grid>
        <Grid item xs={12}>
          {sellerNotesComponent}
        </Grid>
      </Grid>
      {/* Right Column */}
      <Grid container item lg={5} spacing={2} alignSelf='flex-start'>
        <Grid item xs={12}>
          {vehicleInfoComponent}
        </Grid>
        <Grid item xs={12}>
          {standardEquipmentComponent}
        </Grid>
      </Grid>
    </>
  )
}

export const TabletVehicleDetails: FC<VehicleDetailsLayoutProps> = ({
  imagesComponent,
  sellerNotesComponent,
  standardEquipmentComponent,
  vehicleDetailsComponent,
  vehicleInfoComponent,
}) => {
  return (
    <Grid container item alignSelf='flex-start' spacing={4}>
      <Condition
        condition={!!imagesComponent}
        trueContent={
          <Grid item xs={12}>
            {imagesComponent}
          </Grid>
        }
      />
      <Grid item xs={12}>
        {vehicleInfoComponent}
      </Grid>
      <Grid item xs={12}>
        {vehicleDetailsComponent}
      </Grid>
      <Grid item xs={12}>
        {sellerNotesComponent}
      </Grid>
      <Grid item xs={12}>
        {standardEquipmentComponent}
      </Grid>
    </Grid>
  )
}
