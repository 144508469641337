import { createAction } from '@reduxjs/toolkit'
import { externalServiceRegistrationSlice } from './externalServiceRegistration'

export const actionsExternalServiceRegistration = {
  incrementScreenStep:
    externalServiceRegistrationSlice.actions.incrementScreenStep,
  decrementScreenStep:
    externalServiceRegistrationSlice.actions.decrementScreenStep,
  incrementOnboardStep: createAction(
    'externalServiceRegistration/incrementOnboardStep'
  ),
  decrementOnboardStep: createAction(
    'externalServiceRegistration/decrementOnboardStep'
  ),
  setPersonalDetails:
    externalServiceRegistrationSlice.actions.setPersonalDetails,
  setContactDetails: externalServiceRegistrationSlice.actions.setContactDetails,
  resetState: externalServiceRegistrationSlice.actions.resetState,
  resetLocation: externalServiceRegistrationSlice.actions.resetLocation
}
