import { Grid, Typography } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import { FC, useCallback } from 'react'
import { COLORS } from '../../../../../../types/colors'
import { BlueButton } from '../../../../../../atoms/Button'
import { EllipsisText } from '../../../../../../atoms/EllipsisText'
import { ChatFileMessageProps } from '../../types'
import { Show } from '../../../../../../atoms/JSXExtensions'
import { Loader } from '../../../../../../atoms/Loader'
import { useFiles } from '../../../../../../utils/hooks/files'
import { MediaMessageType } from '../../../../../../api/chat/types'

export const ChatMediaMessageContainer: FC<
  ChatFileMessageProps & MediaMessageType
> = ({
  isSender = false,
  isMinimized = false,
  contentName,
  contentPreviewURL,
  onClick,
  icon
}) => {
  const actionColor = onClick ? COLORS.accent_blue : 'inherit'
  const { getFileByLink } = useFiles()

  const onFileClick = useCallback(() => {
    if (onClick && contentPreviewURL) {
      onClick(contentPreviewURL)
    }
  }, [onClick, contentPreviewURL])

  return (
    <Show
      when={contentPreviewURL}
      fallback={
        <Grid container justifyContent='center'>
          <Loader small />
        </Grid>
      }
    >
      <Grid
        container
        flexDirection={isSender ? 'row-reverse' : 'row'}
        flexWrap='nowrap'
        alignItems='center'
        justifyContent='space-between'
        width={isMinimized ? '340px' : '450px'}
      >
        <Grid
          container
          item
          alignItems='center'
          flexDirection={isSender ? 'row-reverse' : 'row'}
          onClick={onFileClick}
          xs={11}
          sx={{ cursor: onClick ? 'pointer' : 'auto' }}
        >
          {icon}
          <Grid item xs={10}>
            <Typography
              mx={1}
              color={actionColor}
              textAlign={isSender ? 'right' : 'left'}
            >
              <EllipsisText tooltipText={contentName}>
                {contentName}
              </EllipsisText>
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs='auto'>
          <BlueButton
            onClick={getFileByLink(contentPreviewURL, contentName)}
            sx={{ minWidth: 'auto', p: 0 }}
          >
            <DownloadIcon sx={{ color: COLORS.accent_blue }} />
          </BlueButton>
        </Grid>
      </Grid>
    </Show>
  )
}
