import { Link, Typography } from '@mui/material'
import { useCallback, useEffect, useMemo } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { CreateContractParams } from '../../api/carSnoopApi/contracts/types'
import { SUPPORT_EMAIL } from '../../constants/applicationConstants'
import { PageContainer } from '../../layout/PageContainer'
import { OperationResultScreen } from '../../molecules/OperationResultScreen'
import { OperationResultInfoType } from '../../molecules/OperationResultScreen/types'
import { routes } from '../../routes'
import { useApiContext, useMakeService, useRestoreData } from '../../utils/hooks'
import { IOfferForm } from '../Offers/hooks/useOfferForm'
import { OFFER_FORM_KEY } from '../Offers/hooks/useRestoreAcceptOfferForm'
import {
  errorButtonText,
  errorText,
  successButtonText,
  successText,
  supportLinkText
} from './constants'
import { extractCreateContractParams } from './utils'

export const DepositResultPage = () => {
  const navigate = useNavigate()
  const { transactionId: transactionID = '' } = useParams()
  const { carSnoopApi } = useApiContext()
  const { restoredData, isRestoring } = useRestoreData<IOfferForm | null>(
    OFFER_FORM_KEY
  )

  const [createContract, createContractObservable] = useMakeService(
    async (params: CreateContractParams) => {
      const response = await carSnoopApi.contracts.createContract(params)
      return response
    }
  )

  const navigateSuccess = useCallback(() => {
    const path = generatePath(routes.contracts.transaction, {
      transactionId: transactionID
    })
    navigate(path)
  }, [transactionID])

  const navigateError = useCallback(() => {
    const path = generatePath(routes.offers.details, {
      transactionId: transactionID
    })
    navigate(path)
  }, [transactionID])

  useEffect(() => {
    if (!isRestoring && restoredData) {
      const params = extractCreateContractParams(restoredData)

      createContract({ ...params, transactionId: transactionID })
    }
  }, [isRestoring, restoredData])

  const successOperationInfo: OperationResultInfoType = useMemo(
    () => ({
      mainText: (
        <Typography variant='body1' textAlign='center' whiteSpace='pre-wrap'>
          {successText}
        </Typography>
      ),
      onClick: navigateSuccess,
      buttonText: successButtonText
    }),
    [navigateSuccess]
  )
  const errorOperationInfo: OperationResultInfoType = useMemo(
    () => ({
      mainText: (
        <Typography variant='body1' textAlign='center' whiteSpace='pre-wrap'>
          {`${errorText} `}
          <Link href={`mailto:${SUPPORT_EMAIL}`}>{supportLinkText}</Link>
        </Typography>
      ),
      onClick: navigateError,
      buttonText: errorButtonText
    }),
    [navigateError]
  )
  return (
    <PageContainer>
      <OperationResultScreen
        isLoading={createContractObservable.isLoading || isRestoring}
        successOperationInfo={successOperationInfo}
        errorOperationInfo={errorOperationInfo}
        isError={createContractObservable.isError}
        sx={{
          width: '400px',
          height: 'calc(100vh - 140px)',
          minHeight: '400px'
        }}
      />
    </PageContainer>
  )
}
