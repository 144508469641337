import {
  useState,
  MouseEventHandler,
  useCallback,
  Dispatch,
  SetStateAction
} from 'react'

export interface IConfirmationModal {
  isOpen: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  onOpen: MouseEventHandler
  onOpenHandler: () => void | Promise<void>
  onSubmit: () => void
  onCancel: () => void
}

export const useModal = (
  handleSubmit?: () => void,
  handleCancel?: () => void
): IConfirmationModal => {
  const [isOpen, setOpen] = useState(false)
  const onOpen: MouseEventHandler = (event) => {
    event.stopPropagation()
    setOpen(true)
  }

  const onCancel = () => {
    handleCancel && handleCancel()
    setOpen(false)
  }

  const onSubmit = () => {
    handleSubmit?.()
  }

  const onOpenHandler = useCallback(() => {
    setOpen(true)
  }, [setOpen])

  return {
    isOpen,
    setOpen,
    onOpen,
    onSubmit,
    onCancel,
    onOpenHandler
  }
}
