export enum SUPPORT_FORM_LABELS {
  REASON = 'Reason',
  MESSAGE = 'You may leave any information here or describe your question'
}

export enum SUPPORT_FORM_NAMES {
  REASON = 'reason',
  MESSAGE = 'message'
}

export interface SupportFormFieldsTypes {
  [SUPPORT_FORM_NAMES.REASON]: string
  [SUPPORT_FORM_NAMES.MESSAGE]: string
}
export const SUPPORT_FORM_ERROR = 'Enter a valid data format.'

export const MAX_REASON_LENGTH = 80

export const buttonText = 'Go back'

export const SUPPORT_FINAL_TEXT_HEADER = 'Thank you for contacting us!'

export const SUPPORT_FINAL_TEXT_MESSAGE =
  'Someone will reach out as soon as possible, \n usually within one business day'
