import * as Yup from 'yup'
import { latinWithSpecial, notOnlySpaces } from '../../../../utils/validators'
import { ERROR } from './types'

export const validationSchema = Yup.object({
  documentName: Yup.string()
    .required('')
    .test('is-latin-or-special', ERROR.NOT_VALID, latinWithSpecial([' ']))
    .test('not-only-spaces', ERROR.NOT_VALID, notOnlySpaces())
})
