import { Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { BlueButton } from '../../atoms/Button'
import { CarFaxInfo } from '../../atoms/CarFaxInfo'
import { Condition } from '../../atoms/Condition'
import { EllipsisText } from '../../atoms/EllipsisText'
import { Show } from '../../atoms/JSXExtensions'
import { COLORS } from '../../types/colors'
import { VehicleConditionMap } from '../../types/status'
import { formatNumber } from '../../utils'
import { useUserInfo, useVehicleDetailsRoute } from '../../utils/hooks'

export type VehicleInfoProps = {
  accountID: string
  condition: keyof typeof VehicleConditionMap
  id: string
  make: string
  mileage: number
  model: string
  openDetailsInNewTab?: boolean
  stockNumber: string
  trim: string
  vin: string
  year: number
}

export const DisplayVehicleInfo: FC<VehicleInfoProps> = (props) => {
  const {
    accountID: vehicleAccountID,
    condition,
    id,
    make,
    mileage,
    model,
    openDetailsInNewTab,
    stockNumber,
    trim,
    vin,
    year
  } = props
  const { accountID: userAccountID, isDealer } = useUserInfo()
  const { getPathToVehicle } = useVehicleDetailsRoute()
  const isOwnCar = userAccountID === vehicleAccountID
  const vehicleTitle = `${year} ${make} ${model} ${trim}`

  return (
    <Grid container item
      flexDirection='column'
      sx={{
        backgroundColor: COLORS.secondary_background,
        padding: 3,
        '& > div:not(:first-of-type)': {
          paddingTop: 3
        },
        '& > div:not(:last-of-type)': {
          borderBottom: `1px solid ${COLORS.border_gray}`,
          paddingBottom: 3,
        }
      }}
    >
      <Grid container item>
        <Grid container item xs={12}>
          <Grid container item xs={6} alignItems='center'>
            <Typography variant='subtitle2' textTransform='uppercase'>
              Vehicle For Sale
            </Typography>
          </Grid>
          <Grid container item xs={6} alignItems='center' justifyContent='end'>
            <NavLink
              to={getPathToVehicle(id)}
              style={{
                textDecoration: 'none'
              }}
              target={openDetailsInNewTab ? '_blank' : '_self'}
            >
              <BlueButton>
                <Typography variant='main'>View Details</Typography>
              </BlueButton>
            </NavLink>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6'>
              <EllipsisText tooltipText={vehicleTitle}>
                {vehicleTitle}
              </EllipsisText>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container item>
        <Grid container justifyContent='space-between'>
          <Grid item>
            <Grid item>
              <Typography variant='subtitle2' textTransform='uppercase'>
                Mileage
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='h6'>{formatNumber(mileage)}</Typography>
            </Grid>
          </Grid>
          <Show
            when={stockNumber && isDealer && isOwnCar}
            fallback={
              <Grid item>
                <Grid item>
                  <Typography variant='subtitle2' textTransform='uppercase'>
                    Condition
                  </Typography>
                </Grid>
                <Grid container item justifyContent='flex-end'>
                  <Typography variant='h6'>{VehicleConditionMap[condition]}</Typography>
                </Grid>
              </Grid>
            }
          >
            <Grid item>
              <Grid item>
                <Typography variant='subtitle2' textTransform='uppercase'>
                  Stock Number
                </Typography>
              </Grid>
              <Grid container item justifyContent='flex-end'>
                <Typography variant='h6'>{stockNumber}</Typography>
              </Grid>
            </Grid>
          </Show>
        </Grid>
      </Grid>

      <Grid container item
        sx={{
          pt: 3
        }}
      >
        <Grid container justifyContent='space-between'>
          <Condition
            condition={!!vin}
            trueContent={
              <Grid container item xs={12}>
                <Grid container item alignItems='center' xs={12} md={4}>
                  <Typography variant='subtitle2'>CARFAX REPORT</Typography>
                </Grid>
                <Grid container item
                  justifyContent={{ xs: 'start', md: 'end' }}
                  xs={12} md={8}
                >
                  <CarFaxInfo vin={vin} />
                </Grid>
              </Grid>
            }
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
