import { Avatar, Grid, Typography } from '@mui/material'
import { FC, useCallback, useMemo } from 'react'
import { DefaultVehicleAvatar } from '../../../../../../assets/icons/DefaultVehicleAvatar'
import { Condition } from '../../../../../../atoms/Condition'
import { EllipsisText } from '../../../../../../atoms/EllipsisText'
import { NumberBadge } from '../../../../../../atoms/NumberBadge'
import { TEXT_MESSAGE_TYPE } from '../../../../../../constants/applicationConstants'
import { useMessageSendTime } from '../../../../../../utils/hooks/chat'
import { ChatListItemType } from '../../../../model/types'
import { ChatContainer } from './ChatItem.styles'
import { LastMediaMessage, LastTextMessage } from './LastChatMessage'

type IChatItemProps = ChatListItemType & {
  onChatClick(chat: ChatListItemType): void
}

export const ChatItem: FC<IChatItemProps> = (props) => {
  const { lastMessage, vehicle, unreadCount, onChatClick } = props
  const sendTime = useMessageSendTime(lastMessage.timestamp, true)

  const handleChatClick = useCallback(() => {
    onChatClick(props)
  }, [props, onChatClick])

  const messageData = useMemo(() => {
    const { make, model, year, trim, thumbnail } = vehicle

    const chatTitle = `${year} ${make} ${model} ${trim}`.trim()
    const displayText =
      'contentId' in lastMessage ? lastMessage.contentName : lastMessage.message
    return {
      avatarSrc: thumbnail,
      chatTitle,
      displayText
    }
  }, [vehicle, lastMessage])

  return (
    <ChatContainer container onClick={handleChatClick}>
      <Grid item mr='12px'>
        <Avatar
          sx={{
            width: 56,
            height: 56
          }}
          src={messageData.avatarSrc}
        >
          <DefaultVehicleAvatar />
        </Avatar>
      </Grid>
      <Grid item container flexGrow={1} mr='12px' flexDirection='column'>
        <Grid item sx={{ width: '260px' }}>
          <Typography variant='label1'>
            <EllipsisText tooltipText={messageData.chatTitle}>
              {messageData.chatTitle}
            </EllipsisText>
          </Typography>
        </Grid>
        <Grid item>
          <Condition
            condition={lastMessage.type === TEXT_MESSAGE_TYPE}
            trueContent={
              <LastTextMessage displayText={messageData.displayText} />
            }
            falseContent={
              <LastMediaMessage
                messageType={lastMessage.type}
                displayText={messageData.displayText}
              />
            }
          />
        </Grid>
      </Grid>
      <Grid item container width='100px' justifyContent='flex-end'>
        <Grid item mb='22px'>
          <NumberBadge count={unreadCount} />
        </Grid>
        <Grid item>
          <Typography variant='subtitle1_grey'>{sendTime}</Typography>
        </Grid>
      </Grid>
    </ChatContainer>
  )
}
