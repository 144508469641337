import * as Yup from 'yup'
import { latinWithSpecial } from '../../utils/validators'
import { SUPPORT_FORM_ERROR, SUPPORT_FORM_NAMES } from './constants'

export const validationSchema = Yup.object({
  [SUPPORT_FORM_NAMES.REASON]: Yup.string()
    .required('')
    .test(
      'is-latin-or-special',
      SUPPORT_FORM_ERROR,
      latinWithSpecial(['\n', ' ', '€', '£'])
    ),
  [SUPPORT_FORM_NAMES.MESSAGE]: Yup.string().test(
    'is-latin-or-special',
    SUPPORT_FORM_ERROR,
    latinWithSpecial(['\n', ' ', '€', '£'])
  )
})
