import { FC } from 'react'
import { Grid } from '@mui/material'
import { FindCarTradeInScreen } from '../../../../forms/screens/FindCarTradeInScreen'
import { actionsFindCarForm } from '../../../../store/units/findCarForm'
import { FIND_CAR_FIELD_NAMES } from '../../../../types/enums/findCar'
import { noop } from '../../../../utils'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const BuildCarTradeInExample: FC = () => {
  const initialValues = {
    [FIND_CAR_FIELD_NAMES.TRADE_IN_VEHICLE]: null,
    [FIND_CAR_FIELD_NAMES.SHOW_TRADE_IN]: true
  }
  return (
    <ComponentContainer fullWidth>
      <ComponentWithPaperBackground title='Not in features folder'>
        <Grid container>
          <FindCarTradeInScreen
            actions={actionsFindCarForm}
            initialValues={initialValues}
            onCancel={noop}
          />
        </Grid>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
