import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import { PageContainer } from '../../../../layout/PageContainer'
import { Show } from '../../../../atoms/JSXExtensions'
import {
  useApiContext,
  useMakeService,
  useReadTransaction
} from '../../../../utils/hooks'
import { ResponseStatusMap } from '../../../../types/status'
import { Loader } from '../../../../atoms/Loader'
import { DealDetailsPage } from './DealDetailsPage'

export const DealDetailsPageContainer: FC = () => {
  const { transactionId = '' } = useParams()
  const { carSnoopApi } = useApiContext()
  const { data: transaction, readTransaction } = useReadTransaction({
    transactionId
  })

  const [cancelDeal] = useMakeService(
    async () => {
      const res = await carSnoopApi.requests.cancelTransaction({
        id: transactionId
      })

      if (res.status === ResponseStatusMap.Success) {
        return readTransaction(transactionId)
      }
      return { status: ResponseStatusMap.Error }
    },
    { withStatusNotification: true }
  )

  const [declineDeal] = useMakeService(
    async () => {
      const res = await carSnoopApi.requests.declineTransaction({
        id: transactionId
      })

      if (res.status === ResponseStatusMap.Success) {
        return readTransaction(transactionId)
      }
      return { status: ResponseStatusMap.Error }
    },
    { withStatusNotification: true }
  )

  return (
    <PageContainer>
      <Show
        when={transaction}
        fallback={
          <Grid container item xs={12} justifyContent='center'>
            <Loader />
          </Grid>
        }
      >
        {(t) => (
          <DealDetailsPage
            transaction={t}
            onCancelDeal={cancelDeal}
            onDeclineDeal={declineDeal}
          />
        )}
      </Show>
    </PageContainer>
  )
}
