import { FC, useMemo } from 'react'
import { VehicleComponent } from '../../../../atoms/VehicleComponent'
import { VehicleBadge } from '../../../../molecules/VehicleItem/VehicleBadge'
import { Details } from './Details'
import { useStatusPathResolver } from '../../../../utils/hooks/useStatusPathResolver'
import { NormalizedReadTransactionsItemType } from '../../../../api/carSnoopApi/transactions/types'

export interface InProgressDealCardProps {
  deal: NormalizedReadTransactionsItemType
}

export const InProgressDealCard: FC<InProgressDealCardProps> = (props) => {
  const { deal } = props
  const { status, vehicle, id } = deal
  const { getPathByStatus } = useStatusPathResolver(status)

  const badges = useMemo(() => {
    return [
      {
        position: 'top-left',
        label: status,
        component: <VehicleBadge status={status} />
      }
    ]
  }, [status])

  const navigationURL = useMemo(() => {
    return getPathByStatus({ transactionId: id })
  }, [id, getPathByStatus])

  return (
    <VehicleComponent
      {...vehicle}
      imageSource={vehicle.documentContent}
      badges={badges}
      details={<Details {...props} />}
      navigationURL={navigationURL}
    />
  )
}
