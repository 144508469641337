import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Grid, Button, Typography } from '@mui/material'
import { DashboardItem } from '../../../../atoms/DashboardItem'

import {
  useAppDispatch,
  useMakeService,
  useApiContext,
  useUserInfo
} from '../../../../utils/hooks'
import type { UserInfoState } from '../../../../store/types'
import { actionsUserInfo } from '../../../../store/features/userInfo'
import { Modal } from '../../../../molecules/Modal'
import { MyTeamCard } from '../../../../molecules/MyTeamCard'
import { useMyTeamCard } from '../../../../molecules/MyTeamCard/hooks'
import { Condition } from '../../../../atoms/Condition'
import { MY_TEAM_MODE } from '../../../../molecules/MyTeamCard/types'
import { ResponseStatusMap } from '../../../../types/status'
import { AvatarsList } from '../../../../atoms/AvatarsList'
import { groupArrPropAlphabetically } from '../../../../utils/groupArrPropAlphabetically'
import { ACCESS_DENIED } from '../../../../constants/messages'

export const UserConnections: FC = () => {
  const { carSnoopApi } = useApiContext()
  const userInfo = useUserInfo()
  const dispatch = useAppDispatch()

  const {
    isMyTeamOpen,
    setIsMyTeamOpen,
    mode,
    setMode,
    isAddingOpenDirectly,
    setIsAddingOpenDirectly,
    readAccount
  } = useMyTeamCard()

  const initialTemporaryMembers = useMemo(() => {
    const onlyTeamMembers = userInfo.users.filter(
      (user) => user.id !== userInfo.id
    )

    return groupArrPropAlphabetically(
      onlyTeamMembers,
      [
        (user) => user.status === 'Admin',
        (user) => user.status === 'User',
        (user) => user.status === 'Pending'
      ],
      'fullName'
    )
  }, [userInfo.users, userInfo.id])

  const [temporaryMembers, setTemporaryMembers] = useState(
    initialTemporaryMembers
  )

  const addButtonMargin = useMemo(
    () => (temporaryMembers.length ? '20px' : '4px'),
    [temporaryMembers.length]
  )

  useEffect(() => {
    setTemporaryMembers(initialTemporaryMembers)
  }, [initialTemporaryMembers])

  const handleCardClick = useCallback(() => {
    setIsMyTeamOpen(true)
  }, [setIsMyTeamOpen, initialTemporaryMembers])

  const handleMyTeamClose = useCallback(() => {
    setMode(MY_TEAM_MODE.LIST)
    setIsMyTeamOpen(false)
    setIsAddingOpenDirectly(false)
    setTemporaryMembers(initialTemporaryMembers)
  }, [
    setMode,
    setIsMyTeamOpen,
    setIsAddingOpenDirectly,
    initialTemporaryMembers,
    setTemporaryMembers
  ])

  const handleInsideAddNewUserClick = useCallback(() => {
    setMode(MY_TEAM_MODE.ADDING)
  }, [setMode])

  const handleAddNewUserClick = useCallback(() => {
    setMode(MY_TEAM_MODE.ADDING)
    setIsMyTeamOpen(true)
    setIsAddingOpenDirectly(true)
  }, [setMode, setIsMyTeamOpen, setIsAddingOpenDirectly])

  const [updateConnectionsService, updateConnectionsObservable] =
    useMakeService(
      async (currentMembersList: UserInfoState['users']) => {
        const { deletedUsers, restUsers } = initialTemporaryMembers.reduce<{
          deletedUsers: typeof userInfo.users
          restUsers: typeof userInfo.users
        }>(
          (acc, user) => {
            if (currentMembersList.every((member) => member.id !== user.id)) {
              acc.deletedUsers.push(user)
            } else {
              acc.restUsers.push(user)
            }

            return acc
          },
          { deletedUsers: [], restUsers: [] }
        )

        const changeUserPermissions = currentMembersList.filter((user) =>
          restUsers.find((member) => {
            return member.id === user.id && member.isAdmin !== user.isAdmin
          })
        )

        const changedPermissionsResponse = Promise.allSettled(
          changeUserPermissions.map(async (user) => {
            if (user.isAdmin) {
              const response = carSnoopApi.users.makeUserAdmin({ id: user.id })
              return response
            }

            return carSnoopApi.users.removeUserAdmin({
              id: user.id
            })
          })
        )

        const deletedUsersResponse = Promise.allSettled(
          deletedUsers.map((user) => {
            return carSnoopApi.users.deleteUser({
              id: user.id
            })
          })
        )

        const resultResponse = await Promise.all([
          changedPermissionsResponse,
          deletedUsersResponse
        ])

        if (
          resultResponse
            .flat()
            .some(
              (res) =>
                res.status === 'fulfilled' &&
                res.value.message === ACCESS_DENIED
            )
        ) {
          return {
            status: ResponseStatusMap.Error,
            message: ACCESS_DENIED
          }
        }

        const response = await carSnoopApi.accounts.readAccount(
          userInfo.accountID
        )

        if (response.status === ResponseStatusMap.Success) {
          dispatch(actionsUserInfo.setConnectedUsers({ users: response.users }))
        }

        setIsMyTeamOpen(false)

        return response
      },
      { includeCheckAccess: true }
    )

  const handleMyTeamSave = useCallback(async () => {
    return updateConnectionsService(temporaryMembers)
  }, [temporaryMembers, updateConnectionsService])

  return (
    <DashboardItem isInteractive onClick={handleCardClick}>
      <Modal open={isMyTeamOpen}>
        <MyTeamCard
          mode={mode}
          isLoading={
            updateConnectionsObservable.isLoading || readAccount.isLoading
          }
          isOpenDirectly={isAddingOpenDirectly}
          members={temporaryMembers}
          setMembers={setTemporaryMembers}
          setMode={setMode}
          onAddNewUserClick={handleInsideAddNewUserClick}
          onCloseClick={handleMyTeamClose}
          onSaveClick={handleMyTeamSave}
          isAdmin={userInfo.isAdmin}
        />
      </Modal>
      <Grid container>
        <Grid container item xs={12}>
          <Typography variant='h6' gutterBottom>
            My Team
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <Grid container item>
            <Grid item>
              <AvatarsList members={initialTemporaryMembers} />
            </Grid>
            <Condition
              condition={userInfo.isAdmin}
              trueContent={
                <Grid item>
                  <Button
                    variant='text'
                    disableElevation
                    startIcon={<AddCircleOutlineIcon />}
                    sx={{
                      textTransform: 'none',
                      fontWeight: '400',
                      ml: addButtonMargin,
                      pl: 0
                    }}
                    onClick={handleAddNewUserClick}
                  >
                    Add New User
                  </Button>
                </Grid>
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </DashboardItem>
  )
}
