import { INTERNAL_SHIPMENT_TYPE } from '../../../constants/applicationConstants'
import { ResponseStatusMap } from '../../../types/status'
import {
  SuccessResponse,
  ErrorResponse,
  RequestRecord
} from '../../private.types'
import { Utils } from '../../utils'
import {
  CompleteShipmentResponse,
  AddShipmentDetailsServiceParams,
  AddShipmentDetailsResponse
} from './types'

export class Shipments {
  protected readonly utils: Utils
  protected readonly operations = {
    completeShipment: 'completeShipment',
    addShipmentDetails: 'addShipmentDetails'
  }

  constructor(utils: Utils) {
    this.utils = utils
  }

  async completeShipment({
    id
  }: {
    id: string
  }): Promise<
    | SuccessResponse<CompleteShipmentResponse>
    | ErrorResponse<CompleteShipmentResponse>
  > {
    const dataToSend = {
      transaction_id: id
    }

    const response = await this.utils.makeJSONRequest<
      SuccessResponse<CompleteShipmentResponse>,
      ErrorResponse<CompleteShipmentResponse>
    >(this.operations.completeShipment, dataToSend)

    if (response.type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        message: response.payload.message
      }
    }

    return {
      status: ResponseStatusMap.Error,
      message: response.payload.message
    }
  }

  async addShipmentDetails(
    params: AddShipmentDetailsServiceParams
  ): Promise<
    | SuccessResponse<CompleteShipmentResponse>
    | ErrorResponse<CompleteShipmentResponse>
  > {
    // Omg, let ^.^
    let dataToSend: RequestRecord

    if (params.shipmentType === INTERNAL_SHIPMENT_TYPE.shipping) {
      dataToSend = {
        transaction_id: params.transactionId,
        shipment_type: params.shipmentType,
        pickup: {
          contact_name: params.pickup.contactName,
          contact_number: params.pickup.contactNumber,
          contact_email: params.pickup.contactEmail,
          address_name: params.pickup.addressName,
          street: params.pickup.street,
          city: params.pickup.city,
          state: params.pickup.state,
          zip_code: params.pickup.zipCode,
          notes: params.pickup.notes || ''
        },
        drop_off: {
          contact_name: params.dropOff.contactName,
          contact_number: params.dropOff.contactNumber,
          contact_email: params.dropOff.contactEmail,
          address_name: params.dropOff.addressName,
          street: params.dropOff.street,
          city: params.dropOff.city,
          state: params.dropOff.state,
          zip_code: params.dropOff.zipCode,
          notes: params.dropOff.notes || ''
        }
      }
    } else {
      dataToSend = {
        transaction_id: params.transactionId,
        shipment_type: params.shipmentType
      }
    }

    const response = await this.utils.makeJSONRequest<
      SuccessResponse<AddShipmentDetailsResponse>,
      ErrorResponse<AddShipmentDetailsResponse>
    >(this.operations.addShipmentDetails, dataToSend)

    if (response.type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        message: response.payload.message
      }
    }

    return {
      status: ResponseStatusMap.Error,
      message: response.payload.message
    }
  }
}
