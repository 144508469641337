import { styled, Typography } from '@mui/material'
import { COLORS } from '../../types/colors'
import { RequiredLabelProps } from './RequiredLabel'

export const StyledRequiredLabel = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'marginTop'
}) <RequiredLabelProps>`
  font-size: 14px;
  margin-top: ${({ marginTop }) => marginTop}px;
  color: ${COLORS.text_primary};
  &::before {
    content: '*';
    margin-right: 6px;
    color: ${COLORS.text_red};
  }
`
