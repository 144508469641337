import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NormalizedTransactionType } from '../../../api/carSnoopApi/transactions/types'
import { OrNull } from '../../../types/OrNull'
import { logout } from '../../thunks/logout'
import { pageNavigation } from '../../thunks/pageNavigation'

export enum CONTRACT_TABS {
  contractInformation,
  documents,
  chat
}
interface ContractPageState {
  currentTab: CONTRACT_TABS
  transaction: OrNull<NormalizedTransactionType>
}

export const initialState: ContractPageState = {
  currentTab: CONTRACT_TABS.contractInformation,
  transaction: null
}

export const contractPageSlice = createSlice({
  name: 'contractPage',
  initialState,
  reducers: {
    setCurrentTab: (store, action: PayloadAction<CONTRACT_TABS>) => {
      store.currentTab = action.payload
    },
    setTransaction: (
      store,
      action: PayloadAction<OrNull<NormalizedTransactionType>>
    ) => {
      store.transaction = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(pageNavigation.fulfilled, () => {
      return initialState
    })
    builder.addCase(logout.fulfilled, () => {
      return initialState
    })
  }
})
