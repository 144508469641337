export enum OFFER_MODAL_FIELDS {
  ADD_ONS = 'addOns',
  VEHICLE_PRICE = 'vehiclePrice',
  SALES_TAX = 'salesTax',
  NOTES = 'notes',
  TRADE_IN = 'tradeIn',
  HAS_TRADE_IN = 'has_tradeIn'
}

export const OFFER_INITIAL_VALUES = {
  [OFFER_MODAL_FIELDS.ADD_ONS]: [],
  [OFFER_MODAL_FIELDS.VEHICLE_PRICE]: '0.00',
  [OFFER_MODAL_FIELDS.SALES_TAX]: '0.00',
  [OFFER_MODAL_FIELDS.NOTES]: '',
  [OFFER_MODAL_FIELDS.TRADE_IN]: '0.00',
  [OFFER_MODAL_FIELDS.HAS_TRADE_IN]: 'true'
}
