import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { selectContractPageModel } from '../../../store/pages/Contract'
import { useUserInfo, useAppSelector } from '../../../utils/hooks'
import { useContractStateDecoder } from './useContractStateDecoder'
import { DealsActiveTabEnum } from '../../../store/types'
import {
  arePaymentsPaid,
  isDeliveryLocal
} from '../components/ContractInformation/utils'
import { useUpdateContractTransaction } from './useUpdateContractTransaction'
import { NormalizedTransactionType } from '../../../api/carSnoopApi/transactions/types'
import { OrNull } from '../../../types/OrNull'
import {
  AGREEMENT_DOCUMENT_NAME,
  PAYMENT_TYPES
} from '../../../constants/applicationConstants'
import { ReadInventoryItemStatusesMap } from '../../../api/carSnoopApi/accounts/types'

const contractIsEditableStatuses = ['Under Contract']

const dealIsCancellableStatuses = ['Under Contract', 'Awaiting Payment']

export const contractInProgressStatuses = [
  'Awaiting Payment',
  'Under Contract',
  'Shipping'
]

const shouldShowPaymentMethodStatuses = [
  'Under Contract',
  'Contract Terminated',
  'Payment failed'
]

const checkShipmentCreation = (
  transaction: OrNull<NormalizedTransactionType>
) => {
  if (!transaction) return false

  const { payments, trackingNumber, contract } = transaction
  const deliveryIsLocal = isDeliveryLocal(contract)
  if (deliveryIsLocal) {
    return true
  }

  const isShipmentPaid = !!payments?.some(
    (p) => p.type === PAYMENT_TYPES.shipping && p.status === 'Paid'
  )

  return isShipmentPaid && !!trackingNumber
}

export const useContractPageInfo = () => {
  const { accountID } = useUserInfo()
  const { transactionId: transactionID = '' } = useParams()
  const { updateTransaction, isLoading } = useUpdateContractTransaction()
  const { transaction, currentTab } = useAppSelector(selectContractPageModel)

  const contractID = transaction?.contract?.id || ''

  const { isCurrentUserABuyer, isCurrentUserASeller } = useMemo(() => {
    return {
      isCurrentUserABuyer: transaction?.buyerID === accountID,
      isCurrentUserASeller: transaction?.sellerID === accountID
    }
  }, [transaction?.buyerID, transaction?.sellerID, accountID])

  const { contractViewState, isAdmin } = useContractStateDecoder(transaction)

  const isContractEditable =
    contractIsEditableStatuses.includes(transaction?.status || '') && isAdmin

  const shouldShowPaymentMethod = shouldShowPaymentMethodStatuses.includes(
    transaction?.status || ''
  )

  const isContractInProgress = useMemo(
    () => contractInProgressStatuses.includes(transaction?.status || ''),
    [transaction?.status]
  )
  const { allowAddDocumentForBuyer, allowAddDocumentForSeller } = useMemo(
    () => ({
      allowAddDocumentForBuyer: isCurrentUserABuyer && isContractInProgress,
      allowAddDocumentForSeller: isCurrentUserASeller && isContractInProgress
    }),
    [isCurrentUserABuyer, isCurrentUserASeller, isContractEditable]
  )

  const isDealCancellable = useMemo(
    () => dealIsCancellableStatuses.includes(transaction?.status || ''),
    [transaction?.status]
  )

  const paymentsPaid = useMemo(
    () => arePaymentsPaid(transaction?.payments || null),
    [transaction]
  )

  const shouldShowCancelDealButton = useMemo(
    () => isCurrentUserABuyer && isDealCancellable && isAdmin && !paymentsPaid,
    [isCurrentUserABuyer, isDealCancellable, isAdmin, paymentsPaid]
  )

  const newTabNumber = isContractInProgress
    ? DealsActiveTabEnum['In Progress']
    : DealsActiveTabEnum.Completed

  const shipmentCreationIsSuccessful = useMemo(
    () => checkShipmentCreation(transaction),
    [transaction]
  )

  const allowPurchaseAgreementCreation = useMemo(
    () =>
      isCurrentUserASeller &&
      transaction?.status === ReadInventoryItemStatusesMap['Under Contract'],
    [isCurrentUserASeller, transaction]
  )

  const isCarsnoopAgreementAvailable = useMemo(
    () =>
      transaction?.contract?.sellerRequiredDocuments.some(
        (doc) => doc.name === AGREEMENT_DOCUMENT_NAME && doc.id
      ),
    [transaction]
  )
  return {
    transaction,
    isLoading,
    readTransaction: updateTransaction,
    transactionID,
    contractViewState,
    isAdmin,
    isCurrentUserABuyer,
    isCurrentUserASeller,
    currentTab,
    allowAddDocumentForBuyer,
    allowAddDocumentForSeller,
    contractID,
    isContractEditable,
    shouldShowPaymentMethod,
    tabNumber: newTabNumber,
    isContractInProgress,
    shouldShowCancelDealButton,
    shipmentCreationIsSuccessful,
    allowPurchaseAgreementCreation,
    isCarsnoopAgreementAvailable
  }
}
