import type { DealsActiveTabEnum } from '../../store/types'
import { SearchComponentProps } from '../../features/SearchInventory'

export const tabs: (keyof typeof DealsActiveTabEnum)[] = [
  'Requests',
  'In Progress',
  'Completed'
]

export type requestsDictType = Record<
  number,
  (searchFormValues: SearchComponentProps['initialValues']) => Promise<unknown>
>

export const closedRequestsStatuses = [
  'Request Cancelled',
  'Request Declined',
  'Request Expired'
]
