import { Box, Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { CameraIcon } from '../../../../assets/icons/Camera'
import { COLORS } from '../../../../types/colors'

interface ImagesCounterLabelProps {
  current: number
  total: number
}

export const ImagesCounterLabel: FC<ImagesCounterLabelProps> = ({
  current,
  total
}) => {
  return (
    <Grid
      container
      alignItems='center'
      sx={{
        backgroundColor: COLORS.border_gray,
        borderRadius: '4px',
        p: 1
      }}
    >
      <Grid item>
        <Box display='flex' alignItems='center' sx={{ mr: 1 }}>
          <CameraIcon width='18' height='18' />
        </Box>
      </Grid>
      <Grid item>
        <Typography variant='subtitle1'>{`${current} of ${total}`}</Typography>
      </Grid>
    </Grid>
  )
}
