import { snackbarActions } from '../../store/features/Snackbar'
import { useAppDispatch } from '.'
import { ResponseStatusMap, RequestStatus } from '../../types/status'

export const useToast = () => {
  const dispatch = useAppDispatch()
  const { openSnackbar, closeSnackbar } = snackbarActions

  const openToast = ({
    status,
    message
  }: {
    status: ResponseStatusMap
    message?: string
  }): void => {
    const defaultMessage =
      status === ResponseStatusMap.Success
        ? 'Data has been saved successfully'
        : 'There was a problem with your submission'

    const toastMessage = message || defaultMessage

    dispatch(
      openSnackbar({
        message: toastMessage,
        status
      })
    )
  }

  const closeToast = () => dispatch(closeSnackbar())

  return { openToast, closeToast }
}

export const useRequestToast = () => {
  const { openToast } = useToast()

  const openRequestToast = ({
    status,
    successMessage,
    errorMessage
  }: {
    status: RequestStatus
    successMessage?: string
    errorMessage?: string
  }) => {
    if (status === 'succeeded') {
      return openToast({
        status: ResponseStatusMap.Success,
        message: successMessage
      })
    }

    if (status === 'failed') {
      return openToast({
        status: ResponseStatusMap.Error,
        message: errorMessage
      })
    }
  }

  return { openRequestToast }
}
