import { Grid } from '@mui/material'
import { Form, Formik, FormikConfig, FormikErrors } from 'formik'
import { FC, useCallback, useMemo, useState } from 'react'
import { AutoselectInputField } from '../../../../../../atoms/AutoselectInputField'
import { AutoselectOption } from '../../../../../../atoms/AutoselectOption'
import { AgreeButton } from '../../../../../../atoms/Button'
import { TextInputFieldNew } from '../../../../../../atoms/TextInputFieldNew'
import { StateOptions } from '../../../../../../constants/applicationConstants'
import { OrNull } from '../../../../../../types/OrNull'
import { ReferenceModel } from '../../../../../../types/ReferenceModel'
import { getOptionLabel } from '../../../../../../utils/autoselect/getOptionLabel'
import { isOptionEqualToValue } from '../../../../../../utils/autoselect/isOptionEqualToValue'
import { CombinedStorage } from '../../../../../../utils/hooks'
import { isRequiredFormField } from '../../../../../../utils/validators/isRequiredFormField'
import { validationSchema } from './validationSchema'

export interface LicensePlateFieldSetValues {
  licensePlate: string
  state: OrNull<ReferenceModel>
}

export interface LicensePlateFieldSetProps {
  onSubmit: FormikConfig<{
    licensePlate: string
    state: ReferenceModel
  }>['onSubmit']
  storage: CombinedStorage<'License Plate', LicensePlateFieldSetValues>
}

export const licensePlateInitialValues: LicensePlateFieldSetValues = {
  licensePlate: '',
  state: null
}

export const getLicensePlateInitialValues = ({
  licensePlate = '',
  state = null
}: Partial<LicensePlateFieldSetValues>): LicensePlateFieldSetValues => ({
  licensePlate,
  state
})

export const LicensePlateFieldSet: FC<LicensePlateFieldSetProps> = ({
  onSubmit,
  storage
}) => {
  const [isChanged, setIsChanged] = useState(false)

  const initialValues = useMemo(() => {
    return storage.values['License Plate']
  }, [storage])

  const syncFormValues = useCallback(
    (values: LicensePlateFieldSetValues) => {
      storage.updateValues('License Plate', values)
    },
    [storage]
  )

  const syncFormErrors = useCallback(
    (errors: FormikErrors<LicensePlateFieldSetValues>) => {
      storage.updateErrors('License Plate', errors)
    },
    [storage]
  )

  const initialErrors = useMemo(() => {
    return storage.errors['License Plate']
  }, [storage])

  const handleIsChanged = useCallback(() => {
    setIsChanged(true)
  }, [setIsChanged])

  const handleSubmit = useCallback(
    (...args: Parameters<typeof onSubmit>) => {
      setIsChanged(false)
      onSubmit(...args)
    },
    [onSubmit]
  )

  const isRequiredField = isRequiredFormField(validationSchema)

  return (
    <Formik
      initialValues={initialValues}
      initialErrors={initialErrors}
      validationSchema={validationSchema}
      validateOnChange={false}
      onSubmit={handleSubmit}
      validateOnMount
    >
      {(formik) => {
        syncFormValues(formik.values)
        syncFormErrors(formik.errors)

        return (
          <Form>
            <Grid container spacing={1}>
              <Grid container item spacing={1} xs={12} md={9}>
                <Grid item xs={8}>
                  <TextInputFieldNew
                    name='licensePlate'
                    label='License Plate'
                    validate={(value) =>
                      value === initialValues.licensePlate
                        ? initialErrors?.licensePlate
                        : ''
                    }
                    onIsChanged={handleIsChanged}
                    required={isRequiredField}
                  />
                </Grid>
                <Grid item xs={4}>
                  <AutoselectInputField<ReferenceModel>
                    name='state'
                    label='State'
                    options={StateOptions}
                    renderOption={AutoselectOption}
                    getOptionLabel={getOptionLabel}
                    isOptionEqualToValue={isOptionEqualToValue}
                    onSelect={handleIsChanged}
                    required={isRequiredField}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={3}>
                <AgreeButton
                  type='submit'
                  disabled={!formik.isValid || !isChanged}
                  sx={{ height: '60px', width: '100%' }}
                >
                  Lookup
                </AgreeButton>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}
