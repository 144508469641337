import { Grid, Typography } from '@mui/material'
import { Form, Formik, FormikProps } from 'formik'
import { FC, memo, useCallback } from 'react'
import { DependentFieldHocEffect } from '../../../../atoms/DependentField'
import { MaskedInputFieldProps } from '../../../../atoms/MaskedInput'
import { TextInputFieldNew } from '../../../../atoms/TextInputFieldNew'
import { zipCodeMask } from '../../../../constants/regexp'
import { ResponseStatusMap } from '../../../../types/status'

import {
  BUYER_FIELDS_ERRORS,
  BUYER_FIELDS_LABELS,
  BUYER_FIELDS_NAMES,
  BuyerFieldsTypes
} from './types'

import { RequiredLabel } from '../../../../atoms/RequiredText'
import type { Address } from '../../../../store/types'
import { useCheckZipCode } from '../../../../utils/hooks/useCheckZipCode'
import { noop } from '../../../../utils/noop'
import { isRequiredFormField } from '../../../../utils/validators/isRequiredFormField'
import { DependentZipCodeField } from './DependentField'
import { validationSchema } from './ValidationSchema'

export type BuyerDetailsRefTargetType = FormikProps<BuyerFieldsTypes>
interface BuyerDetailsFormProps {
  fullName: string
  address: Address
  defaultValues?: BuyerFieldsTypes
  formRef: (node: BuyerDetailsRefTargetType) => void
}
export const BuyerDetailsForm: FC<BuyerDetailsFormProps> = memo((props) => {
  const { defaultValues, fullName, address, formRef } = props
  const { street, zipCode, city, state } = address
  const { checkZipCode, checkZipCodeObservable } = useCheckZipCode()

  const zipCodeFieldEffect: DependentFieldHocEffect<
    MaskedInputFieldProps,
    BuyerFieldsTypes
  > = useCallback(async (_, ctx) => {
    const { values, setFieldValue, setValues, validateForm, setFieldError } =
      ctx
    const { zipCode: zipCodeValue } = values
    setFieldValue(BUYER_FIELDS_NAMES.CITY, '')
    setFieldValue(BUYER_FIELDS_NAMES.STATE, '')

    if (zipCodeValue) {
      const response = await checkZipCode(zipCodeValue)

      if (response.status === ResponseStatusMap.Success) {
        setValues(
          {
            ...values,
            [BUYER_FIELDS_NAMES.CITY]: response.city,
            [BUYER_FIELDS_NAMES.STATE]: response.state
          },
          true
        )
      } else {
        validateForm().then(() => {
          setFieldError(
            BUYER_FIELDS_NAMES.ZIP_CODE,
            BUYER_FIELDS_ERRORS.ZIP_CODE
          )
        })
      }
    }
  }, [])

  const isRequiredField = isRequiredFormField(validationSchema)

  return (
    <>
      <Grid container item flexDirection='column'>
        <Typography variant='label1'>
          1. Enter your Legal Name and Address
        </Typography>
        <Typography variant='details'>
          We need this information to create a contract between you and the
          seller. Note, if you are under 18 years old the primary buyer should
          be any other adult account member.
        </Typography>
      </Grid>
      <Grid container mt={2}>
        <Formik<BuyerFieldsTypes>
          onSubmit={noop}
          initialValues={{
            buyerName: defaultValues?.buyerName || fullName,
            buyerAddress: defaultValues?.buyerAddress || street || '',
            zipCode: defaultValues?.zipCode || zipCode,
            city: defaultValues?.city || city,
            state: defaultValues?.state || state
          }}
          validateOnChange={false}
          validationSchema={validationSchema}
          innerRef={formRef}
        >
          {() => (
            <Form>
              <Grid container item spacing='16px'>
                <Grid item xs={12}>
                  <RequiredLabel />
                </Grid>
                <Grid item xs={12}>
                  <TextInputFieldNew
                    name={BUYER_FIELDS_NAMES.NAME}
                    label={BUYER_FIELDS_LABELS.NAME}
                    required={isRequiredField}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInputFieldNew
                    name={BUYER_FIELDS_NAMES.ADDRESS}
                    label={BUYER_FIELDS_LABELS.ADDRESS}
                    required={isRequiredField}
                  />
                </Grid>
                <Grid container item spacing='16px'>
                  <Grid item xs={5}>
                    <DependentZipCodeField
                      name={BUYER_FIELDS_NAMES.ZIP_CODE}
                      label={BUYER_FIELDS_LABELS.ZIP_CODE}
                      onDoneEffect={zipCodeFieldEffect}
                      mask={zipCodeMask}
                      required={isRequiredField}
                    />
                  </Grid>

                  <Grid item xs={3.5}>
                    <TextInputFieldNew
                      name={BUYER_FIELDS_NAMES.CITY}
                      label={BUYER_FIELDS_LABELS.CITY}
                      disabled
                      isLoading={checkZipCodeObservable.isLoading}
                      required={isRequiredField}
                    />
                  </Grid>
                  <Grid item xs={3.5}>
                    <TextInputFieldNew
                      name={BUYER_FIELDS_NAMES.STATE}
                      label={BUYER_FIELDS_LABELS.STATE}
                      disabled
                      isLoading={checkZipCodeObservable.isLoading}
                      required={isRequiredField}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </>
  )
})
