import { Grid } from '@mui/material'
import { ShippingVehicleIcon } from '../../../../assets/icons/ShippingVehicle'
import { ManualDataGetting } from '../../../../atoms/ManualDataGetting'
import { COLORS } from '../../../../types/colors'
import { noop } from '../../../../utils'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const ManualDataGettingExample = () => {
  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <Grid container spacing={1}>
          <Grid item>
            <ManualDataGetting
              status='idle'
              onGetClick={noop}
              onRetryClick={noop}
              dataText='Get shipping cost'
              dataIcon={<ShippingVehicleIcon fill={COLORS.text_blue} />}
            />
          </Grid>
          <Grid item>
            <ManualDataGetting
              status='pending'
              onGetClick={noop}
              onRetryClick={noop}
              dataText='Get shipping cost'
              dataIcon={<ShippingVehicleIcon fill={COLORS.text_blue} />}
            />
          </Grid>
          <Grid item>
            <ManualDataGetting
              status='failed'
              onGetClick={noop}
              onRetryClick={noop}
              dataText='Get shipping cost'
              dataIcon={<ShippingVehicleIcon fill={COLORS.text_blue} />}
            />
          </Grid>
          <Grid item>
            <ManualDataGetting
              status='succeeded'
              onGetClick={noop}
              onRetryClick={noop}
              dataText='Get shipping cost'
              dataIcon={<ShippingVehicleIcon fill={COLORS.text_blue} />}
            />
          </Grid>
        </Grid>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
