import { Utils } from '../../utils'
import { SuccessResponse, ErrorResponse } from '../../private.types'
import { ResponseStatusMap } from '../../../types/status'
import {
  ReadLinkedInProfileRawResponse,
  ReadLinkedInProfileResponse
} from './types'

export class Users {
  protected readonly utils: Utils
  protected readonly operations = {
    readLinkedInProfile: 'readLinkedInProfile'
  }

  constructor(utils: Utils) {
    this.utils = utils
  }

  async readLinkedInProfile(
    code: string
  ): Promise<
    | SuccessResponse<ReadLinkedInProfileResponse>
    | ErrorResponse<{ message: string }>
  > {
    const response = await this.utils.makeJSONRequest<
      ReadLinkedInProfileRawResponse,
      ErrorResponse<{ message: string }>
    >(this.operations.readLinkedInProfile, {
      code
    })
    // eslint-disable-next-line
    console.log('Read user response: ', response)
    if (response.type === ResponseStatusMap.Success) {
      return {
        status: ResponseStatusMap.Success,
        email: response.payload.email,
        firstName: response.payload.first_name,
        lastName: response.payload.last_name,
        avatar: response.payload.avatar
      }
    }

    return {
      status: ResponseStatusMap.Error,
      message: response.payload.message
    }
  }
}
