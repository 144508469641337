import { FC, memo } from 'react'
import { SupportFormWrapper } from '../../features/Support/SupportFormWrapper'
import { SIDE_BAR } from '../../store/features/SideBar/SideBar.types'

interface ISideBarContentSwitcherProps {
  barType: SIDE_BAR
}

export const SideBarContentSwitcher: FC<ISideBarContentSwitcherProps> = memo(
  ({ barType }) => {
    switch (barType) {
      case SIDE_BAR.CONTACT_SUPPORT: {
        return <SupportFormWrapper />
      }

      case SIDE_BAR.NONE:
      default: {
        return <div>Empty side bar</div>
      }
    }
  }
)
