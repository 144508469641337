import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { FC, useEffect, useMemo } from 'react'
import { CollapsibleNotes } from '../../../../../../../../atoms/CollapsibleNotes'
import { Show } from '../../../../../../../../atoms/JSXExtensions'
import { useUserInfo } from '../../../../../../../../utils/hooks'
import { useShareErrorContext } from '../../../../../../utils/ShareErrorContext'
import { DetailsItem } from '../../../DetailsItem'

export interface ViewModeProps {
  legalName: string
  legalStreet: string
  legalZipCode: string
  legalCity: string
  legalState: string
  notes?: string
  isCurrentUserASeller: boolean
}

export const ViewMode: FC<ViewModeProps> = (props) => {
  const {
    legalName,
    legalStreet,
    legalZipCode,
    legalCity,
    legalState,
    notes,
    isCurrentUserASeller
  } = props
  const { isAdmin } = useUserInfo()

  const emptyNotesPlaceholder = useMemo(
    () =>
      isCurrentUserASeller
        ? 'Contract notes are not added yet'
        : 'If seller adds contract notes you will see it here',
    [isCurrentUserASeller]
  )

  const { setHasError } = useShareErrorContext()

  const hasError = useMemo(
    () => !legalStreet && isCurrentUserASeller && isAdmin,
    [legalStreet, isCurrentUserASeller, isAdmin]
  )

  useEffect(() => {
    setHasError(hasError)
  }, [setHasError, hasError])

  return (
    <Grid container item flexDirection='column'>
      <Grid container item spacing='20px' flexDirection='column'>
        <DetailsItem title='Legal Name' value={legalName} />
        <DetailsItem
          title='Legal Address'
          value={legalStreet}
          fallbackText='Street is not added'
        />
        <DetailsItem title='Zip Code' value={legalZipCode} />
        <DetailsItem
          title='City, state'
          value={`${legalCity}, ${legalState}`}
        />
      </Grid>
      <Show when={hasError}>
        <Grid item mt='8px'>
          <Typography variant='subtitle1' color='error'>
            Please, add a complete address.
          </Typography>
        </Grid>
      </Show>
      <Grid item mt='20px'>
        <CollapsibleNotes
          header={<Typography variant='label1'>Contract Notes</Typography>}
          fallback={
            <Box pt={2}>
              <Typography variant='subtitle2' fontStyle='italic'>
                {emptyNotesPlaceholder}
              </Typography>
            </Box>
          }
        >
          {notes}
        </CollapsibleNotes>
      </Grid>
    </Grid>
  )
}
