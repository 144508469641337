import { useEffect, useState } from 'react'
import { ReadVehicleResponse } from '../../../api/carSnoopApi/vehicles'
import { colorToOption } from '../../../store/units/findCarForm/utils'
import { ColorOption } from '../../../types/Autoselect'
import { OrNull } from '../../../types/OrNull'
import { ResponseStatusMap } from '../../../types/status'
import { useApiContext, useMakeService } from '../../../utils/hooks'

export const useReadVehicle = (vehicleId: string) => {
  const [currentVehicle, setCurrentVehicle] =
    useState<OrNull<ReadVehicleResponse>>(null)

  const [currentVehicleOptions, setCurrentVehicleOptions] =
    useState<OrNull<{ extColors: ColorOption[]; intColors: ColorOption[] }>>(
      null
    )

  const { carSnoopApi } = useApiContext()

  const [readVehicle, readVehicleObservable] = useMakeService(
    async (id: string) => {
      const response = await carSnoopApi.vehicles.readVehicle(id)

      if (response.status === ResponseStatusMap.Success) {
        setCurrentVehicle(response)
        const modelInfo = await carSnoopApi.vehicles.readCarModelYearTrimFromVehicles({
          make: response.make,
          model: response.model,
          year: `${response.year}`,
          trim: response.trim
        })

        if (modelInfo.status === ResponseStatusMap.Success) {
          const { exteriorColors, interiorColors } = modelInfo.data
          const extColors = exteriorColors.map(colorToOption)
          const intColors = interiorColors.map(colorToOption)
          setCurrentVehicleOptions({ extColors, intColors })
        }

        return response
      }
      return { status: ResponseStatusMap.Error }
    }
  )

  useEffect(() => {
    vehicleId && readVehicle(vehicleId)
  }, [vehicleId])

  return [
    readVehicleObservable.isLoading,
    currentVehicle,
    currentVehicleOptions
  ] as [boolean, typeof currentVehicle, typeof currentVehicleOptions]
}
