import { styled } from '@mui/material'
import { COLORS } from '../../../../types/colors'

export const SuccessVerificationContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  & > div:last-child {
    margin-bottom: 0;
    margin-top: auto;
  }

  & > h3 {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 24px;
  }

  & > p {
    color: ${COLORS.text_secondary};
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
`

export const IconContainer = styled('div')`
  margin: 22px auto;
`
