import { FC } from 'react'
import { Box } from '@mui/material'

export const Hover: FC = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        backgroundColor: 'gray',
        opacity: '0.5',
        zIndex: 3
      }}
    />
  )
}
