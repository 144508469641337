import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { Grid, Typography } from '@mui/material'
import { format } from 'date-fns'
import { FC, useMemo } from 'react'
import {
  NormalizedOfferType,
  NormalizedTransactionType
} from '../../../api/carSnoopApi/transactions/types'
import { Show } from '../../../atoms/JSXExtensions'
import { NavigationBackButton } from '../../../atoms/NavigationBackButton'
import { VehicleBadge } from '../../../molecules/VehicleItem/VehicleBadge'
import { NonNullableRecord } from '../../../types/NonNullableRecord'

const expiredStatusList = ['Offer Cancelled', 'Offer Expired', 'Offer Declined']

export type OfferHeaderProps = NonNullableRecord<
  Pick<NormalizedOfferType, 'expiresOn'> &
    Pick<NormalizedTransactionType, 'status'>
> & {
  onBackClick: () => void
  showBackButton?: boolean
}

export const OfferHeader: FC<OfferHeaderProps> = ({
  expiresOn,
  status,
  showBackButton = true,
  onBackClick
}) => {
  const isExpiredOffer = useMemo(
    () => !expiredStatusList.includes(status),
    [status]
  )

  const expirationDate = useMemo(() => {
    return format(expiresOn, 'MM / dd / yyyy')
  }, [expiresOn])

  return (
    /* Back button */
    <Grid
      container
      justifyContent='space-between'
      wrap='nowrap'
      alignItems='center'
    >
      <NavigationBackButton
        buttonText='Offer details'
        onBackClick={onBackClick}
        textVariant='h6'
        showBackButton={showBackButton}
      />
      {/* Expiration date */}
      <Grid item xs='auto'>
        <Grid container spacing={1} alignItems='center' wrap='nowrap'>
          <Show when={isExpiredOffer}>
            <>
              <Grid item>
                <Typography variant='main' noWrap>
                  Offer Expires on
                </Typography>
              </Grid>
              <Grid item container alignItems='center' xs='auto'>
                <AccessTimeIcon />
              </Grid>
              <Grid item>
                <Typography variant='main' noWrap>
                  {expirationDate}
                </Typography>
              </Grid>
            </>
          </Show>
          <Grid item>
            <VehicleBadge status={status} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
