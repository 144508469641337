import { FC, MouseEventHandler } from 'react'
import { Grid } from '@mui/material'

import { AgreeButton, CancelButton } from '../../atoms/Button'

interface IModalFooter {
  isConfirmDisabled: boolean
  onConfirm: MouseEventHandler<HTMLButtonElement> | (() => void | Promise<void>)
  onCancel: (() => void) | MouseEventHandler<Element>
  confirmText?: string
  cancelText?: string
}

export const ModalFooter: FC<IModalFooter> = ({
  onConfirm,
  onCancel,
  confirmText = 'Save',
  cancelText = 'Cancel',
  isConfirmDisabled
}) => {
  return (
    <Grid container justifyContent='flex-end' spacing={2}>
      <Grid item>
        <CancelButton onClick={onCancel}>{cancelText}</CancelButton>
      </Grid>
      <Grid item>
        <AgreeButton
          disabled={isConfirmDisabled}
          type='submit'
          {...(!!onConfirm && { onClick: onConfirm })}
        >
          {confirmText}
        </AgreeButton>
      </Grid>
    </Grid>
  )
}
