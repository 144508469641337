import { format, differenceInCalendarDays } from 'date-fns'
import { chatDatesFormatters } from '../../../constants/applicationConstants'

export const useMessageSendTime = (
  timestamp: string,
  isShortForm: boolean = false
) => {
  const date = new Date(timestamp)
  const dayDifference = differenceInCalendarDays(new Date(), date)

  const isToday = !dayDifference
  const isYesterday = dayDifference === 1

  if (isToday) {
    return isShortForm
      ? format(date, chatDatesFormatters.time)
      : `Today, ${format(date, chatDatesFormatters.time)}`
  }

  if (isYesterday) {
    return isShortForm
      ? format(date, chatDatesFormatters.date)
      : `Yesterday, ${format(date, chatDatesFormatters.time)}`
  }

  return isShortForm
    ? format(date, chatDatesFormatters.date)
    : format(date, chatDatesFormatters.default)
}
