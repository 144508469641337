import * as Yup from 'yup'
import {
  countOfNumbers,
  latinWithSpecial,
  notOnlyZeroes
} from '../../../../utils/validators'
import { BUYER_FIELDS_ERRORS, BUYER_FIELDS_NAMES } from './types'

export const validationSchema = Yup.object({
  [BUYER_FIELDS_NAMES.NAME]: Yup.string()
    .required('')
    .trim()
    .test(
      'is-latin-or-special',
      BUYER_FIELDS_ERRORS.NAME,
      latinWithSpecial([' '])
    ),
  [BUYER_FIELDS_NAMES.ADDRESS]: Yup.string()
    .required('')
    .trim()
    .test(
      'is-latin-or-special',
      BUYER_FIELDS_ERRORS.ADDRESS,
      latinWithSpecial([' '])
    )
    .nullable(),
  [BUYER_FIELDS_NAMES.ZIP_CODE]: Yup.string()
    .required('')
    .trim()
    .test('length', BUYER_FIELDS_ERRORS.ZIP_CODE, countOfNumbers(5))
    .test('not-only-zeroes', BUYER_FIELDS_ERRORS.ZIP_CODE, notOnlyZeroes()),
  [BUYER_FIELDS_NAMES.CITY]: Yup.string().required(''),
  [BUYER_FIELDS_NAMES.STATE]: Yup.string().required('')
})
