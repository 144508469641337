import * as Yup from 'yup'
import {
  MIN_DATE,
  MAX_DATE,
  phoneNumberLength
} from '../../constants/applicationConstants'
import { countOfNumbers, latinWithSpecial } from '../../utils/validators'

export const validationSchema = Yup.object({
  firstName: Yup.string()
    .required('')
    .trim()
    .test(
      'is-latin-or-special',
      'Enter a valid First Name.',
      latinWithSpecial([' '])
    ),
  lastName: Yup.string()
    .required('')
    .trim()
    .test(
      'is-latin-or-special',
      'Enter a valid Last Name.',
      latinWithSpecial([' '])
    ),
  dateOfBirth: Yup.date().required('').max(MAX_DATE).min(MIN_DATE),
  phone: Yup.string()
    .required('')
    .trim()
    .test(
      'length',
      'Enter a valid phone number.',
      countOfNumbers(phoneNumberLength)
    ),
  email: Yup.string().required('')
})
