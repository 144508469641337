import { Grid } from '@mui/material'
import { FC, useRef } from 'react'
import {
  SubscriptionMicroform,
  SubscriptionFormRefTargetType,
  subscriptionOptions
} from '../../../../../features/Subscription'
import { ComponentContainer } from '../../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const SubscriptionMicroformExample: FC = () => {
  const subscriptionMicroformRef = useRef<SubscriptionFormRefTargetType>(null)

  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <Grid container justifyContent='center'>
          <Grid item>
            <SubscriptionMicroform
              options={subscriptionOptions}
              formRef={subscriptionMicroformRef}
            />
          </Grid>
        </Grid>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
