import {
  CheckCircleOutlined as CheckCircleOutlinedIcon,
  Close as CloseIcon,
  ErrorOutlineOutlined as ErrorOutlineOutlinedIcon
} from '@mui/icons-material'
import {
  Alert,
  Grid,
  IconButton,
  Snackbar as SnackbarContainer
} from '@mui/material'
import { FC, useCallback } from 'react'
import { selectSnackbarState } from '../../store/features/Snackbar'
import { BGCOLORS } from '../../types/colors'
import { ResponseStatusMap } from '../../types/status'
import { useAppSelector, useToast } from '../../utils/hooks'
import { Condition } from '../Condition'

export const Snackbar: FC = () => {
  const snackbarState = useAppSelector(selectSnackbarState)
  const { closeToast } = useToast()

  const handleClose = useCallback(() => {
    closeToast()
  }, [])

  const backgroundColor =
    snackbarState.status === ResponseStatusMap.Success
      ? BGCOLORS.snackbarSuccess
      : BGCOLORS.snackbarError

  return (
    <SnackbarContainer
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={snackbarState.isOpen}
      onClose={handleClose}
      key={snackbarState.message}
      sx={{
        '@media (min-width:600px)': {
          top: '86px',
          right: '30px'
        }
      }}
    >
      <Alert
        severity={snackbarState.status}
        icon={
          <Condition
            condition={snackbarState.status === ResponseStatusMap.Success}
            trueContent={<CheckCircleOutlinedIcon />}
            falseContent={<ErrorOutlineOutlinedIcon />}
          />
        }
        sx={{
          minWidth: '300px',
          backgroundColor,
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          '& > div:nth-of-type(2)': {
            flexGrow: 1
          }
        }}
      >
        <Grid container justifyContent='space-between' alignItems='center'>
          <Grid item>{snackbarState.message}</Grid>
          <Grid item>
            <IconButton
              size='small'
              aria-label='close'
              color='inherit'
              onClick={handleClose}
              sx={{ ml: 1 }}
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          </Grid>
        </Grid>
      </Alert>
    </SnackbarContainer>
  )
}
