import { Box, Button, Grid, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { SxProps } from '@mui/system'
import {
  FC,
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useMemo
} from 'react'
import { CloseIcon } from '../../assets/icons/Close'
import { AgreeButton, CancelButton } from '../../atoms/Button'
import { Condition } from '../../atoms/Condition'
import { COLORS } from '../../types/colors'
import { noop } from '../../utils'
import { Modal } from './Modal'

export type ConfirmationModalProps = PropsWithChildren<{
  header?: ReactNode
  isOpen: boolean
  setIsOpen: (status: boolean) => void
  buttonsPosition?: 'flex-start' | 'center' | 'flex-end'
  sx?: SxProps<Theme>
  submit?: {
    text?: string
    disabled?: boolean
    callback?: MouseEventHandler<Element>
    closeModal?: boolean
    sx?: SxProps
  }
  cancel?: {
    text?: string
    disabled?: boolean
    callback?: MouseEventHandler<Element>
    closeModal?: boolean
  }
}>

const defaultStyle = {
  minWidth: { xs: '98vw', md: '458px' },
  backgroundColor: 'white',
  borderRadius: '14px'
}

const DefaultHeader: FC<PropsWithChildren> = () => {
  return (
    <Grid item>
      <Typography variant='h6'>Confirmation</Typography>
    </Grid>
  )
}

export const ConfirmationModal: FC<ConfirmationModalProps> = (props) => {
  const {
    header,
    isOpen,
    setIsOpen,
    buttonsPosition = 'flex-end',
    sx = {},
    children,
    cancel = {},
    submit = {}
  } = props

  const {
    text: submitText,
    callback: submitCallback = noop,
    disabled: submitDisabled = false,
    closeModal: submitCloseModal = true,
    sx: submitSx = {}
  } = submit
  const {
    text: cancelText,
    callback: cancelCallback = noop,
    disabled: cancelDisabled = false,
    closeModal: cancelCloseModal = true
  } = cancel

  const onSubmitHandler = useCallback<MouseEventHandler>(
    async (e) => {
      e.stopPropagation()
      e.preventDefault()
      await submitCallback(e)

      if (submitCloseModal) {
        setIsOpen(false)
      }
    },
    [submitCallback, setIsOpen]
  )

  const onCancelHandler = useCallback<MouseEventHandler>(
    (e) => {
      e.stopPropagation()
      e.preventDefault()
      cancelCallback(e)

      if (cancelCloseModal) {
        setIsOpen(false)
      }
    },
    [cancelCallback, setIsOpen]
  )

  const headerComponent = useMemo(() => header || <DefaultHeader />, [header])

  const style = useMemo(() => {
    return {
      ...sx,
      ...defaultStyle
    }
  }, [sx])

  return (
    <Modal open={isOpen}>
      <Box sx={style}>
        <Grid container justifyContent='space-between'>
          <Grid
            container
            item
            justifyContent='space-between'
            px={3}
            py={2}
            borderBottom={`1px solid ${COLORS.border_gray}`}
          >
            {headerComponent}
            <Grid
              container
              item
              xs={2}
              justifyContent='flex-end'
              alignItems='center'
            >
              <Button
                disableElevation
                variant='text'
                sx={{ minWidth: 0, padding: 0 }}
                onClick={onCancelHandler}
              >
                <CloseIcon />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {children}
        <Condition
          condition={!!cancelText || !!submitText}
          trueContent={
            <Grid
              container
              item
              xs={12}
              justifyContent={buttonsPosition}
              sx={{ padding: '24px' }}
            >
              <Condition
                condition={!!cancelText}
                trueContent={
                  <Grid item>
                    <CancelButton
                      onClick={onCancelHandler}
                      disabled={cancelDisabled}
                    >
                      {cancelText}
                    </CancelButton>
                  </Grid>
                }
              />
              <Condition
                condition={!!submitText}
                trueContent={
                  <Grid item ml={buttonsPosition !== 'center' ? 2 : 0}>
                    <AgreeButton
                      onClick={onSubmitHandler}
                      disabled={submitDisabled}
                      sx={submitSx}
                    >
                      {submitText}
                    </AgreeButton>
                  </Grid>
                }
              />
            </Grid>
          }
        />
      </Box>
    </Modal>
  )
}
