import { FC, useMemo } from 'react'
import { formatEmail, replace } from '../../utils/formatters/formatEmail'
import { EllipsisText } from '../EllipsisText'

export type EllipsisEmailProps = {
  maxCount?: number
  children: string
}

export const EllipsisEmail: FC<EllipsisEmailProps> = (props) => {
  const { maxCount, children } = props

  const formattedEmail = useMemo(() => {
    return formatEmail(children, maxCount)
  }, [children, maxCount])

  const isReduced = useMemo(() => {
    return formattedEmail.includes(replace)
  }, [formattedEmail])

  return (
    <EllipsisText tooltipText={children} forcedTooltip={isReduced}>
      {formattedEmail}
    </EllipsisText>
  )
}
