import { FC, useState, useEffect } from 'react'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { TextField } from '@mui/material'
import { DateValidationError } from '@mui/x-date-pickers/internals'
import { ErrorMessageBlock } from '../ErrorMessageBlock'
import { Condition } from '../Condition'
import {
  DatePickerContainer,
  ErrorMessageContainer,
  InfoMessageContainer
} from './DatePicker.styles'

type DatePickerProps = {
  label: string
  name: string
  isError: boolean
  errorMessage?: string
  infoMessage?: string
  value: Date | null
  minDate?: Date
  maxDate?: Date
  onChange: (
    value: Date | null,
    keyboardInputValue?: string | undefined
  ) => void
  onError?: (e: DateValidationError) => void
  onBlur?: () => void
  disabled?: boolean
  newFieldVariant?: boolean
}

const ERROR_MESSAGE = 'Please enter a valid date format.'

export const DatePicker: FC<DatePickerProps> = ({
  label,
  name,
  isError,
  errorMessage = ERROR_MESSAGE,
  infoMessage,
  value,
  minDate,
  maxDate,
  onChange,
  onError,
  onBlur,
  disabled = false,
  newFieldVariant = false
}) => {
  const [isTouched, setIsTouched] = useState<boolean>(false)
  const [isOnFocus, setIsOnFocus] = useState<boolean>(false)
  const isErrorApplied = isTouched && isError && !!value && !isOnFocus

  useEffect(() => {
    if (!isOnFocus && onBlur) {
      onBlur()
    }
  }, [value, isOnFocus])

  return (
    <DatePickerContainer
      isNewFieldVariant={newFieldVariant}
      hasError={isErrorApplied}
      hasFocus={isOnFocus}
    >
      <Condition
        condition={!newFieldVariant}
        trueContent={<label htmlFor={name}>{label}</label>}
      />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          inputFormat='MM/dd/yyyy'
          disabled={disabled}
          value={value}
          defaultCalendarMonth={maxDate}
          maxDate={maxDate}
          minDate={minDate}
          onChange={(val) => {
            onChange(val)
            setIsTouched(false)
          }}
          InputAdornmentProps={{ position: 'start' }}
          onClose={() => setIsTouched(true)}
          onError={onError}
          renderInput={(params) => (
            <TextField
              name='birthDate'
              {...params}
              onBlur={() => {
                setIsTouched(true)
                setIsOnFocus(false)
              }}
              onFocus={() => setIsOnFocus(true)}
            />
          )}
        />
      </LocalizationProvider>
      <Condition
        condition={isErrorApplied}
        trueContent={
          <ErrorMessageContainer>
            <ErrorMessageBlock message={errorMessage} />
          </ErrorMessageContainer>
        }
      />
      <Condition
        condition={!!infoMessage}
        trueContent={<InfoMessageContainer>{infoMessage}</InfoMessageContainer>}
      />
    </DatePickerContainer>
  )
}
