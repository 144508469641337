import { styled } from '@mui/system';

export const PromotionBlockContainer = styled('div')(({ theme }) => ({
  marginRight: '43px',
  paddingTop: '98px',
  width: '562px',
  [theme.breakpoints.down('lg')]: {
    marginRight: 0,
    paddingTop: '32px',
    width: '460px',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  }
}))

export const PromotionTitle = styled('div')(({ theme }) => ({
  fontSize: '36px',
  fontWeight: 500,
  lineHeight: '40px',
  paddingLeft: '45px',
  [theme.breakpoints.down('lg')]: {
    fontSize: '32px',
    padding: 0,
  }
}))

export const PromotionDescription = styled('div')(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '26px',
  marginTop: '22px',
  paddingLeft: '45px',
  [theme.breakpoints.down('lg')]: {
    margin: '16px 0 10px',
    padding: 0,
  }
}))

export const PromotionImage = styled('div')(({ theme }) => ({
  marginTop: '65px',
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  }
}))
