import { Typography } from '@mui/material'
import { useState } from 'react'
import { For } from '../../../../atoms/JSXExtensions'
import { ChatContainer } from '../../../../features/Chat/view/ChatContainer'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { CompressImageSuccessResponse } from '../../../../utils/compressImages'

export const ChatContainerExample = () => {
  const [messages, setMessages] = useState<string[]>([])

  const handleSend = async (v: string) => {
    setMessages((ms) => [...ms, v])
  }

  const onFileSend = (file: CompressImageSuccessResponse) => {
    console.log(file)
  }

  return (
    <ComponentContainer fullWidth>
      <ChatContainer
        chatId='test'
        onSend={handleSend}
        onFileSend={onFileSend}
        isUploadInProgress={false}
      >
        <For of={messages}>{(m) => <Typography>{m}</Typography>}</For>
      </ChatContainer>
    </ComponentContainer>
  )
}
