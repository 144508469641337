import { useCallback, useEffect, useState } from 'react'
import { OrNull } from '../../types/OrNull'

export const useRestoreData = <T>(key: string) => {
  const storage = window.localStorage

  const [restoredData, setRestoredData] = useState<OrNull<T>>(null)

  const [isRestoring, setIsRestoring] = useState<boolean>(true)

  useEffect(() => {
    const dataToRestore = storage.getItem(key)

    if (dataToRestore) {
      try {
        const parsedData: T = JSON.parse(dataToRestore)
        setRestoredData(parsedData)
        storage.removeItem(key)
      } catch (_) {
        console.warn('Data restoring is failed')
      }
    }

    setIsRestoring(false)
  }, [])

  const storeData = useCallback(
    (data: T) => {
      const preparedData = JSON.stringify(data)
      storage.setItem(key, preparedData)
    },
    [key]
  )

  const clearData = useCallback(() => {
    storage.removeItem(key)
  }, [key])

  return {
    restoredData,
    isRestoring,
    storeData,
    clearData
  }
}
