import { Step } from '../../atoms/FindCarFormStepper'
import type { Address } from '../../store/types'
import { ColorOption } from '../../types/Autoselect'
import { OrNull } from '../../types/OrNull'
import { ReferenceModel } from '../../types/ReferenceModel'
import { VehicleCondition } from '../../types/status'

export const formSteps: Step[] = [
  { label: 'Look Up Your Car' },
  { label: 'Review & Save' },
  { label: 'Add Photos' }
]

export enum ADD_VEHICLE_FIELD_NAMES {
  STOCK_NUMBER = 'stockNumber',
  LICENSE_PLATE = 'formLicensePlate',
  LICENSE_PLATE_STATE = 'formLicensePlateState',
  ADDRESS = 'address',
  EXTERIOR_COLOR = 'exteriorColor',
  INTERIOR_COLOR = 'interiorColor',
  CURRENT_MILEAGE = 'currentMileage',
  CONDITION = 'condition',
  HOW_MUCH_IS_OWED = 'howMuchIsOwed',
  NOTES = 'notes',
  TRADE_IN_VALUE = 'tradeinValue',
  RETAIL_VALUE = 'retailValue',
  MAKE = 'make',
  MODEL = 'model',
  YEAR = 'year',
  TRIM = 'trim'
}

export enum ADD_VEHICLE_FIELD_LABELS {
  STOCK_NUMBER = 'Stock Number',
  LICENSE_PLATE = 'License Plate',
  LICENSE_PLATE_STATE = 'State',
  ADDRESS = 'Address',
  EXTERIOR_COLOR = 'Exterior Color',
  INTERIOR_COLOR = 'Interior Color',
  CURRENT_MILEAGE = 'Current Mileage',
  CONDITION = 'Condition',
  HOW_MUCH_IS_OWED = 'How Much is Owed',
  NOTES = 'Notes',
  TRADE_IN_VALUE = 'Trade in Value',
  RETAIL_VALUE = 'Retail Value',
  MAKE = 'Make',
  MODEL = 'Model',
  YEAR = 'Year',
  TRIM = 'Trim'
}

export type AddressOption = ReferenceModel & { rawValue: Address }

export interface AddVehicleFormFieldsTypes {
  [ADD_VEHICLE_FIELD_NAMES.STOCK_NUMBER]: OrNull<string>
  [ADD_VEHICLE_FIELD_NAMES.LICENSE_PLATE]: string
  [ADD_VEHICLE_FIELD_NAMES.LICENSE_PLATE_STATE]: OrNull<ReferenceModel>
  [ADD_VEHICLE_FIELD_NAMES.ADDRESS]: OrNull<AddressOption>
  [ADD_VEHICLE_FIELD_NAMES.EXTERIOR_COLOR]: OrNull<ColorOption> | string
  [ADD_VEHICLE_FIELD_NAMES.INTERIOR_COLOR]: OrNull<ColorOption> | string
  [ADD_VEHICLE_FIELD_NAMES.CURRENT_MILEAGE]: string
  [ADD_VEHICLE_FIELD_NAMES.CONDITION]: OrNull<ReferenceModel<VehicleCondition>>
  [ADD_VEHICLE_FIELD_NAMES.HOW_MUCH_IS_OWED]: string
  [ADD_VEHICLE_FIELD_NAMES.NOTES]: string
  [ADD_VEHICLE_FIELD_NAMES.TRADE_IN_VALUE]: string
  [ADD_VEHICLE_FIELD_NAMES.RETAIL_VALUE]: string
}

export interface AddOldVehicleFormFieldsTypes {
  [ADD_VEHICLE_FIELD_NAMES.STOCK_NUMBER]: OrNull<string>
  [ADD_VEHICLE_FIELD_NAMES.ADDRESS]: OrNull<AddressOption>
  [ADD_VEHICLE_FIELD_NAMES.MAKE]: OrNull<ReferenceModel>
  [ADD_VEHICLE_FIELD_NAMES.MODEL]: OrNull<ReferenceModel>
  [ADD_VEHICLE_FIELD_NAMES.YEAR]: OrNull<ReferenceModel>
  [ADD_VEHICLE_FIELD_NAMES.TRIM]: OrNull<ReferenceModel>
  [ADD_VEHICLE_FIELD_NAMES.EXTERIOR_COLOR]: OrNull<ColorOption> | string
  [ADD_VEHICLE_FIELD_NAMES.INTERIOR_COLOR]: OrNull<ColorOption> | string
  [ADD_VEHICLE_FIELD_NAMES.CURRENT_MILEAGE]: string
  [ADD_VEHICLE_FIELD_NAMES.CONDITION]: OrNull<ReferenceModel>
  [ADD_VEHICLE_FIELD_NAMES.HOW_MUCH_IS_OWED]: string
  [ADD_VEHICLE_FIELD_NAMES.NOTES]: string
  [ADD_VEHICLE_FIELD_NAMES.TRADE_IN_VALUE]: string
  [ADD_VEHICLE_FIELD_NAMES.RETAIL_VALUE]: string
}
