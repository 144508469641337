import { useMemo } from 'react'
import {
  selectBasicInfoScreenData,
  selectFindCarFormYearFromOptions,
  selectSelectedVehiclesData,
  selectTradeInInfoScreenData
} from '../../../../store/units/findCarForm'
import { getOptionsBetween } from '../../../../store/units/findCarForm/utils/getOptionsBetween'
import { NonNullableRecord } from '../../../../types/NonNullableRecord'
import { firstOf, lastOf } from '../../../../utils/array'
import { useAppSelector } from '../../../../utils/hooks'
import type { BuildCarPreviewScreenProps } from '../BuildCarPreviewScreen'

export const useBuildCarPreviewInfo = () => {
  const { selectedVehicles } = useAppSelector(selectSelectedVehiclesData)
  const { tradeinVehicle } = useAppSelector(selectTradeInInfoScreenData)
  const { notes } = useAppSelector(selectBasicInfoScreenData)
  const yearsOptions = useAppSelector(selectFindCarFormYearFromOptions)
  const requestedVehicleInfo = useAppSelector(
    selectBasicInfoScreenData
  ) as Omit<
    NonNullableRecord<ReturnType<typeof selectBasicInfoScreenData>>,
    'trim'
  > & {
    trim: ReturnType<typeof selectBasicInfoScreenData>['trim']
  }

  const preparedRequestedVehicle: BuildCarPreviewScreenProps['requestedVehicle'] & {
    notes: string
    deliverToAddressId: string
  } = useMemo(() => {
    const from = requestedVehicleInfo.yearFrom || firstOf(yearsOptions)
    const to = requestedVehicleInfo.yearTo || lastOf(yearsOptions)

    const years = getOptionsBetween(
      from,
      to,
      yearsOptions,
      (o, v) => +o.id >= +v.id,
      (o, v) => +o.id <= +v.id
    ).map((option) => option.id)

    return {
      make: requestedVehicleInfo.make?.id || '',
      model: requestedVehicleInfo.model?.id || '',
      years,
      trim: requestedVehicleInfo.trim?.id || '',
      exteriorColors: requestedVehicleInfo.exteriorColor,
      interiorColors: requestedVehicleInfo.interiorColor,
      notes: requestedVehicleInfo.notes,
      deliverToAddressId: requestedVehicleInfo.deliverTo?.id || ''
    }
  }, [requestedVehicleInfo])

  return {
    selectedCars: selectedVehicles,
    requestedVehicle: preparedRequestedVehicle,
    tradeinVehicle,
    notes
  }
}
