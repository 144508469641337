import { Divider, Grid, Typography } from '@mui/material'
import { FC, useCallback, useRef, useState } from 'react'
import { ActionBlock } from '../../atoms/ActionBlock'
import { Condition } from '../../atoms/Condition'
import { FixedContainer } from '../../atoms/FixedContainer'
import { InformationLabel } from '../../atoms/InformationLabel'
import { Show } from '../../atoms/JSXExtensions'
import { PaymentRedirect } from '../../features/PaymentRedirect'
import {
  SubscriptionFormRefTargetType,
  SubscriptionMicroform,
  subscriptionOptions
} from '../../features/Subscription'
import { PageContainer } from '../../layout/PageContainer'
import { CommonConfirmationModal } from '../../molecules/Modal/CommonConfirmationModal'
import { OrNull } from '../../types/OrNull'
import { noop } from '../../utils'
import { useSynchronize } from '../../utils/Synchronizer/hooks/useSynchronize'
import {
  useBackPath,
  useModal,
  useNavigate,
  useSearchParams,
  useUserInfo
} from '../../utils/hooks'
import { AUTOUPDATE_QUERY_SUBSCRIPTION_PAGE_MARK } from '../../utils/hooks/subscription/constants'
import { useUpdateSubscriptionService } from '../../utils/hooks/subscription/useUpdateSubscriptionService'
import { CurrentSubscription } from './components'

export const SubscriptionPage: FC = () => {
  const subscriptionRef = useRef<OrNull<SubscriptionFormRefTargetType>>(null)
  const { isAdmin, accountID, subscription } = useUserInfo()
  const navigate = useNavigate()
  const { backPath } = useBackPath()
  const [isMicroformValid, setIsMicroformValid] = useState(false)

  const [params] = useSearchParams()
  const isSubscriptionPage = params.has(AUTOUPDATE_QUERY_SUBSCRIPTION_PAGE_MARK)

  const { updateSubscriptionService, redirectUrl, isLoading } =
    useUpdateSubscriptionService({
      autoUpdate: isSubscriptionPage,
      isSubscriptionPage: true
    })
  const { isSynchronizing } = useSynchronize({
    autoSynchronize: !isSubscriptionPage
  })

  // Callback ref
  // It's needed to synchronize 'isValid' state
  const setSubscriptionMicroform = useCallback(
    (node: SubscriptionFormRefTargetType) => {
      subscriptionRef.current = node
      if (subscriptionRef.current) {
        setIsMicroformValid(subscriptionRef.current.isValid)
      }
    },
    [setIsMicroformValid]
  )

  const handleConfirmDeactivation = useCallback(() => {
    // TODO: Discuss how to get 'free' subscripton option name
    // In the future the options should be got from our BE so we have to hardcode them here
    updateSubscriptionService({ subscriptionType: 'Free', accId: accountID })
  }, [accountID])

  const handleConfirmActivation = useCallback(() => {
    const { current } = subscriptionRef
    if (current?.values) {
      const { subscription: subscriptionValue } = current.values
      updateSubscriptionService({
        subscriptionType: subscriptionValue,
        accId: accountID
      })
    }
  }, [updateSubscriptionService, accountID])

  const deactivateConfirmationModal = useModal(handleConfirmDeactivation)
  const activatePlanModal = useModal(handleConfirmActivation)

  const handleNotNowClick = useCallback(() => {
    navigate(backPath)
  }, [navigate, backPath])

  return (
    <PageContainer isLoading={isLoading || isSynchronizing}>
      <Show
        when={!redirectUrl}
        fallback={
          <PaymentRedirect
            open={!!redirectUrl}
            onRedirectFail={noop}
            getPaymentRedirectUrl={redirectUrl}
          />
        }
      >
        <Grid container flexDirection='column' pb='40px'>
          <Grid item mb='24px'>
            <Typography variant='h6'>Your current subscription</Typography>
          </Grid>
          <Grid item mb='80px'>
            <CurrentSubscription
              subscription={subscription}
              disabled={subscription.name !== 'Free' && !isAdmin}
              isDefault={subscription.name === 'Free'}
              onDeactivate={deactivateConfirmationModal.onOpen}
            />
          </Grid>
          <Grid item>
            <Typography variant='h6'>Subscriptions</Typography>
          </Grid>
          <Grid item mb='24px'>
            <Typography variant='details'>
              Please note new plan will accrue immediately after activation.
              Subscription runs for calendar month.
            </Typography>
          </Grid>
          <Grid item mb='24px'>
            <SubscriptionMicroform
              options={subscriptionOptions}
              formRef={setSubscriptionMicroform}
              initialValues={{ subscription: subscription.name }}
            />
          </Grid>
          <Condition
            condition={!isAdmin}
            trueContent={
              <Grid item>
                <InformationLabel
                  labelText='Only admin can upgrade the subscription. Please contact your admin to change the plan.'
                />
              </Grid>
            }
            falseContent={
              <Grid item mb='60px'>
                <Typography variant='details'>
                  By confirming your subscription, you allow CarSnoop to charge
                  your payment method for this payment and future payments in
                  accordance with the terms. You can always cancel your
                  subscription.
                </Typography>
              </Grid>
            }
          />
          <Show when={isAdmin}>
            <FixedContainer
              flexDirection='row-reverse'
              bottom
              p='0px 24px 24px'
            >
              <Grid item container justifySelf='flex-end'>
                <Grid item xs={12} mb='24px' pt='0px'>
                  <Divider />
                </Grid>
                <Grid item width='100%'>
                  <ActionBlock
                    pushToBottom
                    schema={[
                      {
                        label: 'cancel',
                        type: 'secondary',
                        onClick: handleNotNowClick
                      },
                      {
                        label: 'activate',
                        type: 'primary',
                        // TODO: Add disable logic after connecting the API (if chosen currently active plan should be disabled)
                        disabled: isLoading || !isMicroformValid,
                        onClick: activatePlanModal.onOpen
                      }
                    ]}
                  />
                </Grid>
              </Grid>
            </FixedContainer>
          </Show>
        </Grid>
      </Show>
      <CommonConfirmationModal
        header='Confirmation'
        message='Are you sure you would like to deactivate your current plan? '
        {...deactivateConfirmationModal}
      />
      <CommonConfirmationModal
        header='Confirmation'
        message='Are you sure you would like to activate the new plan?'
        {...activatePlanModal}
      />
    </PageContainer>
  )
}
