import { useState } from 'react'
import { Switch } from '../../../../atoms/Switch'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const SwitchExample = () => {
  const [checked, setChecked] = useState(false)
  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <Switch checked={checked} onChange={setChecked} />
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
