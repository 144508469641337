import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  SearchInventoryResponseItem
} from '../../../api/carSnoopApi/vehicles'
import { ColorOption, Option } from '../../../types/Autoselect'
import {
  FIND_CAR_FIELD_NAMES,
  FIND_CAR_STEPS
} from '../../../types/enums/findCar'
import { BasicInfo } from '../../../types/forms/findCar'
import { sortArrPropAlphabetically } from '../../../utils/sortArrPropAlphabetically'
import { logout } from '../../thunks/logout'
import { pageNavigation } from '../../thunks/pageNavigation'
import { defaultColorOption, defaultTrimOption } from './constants'
import {
  readCarByYear,
  readCarModelYearTrimFromVehicles,
  readFindCarFormTradeIn,
  readMakers,
  readModels,
  readVehicleTrimByYear,
  readYears
} from './thunks'
import type { DisplayedTradeinValues, FindCarFormFormState, TradeInInfo } from './types'
import {
  carsByYearResponseToExteriorColorsOption,
  carsByYearResponseToInteriorColorsOption,
  carsByYearResponseToTrimOption,
  colorToOption,
  makersResponseToOption,
  modelsResponseToOption,
  yearsResponseToOption
} from './utils'
import { updateTradeinValues } from './utils/updateTradeInValues'

export const initialState: FindCarFormFormState = {
  length: 5,
  step: FIND_CAR_STEPS.stepOne,
  options: {
    [FIND_CAR_FIELD_NAMES.MAKE]: [],
    [FIND_CAR_FIELD_NAMES.MODEL]: [],
    [FIND_CAR_FIELD_NAMES.YEAR_FROM]: [],
    [FIND_CAR_FIELD_NAMES.YEAR_TO]: [],
    [FIND_CAR_FIELD_NAMES.TRIM]: [defaultTrimOption],
    [FIND_CAR_FIELD_NAMES.EXTERIOR_COLOR]: [defaultColorOption],
    [FIND_CAR_FIELD_NAMES.INTERIOR_COLOR]: [defaultColorOption],
    [FIND_CAR_FIELD_NAMES.DELIVER_TO]: [],
    [FIND_CAR_FIELD_NAMES.NOTES]: ''
  },
  [FIND_CAR_STEPS.stepOne]: {
    [FIND_CAR_FIELD_NAMES.MAKE]: null,
    [FIND_CAR_FIELD_NAMES.MODEL]: null,
    [FIND_CAR_FIELD_NAMES.YEAR_FROM]: null,
    [FIND_CAR_FIELD_NAMES.YEAR_TO]: null,
    [FIND_CAR_FIELD_NAMES.TRIM]: null,
    [FIND_CAR_FIELD_NAMES.EXTERIOR_COLOR]: [],
    [FIND_CAR_FIELD_NAMES.INTERIOR_COLOR]: [],
    [FIND_CAR_FIELD_NAMES.DELIVER_TO]: null,
    [FIND_CAR_FIELD_NAMES.NOTES]: ''
  },
  [FIND_CAR_STEPS.stepTwo]: {
    vehiclesList: [],
    selectedVehicles: []
  },
  [FIND_CAR_STEPS.stepThree]: {
    [FIND_CAR_FIELD_NAMES.TRADE_IN_VEHICLE]: null,
    [FIND_CAR_FIELD_NAMES.SHOW_TRADE_IN]: true
  },
  standardEquipment: null,
  isReadCarModelYearTrimLoading: false
}

export const findCarFormSlice = createSlice({
  name: 'findCarForm',
  initialState,
  reducers: {
    incrementScreenStep: (state) => {
      if (state.step + 1 < state.length) {
        state.step += 1
      }
    },
    decrementScreenStep: (state) => {
      if (state.step - 1 >= 0) {
        state.step -= 1
      }
    },
    setBasicInfo: (store, action: PayloadAction<BasicInfo>) => {
      store[FIND_CAR_STEPS.stepOne] = action.payload
    },
    setSelectedVehicles: (
      store,
      action: PayloadAction<SearchInventoryResponseItem[]>
    ) => {
      store[FIND_CAR_STEPS.stepTwo].selectedVehicles = action.payload
    },
    setVehiclesList: (
      store,
      action: PayloadAction<SearchInventoryResponseItem[]>
    ) => {
      store[FIND_CAR_STEPS.stepTwo].vehiclesList = action.payload
    },
    modifyVehiclesListShippingCost: (
      store,
      action: PayloadAction<{ id: string; cost: number }>
    ) => {
      const {
        payload: { id, cost }
      } = action

      store[FIND_CAR_STEPS.stepTwo].vehiclesList = store[
        FIND_CAR_STEPS.stepTwo
      ].vehiclesList.map((vehicle) => {
        return vehicle.id === id ? { ...vehicle, shippingCost: cost } : vehicle
      })
    },
    modifySelectedVehiclesShippingCost: (
      store,
      action: PayloadAction<{ id: string; cost: number }>
    ) => {
      const {
        payload: { id, cost }
      } = action

      store[FIND_CAR_STEPS.stepTwo].selectedVehicles = store[
        FIND_CAR_STEPS.stepTwo
      ].selectedVehicles.map((vehicle) => {
        return vehicle.id === id ? { ...vehicle, shippingCost: cost } : vehicle
      })
    },
    setTradeInInfo: (store, action: PayloadAction<TradeInInfo>) => {
      store[FIND_CAR_STEPS.stepThree] = action.payload
    },
    updateDisplayedTradeInValues: (
      store,
      action: PayloadAction<DisplayedTradeinValues>
    ) => {
      store[FIND_CAR_STEPS.stepThree][FIND_CAR_FIELD_NAMES.TRADE_IN_VEHICLE] =
        updateTradeinValues(
          store[FIND_CAR_STEPS.stepThree][
          FIND_CAR_FIELD_NAMES.TRADE_IN_VEHICLE
          ],
          action.payload
        )
    },
    deleteTradein: (store) => {
      store[FIND_CAR_STEPS.stepThree][FIND_CAR_FIELD_NAMES.TRADE_IN_VEHICLE] =
        null
    },
    setFindCarFormState: (
      store,
      action: PayloadAction<FindCarFormFormState>
    ) => {
      return { ...action.payload }
    },
    setScreenStep: (store, action: PayloadAction<FIND_CAR_STEPS>) => {
      store.step = action.payload
    },
    resetState: () => {
      return { ...initialState }
    }
  },

  extraReducers: (builder) => {
    // ReadFindCarFormTradeIn
    builder
      .addCase(readFindCarFormTradeIn.fulfilled, (_store, action) => {
        return { ...action.payload.data }
      })

    // Makers
    builder
      .addCase(readMakers.pending, (store) => {
        store.options[FIND_CAR_FIELD_NAMES.MAKE] = []
      })
      .addCase(readMakers.fulfilled, (store, action) => {
        store.options[FIND_CAR_FIELD_NAMES.MAKE] = makersResponseToOption(
          action.payload
        )
      })

    // Models
    builder
      .addCase(readModels.pending, (store) => {
        store.options[FIND_CAR_FIELD_NAMES.MODEL] = []
      })
      .addCase(readModels.fulfilled, (store, action) => {
        store.options[FIND_CAR_FIELD_NAMES.MODEL] = modelsResponseToOption(
          action.payload
        )
      })

    // Years
    builder
      .addCase(readYears.pending, (store) => {
        const options: Option[] = []

        store.options[FIND_CAR_FIELD_NAMES.YEAR_FROM] = options
        store.options[FIND_CAR_FIELD_NAMES.YEAR_TO] = options
      })
      .addCase(readYears.fulfilled, (store, action) => {
        const options = yearsResponseToOption(action.payload)

        store.options[FIND_CAR_FIELD_NAMES.YEAR_FROM] = options
        store.options[FIND_CAR_FIELD_NAMES.YEAR_TO] = options
      })

    // Trims, Colors
    builder
      .addCase(readCarByYear.pending, (store) => {
        const trimOptions: Option[] = []
        const interiorColorsOption: ColorOption[] = []
        const exteriorColorsOption: ColorOption[] = []

        store.options[FIND_CAR_FIELD_NAMES.TRIM] = trimOptions
        store.options[FIND_CAR_FIELD_NAMES.INTERIOR_COLOR] =
          interiorColorsOption
        store.options[FIND_CAR_FIELD_NAMES.EXTERIOR_COLOR] =
          exteriorColorsOption
      })
      .addCase(readCarByYear.fulfilled, (store, action) => {
        const trimOptions = sortArrPropAlphabetically(
          carsByYearResponseToTrimOption(action.payload),
          'name'
        )

        const interiorColorsOption = carsByYearResponseToInteriorColorsOption(
          action.payload
        )
        const exteriorColorsOption = carsByYearResponseToExteriorColorsOption(
          action.payload
        )

        store.options[FIND_CAR_FIELD_NAMES.TRIM] = [
          defaultTrimOption,
          ...trimOptions
        ]
        store.options[FIND_CAR_FIELD_NAMES.INTERIOR_COLOR] = [
          defaultColorOption,
          ...interiorColorsOption
        ]
        store.options[FIND_CAR_FIELD_NAMES.EXTERIOR_COLOR] = [
          defaultColorOption,
          ...exteriorColorsOption
        ]
      })
      .addCase(readCarByYear.rejected, (_store, action) => {
        // eslint-disable-next-line
        console.log('>>>', action)
      })

    // Trims
    builder
      .addCase(readVehicleTrimByYear.pending, (store) => {
        const trimOptions: Option[] = []

        store.options[FIND_CAR_FIELD_NAMES.TRIM] = trimOptions
      })
      .addCase(readVehicleTrimByYear.fulfilled, (store, action) => {
        const trimOptions = carsByYearResponseToTrimOption(action.payload)

        store.options[FIND_CAR_FIELD_NAMES.TRIM] = trimOptions.length
          ? trimOptions
          : [defaultTrimOption]
      })
      .addCase(readVehicleTrimByYear.rejected, (_store, action) => {
        // eslint-disable-next-line
        console.log('>>>', action)
      })

    // StandardEquipment
    builder
      .addCase(readCarModelYearTrimFromVehicles.pending, (store) => {
        const standardEquipment = null
        store.standardEquipment = standardEquipment
        store.isReadCarModelYearTrimLoading = true
      })
      .addCase(readCarModelYearTrimFromVehicles.fulfilled, (store, action) => {
        store.standardEquipment = action.payload.data.standardEquipment
        store.options[FIND_CAR_FIELD_NAMES.INTERIOR_COLOR] =
          action.payload.data.interiorColors.map(colorToOption)
        store.options[FIND_CAR_FIELD_NAMES.EXTERIOR_COLOR] =
          action.payload.data.exteriorColors.map(colorToOption)
        store.isReadCarModelYearTrimLoading = false
      })
      .addCase(readCarModelYearTrimFromVehicles.rejected, (store) => {
        store.standardEquipment = null
        store.options[FIND_CAR_FIELD_NAMES.INTERIOR_COLOR] = []
        store.options[FIND_CAR_FIELD_NAMES.EXTERIOR_COLOR] = []
        store.isReadCarModelYearTrimLoading = false
      })
    builder.addCase(logout.fulfilled, () => {
      return {
        ...initialState
      }
    })
    builder.addCase(pageNavigation.fulfilled, () => {
      return initialState
    })
  }
})
