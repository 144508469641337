import { AppleIcon } from '../../../../assets/icons/AppleIcon'
import { AttentionTriangleIcon } from '../../../../assets/icons/AttentionTriangleIcon'
import { AudioIcon } from '../../../../assets/icons/AudioIcon'
import { BackArrow } from '../../../../assets/icons/BackArrow'
import { CameraIcon } from '../../../../assets/icons/Camera'
import { CarIcon } from '../../../../assets/icons/CarIcon'
import { CashIcon } from '../../../../assets/icons/CashIcon'
import { ChatSendIcon } from '../../../../assets/icons/ChatSend'
import { ChatsIcon } from '../../../../assets/icons/ChatsIcon'
import { Checkbox } from '../../../../assets/icons/Checkbox'
import { CheckIcon } from '../../../../assets/icons/CheckIcon'
import { CircleArrowIcon } from '../../../../assets/icons/CircleArrow'
import { CloseIcon } from '../../../../assets/icons/Close'
import { ContractCompleted } from '../../../../assets/icons/ContractCompleted'
import { DeleteIcon } from '../../../../assets/icons/Delete'
import { Document, FileDocument } from '../../../../assets/icons/Document'
import { EditIcon } from '../../../../assets/icons/Edit'
import { EmptyImageIcon } from '../../../../assets/icons/EmptyImage'
import { EnvelopeIcon } from '../../../../assets/icons/EnvelopeIcon'
import { ExclamationSpeechBubble } from '../../../../assets/icons/ExclamationSpeechBubble'
import { FinanceIcon } from '../../../../assets/icons/FinanceIcon'
import { GearIcon } from '../../../../assets/icons/GearIcon'
import { GoogleIcon } from '../../../../assets/icons/GoogleIcon'
import { InformationIcon } from '../../../../assets/icons/Information'
import { InputError } from '../../../../assets/icons/InputError'
import { LeaseIcon } from '../../../../assets/icons/LeaseIcon'
import { LinkedInIcon } from '../../../../assets/icons/LinkedInIcon'
import { LocationIcon } from '../../../../assets/icons/LocationIcon'
import LockIcon from '../../../../assets/icons/LockIcon'
import { LogOutIcon } from '../../../../assets/icons/LogOutIcon'
import { MenuArrowIcon } from '../../../../assets/icons/MenuArrowIcon'
import { NoVehicleIcon } from '../../../../assets/icons/NoVehicle'
import { OfficeIcon } from '../../../../assets/icons/OfficeIcon'
import { PaperClipIcon } from '../../../../assets/icons/PaperClip'
import { PenIcon } from '../../../../assets/icons/Pen'
import { PhoneIcon } from '../../../../assets/icons/Phone'
import { PhotoIcon } from '../../../../assets/icons/Photo'
import { PlayIcon } from '../../../../assets/icons/PlayIcon'
import { RetryIcon } from '../../../../assets/icons/RetryIcon'
import { ShippingVehicleIcon } from '../../../../assets/icons/ShippingVehicle'
import { SmallPlayIcon } from '../../../../assets/icons/SmallPlayIcon'
import { SomethingWentWrong } from '../../../../assets/icons/SomethingWentWrong'
import { SpeedometerIcon } from '../../../../assets/icons/Speedometer'
import { StripeIcon } from '../../../../assets/icons/StripeIcon'
import { SubscriptionsIcon } from '../../../../assets/icons/SubscriptionsIcon'
import SuccessIcon from '../../../../assets/icons/SuccessIcon'
import { SuccessIconCircle } from '../../../../assets/icons/SuccessIconCircle'
import { SupportIcon } from '../../../../assets/icons/SupportIcon'
import { TwoDudes } from '../../../../assets/icons/TwoDudes'
import { UnknownUserIcon } from '../../../../assets/icons/UnknownUser'
import { UploadIcon } from '../../../../assets/icons/Upload'
import { UploadCloudIcon } from '../../../../assets/icons/UploadCloud'
import { UploadDocumentIcon } from '../../../../assets/icons/UploadDocumentIcon'
import { UserSettingsIcon } from '../../../../assets/icons/UserSettings'
import { DrivetrainIcon } from '../../../../assets/icons/vehicleOverviewIcons/DrivetrainIcon'
import { EngineIcon } from '../../../../assets/icons/vehicleOverviewIcons/EngineIcon'
import { ExteriorIcon } from '../../../../assets/icons/vehicleOverviewIcons/ExteriorIcon'
import { FuelIcon } from '../../../../assets/icons/vehicleOverviewIcons/FuelIcon'
import { InteriorIcon } from '../../../../assets/icons/vehicleOverviewIcons/InteriorIcon'
import { MPGIcon } from '../../../../assets/icons/vehicleOverviewIcons/MPGIcon'
import { StyleIcon } from '../../../../assets/icons/vehicleOverviewIcons/StyleIcon'
import { TransmissionIcon } from '../../../../assets/icons/vehicleOverviewIcons/TransmissionIcon'
import { AttentionIcon } from '../../../../atoms/AttentionIcon'

export const ICONS_MAP: Record<string, JSX.Element> = {
  AppleIcon: <AppleIcon />,
  AttentionIcon: <AttentionIcon text='Icon title' />,
  AttentionTriangleIcon: <AttentionTriangleIcon />,
  AudioIcon: <AudioIcon />,
  BackArrow: <BackArrow />,
  CameraIcon: <CameraIcon />,
  CarIcon: <CarIcon />,
  CashIcon: <CashIcon />,
  ChatSendIcon: <ChatSendIcon />,
  ChatsIcon: <ChatsIcon />,
  CheckboxIcon: <Checkbox />,
  CheckIcon: <CheckIcon />,
  CircleArrow: <CircleArrowIcon />,
  CloseIcon: <CloseIcon />,
  DeleteIcon: <DeleteIcon />,
  Document: <Document />,
  FileDocument: <FileDocument />,
  EditIcon: <EditIcon />,
  EmailIcon: <CheckIcon />,
  EmptyImageIcon: <EmptyImageIcon />,
  ExclamationSpeechBubble: <ExclamationSpeechBubble />,
  FinanceIcon: <FinanceIcon />,
  GearIcon: <GearIcon />,
  GoogleIcon: <GoogleIcon />,
  InformationIcon: <InformationIcon />,
  InputError: <InputError />,
  LeaseIcon: <LeaseIcon />,
  LinkedInIcon: <LinkedInIcon />,
  LocationIcon: <LocationIcon />,
  LockIcon: <LockIcon />,
  LogOutIcon: <LogOutIcon />,
  MenuArrowIcon: <MenuArrowIcon />,
  NoVehicle: <NoVehicleIcon />,
  OfficeIcon: <OfficeIcon />,
  PaperClipIcon: <PaperClipIcon />,
  PenIcon: <PenIcon />,
  PhoneIcon: <PhoneIcon />,
  PhotoIcon: <PhotoIcon />,
  PlayIcon: <PlayIcon />,
  RetryIcon: <RetryIcon />,
  ShippingVehicleIcon: <ShippingVehicleIcon />,
  SmallPlayIcon: <SmallPlayIcon />,
  SomethingWentWrong: <SomethingWentWrong />,
  SpeedometerIcon: <SpeedometerIcon />,
  StripeIcon: <StripeIcon />,
  SubscriptionsIcon: <SubscriptionsIcon />,
  SuccessIcon: <SuccessIcon />,
  SuccessIconCircle: <SuccessIconCircle />,
  SupportIcon: <SupportIcon />,
  UploadIcon: <UploadIcon />,
  UploadCloudIcon: <UploadCloudIcon />,
  UploadDocumentIcon: <UploadDocumentIcon />,
  UserSettingsIcon: <UserSettingsIcon />,
  DrivetrainIcon: <DrivetrainIcon />,
  NoVehicleIcon: <NoVehicleIcon />,
  EngineIcon: <EngineIcon />,
  ExteriorIcon: <ExteriorIcon />,
  FuelIcon: <FuelIcon />,
  InteriorIcon: <InteriorIcon />,
  MPGIcon: <MPGIcon />,
  StyleIcon: <StyleIcon />,
  TransmissionIcon: <TransmissionIcon />,
  EnvelopeIcon: <EnvelopeIcon />,
  ContractCompleted: <ContractCompleted />,
  TwoDudes: <TwoDudes />,
  UnknownUserIcon: <UnknownUserIcon />
}
