export enum REGISTRATION_BY_EMAIL_FIELD_NAMES {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  BIRTH_DATE = 'birthDate',
  PHONE = 'phone',
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'confirmPassword'
}

export enum REGISTRATION_BY_EMAIL_FIELD_LABELS {
  FIRST_NAME = 'First Name',
  LAST_NAME = 'Last Name',
  BIRTH_DATE = 'Date of Birth',
  PHONE = 'Phone number',
  PASSWORD = 'Password',
  CONFIRM_PASSWORD = 'Confirm Password'
}

export enum REGISTRATION_BY_EMAIL_PLACEHOLDERS {
  FIRST_NAME = 'First Name',
  LAST_NAME = 'Last Name',
  BIRTH_DATE = 'Date of Birth',
  PHONE = '(XXX) XXX - XXXX',
  PASSWORD = 'Password',
  CONFIRM_PASSWORD = 'Confirm Password'
}
