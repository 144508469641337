import { Typography } from '@mui/material'

import { DetailsItemProps } from '../DetailsItem'

export const RenderNotAvaliableValue: NonNullable<
  DetailsItemProps['renderValue']
> = () => {
  return (
    <Typography variant='label1' fontStyle='italic'>
      Not Available
    </Typography>
  )
}
