import { Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { NoVehiclesImage } from '../../assets/imgs/NoVehicles'
import { AgreeButton } from '../../atoms/Button'
import { routes } from '../../routes'
interface EmptyStateProps {
  action?: () => void | Promise<void>
}

export const EmptyState: FC<EmptyStateProps> = ({ action }) => {
  return (
    <Grid
      container
      justifyContent='center'
      alignContent='center'
      flexDirection='column'
      spacing={2}
    >
      <Grid item textAlign='center'>
        <NoVehiclesImage />
      </Grid>
      <Grid item>
        <Typography variant='h6' sx={{ textAlign: 'center' }}>
          There are no vehicles yet.
        </Typography>
      </Grid>
      <Grid item container justifyContent='center'>
        <NavLink
          to={routes.vehicles.add}
          style={{
            textDecoration: 'none'
          }}
        >
          <AgreeButton sx={{ width: '288px' }} onClick={action}>
            Add Vehicle
          </AgreeButton>
        </NavLink>
      </Grid>
    </Grid>
  )
}
