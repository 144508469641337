import { FC } from 'react'
import { useModal } from '../../../../../../utils/hooks'
import { DetailsContainer } from '../../components/DetailsContainer'
import {
  NormalizedContractType,
  NormalizedTradeInType,
  NormalizedTransactionType,
  NormalizedVehicleType
} from '../../../../../../api/carSnoopApi/transactions/types'
import { OrNull } from '../../../../../../types/OrNull'
import { CommonConfirmationModal } from '../../../../../../molecules/Modal/CommonConfirmationModal'
import { completeDealHint, completeDealMessage } from './constants'
import { CompleteShipmentReturnType } from '../../../../../../api/carSnoopApi/shipments/types'
import { Condition } from '../../../../../../atoms/Condition'
import { NonTrackableView } from '../../components/Shipment/NonTrackableView'
import { TrackableView } from '../../components/Shipment/TrackableView'
import { isDeliveryLocal } from '../../utils'
import { NON_TRACKABLE_PROPS_BUYER } from '../constants'

interface IBuyerStripeShipment {
  vehicle: NormalizedVehicleType
  contract: NormalizedContractType
  tradeIn: OrNull<NormalizedTradeInType>
  trackingNumber: NormalizedTransactionType['trackingNumber']
  transactionId: string
  completeDeal: () => CompleteShipmentReturnType
}

export const BuyerStripeShipment: FC<IBuyerStripeShipment> = ({
  vehicle,
  tradeIn,
  contract,
  trackingNumber,
  transactionId,
  completeDeal
}) => {
  const completeDealModal = useModal(completeDeal)

  const deliveryIsLocal = isDeliveryLocal(contract)

  const shipmentCreationError =
    //  Delivery by service was selected
    !deliveryIsLocal &&
    // but shipment wasn't created
    !trackingNumber

  return (
    <DetailsContainer title='Shipment'>
      <Condition
        condition={deliveryIsLocal}
        trueContent={
          <NonTrackableView
            completeDeal={completeDeal}
            {...NON_TRACKABLE_PROPS_BUYER}
          />
        }
        falseContent={
          <TrackableView
            tradeIn={tradeIn}
            contract={contract}
            trackingNumber={trackingNumber}
            vehicle={vehicle}
            transactionId={transactionId}
            completeDeal={completeDeal}
            shipmentCreationError={shipmentCreationError}
          />
        }
      />
      <CommonConfirmationModal
        {...completeDealModal}
        message={completeDealMessage}
        hint={completeDealHint}
      />
    </DetailsContainer>
  )
}
