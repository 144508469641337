import { Button, Grid } from '@mui/material'
import { FC } from 'react'
import { AudioPlayer } from '../../../../atoms/AudioPlayer/AudioPlayer'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

const TEST_AUDIO_LINK = 'https://mp3gid.me/download.php?id=69169'

export const AudioPlayerExample: FC = () => {
  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <Grid container>
          <Grid item>
            <AudioPlayer link={TEST_AUDIO_LINK}>
              <Button>Play audio</Button>
            </AudioPlayer>
          </Grid>
        </Grid>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
