import { FC, useMemo, MouseEventHandler, useCallback } from 'react'
import { Button, Grid, SxProps } from '@mui/material'
import { PenIcon } from '../../../../../assets/icons/Pen'
import { RedButton } from '../../../../../atoms/Button'
import { DeleteIcon } from '../../../../../assets/icons/Delete'
import { UploadNewDocumentModal } from '../../../../../features/UploadNewDocument'
import { UploadDocumentFormType } from '../../../../../features/UploadNewDocument/types'
import {
  useModal,
  useMakeService,
  useApiContext,
  useAppSelector,
  useDeviceInfo
} from '../../../../../utils/hooks'
import { OrNull } from '../../../../../types/OrNull'
import { DeleteConfirmationModal } from '../../../../../molecules/Modal'
import { ResponseStatusMap } from '../../../../../types/status'
import { selectContractPageModel } from '../../../../../store/pages/Contract'
import { useContractPageInfo } from '../../../hooks/useContractPageInfo'
import { StopPropagationContainer } from '../../../../../atoms/StopPropagationContainer'

interface InteractiveActionsProps {
  name: string
  id: OrNull<string>
  type: OrNull<string>
  notes: OrNull<string>
  createdOn: OrNull<Date>
}

export const InteractiveActions: FC<InteractiveActionsProps> = ({
  name,
  id,
  type,
  createdOn,
  notes
}) => {
  const { carSnoopApi } = useApiContext()
  const updateDocumentModal = useModal()
  const deleteDocumentModal = useModal()
  const { transaction } = useAppSelector(selectContractPageModel)
  const { readTransaction, transactionID } = useContractPageInfo()
  const { isTablet } = useDeviceInfo()
  const onSuccessDocumentChange = () => {
    readTransaction({ id: transactionID, openContractInformation: false })
  }

  const [deleteDocument] = useMakeService(
    async (documentId: string) => {
      const res = await carSnoopApi.documents.deleteContractDocument(documentId)
      if (res.status === ResponseStatusMap.Success) {
        onSuccessDocumentChange()
      }

      return res
    },
    { withStatusNotification: true }
  )

  const [updateDocument, updateDocumentObservable] = useMakeService(
    async (document: UploadDocumentFormType) => {
      const deleteResponse = await carSnoopApi.documents.deleteContractDocument(
        id as string
      )
      if (deleteResponse.status === ResponseStatusMap.Success) {
        const response = await carSnoopApi.documents.createContractDocument({
          contractId: transaction?.contract?.id || '',
          type: document.file?.type || '',
          name,
          content: document.file?.content || '',
          notes: document?.notes
        })

        if (response.status === ResponseStatusMap.Success) {
          onSuccessDocumentChange()
          return response
        }
      }

      return { status: ResponseStatusMap.Error }
    },
    { withStatusNotification: true }
  )

  const [updateDocumentNotes, updateDocumentNotesObservable] = useMakeService(
    async (documentNotes: string) => {
      const response = await carSnoopApi.documents.updateDocumentNotes({
        id: id || '',
        notes: documentNotes
      })

      if (response.status === ResponseStatusMap.Success) {
        onSuccessDocumentChange()
        return response
      }

      return { status: ResponseStatusMap.Error }
    },
    { withStatusNotification: true }
  )

  const submitUpdateDocument = useCallback(
    async (data: UploadDocumentFormType) => {
      if (data.file?.content !== id) {
        updateDocument(data)
      } else {
        updateDocumentNotes(data.notes)
      }
    },
    [updateDocument, updateDocumentNotes]
  )

  const handleDeleteClick: MouseEventHandler = useCallback(
    (event) => {
      event.stopPropagation()
      deleteDocument(id || '')
    },
    [deleteDocument, id]
  )
  const buttonStyles: SxProps = useMemo(
    () => (isTablet ? { minWidth: 'auto' } : {}),
    [isTablet]
  )

  return (
    <>
      <UploadNewDocumentModal
        id={id || ''}
        type={type || ''}
        createdOn={createdOn}
        notes={notes}
        {...updateDocumentModal}
        isLoading={
          updateDocumentObservable.isLoading ||
          updateDocumentNotesObservable.isLoading
        }
        name={name}
        onSubmit={submitUpdateDocument}
      />
      <StopPropagationContainer>
        <DeleteConfirmationModal
          {...deleteDocumentModal}
          onSubmit={handleDeleteClick}
          hint='Deleted document cannot be recovered.'
          message='Are you sure you would like to delete document?'
        />
      </StopPropagationContainer>
      <Grid container wrap='nowrap'>
        <Grid item>
          <Button sx={buttonStyles} onClick={updateDocumentModal.onOpen}>
            <PenIcon />
          </Button>
        </Grid>
        <Grid item>
          <RedButton sx={buttonStyles} onClick={deleteDocumentModal.onOpen}>
            <DeleteIcon />
          </RedButton>
        </Grid>
      </Grid>
    </>
  )
}
