import { FC, useMemo } from 'react'
import { CardMedia, Grid } from '@mui/material'
import { PlayIcon } from '../../../../../../assets/icons/PlayIcon'
import { VideoPlayer } from '../../../../../../atoms/VideoPlayer'
import { Show } from '../../../../../../atoms/JSXExtensions'
import { Loader } from '../../../../../../atoms/Loader'
import { noop } from '../../../../../../utils'

interface VideoMessageProps {
  contentPreviewURL?: string
}

export const VideoMessage: FC<VideoMessageProps> = ({ contentPreviewURL }) => {
  // Added to avoid change poster on readChatMessage
  const posterLink = useMemo(() => `${contentPreviewURL}#t=0.01`, [])

  return (
    <Show
      when={contentPreviewURL}
      fallback={
        <Grid container justifyContent='center'>
          <Loader small />
        </Grid>
      }
    >
      {(link) => (
        <VideoPlayer src={link}>
          <Grid
            item
            sx={{ position: 'relative', cursor: 'pointer' }}
            onClick={noop}
          >
            <CardMedia
              sx={{
                opacity: 0.74,
                height: 200,
                width: 340
              }}
              preload='metadata'
              component='video'
              src={posterLink}
            >
              Your browser does not support the video tag.
            </CardMedia>
            <PlayIcon
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 2
              }}
            />
          </Grid>
        </VideoPlayer>
      )}
    </Show>
  )
}
