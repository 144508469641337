import { Step } from '../../../../../../atoms/FindCarFormStepper'
import {
  ShipmentTypes,
  INTERNAL_SHIPMENT_TYPE
} from '../../../../../../constants/applicationConstants'
import { ReferenceModel } from '../../../../../../types/ReferenceModel'

export const shippingOptions: ReferenceModel<ShipmentTypes, ShipmentTypes>[] = [
  {
    id: ShipmentTypes.local,
    name: ShipmentTypes.local,
    additionalInfo:
      'Pick up the vehicle on your own, or select this option if delivery is already included in the Offer.'
  },
  {
    id: ShipmentTypes.shipping,
    name: ShipmentTypes.shipping,
    additionalInfo: 'Vehicle will be delivered with paid transportation service'
  }
]

export const shipmentSteps: Step[] = [
  { label: 'Shipping' },
  { label: 'Final Payment' }
]

export const shipmentTypesMap = {
  [INTERNAL_SHIPMENT_TYPE.local]: ShipmentTypes.local,
  [INTERNAL_SHIPMENT_TYPE.shipping]: ShipmentTypes.shipping
}
