import { FC, Suspense, lazy } from 'react'

const LazyIcon = lazy(() => import('./Icon'))

export const OnboardingStepThreeImg: FC = () => {
  return (
    <Suspense>
      <LazyIcon />
    </Suspense>
  )
}
