import { FC, useState } from 'react'

import { Counter } from '../../../../atoms/Counter'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const CounterExample: FC = () => {
  const [counter, setCounter] = useState(0)

  const handleAddClick = () => setCounter(counter + 1)
  const handleSubClick = () => setCounter(counter - 1)

  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <Counter
          name='testCounter'
          countValue={counter}
          onAddClick={handleAddClick}
          onSubClick={handleSubClick}
        />
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
