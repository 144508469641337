import { Grid, Typography } from '@mui/material'
import { FC, useMemo } from 'react'
import { NormalizedReadInventoryItem } from '../../api/carSnoopApi/accounts/types'
import { NormalizedTradeInType } from '../../api/carSnoopApi/transactions/types'
import { NoVehicleIcon } from '../../assets/icons/NoVehicle'
import { Show } from '../../atoms/JSXExtensions'
import { TradeInVehicleDetails } from '../../atoms/TradeInVehicleDetails'
import { VehicleComponent } from '../../atoms/VehicleComponent'
import { OrNull } from '../../types/OrNull'
import { useUserInfo } from '../../utils/hooks'
import { returnNoCachedSrc } from '../../utils/returnNoCachedSrc'
import { isTradeInTypeItem } from './utils'

export type TradeInDetailsProps = {
  navigateToNewTab?: boolean
  noTradeinText?: string
  tradeinVehicle?: OrNull<NormalizedTradeInType | NormalizedReadInventoryItem>
}

const NoTradeIn = ({
  noTradeinText
}: Required<Pick<TradeInDetailsProps, 'noTradeinText'>>) => (
  <Grid item container flexDirection='column'>
    <Grid container item justifyContent='center'>
      <NoVehicleIcon />
    </Grid>
    <Grid container item justifyContent='center'>
      <Typography variant='body1'>{noTradeinText}</Typography>
    </Grid>
  </Grid>
)

export const DisplayTradeInDetails: FC<TradeInDetailsProps> = ({
  navigateToNewTab = false,
  noTradeinText = 'There is no Trade In for this Request',
  tradeinVehicle,
}) => {
  const { accountID } = useUserInfo()

  const isOwnCar = useMemo(() => {
    return tradeinVehicle && isTradeInTypeItem(tradeinVehicle)
      ? tradeinVehicle?.accountID === accountID
      : true
  }, [tradeinVehicle, accountID])

  const noCachedImageSrc = useMemo(
    () => returnNoCachedSrc(tradeinVehicle?.documentContent),
    [tradeinVehicle?.documentContent]
  )

  return (
    <Grid container item spacing={3} flexDirection='column'>
      <Grid item>
        <Typography variant='label1'>Trade In</Typography>
      </Grid>

      <Show
        when={tradeinVehicle}
        fallback={<NoTradeIn noTradeinText={noTradeinText} />}
      >
        {(vehicle) => (
          <Grid item container spacing={2}>
            <Grid
              item
              xs={12} md={6} lg={4}
            >
              <VehicleComponent
                {...vehicle}
                imageSource={noCachedImageSrc}
                navigateToNewTab={navigateToNewTab}
                details={
                  <TradeInVehicleDetails
                    mileage={vehicle.mileage}
                    tradeInValue={vehicle.tradeInValue || 0}
                    stockNumber={vehicle.stockNumber}
                    showStockNumber={!!isOwnCar}
                  />
                }
              />
            </Grid>
          </Grid>
        )}
      </Show>
    </Grid>
  )
}
