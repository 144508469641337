import { UploadVehiclePhotoGreetingMessage } from '../../../AddVehicle/screens/AddNewVehicleUploadPhoto/UploadVehiclePhotoGreetingMessage'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const UploadVehiclePhotoGreetingExample = () => {
  return (
    <ComponentContainer>
      <ComponentWithPaperBackground title='Not in atoms folder'>
        <UploadVehiclePhotoGreetingMessage carName='2021 Audi A8 Long' />
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
