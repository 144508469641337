import { Grid, GridProps } from '@mui/material'
import { FC, PropsWithChildren, useMemo } from 'react'
import { COLORS } from '../../types/colors'

interface FixedContainerProps
  extends Omit<GridProps, 'children' | 'left' | 'right' | 'top' | 'bottom'> {
  left?: boolean
  right?: boolean
  top?: boolean
  bottom?: boolean
  background?: string
}

export const FixedContainer: FC<PropsWithChildren<FixedContainerProps>> = ({
  children,
  background,
  left,
  right,
  top,
  bottom,
  ...props
}) => {
  const position = useMemo(() => {
    if (left) {
      return {
        top: 0,
        left: 0
      }
    }

    if (right) {
      return {
        top: 0,
        right: 0
      }
    }

    if (top) {
      return {
        top: 0,
        left: 0
      }
    }

    if (bottom) {
      return {
        bottom: 0,
        left: 0
      }
    }
  }, [left, right, top, bottom])

  return (
    <Grid
      container
      position='sticky'
      {...position}
      margin={0}
      sx={{
        background: background || COLORS.white,
        zIndex: 1
      }}
      {...props}
    >
      {children}
    </Grid>
  )
}
