import * as Yup from 'yup'
import { FIND_CAR_FIELD_NAMES } from '../../types/enums/findCar'
import { latinWithSpecial } from '../../utils/validators'

export const validationSchema = Yup.object({
  [FIND_CAR_FIELD_NAMES.NOTES]: Yup.string().test(
    'is-latin-or-special',
    'Enter a valid data format.',
    latinWithSpecial(['\n', ' ', '€', '£'])
  )
})
