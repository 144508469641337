import Grid from '@mui/material/Grid'
import { FormikValues } from 'formik'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { carSnoopApi } from '../../api'
import { Condition } from '../../atoms/Condition'
import { routes } from '../../routes'
import { useMakeService } from '../../utils/hooks'
import { ConfirmationErrorContainer } from './ConfirmationErrorContainer'
import { ConfirmationLinkContainer } from './ConfirmationLinkContainer'
import { ConfirmationSuccessContainer } from './ConfirmationSuccessContainer'
import { DeleteUserContainer } from './DeleteUserContainer'

interface LocationState {
  email?: string;
}

export const DeleteUser: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [isUserDeleted, setIsUserDeleted] = useState(false)
  const [searchParams] = useSearchParams()
  const linkEmail = searchParams.get('email') || ''
  const linkToken = searchParams.get('token') || ''

  const [deleteUserService, deleteUser] = useMakeService(
    ({ email }: FormikValues) => carSnoopApi.users.deleteUserRequest(email),
    {
      props: {
        email: ''
      }
    }
  )
  const [resendLinkService, resendLink] = useMakeService(
    (email: string) => carSnoopApi.users.deleteUserRequest(email),
    {
      props: ''
    }
  )

  const [deleteUserVerifyTokenService, deleteUserVerifyToken] = useMakeService(
    (data: { id: string; token: string }) => carSnoopApi.users.deleteUserVerifyToken(data),
    {
      props: {
        id: '',
        token: ''
      }
    }
  )

  useEffect(() => {
    if (linkEmail && linkToken) {
      deleteUserVerifyTokenService({ id: linkEmail, token: linkToken })
    }
  }, [linkEmail, linkToken])

  useEffect(() => {
    if (deleteUserVerifyToken.isSuccess) {
      setIsUserDeleted(true)
    }
  }, [deleteUserVerifyToken.isSuccess])

  const onExit = useCallback(() => {
    navigate(routes.login)
  }, [])

  const isLoading = useMemo(() => {
    return deleteUser.isLoading || deleteUserVerifyToken.isLoading || resendLink.isLoading
  }, [deleteUser, deleteUserVerifyToken, resendLink])

  const locationEmail = (location?.state as LocationState)?.email || ''

  return (
    <Grid
      minHeight={{
        xs: '100%'
      }}
      container
      justifyContent='center'
      alignItems={{ sm: 'center', lg: 'flex-start' }}
      flexDirection={{ sm: 'column', lg: 'row' }}
      flexGrow={1}
      paddingTop={{
        xs: '0',
        md: '24px',
        lg: '96px'
      }}
    >
      <Condition
        condition={deleteUser.isSuccess}
        trueContent={
          <ConfirmationLinkContainer
            isLoading={isLoading}
            email={deleteUser.props.email}
            resendLinkService={resendLinkService}
          />
        }
        falseContent={
          <Condition
            condition={isUserDeleted}
            trueContent={
              <ConfirmationSuccessContainer />
            }
            falseContent={
              <Condition
                condition={deleteUserVerifyToken.isError}
                trueContent={<ConfirmationErrorContainer />}
                falseContent={
                  <DeleteUserContainer
                    isLoading={isLoading}
                    email={locationEmail || deleteUser.props.email}
                    status={deleteUser.status}
                    message={deleteUser.payload.message}
                    onSubmit={deleteUserService}
                    onExit={onExit}
                  />
                }
              />
            }
          />
        }
      />
    </Grid>
  )
}
