import { Typography } from '@mui/material'

import { COLORS } from '../../../../../../../types/colors'
import { DetailsItemProps } from '../../DetailsItem'

export const RenderNegative: NonNullable<DetailsItemProps['renderValue']> = (
  value: DetailsItemProps['value']
) => {
  return (
    <Typography variant='main' color={COLORS.text_red}>
      {value}
    </Typography>
  )
}
