import { FC } from 'react'
import { LinkButton } from '../../../../atoms/LinkButton'
import { routes } from '../../../../routes'
import { TermsContainer } from './TermsHint.styles'

export const TermsHint: FC = () => {
  return (
    <TermsContainer>
      <p>
        * I have read and agree to CarSnoop&apos;s{' '}
        <LinkButton label='Terms and Conditions' href={routes.conditions} />{' '}
        and acknowledged{' '}
        <LinkButton label='Cookie and Privacy' href={routes.cookiePolicy} /> policies.
      </p>
    </TermsContainer>
  )
}
