import { countOfChars, latinOnly } from '.'

const validateLength = countOfChars(17)

// const forbidden
const FORBIDDEN_CHARS = ['O', 'o', 'I', 'i', 'Q', 'q']

export const vinNumber = (value?: string) => {
  if (!value) return true

  const lengthIsValid = validateLength(value)
  const containsLatinOnly = latinOnly(value)
  const charsAreValid = !value
    .split('')
    .some((c) => FORBIDDEN_CHARS.includes(c))

  return lengthIsValid && containsLatinOnly && charsAreValid
}
