import {
  CompletedTransactionStatuses,
  InProgressTransactionStatuses
} from '../../../api/carSnoopApi/statusTypes'

export const completedSelectOptionsList = [
  'All',
  'Request Declined',
  'Request Cancelled',
  'Request Expired',
  'Offer Declined',
  'Offer Cancelled',
  'Offer Expired',
  'Contract Terminated',
  'Payment Failed',
  'Delivered'
]

export const completedDealStatuses = completedSelectOptionsList.slice(1)

export const splitSchema: { status: Array<CompletedTransactionStatuses> } = {
  status: completedSelectOptionsList.slice(
    1
  ) as Array<CompletedTransactionStatuses>
}

export const inProgressSelectOptionsList = [
  'All',
  'Offered',
  'Under Contract',
  'Awaiting Payment',
  'Shipping'
]

export const inProgressDealStatuses = inProgressSelectOptionsList.slice(1)
export const inProgressSplitSchema: {
  status: Array<InProgressTransactionStatuses>
} = {
  status: inProgressSelectOptionsList.slice(
    1
  ) as Array<InProgressTransactionStatuses>
}
