import * as Yup from 'yup'
import { MAX_DATE, MIN_DATE } from '../../../constants/applicationConstants'
import { ZIP_CODE_LENGTH } from './constants'
import { latinWithSpecial } from '../../../utils/validators'

export const validationSchema = Yup.object({
  firstName: Yup.string()
    .required('')
    .trim()
    .test(
      'is-latin-or-special',
      'Enter a valid First Name.',
      latinWithSpecial([' '])
    ),
  lastName: Yup.string()
    .required('')
    .trim()
    .test(
      'is-latin-or-special',
      'Enter a valid Last Name.',
      latinWithSpecial([' '])
    ),
  birthDate: Yup.date().required('').max(MAX_DATE).min(MIN_DATE),
  zipCode: Yup.string()
    .typeError('Enter a valid Zip Code.')
    .required('')
    .test('length', 'Enter a valid Zip Code.', (val) => {
      if (!val) {
        return true
      }
      return val?.length === ZIP_CODE_LENGTH
    })
    .matches(/^\d+$/, 'Enter a valid Zip Code.'),
  dealership: Yup.object().required('Dealership is Required!')
})
