import { Typography } from '@mui/material'
import { useMemo, useCallback, useEffect } from 'react'
import {
  generatePath,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom'
import { OperationResultInfoType } from '../../molecules/OperationResultScreen/types'
import { PageContainer } from '../../layout/PageContainer'
import { routes } from '../../routes'
import { OperationResultScreen } from '../../molecules/OperationResultScreen'
import { successfulShippingButtonText, MAIN_TEXT } from './constants'
import {
  FLOW_PARAM,
  FLOW_SEARCH_PARAM,
  PAYMENT_TYPES
} from '../../constants/applicationConstants'
import { useReadTransaction } from '../../utils/hooks'
import { useCreateShipment } from '../../utils/hooks/useCreateShipment'

export const SuccessfulDealPayment = () => {
  const navigate = useNavigate()
  const { transactionId = '' } = useParams()
  const { createShipmentServiceObservable, createShipmentService } =
    useCreateShipment()

  const [params] = useSearchParams()

  const flow = params.get(FLOW_SEARCH_PARAM)

  const navigateToDeal = useCallback(() => {
    const path = generatePath(routes.contracts.transaction, {
      transactionId
    })
    navigate(path)
  }, [transactionId])

  const isStripeFlow = flow === FLOW_PARAM.stripe
  const isNonStripeFlow = flow === FLOW_PARAM.nonStripe

  const { isLoading, data: transaction } = useReadTransaction({ transactionId })

  const mainText = useMemo(() => {
    if (isStripeFlow) {
      return MAIN_TEXT.STRIPE_FLOW
    }
    return MAIN_TEXT.NON_STRIPE_FLOW
  }, [isStripeFlow])

  const operationResultInfo: OperationResultInfoType = useMemo(
    () => ({
      mainText: (
        <Typography variant='body1' textAlign='center' whiteSpace='pre-wrap'>
          {mainText}
        </Typography>
      ),
      onClick: navigateToDeal,
      buttonText: successfulShippingButtonText
    }),
    [navigateToDeal, mainText]
  )

  useEffect(() => {
    if (transaction && isNonStripeFlow) {
      const shipmentPayment =
        transaction.payments?.find((p) => p.type === PAYMENT_TYPES.shipping) ??
        null

      if (shipmentPayment && shipmentPayment.status === 'Paid') {
        createShipmentService(transaction.id)
      }
    }
  }, [transaction])

  return (
    <PageContainer
      isLoading={isLoading || createShipmentServiceObservable.isLoading}
    >
      <OperationResultScreen
        successOperationInfo={operationResultInfo}
        sx={{
          width: '400px',
          height: 'calc(100vh - 140px)',
          minHeight: '400px'
        }}
      />
    </PageContainer>
  )
}
