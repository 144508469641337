import { config } from '../../constants/config'
import { noop } from '../../utils'
import { ApiOptions } from '../types'
import { Utils } from '../utils'
import { Support } from './support'

export class NotificationsApi {
  readonly utils: Utils
  support: Support

  constructor(options: ApiOptions = {}) {
    const {
      endpoint = config.NOTIFICATIONS_API_ENDPOINT,
      token = config.AWS_API_KEY,
      session,
      logoutCallback = noop
    } = options

    this.utils = new Utils({
      endpoint,
      token,
      session,
      logoutCallback
    })

    this.support = new Support(this.utils)
  }
}

export const notificationsApi = new NotificationsApi()
