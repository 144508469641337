import { FC } from 'react'
import { Grid } from '@mui/material'
import { IconButton } from '../IconButton/IconButton'
import { DeleteIcon } from '../../../../assets/icons/Delete'
import { UploadIcon } from '../../../../assets/icons/Upload'

export interface EditMainProps {
  onUploadClick: () => void
  onDeleteClick: () => void
  isMain?: boolean
}

export const EditMenu: FC<EditMainProps> = (props) => {
  const { isMain, onDeleteClick, onUploadClick } = props

  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      spacing={2}
      sx={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        zIndex: 5
      }}
    >
      <Grid item>
        <IconButton isMain={isMain} onClick={onUploadClick}>
          <UploadIcon
            width={isMain ? '24' : '15px'}
            height={isMain ? '24' : '15px'}
          />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton isMain={isMain} onClick={onDeleteClick}>
          <DeleteIcon
            width={isMain ? '24' : '15px'}
            height={isMain ? '24' : '15px'}
          />
        </IconButton>
      </Grid>
    </Grid>
  )
}
