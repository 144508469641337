import { FC, useMemo } from 'react'
import { Grid } from '@mui/material'
import { Show } from '../../../../atoms/JSXExtensions'
import { EmptyState, ItemList } from '../../../../atoms/ItemList'
import { NoMatchesImage } from '../../../../assets/imgs/NoMatches/intex'
import { SellingVehicleItem } from './components/SellingVehicle'
import { OrNull } from '../../../../types/OrNull'
import { Loader } from '../../../../atoms/Loader'
import {
  SearchInventoryControl,
  SearchComponentProps
} from '../../../../features/SearchInventory'
import { NormalizedReadInventoryItem } from '../../../../api/carSnoopApi/accounts/types'

interface RequestsTabProps {
  requestedVehicles: OrNull<NormalizedReadInventoryItem[]>
  searchProps?: SearchComponentProps
}

export const RequestsTab: FC<RequestsTabProps> = ({
  requestedVehicles,
  searchProps
}) => {
  const noDealsText = useMemo(() => {
    const searchApplied = searchProps?.initialValues.search

    return searchApplied ? 'No matches were found' : 'The deals list is empty.'
  }, [searchProps?.initialValues.search])

  return (
    <Grid container spacing={2}>
      <Show when={searchProps}>
        {(props) => (
          <Grid
            item
            container
            justifyContent='flex-start'
            mt={3}
            mb={2}
            xs={5}
            lg={3}
          >
            <SearchInventoryControl {...props} includeReset />
          </Grid>
        )}
      </Show>

      <Show
        when={requestedVehicles}
        fallback={
          <Grid container justifyContent='center'>
            <Loader />
          </Grid>
        }
      >
        {(req) => (
          <Show
            when={req.length > 0}
            fallback={
              <EmptyState
                title={noDealsText}
                image={<NoMatchesImage />}
                sx={{ mt: 2 }}
              />
            }
          >
            <Grid container item spacing={1} xs={12}>
              <Grid item xs={12}>
                <ItemList dataList={req} ItemComponent={SellingVehicleItem} />
              </Grid>
            </Grid>
          </Show>
        )}
      </Show>
    </Grid>
  )
}
