import CloseIcon from '@mui/icons-material/Close'
import { Grid, Typography } from '@mui/material'
import { useField } from 'formik'
import { FC, useCallback, useMemo } from 'react'
import { NotesField } from '../../../atoms/NotesField'
import { useDeviceInfo } from '../../../utils/hooks'

interface AdditionalNotesFieldProps {
  name: string
  label: string
  onCloseHandler: () => void
}

export const AdditionalNotesField: FC<AdditionalNotesFieldProps> = ({
  name,
  label,
  onCloseHandler
}) => {
  const { currentScreenHeight } = useDeviceInfo()
  const [_, __, helpers] = useField<string>({ name })

  const onClose = useCallback(() => {
    helpers.setValue('')
    helpers.setError(undefined)
    onCloseHandler()
  }, [helpers, onCloseHandler])

  const rows = useMemo(() => {
    // 600 is the height of the rest of the form ¯\_(ツ)_/¯
    const boxHeight = (currentScreenHeight - 600) / 100

    return {
      min: boxHeight,
      max: boxHeight
    }
  }, [currentScreenHeight])

  return (
    <Grid container spacing={2}>
      <Grid item container justifyContent='space-between'>
        <Grid item>
          <Typography variant='label1'>Notes</Typography>
        </Grid>
        <Grid item>
          <CloseIcon onClick={onClose} sx={{ cursor: 'pointer' }} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <NotesField name={name} label={label} rows={rows} />
      </Grid>
    </Grid>
  )
}
