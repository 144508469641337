import { Box, Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { RetryIcon } from '../../assets/icons/RetryIcon'
import { Show } from '../JSXExtensions'

interface IErrorTextWithRetryProps {
  errorText: string
  onRetryClick?(): void
  retryButtonText?: string
  withRetryIcon?: boolean
}

export const ErrorTextWithRetry: FC<IErrorTextWithRetryProps> = ({
  errorText,
  retryButtonText = 'Retry',
  onRetryClick,
  withRetryIcon = false
}) => {
  return (
    <Grid container item alignItems='center' spacing={1}>
      <Grid item>
        <Typography variant='subtitle1' color='error'>
          {errorText}
        </Typography>
      </Grid>
      <Show when={onRetryClick}>
        <Grid item>
          <Typography
            component='button'
            variant='subtitle1'
            color='error'
            sx={{
              cursor: 'pointer',
              textDecoration: 'underline',
              border: 'none',
              backgroundColor: 'inherit'
            }}
            onClick={onRetryClick}
          >
            {retryButtonText}
          </Typography>
        </Grid>
      </Show>
      <Show when={withRetryIcon}>
        <Grid item>
          <Box
            component={onRetryClick ? 'button' : 'span'}
            display='flex'
            sx={{
              cursor: onRetryClick ? 'pointer' : '',
              border: 'none',
              backgroundColor: 'inherit'
            }}
            onClick={onRetryClick}
            alignItems='center'
          >
            <RetryIcon size={18} />
          </Box>
        </Grid>
      </Show>
    </Grid>
  )
}
