import { useCallback, useLayoutEffect, useState } from 'react'
import {
  carsByYearResponseToExteriorColorsOption,
  carsByYearResponseToInteriorColorsOption
} from '../../../../../store/units/findCarForm/utils'
import { ColorOption } from '../../../../../types/Autoselect'
import { OrNull } from '../../../../../types/OrNull'
import { ResponseStatusMap } from '../../../../../types/status'
import { useApiContext, useMakeService } from '../../../../../utils/hooks'

export const useReadModelYear = ({
  make,
  model,
  year
}: {
  make?: string
  model?: string
  year?: string
}) => {
  const [currentVehicleOptions, setCurrentVehicleOptions] =
    useState<OrNull<{ extColors: ColorOption[]; intColors: ColorOption[] }>>(
      null
    )

  const { carSnoopApi } = useApiContext()

  const [readModelYear, readModelYearObservable] = useMakeService(
    async ({
      carMake,
      carModel,
      carYear
    }: {
      carMake: string
      carModel: string
      carYear: string
    }) => {
      const response = await carSnoopApi.vehicles.readCarModelYearFromVehicles({
        make: carMake,
        model: carModel,
        year: [carYear]
      })

      if (response.status === ResponseStatusMap.Success) {
        const extColors = carsByYearResponseToExteriorColorsOption(response)
        const intColors = carsByYearResponseToInteriorColorsOption(response)
        setCurrentVehicleOptions({ extColors, intColors })
        return response
      }

      return { status: ResponseStatusMap.Error }
    }
  )

  const fetchOptions = useCallback(() => {
    make &&
      model &&
      year &&
      readModelYear({ carMake: make, carModel: model, carYear: year })
  }, [make, model, year])

  useLayoutEffect(() => {
    fetchOptions()
  }, [fetchOptions])

  return [
    readModelYearObservable.isLoading,
    currentVehicleOptions,
    fetchOptions
  ] as [boolean, typeof currentVehicleOptions, typeof fetchOptions]
}
