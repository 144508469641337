import { Grid, Typography } from '@mui/material'
import { FC, SyntheticEvent, useCallback, useLayoutEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReturnTypeGetAccountStatistics } from '../../../api/carSnoopApi/accounts/returnTypes'
import { NormalizedReadInventoryItem } from '../../../api/carSnoopApi/accounts/types'
import { AgreeButton, BlueButton } from '../../../atoms/Button'
import { Show } from '../../../atoms/JSXExtensions'
import { Loader } from '../../../atoms/Loader'
import { TabContainer } from '../../../atoms/TabContainer'
import { VehiclesList } from '../../../atoms/VehiclesList'
import { routes } from '../../../routes'
import { selectStatistic } from '../../../store/units/statistic'
import { ResponseStatusMap } from '../../../types/status'
import {
  useApiContext,
  useAppSelector,
  useMakeService,
  useUserInfo
} from '../../../utils/hooks'
import { splitSchema } from '../constants'
import { EmptyState } from './EmptyState'
import { useDealerHomepageSlice } from './useDealerHomepageSlice'

interface VehicleListContainerProps {
  isLoading: boolean
  vehicles: NormalizedReadInventoryItem[]
  category: string
}

interface DealerPageProps {
  getAccountStatistics: () => ReturnTypeGetAccountStatistics
}
const VehicleListContainer = ({
  isLoading,
  vehicles,
  category
}: VehicleListContainerProps) => {
  return (
    <Show
      when={!isLoading && vehicles}
      fallback={
        <Grid container justifyContent='center' spacing={2}>
          <Loader small />
        </Grid>
      }
    >
      {(veh) => (
        <VehiclesList
          vehiclesList={veh}
          noVehiclesComponent={<EmptyState category={category} />}
          allowDealsRedirect
        />
      )}
    </Show>
  )
}

export const DealerHomePage: FC<DealerPageProps> = ({
  getAccountStatistics
}) => {
  const { currentTabID, setCurrentTabID, userVehicles, setUserVehiclesList } =
    useDealerHomepageSlice()
  const { requests, offers, contracts } = useAppSelector(selectStatistic)
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    getAccountStatistics()
    setCurrentTabID(newValue)
  }

  const navigate = useNavigate()
  const userInfo = useUserInfo()
  const { carSnoopApi } = useApiContext()
  const statisticsMap = [requests, offers, contracts]
  const [requestInventoryService, inventoryObservable] = useMakeService(
    async () => {
      const response = await carSnoopApi.accounts.readInventory({
        accountID: userInfo.accountID
      })

      if (response.status === ResponseStatusMap.Success) {
        setUserVehiclesList(response.inventory)
      }

      return response
    }
  )

  useLayoutEffect(() => {
    if (userInfo.accountID) {
      requestInventoryService()
    }
  }, [userInfo.accountID, currentTabID])

  const vehiclesList = [
    userVehicles.requested,
    userVehicles.offered,
    userVehicles.underContract
  ]

  const tabContents = vehiclesList.map((list, i) => (
    <VehicleListContainer
      vehicles={list}
      category={splitSchema.status[i].toLowerCase()}
      isLoading={inventoryObservable.isLoading}
    />
  ))

  const onViewAllClick = useCallback(() => {
    navigate(routes.inventory)
  }, [navigate])

  return (
    <Grid container item spacing={2}>
      <Grid item sx={{ display: { xs: 'flex', md: 'none' } }} xs={12}>
        <AgreeButton
          onClick={onViewAllClick}
          sx={{ width: '100%' }}
        >
          <Typography variant='main'>View All Vehicles</Typography>
        </AgreeButton>
      </Grid>
      <Grid item xs={12}>
        <Typography component='h2' variant='h6'>
          My Deals
        </Typography>
      </Grid>
      <Grid container item sx={{ marginBottom: 1 }} xs={12}>
        <TabContainer
          tabNames={statisticsMap.map(
            (itemCount, index) => `${splitSchema.status[index]} (${itemCount})`
          )}
          tabContents={tabContents}
          currentTabID={currentTabID}
          onChange={handleChange}
          trailingContent={
            <BlueButton
              sx={{
                display: { xs: 'none', md: 'block' },
                marginLeft: 'auto'
              }}
              onClick={onViewAllClick}
            >
              <Typography variant='main'>View All Vehicles</Typography>
            </BlueButton>
          }
        />
      </Grid>
    </Grid>
  )
}
