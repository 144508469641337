import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface AddVehicleFormState {
  requests: number
  offers: number
  contracts: number
}

export const initialState: AddVehicleFormState = {
  requests: 0,
  offers: 0,
  contracts: 0
}

export const statisticSlice = createSlice({
  name: 'statisticSlice',
  initialState,
  reducers: {
    setState: (store, action: PayloadAction<AddVehicleFormState>) => {
      const { requests, offers, contracts } = action.payload
      store.requests = requests
      store.offers = offers
      store.contracts = contracts
    },
    reset: () => {
      return initialState
    }
  }
})
