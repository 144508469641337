import { Box, Grid } from '@mui/material'
import { SyntheticEvent, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { TransactionStatusTypes } from '../../api/carSnoopApi/statusTypes'
import {
  NormalizedOfferType,
  NormalizedRequestType,
  NormalizedTradeInType,
  NormalizedVehicleType
} from '../../api/carSnoopApi/transactions/types'
import { ImageWithBadge } from '../../atoms/ImageWithBadge'
import { Show } from '../../atoms/JSXExtensions'
import { NumberBadge } from '../../atoms/NumberBadge'
import { TabContainer } from '../../atoms/TabContainer'
import { Chat, ChatProps } from '../../features/Chat'
import { chatActions } from '../../features/Chat/model/actions'
import { selectMessages } from '../../features/Chat/model/selectors'
import { DisplayVehicleInfo } from '../../features/DisplayVehicleInfo'
import { selectUnreadChatsCounters } from '../../features/MiniChat/model/selectors'
import { getOfferComponentPrice } from '../../features/OfferModal/OfferModal.helpers'
import { CommonConfirmationModal } from '../../molecules/Modal/CommonConfirmationModal'
import { routes } from '../../routes'
import { inProgressDealStatuses } from '../../store/pages/Deals'
import { actionsOfferPage, selectOfferPageModel } from '../../store/pages/Offer'
import { OrNull } from '../../types/OrNull'
import {
  useAppDispatch,
  useAppSelector,
  useBackPath,
  useModal,
  useUserInfo
} from '../../utils/hooks'
import { isNewTab } from '../../utils/isNewTab'
import { useReturnToDealsPage } from '../Deals/hooks'
import { EditOfferModal } from './components/EditOfferModal'
import { OfferHeader } from './components/OfferHeader'
import { OfferInformationViewTab } from './components/OfferInformationViewTab'
import { TABS } from './constants'
import { useOfferDetailsActions } from './hooks/useOfferDetailsActions'

interface OfferDetailsViewProps {
  sellerID: string
  offer: OrNull<NormalizedOfferType>
  previousOffer: OrNull<NormalizedOfferType>
  vehicle: NormalizedVehicleType
  request: NormalizedRequestType
  tradeIn: OrNull<NormalizedTradeInType>
  status: TransactionStatusTypes
  transactionID: string
  onCancelOffer: () => void
  onDeclineOffer: () => void
  readTransaction: () => void
}

export const OfferDetailsView = ({
  sellerID,
  offer: offerData,
  previousOffer,
  vehicle,
  request,
  tradeIn,
  status,
  transactionID,
  onCancelOffer,
  onDeclineOffer,
  readTransaction
}: OfferDetailsViewProps) => {
  const { isAdmin } = useUserInfo()
  const editOfferModal = useModal()
  const navigate = useNavigate()
  const cancelOfferModal = useModal(onCancelOffer)
  const declineOfferModal = useModal(onDeclineOffer)
  const actions = useOfferDetailsActions(
    sellerID,
    editOfferModal,
    cancelOfferModal,
    declineOfferModal
  )
  const backToDealsPage = useReturnToDealsPage(status, sellerID)
  const { backPath } = useBackPath()
  const dispatch = useAppDispatch()
  const { currentTab } = useAppSelector(selectOfferPageModel)
  const unreadChats = useAppSelector(selectUnreadChatsCounters)
  const unreadMessagesCounter = useMemo(
    () => unreadChats[transactionID],
    [unreadChats, transactionID]
  )

  const showActions = useMemo(
    () => isAdmin && status && inProgressDealStatuses.includes(status),
    [status, isAdmin]
  )

  const handleTabChange = useCallback(
    (_event: SyntheticEvent, newValue: number) => {
      dispatch(actionsOfferPage.setCurrentTab(newValue))
    },
    []
  )
  const handleBackClick = useCallback(() => {
    if (backPath !== routes.deals.index) {
      navigate(backPath)
    } else {
      backToDealsPage()
    }
  }, [backPath, backToDealsPage])

  const chatTabContentPadding = useMemo(
    () => (currentTab === 1 ? 0 : 3),
    [currentTab]
  )

  const handleMessagesRead = useCallback<ChatProps['onMessagesRead']>(
    (messages) => {
      dispatch(chatActions.setMessages(messages))
    },
    [dispatch]
  )

  const handleChatUnmount = useCallback(() => {
    dispatch(chatActions.resetMessages())
  }, [dispatch])

  return (
    <Show when={offerData}>
      {(offer) => (
        <Grid container rowSpacing={2} spacing={3}>
          <Grid item xs={12}>
            <OfferHeader
              status={status}
              expiresOn={offer.expiresOn as Date}
              onBackClick={handleBackClick}
              showBackButton={!isNewTab()}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <ImageWithBadge
              imageURL={vehicle.documentContent}
              badgeValue={offer.totalPrice}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <DisplayVehicleInfo {...vehicle} />
          </Grid>
          <Grid container item mt={2}>
            <TabContainer
              tabNames={[TABS.OFFER_INFORMATION, TABS.CHAT]}
              trailingContent={
                <Box sx={{ position: 'relative' }}>
                  <Grid
                    item
                    sx={{
                      position: 'absolute',
                      left: '-26px',
                      top: '8px'
                    }}
                  >
                    <Show when={unreadMessagesCounter}>
                      <NumberBadge
                        width='16px'
                        height='16px'
                        fontSize='10px'
                        count={unreadMessagesCounter}
                      />
                    </Show>
                  </Grid>
                </Box>
              }
              tabContents={[
                <OfferInformationViewTab
                  request={request}
                  offer={offer}
                  tradeIn={tradeIn}
                  showActions={showActions}
                  actions={actions}
                  previousOffer={previousOffer}
                />,
                <Chat
                  onMessagesRead={handleMessagesRead}
                  onChatUnmount={handleChatUnmount}
                  messagesSelector={selectMessages}
                  chatId={transactionID}
                  height='640px'
                />
              ]}
              currentTabID={currentTab}
              onChange={handleTabChange}
              tabContentsSx={{ pb: chatTabContentPadding }}
            />
          </Grid>

          <CommonConfirmationModal
            {...declineOfferModal}
            message='Are you sure you would like to decline the offer?'
            hint='Declined offer can not be recovered'
          />

          <CommonConfirmationModal
            {...cancelOfferModal}
            message='Are you sure you would like to cancel the offer?'
            hint='Cancelled offer can not be recovered'
          />
          <EditOfferModal
            offerId={offer.id}
            transactionId={offer.transactionID}
            onCloseClick={editOfferModal.onCancel}
            isOpen={editOfferModal.isOpen}
            vehiclePrice={getOfferComponentPrice(offer, 'Vehicle Price')}
            tradeIn={getOfferComponentPrice(offer, 'Trade-In Vehicle')}
            salesTax={getOfferComponentPrice(offer, 'Sales Tax')}
            includeTradeIn={!!tradeIn}
            vehicleZipCode={vehicle.zipCode}
            notes={offer.notes}
            addons={offer.addons}
            readTransaction={readTransaction}
          />
        </Grid>
      )}
    </Show>
  )
}
