import { Box, CardMedia, Grid } from '@mui/material'
import { FC, PropsWithChildren } from 'react'
import { ConfirmationModal } from '../../molecules/Modal'
import { useModal } from '../../utils/hooks'

interface IVideoPlayerProps {
  src: string
}

export const VideoPlayer: FC<PropsWithChildren<IVideoPlayerProps>> = ({
  src,
  children
}) => {
  const { onOpen, isOpen, setOpen } = useModal()

  return (
    <>
      <Box onClick={onOpen}>{children}</Box>
      <ConfirmationModal
        isOpen={isOpen}
        setIsOpen={setOpen}
        header={<Grid item />}
        sx={{ maxWidth: '80vw', maxHeight: '85vh', mt: '50px' }}
      >
        <Grid container alignItems='center'>
          <CardMedia
            sx={{ maxHeight: '80vh', width: '100%', p: 3, pb: 5 }}
            component='video'
            controls
            disablePictureInPicture
            src={src}
          />
        </Grid>
      </ConfirmationModal>
    </>
  )
}
