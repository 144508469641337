import { ERROR_REASONS } from '../../../types/enums/network'
import { ResponseStatusMap } from '../../../types/status'
import { ErrorResponse, SuccessResponse } from '../../private.types'
import { Utils } from '../../utils'
import { CheckSubscription, UpdateSubscription } from './types'

export class Subscription {
  protected readonly utils: Utils
  protected readonly operations = {
    checkSubscription: 'checkSubscription',
    updateSubscription: 'updateSubscription'
  }

  constructor(utils: Utils) {
    this.utils = utils
  }

  async checkSubscription(): Promise<
    | SuccessResponse<CheckSubscription.ServiceResponse>
    | ErrorResponse<CheckSubscription.ServiceResponse>
  > {
    try {
      const { type, payload } = await this.utils.makeJSONRequest<
        CheckSubscription.ApiResponse,
        CheckSubscription.ApiResponse
      >(this.operations.checkSubscription)
      if (type === ResponseStatusMap.Success) {
        return {
          status: ResponseStatusMap.Success,
          message: payload.message
        }
      }
      return {
        status: ResponseStatusMap.Error,
        message: payload.message,
        subscriptionIsInvalid:
          payload.reason === ERROR_REASONS.INVALID_SUBSCRIPTION
      }
    } catch (e) {
      return {
        status: ResponseStatusMap.Error,
        message: 'Something went wrong'
      }
    }
  }

  async updateSubscription(
    params: UpdateSubscription.Params
  ): Promise<
    | SuccessResponse<UpdateSubscription.ServiceSuccessResponse>
    | ErrorResponse<UpdateSubscription.ServiceErrorResponse>
  > {
    const dataToSend = {
      account_id: params.accountId,
      type: params.type,
      return_url: params.returnUrl
    }

    const response = await this.utils.makeJSONRequest<
      UpdateSubscription.ApiSuccessResponse,
      UpdateSubscription.ApiErrorResponse
    >(this.operations.updateSubscription, dataToSend)

    const { status } = response
    const isRedirectToStripeNeeded = status === 400

    const isSuccess = response.type === ResponseStatusMap.Success

    if (isSuccess) {
      return {
        status: ResponseStatusMap.Success
      }
    }

    if (isRedirectToStripeNeeded) {
      return {
        status: ResponseStatusMap.Error,
        message: response.payload.message,
        stripeSession: response.payload.stripe_session
      }
    }

    return {
      status: ResponseStatusMap.Error,
      message: response.payload.message
    }
  }
}
