import { useState, useEffect } from 'react'
import { MY_TEAM_MODE } from '../types'
import {
  useAppDispatch,
  useMakeService,
  useUserInfo,
  useApiContext
} from '../../../utils/hooks'
import { ResponseStatusMap } from '../../../types/status'
import { actionsUserInfo } from '../../../store/features/userInfo'

export const useMyTeamCard = () => {
  const [isMyTeamOpen, setIsMyTeamOpen] = useState(false)
  const [isAddingOpenDirectly, setIsAddingOpenDirectly] = useState(false)
  const [mode, setMode] = useState<MY_TEAM_MODE>(MY_TEAM_MODE.LIST)
  const dispatch = useAppDispatch()
  const userInfo = useUserInfo()
  const { carSnoopApi } = useApiContext()

  const [readAccountService, readAccount] = useMakeService(
    async (id: string) => {
      const response = await carSnoopApi.accounts.readAccount(id)

      if (response.status === ResponseStatusMap.Success) {
        dispatch(actionsUserInfo.setConnectedUsers({ users: response.users }))
      }

      return response
    }
  )

  useEffect(() => {
    if (isMyTeamOpen) {
      readAccountService(userInfo.accountID)
    }
  }, [isMyTeamOpen])

  return {
    isMyTeamOpen,
    setIsMyTeamOpen,
    mode,
    setMode,
    isAddingOpenDirectly,
    setIsAddingOpenDirectly,
    readAccount
  }
}

export const useAddUsersService = () => {
  const { carSnoopApi } = useApiContext()
  const { accountID } = useUserInfo()
  const dispatch = useAppDispatch()

  const [addUsersService, addUsersObservable] = useMakeService(
    async (emails: string[]) => {
      const response = await carSnoopApi.accounts.addMultipleUsers({ emails })

      if (response.status === ResponseStatusMap.Success) {
        const readAccountResponse = await carSnoopApi.accounts.readAccount(
          accountID
        )

        if (readAccountResponse.status === ResponseStatusMap.Success) {
          dispatch(
            actionsUserInfo.setConnectedUsers({
              users: readAccountResponse.users
            })
          )
          return { status: ResponseStatusMap.Success }
        }
      }

      return response
    },
    { includeCheckAccess: true }
  )

  return { addUsersObservable, addUsersService }
}
