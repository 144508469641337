import { FC, useRef, useMemo, useCallback } from 'react'
import { Grid } from '@mui/material'
import { FormikProps } from 'formik'
import { PageContainer } from '../../layout/PageContainer'
import { VehiclesList } from '../../atoms/VehiclesList'
import { VehiclesListContainer } from '../../atoms/VehiclesListContainer'
import { FilterInventoryControl, FiltersFormValues } from './FilterInventory'
import { NoResult } from './NoResult'
import { EmptyState } from './EmptyState'
import { useAppSelector } from '../../utils/hooks'
import { SearchInventoryControl } from '../../features/SearchInventory'
import { FilterInventoryByStatusControl } from '../../features/FilterInventoryByStatus'
import { selectInventoryPageModel } from '../../store/pages/Inventory'
import { useInventoryHandlers } from './useInventoryHandlers'
import { useInventorySearch } from './useInventorySearch'

export const InventoryPage: FC = () => {
  const {
    vehiclesListMap,
    selectOptions,
    filterFormValues,
    displayList,
    vehiclesList,
    selectedStatus,
    action
  } = useAppSelector(selectInventoryPageModel)

  const {
    isLoading,
    applySelectVehiclesList,
    applySearchVehiclesList,
    applyFilterVehiclesList,
    resetSearchVehiclesList,
    clearAllFilters
  } = useInventoryHandlers()
  const filtersFormRef = useRef<FormikProps<FiltersFormValues>>(null)

  const searchInventoryControlProps = useInventorySearch({
    filtersFormRef,
    resetSearchVehiclesList,
    applySearchVehiclesList
  })

  const noVehiclesComponent = useMemo(() => {
    if (vehiclesListMap[selectedStatus].length && !displayList.length) {
      return <NoResult action={clearAllFilters} />
    }

    return <EmptyState />
  }, [displayList, clearAllFilters])

  const clearAllFilterForms = useCallback(() => {
    clearAllFilters()
    searchInventoryControlProps.formRef.current?.setFieldValue('search', '')
  }, [clearAllFilters, searchInventoryControlProps.formRef])

  const isNotFiltered = action === 'select'

  return (
    <PageContainer isLoading={isLoading}>
      <VehiclesListContainer vehiclesListCount={vehiclesList.length}>
        <Grid container spacing={2} mt={1}>
          <Grid item container justifyContent='space-between' wrap='nowrap'>
            <Grid item xs='auto'>
              <FilterInventoryByStatusControl
                vehiclesListMap={vehiclesListMap}
                selectOptions={selectOptions}
                applySelectVehiclesList={applySelectVehiclesList}
                initialStatus={selectedStatus}
              />
            </Grid>
            <Grid item xs={6} lg={3}>
              <SearchInventoryControl
                {...searchInventoryControlProps}
                includeReset
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FilterInventoryControl
              initialValues={filterFormValues}
              resetFilters={clearAllFilterForms}
              defaultDisplayVehicleList={vehiclesListMap[selectedStatus]}
              displayVehicleList={displayList}
              applyFilters={applyFilterVehiclesList}
              formRef={filtersFormRef}
              isClearDisabled={isNotFiltered}
            />
          </Grid>
          <Grid item xs={12}>
            <VehiclesList
              vehiclesList={displayList}
              noVehiclesComponent={noVehiclesComponent}
            />
          </Grid>
        </Grid>
      </VehiclesListContainer>
    </PageContainer>
  )
}
