import { Grid, Typography } from '@mui/material'
import { FC, PropsWithChildren, useMemo } from 'react'
import { VehicleStatusTypes } from '../../../../api/carSnoopApi/statusTypes'
import { RemoveButton } from '../../../../atoms/Button'
import { CarFaxInfo } from '../../../../atoms/CarFaxInfo'
import { Condition } from '../../../../atoms/Condition'
import { Show } from '../../../../atoms/JSXExtensions'
import { VehicleBadge } from '../../../../molecules/VehicleItem/VehicleBadge'
import type { Address } from '../../../../store/types'
import { VehicleCondition, VehicleConditionMap } from '../../../../types/status'
import { formatNumber, formatPrice } from '../../../../utils'
import { useUserInfo } from '../../../../utils/hooks'
import { VehicleDetailsMainInfoGrid } from './VehicleDetailsMainInfo.styles'


export type VehicleDetailsMainInfoProps = {
  address?: Address
  allowEdit?: boolean
  condition: VehicleCondition
  isDealer: boolean
  isDeleteButtonEnabled: boolean
  mileage: number
  onDeleteVehicle?: () => void
  retailValue: number
  status?: VehicleStatusTypes
  stockNumber: string
  tradeinValue: number
  vehicleAccountID: string
  vin: string
}

const DetailRowGrid: FC<PropsWithChildren> = ({ children }) => (
  <Grid container item xs={12}>
    {children}
  </Grid>
)

export const VehicleDetailsMainInfo: FC<VehicleDetailsMainInfoProps> = (
  props
) => {
  const {
    address,
    allowEdit = true,
    condition,
    isDealer,
    isDeleteButtonEnabled,
    mileage,
    onDeleteVehicle,
    retailValue,
    status,
    stockNumber,
    tradeinValue,
    vehicleAccountID,
    vin
  } = props

  const vehicleCondition = useMemo(() => {
    return VehicleConditionMap[condition]
  }, [condition])

  const vinCode = useMemo(() => vin || '-', [vin])

  const formattedMileage = useMemo(() => formatNumber(mileage), [mileage])

  const formattedRetailValue = useMemo(
    () => (retailValue ? formatPrice(retailValue) : 'Contact CarSnoop'),
    [retailValue]
  )

  const formattedTradeinValue = useMemo(
    () => (tradeinValue ? formatPrice(tradeinValue) : 'Contact CarSnoop'),
    [tradeinValue]
  )

  const { accountID } = useUserInfo()

  const isOwnCar = vehicleAccountID === accountID

  const {
    mainInfo: { title: mainInfoTitle, value: mainInfoValue },
    secondaryInfo: { title: secondaryInfoTitle, value: secondaryInfoValue }
  } = useMemo(() => {
    return {
      mainInfo: {
        title: isDealer && isOwnCar ? 'STOCK NUMBER' : 'CONDITION',
        value: isDealer && isOwnCar ? stockNumber : vehicleCondition
      },
      secondaryInfo: {
        title: isDealer && isOwnCar ? 'CONDITION' : 'TRADE IN VALUE',
        value: isDealer && isOwnCar ? vehicleCondition : formattedTradeinValue
      }
    }
  }, [isDealer, stockNumber, vehicleCondition, formattedTradeinValue, isOwnCar])

  return (
    <VehicleDetailsMainInfoGrid
      container
      item
      sx={{
        padding: { xs: 2, md: 3 }
      }}
      xs={12}
    >
      <DetailRowGrid>
        <Grid item xs={6}>
          <Grid container item>
            <Typography variant='subtitle2'>VIN</Typography>
          </Grid>
          <Grid container item>
            <Typography variant='h6'>{vinCode}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container item justifyContent='flex-end'>
            <Condition
              condition={!!status}
              trueContent={
                <Grid item>
                  <VehicleBadge status={status} />
                </Grid>
              }
            />
          </Grid>
        </Grid>
      </DetailRowGrid>

      <DetailRowGrid>
        <Grid item xs={6}>
          <Grid container item>
            <Typography variant='subtitle2'>MILEAGE</Typography>
          </Grid>
          <Grid container item>
            <Typography variant='h6'>{formattedMileage}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container item justifyContent='flex-end'>
            <Typography variant='subtitle2'>{mainInfoTitle}</Typography>
          </Grid>
          <Grid container item justifyContent='flex-end'>
            <Typography variant='h6'>{mainInfoValue}</Typography>
          </Grid>
        </Grid>
      </DetailRowGrid>

      <DetailRowGrid>
        <Grid item xs={6}>
          <Grid container item>
            <Typography variant='subtitle2'>PRICE</Typography>
          </Grid>
          <Grid container item>
            <Typography variant='h6'>{formattedRetailValue}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container item justifyContent='flex-end'>
            <Typography variant='subtitle2'>{secondaryInfoTitle}</Typography>
          </Grid>
          <Grid container item justifyContent='flex-end'>
            <Typography variant='h6'>{secondaryInfoValue}</Typography>
          </Grid>
        </Grid>
      </DetailRowGrid>

      <Condition
        condition={!isDealer}
        trueContent={
          <Show when={address}>
            {(addressInfo) => (
              <DetailRowGrid>
                <Grid container item justifyContent='space-between'>
                  <Grid container item spacing={1} xs={6}>
                    <Grid container item>
                      <Typography variant='subtitle2'>ADDRESS</Typography>
                    </Grid>
                    <Grid container item>
                      <Typography variant='h6'>
                        {addressInfo.nickname}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    alignItems='flex-end'
                    flexDirection='column'
                    justifyContent='center'
                    xs={6}
                  >
                    <Grid item textAlign='end'>
                      <Typography variant='main'>
                        {addressInfo.street}
                      </Typography>
                    </Grid>
                    <Grid item textAlign='end'>
                      <Typography variant='main'>
                        {`${addressInfo.city}, ${addressInfo.state} ${addressInfo.zipCode}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </DetailRowGrid>
            )}
          </Show>
        }
      />

      <Condition
        condition={!!vin}
        trueContent={
          <DetailRowGrid>
            <Grid container item xs={12}>
              <Typography variant='subtitle2'>CARFAX REPORT</Typography>
            </Grid>
            <Grid container item xs={12}>
              <CarFaxInfo vin={vin} />
            </Grid>
          </DetailRowGrid>
        }
      />

      <Condition
        condition={isDeleteButtonEnabled && allowEdit}
        trueContent={
          <DetailRowGrid>
            <RemoveButton onClick={onDeleteVehicle}>
              Delete Vehicle
            </RemoveButton>
          </DetailRowGrid>
        }
      />
    </VehicleDetailsMainInfoGrid>
  )
}
