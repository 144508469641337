import { useCallback, useState } from 'react'
import { useCreatePaymentCheckout } from '../../../../api/externalCarSnoopApi/payment/hooks'
import { PaymentApi } from '../../../../api/externalCarSnoopApi/payment/types'
import { ACCESS_DENIED } from '../../../../constants/messages'
import {
  SubscriptionCheckingError,
  SUBSCRIPTION_CHECKING_MESSAGE
} from '../../../../features/Subscription/SubscriptionCheckingError'
import { ResponseStatusMap } from '../../../../types/status'
import { useRequestToast, useSubscriptionModal } from '../../../../utils/hooks'

const generateCancelUrl = (): string => {
  const baseUrl = window.location.origin
  const baseUrlPath = window.location.pathname
  const cancelUrl = new URL('/', baseUrl)
  cancelUrl.pathname = baseUrlPath

  return cancelUrl.href
}

export const useStripePaymentRedirect = (
  generateSuccessUrl: () => string,
  transactionId: string
) => {
  const { createPaymentCheckout } = useCreatePaymentCheckout()
  const [isPaymentOpen, setIsPaymentOpen] = useState(false)
  const { openSubscriptionModal } = useSubscriptionModal()
  const { openRequestToast } = useRequestToast()

  const redirectToPayment = useCallback(() => {
    setIsPaymentOpen(true)
  }, [setIsPaymentOpen])

  const handleRedirectFail = useCallback(
    (e: Error) => {
      if (
        e.message !== SUBSCRIPTION_CHECKING_MESSAGE &&
        e.message !== ACCESS_DENIED
      ) {
        openRequestToast({ status: 'failed' })
      }

      setIsPaymentOpen(false)
    },
    [setIsPaymentOpen]
  )

  const getPaymentRedirectUrl = useCallback(
    async (items: PaymentApi.CreatePaymentCheckout.Params['items']) => {
      const requestParams: PaymentApi.CreatePaymentCheckout.Params = {
        transactionId,
        successUrl: generateSuccessUrl(),
        cancelUrl: generateCancelUrl(),
        items
      }

      const response = await createPaymentCheckout(requestParams)

      if (response.status === ResponseStatusMap.Success) {
        const { paymentUrl } = response

        return paymentUrl
      }

      if (
        response.status === ResponseStatusMap.Error &&
        response.message === ACCESS_DENIED
      ) {
        throw new Error(ACCESS_DENIED)
      }

      if (response.isSubscriptionError) {
        openSubscriptionModal()
        throw new SubscriptionCheckingError()
      }

      throw new Error('Getting url error')
    },
    [transactionId]
  )

  return {
    getPaymentRedirectUrl,
    isPaymentOpen,
    redirectToPayment,
    handleRedirectFail
  }
}
