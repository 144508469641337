import { FC } from 'react'
import { Grid, Typography } from '@mui/material'
import { AgreeButton } from '../../atoms/Button'
import { NoMatchesImage } from '../../assets/imgs/NoMatches/intex'

interface NoResultProps {
  action: () => void | Promise<void>
}

export const NoResult: FC<NoResultProps> = ({ action }) => {
  return (
    <Grid
      container
      justifyContent='center'
      alignContent='center'
      spacing={2}
      flexDirection='column'
    >
      <Grid item textAlign='center'>
        <NoMatchesImage />
      </Grid>
      <Grid item>
        <Typography variant='h6' sx={{ textAlign: 'center' }}>
          No matches were found
        </Typography>
      </Grid>
      <Grid item container justifyContent='center'>
        <AgreeButton sx={{ width: '288px' }} onClick={action}>
          Clear all
        </AgreeButton>
      </Grid>
    </Grid>
  )
}
