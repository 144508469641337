import { KeyboardEventHandler, useState } from 'react'
import { noop } from '../../../../../../utils'

export const useSendListener = (onSend: () => void) => {
  const [isShiftDown, setIsShiftDown] = useState(false)

  const downListener: KeyboardEventHandler = (e) => {
    const { code } = e

    switch (code) {
      case 'ShiftRight':
      case 'ShiftLeft': {
        setIsShiftDown(true)
        break
      }
      case 'NumpadEnter':
      case 'Enter': {
        if (!isShiftDown) {
          e.preventDefault()
          onSend()
        }
        break
      }
      default:
        noop()
    }
  }

  const upListener: KeyboardEventHandler = (e) => {
    const { code } = e

    switch (code) {
      case 'ShiftRight':
      case 'ShiftLeft': {
        setIsShiftDown(false)
        break
      }
      default:
        noop()
    }
  }

  return { downListener, upListener }
}
