import { FC, useMemo } from 'react'
import { Grid, Typography } from '@mui/material'
import { format } from 'date-fns'
import { SpeedometerIcon } from '../../../../assets/icons/Speedometer'
import { Condition } from '../../../../atoms/Condition'
import { InProgressDealCardProps } from './InProgressDealCard'
import { EllipsisText } from '../../../../atoms/EllipsisText'
import { selectDealsPageState } from '../../../../store/pages/Deals'
import { useUserInfo, useAppSelector } from '../../../../utils/hooks'
import { formatPrice, formatNumber } from '../../../../utils'
import { ReadInventoryItemStatusesMap } from '../../../../api/carSnoopApi/accounts/types'
import { VehicleComponentDetailsItem } from '../../../../atoms/VehicleComponentDetailsItem'

export const Details: FC<InProgressDealCardProps> = (props) => {
  const { deal } = props
  const { status, vehicle, offer } = deal
  const { mileage, stockNumber, vin } = vehicle
  const { isDealer } = useUserInfo()
  const { isSellingView } = useAppSelector(selectDealsPageState)
  const formattedMileage = useMemo(() => {
    return `${formatNumber(mileage)} miles`
  }, [mileage])

  const formattedTotalPrice = useMemo(() => {
    return formatPrice(Number(offer?.totalPrice) || 0, {
      includeDecimalPart: true
    })
  }, [offer?.totalPrice])

  const formattedExpirationDate = useMemo(() => {
    return offer?.expiresOn ? format(offer?.expiresOn, 'MM / dd / yyyy') : ''
  }, [offer?.expiresOn])

  const [dependentDetailTitle, dependentDetailText] = useMemo(
    () =>
      status === ReadInventoryItemStatusesMap.Offered
        ? ['Expires on:', formattedExpirationDate]
        : ['Vin:', vin || '-'],
    [status, formattedExpirationDate, vin]
  )

  return (
    <Grid container item flexDirection='column' spacing={1}>
      <Grid item container spacing={1} flexWrap='nowrap'>
        <Condition
          condition={!!stockNumber && isSellingView && isDealer}
          trueContent={
            <Grid container item flexWrap='nowrap' xs={6}>
              <Grid
                item
                display='flex'
                alignItems='center'
                sx={{ mr: 0.5 }}
                flexWrap='nowrap'
              >
                <Typography variant='body2' color='textSecondary'>
                  №
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant='subtitle1'>
                  <EllipsisText tooltipText={stockNumber}>
                    {stockNumber}
                  </EllipsisText>
                </Typography>
              </Grid>
            </Grid>
          }
        />
        <Grid container item flexWrap='nowrap'>
          <Grid item display='flex' alignItems='center' sx={{ mr: 0.5 }}>
            <SpeedometerIcon />
          </Grid>
          <Grid item xs={6}>
            <Typography variant='subtitle1' whiteSpace='nowrap'>
              <EllipsisText tooltipText={formattedMileage}>
                {formattedMileage}
              </EllipsisText>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <VehicleComponentDetailsItem
        title='Total price:'
        value={formattedTotalPrice}
      />
      <Grid item container spacing={0.5} wrap='nowrap'>
        <Grid item>
          <Typography
            whiteSpace='nowrap'
            variant='body2'
            color='textSecondary'
            textTransform='uppercase'
          >
            {dependentDetailTitle}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='body2'>
            <EllipsisText tooltipText={dependentDetailText}>
              {dependentDetailText}
            </EllipsisText>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
