import { useCallback } from 'react'
import {
  selectGaragePageState,
  actionsGaragePage
} from '../../store/pages/Garage'
import { useAppDispatch, useAppSelector } from '../../utils/hooks'

export const useGaragePageSlice = () => {
  const dispatch = useAppDispatch()
  const { currentTabID } = useAppSelector(selectGaragePageState)
  const setCurrentTabID = useCallback((newValue: number) => {
    dispatch(actionsGaragePage.setCurrentTabID(newValue))
  }, [])

  return {
    currentTabID,
    setCurrentTabID
  }
}
