import { ResponseStatusMap } from '../../../types/status'
import { ErrorResponse, SuccessResponse } from '../../private.types'
import { Utils } from '../../utils'
import { SalesTaxError, SalesTaxResponse, SalesTaxSuccess } from './types'

export class Offers {
  protected readonly utils: Utils
  protected readonly operations = {
    readSalesTax: 'readSalesTax'
  }

  constructor(utils: Utils) {
    this.utils = utils
  }

  async readSalesTax(
    sellerZipCode: string,
    totalAmount: string
  ): Promise<SuccessResponse<SalesTaxSuccess> | ErrorResponse<SalesTaxError>> {
    try {
      const response = await this.utils.makeJSONRequest<
        SalesTaxResponse,
        SalesTaxError
      >(this.operations.readSalesTax, {
        seller_zip_code: sellerZipCode,
        total_amount: totalAmount
      })

      if (response.type === ResponseStatusMap.Success) {
        return {
          status: ResponseStatusMap.Success,
          tax: response.payload.sales_tax.toFixed(2).toString()
        }
      }

      return {
        status: ResponseStatusMap.Error,
        message: response.payload.message
      }
    } catch (e) {
      return {
        status: ResponseStatusMap.Error,
        message: 'Something went wrong'
      }
    }
  }
}
