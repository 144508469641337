import { Grid, Typography } from '@mui/material'
import { FC, useMemo } from 'react'
import { Condition } from '../../../../../../atoms/Condition'
import { InformationLabel } from '../../../../../../atoms/InformationLabel'
import { LabelWithCustomValue } from '../../../../../../atoms/LabelWithCustomValue'
import { COLORS } from '../../../../../../types/colors'
import { useModal, useUserInfo } from '../../../../../../utils/hooks'
import {
  PAYMENT_METHODS_ENUM,
  PAYMENT_STATUS
} from '../../../../../Offers/forms/PaymentMicroform/constants'
import { DetailsContainer } from '../../components/DetailsContainer'

import { DeclinePaymentReturnType } from '../../../../../../api/carSnoopApi/payments/types'
import { NormalizedTransactionType } from '../../../../../../api/carSnoopApi/transactions/types'
import { Show } from '../../../../../../atoms/JSXExtensions'
import { Loader } from '../../../../../../atoms/Loader'
import { CommonConfirmationModal } from '../../../../../../molecules/Modal/CommonConfirmationModal'
import { formatPrice, noop } from '../../../../../../utils'
import { arePaymentsPaid } from '../../utils'
import { OneButtonsVariant, TwoButtonsVariant } from './components/Actions'
import {
  confirmPaymentMainText,
  confirmPaymentText,
  declinePaymentMainText,
  declinePaymentText
} from './constants'

export type StripeDetails = {
  totalPrice: number
  paymentMethod: PAYMENT_METHODS_ENUM.ELECTRONIC
}
export type NonStripeDetails = {
  totalPrice: number
  paymentMethod:
  | PAYMENT_METHODS_ENUM.CASH
  | PAYMENT_METHODS_ENUM.CHECK
  | PAYMENT_METHODS_ENUM.FINANCE
  | PAYMENT_METHODS_ENUM.LEASE
}

type PaymentDetails = StripeDetails | NonStripeDetails

export interface SellerStripePaymentProps {
  paymentDetails: PaymentDetails
  payments: NormalizedTransactionType['payments']
  terminateContract: () => DeclinePaymentReturnType
  confirmPayment: () => unknown
  isLoading: boolean
}

export const SellerPayment: FC<SellerStripePaymentProps> = (props) => {
  const {
    paymentDetails,
    payments,
    terminateContract,
    confirmPayment,
    isLoading
  } = props
  const { totalPrice, paymentMethod } = paymentDetails
  const { isAdmin } = useUserInfo()
  const terminateContractModal = useModal(terminateContract)
  const confirmPaymentModal = useModal(confirmPayment)
  const isStripeFlow = paymentMethod === PAYMENT_METHODS_ENUM.ELECTRONIC

  const isPaid = useMemo(() => arePaymentsPaid(payments), [payments])

  const statusOfPaymentParams = useMemo(
    () => ({
      props: {
        color: isPaid ? COLORS.utility_positive : COLORS.text_red
      },
      children: isPaid
        ? PAYMENT_STATUS.COMPLETED
        : PAYMENT_STATUS.WAITING_FOR_PAYMENT
    }),
    [isPaid]
  )

  const formattedPrice = useMemo(() => {
    return formatPrice(totalPrice, { includeDecimalPart: true })
  }, [totalPrice])

  return (
    <DetailsContainer title='Payment details'>
      <Grid container item flexDirection='column' spacing={4}>
        <Grid item>
          <LabelWithCustomValue
            title='Final price'
            value={<Typography variant='title1'>{formattedPrice}</Typography>}
          />
        </Grid>
        <Grid item>
          <LabelWithCustomValue
            title='Payment method'
            value={<Typography variant='label1'>{paymentMethod}</Typography>}
          />
        </Grid>

        <Grid item>
          <Condition
            condition={isStripeFlow}
            trueContent={
              <LabelWithCustomValue
                title='Status of payment'
                value={
                  <Typography
                    variant='label1'
                    {...statusOfPaymentParams.props}
                  >
                    {statusOfPaymentParams.children}
                  </Typography>
                }
              />
            }
            falseContent={
              <InformationLabel labelText='Once the payment is confirmed, we`ll release the vehicle.' />
            }
          />
        </Grid>
        {/* Actions */}
        <Show when={isAdmin}>
          <Show
            when={!isLoading}
            fallback={
              <Grid container justifyContent='center'>
                <Loader small />
              </Grid>
            }
          >
            <>
              {/* Stripe */}
              <Show when={isStripeFlow}>
                <Condition
                  condition={isPaid}
                  trueContent={
                    <OneButtonsVariant onClick={confirmPaymentModal.onOpen} />
                  }
                  falseContent={
                    <TwoButtonsVariant
                      // This button is always disabled in Stripe flow
                      onConfirmClick={noop}
                      onDeclineClick={terminateContractModal.onOpen}
                      confirmDisabled
                    />
                  }
                />
              </Show>
              {/* /Stripe */}

              {/* NonStripe */}
              <Show when={!isStripeFlow}>
                <TwoButtonsVariant
                  onConfirmClick={confirmPaymentModal.onOpen}
                  onDeclineClick={terminateContractModal.onOpen}
                />
              </Show>
              {/* /NonStripe */}
            </>
          </Show>
        </Show>
        {/* Actions */}
      </Grid>
      <CommonConfirmationModal
        {...confirmPaymentModal}
        message={confirmPaymentMainText}
        hint={confirmPaymentText}
      />
      <CommonConfirmationModal
        {...terminateContractModal}
        message={declinePaymentMainText}
        hint={declinePaymentText}
      />
    </DetailsContainer>
  )
}
