import { Box, Button, ButtonProps, styled } from '@mui/material'
import { PropsWithChildren } from 'react'
import { BGCOLORS, BORDERS, COLORS } from '../../types/colors'
import { ButtonType } from './ActionBlock.types'

export const ActionBlockBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  height: 48px;
  width: 100%;
`

interface IActionButtonProps {
  isSingle: boolean
  buttonType: ButtonType
}

export const ActionButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isSingle' && prop !== 'buttonType'
})<PropsWithChildren<IActionButtonProps & ButtonProps>>(
  ({ isSingle, buttonType }) => ({
    fontSize: '18px',
    color: COLORS.main_black,
    width: isSingle ? '100%' : '',
    border: buttonType === 'secondary' ? BORDERS.SM_YELLOW : 'none',
    background: buttonType === 'secondary' ? '' : COLORS.main_yellow,

    '&:hover': {
      border: buttonType === 'secondary' ? BORDERS.SM_YELLOW : 'none',
      background:
        buttonType === 'secondary' ? 'none' : COLORS.hover_yellow
    },

    '&:active': {
      border: buttonType === 'secondary' ? BORDERS.SM_ACTIVE_YELLOW : 'none',
      background:
        buttonType === 'secondary' ? 'none' : COLORS.active_yellow
    },
    ...(buttonType === 'primary'
      ? {
        '&:disabled': {
          color: COLORS.main_black,
          background: BGCOLORS.gray,
          border: 'none',
          cursor: 'not-allowed'
        }
      }
      : {})
  })
)
