import { CardMedia, Grid, Typography } from '@mui/material'
import { DragEventHandler, FC, useCallback } from 'react'
import { CameraIcon } from '../../../../assets/icons/Camera'
import { UploadCloudIcon } from '../../../../assets/icons/UploadCloud'
import { DefaultVehicle } from '../../../../assets/imgs/DefaultVehicle'
import { Condition } from '../../../../atoms/Condition'
import { Show } from '../../../../atoms/JSXExtensions'
import { MAX_PHOTO_SIZE_MB } from '../../../../constants/applicationConstants'
import { COLORS } from '../../../../types/colors'
import {
    CompressImageResponse,
    compressImages
} from '../../../../utils/compressImages'

export interface DropProps {
  onUploadClick: () => void
  image: string
  handleBeforeDrop: () => Promise<void>
  onFileDrop: (file: CompressImageResponse) => void
  maxSizeMB?: number
  error?: string
  allowEdit?: boolean
}

export const Drop: FC<DropProps> = (props) => {
  const {
    onUploadClick,
    handleBeforeDrop,
    onFileDrop,
    image,
    maxSizeMB = MAX_PHOTO_SIZE_MB,
    error = '',
    allowEdit = true
  } = props

  const handleFileDrop: DragEventHandler<HTMLDivElement> = useCallback(
    async (e) => {
      e.stopPropagation()
      e.preventDefault()
      await handleBeforeDrop()
      const files = Array.from(e.dataTransfer.files)
      const compressedFiles = await compressImages(files, {
        useWebWorker: true,
        maxSizeMB
      })

      onFileDrop(compressedFiles)
    },
    [onFileDrop, handleBeforeDrop]
  )

  const onDragOver: DragEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()
    e.preventDefault()
  }
  const DefaultImage = <DefaultVehicle width='75%' height='75%' />
  const DefaultView = (
    <Grid container item zIndex={6} spacing={1}>
      <Grid item xs={12} container justifyContent='center'>
        <UploadCloudIcon />
      </Grid>
      <Grid item xs={12} container justifyContent='center'>
        <Typography variant='main' color={COLORS.text_secondary}>
          Drag and drop files or
        </Typography>
      </Grid>
      <Grid item xs={12} container justifyContent='center'>
        <Typography
          variant='main'
          color={COLORS.text_blue}
          sx={{
            cursor: 'pointer'
          }}
          onClick={onUploadClick}
        >
          Click here to choose
        </Typography>
      </Grid>
    </Grid>
  )

  const OversizeView = (
    <Grid container item zIndex={6} spacing={1}>
      <Grid item xs={12} container justifyContent='center'>
        <CameraIcon width='44px' height='44px' color={COLORS.text_red} />
      </Grid>
      <Grid item xs={12} container justifyContent='center'>
        <Typography variant='error'>{error}</Typography>
      </Grid>
      <Grid item xs={12} container justifyContent='center'>
        <Typography
          variant='main'
          color={COLORS.text_blue}
          sx={{
            cursor: 'pointer'
          }}
          onClick={onUploadClick}
        >
          Click here to choose new photo
        </Typography>
      </Grid>
    </Grid>
  )

  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      height='100%'
      onDragOver={onDragOver}
      onDrop={handleFileDrop}
      bgcolor={allowEdit ? COLORS.white : COLORS.border_gray}
    >
      <Condition
        condition={!!error}
        trueContent={OversizeView}
        falseContent={
          <Show when={image} fallback={allowEdit ? DefaultView : DefaultImage}>
            {(imageURL) => (
              <CardMedia
                key={imageURL}
                component='img'
                src={imageURL}
                alt='Vehicle'
                height='100%'
                width='100%'
                sx={{
                  objectFit: 'cover'
                }}
              />
            )}
          </Show>
        }
      />
    </Grid>
  )
}
