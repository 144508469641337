import { useCallback } from 'react'
import { useAppDispatch, useNavigate } from '.'
import { LoginFormValues } from '../../forms/screens/LogIn/types'
import { routes } from '../../routes'
import { actionsUserInfo } from '../../store/features/userInfo'
import { actionsLoginForm } from '../../store/units/loginForm/actions'
import { ResponseStatusMap } from '../../types/status'
import {
  AuthenticatedUserValues,
  authorizeBySession
} from '../authorizeBySession'
import { useApiContext } from './useApiContext'
import { useMakeService } from './useMakeService'
import { useRequestToast } from './useToast'

export const useAuthenticateUser = () => {
  const context = useApiContext()
  const { carSnoopApi } = context
  const navigate = useNavigate()

  const dispatch = useAppDispatch()
  const { openRequestToast } = useRequestToast()

  interface AuthParams extends LoginFormValues {
    idp?: 'Google' | 'LinkedIn'
  }

  const loginBySession = useCallback(
    async (options: AuthenticatedUserValues) => {
      const authorizedData = await authorizeBySession(
        {
          id: options.id,
          sessionID: options.sessionID
        },
        context
      )

      if (authorizedData) {
        dispatch(actionsUserInfo.authorize(authorizedData))
      } else {
        navigate(routes.login)
      }
    },
    [context]
  )

  const [loginService, loginObservable] = useMakeService(
    async ({ email, password, idp }: AuthParams) => {
      const response = await carSnoopApi.users.authenticateUser({
        id: email,
        password,
        idp
      })

      if (response.status === ResponseStatusMap.Success) {
        const authorizedData = await authorizeBySession(
          {
            id: response.id,
            sessionID: response.sessionID
          },
          context
        )

        if (authorizedData) {
          dispatch(actionsUserInfo.authorize(authorizedData))
          navigate(routes.dashboard)
        }
      }

      if (response.isExternal && response.status === ResponseStatusMap.Error) {
        openRequestToast({ status: 'failed' })
      }

      dispatch(
        actionsLoginForm.setLoginFormState({
          isBlocked: response.isBlocked,
          blockedUntil: response.blockedUntil,
          errorMessage: response.message
        })
      )

      return response
    },
    {
      props: {
        email: '',
        password: ''
      }
    }
  )

  return { loginObservable, loginService, loginBySession }
}
