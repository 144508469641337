import { OrNull } from '../../types/OrNull'

export enum FIELDS_NAMES {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  PHONE = 'phone',
  EMAIL = 'email',
  DATE_OF_BIRTH = 'dateOfBirth'
}

export enum FIELDS_LABELS {
  FIRST_NAME = 'First name',
  LAST_NAME = 'Last name',
  PHONE = 'Phone number',
  EMAIL = 'Email',
  DATE_OF_BIRTH = 'dateOfBirth'
}
export interface PersonalDetailsFormFieldsTypes {
  [FIELDS_NAMES.FIRST_NAME]: string
  [FIELDS_NAMES.LAST_NAME]: string
  [FIELDS_NAMES.PHONE]: string
  [FIELDS_NAMES.EMAIL]: string
  [FIELDS_NAMES.DATE_OF_BIRTH]: OrNull<Date>
}
