import {
  FC,
  PropsWithChildren,
  useCallback,
  MouseEventHandler,
  ElementType
} from 'react'
import { Box } from '@mui/material'
import { NavLinkProps, useNavigate } from 'react-router-dom'
import { useNavigationController } from '../hooks/useNavigationController'

export interface ControlledLinkProps {
  to: NavLinkProps['to']
  component?: ElementType
}
export const ControlledLink: FC<PropsWithChildren<ControlledLinkProps>> = ({
  children,
  component = Box,
  to
}) => {
  const navigate = useNavigate()
  const controller = useNavigationController()

  const onConfirmNavigate = useCallback(() => {
    navigate(to)
  }, [navigate, to])

  const handleClick = useCallback<MouseEventHandler>(
    (e) => {
      if (controller.isPathBlocked) {
        e.preventDefault()
        controller.callback(onConfirmNavigate)
      }
    },
    [controller.isPathBlocked]
  )
  return (
    <Box component={component} onClickCapture={handleClick}>
      {children}
    </Box>
  )
}
