import { RootState } from '../../../store/types'

export const selectChatList = (state: RootState) => state.miniChatStore.chatList
export const selectMessages = (state: RootState) => state.miniChatStore.messages
export const selectHasUnread = (state: RootState) =>
  state.miniChatStore.hasUnread
export const selectUnreadChatsCounters = (state: RootState) =>
  state.miniChatStore.unreadChats
export const selectIsChatOpen = (state: RootState) =>
  state.miniChatStore.isChatOpen
