import { Typography } from '@mui/material'
import { FC } from 'react'
import { Linkify } from '../../../../atoms/Linkify'
import {
  ChatMessageContainer,
  ChatMessageContainerOptions
} from '../ChatMessageContainer'
import { TextMessageType } from '../../../../api/chat/types'

interface ITextMessageContentProps {
  text: string
}

const TextMessageContent: FC<ITextMessageContentProps> = ({ text }) => {
  return (
    <Linkify>
      <Typography
        variant='body1'
        sx={{
          overflowWrap: 'anywhere',
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word'
        }}
      >
        {text}
      </Typography>
    </Linkify>
  )
}

export const TextMessage: FC<TextMessageType & ChatMessageContainerOptions> = (
  props
) => {
  const { message } = props
  return (
    <ChatMessageContainer {...props}>
      <TextMessageContent text={message} />
    </ChatMessageContainer>
  )
}
