export const dataURLtoFile = (
  dataURL: string,
  fileName: string
): Promise<File> => {
  return fetch(dataURL)
    .then((res) => res.blob())
    .then((blob) => {
      return new File([blob], fileName, { type: blob.type })
    })
}
