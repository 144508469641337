import { Grid, Typography, Link, styled } from '@mui/material'
import { COLORS } from '../../types/colors'

export const SubscriptionModalContainer = styled(Grid)(() => ({
  justifyContent: 'center',
  minWidth: '528px'
}))

export const SubscriptionModalContentContainer = styled(Grid)(() => ({
  flexDirection: 'column',
  alignItems: 'center',
  width: '300px',
  paddingTop: '32px',
  paddingBottom: '8px'
}))

export const SubscriptionTypography = styled(Typography)(() => ({
  textAlign: 'center'
}))

export const IconContainer = styled(Grid)(() => ({
  marginBottom: '32px'
}))

export const SupportLink = styled(Link)(() => ({
  color: COLORS.accent_blue
}))

export const submitButtonStyle = {
  width: '300px',
  py: '9px'
}

export const confirmationModalStyle = {
  paddingBottom: '16px'
}
