import { FC } from 'react'
import { Loader } from '../../../../atoms/Loader'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const LoaderExample: FC = () => {
  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <Loader />
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
