import { Grid } from '@mui/material'
import { FC, useMemo } from 'react'
import { Show } from '../../../atoms/JSXExtensions'
import { Loader } from '../../../atoms/Loader'
import { FilterInventoryByStatusControl } from '../../../features/FilterInventoryByStatus'
import {
  SearchComponentProps,
  SearchInventoryControl
} from '../../../features/SearchInventory'
import {
  actionsInProgressDealsPage,
  selectInProgressDealsPageModel
} from '../../../store/pages/Deals'
import type {
  NormalizedDealsListType,
  SelectedInProgressStatus
} from '../../../store/types'
import { useAppDispatch, useAppSelector } from '../../../utils/hooks'
import { InProgressDealsList } from './InProgressDealsList'

interface InProgressTabProps {
  searchProps?: SearchComponentProps
}

export const InProgressTab: FC<InProgressTabProps> = ({ searchProps }) => {
  const {
    dealsListMap,
    selectOptions,
    displayList,
    selectedStatus,
    dealsList
  } = useAppSelector(selectInProgressDealsPageModel)
  const dispatch = useAppDispatch()

  const applySelectDealsList = (
    vl: NormalizedDealsListType,
    status: SelectedInProgressStatus
  ) => {
    dispatch(actionsInProgressDealsPage.setSelectResults({ data: vl, status }))
  }

  const noDealsText = useMemo(() => {
    const selectApplied =
      !dealsListMap[selectedStatus]?.length && dealsList && dealsList.length
    const searchApplied = searchProps?.initialValues.search

    return selectApplied || searchApplied
      ? 'No matches were found'
      : 'The deals list is empty.'
  }, [
    dealsListMap[selectedStatus],
    dealsList,
    searchProps?.initialValues.search
  ])

  return (
    <Grid container gap={2} mt={1}>
      <Grid container item justifyContent='space-between'>
        <Grid item xs={5} lg={3}>
          <Show when={searchProps}>
            {(props) => <SearchInventoryControl {...props} includeReset />}
          </Show>
        </Grid>
        <Grid item>
          <FilterInventoryByStatusControl
            disabled={!displayList}
            vehiclesListMap={dealsListMap}
            selectOptions={selectOptions}
            applySelectVehiclesList={applySelectDealsList}
            initialStatus={selectedStatus}
            vehiclesList={dealsList}
          />
        </Grid>
      </Grid>
      <Show
        when={displayList}
        fallback={
          <Grid container height={200}>
            <Loader />
          </Grid>
        }
      >
        {(inProgressDisplayList) => (
          <Grid item xs={12} mt={2}>
            <InProgressDealsList
              deals={inProgressDisplayList}
              noDealsText={noDealsText}
            />
          </Grid>
        )}
      </Show>
    </Grid>
  )
}
