import { Grid, Typography } from '@mui/material'
import { format, parseISO } from 'date-fns'
import { FC, MouseEventHandler, useCallback, useMemo } from 'react'
import { CarsnoopIcon } from '../../../assets/logo/CarsnoopIcon'
import { Show } from '../../../atoms/JSXExtensions'
import { MMDDYYYY } from '../../../constants/applicationConstants'
import { OrNull } from '../../../types/OrNull'
import { COLORS } from '../../../types/colors'
import { useModal } from '../../../utils/hooks'
import { DocumentPreview } from '../../Contract/DocumentsTab/components/DocumentPreview'

interface DocumentInfoCardProps {
  name: string
  type: string
  createdOn: string
  link: OrNull<string>
}

export const DocumentInfoCard: FC<DocumentInfoCardProps> = ({
  name,
  type,
  createdOn,
  link
}) => {
  const documentPreviewModal = useModal()

  const openDocumentClick: MouseEventHandler = useCallback(
    (e) => {
      if (type === 'pdf' && link) {
        window.open(link, '_blank')?.focus()
      } else {
        documentPreviewModal.onOpen(e)
      }
    },
    [link, type, documentPreviewModal.onOpen]
  )

  const documentDescription = useMemo(
    () => `${type.toUpperCase()} - ${format(parseISO(createdOn), MMDDYYYY)}`,
    [type, createdOn]
  )

  return (
    <>
      <Show when={link}>
        <DocumentPreview
          type={type}
          link={link || ''}
          isOpen={documentPreviewModal.isOpen}
          setIsOpen={documentPreviewModal.setOpen}
        />
      </Show>
      <Grid
        item
        container
        xs={12}
        columnSpacing={1}
        alignItems='center'
        sx={{
          background: COLORS.secondary_background,
          p: 1,
          ml: 0,
          '&:hover': {
            cursor: 'pointer'
          }
        }}
        onClick={openDocumentClick}
      >
        <Grid item container alignItems='center' xs='auto'>
          <CarsnoopIcon />
        </Grid>
        <Grid item>
          <Typography variant='label1' mb={1}>
            {name}
          </Typography>
          <Typography variant='subtitle2' fontWeight={500}>
            {documentDescription}
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}
