import { useMediaQuery, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import { CarSnoopLogo } from '../../assets/logo/CarsnoopLogo';
import { routes } from '../../routes';
import { COLORS } from '../../types/colors';
import { AccountMenu } from './components/AccountMenu';
import { AppButtons } from './components/AppButtons';
import { AppMenu } from './components/AppMenu/AppMenu';


export const ResponsiveAppBar = () => {
  const theme = useTheme();
  const isBreakpointDownMd = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <AppBar
      component="nav"
      position="fixed"
      sx={{
        backgroundColor: COLORS.main_black,
      }}
    >
      <Container disableGutters={isBreakpointDownMd} maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
            <Link href={routes.home} sx={{ lineHeight: .6, textDecoration: 'none' }} target='_blank'>
              <CarSnoopLogo height="28" width="224" />
            </Link>
          </Box>

          <Box sx={{ display: { xs: 'flex', md: 'none' }, flexGrow: 1 }}>
            <AppMenu />
          </Box>

          <Box sx={{ display: { xs: 'flex', md: 'none' }, flexGrow: 1 }}>
            <Link href={routes.home} sx={{ lineHeight: .6, textDecoration: 'none' }} target='_blank'>
              <CarSnoopLogo height="28" width="224" />
            </Link>
          </Box>

          <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1, justifyContent: 'center' }}>
            <AppButtons />
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <AccountMenu />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
