import { FC, useCallback, useState } from 'react'
import { Condition } from '../../../atoms/Condition'
import { PersonalSettingsItem } from '../../../atoms/PersonalSettingsItem'
import { DisplayDriverLicenseCard } from './DisplayDriverLicenseCard'
import { EditDriverLicense, EditDriverLicenseProps } from './EditDriverLicense'
import {
  useApiContext,
  useAppDispatch,
  useMakeService,
  useUserInfo,
  useModal
} from '../../../utils/hooks'
import { ResponseStatusMap } from '../../../types/status'
import { actionsUserInfo } from '../../../store/features/userInfo'
import { DeleteConfirmationModal } from '../../../molecules/Modal'
import { UploadNewDocumentModal } from '../../../features/UploadNewDocument'
import { UploadDocumentFormType } from '../../../features/UploadNewDocument/types'
import { DriverLicenseFileType } from '../../../api/carSnoopApi/users/types'

export interface DriverLicenseCardProps {
  fullName?: string
  licenseNumber?: string
  state?: string
  expirationDate?: string
  licenseDocument?: DriverLicenseFileType
}

type UpdateUserDriverLicenceOptions =
  | Parameters<EditDriverLicenseProps['submitChanges']>[0]
  | {
      dnl: ''
      state: ''
      expirationDate: ''
    }

export const DriverLicenseInformationCard: FC<DriverLicenseCardProps> = (
  props
) => {
  const dispatch = useAppDispatch()
  const { carSnoopApi } = useApiContext()
  const [
    isDeleteDriverLicenceConfirmationOpen,
    setDeleteDriverLicenceConfirmationOpen
  ] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const { fullName, ...editableProps } = props
  const uploadDocumentModal = useModal()
  const {
    id: userId,
    firstName,
    lastName,
    phone,
    dateOfBirth,
    taxID,
    addresses,
    driverLicenseFile
  } = useUserInfo()

  const [updateDriverLicense, updateDriverLicenseObservable] = useMakeService(
    async (options: UpdateUserDriverLicenceOptions) => {
      const updateResponse = await carSnoopApi.users.updateUser({
        id: userId,
        firstName,
        lastName,
        phone,
        dateOfBirth: new Date(dateOfBirth),
        taxId: taxID,
        driversLicense: options.dnl,
        driversLicenseState: options.state,
        driversLicenseExpiration: options.expirationDate,
        addresses
      })

      if (updateResponse.status === ResponseStatusMap.Success) {
        const updatedUser = await carSnoopApi.users.readUser(userId)

        if (updatedUser.status === ResponseStatusMap.Success) {
          dispatch(
            actionsUserInfo.setUserPersonalInfo({
              driversLicense: updatedUser.driversLicense,
              driversLicenseState: updatedUser.driversLicenseState,
              driversLicenseExpiration: updatedUser.driversLicenseExpiration,
              driverLicenseFile: updatedUser.driverLicenseFile
            })
          )
          setIsEditMode(false)
        }
      }

      return updateResponse
    },
    {
      withStatusNotification: true
    }
  )

  const [uploadDriverLicense, uploadDriverLicenseObservable] = useMakeService(
    async (data: UploadDocumentFormType) => {
      const res = await carSnoopApi.documents.uploadDriversLicense({
        type: data.file?.type || '',
        content: data.file?.content || '',
        notes: data.notes
      })

      if (res.status === ResponseStatusMap.Success) {
        const updatedUser = await carSnoopApi.users.readUser(userId)

        if (updatedUser.status === ResponseStatusMap.Success) {
          dispatch(
            actionsUserInfo.setUserPersonalInfo({
              driverLicenseFile: updatedUser.driverLicenseFile
            })
          )
          uploadDocumentModal.onCancel()
        }

        return updatedUser
      }

      return { status: ResponseStatusMap.Error }
    },
    {
      withStatusNotification: true
    }
  )

  const [deleteDriverLicense, deleteDriverLicenseObservable] = useMakeService(
    async (id: string) => {
      const res = await carSnoopApi.documents.deleteContractDocument(id)

      if (res.status === ResponseStatusMap.Success) {
        const updatedDriverLicense = await updateDriverLicense({
          dnl: '',
          state: '',
          expirationDate: ''
        })

        return updatedDriverLicense
      }

      return { status: ResponseStatusMap.Error }
    }
  )

  const submitUploadDocument = async (data: UploadDocumentFormType) => {
    uploadDriverLicense(data)
  }

  const submitChangeHandler: EditDriverLicenseProps['submitChanges'] =
    useCallback(
      async (options) => {
        await updateDriverLicense(options)
      },
      [updateDriverLicense]
    )

  const submitDeleteHandler = useCallback(() => {
    if (driverLicenseFile) {
      deleteDriverLicense(driverLicenseFile.id)
    }
  }, [deleteDriverLicense, driverLicenseFile])

  const onDeleteDriverLicence = useCallback(() => {
    setDeleteDriverLicenceConfirmationOpen(true)
  }, [setDeleteDriverLicenceConfirmationOpen])

  return (
    <PersonalSettingsItem
      hint='We only need your Driver`s License for the title transfer of
  your vehicle.'
      isEditMode={isEditMode}
      isLoading={
        updateDriverLicenseObservable.isLoading ||
        deleteDriverLicenseObservable.isLoading
      }
    >
      <DeleteConfirmationModal
        isOpen={isDeleteDriverLicenceConfirmationOpen}
        setOpen={setDeleteDriverLicenceConfirmationOpen}
        onSubmit={submitDeleteHandler}
        message='Are you sure you would like to delete Driver’s License?'
      />
      <UploadNewDocumentModal
        {...uploadDocumentModal}
        isLoading={uploadDriverLicenseObservable.isLoading}
        name="Driver's License"
        onSubmit={submitUploadDocument}
      />
      <Condition
        condition={isEditMode}
        trueContent={
          <EditDriverLicense
            {...editableProps}
            setIsEditMode={setIsEditMode}
            submitChanges={submitChangeHandler}
            deleteDNL={onDeleteDriverLicence}
            onUploadDocument={uploadDocumentModal.onOpen}
          />
        }
        falseContent={
          <DisplayDriverLicenseCard setEditMode={setIsEditMode} {...props} />
        }
      />
    </PersonalSettingsItem>
  )
}
