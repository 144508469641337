import { Box, Grid } from '@mui/material'
import { FC, useMemo } from 'react'
import { BackArrow } from '../../../../assets/icons/BackArrow'
import { ColoredButton } from '../../../../atoms/Button'
import { EllipsisText } from '../../../../atoms/EllipsisText'
import { LinkButton } from '../../../../atoms/LinkButton'
import { BGCOLORS, COLORS } from '../../../../types/colors'
import { useStatusPathResolver } from '../../../../utils/hooks/useStatusPathResolver'
import { ChatListItemType } from '../../model/types'

export interface ChatTabParams {
  openChatTab?: boolean
}
interface ChatHeaderProps {
  chat: ChatListItemType
  onBack: () => void
}

export const ChatHeader: FC<ChatHeaderProps> = ({ onBack, chat }) => {
  const { transactionId, transactionStatus } = chat
  const headerTitle = useMemo(() => {
    const { year, make, model, trim } = chat.vehicle

    return `${year} ${make} ${model} ${trim}`.trim()
  }, [chat.vehicle])

  const { getPathByStatus } = useStatusPathResolver(transactionStatus)

  const navigationURL = useMemo(
    () => getPathByStatus({ transactionId }),
    [transactionId, getPathByStatus]
  )

  return (
    <Box>
      <Box sx={{ position: 'relative', top: 22, height: 0 }}>
        <ColoredButton onClick={onBack}>
          <BackArrow />
        </ColoredButton>
      </Box>
      <Grid
        item
        container
        justifyContent='center'
        height={80}
        alignItems='center'
        p={3}
        sx={{
          backgroundColor: BGCOLORS.white
        }}
      >
        <Grid item sx={{ color: COLORS.accent_blue, maxWidth: '85%' }}>
          <EllipsisText tooltipText={headerTitle}>
            <LinkButton
              label={headerTitle}
              to={navigationURL}
              target='_blank'
            />
          </EllipsisText>
        </Grid>
      </Grid>
    </Box>
  )
}
