import { TextField } from '@mui/material'
import { useField } from 'formik'
import { ChangeEvent, FC } from 'react'
import { lastOf } from '../../../../../../utils/array'
import { inputStyles } from '../styles'

interface ChatInputProps {
  name: string
  placeholder?: string
}

export const ChatInput: FC<ChatInputProps> = (props) => {
  const { name, placeholder = '' } = props

  const [field] = useField<string>({ name })
  const { onChange } = field

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value }
    } = e
    const prevSybmol = value[value.length - 2]
    const currentSybmol = lastOf(value.split(''))

    const isPrevSymbolNewLine = prevSybmol === '\n' || prevSybmol === undefined
    const isNewSymbolNewLine = currentSybmol === '\n'

    // There is a bug with many rerenders so...
    if (isNewSymbolNewLine && isPrevSymbolNewLine) {
      return
    }

    onChange(e)
  }

  return (
    <TextField
      sx={inputStyles}
      label=''
      placeholder={placeholder}
      minRows={1}
      maxRows={3}
      multiline
      {...field}
      onChange={handleChange}
    />
  )
}
