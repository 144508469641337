import { useCallback } from 'react'
import { generatePath } from 'react-router-dom'
import { routes } from '../../routes'
import { DEAL_FLOW } from '../../types/enums/dealFlow'

export const useDealsRoute = () => {
  const getPathToDeal = useCallback(
    (params: { transactionId: string; flow: DEAL_FLOW }) => {
      const { transactionId, flow } = params
      return generatePath(routes.deals.details, {
        transactionId,
        mode: flow
      })
    },
    []
  )

  return {
    getPathToDeal
  }
}
