import { Grid } from '@mui/material'
import { RequestDetailsWidget } from '../../../../features/RequestDetailsWidget'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

const mocks = [
  {
    id: '1',
    make: 'BMW',
    model: 'X6',
    trim: 'The best',
    years: ['2021', '2022'],
    interiorColors: ['Red', 'Green', 'Blue'],
    exteriorColors: ['Red', 'Green', 'Blue'],
    tradeInPrice: 10000
  },
  {
    id: '2',
    make: 'BMW',
    model: 'X7',
    trim: '',
    years: ['2022'],
    interiorColors: [''],
    exteriorColors: [''],
    tradeInPrice: undefined
  }
]
export const RequestDetailsExample = () => {
  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <Grid container item justifyContent='center' xs={12} spacing={2}>
          <Grid item xs={12} lg={6}>
            <RequestDetailsWidget {...mocks[0]} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <RequestDetailsWidget {...mocks[1]} />
          </Grid>
        </Grid>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
