import { Menu, MenuProps, styled } from '@mui/material'
import { FC, useEffect } from 'react'
import { config } from '../../constants/config'
import { BORDERS, SHADOWS } from '../../types/colors'
import { Renovator } from '../Chat/Renovator/Renovator'
import { useRenovator } from '../Chat/Renovator/useRenovator'
import { useMiniChatReader } from './controller/reader'

type MiniChatContainerProps = Omit<MenuProps, 'children'> & {
  children: (renovator: Renovator) => JSX.Element
}

const MiniChatMenu = styled(Menu)(() => ({
  '& .MuiPaper-root': {
    border: BORDERS.SM_GRAY,
    borderRadius: '4px',
    boxShadow: SHADOWS.hover,
    left: 'auto !important',
    marginTop: '70px',
    maxHeight: 'calc(100vh - 72px)',
    maxWidth: '100%',
    right: '0',
    top: '0 !important',
    width: 476,
  }
}))

export const MiniChatContainer: FC<MiniChatContainerProps> = ({ children, ...props }) => {
  const { getChatList } = useMiniChatReader(false)

  const { renovator } = useRenovator({
    renovateFunction: getChatList,
    renovatePeriod: Number(config.UPDATE_CHATS_PERIOD)
  })

  useEffect(() => {
    renovator.start()

    return () => {
      renovator.stop()
    }
  }, [])

  return (
    <MiniChatMenu
      anchorPosition={{ top: 0, left: 0 }}
      anchorReference='anchorPosition'
      transitionDuration={10}
      {...props}
    >
      {children(renovator)}
    </MiniChatMenu>
  )
}
