import { BADGE_COLORS } from '../../../../../types/colors'
import {
  TransactionStatusTypes,
  VehicleStatusTypes
} from '../../../../../api/carSnoopApi/statusTypes'

export const DealStatusColorsMap: Record<
  TransactionStatusTypes | VehicleStatusTypes,
  keyof typeof BADGE_COLORS
> = {
  'Request Cancelled': 'red',
  'For Sale': 'beige',
  Requested: 'beige',
  'In Request': 'beige',
  Offered: 'beige',
  'In Offer': 'beige',
  'Under Contract': 'beige',
  'Awaiting Payment': 'green',
  Shipping: 'beige',
  Delivered: 'green',
  'Request Declined': 'red',
  'Request Expired': 'red',
  'Offer Cancelled': 'red',
  'Offer Declined': 'red',
  'Offer Expired': 'red',
  'Contract Terminated': 'red',
  'Payment Failed': 'red'
}
