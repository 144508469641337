import { FC, useCallback, useState } from 'react'
import { OfferModal, IOfferModalProps } from '../../../features/OfferModal'
import {
  calculateTotalCost,
  mapOfferData
} from '../../../features/OfferModal/OfferModal.helpers'
import { IOfferModalForm } from '../../../features/OfferModal/OfferModal.types'
import { NormalizedOfferType } from '../../../api/carSnoopApi/transactions/types'
import { ResponseStatusMap } from '../../../types/status'
import { useMakeService } from '../../../utils/hooks'
import { useRequests } from '../hooks/useRequests'
import { IPriceComponent } from '../../../api/carSnoopApi/offers/types'

interface IEditOfferModal
  extends Omit<
    IOfferModalProps,
    'isModalLoading' | 'onConfirm' | 'addons' | 'onRemoveTradeInConfirm'
  > {
  addons: NormalizedOfferType['addons']
  offerId: string
  transactionId: string
  readTransaction: () => void
}

export const EditOfferModal: FC<IEditOfferModal> = ({
  vehicleZipCode,
  vehiclePrice,
  tradeIn,
  isOpen,
  notes,
  addons,
  offerId,
  transactionId,
  salesTax,
  readTransaction,
  includeTradeIn: initialIncludeTradeIn,
  onCloseClick
}) => {
  const { updateOffer, removeTradeIn } = useRequests()
  const [includeTradeIn, setIncludeTradeIn] = useState(initialIncludeTradeIn)

  const handleRemoveTradeIn = useCallback(() => {
    setIncludeTradeIn(false)
  }, [setIncludeTradeIn])

  const handleCloseModal = useCallback(() => {
    setIncludeTradeIn(true)
    onCloseClick()
  }, [setIncludeTradeIn, onCloseClick])

  const [updateOfferRequest] = useMakeService(
    async ({
      formData,
      priceComponents,
      addOns
    }: {
      formData: IOfferModalForm
      priceComponents: IPriceComponent<number>[]
      addOns: IPriceComponent<number>[]
    }) => {
      const updateOfferResponse = await updateOffer({
        id: offerId,
        totalPrice: calculateTotalCost(formData),
        notes: formData.notes,
        priceComponents,
        addOns
      })

      if (updateOfferResponse?.status === ResponseStatusMap.Success) {
        readTransaction()
        onCloseClick()
        return updateOfferResponse
      }

      return updateOfferResponse
    },
    { withStatusNotification: true }
  )

  const [handleUpdateOffer] = useMakeService(
    async (formData: IOfferModalForm) => {
      const { priceComponents, addOns } = mapOfferData(formData, includeTradeIn)

      if (initialIncludeTradeIn && !includeTradeIn) {
        const removeTradeInResponse = await removeTradeIn(transactionId)

        if (removeTradeInResponse?.status === ResponseStatusMap.Success) {
          updateOfferRequest({
            formData,
            priceComponents,
            addOns
          })
        }

        return false
      }

      updateOfferRequest({
        formData,
        priceComponents,
        addOns
      })
    },
    { withStatusNotification: true }
  )

  return (
    <OfferModal
      confirmText='Save'
      title='Edit offer'
      isModalLoading={false}
      withDeclineModal
      vehiclePrice={vehiclePrice}
      tradeIn={tradeIn}
      vehicleZipCode={vehicleZipCode}
      notes={notes}
      isOpen={isOpen}
      includeTradeIn={initialIncludeTradeIn}
      onCloseClick={handleCloseModal}
      onConfirm={handleUpdateOffer}
      addons={addons}
      onRemoveTradeInConfirm={handleRemoveTradeIn}
      salesTax={salesTax}
    />
  )
}
