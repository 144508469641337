import { DependentField } from '../../../../../../../atoms/DependentField'
import {
  MaskedInput,
  MaskedInputFieldProps
} from '../../../../../../../atoms/MaskedInput'

import { FIELDS_NAMES, AddressFieldsTypes } from './types'

export type { MaskedInputFieldProps }

export const DependentZipCodeField = DependentField<
  MaskedInputFieldProps,
  AddressFieldsTypes
>(MaskedInput, {
  deps: [FIELDS_NAMES.ZIP_CODE]
})
