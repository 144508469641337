import { Suspense, lazy } from 'react'

const LazyIcon = lazy(() => import('./Icon'))

export default (): JSX.Element => {
  return (
    <Suspense>
      <LazyIcon />
    </Suspense>
  )
}
