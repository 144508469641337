import { styled } from '@mui/system'
import { BORDERS, COLORS } from '../../types/colors'

export const TextInputFieldContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hasError'
}) <{ hasError: boolean }>`
  display: flex;
  flex-direction: column;

  & > input {
    height: 46px;
    padding-left: 22px;
    border: ${({ hasError }) =>
    hasError ? BORDERS.SM_ERROR : BORDERS.SM_GRAY};
    color: ${({ hasError }) =>
    hasError ? COLORS.text_red : COLORS.text_primary};
    border-radius: 4px;

    &::placeholder {
      color: ${COLORS.text_secondary};
      opacity: 1;
    }
    &:focus {
      color: ${COLORS.text_primary};
      outline: none;
      border: ${BORDERS.SM_BLACK};
    }
  }
`

export const InputErrorMessage = styled('div')`
  font-size: 14px;
  color: ${COLORS.error_message_red};
`
