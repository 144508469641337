import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import isUUID from 'validator/lib/isUUID';
import { useStorage } from './useStorage';

export const SESSION_VEHICLE_KEY = 'sessionVehicle'
export type SessionVehicleState = {
  vehicle_id: string
}

export const useSessionVehicle = () => {
  const {
    clearData: clearSessionVehicle,
    restoredData: sessionVehicle,
    storeData: storeSessionVehicle
  } = useStorage<SessionVehicleState>(SESSION_VEHICLE_KEY);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const vehicle_id = searchParams.get('vid') || '';
    if (isUUID(vehicle_id)) {
      storeSessionVehicle({ vehicle_id })
    }
  }, [searchParams, storeSessionVehicle])

  return { clearSessionVehicle, sessionVehicle };
}
