import { Grid, GridProps } from '@mui/material'
import { FC, PropsWithChildren } from 'react'
import { Condition } from '../../atoms/Condition'
import { Loader } from '../../atoms/Loader'

export const ScreenContainer: FC<
  PropsWithChildren<
    GridProps & { isLoading?: boolean } & { wrapperProps?: GridProps }
  >
> = ({ children, isLoading, wrapperProps, ...props }) => {
  return (
    <Grid
      container
      item
      flexDirection='column'
      flexGrow={1}
      paddingX={{ xs: 3, md: 5 }}
      paddingBottom={{ xs: 3, md: 5 }}
      {...props}
    >
      <Condition
        condition={!!isLoading}
        trueContent={<Loader />}
        falseContent={
          <Grid
            container
            item
            flexGrow={1}
            flexDirection='column'
            {...wrapperProps}
          >
            {children}
          </Grid>
        }
      />
    </Grid>
  )
}
