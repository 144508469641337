import { createAsyncThunk } from '@reduxjs/toolkit'
import { carSnoopApi } from '../../../../api'
import { ReadFindCarFormTradeInParams } from '../../../../api/carSnoopApi/vehicles'
import { initialState } from '../findCarForm'
import type { ReadFindCarFormTradeInResponse } from './types'

export const readFindCarFormTradeIn = createAsyncThunk(
  'readFindCarFormTradeIn',
  async (params: ReadFindCarFormTradeInParams): Promise<ReadFindCarFormTradeInResponse> => {
    const response = await carSnoopApi.vehicles.readFindCarFormTradeIn(params)

    if (response.status >= 200 && response.status < 300) {
      const data = await response.json()

      return {
        status: 'succeeded',
        data
      }
    }

    return {
      status: 'failed',
      data: initialState
    }
  }
)
