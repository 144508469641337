import { DependentField } from '../../../../../../../../atoms/DependentField'
import {
  MaskedInput,
  MaskedInputFieldProps
} from '../../../../../../../../atoms/MaskedInput'
import { BUYER_FIELDS_NAMES, BuyerFormFieldsTypes } from './types'

export type DependentZipCodeFieldProps = MaskedInputFieldProps

export const DependentBuyerZipCodeField = DependentField<
  MaskedInputFieldProps,
  BuyerFormFieldsTypes
>(MaskedInput, {
  deps: [BUYER_FIELDS_NAMES.ZIP_CODE]
})

export const DependentCoBuyerZipCodeField = DependentField<
  MaskedInputFieldProps,
  BuyerFormFieldsTypes
>(MaskedInput, {
  deps: [BUYER_FIELDS_NAMES.CO_BUYER_ZIP_CODE]
})
