import { styled } from '@mui/material'
import { Form } from 'formik'
import { COLORS } from '../../../types/colors'

export const VerificationCodeForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  & > div:last-child {
    margin-bottom: 0;
    margin-top: auto;
  }
`

export const IconContainer = styled('div')`
  margin: 0 auto 16px auto;
`

export const TitleStyles = styled('h2')`
  display: block;
  margin: 0 auto 16px auto;
`

export const SubtitleStyles = styled('div')`
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 16px;
  text-align: center;

  & > h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    line-height: 20px;
  }
`

export const VerificationInputContainer = styled('div')`
  margin: 0 auto 36px auto;
`

export const ResendButton = styled('div')`
  display: flex;
  color: ${COLORS.text_secondary};
  margin: 0 auto 36px auto;
`
