import { FC, useMemo } from 'react'
import { Box, Grid } from '@mui/material'
import { BORDERS } from '../../../../types/colors'
import { EmptyImageIcon } from '../../../../assets/icons/EmptyImage'
import { Condition } from '../../../../atoms/Condition'
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner'
import { DefaultVehicle } from '../../../../assets/imgs/DefaultVehicle'

export interface EmptyImageProps {
  isLoading?: boolean
  isSelected?: boolean
  allowEdit?: boolean
}

export const EmptyImage: FC<EmptyImageProps> = (props) => {
  const { isLoading, isSelected, allowEdit = true } = props
  const border = useMemo(() => {
    return (
      (isSelected && BORDERS.SELECTED_IMAGE) ||
      (allowEdit && BORDERS.EMPTY_IMAGE) ||
      BORDERS.TRANSPARENT_IMAGE
    )
  }, [isSelected, allowEdit])

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        position: 'relative'
      }}
    >
      <Condition
        condition={!!isLoading}
        trueContent={<LoadingSpinner />}
        falseContent={
          <Grid
            container
            sx={{
              border,
              height: '100%'
            }}
            justifyContent='center'
            alignItems='center'
          >
            <Condition
              condition={allowEdit}
              trueContent={<EmptyImageIcon />}
              falseContent={<DefaultVehicle width='100%' height='100%' />}
            />
          </Grid>
        }
      />
    </Box>
  )
}
