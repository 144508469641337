import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { IBreadcrumbItem } from '../../atoms/Breadcrumbs'
import { breadcrumbsActions } from '../../store/features/Breadcrumbs'

export const useBreadcrumbs = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    pushBreadcrumb: pushBreadcrumbAction,
    popBreadcrumb: popBreadcrumbAction,
    setBreadcrumbs: setBreadcrumbsAction
  } = breadcrumbsActions

  const navigateForward = (route: string, breadcrumb: IBreadcrumbItem) => {
    dispatch(pushBreadcrumbAction(breadcrumb))
    navigate(route)
  }
  const navigateBack = (route: string) => {
    dispatch(popBreadcrumbAction())
    navigate(route)
  }
  const resetBreadcrumbs = () => {
    dispatch(setBreadcrumbsAction([]))
  }
  const pushBreadcrumb = (breadcrumb: IBreadcrumbItem) => {
    dispatch(pushBreadcrumbAction(breadcrumb))
  }
  const popBreadcrumb = () => {
    dispatch(popBreadcrumbAction())
  }
  const setBreadcrumbs = (breadcrumbs: IBreadcrumbItem[]) => {
    dispatch(setBreadcrumbsAction(breadcrumbs))
  }

  return {
    popBreadcrumb,
    setBreadcrumbs,
    pushBreadcrumb,
    resetBreadcrumbs,
    navigateForward,
    navigateBack
  }
}
