import { Grid, Typography } from '@mui/material'
import { Form, Formik, FormikProps } from 'formik'
import { FC, useCallback, useContext, useEffect, useRef } from 'react'
import { ApiContext } from '../../../../../../../apiContext'
import { DeleteIcon } from '../../../../../../../assets/icons/Delete'
import {
  AgreeButton,
  CancelButton,
  RedButton
} from '../../../../../../../atoms/Button'
import { Condition } from '../../../../../../../atoms/Condition'
import { DependentFieldHocEffect } from '../../../../../../../atoms/DependentField'
import { RequiredLabel } from '../../../../../../../atoms/RequiredText'
import { TextInputFieldNew } from '../../../../../../../atoms/TextInputFieldNew'
import { zipCodeMask } from '../../../../../../../constants/regexp'
import type { Address } from '../../../../../../../store/types'
import { selectEditingAddress } from '../../../../../../../store/units/editingAddress'
import { ResponseStatusMap } from '../../../../../../../types/status'
import {
  useAppSelector,
  useMakeService
} from '../../../../../../../utils/hooks'
import { isValidForm } from '../../../../../../../utils/isValidForm'
import { MODES } from '../../../../constants'
import { DependentZipCodeField, MaskedInputFieldProps } from './DependentFields'
import {
  AddressFieldsTypes,
  FIELDS_ERRORS,
  FIELDS_LABELS,
  FIELDS_NAMES
} from './types'
import { validationSchema } from './validationSchema'

export interface EditModeProps {
  mode: 'edit' | 'create'
  address: Address
  canBeDeleted?: boolean
  onSave: (values: Address) => void
  onCancel: () => void
  onDelete?: () => void
  updateAddressError: boolean
}

export const EditMode: FC<EditModeProps> = (props) => {
  const {
    mode,
    address,
    canBeDeleted,
    onSave,
    onCancel,
    onDelete,
    updateAddressError
  } = props
  const { editingAddress } = useAppSelector(selectEditingAddress)
  const { nickname, street, zipCode, city, state, id } =
    editingAddress || address
  const { externalCarSnoopApi } = useContext(ApiContext)
  const formikRef = useRef<FormikProps<AddressFieldsTypes>>()
  const [checkZipCode, checkZipCodeObservable] = useMakeService(
    async (zip: string) => {
      const response = await externalCarSnoopApi.addresses.validateZipCode(zip)

      if (response.status === ResponseStatusMap.Success) {
        formikRef.current?.setValues(
          {
            ...formikRef.current.values,
            [FIELDS_NAMES.CITY]: response.city,
            [FIELDS_NAMES.STATE]: response.state
          },
          true
        )

        return {
          status: response.status,
          city: response.city,
          state: response.state
        }
      }
      formikRef.current?.validateForm().then(() => {
        formikRef.current?.setFieldError(
          FIELDS_NAMES.ZIP_CODE,
          FIELDS_ERRORS.ZIP_CODE
        )
      })

      return response
    }
  )

  const zipCodeFieldEffect: DependentFieldHocEffect<
    MaskedInputFieldProps,
    AddressFieldsTypes
  > = useCallback(
    (_, ctx) => {
      const { values, setFieldValue } = ctx
      const { zipCode: zipCodeValue } = values

      setFieldValue(FIELDS_NAMES.CITY, '')
      setFieldValue(FIELDS_NAMES.STATE, '')

      zipCodeValue && checkZipCode(zipCodeValue)
    },
    [checkZipCode]
  )

  useEffect(() => {
    if (updateAddressError) {
      formikRef.current?.validateForm().then(() => {
        formikRef.current?.setErrors({
          [FIELDS_NAMES.STREET]: ' ',
          [FIELDS_NAMES.ZIP_CODE]: FIELDS_ERRORS.CHECK_ADDRESS
        })
      })
    }
  }, [updateAddressError, formikRef])

  const isEditMode = mode === MODES.EDIT
  const isCreateMode = !isEditMode
  const onSubmit = useCallback((values: AddressFieldsTypes) => {
    onSave({ ...values, id })
  }, [])
  return (
    <Formik<AddressFieldsTypes>
      initialValues={{
        nickname: nickname || '',
        street: street || '',
        zipCode: zipCode || '',
        city: city || '',
        state: state || ''
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnMount
      validateOnChange={false}
    >
      {(formik) => {
        formikRef.current = formik

        return (
          <Form>
            <Grid container spacing={3}>
              <Grid container item spacing='16px'>
                <Condition
                  condition={isCreateMode}
                  trueContent={
                    <Grid item xs={12}>
                      <Typography variant='label1'>Add Address</Typography>
                    </Grid>
                  }
                />
                <Grid item xs={12}>
                  <RequiredLabel />
                </Grid>

                <Grid item xs={12}>
                  <TextInputFieldNew
                    name={FIELDS_NAMES.NAME}
                    label={FIELDS_LABELS.NAME}
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextInputFieldNew
                    name={FIELDS_NAMES.STREET}
                    label={FIELDS_LABELS.STREET}
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <DependentZipCodeField
                    name={FIELDS_NAMES.ZIP_CODE}
                    label={FIELDS_LABELS.ZIP_CODE}
                    onDoneEffect={zipCodeFieldEffect}
                    mask={zipCodeMask}
                    required
                  />
                </Grid>

                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={6}>
                    <TextInputFieldNew
                      name={FIELDS_NAMES.CITY}
                      label={FIELDS_LABELS.CITY}
                      disabled
                      isLoading={checkZipCodeObservable.isLoading}
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInputFieldNew
                      name={FIELDS_NAMES.STATE}
                      label={FIELDS_LABELS.STATE}
                      disabled
                      isLoading={checkZipCodeObservable.isLoading}
                      required
                    />
                  </Grid>
                </Grid>
                <Condition
                  condition={isEditMode && !!canBeDeleted}
                  trueContent={
                    <Grid item xs={12}>
                      <RedButton onClick={onDelete} startIcon={<DeleteIcon />}>
                        <Typography variant='main'>Delete Address</Typography>
                      </RedButton>
                    </Grid>
                  }
                />
              </Grid>
              <Grid
                container
                item
                xs={12}
                justifyContent='flex-end'
                spacing={2}
              >
                <Grid item>
                  <CancelButton onClick={onCancel}>Cancel</CancelButton>
                </Grid>
                <Grid item>
                  <AgreeButton
                    type='submit'
                    disabled={
                      !isValidForm(formik) || checkZipCodeObservable.isLoading
                    }
                  >
                    Save
                  </AgreeButton>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}
