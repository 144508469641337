import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { FC, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReadRequestsResponse } from '../../../../api/carSnoopApi/requests/types'
import { NoMatchesImage } from '../../../../assets/imgs/NoMatches/intex'
import { Accordion } from '../../../../atoms/Accordion'
import { BlueButton } from '../../../../atoms/Button'
import { Divider } from '../../../../atoms/Divider'
import { EmptyState } from '../../../../atoms/ItemList'
import { For, Show } from '../../../../atoms/JSXExtensions'
import { Loader } from '../../../../atoms/Loader'
import { routes } from '../../../../routes'
import { inProgressDealStatuses } from '../../../../store/pages/Deals'
import { OrNull } from '../../../../types/OrNull'
import { closedRequestsStatuses } from '../../constants'
import { ListHeaderDesktop, ListHeaderTablet } from './components/ListHeader'
import { RequestBody } from './components/RequestBody'
import { RequestHeader } from './components/RequestHeader'

interface RequestsTabProps {
  requests: OrNull<ReadRequestsResponse.Normilized.ReadRequestsResponse[]>
  onCancelRequest?: (id: string) => Promise<unknown>
  isLoading: boolean
}

const getRequestsText = (length: number) =>
  length > 1 ? 'Open requests' : 'Open request'

const getTradeinValue =
  (request: ReadRequestsResponse.Normilized.ReadRequestsResponse) => () => {
    const tabletView = request.tradeInVehicle
      ? [
        [request.tradeInVehicle.year, request.tradeInVehicle.make].join(' '),
        [request.tradeInVehicle.model, request.tradeInVehicle.trim]
          .filter(Boolean)
          .join(' ')
      ]
      : ['Not included']

    const desktopView = request.tradeInVehicle
      ? [
        request.tradeInVehicle.year,
        request.tradeInVehicle.make,
        request.tradeInVehicle.model,
        request.tradeInVehicle.trim
      ]
        .filter(Boolean)
        .join(' ')
      : 'Not included'
    return {
      tablet: tabletView,
      desktop: desktopView
    }
  }
const filterOpenTransactions = (
  transactions: ReadRequestsResponse.Normilized.ReadRequestsResponse_Transaction[]
) => transactions.filter((t) => !closedRequestsStatuses.includes(t.status))

export const RequestsTab: FC<RequestsTabProps> = ({
  requests,
  onCancelRequest,
  isLoading
}) => {
  const theme = useTheme();
  const isBreakpointDownLg = useMediaQuery(theme.breakpoints.down('lg'));
  const navigate = useNavigate()

  const checkRequestInProgress = useCallback(
    (request: ReadRequestsResponse.Normilized.ReadRequestsResponse) => {
      return request.transactions.some((transaction) =>
        inProgressDealStatuses.includes(transaction.status)
      )
    },
    []
  )
  const handleCreateRequest = useCallback(
    () => navigate(routes.carSearch),
    [navigate]
  )

  return (
    <Show
      when={!isLoading && requests}
      fallback={
        <Grid container justifyContent='center'>
          <Loader />
        </Grid>
      }
    >
      {(req) => (
        <Show
          when={req.length > 0}
          fallback={
            <EmptyState
              title='The deals list is empty.'
              image={<NoMatchesImage />}
              sx={{ mt: 2 }}
              onAcceptButtonClick={handleCreateRequest}
              acceptButtonText='Create request'
            />
          }
        >
          <Grid container gap={1}>
            <Grid container item
              alignItems='center'
              justifyContent='space-between'
              xs={12}
            >
              <Grid item>
                <Typography variant='h6'>
                  {req.length} {getRequestsText(req.length)}
                </Typography>
              </Grid>
              <Grid item>
                <BlueButton
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={handleCreateRequest}
                >
                  Add Request
                </BlueButton>
              </Grid>
            </Grid>
            <Grid item xs={12} mt={3} ml={4}>
              <Show when={isBreakpointDownLg} fallback={<ListHeaderDesktop />}>
                <Grid container>
                  <Grid item xs={11}>
                    <ListHeaderTablet />
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
              </Show>
            </Grid>
            <Grid item container>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <For of={req}>
                    {(request) => (
                      <>
                        <Accordion
                          key={`accordion-${request.id}`}
                          // https://github.com/mui/material-ui/issues/35144
                          TransitionProps={{
                            unmountOnExit: true,
                            mountOnEnter: true
                          }}
                        >
                          <RequestHeader
                            request={request}
                            getTradeinValue={getTradeinValue(request)}
                            isBuyingFlow
                            disableCancel={checkRequestInProgress(request)}
                            onCancelClick={onCancelRequest}
                          />
                          <RequestBody
                            deals={filterOpenTransactions(request.transactions)}
                          />
                        </Accordion>
                        <Divider />
                      </>
                    )}
                  </For>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Show>
      )}
    </Show>
  )
}
