import * as Yup from 'yup'
import {
  countOfNumbers,
  latinWithSpecial,
  notOnlyZeroes
} from '../../../../../../../utils/validators'
import { FIELDS_ERRORS, FIELDS_NAMES } from './types'

export const validationSchema = Yup.object({
  [FIELDS_NAMES.NAME]: Yup.string()
    .required('')
    .trim()
    .test('is-latin-or-special', FIELDS_ERRORS.NAME, latinWithSpecial([' '])),
  [FIELDS_NAMES.STREET]: Yup.string()
    .required('')
    .trim()
    .test('is-latin-or-special', FIELDS_ERRORS.STREET, latinWithSpecial([' ']))
    .nullable(),
  [FIELDS_NAMES.ZIP_CODE]: Yup.string()
    .required('')
    .trim()
    .test('length', FIELDS_ERRORS.ZIP_CODE, countOfNumbers(5))
    .test('not-only-zeroes', 'Enter a valid Zip Code.', notOnlyZeroes()),
  [FIELDS_NAMES.CITY]: Yup.string().required('').nullable(),
  [FIELDS_NAMES.STATE]: Yup.string().required('').nullable()
})
