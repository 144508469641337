import { FC, useState } from 'react'
import { ErrorMessage, Field, FieldProps, FieldValidator } from 'formik'
import IconButton from '@mui/material/IconButton'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'
import { Label } from '../Label'
import { Input } from './BaseInput'
import { Condition } from '../Condition'
import {
  ErrorMessageContainer,
  PasswordInputFieldContainer
} from './PasswordInputField.styles'

type PasswordInputFieldProps = {
  name: string
  label: string
  placeholder: string
  isValid?: boolean
  isErrorMessageRequired?: boolean
  onInput?: () => void
  autoComplete?: string
  validate?: FieldValidator
}

export const PasswordInputField: FC<PasswordInputFieldProps> = ({
  name,
  label,
  placeholder,
  isValid,
  isErrorMessageRequired = true,
  onInput,
  autoComplete = 'none',
  validate
}) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <PasswordInputFieldContainer>
      <Label name={name} label={label} />
      <Field
        id={name}
        name={name}
        placeholder={placeholder}
        validate={validate}
      >
        {(props: FieldProps) => {
          return (
            <FormControl variant='outlined' fullWidth>
              <Input
                error={!isValid}
                inputProps={{
                  ...props.field,
                  autoComplete,
                  placeholder,
                  onFocus: () => {
                    props.form.setTouched({
                      ...props.form.touched,
                      [props.field.name]: false
                    })
                  },
                  onInput: () => onInput && onInput()
                }}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowPassword(!showPassword)}
                      edge='end'
                    >
                      {showPassword ? (
                        <Visibility color={isValid ? 'inherit' : 'error'} />
                      ) : (
                        <VisibilityOff color={isValid ? 'inherit' : 'error'} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          )
        }}
      </Field>
      <Condition
        condition={isErrorMessageRequired}
        trueContent={
          <ErrorMessageContainer>
            <ErrorMessage name={name} />
          </ErrorMessageContainer>
        }
      />
    </PasswordInputFieldContainer>
  )
}
