export const fromAdmin = (fullName: string) =>
  `Would you like to remove admin permission for ${fullName}?`

export const toAdmin = (fullName: string) =>
  `Would you like to make ${fullName} an admin of the account?`

export const deleteUserConfirmationText = {
  message: 'Are you sure you would like to delete this user?',
  hint: 'Deleted user cannot be recovered. We will also notify the user that their account has been removed.'
}
