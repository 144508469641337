import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  ReadCarByYearTrimResponse,
  ReadCarModelYearTrimParams
} from '../../../../api/carSnoopApi/vehicles'
import { apiContextValues } from '../../../../apiContext'
import { ResponseStatusMap } from '../../../../types/status'
import { formatEmptyObjectToNull } from '../../../../utils/formatters/formatEmptyObjectToNull'

export const readCarModelYearTrimFromVehicles = createAsyncThunk(
  'readCarModel/readCarModelYearTrim',
  async ({
    params,
    apiContext
  }: {
    params: ReadCarModelYearTrimParams
    apiContext: typeof apiContextValues
  }): Promise<{ status: string; data: ReadCarByYearTrimResponse }> => {
    const { carSnoopApi } = apiContext

    const response = await carSnoopApi.vehicles.readCarModelYearTrimFromVehicles(params)

    if (response.status === ResponseStatusMap.Success) {
      return {
        status: 'succeeded',
        data: {
          ...response.data,
          standardEquipment: formatEmptyObjectToNull(
            response.data.standardEquipment
          )
        }
      }
    }

    throw new Error()
  }
)
