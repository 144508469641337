import { ERROR_REASONS } from '../../../types/enums/network'
import { ResponseStatusMap } from '../../../types/status'
import { ErrorResponse, SuccessResponse } from '../../private.types'
import { Utils } from '../../utils'
import {
  CompleteContractError,
  CompleteContractSuccess,
  CompleteContractApiSuccess,
  CompleteContractApiError,
  TerminateContractError,
  TerminateContractSuccess,
  CreateContractParams,
  CreateContractSuccess,
  CreateContractError,
  CreateContractApiRequest,
  UpdateContractParams,
  UpdateContractApiRequest
} from './types'
import { serializeDealSideDetails } from './utils/dataSerializer'

export class Contracts {
  protected readonly utils: Utils
  protected readonly operations = {
    completeContract: 'completeContract',
    terminateContract: 'terminateContract',
    createContract: 'createContract',
    updateContract: 'updateContract'
  }

  constructor(utils: Utils) {
    this.utils = utils
  }

  async completeContract(
    id: string
  ): Promise<
    | SuccessResponse<CompleteContractSuccess>
    | ErrorResponse<CompleteContractError>
  > {
    const dataToSend = {
      transaction_id: id
    }
    try {
      const { type, payload } = await this.utils.makeJSONRequest<
        CompleteContractApiSuccess,
        CompleteContractApiError
      >(this.operations.completeContract, dataToSend)

      if (type === ResponseStatusMap.Success) {
        return {
          status: ResponseStatusMap.Success,
          message: payload.message
        }
      }

      return {
        status: ResponseStatusMap.Error,
        message: payload.message,
        isSubscriptionError:
          payload.reason === ERROR_REASONS.INVALID_SUBSCRIPTION
      }
    } catch (e) {
      return {
        status: ResponseStatusMap.Error,
        message: 'Something went wrong',
        isSubscriptionError: false
      }
    }
  }

  async terminateContract(
    id: string
  ): Promise<
    | SuccessResponse<TerminateContractSuccess>
    | ErrorResponse<TerminateContractSuccess>
  > {
    const dataToSend = {
      transaction_id: id
    }
    try {
      const { type, payload } = await this.utils.makeJSONRequest<
        SuccessResponse<TerminateContractSuccess>,
        ErrorResponse<TerminateContractError>
      >(this.operations.terminateContract, dataToSend)

      if (type === ResponseStatusMap.Success) {
        return {
          status: ResponseStatusMap.Success,
          message: payload.message
        }
      }

      return {
        status: ResponseStatusMap.Error,
        message: payload.message
      }
    } catch (e) {
      return {
        status: ResponseStatusMap.Error,
        message: 'Something went wrong'
      }
    }
  }

  async createContract(
    params: CreateContractParams
  ): Promise<
    SuccessResponse<CreateContractSuccess> | ErrorResponse<CreateContractError>
  > {
    const { buyerDetails, additionalBuyerDetails: coBuyer } = params

    const additionalBuyerDetails = coBuyer
      ? {
          legal_name: coBuyer.legalName,
          legal_street: coBuyer.legalStreet,
          legal_city: coBuyer.legalCity,
          legal_state: coBuyer.legalState,
          legal_zip_code: coBuyer.legalZipCode
        }
      : null

    const dataToSend: CreateContractApiRequest = {
      transaction_id: params.transactionId,
      primary_buyer: params.primaryBuyer,
      buyer_details: {
        legal_name: buyerDetails.legalName,
        legal_street: buyerDetails.legalStreet,
        legal_city: buyerDetails.legalCity,
        legal_state: buyerDetails.legalState,
        legal_zip_code: buyerDetails.legalZipCode
      },
      additional_buyer: params.additionalBuyer,
      additional_buyer_details: additionalBuyerDetails,
      payment_method: params.paymentMethod,
      notes: params.notes
    }

    try {
      const { type, payload } = await this.utils.makeJSONRequest<
        SuccessResponse<CreateContractSuccess>,
        ErrorResponse<CreateContractError>
      >(this.operations.createContract, dataToSend)

      if (type === ResponseStatusMap.Success) {
        return {
          status: ResponseStatusMap.Success,
          message: payload.message
        }
      }

      return {
        status: ResponseStatusMap.Error,
        message: payload.message
      }
    } catch (e) {
      return {
        status: ResponseStatusMap.Error,
        message: 'Something went wrong'
      }
    }
  }

  async updateContract(
    params: UpdateContractParams
  ): Promise<
    SuccessResponse<CreateContractSuccess> | ErrorResponse<CreateContractError>
  > {
    const { additionalBuyerDetails: coBuyer } = params

    const additionalBuyerDetails = coBuyer
      ? serializeDealSideDetails(coBuyer)
      : null

    const dataToSend: UpdateContractApiRequest = {
      id: params.id,
      additional_buyer_details: additionalBuyerDetails,
      notes: params.notes,
      buyer_details: serializeDealSideDetails(params.buyerDetails),
      seller_details: serializeDealSideDetails(params.sellerDetails)
    }

    try {
      const { type, payload } = await this.utils.makeJSONRequest<
        SuccessResponse<CreateContractSuccess>,
        ErrorResponse<CreateContractError>
      >(this.operations.updateContract, dataToSend)

      if (type === ResponseStatusMap.Success) {
        return {
          status: ResponseStatusMap.Success,
          message: payload.message
        }
      }

      return {
        status: ResponseStatusMap.Error,
        message: payload.message
      }
    } catch (e) {
      return {
        status: ResponseStatusMap.Error,
        message: 'Something went wrong'
      }
    }
  }
}
