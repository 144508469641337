import { FC } from 'react'
import { GearIcon } from '../../assets/icons/GearIcon'
import { IconButtonStyles } from './SettingButton.styles'

export const SettingButton: FC = () => {
  return (
    <IconButtonStyles size='small'>
      <GearIcon />
    </IconButtonStyles>
  )
}
