import { ERROR_REASONS } from '../../../types/enums/network'
import { ResponseStatusMap } from '../../../types/status'
import { Utils } from '../../utils'
import { SubmitRequestFeeCheckoutType } from './types'

export class Requests {
  protected readonly utils: Utils
  protected readonly operations = {
    submitRequestFeeCheckout: 'submitRequestFeeCheckout'
  }

  constructor(utils: Utils) {
    this.utils = utils
  }

  async submitRequestFeeCheckout(
    params: SubmitRequestFeeCheckoutType.Params
  ): Promise<SubmitRequestFeeCheckoutType.MethodResponse> {
    type SuccessResponse = SubmitRequestFeeCheckoutType.ApiSuccessResponse
    type ErrorResponse = SubmitRequestFeeCheckoutType.ApiErrorResponse

    const { successUrl, cancelUrl } = params

    const dataToSend = {
      success_url: successUrl,
      cancel_url: cancelUrl
    }

    const { type, payload } = await this.utils.makeJSONRequest<
      SuccessResponse,
      ErrorResponse
    >(this.operations.submitRequestFeeCheckout, dataToSend)

    if (type === ResponseStatusMap.Success) {
      const { payment_url: paymentUrl, session_id: sessionId } = payload
      return {
        status: ResponseStatusMap.Success,
        paymentUrl,
        sessionId
      }
    }

    const { id, message } = payload
    return {
      status: ResponseStatusMap.Error,
      id,
      message
    }
  }
}
