import { Grid } from '@mui/material'
import { FC, useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import isUUID from 'validator/lib/isUUID'
import { FindCarFormStepper } from '../../atoms/FindCarFormStepper'
import { Show } from '../../atoms/JSXExtensions'
import { Loader } from '../../atoms/Loader'
import { PaymentRedirect } from '../../features/PaymentRedirect'
import { DiscardConfirmationModal } from '../../molecules/Modal'
import { useAddVehicleFormSlice } from '../../routes/AddVehicle/screens/hooks'
import { useUserDashboardStatistic } from '../../routes/MainPage/Dashboard/StatisticCard/hooks/useUserDashboardStatistic'
import { actionsUserInfo } from '../../store/features/userInfo'
import {
  actionsFindCarForm,
  selectBasicInfoScreenData,
  selectFindCarFormState,
  selectFindCarFormStep,
  selectSelectedVehiclesData,
  selectTradeInInfoScreenData
} from '../../store/units/findCarForm'
import { readFindCarFormTradeIn } from '../../store/units/findCarForm/thunks'
import { NonNullableRecord } from '../../types/NonNullableRecord'
import { FIND_CAR_STEPS } from '../../types/enums/findCar'
import { BasicInfo } from '../../types/forms/findCar'
import { ResponseStatusMap } from '../../types/status'
import {
  useApiContext,
  useAppDispatch,
  useAppSelector,
  useMakeService,
  useModal,
  useSessionVehicle,
  useUserInfo
} from '../../utils/hooks'
import { BuildCarPreviewScreenContainer as BuildCarPreviewScreen } from '../screens/BuildCarPreviewScreen'
import { BuildCarPreviewVerificationModal } from '../screens/BuildCarPreviewScreen/components/BuildCarPreviewModal'
import { useBuildCarPreviewInfo } from '../screens/BuildCarPreviewScreen/hooks/useBuildCarPreviewInfo'
import { FindCarBasicInfoScreen } from '../screens/FindCarBasicInfo'
import { FindCarResultScreen } from '../screens/FindCarResultScreen'
import { FindCarSelectScreen } from '../screens/FindCarSelect'
import { FindCarTradeInScreen } from '../screens/FindCarTradeInScreen'
import { formSteps } from './constants'
import { RESULT_PARAM, useRestoreFindCarForm } from './hooks'

interface IFindCarFormProps {
  onCancelSearch?: () => void
}

export const FindCarForm: FC<IFindCarFormProps> = ({ onCancelSearch }) => {
  const dispatch = useAppDispatch()
  const { closeAddVehicleModal, isAddVehicleModalOpen } =
    useAddVehicleFormSlice()

  const screenStep = useAppSelector(selectFindCarFormStep)
  const basicInfoScreenData = useAppSelector(selectBasicInfoScreenData)
  const tradeInInfoScreenData = useAppSelector(selectTradeInInfoScreenData)
  const selectedVehiclesData = useAppSelector(selectSelectedVehiclesData)
  const selectFindCarFormData = useAppSelector(selectFindCarFormState)

  const [isDiscardConfirmationOpen, setIsDiscardConfirmationOpen] =
    useState(false)

  const [params] = useSearchParams()
  const showResultScreen = params.has(RESULT_PARAM)

  const { clearSessionVehicle, sessionVehicle } = useSessionVehicle()

  const {
    isPaymentOpen,
    isRestoring,
    restoredData,
    storeData,
    clearData,
    getPaymentRedirectUrl,
    handleRedirectFail,
    redirectToPayment
  } = useRestoreFindCarForm()

  const { accountID, submitRequestFeePaid, isDealer } = useUserInfo()
  const { carSnoopApi } = useApiContext()
  const { getAccountStatistics } = useUserDashboardStatistic()
  const { selectedCars, tradeinVehicle, requestedVehicle } =
    useBuildCarPreviewInfo()
  const handleRedirectConfirm = useCallback(async () => {
    storeData(selectFindCarFormData)
    redirectToPayment()
  }, [selectFindCarFormData, redirectToPayment, storeData])
  const verificationModal = useModal(handleRedirectConfirm)

  const isVerificationFeePaid = isDealer || submitRequestFeePaid

  const [submitRequest, submitRequestObservable] = useMakeService(
    async (requestId: string) => {
      const res = await carSnoopApi.requests.submitRequest({
        id: requestId,
        vehicles: selectedCars.map((v) => ({
          id: v.id,
          accountId: v.accountId
        }))
      })

      if (res.status === ResponseStatusMap.Success) {
        await getAccountStatistics()
        return res
      }
      return { status: ResponseStatusMap.Error }
    },
    {
      withStatusNotification: true
    }
  )

  const [createRequest, createRequestObservable] = useMakeService(
    async () => {
      const res = await carSnoopApi.requests.createRequest({
        accountId: accountID,
        tradeinVehicle: tradeinVehicle?.id || '',
        make: requestedVehicle.make,
        model: requestedVehicle.model,
        year: requestedVehicle.years.join(', '),
        trim: requestedVehicle.trim || '',
        exteriorColor: requestedVehicle.exteriorColors
          .map((c) => c.id)
          .join(', '),
        interiorColor: requestedVehicle.interiorColors
          .map((c) => c.id)
          .join(', '),
        notes: requestedVehicle.notes,
        deliverToAddressId: requestedVehicle.deliverToAddressId
      })

      if (res.status === ResponseStatusMap.Success) {
        const { id: requestId } = res
        return submitRequest(requestId)
      }
      return { status: ResponseStatusMap.Error }
    },
    {
      withStatusNotification: true
    }
  )

  const [readAccountService, readAccountObservable] = useMakeService(
    async (id: string) => {
      const response = await carSnoopApi.accounts.readAccount(id)

      if (response.status === ResponseStatusMap.Success) {
        dispatch(
          actionsUserInfo.setSubmitRequestFeePaid({
            submitRequestFeePaid: response.submitRequestFeePaid
          })
        )
        if (!response.submitRequestFeePaid) {
          verificationModal.onOpenHandler()
        } else {
          dispatch(actionsFindCarForm.incrementScreenStep())
        }
      }
      return response
    }
  )

  useLayoutEffect(() => {
    if (accountID && sessionVehicle && isUUID(sessionVehicle.vehicle_id)) {
      dispatch(readFindCarFormTradeIn({ account_id: accountID, vehicle_id: sessionVehicle.vehicle_id }))
      clearSessionVehicle()
    }
  }, [accountID, sessionVehicle])

  useLayoutEffect(() => {
    if (restoredData) {
      dispatch(actionsFindCarForm.setFindCarFormState(restoredData))
    }

    return () => clearData()
  }, [restoredData])

  useEffect(() => {
    if (showResultScreen && restoredData) {
      dispatch(actionsFindCarForm.setScreenStep(FIND_CAR_STEPS.stepFive))
    }
  }, [showResultScreen, restoredData])

  const onSubmitClick = useCallback(() => {
    if (isVerificationFeePaid) {
      dispatch(actionsFindCarForm.incrementScreenStep())
    } else {
      readAccountService(accountID)
    }
  }, [isVerificationFeePaid, dispatch, readAccountService, accountID])

  const handleCancelClick = useCallback(() => {
    setIsDiscardConfirmationOpen(true)
  }, [setIsDiscardConfirmationOpen])

  const handleDiscard = useCallback(() => {
    if (isAddVehicleModalOpen) {
      return closeAddVehicleModal()
    }
    dispatch(actionsFindCarForm.resetState())
    onCancelSearch && onCancelSearch()
  }, [isAddVehicleModalOpen, closeAddVehicleModal])

  const screenFlow = [
    <FindCarBasicInfoScreen
      initialValues={basicInfoScreenData}
      actions={actionsFindCarForm}
    />,
    <FindCarSelectScreen
      initialValues={selectedVehiclesData}
      actions={actionsFindCarForm}
      dependentData={basicInfoScreenData as NonNullableRecord<BasicInfo>}
      onCancel={handleCancelClick}
    />,
    <FindCarTradeInScreen
      initialValues={tradeInInfoScreenData}
      actions={actionsFindCarForm}
      onCancel={handleCancelClick}
    />,
    <BuildCarPreviewScreen
      isLoading={
        createRequestObservable.isLoading || readAccountObservable.isLoading
      }
      onCancel={handleCancelClick}
      onSubmit={onSubmitClick}
      actions={actionsFindCarForm}
    />,
    <FindCarResultScreen
      onMountRequest={createRequest}
      isLoading={
        !createRequestObservable.isError && !createRequestObservable.isSuccess
      }
      isError={
        createRequestObservable.isError || submitRequestObservable.isError
      }
      actions={actionsFindCarForm}
    />
  ]

  return (
    <Show
      when={!isRestoring}
      fallback={
        <Grid container justifyContent='center'>
          <Loader />
        </Grid>
      }
    >
      <Grid container alignContent='baseline' flexDirection='column'>
        <Grid
          item
          sx={{
            pb: 3,
            width: '100%'
          }}
        >
          <DiscardConfirmationModal
            isOpen={isDiscardConfirmationOpen}
            setOpen={setIsDiscardConfirmationOpen}
            onSubmit={handleDiscard}
          />
          <BuildCarPreviewVerificationModal {...verificationModal} />
          <PaymentRedirect
            open={isPaymentOpen}
            onRedirectFail={handleRedirectFail}
            getPaymentRedirectUrl={getPaymentRedirectUrl}
          />
          <FindCarFormStepper
            steps={formSteps}
            currentStep={screenStep}
            showAllSteps
          />
        </Grid>
        {screenFlow[screenStep]}
      </Grid>
    </Show>
  )
}
