import { FC, memo, ChangeEvent } from 'react'
import { Grid, Typography } from '@mui/material'
import { ActionBlock } from '../../../../../../../atoms/ActionBlock'
import { RadioGroup } from '../../../../../../../atoms/RadioGroup'
import { useUserInfo } from '../../../../../../../utils/hooks'
import { shippingOptions } from '../../BuyerStripeAwaitingPayment/state.constants'
import { ShipmentTypes } from '../../../../../../../constants/applicationConstants'

interface IChooseShipmentTypeStep {
  mt?: string
  shipmentType: string
  deliveryAdditionalInfo?: JSX.Element | null
  onShipmentTypeChange: (type: ShipmentTypes) => void
  onContinueClick: () => void
  disableContinue?: boolean
  buttonLabel: string
}

export const ChooseShipmentTypeStep: FC<IChooseShipmentTypeStep> = memo(
  ({
    mt = '',
    shipmentType,
    deliveryAdditionalInfo = null,
    disableContinue = false,
    onContinueClick,
    onShipmentTypeChange,
    buttonLabel
  }) => {
    const { isAdmin } = useUserInfo()
    const handleChange = (
      _: ChangeEvent<HTMLInputElement>,
      nextValue: string
    ) => {
      onShipmentTypeChange(nextValue as ShipmentTypes)
    }

    return (
      <Grid container mt={mt} flexDirection='column'>
        <Grid item mb='36px'>
          <Typography>
            Please, select the way you want to ship the vehicle
          </Typography>
        </Grid>
        <Grid item mb='36px'>
          <RadioGroup
            withBold
            name='shipping-type'
            options={shippingOptions}
            value={shipmentType}
            onChange={handleChange}
          />
        </Grid>
        {!!deliveryAdditionalInfo && (
          <Grid item mb='36px'>
            {deliveryAdditionalInfo}
          </Grid>
        )}
        <Grid item width='100%'>
          <ActionBlock
            schema={[
              {
                label: buttonLabel,
                type: 'primary',
                props: {
                  disabled: disableContinue || !isAdmin,
                  onClick: onContinueClick
                }
              }
            ]}
          />
        </Grid>
      </Grid>
    )
  }
)
