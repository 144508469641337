import { Grid, Typography } from '@mui/material'

import { InputField } from '../../../../atoms/InputField/InputField'
import { ComponentContainer } from '../../ComponentsPreviewPage/components/ComponentContainer'
import { ComponentWithPaperBackground } from '../../ComponentsPreviewPage/components/ComponentWithPaperBackground'

export const InputFieldExample = () => {
  return (
    <ComponentContainer>
      <ComponentWithPaperBackground>
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
          rowSpacing={5}
        >
          <Grid item>
            <Typography variant='h6' gutterBottom>
              Default
            </Typography>
            <InputField name='car' placeholder='Honda' label='Make' />
          </Grid>
          <Grid item>
            <Typography variant='h6' gutterBottom>
              Disabled
            </Typography>
            <InputField
              name='name'
              placeholder='placeholder'
              label='label'
              disabled
            />
          </Grid>
          <Grid item>
            <Typography variant='h6' gutterBottom>
              Error
            </Typography>
            <InputField
              error
              name='name'
              placeholder='placeholder'
              label='label'
              value='Привет'
            />
          </Grid>
          <Grid item>
            <Typography variant='h6' gutterBottom>
              Loading
            </Typography>
            <InputField
              name='name'
              placeholder='placeholder'
              label='label'
              value='Привет'
              isLoading
            />
          </Grid>
        </Grid>
      </ComponentWithPaperBackground>
    </ComponentContainer>
  )
}
