import { FC, PropsWithChildren } from 'react'
import { Box, Modal as MUIModal, SxProps } from '@mui/material'

export type ModalProps = {
  open: boolean
  onClose?: () => void
  sx?: SxProps
} & PropsWithChildren

export const Modal: FC<ModalProps> = (props) => {
  const { open, children, onClose, sx = {} } = props

  return (
    <MUIModal
      open={open}
      onClose={onClose}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Box sx={sx}>{children}</Box>
    </MUIModal>
  )
}
