import { BORDERS, SHADOWS } from '../../../../../../types/colors'

export const leftIconStyle = {
  transform: 'rotateZ(90deg)',
  position: 'absolute',
  left: '8px',
  alignItems: 'center',
  justifyContent: 'center'
}

export const menuItemStyle = {
  px: 1,
  justifyContent: 'flex-end',
  alignItems: 'center',
  borderBottom: BORDERS.SM_GRAY,
  '& .MuiTypography-root': {
    fontWeight: 500
  }
}

export const menuPaperStyle = {
  overflow: 'visible',
  width: 220,
  mt: 5,
  border: BORDERS.SM_GRAY,
  borderRadius: '4px',
  boxShadow: SHADOWS.hover,
  '& .MuiMenuItem-root': {
    py: 2
  }
}
