import { FC, useMemo } from 'react'
import { Grid } from '@mui/material'
import { useUserInfo } from '../../../../../utils/hooks'
import { InteractiveActions } from './InteractiveActions'
import { UploadAction } from './UploadAction'
import { DownloadAction } from './DownloadAction'
import { NotAddedActions } from './NotAddedActions'
import { OrNull } from '../../../../../types/OrNull'
import { AGREEMENT_DOCUMENT_NAME } from '../../../../../constants/applicationConstants'

interface DocumentRowActionsProps {
  name: string
  id: OrNull<string>
  type: OrNull<string>
  createdOn: OrNull<Date>
  notes: OrNull<string>
  viewType: 'seller' | 'buyer'
  sellerID?: string
  buyerID?: string
  isEditable: boolean
  isUploadAllowed: boolean
}

export const DocumentRowActions: FC<DocumentRowActionsProps> = ({
  id,
  name,
  type,
  createdOn,
  sellerID,
  buyerID,
  viewType,
  notes,
  isEditable,
  isUploadAllowed
}) => {
  const { accountID } = useUserInfo()

  const isSeller = useMemo(() => {
    return accountID === sellerID
  }, [accountID, sellerID])

  const isBuyer = useMemo(() => {
    return accountID === buyerID
  }, [accountID, buyerID])

  const actions = useMemo(() => {
    const isUserAndTypeSeller = viewType === 'seller' && isSeller
    const isUserAndTypeBuyer = viewType === 'buyer' && isBuyer
    const isPurchaseAgreement = name === AGREEMENT_DOCUMENT_NAME
    if (isUserAndTypeSeller || isUserAndTypeBuyer) {
      if (id) {
        return isEditable && !isPurchaseAgreement ? (
          <InteractiveActions
            id={id}
            type={type}
            name={name}
            createdOn={createdOn}
            notes={notes}
          />
        ) : (
          <DownloadAction name={name} fileId={id} extension={type as string} />
        )
      }

      if (!id) {
        return (
          <UploadAction
            id={id}
            type={type}
            name={name}
            notes={notes}
            isEditable={isUploadAllowed && !isPurchaseAgreement}
          />
        )
      }
    }

    const isViewSellerAndUserBuyer = viewType === 'seller' && isBuyer
    const isViewBuyerAndUserSeller = viewType === 'buyer' && isSeller
    if (isViewSellerAndUserBuyer || isViewBuyerAndUserSeller) {
      if (id) {
        return (
          <DownloadAction name={name} fileId={id} extension={type as string} />
        )
      }

      return isViewSellerAndUserBuyer ? (
        <NotAddedActions userType='Seller' />
      ) : (
        <NotAddedActions userType='Buyer' />
      )
    }
  }, [id, isSeller, isBuyer, viewType, isEditable, name])

  return (
    <Grid container justifyContent='flex-end' alignItems='center'>
      <Grid item>{actions}</Grid>
    </Grid>
  )
}
