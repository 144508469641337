import { FC, useMemo } from 'react'
import { Show } from '../JSXExtensions'
import { Tooltip } from '../Tooltip'

export const DisplayColors: FC<{ colors: string[] }> = ({ colors }) => {
  const displayText = useMemo(() => {
    if (!colors.length) {
      return null
    }

    if (colors.length === 1) {
      return colors[0] || 'Any Color'
    }

    return `${colors[0]} +${colors.length - 1}`
  }, [colors])

  const isTooltipActive = useMemo(() => {
    return colors.length > 1
  }, [colors])

  return (
    <Show when={isTooltipActive} fallback={<span>{displayText}</span>}>
      <Tooltip title={colors.join(', ')}>
        <span>{displayText}</span>
      </Tooltip>
    </Show>
  )
}
