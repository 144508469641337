import { Box } from '@mui/material'
import { FC } from 'react'
import { AttentionTriangleIcon } from '../../assets/icons/AttentionTriangleIcon'
import { Tooltip } from '../Tooltip'

interface AttentionIconProps {
  text: string
}

export const AttentionIcon: FC<AttentionIconProps> = (props) => {
  const { text } = props

  return (
    <Tooltip title={text}>
      {/* Any node is needed for tooltip working */}
      <Box component='span' sx={{ cursor: 'pointer' }}>
        <AttentionTriangleIcon />
      </Box>
    </Tooltip>
  )
}
