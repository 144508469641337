import { FC, useCallback, useState, useRef } from 'react'
import { Cropper, ReactCropperElement } from 'react-cropper'
import { Grid, Typography, Box } from '@mui/material'
import { Avatar } from '../../atoms/Avatar'
import { PhotoIcon } from '../../assets/icons/Photo'
import { BlueButton } from '../../atoms/Button'
import {
  UploadDocument,
  UploadDocumentCallbackPropsItem
} from '../../atoms/UploadDocument'

import 'cropperjs/dist/cropper.css'
import './override.css'
import { dataURLtoFile } from '../../utils/formatters/dataURLtoFile'
import {
  CompressImageResponse,
  compressImages
} from '../../utils/compressImages'
import { UploadAvatarModals } from './Modals'
import {
  useApiContext,
  useAppDispatch,
  useMakeService,
  useUserInfo
} from '../../utils/hooks'
import { ResponseStatusMap } from '../../types/status'
import { actionsUserInfo } from '../../store/features/userInfo'
import {
  IMAGE_OVERSIZE_ERROR,
  IMAGE_WRONG_FORMAT_ERROR
} from '../../constants/messages'
import { MAX_PHOTO_SIZE_MB } from '../../constants/applicationConstants'
import { returnNoCachedSrc } from '../../utils/returnNoCachedSrc'

export type UploadNewPhotoProps = {
  avatar: string
}

const settings = { maxSizeMB: MAX_PHOTO_SIZE_MB, useWebWorker: true }

export const UploadNewPhoto: FC<UploadNewPhotoProps> = ({ avatar }) => {
  const dispatch = useAppDispatch()
  const { id } = useUserInfo()
  const { carSnoopApi } = useApiContext()

  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false)
  const [isDiscardPopupOpen, setIsDiscardPopupOpen] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [progress, setProgress] = useState(0)
  const [fileData, setFileData] =
    useState<UploadDocumentCallbackPropsItem | null>(null)
  const [error, setError] = useState('')
  const [cropper, setCropper] = useState<Cropper | null>(null)
  const cropperRef = useRef<ReactCropperElement>(null)

  const [uploadAvatar] = useMakeService(
    async (file: UploadDocumentCallbackPropsItem) => {
      const avatarFileType = file.type.split('/')[1]

      const res = await carSnoopApi.users.uploadAvatar({
        id,
        avatarFileType,
        avatar: file.cleanBase64
      })

      if (res.status === ResponseStatusMap.Success) {
        const userData = await carSnoopApi.users.readUser(id)

        if (userData.status === ResponseStatusMap.Success) {
          const { avatar: newPicture } = userData

          dispatch(
            actionsUserInfo.setUserPersonalInfo({
              avatar: `${newPicture}?${Date.now()}`
            })
          )
        }

        return userData
      }

      return { status: ResponseStatusMap.Error }
    },
    {
      withStatusNotification: true
    }
  )

  const onCrop = useCallback(() => {
    const imageElement = cropperRef?.current

    if (imageElement && !cropper) {
      setCropper(imageElement.cropper)
    }
  }, [cropper, cropperRef, setCropper])

  const beforeLoad = useCallback(
    (files: File[]) => {
      setIsProcessing(true)
      setError('')
      setFileData(null)
      setProgress(0)

      if (files.length) {
        setIsEditPopupOpen(true)
      }
    },
    [setIsProcessing, setFileData, setIsEditPopupOpen]
  )

  const onDiscardSubmit = useCallback(() => {
    setIsEditPopupOpen(false)
    setFileData(null)
    setIsProcessing(false)
    setCropper(null)
  }, [setFileData, setIsProcessing, setCropper])

  const onSubmit = useCallback(async () => {
    setFileData(null)

    if (cropper) {
      setProgress(0)
      setIsProcessing(true)
      const dataURL = cropper.getCroppedCanvas().toDataURL()

      const file = await dataURLtoFile(dataURL, fileData?.name || 'image')

      const [minifiedImage] = await compressImages([file], {
        onProgress: setProgress,
        ...settings
      })

      if (!minifiedImage.isFileExtensionAvailable) {
        return
      }

      if (minifiedImage.isAcceptableMinify) {
        await uploadAvatar(minifiedImage)
        setIsEditPopupOpen(false)
      } else {
        setError(IMAGE_OVERSIZE_ERROR)
      }
      setProgress(0)
      setIsProcessing(false)
    }

    setIsProcessing(false)
    setCropper(null)
  }, [
    cropper,
    fileData,
    uploadAvatar,
    setFileData,
    setIsProcessing,
    setCropper,
    setIsEditPopupOpen
  ])

  const onDiscard = useCallback(() => {
    setIsDiscardPopupOpen(true)
  }, [setIsDiscardPopupOpen])

  const onChangeFileLoad = useCallback(
    async ([userFile]: CompressImageResponse) => {
      if (userFile.isFileExtensionAvailable) {
        setIsProcessing(false)
        setProgress(0)
        setIsEditPopupOpen(true)
        setFileData(userFile)
      } else {
        setIsProcessing(false)
        setProgress(0)
        setIsEditPopupOpen(true)
        setError(IMAGE_WRONG_FORMAT_ERROR)
      }
    },
    [setIsProcessing, setIsEditPopupOpen, setFileData]
  )

  return (
    <Grid container item spacing='10px'>
      <UploadAvatarModals
        error={error}
        onCrop={onCrop}
        fileData={fileData}
        onSubmit={onSubmit}
        onDiscard={onDiscard}
        cropperRef={cropperRef}
        beforeLoad={beforeLoad}
        isProcessing={isProcessing}
        progress={progress}
        onDiscardSubmit={onDiscardSubmit}
        isEditPopupOpen={isEditPopupOpen}
        onChangeFileLoad={onChangeFileLoad}
        isDiscardPopupOpen={isDiscardPopupOpen}
        setIsEditPopupOpen={setIsEditPopupOpen}
        setIsDiscardPopupOpen={setIsDiscardPopupOpen}
      />
      <Grid container item xs={12} justifyContent='center'>
        <Avatar
          avatar={returnNoCachedSrc(avatar)}
          badge={
            <UploadDocument
              name='upload-avatar'
              onChange={onChangeFileLoad}
              onBeforeChange={beforeLoad}
            >
              <Box
                sx={{
                  borderRadius: '50%',
                  cursor: 'pointer',
                  overflow: 'hidden'
                }}
              >
                <PhotoIcon />
              </Box>
            </UploadDocument>
          }
        />
      </Grid>
      <Grid container item xs={12} justifyContent='center'>
        <BlueButton>
          <UploadDocument
            name='upload-avatar'
            onChange={onChangeFileLoad}
            onBeforeChange={beforeLoad}
          >
            <Typography color='inherit' sx={{ cursor: 'pointer' }}>
              Upload New Photo
            </Typography>
          </UploadDocument>
        </BlueButton>
      </Grid>
    </Grid>
  )
}
