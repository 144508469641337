import { Utils } from '../../utils'
import {
  ErrorResponse,
  RequestRecord,
  SuccessResponse
} from '../../private.types'
import { ResponseStatusMap } from '../../../types/status'
import {
  CreateDocumentParams,
  DocumentSuccess,
  DocumentError,
  DocumentSuccessResponse,
  DocumentErrorResponse,
  UploadDriverLicenseParams,
  CreateContractDocumentParams,
  AddNewDocumentParams,
  AddDocumentResult,
  GetDocumentURLResponse,
  UpdateDocumentNotesParams,
  UpdateDocumentNotesResponse
} from './types'

export class Documents {
  protected readonly utils: Utils
  protected readonly operations = {
    createDocument: 'createDocument',
    deleteContractDocument: 'deleteContractDocument',
    uploadDriversLicense: 'uploadDriversLicense',
    createContractDocument: 'createContractDocument',
    addContractDocument: 'addContractDocument',
    getDocumentUrl: 'getDocumentUrl',
    updateDocumentNotes: 'updateDocumentNotes',
    generatePurchaseAgreement: 'generatePurchaseAgreement'
  }

  constructor(utils: Utils) {
    this.utils = utils
  }

  async createDocument(
    params: CreateDocumentParams
  ): Promise<SuccessResponse<DocumentSuccess> | ErrorResponse<DocumentError>> {
    const dataToSend = {
      created_by: params.createdBy,
      type: params.type,
      name: params.name,
      object_type: params.objectType,
      object_id: params.objectId,
      content: params.content,
      notes: params.notes
    }
    try {
      const { type, payload } = await this.utils.makeJSONRequest<
        SuccessResponse<DocumentSuccessResponse>,
        ErrorResponse<DocumentErrorResponse>
      >(this.operations.createDocument, dataToSend as RequestRecord)

      if (type === ResponseStatusMap.Success) {
        return {
          status: ResponseStatusMap.Success,
          id: payload.id,
          message: payload.message
        }
      }

      return {
        status: ResponseStatusMap.Error,
        message: payload.message
      }
    } catch (e) {
      return {
        status: ResponseStatusMap.Error,
        message: 'Something went wrong'
      }
    }
  }

  async deleteContractDocument(
    id: string
  ): Promise<SuccessResponse<DocumentSuccess> | ErrorResponse<DocumentError>> {
    try {
      const { type, payload } = await this.utils.makeJSONRequest<
        SuccessResponse<DocumentSuccessResponse>,
        ErrorResponse<DocumentErrorResponse>
      >(this.operations.deleteContractDocument, { id })

      if (type === ResponseStatusMap.Success) {
        return {
          status: ResponseStatusMap.Success,
          id: payload.id,
          message: payload.message
        }
      }

      return {
        status: ResponseStatusMap.Error,
        message: payload.message
      }
    } catch (e) {
      return {
        status: ResponseStatusMap.Error,
        message: 'Something went wrong'
      }
    }
  }

  async uploadDriversLicense(
    params: UploadDriverLicenseParams
  ): Promise<SuccessResponse<DocumentSuccess> | ErrorResponse<DocumentError>> {
    try {
      const { type, payload } = await this.utils.makeJSONRequest<
        SuccessResponse<DocumentSuccessResponse>,
        ErrorResponse<DocumentErrorResponse>
      >(this.operations.uploadDriversLicense, params as RequestRecord)

      if (type === ResponseStatusMap.Success) {
        return {
          status: ResponseStatusMap.Success,
          id: payload.id,
          message: payload.message
        }
      }

      return {
        status: ResponseStatusMap.Error,
        message: payload.message
      }
    } catch (e) {
      return {
        status: ResponseStatusMap.Error,
        message: 'Something went wrong'
      }
    }
  }

  async createContractDocument(
    params: CreateContractDocumentParams
  ): Promise<SuccessResponse<DocumentSuccess> | ErrorResponse<DocumentError>> {
    const dataToSend = {
      contract_id: params.contractId,
      type: params.type,
      name: params.name,
      content: params.content,
      notes: params.notes
    }
    try {
      const { type, payload } = await this.utils.makeJSONRequest<
        SuccessResponse<DocumentSuccessResponse>,
        ErrorResponse<DocumentErrorResponse>
      >(this.operations.createContractDocument, dataToSend as RequestRecord)

      if (type === ResponseStatusMap.Success) {
        return {
          status: ResponseStatusMap.Success,
          id: payload.id,
          message: payload.message
        }
      }

      return {
        status: ResponseStatusMap.Error,
        message: payload.message
      }
    } catch (e) {
      return {
        status: ResponseStatusMap.Error,
        message: 'Something went wrong'
      }
    }
  }

  async addContractDocument({
    contractID,
    documentName
  }: AddNewDocumentParams): Promise<
    SuccessResponse<AddDocumentResult> | ErrorResponse<AddDocumentResult>
  > {
    try {
      const { type, payload } = await this.utils.makeJSONRequest<
        SuccessResponse<DocumentSuccessResponse>,
        ErrorResponse<DocumentErrorResponse>
      >(this.operations.addContractDocument, {
        contract_id: contractID,
        name: documentName
      })

      if (type === ResponseStatusMap.Success) {
        return {
          status: ResponseStatusMap.Success,
          message: payload.message
        }
      }

      return {
        status: ResponseStatusMap.Error,
        message: payload.message
      }
    } catch (e) {
      return {
        status: ResponseStatusMap.Error,
        message: 'Something went wrong'
      }
    }
  }

  async getDocumentUrl({
    documentId
  }: {
    documentId: string
  }): Promise<
    | SuccessResponse<GetDocumentURLResponse>
    | ErrorResponse<DocumentErrorResponse>
  > {
    try {
      const { type, payload } = await this.utils.makeJSONRequest<
        SuccessResponse<GetDocumentURLResponse>,
        ErrorResponse<DocumentErrorResponse>
      >(this.operations.getDocumentUrl, {
        id: documentId
      })

      if (type === ResponseStatusMap.Success) {
        return {
          status: ResponseStatusMap.Success,
          url: payload.url
        }
      }

      return {
        status: ResponseStatusMap.Error,
        message: payload.message
      }
    } catch (e) {
      return {
        status: ResponseStatusMap.Error,
        message: 'Something went wrong'
      }
    }
  }

  async updateDocumentNotes(
    params: UpdateDocumentNotesParams
  ): Promise<
    | SuccessResponse<UpdateDocumentNotesResponse>
    | ErrorResponse<UpdateDocumentNotesResponse>
  > {
    try {
      const { type, payload } = await this.utils.makeJSONRequest<
        SuccessResponse<UpdateDocumentNotesResponse>,
        ErrorResponse<UpdateDocumentNotesResponse>
      >(this.operations.updateDocumentNotes, params)

      if (type === ResponseStatusMap.Success) {
        return {
          status: ResponseStatusMap.Success,
          message: payload.message
        }
      }

      return {
        status: ResponseStatusMap.Error,
        message: payload.message
      }
    } catch (e) {
      return {
        status: ResponseStatusMap.Error,
        message: 'Something went wrong'
      }
    }
  }
  async generatePurchaseAgreement({
    contractID
  }: {
    contractID: string
  }): Promise<
    SuccessResponse<{ message: string }> | ErrorResponse<{ message: string }>
  > {
    try {
      const { type, payload } = await this.utils.makeJSONRequest<
        SuccessResponse<DocumentSuccessResponse>,
        ErrorResponse<DocumentErrorResponse>
      >(this.operations.generatePurchaseAgreement, {
        contract_id: contractID
      })

      if (type === ResponseStatusMap.Success) {
        return {
          status: ResponseStatusMap.Success,
          message: payload.message
        }
      }

      return {
        status: ResponseStatusMap.Error,
        message: payload.message
      }
    } catch (e) {
      return {
        status: ResponseStatusMap.Error,
        message: 'Something went wrong'
      }
    }
  }
}
