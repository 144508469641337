import { FC, ReactNode } from 'react'
import { Avatar as MUIAvatar, Badge, Box } from '@mui/material'
import { UnknownUserIcon } from '../../assets/icons/UnknownUser'

export interface AvatarProps {
  avatar: string
  badge?: ReactNode
}
export const Avatar: FC<AvatarProps> = (props) => {
  const { avatar, badge } = props
  return (
    <Badge
      overlap='circular'
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={badge}
      sx={{ '& .MuiBadge-badge': { bottom: '15px' } }}
    >
      <MUIAvatar
        sx={{
          width: '80px',
          height: '80px'
        }}
        alt='user avatar'
        src={avatar}
      >
        <Box
          sx={{
            width: '80px',
            height: '80px'
          }}
        >
          <UnknownUserIcon />
        </Box>
      </MUIAvatar>
    </Badge>
  )
}
