import { useMemo } from 'react'
import { OrNull } from '../../../../types/OrNull'
import { ContractViewStates } from '../useContractStateDecoder'

export const useContractMessage = (
  contractState: ContractViewStates,
  shipmentCreationIsSuccessful: boolean
): OrNull<string> => {
  return useMemo(() => {
    switch (contractState) {
      case ContractViewStates.BUYER_STRIPE_AWAITING_PAYMENT:
        return 'Seller has completed the contract, please continue with the shipping and payment.'
      case ContractViewStates.BUYER_STRIPE_SHIPPING:
        return 'We’re almost there! You may track the shipment here.'
      case ContractViewStates.BUYER_NON_STRIPE_SHIPPING:
        return shipmentCreationIsSuccessful
          ? 'We`re almost there! You may track the shipment here.'
          : 'Seller has confirmed the payment, please continue with the shippment.'
      case ContractViewStates.SELLER_STRIPE_SHIPPING:
      case ContractViewStates.SELLER_NON_STRIPE_SHIPPING:
        return shipmentCreationIsSuccessful
          ? 'We`re almost there! You may track the shipment here.'
          : 'Once the Buyer selects the shipment, you will be able to track it here'
      case ContractViewStates.BUYER_COMPLETED:
      case ContractViewStates.SELLER_COMPLETED:
        return 'Congratulations! The deal is completed.'
      case ContractViewStates.PAYMENT_FAILED:
      case ContractViewStates.UNDER_CONTRACT:
      case ContractViewStates.UNKNOWN:
      case ContractViewStates.SELLER_NON_STRIPE_AWAITING_PAYMENT:
      case ContractViewStates.BUYER_NON_STRIPE_AWAITING_PAYMENT:
      case ContractViewStates.SELLER_STRIPE_AWAITING_PAYMENT:
      default:
        return null
    }
  }, [contractState, shipmentCreationIsSuccessful])
}
