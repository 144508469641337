import { FormikProps } from 'formik'

export const isValidForm = <T extends object>(
  formik: FormikProps<T>,
  {
    requiredFields = []
  }: { requiredFields?: Array<keyof FormikProps<T>['values']> } = {}
): boolean => {
  if (formik.isValid) {
    if (requiredFields.length) {
      return requiredFields.every((fieldName) => formik.values[fieldName])
    }

    return true
  }

  return false
}
