import { Link, Typography } from '@mui/material'
import { FC } from 'react'

export interface IBreadcrumbItem {
  label: string
  link?: string
  isActive?: boolean
}

export const BreadcrumbItem: FC<IBreadcrumbItem> = ({
  label,
  link = '',
  isActive
}) => {
  if (link && !isActive) {
    return (
      <Typography variant='h6' fontWeight={400}>
        <Link color='inherit' underline='hover' href={link}>
          {label}
        </Link>
      </Typography>
    )
  }

  return (
    <Typography variant='h6' fontWeight={isActive ? 500 : 400}>
      {label}
    </Typography>
  )
}
